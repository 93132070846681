<template>
  <div class="d-flex align-center theme-text-color mr-1">
    <v-menu
      v-model="timestampCardOpened"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip open-delay="500" bottom :disabled="timestampCardOpened">
          <template v-slot:activator="tooltip">
            <div
              v-bind="tooltip.attrs"
              v-on="tooltip.on"
            >
              {{
              selectedTimestamp !== OFF_TIMESTAMP
                ? selectedTimestamp === CUSTOM_TIMESTAMP
                  ? !editCustomTimestampMode ? customTimestamp + " " + selectedTimestampVariable.text : ""
                  : (timestamps.find((it) => it.time === selectedTimestamp) || {}).text
                : ""
              }}
              <v-btn
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{pAutoRefreshBtnTooltip}}</span>
        </v-tooltip>
      </template>
      <v-card class="drp-refresh-card" width="250px">
        <v-card-title class="pb-0">
          {{$localizationService.localize("refresh.refresh_every")}}:
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedTimestamp"
            :items="timestamps"
            @change="changeTimestamp()"
            item-text="text"
            item-value="time"
          ></v-select>
          <div class="d-flex align-center" v-if="selectedTimestamp === CUSTOM_TIMESTAMP">
            <v-text-field
              class="custom-timestamp pt-0 mr-1"
              type="number"
              min="5"
              :label="$localizationService.localize('refresh.param.your_time')"
              :disabled="!editCustomTimestampMode"
              v-model="customTimestamp"
            ></v-text-field>
            <v-select
              class="custom-timestamp pt-0"
              :items="timestampVariables"
              :disabled="!editCustomTimestampMode"
              return-object
              v-model="selectedTimestampVariable"
            ></v-select>
            <div class="d-flex align-center" v-if="editCustomTimestampMode">
              <v-icon
                :class="{'mr-1': customTimestampCopy}"
                color="green"
                :disabled="!customTimestamp || !selectedTimestampVariable"
                @click="changeTimestamp()"
              >mdi-check</v-icon>
              <v-icon
                v-if="customTimestampCopy"
                color="red"
                @click="closeEditCustomTimestamp()"
              >mdi-close</v-icon>
            </div>
            <v-btn
              v-if="!editCustomTimestampMode"
              @click="editCustomTimestampMode = true"
              text
              x-small
            > {{$localizationService.localize("btn.edit")}}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-tooltip open-delay="500" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mr-2"
          icon
          @click="$emit('onRefresh')"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </template>
      <span>{{pRefreshBtnTooltip}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from "vue";
import { getLocalStorageData, setLocalStorageData } from "../utils/utils";

const locService = Vue.prototype.$localizationService;

const OFF_TIMESTAMP = "OFF TIMESTAMP";
const CUSTOM_TIMESTAMP = "CUSTOM";
const DEFAULT_TIMESTAMP = "DEFAULT";
const SEC_VALUE = 1000;
const MIN_VALUE = 1000 * 60;

export default {
  name: "RefreshData",

  props: {
    pAutoRefreshBtnTooltip: String,
    pRefreshBtnTooltip: String,
    pTimestampLocalStorageKey: String,
  },

  data: () => ({
    OFF_TIMESTAMP,
    CUSTOM_TIMESTAMP,
    DEFAULT_TIMESTAMP,
    SEC_VALUE,
    MIN_VALUE,

    timestampCardOpened: false,
    refreshDrp: undefined,
    selectedTimestamp: OFF_TIMESTAMP,
    selectedTimestampCopy: undefined,
    timestamps: [
      {
        text: locService.localize("refresh.default_timestamp.off"),
        time: OFF_TIMESTAMP,
        isDefault: true,
      },
      {
        text: locService.localize("refresh.default_timestamp.sec_30"),
        time: SEC_VALUE * 30,
      },
      {
        text: locService.localize("refresh.default_timestamp.min_1"),
        time: MIN_VALUE,
      },
      {
        text: locService.localize("refresh.default_timestamp.min_5"),
        time: MIN_VALUE * 5,
      },
      {
        text: locService.localize("refresh.default_timestamp.custom"),
        time: CUSTOM_TIMESTAMP,
      },
    ],

    editCustomTimestampMode: false,
    customTimestamp: undefined,
    selectedTimestampVariable: {
      text: locService.localize("refresh.sec"),
      value: SEC_VALUE,
    },
    customTimestampCopy: undefined,
    timestampVariables: [
      {
        text: locService.localize("refresh.sec"),
        value: SEC_VALUE,
      },
      {
        text: locService.localize("refresh.min"),
        value: MIN_VALUE,
      },
    ],
  }),

  watch: {
    timestampCardOpened() {
      if (
        !this.timestampCardOpened
        && this.selectedTimestamp === CUSTOM_TIMESTAMP
        && this.editCustomTimestampMode
      ) {
        this.editCustomTimestampMode = false;
        if (this.customTimestampCopy) {
          this.applyCopiedCustomTimestamp();
        } else {
          this.selectedTimestamp = this.selectedTimestampCopy || OFF_TIMESTAMP;
          this.customTimestamp = undefined;
        }
      }
    },
  },

  methods: {
    closeEditCustomTimestamp() {
      this.editCustomTimestampMode = false;
      this.applyCopiedCustomTimestamp();
    },

    applyCopiedCustomTimestamp() {
      this.customTimestamp = this.customTimestampCopy.customTimestamp;
      this.selectedTimestampVariable = { ...this.customTimestampCopy.timestampVariable };
    },

    cancelRefresh() {
      clearInterval(this.refreshDrp);
      this.saveTimestampOfRefresh();
    },

    changeTimestamp() {
      if (this.selectedTimestamp !== CUSTOM_TIMESTAMP) {
        this.editCustomTimestampMode = false;
        this.timestampCardOpened = false;
      }

      if (this.selectedTimestamp === OFF_TIMESTAMP) {
        this.createTimestampCopy();
        this.cancelRefresh();
        return;
      }

      if (this.selectedTimestamp === CUSTOM_TIMESTAMP && !this.customTimestamp) {
        this.editCustomTimestampMode = true;
        return;
      }

      if (
        this.selectedTimestamp === CUSTOM_TIMESTAMP
        && +this.customTimestamp < 5
        && this.selectedTimestampVariable.value === SEC_VALUE
      ) {
        Vue.toasted.error(
          locService.localize("refresh.error.less_5_sec"),
          { duration: 3000 },
        );
        return;
      }

      clearInterval(this.refreshDrp);
      this.refreshDrpByTimestamp();
    },

    createTimestampCopy() {
      if (this.selectedTimestamp === CUSTOM_TIMESTAMP) {
        this.customTimestampCopy = {
          customTimestamp: this.customTimestamp,
          timestampVariable: { ...this.selectedTimestampVariable },
        };
      } else {
        this.selectedTimestampCopy = this.selectedTimestamp;
      }
    },

    refreshDrpByTimestamp() {
      let timestamp;
      if (this.selectedTimestamp === CUSTOM_TIMESTAMP) {
        this.editCustomTimestampMode = false;
        timestamp = +this.customTimestamp * this.selectedTimestampVariable.value;
      } else {
        timestamp = this.selectedTimestamp;
      }
      this.createTimestampCopy();

      this.saveTimestampOfRefresh();
      this.refreshDrp = setInterval(() => {
        this.$emit("onRefresh");
      }, timestamp);
    },

    saveTimestampOfRefresh() {
      let timestamp;
      if (this.selectedTimestamp !== OFF_TIMESTAMP) {
        if (this.selectedTimestamp === CUSTOM_TIMESTAMP) {
          timestamp = {
            type: CUSTOM_TIMESTAMP,
            customTimestamp: this.customTimestamp,
            timestampVariable: this.selectedTimestampVariable,
          };
        } else {
          timestamp = {
            type: DEFAULT_TIMESTAMP,
            timestamp: this.selectedTimestamp,
          };
        }
      }

      setLocalStorageData(
        this.pTimestampLocalStorageKey,
        timestamp ? JSON.stringify(timestamp) : undefined,
      );
    },
  },

  async mounted() {
    const storageData = await getLocalStorageData(this.pTimestampLocalStorageKey);

    let parsedData;
    try {
      parsedData = JSON.parse(storageData);
    } catch (e) {
      console.log("Refresh is off");
    }

    if (parsedData) {
      if (parsedData.type === CUSTOM_TIMESTAMP) {
        this.selectedTimestamp = this.timestamps.find((it) => it.time === CUSTOM_TIMESTAMP).time;
        this.customTimestamp = parsedData.customTimestamp;
        this.selectedTimestampVariable = parsedData.timestampVariable;
      }

      if (parsedData.type === DEFAULT_TIMESTAMP) {
        this.selectedTimestamp = parsedData.timestamp;
      }

      this.createTimestampCopy();

      this.refreshDrpByTimestamp();
    }
  },
};
</script>

<style lang="scss">
.drp-refresh-card {
  .custom-timestamp {
    width: 30px;
    .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-text-field__details,
    .v-messages {
      display: none !important;
    }
  }
}
</style>
