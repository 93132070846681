










































import {
  Component, Emit, Ref, Vue,
} from "vue-property-decorator";
import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import { TRIGGER_ACTION_TYPES } from "@/utils/ts-utils";
import { TriggerActionType } from "@/types/types";
import TriggerAgentsModule from "@/store/trigger-agents.store";

const triggerAgentsModule = TriggerAgentsModule.namespace;

const locService = Vue.prototype.$localizationService;

@Component({})
export default class EditTriggerActionDialog extends Vue {
  locService = locService;

  TRIGGER_ACTION_TYPES = TRIGGER_ACTION_TYPES;

  open = false;

  targetType: TriggerActionType | null = null;

  actionDraftTemplate: ActionDraftTemplate | null = null;

  actionIndex!: number | null;

  disableSave = true;

  @triggerAgentsModule.State
  actionAgents!: any;

  @Ref("actionAgent")
  actionAgent!: any;

  saveTriggerAction() {
    this.actionDraftTemplate = this.actionAgent.actionTemplate;
    this.emitToSaveAction(this.actionDraftTemplate as ActionDraftTemplate, this.actionIndex);
    this.open = false;
  }

  @Emit("onSaveTriggerAction")
  emitToSaveAction(action: ActionDraftTemplate, index: number | null) {
    return {
      action,
      index,
    };
  }

  openDialog(actionDraftTemplate: ActionDraftTemplate | null, index: number | null) {
    this.actionDraftTemplate = actionDraftTemplate?.clone() || null;
    this.actionIndex = index ?? null;
    this.targetType = this.actionDraftTemplate?.type || null;
    this.open = true;
  }
}
