import lodash from "lodash";
import { getLocalStorageData, setLocalStorageData } from "../../utils/utils";

export default {

  name: "drpRescuersTableSettingsModule",
  namespaced: true,
  state: {
    tableSettingsStorageKey: "drp.rescuers.table.settings",

    sortPropertyName: "rescuersSort",

    rescuersSort: {
      sortBy: [],
      sortDesc: [],
    },

    defaultSettings: {
      rescuersSort: {
        sortBy: [],
        sortDesc: [],
      },
    },
  },

  mutations: {
    changeSettings(state, data) {
      state.rescuersSort = data.rescuersSort;
    },

    saveSettings(state) {
      const data = {
        rescuersSort: state.rescuersSort,
      };
      setLocalStorageData(state.tableSettingsStorageKey, JSON.stringify(data));
      state.defaultSettings = data;
    },

    applyDefaultSettings(state) {
      state.rescuersSort = { ...state.defaultSettings.rescuersSort };
    },

    applyQuerySettings(state, query) {
      if (query.rescuersSort) {
        state.rescuersSort = JSON.parse(query.rescuersSort);
      }
    },
  },

  actions: {
    loadDefaultSettingsFromStorage({ state }) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },
  },

  getters: {
    isDefaultSettings: (state, getters) => getters.isDefaultSort,
    isDefaultSort: (state) => lodash.isEqual(state.rescuersSort, state.defaultSettings.rescuersSort),
  },
};
