<template>
  <v-container fluid class="company-management-table ">
    <CompanyManagement />
        <v-row class="pa-3">
          <v-col cols="12">
            <v-toolbar flat>
              <v-toolbar-title class="table-toolbar-title mr-4 d-flex align-center">
                {{
                    $localizationService.localize("company_management_page.staff")
                }}
                <TableSettings
                  class="ml-5"
                  :pDefaultButtonTooltip="$localizationService.localize('user_page.settings_tooltip.apply_default')"
                  :pIsVisible="isVisibleSettings()"
                  :pSaveButtonTooltip="$localizationService.localize('user_page.settings_tooltip.save')"
                  @onApplyDefaultSettings=
                    "selectedTab === USERS_TAB ? applyDefaultUserSettings() : applyDefaultUserInvitationSettings()"
                  @onSaveSettings=
                    "selectedTab === USERS_TAB ? saveUserSettings() : saveUserInvitationSettings()"
                />
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                class="search-field"
                :label="$localizationService.localize('user_page.search')"
                hide-details
                width="200"
                v-model="searchString"
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-2"
                @click="$refs.createUserInvitaionDialog.openDialog()"
              >
                {{
                  $localizationService.localize(
                      "company_management_page.user_invitations.new_btn"
                  )
                }}
              </v-btn>
            </v-toolbar>
            <v-tabs v-model="selectedTabModel" @change="updateRoutePath()">
              <v-tab
                v-for="tab in tabs"
                :key="tab.value"
                active-class="primary--text"
              >
                {{ tab.text }}
              </v-tab>
            </v-tabs>
            <UserInvitations
              v-if="selectedTab === USER_INVITATIONS_TAB"
              :searchString="searchString"
              @onUpdatePage="onUpdatePage($event)"
              ref="userInvitation"
            />
            <Users
              v-if="selectedTab === USERS_TAB"
              :searchString="searchString"
              @onUpdatePage="onUpdatePage($event)"
              ref="users"
            />
            <CreateUserInvitationDialog ref="createUserInvitaionDialog" />
          </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import CompanyManagement from "@/components/CompanyManagement.vue";
import CreateUserInvitationDialog from "../components/user-invitations/CreateUserInvitationDialog";
import TableSettings from "@/components/TableSettings.vue";
import UserInvitations from "@/components/user-invitations/UserInvitations.vue";
import { USERS_TAB, USER_INVITATIONS_TAB } from "../utils/utils";
import Users from "../components/users/Users";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CompanyManagementPage",

  data: () => ({
    USER_INVITATIONS_TAB,
    USERS_TAB,
    selectedTabModel: 0,
    searchString: "",

    tabs: [
      {
        text: locService.localize("company_management_page.tab.users"),
        value: USERS_TAB,
        tabIndex: 0,
      },
      {
        text: locService.localize("company_management_page.tab.user_invitations"),
        value: USER_INVITATIONS_TAB,
        tabIndex: 1,
      },
    ],
  }),

  routing: {
    localizationKey: "menu.tab.company_management",
    icon: "mdi-domain",
    path: "/company_management",
    hasAccess: (user) => user.isAdmin,
  },

  components: {
    Users,
    CompanyManagement,
    UserInvitations,
    TableSettings,
    CreateUserInvitationDialog,
  },

  computed: {
    ...mapState("userModule", [
      "userTableInited",
    ]),
    ...mapState("userInvitationModule", [
      "userInvitationTableInited",
    ]),
    ...mapGetters("usersTableSettingsModule", [
      "isDefaultSettings",
    ]),
    ...mapGetters("userInvitationTableSettingsModule", [
      "isDefaultUserInvitationSettings",
    ]),
    selectedTab() {
      return this.tabs.find((it) => it.tabIndex === this.selectedTabModel).value;
    },
  },

  methods: {
    isVisibleSettings() {
      if (this.selectedTab === USERS_TAB && !this.isDefaultSettings) return true;
      return this.selectedTab === USER_INVITATIONS_TAB && !this.isDefaultUserInvitationSettings;
    },

    onUpdatePage(e) {
      this.updateRoutePath(e);
    },

    updateRoutePath(e) {
      const newPath = `/company_management/${this.selectedTab}/`;

      const params = new URLSearchParams();

      if (e) {
        params.append("pageSize", e.size);

        params.append("pageIndex", e.index);

        params.append("sort", JSON.stringify(e.sort));

        if (e.searchString) {
          params.append("searchString", e.searchString);
        }
      }

      if (
        this.$route.path !== newPath || this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`${newPath}?${params.toString()}`);
      }
    },

    saveUserSettings() {
      this.$refs.users.saveSettings();
    },

    applyDefaultUserSettings() {
      this.$refs.users.applyDefaultSettings();
    },

    saveUserInvitationSettings() {
      this.$refs.userInvitation.saveSettings();
    },

    applyDefaultUserInvitationSettings() {
      this.$refs.userInvitation.applyDefaultSettings();
    },
  },

  created() {
    const query = this.$route.query || {};
    if (this.$route.params.selectedTab) {
      this.selectedTabModel = (this.tabs.find((it) => (
        it.value === this.$route.params.selectedTab
      )) || {}).tabIndex || 0;
    }
    if (query.searchString) {
      this.searchString = query.searchString;
    }
  },
};
</script>

<style scoped lang="scss">
.company-management-table {
  .search-field {
    max-width: 250px;
  }
  .table-toolbar-title {
    min-width: min-content;
  }
}
</style>
