<template>
  <v-dialog v-model="open" max-width="602px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              editedScheme.id
                ? "vehicle_inspection_scheme_page.dialog.add_and_edit.edit_title"
                : "vehicle_inspection_scheme_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container class="vehicle-inpsection-scheme-editor-container pa-0">
          <v-row>
            <v-col>
              <v-text-field
                :rules="requiredRule"
                v-model="editedScheme.name"
                :label="
                  $localizationService.localize(
                      'vehicle_inspection_scheme_page.dialog.add_and_edit.param.name'
                  )
                "
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="editedScheme.description"
                :label="
                  $localizationService.localize(
                      'vehicle_inspection_scheme_page.dialog.add_and_edit.param.description'
                  )
                "
                :disabled="loading"
              ></v-text-field>
              <VehicleTargetSelection
                :label="$localizationService.localize('vehicle_inspection_assign_dialog.target_vehicles')"
                :target-vehicle-groups="editedScheme.targetVehicleGroups"
                :target-vehicles="editedScheme.targetVehicles"
                :is-required-groups=true
                :multiple=true
                @input="onTargetSelect($event)"
              />
            </v-col>
          </v-row>
          <v-row class="inspection-draggable-container">
            <div>
              <div class="d-flex flex-row justify-space-between align-center">
                <v-text-field
                  v-model="search"
                  placeholder="Search">
                </v-text-field>
                <v-btn
                  v-if="!showDeleteButton"
                  class="main-action-button ml-4 mb-2"
                  color="primary"
                  @click="addNewItem()"
                >New Item</v-btn>
                <draggable
                  v-if="showDeleteButton"
                  class="vehicle-inspection-item-dev-null"
                  draggable=".item"
                  group="vehicle-inspection-items"
                  v-model="trashList"
                >
                  <v-btn
                    slot="header"
                    class="main-action-button ml-4 mb-2 white--text"
                    color="red"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </draggable>
              </div>
              <draggable
                class="inspection-draggable-body"
                v-model="displayItems"
                handle=".handle"
                group="vehicle-inspection-items"
                @end="onEndDrag"
                @start="onStartDrag"
              >
                <div
                  v-for="it in displayItems"
                  :key="it.model.id"
                  class="vehicle-inspection-item"
                  :id="it.model.id"
                >
                  <div
                    class="vehicle-inspection-item-header"
                    :canDrag="it.canDrag"
                    @mouseenter="enableDraggable(it)"
                    @mouseleave="disableDraggable(it)"
                  >
                    <p class="item-order text-h6 mr-4">{{ getDisplayOrder(it) }}</p>
                    <v-icon
                      class="item-dragger ml-1 mr-3 handle"
                      @mouseenter="changeShowDeleteButton(true)"
                      @mouseleave="changeShowDeleteButton(false)"
                    >mdi-camera-control</v-icon>
                    <v-text-field
                      :rules="requiredRule"
                      class="inspection-item-scheme-name-editor"
                      v-model="it.model.name"
                      placeholder="Name">
                    </v-text-field>
                    <v-select
                      :rules="requiredRule"
                      class="inspection-item-scheme-type-selector ml-4"
                      v-model="it.model.type"
                      :items="availableSupportedTypes(it)"
                      :disabled="!it.isNew"
                      placeholder="Type"
                    >
                      <template v-slot:item="{ item }">

                        <div v-if="item.value == 'InspectionOdometerItem'">
                          <v-icon class="mr-2">mdi-counter</v-icon> Odometer
                        </div>

                        <div v-else-if="item.value == 'InspectionFailPassItem'">
                          <v-icon class="mr-2">mdi-check</v-icon>
                          <span class="red--text">Fail</span> / <span class="green--text">Pass</span>
                        </div>

                        <div v-else-if="item.value == 'InspectionNumberValueItem'">
                          <v-icon class="mr-2">mdi-numeric</v-icon> Numeric
                        </div>

                      </template>

                      <template v-slot:selection="{ item }">

                        <div v-if="item.value == 'InspectionOdometerItem'">
                          <v-icon class="mr-1">mdi-counter</v-icon> Odometer
                        </div>

                        <div v-else-if="item.value == 'InspectionFailPassItem'">
                          <v-icon class="mr-1">mdi-check</v-icon>
                          <span class="red--text">Fail</span> / <span class="green--text">Pass</span>
                        </div>

                        <div v-else-if="item.value == 'InspectionNumberValueItem'">
                          <v-icon class="mr-1">mdi-numeric</v-icon> Numeric
                        </div>

                      </template>

                    </v-select>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-4"
                          :color="getRequirePhotoColor(it)"
                          @click="changeRequirePhotoMode(it)"
                          v-bind="attrs"
                          v-on="on"
                        >mdi-camera</v-icon>
                      </template>
                      <span>{{ getCameraTooltip(it) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on">
                          <v-icon
                            class="ml-4"
                            :color="getRequireRepairVerificationColor(it)"
                            @click="changeRequireRepairVerificationMode(it)"
                            :disabled="!isSupportRequireRepairVerification(it)"
                            v-bind="attrs"
                          >mdi-wrench</v-icon>
                        </div>
                      </template>
                      <span>{{ getWrenchTooltip(it) }}</span>
                    </v-tooltip>
                    <v-icon
                      class="draggable-header-last-item ml-4"
                      @click="it.expanded = !it.expanded"
                    >{{it.model.instruction ? "mdi-script-text" : "mdi-script"}}</v-icon>
                  </div>
                  <div v-if="it.expanded" class="mr-3">
                    <v-textarea
                      class="ml-10"
                      v-model="it.model.instruction"
                      label="Instruction"
                      rows="3"
                    >
                    </v-textarea>
                  </div>
                </div>
              </draggable>
            </div>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="addOrSaveVehicleInspectionScheme"
            :disabled="saveIsDisabled"
        >
          {{ $localizationService.localize(editedScheme.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
      <ConfirmationDialog ref="deleteDialog" @onCloseDialog="onCloseDeleteConfirmationDialog()"/>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import lodash, { isEqual } from "lodash";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import { VehicleInspectionScheme } from "@/model/inspection/vehicle-inspection-scheme.model";
import ConfirmationDialog from "../ConfirmationDialog";
import VehicleTargetSelection from "./VehicleTargetSelection";
import {
  INSPECTION_ODOMETER_ITEM,
  INSPECTION_FAIL_PASS_ITEM,
  INSPECTION_NUMBER_VALUE_ITEM,
  INPSECTION_ITEM_TYPES,
} from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditVehicleInspectionSchemeDialog",

  components: {
    draggable,
    ConfirmationDialog,
    VehicleTargetSelection,
  },

  data: () => ({
    INSPECTION_ODOMETER_ITEM,
    INSPECTION_FAIL_PASS_ITEM,
    INSPECTION_NUMBER_VALUE_ITEM,
    INPSECTION_ITEM_TYPES,

    open: false,
    loading: false,

    draggableEnabled: false,

    showDeleteButton: false,
    showDeleteButtotStateLocked: false,

    editedSchemeOriginal: {},
    editedScheme: {},

    search: "",

    supportedTypes: INPSECTION_ITEM_TYPES.map((it) => ({ value: it })),

    schemeItems: [],
    schemeItemsOriginal: [],

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

    targets: [],
    isFirstEmit: true,
    originalTargets: [],

  }),

  computed: {

    trashList: {

      set(toDeleteItems) {
        toDeleteItems.forEach((it) => {
          this.$refs.deleteDialog.openDialog({
            header: locService.localize("fuel_card_page.dialog.delete.title"),
            text: locService.localize("fuel_card_page.dialog.delete.text", [
              it.name,
            ]),
            submitText: locService.localize("btn.delete"),
            submitColor: "red",
            onSubmit: () => {
              this.schemeItems.splice(it.model.myOrder, 1);
              this.recalcItemsOrder();
            },
          });
        });
      },

      get() {
        return [];
      },
    },

    displayItems: {

      set() {},

      get() {
        return this.schemeItems.filter((it) => it.model.name.includes(this.search))
          .sort((a, b) => a.model.myOrder - b.model.myOrder);
      },
    },

    targetVehicleIds() {
      return this.targets.filter((it) => it.isVehicle).map((it) => it.id);
    },

    targetVehicleGroupIds() {
      return this.targets.filter((it) => it.isGroup).map((it) => it.id);
    },

    saveIsDisabled() {
      return this.loading
          || !this.editedScheme.name
          || (
            isEqual(this.editedScheme, this.editedSchemeOriginal)
            && isEqual(
              this.schemeItems.map((it) => it.model)
                .sort((a, b) => a.myOrder - b.myOrder),
              this.schemeItemsOriginal.map((it) => it.model)
                .sort((a, b) => a.myOrder - b.myOrder),
            )
              && isEqual(this.originalTargets, this.targets)
          )
          || !!this.schemeItems.find((it) => !it.model.type || !(it.model.name || "").trim());
    },
  },

  methods: {

    isSupportRequireRepairVerification(item) {
      return item.model.type == "InspectionFailPassItem";
    },

    changeRequireRepairVerificationMode(item) {
      item.model.requireRepairVerification = !item.model.requireRepairVerification;
    },

    getRequireRepairVerificationColor(item) {
      return item.model.requireRepairVerification ? "primary" : "default";
    },

    getWrenchTooltip(item) {
      let option;

      if (this.isSupportRequireRepairVerification(item)) {
        option = `${item.model.requireRepairVerification ? "" : "no_"}require_repair_verification`;
      } else {
        option = "require_repair_verification_unavailable_for_this_type";
      }

      return this.$localizationService.localize(
        // eslint-disable-next-line max-len
        `vehicle_inspection_scheme_page.dialog.add_and_edit.items.param.${option}`,
      );
    },

    onTargetSelect(event) {
      if (this.isFirstEmit) {
        this.isFirstEmit = false;
        this.originalTargets = event;
      }
      this.targets = event;
    },

    getDisplayOrder(it) {
      return `#${it.model.myOrder < 9 ? "0" : ""}${it.model.myOrder + 1}`;
    },

    availableSupportedTypes(item) {
      const types = [...this.supportedTypes];

      if (this.schemeItems.find((it) => it.model.type == "InspectionOdometerItem" && it.model.id != item.model.id)) {
        types.splice(types.findIndex((it) => it.value == "InspectionOdometerItem"), 1);
      }

      return types;
    },

    // Stabilization for new/delete button
    onCloseDeleteConfirmationDialog() {
      this.showDeleteButtotStateLocked = false;

      this.schemeItems.forEach((it) => {
        it.canDrag = false;
      });

      if (!this.schemeItems.length) {
        this.changeShowDeleteButton(false);
      }
    },

    addNewItem() {
      this.schemeItems.push({
        model: {
          id: uuid(), // Temporal id
          name: "",
          type: undefined,
          requirePhoto: false,
          requirePhotoOnPass: false,
          requirePhotoOnFail: false,
          instruction: "",
          myOrder: this.schemeItems.length,
          requireRepairVerification: true,
        },
        isNew: true,
        expanded: false,
        canDrag: true,
        requirePhotoState: 0,
      });
    },

    recalcItemsOrder() {
      this.schemeItems.forEach((it, i) => it.model.myOrder = i);
    },

    changeShowDeleteButton(value) {
      if (value) {
        this.showDeleteButton = true;
        this.disableShowDeleteButton(true);
      } else this.disableShowDeleteButton(false);
    },
    // Can make problem with multiple same this dialogs
    disableShowDeleteButton: lodash.debounce(function (value) {
      if (this.showDeleteButtotStateLocked) return;
      this.showDeleteButton = value;
    }, 300),

    getCameraTooltip(it) {
      const basePath = "vehicle_inspection_scheme_page.dialog.add_and_edit.items.param.require_photo";
      const subLocalize = (v) => this.$localizationService.localize(`${basePath}.${v}`);

      if (it.model.requirePhoto || (it.model.requirePhotoOnPass && it.model.requirePhotoOnFail)) return subLocalize("always");
      if (it.model.requirePhotoOnPass) return subLocalize("pass");
      if (it.model.requirePhotoOnFail) return subLocalize("fail");
      return subLocalize("not");
    },

    onStartDrag(e) {
      this.showDeleteButtotStateLocked = true;
      this.onDrag(e.item.id);
    },

    onEndDrag(e) {
      this.showDeleteButtotStateLocked = false;

      this.onDrag(e.item.id);

      if (!this.schemeItems.length) {
        this.changeShowDeleteButton(false);
      }

      if (e.from != e.to) return;

      this.schemeItems.sort((a, b) => a.model.myOrder - b.model.myOrder);

      const item = this.displayItems[e.oldIndex];
      const wantIndex = this.displayItems[e.newIndex].model.myOrder;

      this.schemeItems.splice(item.model.myOrder, 1);
      this.schemeItems.splice(wantIndex, 0, item);

      this.recalcItemsOrder();
      console.log("RUned");
    },

    onDrag(itemId) {
      this.schemeItems.forEach((it) => {
        it.canDrag = it.model.id == itemId;
      });
    },

    enableDraggable(it) {
      it.canDrag = true;
    },

    disableDraggable(it) {
      it.canDrag = false;
    },

    changeRequirePhotoMode(it) {
      if (it.model.type == "InspectionFailPassItem") {
        it.requirePhotoState++;

        if (it.requirePhotoState == 4) {
          it.requirePhotoState = 0;
        }

        switch (it.requirePhotoState) {
          case 0:
            it.model.requirePhotoOnFail = false;
            it.model.requirePhotoOnPass = false;
            break;
          case 1:
            it.model.requirePhotoOnFail = true;
            it.model.requirePhotoOnPass = false;
            break;
          case 2:
            it.model.requirePhotoOnFail = false;
            it.model.requirePhotoOnPass = true;
            break;
          case 3:
            it.model.requirePhotoOnFail = true;
            it.model.requirePhotoOnPass = true;
            break;
        }
      } else {
        it.model.requirePhoto = !it.model.requirePhoto;
      }
    },

    getRequirePhotoColor(item) {
      if (item.requirePhotoState == 1) return "red";
      if (item.requirePhotoState == 2) return "green";
      if (item.requirePhotoState == 3 || item.model.requirePhoto) return "primary";
      return ""; // "grey";
    },

    openDialog(vehicleInspectionScheme) {
      this.showDeleteButton = false;
      this.showDeleteButtotStateLocked = false;

      this.editedSchemeOriginal = vehicleInspectionScheme || { items: [] };
      this.editedScheme = Object.assign(new VehicleInspectionScheme(), this.editedSchemeOriginal);

      this.schemeItems = this.editedScheme.items.map((it) => ({
        model: { ...it, type: it.type },
        expanded: false,
        canDrag: true,
        requirePhotoState: this.getRequirePhotoStateByModel(it),
      }));
      this.schemeItemsOriginal = this.schemeItems.map((it) => ({ ...it, model: { ...it.model } }));

      this.open = true;
    },

    getRequirePhotoStateByModel(model) {
      if (model.type == "InspectionFailPassItem") {
        if (!model.requirePhotoOnFail && !model.requirePhotoOnPass) return 0;
        if (model.requirePhotoOnFail && !model.requirePhotoOnPass) return 1;
        if (!model.requirePhotoOnFail && model.requirePhotoOnPass) return 2;
        if (model.requirePhotoOnFail && model.requirePhotoOnPass) return 3;
      }

      return 0;
    },

    closeDialog() {
      this.open = false;
    },

    async addOrSaveVehicleInspectionScheme() {
      this.loading = true;

      const itemsOrder = this.schemeItems
        .sort((a, b) => a.model.myOrder - b.model.myOrder)
        .map((it) => it.model.name.trim());

      const itemIds = this.schemeItems.map((it) => it.model.id);

      const response = await this.$store.dispatch(
        "vehicleInspectionSchemesModule/addOrSaveVehicleInspectionScheme",
        {
          ...this.editedScheme,
          targetVehicleIds: this.targetVehicleIds,
          targetVehicleGroupIds: this.targetVehicleGroupIds,
          itemsOrder,
          createItems: this.schemeItems.filter((it) => it.isNew).map((it) => ({
            ...it.model,
            // remove temporal id
            id: undefined,
            type: it.model.type,
          })),
          updateItems: this.schemeItems.filter((it) => !it.isNew).map((it) => ({
            ...it.model,
            type: it.model.type,
          })),
          deleteItems: this.schemeItemsOriginal.filter((it) => !itemIds.includes(it.model.id)).map((it) => it.model.id),
        },
      );

      this.loading = false;

      if (response) {
        this.$emit("onAddOrSave", response);
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss">

.vehicle-inspection-item-dev-null {

  .vehicle-inspection-item {
    display: none !important;
  }
}

.inspection-item-scheme-name-editor {
  max-width: 250px;
}

.inspection-item-scheme-type-selector {
  max-width: 110px;

  .v-input__append-inner {
    display: none !important;
  }
}

.vehicle-inpsection-scheme-editor-container {
//  height: 40vh;
}

.inspection-draggable-container {

  .main-action-button {
    width: 109px;
  }

  > div {
    width: 100%;
    padding: 0 12px;
  }
}

.inspection-draggable-body {
  position: relative;
  height: 30vh;
  max-height: 30vh;
  width: calc(100% + 12px);
  overflow-y: auto;
  > div {
    width: 100%;
  }

  ::-webkit-scrollbar {
    transform: translateX(12px);
  }
}

.vehicle-inspection-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-bottom: 0;
    height: min-content;
  }

  .item-order {
    color: var(--v-themeTextColor-base);
    user-select: none;
    width: 36px !important;
  }

  .item-dragger {
    cursor: grab;
    display: none;
    width: 36px !important;
  }

  .draggable-header-last-item {
    justify-self: flex-end;
  }

  &:hover[canDrag=true] {

    .item-dragger {
      display: unset;
    }

    .item-order {
      display: none;
    }
  }
}

.vehicle-inspection-item[draggable=true] {

  .vehicle-inspection-item-header {

    .item-dragger {
      display: unset !important;
    }

    .item-order {
      display: none !important;
    }
  }
}

</style>
