
























































import {
  Component, Emit, Prop, Ref, Vue, Watch,
} from "vue-property-decorator";

const READ_STATE = "READ";
const EDIT_STATE = "EDIT";

@Component
export default class ReadEditTextarea extends Vue {
  READ_STATE = READ_STATE;

  EDIT_STATE = EDIT_STATE;

  state = READ_STATE;

  currentValue = "";

  @Prop()
  disabled: boolean | undefined

  @Prop()
  maxlength: number | undefined

  @Prop()
  rows: number | undefined

  @Prop()
  value!: string;

  @Prop()
  label!: string;

  @Prop()
  isDisplayLabelOnRead!: boolean;

  @Ref("readContainer")
  readContainer!: any;

  @Ref("editTextarea")
  editTextarea!: any;

  rules: any[] = [];

  @Watch("value")
  onUpdateValue() {
    this.currentValue = this.value;
  }

  @Emit("onSaveValue")
  emitNewValue(value: string) {
    return value;
  }

  get disableSave() {
    return (!this.value
        && (this.value || "") === (this.currentValue || ""))
      || (this.value || "") === (this.currentValue || "")
      || this.rules.map((it) => it(this.currentValue)).find((it) => it !== true && it);
  }

  saveNewValue() {
    if (this.disableSave) return;

    this.emitNewValue(this.currentValue);
    if (this.currentValue) {
      this.readField();
    }
  }

  cancelEditing() {
    if (!this.value) return;

    this.currentValue = this.value;
    this.readField();
  }

  editField() {
    const scroll = this.readContainer.scrollTop;
    this.state = EDIT_STATE;
    Vue.nextTick(() => {
      this.editTextarea.$refs.input.scrollTop = scroll;
    });
  }

  readField() {
    const scroll = this.editTextarea.$refs.input.scrollTop;
    this.state = READ_STATE;
    Vue.nextTick(() => {
      this.readContainer.scrollTop = scroll;
    });
  }

  mounted() {
    this.currentValue = this.value || "";

    if (!this.value) {
      this.editField();
    }

    const maxlength = this.maxlength;
    if (maxlength) {
      this.rules.push((v: string | undefined) => !v || v.length <= maxlength || `Max ${this.maxlength} characters`);
    }
  }
}
