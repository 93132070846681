<template>
  <Dashboard grafana-path="/d/kVfFugRnz/netradyne-following-distance-pivot" />
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "NetradyneFollowingDistanceDriverPivot",

  routing: {
    localizationKey: "menu.tab.following_distance",
    icon: "mdi-table",
    path: "/netradyne_following_distance",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Dashboard,
  },
};
</script>
