






























































































import {
  Component, Prop, Vue,
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import moment from "moment";
import { Vehicle } from "@/model/vehicle.model";
import RemindersModule from "@/store/issues.store";
import { Reminder, ReminderStatus } from "@/model/reminder/reminder.model";
import SubmitActionDialog from "@/components/reminders/vehicle-actions/SubmitActionDialog.vue";
import CreateReminderDialog from "@/components/reminders/CreateReminderDialog.vue";
import { VehicleAction } from "@/model/reminder/vehicle-action.model";

const locService = Vue.prototype.$localizationService;
@Component({
  components: {
    SubmitActionDialog,
    CreateReminderDialog,
  },
})
export default class VehicleReminders extends Vue {
  locService = locService;

  search: string | null = null;

  @Prop()
  vehicle!: Vehicle;

  get vehicleReminders() {
    const search = this.search || "";

    return (this.vehicle.reminders as Array<Reminder>)
      .filter((it) => it.name.includes(search)
        || (it.comment || "").includes(search)
        || this.getMeterProgressText(it).includes(search)
        || this.getTimeProgressText(it).includes(search));
  }

  selectedStatuses: ReminderStatus[] = [ReminderStatus.ON_WATCH, ReminderStatus.DUE_SOON, ReminderStatus.OVERDUE];

  get chips() {
    return [
      {
        status: ReminderStatus.ON_WATCH,
        textColor: "white--text",
        backgroundColor: "primary",
      },
      {
        status: ReminderStatus.DUE_SOON,
        textColor: "white--text",
        backgroundColor: "warning",
      },
      {
        status: ReminderStatus.OVERDUE,
        textColor: "white--text",
        backgroundColor: "red",
      },
    ];
  }

  afterUpdateReminder() {
    window.location.reload();
  }

  shouldShowSubmitAction(reminder: Reminder) {
    return reminder.status == ReminderStatus.OVERDUE || reminder.status == ReminderStatus.DUE_SOON;
  }

  openEditVehicleReminderDialog(reminder: Reminder) {
    (this.$refs.createReminderDialog as any).openDialog(reminder);
  }

  openSubmitActionDialog(reminder: Reminder) {
    (this.$refs.submitActionDialog as any).openDialog(VehicleAction.createEmpty(reminder));
  }

  hasMeterProgress(reminder: Reminder) {
    if (reminder.lastActionOnTrackedType && !reminder.repeatEveryMeter) return false;
    return !!reminder.firstFireAtMeter;
  }

  getMeterProgressStyle(reminder: Reminder): any {
    if (!this.hasMeterProgress(reminder)) return {};

    return {
      width: `${this.getMeterProgressValue(reminder) * 100}%`,
    };
  }

  getMeterProgressValue(reminder: Reminder): number {
    const startPoint = Math.max(reminder.lastActionOnTrackedType?.doneAtMeterValue || 0, reminder.createdAtMeterValue || 0);
    const endPoint = Math.max(
      // @ts-ignore
      reminder.lastActionOnTrackedType?.doneAtMeterValue + reminder.repeatEveryMeter || 0,
      reminder.firstFireAtMeter || 0,
    );

    const currentPoint = reminder.trackedVehicle?.lastKnownOdometerValueForNow;

    return Math.min((currentPoint - startPoint) / (endPoint - startPoint), 1);
  }

  getMeterProgressLeft(reminder: Reminder): number {
    const endPoint = Math.max(
      // @ts-ignore
      reminder.lastActionOnTrackedType?.doneAtMeterValue + reminder.repeatEveryMeter || 0,
      reminder.firstFireAtMeter || 0,
    );

    const currentPoint = reminder.trackedVehicle?.lastKnownOdometerValueForNow;

    return Math.max(endPoint - currentPoint, 0);
  }

  getMeterProgressText(reminder: Reminder): string {
    const metterLeft = this.getMeterProgressLeft(reminder);
    return metterLeft == 0 ? "mileage reached" : `${metterLeft} mi left`;
  }

  hasTimeProgress(reminder: Reminder) {
    if (reminder.lastActionOnTrackedType && !reminder.repeatEvery?.valueInMilliseconds) return false;
    return !!reminder.firstFireAt;
  }

  getTimeProgressLeft(reminder: Reminder): number {
    const endPoint = moment(Math.max(
      // @ts-ignore
      new Date(reminder.lastActionOnTrackedType?.doneAt || "").valueOf() + reminder.repeatEvery?.valueInMilliseconds || 0,
      new Date(reminder.firstFireAt || "").valueOf() || 0,
    ));

    return Math.max(endPoint.diff(moment().startOf("day"), "days"), 0);
  }

  getTimeProgressText(reminder: Reminder): string {
    const daysLeft = this.getTimeProgressLeft(reminder);
    return daysLeft == 0 ? "time is up" : `${daysLeft} days left`;
  }

  getTimeProgressStyle(reminder: Reminder): any {
    if (!this.hasTimeProgress(reminder)) return {};

    return {
      width: `${this.getTimeProgressValue(reminder) * 100}%`,
    };
  }

  getTimeProgressValue(reminder: Reminder): number {
    const startPoint = Math.max(
      new Date(reminder.lastActionOnTrackedType?.doneAt || "").valueOf() || 0,
      new Date(reminder.createdAt).valueOf() || 0,
    );
    const endPoint = Math.max(
      // @ts-ignore
      new Date(reminder.lastActionOnTrackedType?.doneAt).valueOf() + reminder.repeatEvery?.valueInMilliseconds || 0,
      new Date(reminder.firstFireAt || "").valueOf() || 0,
    );

    return Math.min((moment().valueOf() - startPoint) / (endPoint - startPoint), 1);
  }

  getReminderCommentString(reminder: Reminder) {
    return (reminder.comment || "No comment").substring(0, 150);
  }

  getReminderBackgroundColor(reminder: Reminder) {
    if (reminder.status == ReminderStatus.DUE_SOON) return "due-soon-reminder-background";
    if (reminder.status == ReminderStatus.OVERDUE) return "overdue-reminder-background";
    return "";
  }

  selectStatus(status: ReminderStatus) {
    const index = this.selectedStatuses.findIndex((it) => it === status);

    if (index !== -1) {
      if (this.selectedStatuses.length === 1) return;

      this.selectedStatuses.splice(index, 1);
    } else {
      this.selectedStatuses.push(status);
    }
  }

  mounted() {
    getModule(RemindersModule, this.$store);
  }
}
