


























































import { Component, Vue } from "vue-property-decorator";
import { Trigger, TriggerStub } from "@/model/trigger/trigger.model";
import { TRIGGER_TYPES } from "@/utils/ts-utils";
import TriggerAgentsModule from "@/store/trigger-agents.store";
import TriggerActions from "@/components/triggers/trigger-edit-dialog/TriggerActions.vue";
import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import TriggersModule from "@/store/triggers.store";
import { TriggerType } from "@/types/types";

const triggerAgentsModule = TriggerAgentsModule.namespace;

const triggersModule = TriggersModule.namespace;

const locService = Vue.prototype.$localizationService;

@Component({
  components: {
    TriggerActions,
  },
})
export default class TriggerEditDialog extends Vue {
  TRIGGER_TYPES = TRIGGER_TYPES;

  locService = locService;

  trigger = TriggerStub.create();

  open = false;

  @triggerAgentsModule.State
  triggerTypeAgents!: any

  @triggersModule.Action
  addOrSaveTrigger!: (trigger: Trigger<any>) => Promise<Trigger<any> | undefined>;

  get title() {
    return this.trigger.id
      ? locService.localize("trigger_page.dialog.add_and_edit.edit_title")
      : locService.localize("trigger_page.dialog.add_and_edit.new_title");
  }

  get disableSave() {
    return !this.trigger.name
      // @ts-ignore
      || this.trigger.priority === ""
      || Number.isNaN(+this.trigger.priority)
      || !this.trigger.type;
    //      || !this.trigger.actionDraftTemplates.length;
  }

  async saveTrigger() {
    console.log(this.trigger);
    await this.addOrSaveTrigger(this.trigger);
    this.open = false;
  }

  onTriggerPropertiesUpdate(event: any) {
    Object.assign(this.trigger, event);
  }

  updateActionDraftTemplates(actions: ActionDraftTemplate[]) {
    this.trigger.actionDraftTemplates = [...actions];
  }

  changeTriggerType(value: TriggerType) {
    const old = this.trigger;

    this.trigger = Trigger.create(value);

    this.trigger.name = old.name;
    this.trigger.priority = old.priority;
  }

  openDialog(trigger: Trigger<any> | undefined) {
    if (trigger) {
      this.trigger = trigger.clone();
    } else {
      console.error("TODO");
      this.trigger = TriggerStub.create();
    }
    this.open = true;
    console.log(this.trigger.type);
    console.log(this.triggerTypeAgents);
  }
}
