














import { Component, Prop, Vue } from "vue-property-decorator";
import { IssueDataUnit } from "@/model/issue/data-unit.model";
import IssueAgentsModule from "@/store/issue-agents.store";

const locService = Vue.prototype.$localizationService;

const issueAgentsModule = IssueAgentsModule.namespace;

@Component({})
export default class AuthorAgent extends Vue {
  static agentName = "AuthorAgent"

  locService = locService;

  @Prop()
  dataUnits!: IssueDataUnit[]

  @issueAgentsModule.State
  authorAgents!: any
}
