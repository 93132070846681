

















import { Component, Prop, Vue } from "vue-property-decorator";
import { IssueDataUnitUser } from "@/model/issue/units/issue-data-unit-user.model";
import { getAbbreviation } from "@/utils/ts-utils";

@Component({})
export default class UserAuthorDataUnitAgent extends Vue {
  static agentName = "UserAuthorDataUnitAgent"

  getAbbreviation = getAbbreviation;

  @Prop()
  dataUnit!: IssueDataUnitUser
}
