<template>
  <FuelCards />
</template>

<script>
import FuelCards from "../components/fuel-cards/FuelCards";

export default {
  name: "FuelCardsPage",
  routing: {
    localizationKey: "menu.tab.fuel_cards",
    icon: "mdi-credit-card",
    path: "/fuelcards",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
  components: {
    FuelCards,
  },
};
</script>
