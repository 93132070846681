<template>
  <Dashboard grafana-path="/d/CIZYgv_Gz/daily-dashboard" />
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "HomePage",

  routing: {
    localizationKey: "menu.tab.custom_report",
    icon: "mdi-view-dashboard",
    path: "/",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Dashboard,
  },
};
</script>
