export class Driver {
    id;

    name;

    transporterId;

    email;

    phone;

    invitationStatus;

    createdAt;

    updatedAt;

    aliases;

    constructor(
      id,
      name,
      transporterId,
      email,
      phone,
      invitationStatus,
      createdAt,
      updatedAt,
      aliases,
    ) {
      this.id = id;
      this.name = name;
      this.transporterId = transporterId;
      this.email = email;
      this.phone = phone;
      this.invitationStatus = invitationStatus;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.aliases = aliases;
    }

    static fromDto(dto) {
      return new Driver(
        dto.id,
        dto.name,
        dto.transporterId,
        dto.email,
        dto.phone,
        dto.invitationStatus,
        dto.createdAt,
        dto.updatedAt,
        dto.aliases,
      );
    }
}
