





































import {
  Component, Emit, Prop, Vue,
} from "vue-property-decorator";
import lodash from "lodash";
import { NoCache } from "@/utils/decorators";

const READ_STATE = "READ";
const EDIT_STATE = "EDIT";

@Component({})
export default class ReadEditSelect extends Vue {
  READ_STATE = READ_STATE;

  EDIT_STATE = EDIT_STATE;

  state = READ_STATE;

  @Prop()
  item!: { text: string; value: string } | null;

  newItem = this.item;

  @Prop()
  disabled: boolean | undefined

  @Prop()
  items!: any[];

  @Prop()
  label!: string;

  @Prop()
  isMayBeEmpty!: boolean;

  @Prop()
  isDisplayLabelOnRead!: boolean;

  @NoCache
  get disableSave() {
    return (!this.newItem && !this.isMayBeEmpty)
      || (!this.item && !this.newItem)
      || lodash.isEqual(this.item, this.newItem);
  }

  get classes(): string[] {
    const classes = [];

    if (this.disableSave) classes.push("disable-save");

    if (!this.label) classes.push("pt-0");

    return classes;
  }

  onFocus() {
    const select = this.$refs.select as any;
    if (select) {
      select.activateMenu();
    }
  }

  @Emit("onChange")
  emitNewItem() {
    return this.newItem;
  }

  onChange(listItem: any) {
    this.newItem = listItem;
    this.saveNewValue();
  }

  editField(_: { fromMounted: boolean }) {
    this.state = EDIT_STATE;

    this.$nextTick(() => {
      const select = this.$refs.select as any;
      if (select && !_.fromMounted) {
        select.focus();
      }
    });
  }

  readField() {
    this.state = READ_STATE;
  }

  saveNewValue() {
    if (this.disableSave) return;

    this.emitNewItem();

    if (this.newItem) {
      this.readField();
    }
  }

  mounted() {
    if (!this.item) {
      this.editField({ fromMounted: true });
    }
  }
}
