import { render, staticRenderFns } from "./DriverInfractionsSubTable.vue?vue&type=template&id=25c681b0&scoped=true&"
import script from "./DriverInfractionsSubTable.vue?vue&type=script&lang=js&"
export * from "./DriverInfractionsSubTable.vue?vue&type=script&lang=js&"
import style0 from "./DriverInfractionsSubTable.vue?vue&type=style&index=0&id=25c681b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c681b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCol,VContainer,VDataTable,VIcon,VRow,VSimpleCheckbox})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
