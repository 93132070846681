import Vue from "vue";
import { Auth, Storage, API } from "aws-amplify";
// eslint-disable-next-line camelcase,@typescript-eslint/camelcase
import { v4 as uuid_v4 } from "uuid";
import {
  bodyWithAuthHeader,
  XLSX_TEMPLATE_TYPE,
  ROUTE_STAGING_IMPORT_MAPPING_TYPE,
} from "../utils/utils";
import { RouteStagingTemplate } from "../model/import-amazon-data/route-staging-template.model";
import { RouteStagingImportMappings } from "../model/import-amazon-data/route-staging-import-mappings.model";

export default {

  name: "importRouteStagingModule",
  namespaced: true,
  state: {
    fileKey: undefined,
  },
  mutations: {
    updateFileKey(state, key) {
      state.fileKey = key;
    },
  },
  actions: {
    async sendData({ commit, rootState }, { file, targetDate }) {
      const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const fileId = uuid_v4();

      const key = `cognito/dsps/${(await Auth.currentSession()).getIdToken().payload.sub}/${fileId}`;

      return Storage.put(key, file, {

        contentType,
        metadata: {
          id: fileId,
          "target-date": targetDate,
          uploader: rootState.userModule.user.id,
        },
      })
        .then(async () => {
          commit("updateFileKey", `public/${key}`);
        });
    },

    async sendTemplateSettings({ state }, { template }) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          binKey: state.fileKey,
          importTemplateId: template.id,
        },
      };

      try {
        return await API.post("core", "/drp/route_staging/begin", body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async getImportedFileParsingStatusById(context, reportId) {
      const body = await bodyWithAuthHeader();

      try {
        return await API.get("core", `/drp/route_staging/status/${reportId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async loadFilteredTemplates(context, searchString) {
      const body = await bodyWithAuthHeader();

      try {
        const templatesDto = await API.get("core", `/import/template?searchString=${searchString || ""}&targetType=${XLSX_TEMPLATE_TYPE}`, body);
        return templatesDto.map((template) => RouteStagingTemplate.fromDto(template));
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async addNewTemplate(context, template) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          name: template.name,
          rowsCountToSkipFromBegin: 0,
          rowsCountToSkipFromEnd: 0,
          shouldReadOnlyVisibleRows: false,
          targetSheetName: "",
        },
      };

      try {
        const templateDto = await API.post("core", `/import/template/${XLSX_TEMPLATE_TYPE}`, body);
        return RouteStagingTemplate.fromDto(templateDto);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async updateTemplate(context, template) {
      const body = { ...await bodyWithAuthHeader(), body: template };

      try {
        const templateDto = await API.put("core", `/import/template/${template.id}`, body);
        return RouteStagingTemplate.fromDto(templateDto);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async deleteTemplate(context, template) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `/import/template/${template.id}`, body);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
    },

    async initiationMapping(context, { reportId, modelMappingsId }) {
      const body = { ...await bodyWithAuthHeader(), body: { modelMappingsId } };

      try {
        return await API.post("core", `/drp/route_staging/map/${reportId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async loadFilteredModelMappings(context, searchString) {
      const body = await bodyWithAuthHeader();

      try {
        const mappingsDto = await API.get("core",
          `/import/mappings?searchString=${searchString || ""}&targetType=${ROUTE_STAGING_IMPORT_MAPPING_TYPE}`, body);
        return mappingsDto.map((mapping) => RouteStagingImportMappings.fromDto(mapping));
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async addNewModelMapping(context, modelMapping) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          name: modelMapping.name,
          mappings: {},
        },
      };

      try {
        const mappingDto = await API.post("core", `/import/mappings/${ROUTE_STAGING_IMPORT_MAPPING_TYPE}`, body);
        return RouteStagingImportMappings.fromDto(mappingDto);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async updateModelMapping(context, modelMapping) {
      const body = { ...await bodyWithAuthHeader(), body: modelMapping };

      try {
        const mappingDto = await API.put("core", `/import/mappings/${modelMapping.id}`, body);
        return RouteStagingImportMappings.fromDto(mappingDto);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async deleteModelMapping(context, modelMapping) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `/import/mappings/${modelMapping.id}`, body);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
    },

    async applyImportSession(context, { reportId, shouldReplaceStaging }) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          shouldReplaceStaging,
        },
      };

      try {
        await API.post("core", `/drp/route_staging/apply/${reportId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
    },
  },
};
