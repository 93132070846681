import { API } from "aws-amplify";
import {
  VuexModule, Module, Mutation, Action,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";

import { AssistantConstant, AssistantConstantDto } from "@/model/assistant-constant.model";
import { bodyWithAuthHeader, handleHttpError, REQUEST_CANCEL_MESSAGE } from "@/utils/utils";

const moduleName = "AssistantConstantModule";

@Module({ namespaced: true, name: moduleName })
export default class AssistantConstantModule extends VuexModule {
  constants: AssistantConstant[] = [];

  currentRequest: any;

  static get namespace() {
    return namespace("AssistantConstantModule");
  }

  @Mutation
  updateConstants(constants: AssistantConstant[]) {
    this.constants = constants;
  }

  @Action({ rawError: true })
  async loadConstants() {
    const body = { ...await bodyWithAuthHeader() };

    if (this.currentRequest) {
      API.cancel(this.currentRequest, REQUEST_CANCEL_MESSAGE);
    }

    this.currentRequest = API.get("core", "/messaging/template-constants/assistant", body);

    try {
      const dto = await this.currentRequest;

      this.context.commit(
        "updateConstants",
        dto.map((it: AssistantConstantDto) => AssistantConstant.fromDto(it, undefined)),
      );
    } catch (e: any) {
      if (e.message === REQUEST_CANCEL_MESSAGE) {
        console.log(e);
      } else {
        handleHttpError(e);
      }
    }
  }
}
