<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-data-table
          class="scans-table-display"
          :headers="headers"
          :items="displayScans"
          :loading="currentPageLoading"
          :server-items-length="totalCount"
          @update:options="paginate($event)"
          :footer-props="footerProps"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{
                  $localizationService.localize(
                    "coaching_page.scans_table.title"
                  )
                }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openRequestScanDialog()">
                {{ $localizationService.localize("btn.scan") }}
              </v-btn>
              <CoachingMenu class="ml-2" />
              <RequestScanDialog ref="requestScanDialog" />
            </v-toolbar>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div v-if="item.status == 'COMPLETE'" class="green--text">
              {{
                $localizationService.localize(
                  "coaching_page.scans_table.status.complete"
                )
              }}
              <v-icon size="1.25em" color="green" class="ml-1 mb-1"
                >mdi-check</v-icon
              >
            </div>
            <div v-else-if="item.status == 'ENGINE_ERROR'" class="red--text">
              {{
                $localizationService.localize(
                  "coaching_page.scans_table.status.engine_error"
                )
              }}
              <v-icon size="1.25em" color="red" class="ml-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
            </div>
            <div v-else class="primary--text">
              {{
                $localizationService.localize(
                  "coaching_page.scans_table.status.processing"
                )
              }}
              <v-progress-circular
                :size="15"
                :width="2"
                class="ml-1 mb-1"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>

          <template v-slot:[`item.newInfractions`]="{ item }">
            {{
              item.status == "COMPLETE" ? item.createdInfractionIds.length : "-"
            }}
          </template>

          <template v-slot:[`item.scanTimeRange`]="{ item }">
            {{ momentDateTime(item.fromTime) }}
            <div class="range-delimeter">-</div>
            {{ momentDateTime(item.toTime) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import RequestScanDialog from "./RequestScanDialog.vue";
import { paginateTable, dateTimeMomentFormat } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "ScansTable",

  components: {
    RequestScanDialog,
    CoachingMenu: () => import("./CoachingMenu.vue"),
  },

  data: () => ({
    localStorageTable: "scans.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },
  }),

  computed: {
    ...mapState("scanModule", [
      "currentPageLoading",
      "currentPage",
      "displayScans",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),

    headers: () => [
      {
        text: locService.localize(
          "coaching_page.scans_table.header.created_at",
        ),
        value: "createdAt",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize("coaching_page.scans_table.header.status"),
        value: "status",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.scans_table.header.new_infractions",
        ),
        value: "newInfractions",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.scans_table.header.scan_time_range",
        ),
        value: "scanTimeRange",
        sortable: false,
        align: "start",
      },
    ],
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTable,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.$store.dispatch("scanModule/loadPage", currentPage);
      this.tableInited = true;
    },
    reloadPage() {
      this.$store.dispatch("scanModule/reloadPage");
    },
    momentDateTime(date) {
      return date ? moment(date).format(dateTimeMomentFormat) : "";
    },
    openRequestScanDialog(scan) {
      this.$refs.requestScanDialog.openDialog(scan);
    },
  },
};
</script>

<style scoped lang="scss">
.range-delimeter {
  margin: 0 0.3em !important;
  font-weight: bold;
  display: inline-block;
}
</style>
