import { IssueDataUnitToDto, IssueUnitType } from "@/types/types";

export abstract class IssueDataUnit {
  constructor(
      public id: string,
      public type: IssueUnitType,
  ) { }

  toDto(): undefined | IssueDataUnitToDto {
    return undefined;
  }
}
