import { createDecorator } from "vue-class-component";

export const NoCache = createDecorator((options, key) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  options.computed[key].cache = false;
});

// If you want to set deep to true when watching an array
// or object so that Vue knows that it should watch the nested data for changes.
export const DeepWatch = (targetPropertyName: string) => createDecorator((options, key) => {
  // @ts-ignore
  const foundWatch = options.watch[targetPropertyName].find((it) => it.handler === key);
  foundWatch.deep = true;
});
