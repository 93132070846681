/* eslint-disable no-underscore-dangle */
import { API } from "aws-amplify";
import axios from "axios";
import sum from "lodash/sum";
import { bodyWithAuthHeader, handleHttpError, ROCKET_CHAT_API } from "../utils/utils";

const cache = (key, value) => {
  if (typeof value == "undefined") {
    return cache[key];
  }
  return cache[key] = value;
};

export default {

  name: "rocketChatModule",
  namespaced: true,
  state: {
    authed: false,
    iframe: undefined,
    token: undefined,
    userId: undefined,
    channels: [],
    chatOpened: false,
    handledEventIds: [],
    selectedChannel: undefined,
    directMessagingChannel: undefined,
    menuOpened: false,
    loadingDirectChannel: false,
  },

  mutations: {
    updateToken(state, token) {
      state.token = token;
    },

    updateUserId(state, userId) {
      state.userId = userId;
    },

    endAuth(state) {
      state.authed = true;
    },

    changeChatOpenedState(state, value) {
      state.chatOpened = value;
    },

    initIframe(state, iframe) {
      state.iframe = iframe;
      state.iframe.contentWindow.postMessage({
        externalCommand: "login-with-token",
        token: state.token,
      }, "*");
    },

    addNewChannel(state, { channel, eventId }) {
      state.handledEventIds.push(eventId);
      state.channels.push(channel);
    },

    updateHandledEventIds(state, eventId) {
      state.handledEventIds.push(eventId);
    },

    clearAllUnreadMessages(state) {
      state.channels.forEach((it) => it.unread = 0);
    },

    setSelectedChannel(state, selectedChannel) {
      state.selectedChannel = selectedChannel;
    },

    updateDirectMessagingChannel(state, channel) {
      state.directMessagingChannel = channel;
    },

    updateMenuOpenedState(state, value) {
      state.menuOpened = value;
    },

    updateLoadingDirectChannel(state, value) {
      state.loadingDirectChannel = value;
    },
  },

  actions: {

    async postReaction({ getters }, { messageId, emoji, shouldReact }) {
      await axios.post(`${ROCKET_CHAT_API}/api/v1/chat.react`,
        {
          messageId,
          emoji,
          shouldReact,
        }, { ...getters.authHeader });
    },

    async postMessage({ getters }, { roomId, text }) {
      return axios.post(`${ROCKET_CHAT_API}/api/v1/chat.postMessage`,
        {
          roomId,
          text,
        },
        { ...getters.authHeader });
    },

    goToChannel({ dispatch }, channel) {
      dispatch("goTo", `/group/${channel}`);
    },

    goToHome({ dispatch }) {
      dispatch("goTo", "/home");
    },

    goTo({ state }, path) {
      state.iframe.contentWindow.postMessage({
        externalCommand: "go",
        path,
      }, "*");
    },

    async loadUserData({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const data = await API.get("core", "/messaging/auth_token", body);
        commit("updateToken", data.token);
        commit("updateUserId", data.userId);
      } catch (e) {
        handleHttpError(e);
      }
    },

    async getDirectMessagingChannel({ commit }, channelKey) {
      const body = await bodyWithAuthHeader();
      try {
        commit("updateLoadingDirectChannel", true);
        const data = await API.get("core", `/messaging-channel/${channelKey}`, body);
        commit("updateDirectMessagingChannel", data || undefined);
        commit("updateLoadingDirectChannel", false);
      } catch (e) {
        handleHttpError(e);
      }
    },

    async getCustomDirectChannel({ dispatch, commit, state }, target) {
      const body = await bodyWithAuthHeader();
      let channelId;
      try {
        if (!cache(target)) {
          channelId = await API.get("core", `${target}`, body);
          cache(target, channelId);
        } else {
          channelId = cache(target);
        }
      } catch (e) {
        handleHttpError(e);
      }
      const channelName = (state.channels.find((it) => it.rid === channelId)
          || await dispatch("getChannelInfo", channelId)).name;
      dispatch("goToChannel", channelName);
      commit("changeChatOpenedState", true);
    },

    // WARNING: can be performance issue
    // CAN BE SOLVED BY: refactor to parallel processing
    async loadChannelsUnreadMessages({ state, dispatch }) {
      for (let i = 0; i < state.channels.length; i++) {
        const channel = state.channels[i];
        if (channel.alert) {
          // eslint-disable-next-line no-await-in-loop
          channel.unread = await dispatch("getUnreadMessagesCountForChannel", channel.rid);
        }
      }
    },

    async getUnreadMessagesCountForChannel({ getters }, roomId) {
      try {
        const http = axios.create({
          ...getters.authHeader,
          baseURL: `${ROCKET_CHAT_API}/api/v1/groups.counters?roomId=${roomId}`,
        });
        const { data } = await http.get();
        return data.unreads;
      } catch (e) {
        handleHttpError(e);
      }
      return 0;
    },

    async getChannelInfo({ getters }, roomId) {
      try {
        const http = axios.create({
          ...getters.authHeader,
          baseURL: `${ROCKET_CHAT_API}/api/v1/groups.info?roomId=${roomId}`,
        });
        const { data } = await http.get();
        return data.group;
      } catch (e) {
        handleHttpError(e);
      }
      return 0;
    },
  },

  getters: {
    authHeader: (state) => ({
      headers: {
        "X-Auth-Token": state.token,
        "X-User-Id": state.userId,
      },
    }),

    unreadMessagesCount: (state) => sum(state.channels.map((it) => it.unread)),

    recipientId(state) {
      if (!state.directMessagingChannel) {
        return null;
      }
      const driverTag = state.directMessagingChannel.tags.find((it) => it.startsWith("audience"));

      if (!driverTag) return null;

      return driverTag.substring(9, driverTag.length);
    },

    deviceRecipientId(state) {
      if (!state.directMessagingChannel) {
        return null;
      }
      const deviceTag = state.directMessagingChannel.tags.find((it) => it.startsWith("device audience"));

      if (!deviceTag) return null;

      return deviceTag.substring("device audience ".length, deviceTag.length);
    },
  },
};
