import lodash from "lodash";
import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import {
  SimpleValueCondition, VehicleChangedTriggerDto, TriggerType, TriggerTypes,
} from "@/types/types";
import { TRIGGER_TYPES } from "@/utils/ts-utils";
import { actionDraftTemplateClasses, Trigger, triggerTypesClasses } from "./trigger.model";

export class VehicleChangedTrigger extends Trigger<VehicleChangedTrigger> {
  constructor(
    id: string,
    name: string,
    type: TriggerType | null,
    priority: number,
    actionDraftTemplates: ActionDraftTemplate[],
    public fromValueCondition: string,
    public toValueCondition: string,
  ) {
    super(id, name, type, priority, actionDraftTemplates);
  }

  get parsedFromValueCondition(): SimpleValueCondition | null {
    try {
      return JSON.parse(this.fromValueCondition);
    } catch (e) {
      console.log("Incorrect format for parse fromValueCondition");
      return null;
    }
  }

  get parsedToValueCondition(): SimpleValueCondition | null {
    try {
      return JSON.parse(this.toValueCondition);
    } catch (e) {
      console.log("Incorrect format for parse toValueCondition");
      return null;
    }
  }

  toDto() {
    return {
      id: this.id,
      name: this.name,
      type: this.type?.value,
      priority: this.priority,
      fromValueCondition: this.fromValueCondition,
      toValueCondition: this.toValueCondition,
      actionDraftTemplates: this.actionDraftTemplates.map((it) => it.toDto()),
    };
  }

  clone() {
    return new VehicleChangedTrigger(
      this.id,
      this.name,
      this.type,
      this.priority,
      [...this.actionDraftTemplates],
      this.fromValueCondition,
      this.toValueCondition,
    );
  }

  static fromDto(dto: VehicleChangedTriggerDto): VehicleChangedTrigger {
    return new VehicleChangedTrigger(
      dto.id,
      dto.name,
      lodash.keyBy(TRIGGER_TYPES, "value")[dto.type],
      dto.priority,
      dto.actionDraftTemplates.map((it) => actionDraftTemplateClasses[it.type].fromDto(it)),
      dto.fromValueCondition,
      dto.toValueCondition,
    );
  }

  static create(): VehicleChangedTrigger {
    return this.createEmpty();
  }

  static createEmpty(): VehicleChangedTrigger {
    return new VehicleChangedTrigger(
      "",
      "",
      TRIGGER_TYPES.find((it) => it.value == TriggerTypes.VehicleChangedTrigger) || null,
      0,
      [],
      "",
      "",
    );
  }
}

triggerTypesClasses[TriggerTypes.VehicleChangedTrigger] = VehicleChangedTrigger;
