<template>
  <v-container
    fluid
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
    class="daily-route-planner-page-container"
  >
    <ProminentStats :pRoutePlan="routePlan" />
    <v-toolbar flat class="full-width toolbar-component">
      <v-toolbar-title
        :class="{ 'mobile-toolbar': IS_MOBILE }"
        class="toolbar-title d-flex flex-row align-center w-100"
      >
        {{
          IS_MOBILE
            ? $localizationService.localize("drp.main_component.not_full_name")
            : $localizationService.localize("drp.main_component.full_name")
        }}
        <div class="d-flex flex-row align-center ml-2">
          <v-btn
            v-if="!IS_MOBILE"
            icon
            class="small-icon-button-date"
            @click="decreaseDate()"
          >
            <v-icon> mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu
            v-model="datePickerDialog"
            :nudge-right="50"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="!IS_MOBILE"
                :value="drpFormattedTargetDate"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                error-count="0"
              ></v-text-field>
              <v-btn v-if="IS_MOBILE" v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="targetDate"
              @input="
                datePickerDialog = false;
                reloadPage();
              "
            ></v-date-picker>
          </v-menu>
          <v-btn
            v-if="!IS_MOBILE"
            icon
            class="small-icon-button-date"
            @click="increaseDate()"
          >
            <v-icon> mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <TableSettings
          :pIsVisible="pageIsMounted && !isDefaultSettings"
          :pIsDisabled="!routePlan.id"
          :pSaveButtonTooltip="
            $localizationService.localize(
                'drp.main_component.settings_tooltip.save'
            )
          "
          :pDefaultButtonTooltip="
            $localizationService.localize(
                'drp.main_component.settings_tooltip.apply_default'
            )
          "
          @onSaveSettings="saveSettings()"
          @onApplyDefaultSettings="applyDefaultSettings()"
        />
        <v-spacer v-if="!IS_MOBILE"></v-spacer>
        <div class="drp-actions d-flex justify-end align-center w-100">
          <div class="buttons-block">
            <div class="text-caption text--secondary text-center buttons-block-caption">Filters</div>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="badgeContent"
                  :value="badgeContent"
                  color="red"
                  left
                  overlap
                >
                  <v-btn-toggle
                    v-model="filterPanelIsOpened"
                    color="primary"
                    group
                    class="btn-toggle-border"
                  >
                    <v-btn
                      :value="true"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class="ma-0"
                      :disabled="!routePlan.id"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-badge>
              </template>
              <span>
                {{
                  $localizationService.localize(
                    "drp.main_component.tooltip_filter"
                  )
                }}
              </span>
            </v-tooltip>
          </div>
          <v-divider vertical class="ml-2 mr-2"></v-divider>
          <div class="buttons-block">
            <div class="text-caption text--secondary text-center buttons-block-caption">Imports</div>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="mr-2 route-staging-btn"
                  :disabled="!routePlan.id"
                >
                  <v-icon class="route-staging-btn">
                    mdi-swap-horizontal-variant
                  </v-icon>
                  <input
                    @input="handleFiles"
                    type="file"
                    name="upload"
                    class="file-input route-staging-btn"
                    multiple
                    accept=".xlsx"
                  />
                </v-btn>
              </template>
              <span>
                {{
                  $localizationService.localize(
                    "drp.main_component.tooltip_import_routes"
                  )
                }}
              </span>
            </v-tooltip>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="openImportDialog(DRP_IMPORT_AMAZON_LOGISTICS)"
                  icon
                  :disabled="!routePlan.id"
                >
                  <v-icon> mdi-truck-outline</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  $localizationService.localize(
                    "drp.main_component.tooltip_import_amazon_logistics"
                  )
                }}
              </span>
            </v-tooltip>
          </div>
          <v-divider vertical class="ml-2 mr-2"></v-divider>
          <div class="buttons-block">
          <div class="text-caption text--secondary text-center buttons-block-caption">Utils</div>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="printThePlan()"
                  icon
                  class="mr-2"
                  :disabled="!routePlan.id"
                >
                  <v-icon> mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>{{$localizationService.localize('drp.main_component.print_drp')}}</span>
            </v-tooltip>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="sendBulkMessage()"
                  icon
                  :disabled="!routePlan.id || selectedTab === DRP_RESCUE_TAB"
                >
                  <v-icon>mdi-message-text</v-icon>
                </v-btn>
              </template>
              <span>Send bulk message to drivers in drp</span>
            </v-tooltip>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="openInspectionsAssignDialog()"
                  icon
                  :disabled="!routePlan.id || selectedTab === DRP_RESCUE_TAB"
                >
                  <v-icon>mdi-content-paste</v-icon>
                </v-btn>
              </template>
              <span>Assign inspections to all vehicles in drp</span>
            </v-tooltip>
          </div>
          <v-divider vertical class="ml-2 mr-2"></v-divider>
          <v-btn
            class="ml-4"
            color="primary"
            :loading="pageLoading"
            @click="buttonAction()"
          >
            {{ buttonText }}
          </v-btn>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="selectedTabModel" @change="updateRoutePath()">
      <v-tab
        v-for="tab in tabs"
        :key="tab.value"
        active-class="primary--text"
        :disabled="!routePlan.id"
      >
        {{ tab.text }}
      </v-tab>
      <div class="w-100 d-flex align-center justify-end pr-4">
        <RefreshData
          :pAutoRefreshBtnTooltip="$localizationService.localize('drp.refresh.auto')"
          :pRefreshBtnTooltip="$localizationService.localize('drp.refresh')"
          pTimestampLocalStorageKey="drp.timestamp.storage.key"
          @onRefresh="$store.dispatch('dailyRoutePlannerModule/reloadPage')"
        />
        <v-progress-circular
          v-if="synchronization"
          :size="22"
          :width="3"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-icon color="green" v-if="!synchronization">
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </div>
    </v-tabs>
    <v-card
      v-show="filterPanelIsOpened"
      elevation="0"
      class="pa-4 pt-2 pb-2 filter-panel"
    >
      <div
        class="w-100 filter-panel-container"
        :class="{ 'filter-panel-on-mobile': IS_MOBILE }"
      >
        <div class="d-flex align-center">
          <span
            v-if="printingDrpMode && (filterState.criteriaCount || searchString)"
            class="black--text no-wrap mr-2"
          >
            {{$localizationService.localize("drp.print.filtered_by")}}:
          </span>
          <InfractionCriteriaFilter
            :driverEntries="routePlan.driverEntries || []"
            :additionalDataPerDriver="additionalDataPerDriver"
            @onSelectCriteria="onSelectCriteria($event)"
            ref="infractionCriteriaFilter"
          />
        </div>
        <v-text-field
          class="search-field pt-0 w-100"
          :class="[{ 'search-field-on-mobile': IS_MOBILE }, {'d-none': printingDrpMode && !searchString}]"
          :label="searchLabelText"
          hide-details
          v-model="searchString"
          @input="updateRoutePath()"
        >
        </v-text-field>
      </div>
    </v-card>
    <v-overlay
      :absolute="true"
      opacity="1"
      color="grafanaOverlayColor"
      :value="overlayEnabled"
      class="drp-overlay"
    >
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <DailyRoutePlannerRoutes
      v-if="selectedTab === DRP_ROUTES_TAB"
      :routePlan="routePlan"
      :pageLoading="pageLoading"
      :targetDate="targetDate"
      :searchString="searchString"
      :filterState="filterState"
      :driverEntries="routePlan.driverEntries"
      :rows="routePlan.rows"
      :additionalDataPerDriver="additionalDataPerDriver"
      :filterPanelIsOpened="filterPanelIsOpened"
      :printingDrpMode="printingDrpMode"
      :pageIsMounted="pageIsMounted"
      @onUpdateSort="onUpdateSort();"
    />
    <DailyRoutePlannerDrivers
      v-if="selectedTab === DRP_DRIVERS_TAB"
      :pageLoading="pageLoading"
      :searchString="searchString"
      :filterState="filterState"
      :driverEntries="routePlan.driverEntries"
      :additionalDataPerDriver="additionalDataPerDriver"
      :filterPanelIsOpened="filterPanelIsOpened"
      :printingDrpMode="printingDrpMode"
      @onUpdateSort="onUpdateSort()"
    />
    <DailyRoutePlannerRescues
      v-if="selectedTab === DRP_RESCUE_TAB"
      :pTargetDate="targetDate"
      :pPageLoading="pageLoading"
      :pRoutePlan="routePlan"
      :pAdditionalDataPerDriver="additionalDataPerDriver"
      :pSearchString="searchString"
      :pFilterState="filterState"
      :pFilterPanelIsOpened="filterPanelIsOpened"
      :pPrintingDrpMode="printingDrpMode"
      @onUpdateSort="onUpdateSort()"
    />
    <ImportStepper ref="ImportStepper" :pTargetDate="targetDate"/>
    <CreateEntryDialog
      ref="createEntryDialog"
      @afterCreateEntry="updateRowsBySelectedCriteriaFilter()"
      :driverEntries="routePlan.driverEntries"
    />
    <CreateRescueDialog
      ref="createRescueDialog"
      :pRoutePlan="routePlan"
      :pAdditionalDataPerDriver="additionalDataPerDriver"
    />
    <BulkMessageDialog ref="bulkMessageDialog" />
    <VehicleInspectionAssignDialog ref="vehicleInspectionAssignDialog" @assignmentCreated="realodAssignments()"/>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Vue from "vue";
import lodash from "lodash";
import TableSettings from "@/components/TableSettings.vue";
import InfractionCriteriaFilter from "@/components/route-planner/criteria-filter/InfractionCriteriaFilter.vue";
import DailyRoutePlannerRoutes from "@/components/route-planner/routes/DailyRoutePlannerRoutes.vue";
import DailyRoutePlannerDrivers from "@/components/route-planner/driver-entries/DailyRoutePlannerDrivers.vue";
import ImportStepper from "@/components/route-planner/import-amazon-data/ImportStepper.vue";
import DailyRoutePlannerRescues from "../components/route-planner/rescues/DailyRoutePlannerRescues";
import CreateRescueDialog from "@/components/route-planner/rescues/CreateRescueDialog.vue";
import {
  dateMomentFormat,
  defaultDateFormat,
  DRP_ROUTES_TAB,
  DRP_DRIVERS_TAB,
  IS_MOBILE,
  DRP_IMPORT_AMAZON_LOGISTICS,
  DRP_IMPORT_ROUTE_STAGING,
  DRP_RESCUE_TAB,
} from "../utils/utils";
import CreateEntryDialog from "../components/route-planner/driver-entries/CreateEntryDialog.vue";
import ProminentStats from "../components/route-planner/ProminentStats";
import RefreshData from "../components/RefreshData";
import BulkMessageDialog from "../components/messaging/BulkMessageDialog.vue";
import VehicleInspectionAssignDialog from "../components/vehicle-inspections/VehicleInspectionAssignDialog.vue";

const locService = Vue.prototype.$localizationService;

export default {
  name: "DailyRoutePlannerPage",

  routing: {
    localizationKey: "menu.tab.drp",
    icon: "mdi-map",
    path: "/planner",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    InfractionCriteriaFilter,
    DailyRoutePlannerRoutes,
    DailyRoutePlannerDrivers,
    ImportStepper,
    CreateEntryDialog,
    TableSettings,
    ProminentStats,
    DailyRoutePlannerRescues,
    CreateRescueDialog,
    RefreshData,
    BulkMessageDialog,
    VehicleInspectionAssignDialog,
  },

  data: () => ({
    DRP_IMPORT_AMAZON_LOGISTICS,
    DRP_IMPORT_ROUTE_STAGING,
    IS_MOBILE,
    DRP_ROUTES_TAB,
    DRP_DRIVERS_TAB,
    DRP_RESCUE_TAB,

    overlayEnabled: false,
    printingDrpMode: false,

    selectedTabModel: 0,

    filterPanelIsOpened: false,

    pageIsMounted: false,

    searchString: "",

    filterState: {
      driverIds: [],
      criteriaCount: 0,
    },

    datePickerDialog: false,
    targetDate: moment().format(defaultDateFormat),

    tableSettingsModules: [
      "drpDriversTableSettingsModule",
      "drpRoutesTableSettingsModule",
      "drpRescuersTableSettingsModule",
    ],

    settingsModules: [
      "drpDriversTableSettingsModule",
      "drpRoutesTableSettingsModule",
      "drpFilterPanelTableSettingsModule",
      "drpRescuersTableSettingsModule",
    ],

    tabs: [
      {
        text: locService.localize("drp.main_component.tab.routes"),
        value: DRP_ROUTES_TAB,
        tabIndex: 0,
        searchLabelText: locService.localize(
          "drp.main_component.search_label_text.search_rows",
        ),
        buttonText: locService.localize("drp.main_component.button_text.add_row"),
      },
      {
        text: locService.localize("drp.main_component.tab.drivers"),
        value: DRP_DRIVERS_TAB,
        tabIndex: 1,
        searchLabelText: locService.localize(
          "drp.main_component.search_label_text.search_drivers",
        ),
        buttonText: locService.localize("drp.main_component.button_text.add_driver"),
      },
      {
        text: locService.localize("drp.main_component.tab.rescues"),
        value: DRP_RESCUE_TAB,
        tabIndex: 2,
        searchLabelText: "Search Rescue",
        buttonText: "Add Rescue",
      },
    ],
  }),

  watch: {
    "$route.params.date": function () {
      if (!this.$route.params.date) {
        this.targetDate = moment().format(defaultDateFormat);
        this.updateRoutePath();
        this.reloadPage();
        return;
      }

      if (this.targetDate != this.$route.params.date) {
        this.targetDate = this.$route.params.date;
        this.reloadPage();
      }
    },
  },

  computed: {
    ...mapState("dailyRoutePlannerModule", [
      "routePlan",
      "pageLoading",
      "synchronization",
      "additionalDataPerDriver",
    ]),
    ...mapState("coreModule", ["drawer"]),

    ...mapState("drpDriversTableSettingsModule", [
      "driversSort",
    ]),
    ...mapState("drpRoutesTableSettingsModule", [
      "routesSort",
    ]),
    ...mapState("drpFilterPanelTableSettingsModule", [
      "criteriaFilter",
    ]),
    ...mapState("drpRescuersTableSettingsModule", [
      "rescuersSort",
    ]),

    isDefaultSettings() {
      return !this.settingsModules.map((module) => (
        this.$store.getters[`${module}/isDefaultSettings`]
      )).filter((it) => it === false).length;
    },

    drpFormattedTargetDate() {
      return moment(this.targetDate).format(dateMomentFormat);
    },

    buttonText() {
      if (IS_MOBILE) return locService.localize("drp.main_component.button_text.add");
      return this.tabs.find((it) => it.value === this.selectedTab).buttonText;
    },

    searchLabelText() {
      return this.tabs.find((it) => it.value === this.selectedTab).searchLabelText;
    },

    selectedTab() {
      return this.tabs.find((it) => it.tabIndex === this.selectedTabModel).value;
    },

    badgeContent() {
      return this.filterState.criteriaCount + +!!this.searchString;
    },

  },

  methods: {

    openInspectionsAssignDialog() {
      this.$refs.vehicleInspectionAssignDialog.openDialog({
        targetVehicles: lodash.flatten([this.routePlan.rows.map((it) => it.vehicle),
          this.routePlan.allRescuers.map((it) => it.rescuer.vehicle)]).filter((it) => it),
      });
    },

    sendBulkMessage() {
      switch (this.selectedTab) {
        case DRP_ROUTES_TAB:
          // eslint-disable-next-line no-case-declarations
          const allDrivers = lodash.flatten([this.routePlan.rows.map((it) => (it.currentDriverEntry || {}).driver),
            this.routePlan.allRescuers.map((it) => it.rescuer.driver)]).filter((it) => it);
          this.$refs.bulkMessageDialog.subTitle = locService.localize("bulk_message_dialog.subtitle_routes");
          this.$refs.bulkMessageDialog.openDialog(lodash.uniqBy(allDrivers, (it) => it.id));
          break;
        case DRP_DRIVERS_TAB:
          this.$refs.bulkMessageDialog.subTitle = locService.localize("bulk_message_dialog.subtitle_drivers");
          this.$refs.bulkMessageDialog.openDialog(this.routePlan.driverEntries.map((it) => it.driver));
          break;
      }
    },

    applyDefaultSettings() {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/applyDefaultSettings`);
      });
      this.$refs.infractionCriteriaFilter.applyDefaultFilters();
    },

    onUpdateSort() {
      if (this.pageIsMounted) {
        this.updateRoutePath();
      }
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      if (this.searchString) {
        params.append("search", this.searchString);
      }

      params.append("criteriaFilter", JSON.stringify(this.criteriaFilter));

      this.tableSettingsModules.forEach((module) => {
        const sortPropertyName = this.$store.state[module].sortPropertyName;
        params.append(sortPropertyName, JSON.stringify(this[sortPropertyName]));
      });

      const newPath = `/planner/${this.targetDate}/${this.selectedTab}/`;

      if (
        this.$route.path !== newPath
          || this.$route.query.search !== (params.get("search") || undefined)
          || this.$route.query.criteriaFilter !== (params.get("criteriaFilter") || undefined)
          || this.tableSettingsModules.map((module) => {
            const sortPropertyName = this.$store.state[module].sortPropertyName;
            return this.$route.query[sortPropertyName] === (params.get(sortPropertyName) || undefined);
          }).filter((it) => it === false).length
      ) {
        this.$router.push(`${newPath}?${params.toString()}`);
      }
    },

    saveSettings() {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/saveSettings`);
      });
      Vue.toasted.success(
        "Settings have been saved!",
        { duration: 3000 },
      );
    },

    onSelectCriteria(data) {
      this.filterState = {
        driverIds: data.driverIds,
        criteriaCount: data.criteriaCount,
      };

      this.$store.commit(
        "drpFilterPanelTableSettingsModule/changeCriteriaFilter",
        data.criteriaRouteParams,
      );

      this.updateRoutePath();
    },

    async loadPage() {
      if (this.$route.params.date != this.targetDate) this.updateRoutePath();

      await this.$store.dispatch(
        "dailyRoutePlannerModule/loadPage",
        this.targetDate,
      );
    },

    reloadPage: lodash.debounce(async function () {
      await this.loadPage();

      this.updateRowsBySelectedCriteriaFilter();
    }, 500),

    async buttonAction() {
      if (this.selectedTab === DRP_ROUTES_TAB) {
        await this.$store.dispatch("dailyRoutePlannerModule/addRow", {
          targetDate: this.targetDate,
        });
      } else if (this.selectedTab === DRP_DRIVERS_TAB) {
        this.$refs.createEntryDialog.openDialog(this.targetDate);
      } else if (this.selectedTab === DRP_RESCUE_TAB) {
        this.$refs.createRescueDialog.openDialog();
      }
    },

    updateRowsBySelectedCriteriaFilter() {
      if (this.$refs.infractionCriteriaFilter) {
        this.$refs.infractionCriteriaFilter.updateRowsBySelectedCriteriaFilter();
      }
    },

    parseCriteriaFromUrl() {
      try {
        const parsedData = JSON.parse(this.$route.query.criteriaFilter);
        this.$refs.infractionCriteriaFilter.parseCriteriaFromUrl(parsedData);
      } catch (e) {
        Vue.toasted.error(
          locService.localize("drp.main_component.incorrect_filter_in_url"),
          { duration: 5000 },
        );
        this.updateRoutePath();
        console.log(e);
      }
    },

    decreaseDate() {
      this.targetDate = moment(this.targetDate)
        .add({ day: -1 })
        .format(defaultDateFormat);

      this.reloadPage();
    },

    increaseDate() {
      this.targetDate = moment(this.targetDate)
        .add({ day: 1 })
        .format(defaultDateFormat);

      this.reloadPage();
    },

    openImportDialog(importMode, files) {
      this.$refs.ImportStepper.openDialog(importMode, files);
    },

    async handleFiles(event) {
      if (event.target.files.length) {
        this.openImportDialog(DRP_IMPORT_ROUTE_STAGING, event.target.files);
      }
      event.target.value = "";
    },

    printThePlan() {
      this.overlayEnabled = true;
      this.$store.commit("coreModule/hideDrawerBeforePrint");
      setTimeout(() => {
        this.printingDrpMode = true;
      }, 200);
      setTimeout(() => {
        window.print();
      }, 500);
    },
  },

  created() {
    this.settingsModules.forEach((module) => {
      this.$store.dispatch(`${module}/loadDefaultSettingsFromStorage`);
    });

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/applyDefaultSettings`);
      });
      if (this.criteriaFilter.length) {
        this.updateRoutePath();
      }
    } else {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/applyQuerySettings`, query);
      });
    }
  },

  async mounted() {
    window.addEventListener("beforeprint", () => {
      if (!this.overlayEnabled) {
        this.overlayEnabled = true;
        this.printingDrpMode = true;
        this.$store.commit("coreModule/hideDrawerBeforePrint");
      }
    });
    window.addEventListener("afterprint", () => {
      this.printingDrpMode = false;
      this.overlayEnabled = false;
      this.$store.commit("coreModule/restoreDrawerAfterPrint");
    });

    if (
      !this.$route.params.date
      || !moment(this.$route.params.date).isValid()
    ) {
      this.targetDate = moment().format(defaultDateFormat);
    } else {
      this.targetDate = this.$route.params.date;
    }

    if ((this.$route.query || {}).search) {
      this.searchString = this.$route.query.search;
    }

    await this.loadPage();

    if (this.$route.params.selectedTab) {
      this.selectedTabModel = (this.tabs.find((it) => (
        it.value === this.$route.params.selectedTab
      )) || {}).tabIndex || 0;
    }

    if ((this.$route.query || {}).criteriaFilter) {
      this.parseCriteriaFromUrl();
    }

    this.pageIsMounted = true;
  },
};
</script>

<style lang="scss">
.daily-route-planner-page-container {

  .buttons-block {
    position: relative;
  }

  .toolbar-component {
    &:hover {
      .buttons-block-caption {
        opacity: 1;
      }
    }
  }

  .buttons-block-caption {
    position: absolute;
    left: 0;
    right: 0;
    top: -8px;
    font-size: 0.7rem !important;
    letter-spacing: 0.05em !important;
    opacity: 0;
    transition: opacity 0.25s;
  }

  .no-wrap {
    white-space: nowrap;
  }
  .route-staging-btn {
    cursor: pointer !important;
  }

  .btn-toggle-border {
    border-radius: 50% !important;
  }

  .v-badge__badge {
    inset: auto calc(100% - 18px) calc(100% - 18px) auto !important;
  }

  .filter-panel {
    .filter-panel-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      &.filter-panel-on-mobile {
        flex-direction: column-reverse;
      }

      .btn-and-search {
        display: flex;
        align-items: center;

        &.on-mobile {
          display: block;
        }
      }
    }
  }

  .mobile-toolbar {
    width: -webkit-fill-available;
  }

  .file-input {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: -30%;
    width: 100%;
    height: 36px;
    cursor: pointer !important;
  }

  .search-field {
    max-width: 200px;

    &.search-field-on-mobile {
      max-width: none;
      margin-bottom: 15px;
    }
  }

  .toolbar-second-line {
    opacity: 0.5;
    font-size: 0.75em;
  }

  .toolbar-cell-delimeter {
    height: calc(100% - 12px);
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .toolbar-title {
    height: 100%;
    box-sizing: border-box;
    overflow-x: auto;
  }

  header.v-toolbar.theme--light {
    border-top: 1px solid rgb(224 224 224);
  }

  .small-icon-button-date {
    width: 36px !important;
    height: 36px !important;
  }

  .progress-circle {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media print {
  .daily-route-planner-page-container {
    max-width: 100% !important;
    padding: 0;
    .v-toolbar {
      height: auto !important;
      .v-toolbar__content {
        height: auto !important;
        padding: 0 !important;
        .toolbar-title,.toolbar-title input {
          color: black !important;
        }
      }
    }

    .small-checkbox .v-icon {
      display: block !important;
    }

    .v-tabs,
    .drp-actions,
    .small-icon-button-date,
    .v-btn,
    .v-icon,
    .v-autocomplete,
    .drp-routes-table .v-chip,
    .drp-entries-table .v-chip,
    table .v-text-field,
    .drp-overlay {
      display: none !important;
    }

    table, th, td {
      max-width: 100% !important;
      border: 1px solid black !important;
    }

    th, td {
      height: 0 !important;
      color: black !important;
      font-size: 10px !important;

      div {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    .filter-panel {
      display: flex !important;
      font-size: 15px !important;
      padding-left: 0 !important;
      align-items: center !important;
      .filter-panel-container {
        display: flex !important;
        justify-content: start !important;
        align-items: center !important;
        .v-text-field {
          margin-left: 15px;
          margin-top: 0 !important;
          .v-input__control > .v-input__slot:before {
            border-color: black;
          }
          label {
            color: black !important;
          }
          input {
            font-size: 15px;
            color: black !important;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
          }
        }
      }

      .criteria-filter-container {
        .criteria-filter-margin {
          margin-right: 0 !important;
        }
        .v-chip{
          background: none !important;
          color: black !important;
          border: 1px solid black !important;
          font-size: 13px !important;
          height: 20px !important;
        }
      }
    }

    .entry-label {
      .margin-left {
        margin-left: 2px !important;
      }

      .v-chip {
        background: none !important;
        display: block !important;
        font-size: 10px !important;
        color: black !important;
        height: auto !important;
        border: 1px solid black !important;
        padding: 0 5px 0 5px;
        line-height: 15px;

        .v-avatar {
          background: none !important;
          padding-right: 5px;
          height: auto !important;
          min-width: auto !important;
          width: auto !important;
        }
      }
    }

    .v-data-table__expanded {
      border-collapse: collapse !important;
      td {
        .entry-cell {
          border: 1px solid black !important;
          &:first-child {
            border-top: none !important;
          }
          &:last-child {
            border-bottom: none !important;
          }
        }
        &:not(:first-child){
          .entry-cell {
            border-right: none !important;
            border-left: none !important;
          }
        }
      }
    }

    .entry-cell {
      height: 30px !important;
    }

    .rescuer-widget{
      .rescuer-chip {
        height: 20px !important;
        border: 1px solid black !important;
        display: inline-flex !important;
        .middle-field {
          border-right: 1px solid black!important;
          border-left: 1px solid black !important;
        }
      }
      .editable-item, .bonus-btn {
        font-size: 10px !important;
      }
      .arrow-icon, .bonus-btn{
        display: block !important;
      }
    }
  }
}
</style>
