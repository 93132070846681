import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import {
  VehicleGroup,
  VehicleGroupCriteriaDto,
  VehicleGroupGroupingDto,
  VehicleGroupManualDto,
} from "../model/vehicle-group.model";

const API_PATH = "/vehicles/vehicle-group";

const locService = Vue.prototype.$localizationService;

export default {
  name: "vehicleGroupsModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayVehicleGroups: [],
    currentPage: {
      vehicleGroups: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicleGroups(state, vehicleGroupDto) {
      state.currentPage.vehicleGroups = [...vehicleGroupDto];
      state.displayVehicleGroups = state.currentPage.vehicleGroups;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const vehicleGroupsDto = await API.get("core", API_PATH, body);
        commit("updateVehicleGroups", vehicleGroupsDto.map((vehicleGroup) => VehicleGroup.fromDto(vehicleGroup)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addManualVehicleGroup(context, vehicleGroup) {
      const body = { ...await bodyWithAuthHeader(), body: VehicleGroupManualDto.toManualDto(vehicleGroup) };

      try {
        await API.post("core", `${API_PATH}/manual`, body);
        Vue.toasted.success(
          locService.localize("store.vehicle_group.add_vehicle_group_msg", [vehicleGroup.name]), {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
    },

    async addCriteriaVehicleGroup(context, vehicleGroup) {
      const body = { ...await bodyWithAuthHeader(), body: VehicleGroupCriteriaDto.toCriteriaDto(vehicleGroup) };

      try {
        await API.post("core", `${API_PATH}/criteria`, body);
        Vue.toasted.success(
          locService.localize("store.vehicle_group.add_vehicle_group_msg", [vehicleGroup.name]), {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
    },

    async addGroupingVehicleGroup(context, vehicleGroup) {
      const body = { ...await bodyWithAuthHeader(), body: VehicleGroupGroupingDto.toGroupingDto(vehicleGroup) };

      try {
        await API.post("core", `${API_PATH}/grouping`, body);
        Vue.toasted.success(
          locService.localize("store.vehicle_group.add_vehicle_group_msg", [vehicleGroup.name]), {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
    },

    async saveVehicleGroup(context, vehicleGroup) {
      const body = { ...await bodyWithAuthHeader(), body: VehicleGroup.toDto(vehicleGroup) };

      try {
        await API.put("core", `${API_PATH}/${vehicleGroup.id}`, body);
        Vue.toasted.success(
          locService.localize("store.vehicle_group.save_vehicle_group_msg", [vehicleGroup.name]), {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
    },

    async deleteVehicleGroup(context, vehicleGroup) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicleGroup.id}`, body);
        Vue.toasted.success(locService.localize("store.vehicle_group.delete_vehicle_group_msg",
          [vehicleGroup.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredVehicleGroups(context, searchString) {
      if (!searchString) return [];

      try {
        return await API.get("core", `${API_PATH}/search?searchString=${searchString}`,
          await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },

    async loadVehiclesFromGroups(context, ids) {
      if (!ids) return [];

      try {
        return await API.get("core", `${API_PATH}/vehicles?vehicleGroupIds=${ids}`,
          await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },
  },
};
