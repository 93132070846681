<template>
  <div v-if="pRoutePlan.id" class="stats-card d-flex align-center justify-space-around mb-1">
    <div class="stat-container" v-for="it in stats" :key="it.title">
      <div class="title">{{ it.title }}</div>
      <div class="stat" :class="it.color">{{ it.value }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  ENTRY_STATUS_CONFIRMED,
  ENTRY_STATUS_CALL_OUT,
  ENTRY_STATUS_LATE,
  ENTRY_STATUS_NO_RESPONSE,
} from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "ProminentStats",

  props: {
    pRoutePlan: Object,
  },

  computed: {
    stats() {
      return [
        {
          title: locService.localize("drp.stat.total_routes"),
          value: this.pRoutePlan.rows.length,
        },
        {
          title: locService.localize("drp.stat.total_drivers"),
          value: this.pRoutePlan.driverEntries.length,
        },
        {
          title: locService.localize("drp.stat.confirmed"),
          value: this.pRoutePlan.driverEntries.filter((it) => (
            it.status === ENTRY_STATUS_CONFIRMED
          )).length,
          color: "green--text",
        },
        {
          title: locService.localize("drp.stat.call_outs"),
          value: this.pRoutePlan.driverEntries.filter((it) => (
            it.status === ENTRY_STATUS_CALL_OUT
          )).length,
          color: "red--text",
        },
        {
          title: locService.localize("drp.stat.late"),
          value: this.pRoutePlan.driverEntries.filter((it) => (
            it.status === ENTRY_STATUS_LATE
          )).length,
          color: "red--text",
        },
        {
          title: locService.localize("drp.stat.no_responses"),
          value: this.pRoutePlan.driverEntries.filter((it) => (
            it.status === ENTRY_STATUS_NO_RESPONSE
          )).length,
          color: "red--text",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.stats-card {
  .stat-container {
    .title {
      font-size: 15px !important;
      line-height: normal;
    }
    .stat {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px !important;
      line-height: normal;
    }
  }
}
@media(max-width: 550px) {
  .stats-card {
    .stat-container {
      .title {
        font-size: 11px !important;
      }
      .stat {
        font-size: 20px !important;
      }
    }
  }
}
@media(max-width: 400px) {
  .stats-card {
    .stat-container {
      .title {
        font-size: 10px !important;
      }
      .stat {
        font-size: 18px !important;
      }
    }
  }
}
</style>
