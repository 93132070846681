<template>
  <Dashboard grafana-path="/d/hDe-6duGz/service-details" />
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "ServiceDetailsPage",

  routing: {
    localizationKey: "menu.tab.service_details",
    icon: "mdi-table",
    path: "/service_details",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Dashboard,
  },
};
</script>
