<template>
  <div
    v-if="state == 'view'"
    :class="{ 'btn-cursor': !isDisabled }"
    @click="setEditState()"
    class="replaced-driver-note-container d-flex align-center"
  >
    {{ targetEntry.note }}
  </div>
  <div v-else class="edit-container pr-2 d-flex flex-row align-center">
    <NoteEdit v-model="newNote" @cancel="cancel()" />
    <div class="d-flex flex-column">
      <v-icon class="ml-2" color="green" @click="save()">mdi-check</v-icon>
      <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
    </div>
  </div>
</template>

<script>
import NoteEdit from "./NoteEdit";

export default {
  name: "NoteLabel",

  components: {
    NoteEdit,
  },

  props: {
    targetEntry: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    state: "view",
    newNote: "",
  }),

  methods: {
    setEditState() {
      if (this.isDisabled) return;

      this.newNote = this.targetEntry.note;
      this.state = "edit";
    },

    save() {
      this.state = "view";
      this.$emit("onChangeData", this.newNote);
    },

    cancel() {
      this.newNote = "";
      this.state = "view";
    },
  },
};
</script>

<style scoped lang="scss">
.replaced-driver-note-container {
  position: relative;
  font-size: 0.9em;
  width: 100%;
  height: 100%;
}
.edit-container {
  width: 100%;
}
</style>
