<template>
  <v-dialog v-model="open" max-width="700px">
    <v-card :loading="reportsUploadingInProgress">
      <v-card-title>
        <span class="headline">{{
          $localizationService.localize(
            "report_page.dialog.upload.title"
          )
        }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
            class="mt-0"
            v-for="report in toUploadReports"
            :key="report.id"
          >
            <v-col cols="7">
              <v-text-field
                :label="
                  $localizationService.localize(
                    'report_page.dialog.upload.param.name'
                  )
                "
                v-model="report.file.name"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="report.menu"
                :nudge-right="50"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="report.getFormattedTargetDate()"
                    :label="
                      $localizationService.localize(
                        'report_page.dialog.upload.param.target_date'
                      )
                    "
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="reportsUploadingInProgress"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="report.targetDate"
                  @input="report.menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-container fill-height class="pa-0 justify-center">
                <v-progress-circular
                  :rotate="-90"
                  :size="25"
                  :width="2.5"
                  v-if="
                    reportsUploadingStatus[report.id] &&
                    reportsUploadingStatus[report.id] != 'success'
                  "
                  :value="reportsUploadingStatus[report.id] || 0"
                  color="primary"
                >
                </v-progress-circular>
                <v-icon
                  v-if="
                    reportsUploadingStatus[report.id] == 'success'
                  "
                  size="30"
                  color="green"
                >mdi-checkbox-marked-circle-outline</v-icon
                >
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="open = false"
          :disabled="reportsUploadingInProgress"
        >{{ $localizationService.localize("btn.cancel") }}</v-btn
        >
        <v-btn
          color="primary"
          text
          @click="uploadReports()"
          :disabled="reportsUploadingInProgress"
        >{{ $localizationService.localize("btn.upload") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { Report } from "../../model/report.model";

export default {
  name: "UploadReportDialog",

  computed: {
    ...mapState("reportModule", [
      "reportsUploadingStatus",
      "reportsUploadingInProgress",
      "processingReport",
      "displayReports",
    ]),
  },

  data: () => ({
    open: false,
    toUploadReports: [],
  }),

  methods: {
    openDialog({ event, targetDate }) {
      if (event.target.files.length) {
        this.toUploadReports = Array.from(event.target.files)
          .map((file) => new Report(
            undefined,
            undefined,
            targetDate,
            undefined,
            [],
            undefined,
            file,
          ));

        this.open = true;
        event.target.value = "";
      }
    },

    uploadReports() {
      this.$store
        .dispatch("reportModule/uploadReports", this.toUploadReports)
        .then(() => {
          this.$store.commit("reportModule/updateDisplayReports", [
            ...this.toUploadReports,
            ...this.displayReports,
          ]);
          setTimeout(() => (this.open = false), 500);
        });
    },
  },
};
</script>

<style scoped>

</style>
