<template>
  <Vehicles />
</template>

<script>
import Vehicles from "@/components/vehicles/Vehicles.vue";

export default {
  name: "VehiclesPage",
  routing: {
    localizationKey: "menu.tab.vehicles",
    icon: "mdi-truck",
    path: "/vehicles",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Vehicles,
  },
};
</script>
