export class UserInfo {
    id;

    name;

    email;

    type;

    selectedPhoneAgent;

    blocked;

    updatedAt;

    constructor(
      id,
      name,
      email,
      type,
      selectedPhoneAgent,
      blocked,
      updatedAt,
    ) {
      this.id = id;
      this.name = name;
      this.email = email;
      this.type = type;
      this.selectedPhoneAgent = selectedPhoneAgent;
      this.blocked = blocked;
      this.updatedAt = updatedAt;
    }

    static fromDto(dto) {
      return new UserInfo(
        dto.id,
        dto.name,
        dto.email,
        dto.type,
        dto.selectedPhoneAgent,
        dto.blocked,
        dto.updatedAt,
      );
    }

    static toDto(dto) {
      return new UserInfo(
        dto.id,
        dto.name,
        dto.email,
        dto.type,
        (dto.selectedPhoneAgent || {}).id,
        dto.blocked,
        dto.updatedAt,
      );
    }
}

export class SimpleUserInfo {
    id;

    name;

    email;

    constructor(
      id,
      name,
      email,
    ) {
      this.id = id;
      this.name = name;
      this.email = email;
    }

    static fromDto(dto) {
      return new SimpleUserInfo(
        dto.id,
        dto.name,
        dto.email,
      );
    }
}
