<template>
  <v-btn
    v-if="!targetStaging && state == 'view'"
    @click="state = 'edit'"
    x-small
    class="theme-text-color"
    color="secondary"
  >
    {{ $localizationService.localize('drp.staging_label.set_btn') }}
  </v-btn>
  <div v-else-if="state == 'view'" @click="state = 'edit'">
    {{ targetStaging }}
  </div>
  <div v-else class="staging-select d-flex flex-row align-center">
    <StagingSelect
      :value="targetStaging"
      @input="newStaging = $event"
      @submit="save()"
      @cancel="cancel()"
    />
    <v-icon class="ml-2" color="green" @click="save()">mdi-check</v-icon>
    <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
  </div>
</template>

<script>
import StagingSelect from "./StagingSelect.vue";

export default {
  name: "StagingLabel",

  components: {
    StagingSelect,
  },

  props: {
    targetStaging: String,
  },

  data: () => ({
    state: "view",
    newStaging: "",
  }),

  methods: {
    save() {
      this.state = "view";
      this.$emit("onChangeData", this.newStaging);
    },
    cancel() {
      this.state = "view";
    },
  },
};
</script>

<style scoped lang="scss">
.staging-select {
  min-width: 100px;
  max-width: 150px;
}
</style>
