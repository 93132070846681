import lodash from "lodash";
import { getLocalStorageData, setLocalStorageData } from "../../utils/utils";

export default {
  name: "vehicleInspectionSchemesTableSettingsModule",
  namespaced: true,
  state: {
    tableSettingsStorageKey: "vehicle.inspection.schemes.table.settings",

    pageIndex: 1,

    pageSize: 25,
    sort: {
      sortBy: [],
      sortDesc: [],
    },

    defaultSettings: {
      pageSize: 25,
      sort: {
        sortBy: [],
        sortDesc: [],
      },
    },
  },

  mutations: {
    changeSettings(state, data) {
      state.pageIndex = data.index;
      state.pageSize = data.size;
      state.sort = data.sort;
    },

    saveSettings(state) {
      const data = {
        pageSize: state.pageSize,
        sort: state.sort,
      };
      setLocalStorageData(state.tableSettingsStorageKey, JSON.stringify(data));
      state.defaultSettings = data;
    },

    applyDefaultSettings(state) {
      state.sort = { ...state.defaultSettings.sort };
      state.pageSize = state.defaultSettings.pageSize;
    },

    getDefaultSettingsFromStorage(state) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },

    applyQuerySettings(state, query) {
      if (query.pageIndex) {
        state.pageIndex = +query.pageIndex;
      }
      if (query.pageSize) {
        state.pageSize = +query.pageSize;
      }
      if (query.sort) {
        state.sort = JSON.parse(query.sort);
      }
    },
  },

  actions: {
    loadDefaultSettingsFromStorage({ state }) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },
  },

  getters: {
    isDefaultSettings: (state, getters) => getters.isDefaultSort && getters.isDefaultSize,
    isDefaultSort: (state) => lodash.isEqual(state.sort, state.defaultSettings.sort),
    isDefaultSize: (state) => state.pageSize === state.defaultSettings.pageSize,
  },
};
