import Vue from "vue";
import { API } from "aws-amplify";
import { dismissAction, bodyWithAuthHeader } from "../utils/utils";

const locService = Vue.prototype.$localizationService;

const API_PATH = "/coaching/criteria";

export default {

  name: "criteriaModule",
  namespaced: true,
  state: {
    processingCriteria: false,
    currentPageLoading: false,
    currentPage: {
      criteria: [],
      index: 0,
      size: 25,
    },
    displayCriteria: [],
    totalCount: 0,
    allCriteria: [],
    allCriteriaLoading: false,
    allCriteriaLoaded: false,
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageCriteria(state, criteriaDto) {
      state.currentPage.criteria = [...criteriaDto];
      state.displayCriteria = state.currentPage.criteria;
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    beginProcessingCriteria(state) {
      state.processingCriteria = true;
    },

    endProcessingCriteria(state) {
      state.processingCriteria = false;
    },

    beginLoadAllCriteria(state) {
      state.allCriteriaLoading = true;
    },

    endLoadAllCriteria(state) {
      state.allCriteriaLoading = false;
    },

    setAllCriteriaLoaded(state, val) {
      state.allCriteriaLoaded = val;
    },

    updateAllCriteria(state, newAllCriteria) {
      state.allCriteria = newAllCriteria.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  actions: {

    async loadAllCriteriaIfNeeded({ commit, state }) {
      if (state.allCriteriaLoading || state.allCriteriaLoaded) return;

      commit("beginLoadAllCriteria");

      const body = await bodyWithAuthHeader();

      try {
        const allCriteriaDto = await API.get("core", `${API_PATH}`, body);
        commit("updateAllCriteria", allCriteriaDto);
        commit("setAllCriteriaLoaded", true);
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      } finally {
        commit("endLoadAllCriteria");
      }
    },

    async reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        const criteriaDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageCriteria", criteriaDto);
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async deleteCriteria({ commit, dispatch, state }, criteria) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${criteria.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        commit("updateAllCriteria", state.allCriteria.filter((it) => it.id != criteria.id));
        dispatch("reloadPage");
        return locService.localize("store.criteria.delete_criteria_msg", [criteria.name]);
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async addOrSaveCriteria({ commit, dispatch, state }, criteria) {
      const body = { ...await bodyWithAuthHeader(), body: criteria };
      commit("beginProcessingCriteria");
      try {
        const result = await (criteria.id ? API.put("core", `${API_PATH}/${criteria.id}`, body) : API.post("core", API_PATH, body));
        commit("updateTotalCount", state.totalCount + 1);

        if (!criteria.id) {
          commit("updateAllCriteria", [result, ...state.allCriteria]);
        }

        dispatch("reloadPage");
        return result;
      } catch (e) {
        throw e.response.data.message;
      } finally {
        commit("endProcessingCriteria");
      }
    },
  },
};
