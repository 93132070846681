<template>
  <v-dialog v-model="open" max-width="60%">
    <v-card>
      <v-card-title class="d-flex align-baseline">
        <span class="headline flex-grow-1">
          {{ $localizationService.localize("vehicle_reminder_page.date_per_vehicle_dialog.title")}}
        </span>
        <v-menu
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="generalDate"
              :label="$localizationService.localize('vehicle_reminder_page.date_per_vehicle_dialog.general_date')"
              readonly
              v-bind="attrs"
              v-on="on"
              class="general-date"
              @click="isDueDateMenuOpen = true"
            />
          </template>
          <v-date-picker
            v-model="generalDate"
            @input="setGeneralDateByAllTargets($event)"
          />
        </v-menu>
    </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense v-for="item in targets" :key="item.id" align-content="center" justify="center">
            <v-col class="mb-9 pb-0" align-self="center">
              <div class="target-name">{{item.name || item.vin}}</div>
            </v-col>
            <v-col class="mb-0">
              <v-menu
                :key="item.id"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="getDateValue(item)"
                    :label="$localizationService.localize('vehicle_reminder_page.dialog.due_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click="isDueDateMenuOpen = true"
                  />
                </template>
                <v-date-picker
                  :value="getDateValue(item)"
                  @input="changeDateByTarget(item, $event)"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="confirm"
        >
          {{ $localizationService.localize("btn.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DatePerVehicleDialog",
  props: {
    targets: Array,
  },

  data: () => ({
    open: false,
    isDueDateMenuOpen: false,
    targetsByDate: [],
    generalDate: "",
  }),

  methods: {
    openDialog() {
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    confirm() {
      this.$emit("onEditTargetsByDate", this.targetsByDate);
      this.closeDialog();
    },

    changeDateByTarget(item, date) {
      const index = this.targetsByDate.findIndex((it) => it.vehicle.id === item.id);
      if (index !== -1) {
        this.targetsByDate[index].firstFireAt = date;
      } else this.targetsByDate.push({ vehicle: item, firstFireAt: date });
    },

    setGeneralDateByAllTargets(date) {
      this.targetsByDate = [];
      this.targets.forEach((it) => {
        this.changeDateByTarget(it, date);
      });
    },

    getDateValue(item) {
      const index = this.targetsByDate.findIndex((it) => it.vehicle.id === item.id);
      if (index !== -1) {
        return this.targetsByDate[this.targetsByDate.findIndex((it) => it.vehicle.id === item.id)].firstFireAt;
      }
      return "";
    },

  },
};
</script>

<style scoped lang="scss">
.target-name {
  font-size: 15px;
  color: var(--v-themeTextColor-base) !important;

}
.general-date {
  width: 30%;
}
</style>
