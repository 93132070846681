import {
  Action, Module, Mutation, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
import lodash from "lodash";
import { store } from "@/main";

const moduleName = "triggerAgentsModule";

@Module({
  name: moduleName, namespaced: true, store,
})
export default class TriggerAgentsModule extends VuexModule {
  static get namespace() {
    return namespace(moduleName);
  }

  actionAgents: any

  issueUnitAgents: any

  triggerTypeAgents: any

  @Mutation
  setActionAgents(agents: any) {
    this.actionAgents = agents;
  }

  @Mutation
  setIssueUnitAgents(agents: any) {
    this.issueUnitAgents = agents;
  }

  @Mutation
  setTriggerTypeAgents(agents: any) {
    this.triggerTypeAgents = agents;
  }

  @Action({ rawError: true })
  async loadAgents() {
    await this.loadActionAgents();
    await this.loadIssueUnitAgents();
    await this.loadTriggerTypeAgents();
  }

  @Action({ rawError: true })
  async loadActionAgents() {
    const context = await require.context(
      "@/components/triggers/trigger-edit-dialog/edit-trigger-action/action-agents",
      false,
      /.Agent.vue$/,
    );

    const modules = context.keys().map((x) => context(x).default);

    this.setActionAgents(lodash.keyBy(modules, "agentName"));
  }

  @Action({ rawError: true })
  async loadIssueUnitAgents() {
    const context = await require.context(
      "@/components/triggers/trigger-edit-dialog/edit-trigger-action/action-agents/issue-unit-agents",
      false,
      /.Agent.vue$/,
    );

    const modules = context.keys().map((x) => context(x).default);

    this.setIssueUnitAgents(lodash.keyBy(modules, "agentName"));
  }

  @Action({ rawError: true })
  async loadTriggerTypeAgents() {
    const context = await require.context(
      "@/components/triggers/trigger-edit-dialog/agents",
      false,
      /.Agent.vue$/,
    );

    const modules = context.keys().map((x) => context(x).default);

    this.setTriggerTypeAgents(lodash.keyBy(modules, "agentName"));
  }
}
