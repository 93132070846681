<template>
  <v-container fluid>
    <v-row align="start" class="template-constants" justify="start">
      <v-col>
        <v-data-table
            :class="{'not-default-page-size': !this.isDefaultSize}"
            :footer-props="footerProps"
            :headers="headers"
            :items="displayTemplateConstants"
            :loading="pageLoading || !tableInited"
            :options="{
              page: pageIndex,
              itemsPerPage: pageSize,
              sortBy: sort.sortBy,
              sortDesc: sort.sortDesc,
             }"
            :server-items-length="totalCount"
            multi-sort
            @update:options="paginate($event)"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("template_constant_page.title") }}
                <TableSettings
                    :pDefaultButtonTooltip="
                      $localizationService.localize(
                        'template_constant_page.settings_tooltip.apply_default'
                        )
                    "
                    :pIsVisible="tableInited && !isDefaultSettings"
                    :pSaveButtonTooltip="
                      $localizationService.localize(
                        'template_constant_page.settings_tooltip.save'
                      )
                    "
                    class="ml-5"
                    @onApplyDefaultSettings="applyDefaultSettings()"
                    @onSaveSettings="saveSettings()"
                />
              </v-toolbar-title>
              <v-spacer/>
              <v-text-field
                  v-model="searchString"
                  :label="$localizationService.localize('template_constant_page.search')"
                  class="search-field"
                  hide-details
                  width="200"
                  @input="loadOnInputSearchString()"
              ></v-text-field>
              <v-btn class="ml-2" color="primary" @click="openEditTemplateConstantDialog()">
                {{ $localizationService.localize("template_constant_page.new_btn") }}
              </v-btn>
              <EditTemplateConstantDialog @onAddOrSave="reloadPage" ref="templateConstantDialog"/>
              <ConfirmationDialog ref="deleteDialog"/>
            </v-toolbar>
          </template>
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
                :key="header.value"
                :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDateAndTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" medium @click="openEditTemplateConstantDialog(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon color="red" medium @click="openDeleteTemplateConstantDialog(item)"
            >mdi-delete
            </v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import lodash from "lodash";
import tableSortParserMixin from "../../utils/table-sort-parser";
import ConfirmationDialog from "../ConfirmationDialog";
import TableSettings from "../TableSettings";
import { momentDateAndTime } from "@/utils/utils";
import EditTemplateConstantDialog from "./EditTemplateConstantDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "TemplateConstant",

  mixins: [tableSortParserMixin],

  components: {
    EditTemplateConstantDialog,
    ConfirmationDialog,
    TableSettings,
  },

  computed: {
    ...mapState("templateConstantsModule", [
      "displayTemplateConstants",
      "totalCount",
      "currentPage",
      "pageLoading",
    ]),
    ...mapState("templateConstantsTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("templateConstantsTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },
  data: () => ({

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    tableInited: false,

    searchString: "",

    headers: [
      {
        text: locService.localize("template_constant_page.header.name"),
        value: "name",
        sortable: true,
      },
      {
        text: locService.localize("template_constant_page.header.value"),
        value: "value",
        sortable: true,
      },
      {
        text: locService.localize("template_constant_page.header.description"),
        value: "description",
        sortable: true,
      },
      {
        text: locService.localize("template_constant_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("template_constant_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("template_constant_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("templateConstantsTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("templateConstantsModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    openEditTemplateConstantDialog(templateConstant) {
      this.$refs.templateConstantDialog.openDialog(templateConstant);
    },

    openDeleteTemplateConstantDialog(templateConstant) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("template_constant_page.dialog.delete.title"),
        text: locService.localize("template_constant_page.dialog.delete.text", [
          templateConstant.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          await this.$store.dispatch("templateConstantsModule/deleteTemplateConstant", templateConstant);
          this.reloadPage();
        },
      });
    },

    saveSettings() {
      this.$store.commit("templateConstantsTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("template_constant_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("templateConstantsTableSettingsModule/applyDefaultSettings");
    },

    reloadPage() {
      this.$store.dispatch("templateConstantsModule/reloadPage");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/messaging/template-constants/?${params.toString()}`);
      }
    },

    loadOnInputSearchString: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("templateConstantsModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),
  },

};
</script>

<style lang="scss">
.template-constants {
  .search-field {
    max-width: 250px;
  }

  .v-data-table-header-mobile {
    display: none;
  }
}
</style>
