
















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Vehicle } from "@/model/vehicle.model";
import VehicleInfo from "@/components/vehicles/vehicle-profile/VehicleInfo.vue";
import VehicleIssues from "@/components/vehicles/vehicle-profile/VehicleIssues.vue";
import VehicleReminders from "@/components/vehicles/vehicle-profile/VehicleReminders.vue";
import VehicleInspectionAssignments from "@/components/vehicles/vehicle-profile/VehicleInspectionAssignments.vue";

const vehiclesModule = namespace("vehiclesModule");

@Component({
  components: {
    VehicleIssues,
    VehicleInfo,
    VehicleInspectionAssignments,
    VehicleReminders,
  },
})
export default class VehicleProfile extends Vue {
  vehicle: Vehicle | null = null;

  loading = false;

  updating = false;

  @vehiclesModule.Action
  loadVehicleById!: (id: string) => Promise<Vehicle>;

  @vehiclesModule.Action
  addOrSaveVehicle!: (it: Vehicle) => Promise<Vehicle>;

  async loadVehicleByRouteId() {
    this.loading = true;
    this.vehicle = await this.loadVehicleById(this.$route.params.id);
    this.loading = false;
  }

  async onUpdateVehicle(vehicle: Vehicle) {
    this.updating = true;
    this.vehicle = await this.addOrSaveVehicle(vehicle);
    this.updating = false;
  }

  mounted() {
    this.loadVehicleByRouteId();
  }
}
