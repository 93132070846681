<template>
  <v-autocomplete
    class="drp-device-select"
    :label="$localizationService.localize('drp.device_select.label')"
    :items="items"
    item-text="device.name"
    item-value="device.id"
    return-object
    cache-items
    v-model="selectedDeviceItems"
    hide-details
    error-count="0"
    chips
    multiple
    deletable-chips
    :loading="loading"
    :dense="!label"
    autofocus
    :search-input.sync="searchDevices"
    ref="inputDevice"
    :placeholder="$localizationService.localize('common.begin_typing')"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content :class="getDeviceClass(item.device)">
        {{item.device.name}}
        <small v-if="!canBeSelected(item.device)" class="already-in-use-device-note">
          <br>
          Already in use
        </small>
        </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "DeviceSelect",

  props: {
    label: String,
    value: Array,
    countDevicesInUse: Object,
    ownersPerDevice: Object,
    hostDriverEntry: Object,
  },

  data() {
    return {
      searchDevices: null,

      selectedDeviceItems: [],
      matchedDevices: this.value,

      loading: false,
    };
  },

  watch: {
    searchDevices() {
      if (this.searchDevices) {
        this.loadFilteredDevices();
      }
    },

    selectedDeviceItems() {
      this.$refs.inputDevice.internalSearch = "";
      this.$emit("input", this.selectedDeviceItems.map((it) => it.device));
    },
  },

  computed: {

    items() {
      return this.matchedDevices.map((device) => ({ device, disabled: !this.canBeSelected(device) }));
    },
  },

  methods: {

    canBeSelected(device) {
      const ownersPerDevice = this.ownersPerDevice;
      if (!this.hostDriverEntry) return false;

      const owners = ownersPerDevice[device.id] || [];

      return (owners.length - (owners.find((it) => it.id == this.hostDriverEntry.driver.id) ? 1 : 0)) <= 0;
    },

    getDeviceClass(device) {
      return this.countDevicesInUse[device.id] > 0
        ? "orange--text"
        : "";
    },

    // TODO: warning
    loadFilteredDevices: debounce(function () {
      this.loading = true;
      this.selectedEntryModel = undefined;

      this.$store.dispatch(
        "dailyRoutePlannerModule/loadFilteredDevices",
        this.searchDevices,
      ).then((data) => {
        this.matchedDevices = data;
        this.loading = false;
      });
    }, 300),
  },

  mounted() {
    this.selectedDeviceItems = this.items.filter((a) => this.value.find((b) => a.device.id == b.id));
  },
};
</script>

<style lang="scss" scoped>
.already-in-use-device-note {
  color: red;
  font-weight: bold;
  line-height: 50%;
}
</style>
