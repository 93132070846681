






























import {
  Component, Emit, Prop, Vue, Watch,
} from "vue-property-decorator";

const READ_STATE = "READ";
const EDIT_STATE = "EDIT";

@Component
export default class ReadEditTextField extends Vue {
  READ_STATE = READ_STATE;

  EDIT_STATE = EDIT_STATE;

  state = READ_STATE;

  currentValue = "";

  @Prop()
  value!: string;

  @Prop()
  label!: string;

  @Prop()
  isDisplayLabelOnRead!: boolean;

  @Watch("value")
  onUpdateValue() {
    this.currentValue = this.value;
  }

  @Emit("onSaveValue")
  emitNewValue(value: string) {
    return value;
  }

  get disableSave() {
    return (!this.value && !this.currentValue)
      || (this.value || "") === (this.currentValue || "");
  }

  saveNewValue() {
    if (this.disableSave) return;

    this.emitNewValue(this.currentValue);

    if (this.currentValue) {
      this.readField();
    }
  }

  cancelEditing() {
    if (!this.value) return;

    this.currentValue = this.value;
    if (this.currentValue) {
      this.readField();
    }
  }

  editField() {
    this.state = EDIT_STATE;
  }

  readField() {
    this.state = READ_STATE;
  }

  mounted() {
    this.currentValue = this.value || "";
    if (!this.value) {
      this.editField();
    }
  }
}
