import { render, staticRenderFns } from "./RocketChatSettings.vue?vue&type=template&id=0d4e2062&"
import script from "./RocketChatSettings.vue?vue&type=script&lang=ts&"
export * from "./RocketChatSettings.vue?vue&type=script&lang=ts&"
import style0 from "./RocketChatSettings.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./RocketChatSettings.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCheckbox,VCol,VContainer,VDivider,VIcon,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VProgressLinear,VRow,VSelect,VSwitch})
