






























import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CoreModule from "@/store/core.store";
import router from "@/router";
import { dismissAction, IS_MOBILE } from "@/utils/utils";
// eslint-disable-next-line no-unused-vars
import { User } from "@/model/user.model";

const userModule = namespace("userModule");
const coreModule = CoreModule.namespace;

@Component({
  components: {
    ThemeSwitcher: () => import("./ThemeSwitcher.vue"),
  },
})

export default class Login extends Vue {
  @userModule.State
  user!: User;

  @userModule.State
  authInProgress!: boolean;

  @userModule.Action
  signIn!: () => void;

  @userModule.Action
  checkLoginAndRedirect!: () => void;

  @coreModule.Mutation
  toggleDrawer!: () => void;

  onLoginClick() {
    this.signIn();
  }

  mounted() {
    if (!IS_MOBILE && !this.user) {
      this.toggleDrawer();
    }
    const errorDescription: string | (string | null)[] = router.currentRoute.query.error_description;
    if (errorDescription) {
      this.$toasted.error(errorDescription as string, { ...dismissAction });
    } else {
      this.checkLoginAndRedirect();
    }
  }
}
