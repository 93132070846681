











































import {
  Component, Emit, Prop, Ref, Vue,
} from "vue-property-decorator";
import draggable from "vuedraggable";
import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import EditTriggerActionDialog from "@/components/triggers/trigger-edit-dialog/edit-trigger-action/EditTriggerActionDialog.vue";
import TriggersModule from "@/store/triggers.store";

const triggerModule = TriggersModule.namespace;

const locService = Vue.prototype.$localizationService;

@Component({
  components: {
    draggable,
    EditTriggerActionDialog,
  },
})
export default class TriggerActions extends Vue {
  locService = locService;

  drag = false;

  @Prop()
  actionDraftTemplates!: ActionDraftTemplate[]

  currentActionDraftTemplates: ActionDraftTemplate[] = [...this.actionDraftTemplates]

  @Ref("editTriggerActionDialog")
  editTriggerActionDialog!: any;

  @triggerModule.State
  currentModels!: any

  @triggerModule.State
  loadingModels!: boolean

  @triggerModule.Action
  loadTriggerModelsIfNeeded!: () => Promise<void>

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
    };
  }

  @Emit("onUpdateActionTemplates")
  emitUpdatedActionTemplates() {
    return this.currentActionDraftTemplates;
  }

  openEditTriggerActionDialog(action: ActionDraftTemplate | undefined, index?: number) {
    this.editTriggerActionDialog.openDialog(action, index);
  }

  onSaveTriggerAction(data: { action: ActionDraftTemplate; index: number | null }) {
    const {
      index,
      action,
    } = data;
    console.log(data);

    if (index !== null) {
      this.currentActionDraftTemplates.splice(index, 1, action);
    } else {
      this.currentActionDraftTemplates.push(action);
    }
    this.updateOrders();
  }

  onRemoveTriggerAction(index: number) {
    this.currentActionDraftTemplates.splice(index, 1);
    this.updateOrders();
  }

  async updateOrders() {
    this.currentActionDraftTemplates = this.currentActionDraftTemplates.map((it, index) => {
      it.order = index;
      return it;
    });

    await Vue.nextTick(() => {
      this.emitUpdatedActionTemplates();
    });
  }

  mounted() {
    this.loadTriggerModelsIfNeeded();
  }
}
