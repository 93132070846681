import Vue from "vue";

const locService = Vue.prototype.$localizationService;

export const getMnt = (targetEntry, targetVehicle) => (targetVehicle && targetEntry
  ? targetEntry.mntVehicles
    .some((vehicle) => vehicle.id === targetVehicle.id)
  : false);

export const updateMnt = (value, targetEntry, targetVehicle) => {
  if (!targetEntry) {
    Vue.toasted.error(locService.localize("model.row.mnt.driver_not_selected"), { duration: 3000 });
    return;
  }

  if (!targetVehicle) {
    Vue.toasted.error(locService.localize("model.row.mnt.vehicle_not_selected"), { duration: 3000 });
    return;
  }

  if (
    value
    && !targetEntry.mntVehicles.find(
      (vehicle) => vehicle.id === targetVehicle.id,
    )
  ) {
    targetEntry.mntVehicles.push(targetVehicle);
    return;
  }

  if (
    !value
    && targetEntry.mntVehicles.find(
      (vehicle) => vehicle.id === targetVehicle.id,
    )
  ) {
    const foundIndex = targetEntry.mntVehicles.findIndex(
      (vehicle) => vehicle.id === targetVehicle.id,
    );

    targetEntry.mntVehicles.splice(foundIndex, 1);
  }
};
