<template>
  <v-textarea
    :placeholder="$localizationService.localize('drp.note_edit.placeholder')"
    rows="2"
    no-resize
    v-model="newNote"
    error-count="0"
    hide-details
    @keydown.esc="$emit('cancel')"
    class="drp-note-edit"
    dense
    autofocus
  ></v-textarea>
</template>

<script>
export default {
  name: "NoteEdit",

  props: {
    value: String,
  },

  data: () => ({
    newNote: "",
  }),

  watch: {
    newNote() {
      this.$emit("input", this.newNote);
    },
    value() {
      this.newNote = this.value;
    },
  },

  mounted() {
    this.newNote = this.value;
  },
};
</script>

<style lang="scss">
.drp-note-edit {
  textarea {
    font-size: 0.9em;
    line-height: 1.5em;
  }
}
</style>
