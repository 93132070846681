<template>
  <VehicleInspections />
</template>

<script>
import VehicleInspections from "../components/vehicle-inspections/VehicleInspections";

export default {
  name: "VehicleInspectionsPage",
  components: { VehicleInspections },

  routing: {
    localizationKey: "menu.tab.vehicle_inspections",
    icon: "mdi-car-search",
    path: "/vehicle-inspection",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>
