import { VehicleInspection } from "./vehicle-inspection.model";
import { Vehicle } from "@/model/vehicle.model";
import { VehicleInspectionScheme } from "./vehicle-inspection-scheme.model";

export class VehicleInspectionAssignment {
  id: string;

  targetDate: Date;

  targetVehicle: Vehicle;

  targetScheme: VehicleInspectionScheme;

  inspection: VehicleInspection | null = null;

  constructor(
    id: string,
    targetDate: Date,
    targetVehicle: Vehicle,
    targetScheme: VehicleInspectionScheme,
    inspection: VehicleInspection | null,
  ) {
    this.id = id;
    this.targetDate = targetDate;
    this.targetVehicle = Vehicle.fromDto(targetVehicle);
    this.targetScheme = VehicleInspectionScheme.fromDto(targetScheme);
    this.inspection = inspection;
  }

  static fromDto(dto: any) {
    return new VehicleInspectionAssignment(
      dto.id,
      new Date(dto.targetDate),
      Vehicle.fromDto(dto.targetVehicle),
      VehicleInspectionScheme.fromDto(dto.targetScheme),
      dto.inspection,
    );
  }
}
