/* eslint-disable no-underscore-dangle */
export default class LocalizationService {
  currentLanguage = undefined

  namesMap = undefined

  localize(key, arr = []) {
    if (!this.namesMap[key]) {
      console.error(`Unknown localization key: ${key}`);
      return "";
    }

    if (!arr.length) {
      return this.namesMap[key];
    }

    let mappedValue = this.namesMap[key];

    arr.forEach((value, index) => {
      mappedValue = mappedValue.split(`$${index}`).join(value);
    });

    return mappedValue;
  }

  initLanguage(language) {
    this.currentLanguage = language;
    this.buildNamesMap();
  }

  injectLocalizedNames(obj) {
    if (!obj) return;

    if (obj.localizationKey) {
      const localizedName = this.namesMap[obj.localizationKey];

      if (localizedName) {
        obj.displayName = localizedName;
      } else {
        console.error(`Localization failed for ${obj.localizationKey}`);
      }
    }

    Object.values(obj)
      .filter((suspect) => typeof suspect == "object")
      .forEach((val) => this.injectLocalizedNames(val));
  }

  buildNamesMap() {
    this.namesMap = {};
    Object.keys(this.currentLanguage).forEach((key) => {
      this.buildNamesMapNode(key, this.currentLanguage[key]);
    });
    this.namesMap._empty = "---";
  }

  buildNamesMapNode(nodePath, node) {
    if (node._name || typeof node === "string") {
      this.namesMap[nodePath] = node._name || node;
    }

    Object.keys(node).filter((suspect) => !suspect.startsWith("_")).forEach((key) => {
      if (typeof node[key] === "string") {
        this.namesMap[`${nodePath}.${key}`] = node[key];
      } else {
        this.buildNamesMapNode(`${nodePath}.${key}`, node[key]);
      }
    });
  }
}
