import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import { TriggerActionDraft, TriggerDto, TriggerType } from "@/types/types";
import { IssueActionDraftTemplate } from "@/model/trigger/action-draft-templates/issue/issue-action-draft-template.model";
import { CloseIssueActionDraftTemplate } from "@/model/trigger/action-draft-templates/issue/close-issue-action-draft-template.model";

export const actionDraftTemplateClasses = {
  [TriggerActionDraft.CreateIssueActionDraftTemplate]: IssueActionDraftTemplate,
  [TriggerActionDraft.CloseIssueActionDraftTemplate]: CloseIssueActionDraftTemplate,
};

export const triggerTypesClasses: any = {};

export abstract class Trigger<T extends Trigger<any>> {
  constructor(
    public id: string,
    public name: string,
    public type: TriggerType | null,
    public priority: number,
    public actionDraftTemplates: ActionDraftTemplate[],
  ) {}

  abstract toDto(): any

  abstract clone(): T

  static create(type: TriggerType): any {
    return triggerTypesClasses[type.value].create();
  }

  static fromDto(dto: TriggerDto): any {
    return triggerTypesClasses[dto.type].fromDto(dto as any);
  }
}

export class TriggerStub extends Trigger<TriggerStub> {
  static create() {
    return new TriggerStub(
      "",
      "",
      null,
      0,
      [],
    );
  }

  toDto(): any {
    return {};
  }

  clone(): TriggerStub {
    return new TriggerStub(
      this.id,
      this.name,
      this.type,
      this.priority,
      this.actionDraftTemplates,
    );
  }
}
