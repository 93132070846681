<template>
  <Triggers />
</template>

<script>
import Triggers from "@/components/triggers/Triggers.vue";

export default {
  name: "TriggersPage",
  routing: {
    localizationKey: "menu.tab.triggers",
    icon: "mdi-memory",
    path: "/triggers",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Triggers,
  },
};
</script>
