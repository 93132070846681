<template>
  <v-container fluid class="auto-coaching-table" :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title class="table-toolbar-title mr-4 d-flex align-center">
            {{
              $localizationService.localize(
                "coaching_page.auto_coaching.title"
              )
            }}
            <TableSettings
              class="ml-5"
              :pIsVisible="pageInited && (!isDefaultNewSettings || !isDefaultResolvedSettings)"
              :pSaveButtonTooltip="
                $localizationService.localize(
                  'coaching_page.auto_coaching.settings_tooltip.save'
                )
              "
              :pDefaultButtonTooltip="
                $localizationService.localize(
                  'coaching_page.auto_coaching.settings_tooltip.apply_default'
                )
              "
              @onSaveSettings="saveSettings()"
              @onApplyDefaultSettings="applyDefaultSettings()"
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="search-field"
            :label="
              $localizationService.localize(
                'coaching_page.auto_coaching.param.search'
              )
            "
            hide-details
            width="200"
            v-model="searchString"
          ></v-text-field>
          <CoachingMenu class="ml-2" />
        </v-toolbar>
        <v-tabs v-model="rawCurrentTab" @change="tabChangeLoading = true">
          <v-tab active-class="primary--text">
            {{
              $localizationService.localize(
                "coaching_page.auto_coaching.tab.new"
              )
            }}
          </v-tab>
          <v-tab active-class="green--text">
            {{
              $localizationService.localize(
                "coaching_page.auto_coaching.tab.resolved"
              )
            }}
          </v-tab>
        </v-tabs>
        <NewCoachingTable
          v-if="targetStatus === COACHING_STATUS_NEW.value"
          :pLoading="tableLoading"
          :pItems="tabChangeLoading ? [] : displayCoaching"
          :pTotalCount="totalCount"
          @onUpdateTable="loadPage($event);"
          ref="newCoachingTable"
        />
        <ResolvedCoachingTable
          v-if="targetStatus === COACHING_STATUS_RESOLVED.value"
          :pLoading="tableLoading"
          :pItems="tabChangeLoading ? [] : displayCoaching"
          :pTotalCount="totalCount"
          @onUpdateTable="loadPage($event);"
          ref="resolvedCoachingTable"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import lodash from "lodash";
import Vue from "vue";
import {
  COACHING_STATUS_NEW,
  COACHING_STATUS_RESOLVED,
} from "../../utils/utils";

export default {
  name: "AutoCoachingTablesFacade",

  components: {
    CoachingMenu: () => import("./CoachingMenu.vue"),
    NewCoachingTable: () => import("./NewCoachingTable.vue"),
    ResolvedCoachingTable: () => import("./ResolvedCoachingTable.vue"),
    TableSettings: () => import("@/components/TableSettings.vue"),
  },

  data: () => ({
    COACHING_STATUS_NEW,
    COACHING_STATUS_RESOLVED,

    rawCurrentTab: 0,

    searchString: "",
    pageInited: false,
    tabChangeLoading: false,

    settingsModules: [
      "newCoachingTableSettingsModule",
      "resolvedCoachingTableSettingsModule",
    ],
  }),

  watch: {
    searchString() {
      this.searchStringDebounceWatch();
    },
  },

  computed: {
    ...mapState("coachingModule", [
      "pageLoading",
      "currentPage",
      "displayCoaching",
      "totalCount",
      "processingCoaching",
    ]),
    ...mapState("coreModule", ["drawer"]),
    // TODO (FUTURE): incapsulate
    ...mapState("newCoachingTableSettingsModule", [
      "newPageIndex",
      "newPageSize",
      "newSort",
    ]),
    ...mapGetters("newCoachingTableSettingsModule", [
      "isDefaultNewSettings",
    ]),
    ...mapState("resolvedCoachingTableSettingsModule", [
      "resolvedPageIndex",
      "resolvedPageSize",
      "resolvedSort",
    ]),
    ...mapGetters("resolvedCoachingTableSettingsModule", [
      "isDefaultResolvedSettings",
    ]),

    tableLoading() {
      return this.tabChangeLoading || this.pageLoading || !this.pageInited;
    },

    targetStatus() {
      if (this.rawCurrentTab == 0) return COACHING_STATUS_NEW.value;
      if (this.rawCurrentTab == 1) return COACHING_STATUS_RESOLVED.value;
      return undefined;
    },
  },

  methods: {
    applyDefaultSettings() {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/applyDefaultSettings`);
      });
    },

    loadPage(currentPage) {
      this.updateRoutePath();
      this.sendCurrentPage(currentPage);
    },

    sendCurrentPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("coachingModule/loadPage", {
        ...currentPage,
        index: currentPage.index - 1,
        sort: currentPage.sort.sortBy.map((it, i) => (currentPage.sort.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
        targetStatus: this.targetStatus,
      });
      this.tabChangeLoading = false;
      this.pageInited = true;
    }, 500),

    saveSettings() {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/saveSettings`);
      });

      Vue.toasted.success(
        "Settings have been saved!",
        { duration: 3000 },
      );
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("newPageSize", this.newPageSize);

      params.append("newPageIndex", this.newPageIndex);

      params.append("newSort", JSON.stringify(this.newSort));

      params.append("resolvedPageSize", this.resolvedPageSize);

      params.append("resolvedPageIndex", this.resolvedPageIndex);

      params.append("resolvedSort", JSON.stringify(this.resolvedSort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      const newPath = `/coaching-table/${this.targetStatus}/`;
      if (
        this.$route.path != newPath
        || this.$route.query.newPageSize != (params.get("newPageSize") || undefined)
        || this.$route.query.newPageIndex != (params.get("newPageIndex") || undefined)
        || this.$route.query.newSort != (params.get("newSort") || undefined)
        || this.$route.query.resolvedPageSize != (params.get("resolvedPageSize") || undefined)
        || this.$route.query.resolvedPageIndex != (params.get("resolvedPageIndex") || undefined)
        || this.$route.query.resolvedSort != (params.get("resolvedSort") || undefined)
        || this.$route.query.searchString != (params.get("searchString") || undefined)
      ) {
        this.$router.push(`${newPath}?${params.toString()}`);
      }
    },

    reloadPage() {
      this.$store.dispatch("coachingModule/reloadPage");
    },
  },

  created() {
    this.settingsModules.forEach((module) => {
      this.$store.dispatch(`${module}/loadDefaultSettingsFromStorage`);
    });

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/applyDefaultSettings`);
      });
    } else {
      this.settingsModules.forEach((module) => {
        this.$store.commit(`${module}/applyQuerySettings`, query);
      });

      if (query.searchString) {
        this.searchString = query.searchString;
      }
    }
  },

  mounted() {
    const selectedTab = this.$route.params.selectedTab;

    if (selectedTab) {
      if (selectedTab === COACHING_STATUS_NEW.value) {
        this.rawCurrentTab = 0;
      }

      if (selectedTab === COACHING_STATUS_RESOLVED.value) {
        this.rawCurrentTab = 1;
      }
    }

    this.searchStringDebounceWatch = lodash.debounce(() => {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("coachingModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600);
  },
};
</script>

<style lang="scss">
.auto-coaching-table {
  .search-field {
    max-width: 250px;
  }
  .table-toolbar-title {
    min-width: min-content;
  }
  .driver-name {
    color: inherit !important;
    text-decoration: underline;
  }
}
</style>
