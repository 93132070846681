var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.userInputDisabled}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.request_scan_dialog.title" ))+" ")])]),_c('v-card-text',[(_vm.open)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"nudge-right":50,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.scanRequest.fromTime,"label":_vm.$localizationService.localize(
                      'coaching_page.request_scan_dialog.param.from_date'
                    ),"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.userInputDisabled}},'v-text-field',attrs,false),on))]}}],null,false,3470745163),model:{value:(_vm.fromDateDialog),callback:function ($$v) {_vm.fromDateDialog=$$v},expression:"fromDateDialog"}},[_c('v-date-picker',{attrs:{"max":_vm.scanRequest.toTime},on:{"input":function($event){_vm.fromDateDialog = false}},model:{value:(_vm.scanRequest.fromTime),callback:function ($$v) {_vm.$set(_vm.scanRequest, "fromTime", $$v)},expression:"scanRequest.fromTime"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"nudge-right":50,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.scanRequest.toTime,"label":_vm.$localizationService.localize(
                      'coaching_page.request_scan_dialog.param.to_date'
                    ),"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.userInputDisabled}},'v-text-field',attrs,false),on))]}}],null,false,4227515179),model:{value:(_vm.toDateDialog),callback:function ($$v) {_vm.toDateDialog=$$v},expression:"toDateDialog"}},[_c('v-date-picker',{attrs:{"min":_vm.scanRequest.fromTime},on:{"input":function($event){_vm.toDateDialog = false}},model:{value:(_vm.scanRequest.toTime),callback:function ($$v) {_vm.$set(_vm.scanRequest, "toTime", $$v)},expression:"scanRequest.toTime"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"flex-grow-0",attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.request_scan_dialog.param.target_report_types'
                ),"items":_vm.availableReportTypes,"multiple":"","disabled":_vm.userInputDisabled},model:{value:(_vm.scanRequest.targetTypes),callback:function ($$v) {_vm.$set(_vm.scanRequest, "targetTypes", $$v)},expression:"scanRequest.targetTypes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"flex-grow-0",attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.request_scan_dialog.param.target_criteria'
                ),"items":_vm.availableCriteria,"multiple":"","disabled":_vm.userInputDisabled},model:{value:(_vm.scanRequest.criteria),callback:function ($$v) {_vm.$set(_vm.scanRequest, "criteria", $$v)},expression:"scanRequest.criteria"}})],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.scanButtonActive || _vm.userInputDisabled},on:{"click":_vm.requestScan}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.scan"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }