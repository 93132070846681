import lodash from "lodash";
import {
  VEHICLE_STATUSES,
  VEHICLE_STATUS_ACTIVE,
  VEHICLE_TYPES,
  VEHICLE_FLEET_MANAGEMENT_COMPANY,
  VEHICLE_TYPE_OWNED,
} from "../utils/utils";
import {
  ISSUE_SEVERITIES,
} from "@/utils/ts-utils";
import { FuelCard } from "./fuel-card.model";
import { VehicleShop } from "./vehicle-shop.model";
import { VehicleGroup } from "./vehicle-group.model";
import { Reminder } from "@/model/reminder/reminder.model";

export class Vehicle {
    id;

    name;

    vin;

    type;

    make;

    model;

    licensePlate;

    rentalExpiry;

    rentalProvider;

    fleetManagementCompany;

    status;

    notes;

    createdAt;

    updatedAt;

    fuelCard;

    vehicleShop;

    vehicleGroups;

    metadata;

    openIssuesCount;

    lastKnownOdometerValueForNow;

    reminders;

    highestIssuesSeverity;

    constructor(
      id,
      name,
      vin,
      type,
      make,
      model,
      licensePlate,
      rentalExpiry,
      rentalProvider,
      fleetManagementCompany,
      status,
      notes,
      createdAt,
      updatedAt,
      fuelCard,
      vehicleShop,
      vehicleGroups,
      metadata,
      openIssuesCount,
      lastKnownOdometerValueForNow,
      reminders,
      highestIssuesSeverity,
    ) {
      this.id = id;
      this.name = name;
      this.vin = vin;
      this.type = type;
      this.make = make;
      this.model = model;
      this.licensePlate = licensePlate;
      this.rentalExpiry = rentalExpiry;
      this.rentalProvider = rentalProvider;
      this.fleetManagementCompany = fleetManagementCompany;
      this.status = status;
      this.notes = notes;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.fuelCard = fuelCard ? FuelCard.fromDto(fuelCard) : null;
      this.vehicleShop = vehicleShop ? VehicleShop.fromDto(vehicleShop) : null;
      this.vehicleGroups = vehicleGroups ? vehicleGroups.map((it) => VehicleGroup.fromDto(it)) : [];
      this.metadata = metadata;
      this.openIssuesCount = openIssuesCount;
      this.lastKnownOdometerValueForNow = lastKnownOdometerValueForNow;
      this.reminders = reminders;
      this.highestIssuesSeverity = highestIssuesSeverity;
    }

    static fromDto(dto) {
      return new Vehicle(
        dto.id,
        dto.name,
        dto.vin,
        dto.type,
        dto.make,
        dto.model,
        dto.licensePlate,
        dto.rentalExpiry,
        dto.rentalProvider,
        dto.fleetManagementCompany,
        dto.status,
        dto.notes,
        dto.createdAt,
        dto.updatedAt,
        dto.fuelCard,
        dto.vehicleShop,
        dto.vehicleGroups,
        dto.metadata,
        dto.openIssuesCount,
        dto.lastKnownOdometerValueForNow,
        dto.reminders.map((it) => Reminder.fromDto(it)),
        lodash.keyBy(ISSUE_SEVERITIES, "value")[dto.highestIssuesSeverity],
      );
    }

    static createNew(data = {}) {
      return new Vehicle(
        undefined,
        data.name || "",
        data.vin || "",
        VEHICLE_TYPE_OWNED.value,
        data.make || "",
        data.model || "",
        data.licensePlate || "",
        data.rentalExpiry || "",
        data.rentalProvider || "",
        data.fleetManagementCompany || "",
        VEHICLE_STATUS_ACTIVE.value,
        data.notes || "",
        data.createdAt || "",
        data.updatedAt || "",
        data.fuelCard || undefined,
        data.vehicleShop || undefined,
        data.vehicleGroups || [],
        data.metadata || {},
        data.openIssuesCount || 0,
        data.lastKnownOdometerValueForNow || 0,
        data.reminders || [],
        data.highestIssuesSeverity || undefined,
      );
    }

    getDisplayStatus() {
      return VEHICLE_STATUSES.find((it) => it.value == this.status).text;
    }

    getStatusColor() {
      return VEHICLE_STATUSES.find((it) => it.value == this.status).color;
    }

    getDisplayType() {
      return VEHICLE_TYPES.find((item) => item.value == this.type).text;
    }

    getDisplayFleetManagementCompany() {
      return (VEHICLE_FLEET_MANAGEMENT_COMPANY
        .find((it) => it.value == this.fleetManagementCompany) || {})
        .text || "";
    }
}

export class VehicleDto {
  constructor(
    id,
    name,
    vin,
    type,
    make,
    model,
    licensePlate,
    rentalExpiry,
    rentalProvider,
    fleetManagementCompany,
    status,
    notes,
    fuelCardId,
    vehicleShopId,
    openIssuesCount,
    lastKnownOdometerValueForNow,
    reminders,
    highestIssuesSeverity,
  ) {
    this.id = id;
    this.name = name;
    this.vin = vin;
    this.type = type;
    this.make = make;
    this.model = model;
    this.licensePlate = licensePlate;
    this.rentalExpiry = rentalExpiry;
    this.rentalProvider = rentalProvider;
    this.fleetManagementCompany = fleetManagementCompany;
    this.status = status;
    this.notes = notes;
    this.fuelCardId = fuelCardId;
    this.vehicleShopId = vehicleShopId;
    this.openIssuesCount = openIssuesCount;
    this.lastKnownOdometerValueForNow = lastKnownOdometerValueForNow;
    this.reminders = reminders;
    this.highestIssuesSeverity = highestIssuesSeverity;
  }

  static toDto(vehicle) {
    return new VehicleDto(
      vehicle.id,
      vehicle.name,
      vehicle.vin,
      vehicle.type,
      vehicle.make,
      vehicle.model,
      vehicle.licensePlate,
      vehicle.rentalExpiry || null,
      vehicle.rentalProvider,
      vehicle.fleetManagementCompany,
      vehicle.status,
      vehicle.notes,
      (vehicle.fuelCard || {}).id,
      (vehicle.vehicleShop || {}).id,
      vehicle.openIssuesCount || 0,
      vehicle.lastKnownOdometerValueForNow || 0,
      [],
      vehicle.highestIssuesSeverity || undefined,
    );
  }
}
