import Vue from "vue";
import { API } from "aws-amplify";
import { dismissAction, bodyWithAuthHeader, handleHttpError } from "../utils/utils";

const locService = Vue.prototype.$localizationService;

const API_PATH = "/user-invitations";

export default {

  name: "userInvitationModule",
  namespaced: true,
  state: {

    currentPageLoading: false,
    userInvitationTableInited: false,
    displayUserInvitations: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageUserInvitations(state, userInvitationsDto) {
      state.displayUserInvitations = [...userInvitationsDto];
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    initUserInvitationTable(state) {
      state.userInvitationTableInited = true;
    },
  },
  actions: {

    async reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }) {
      commit("startLoadingPage");

      await dispatch("loadTotalCount");

      try {
        const userInvitationsDto = await API.get("core", API_PATH, {
          ...await bodyWithAuthHeader(),
        });
        commit("updateCurrentPageUserInvitations", userInvitationsDto);
        commit("initUserInvitationTable");
        commit("endLoadingPage");
        return userInvitationsDto;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async deleteUserInvitation({ commit, dispatch, state }, userInvitation) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${userInvitation.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        return locService.localize("store.user_invitation.delete_user_invitation_msg", [userInvitation.email]);
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async addUserInvitation({ commit, dispatch, state }, userInvitation) {
      const body = { ...await bodyWithAuthHeader(), body: userInvitation };

      try {
        await API.post("core", API_PATH, body);
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");

        return locService.localize("store.user_invitation.add_user_invitation_msg", [userInvitation.email]);
      } catch (e) {
        throw e.response.data.message;
      }
    },
  },
};
