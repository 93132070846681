<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-data-table
          class="driver-table-display"
          :class="{'not-default-page-size': !this.isDefaultSize}"
          :headers="headers"
          :items="displayDrivers"
          :loading="currentPageLoading || !tableInited"
          :server-items-length="totalCount"
          :options="{
            page: pageIndex,
            itemsPerPage: pageSize,
            sortBy: sort.sortBy,
            sortDesc: sort.sortDesc,
          }"
          @update:options="paginate($event)"
          :footer-props="footerProps"
          multi-sort
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("driver_page.title") }}
                <TableSettings
                  class="ml-5"
                  :pIsVisible="tableInited && !isDefaultSettings"
                  :pSaveButtonTooltip="$localizationService.localize('driver_page.settings_tooltip.save')"
                  :pDefaultButtonTooltip=
                    "$localizationService.localize('driver_page.settings_tooltip.apply_default')"
                  @onSaveSettings="saveSettings()"
                  @onApplyDefaultSettings="applyDefaultSettings()"
                />
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchString"
                :label="$localizationService.localize('driver_page.search')"
                class="search-field"
                hide-details
                @input="onInputSearchString()"
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-2"
                @click="openEditDriverDialog()"
              >
                {{ $localizationService.localize("driver_page.new_btn") }}
              </v-btn>
              <EditDriverDialog ref="editDriverDialog" />
              <ConfirmationDialog ref="deleteDialog" />
            </v-toolbar>
          </template>

          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
              :key="header.value"
              :class="{
              'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center">
              <v-tooltip bottom z-index="100">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.name }}
                  </span>
                </template>
                <span>{{
                  item.aliases.length
                    ? item.aliases.join(", ")
                    : $localizationService.localize(
                        "driver_page.does_not_have_aliases"
                    )
                  }}</span>
              </v-tooltip>
              <MessageButton
                class="message-button ml-1"
                :target="MessagingTargets.driver(item.id)"
              />
            </div>
          </template>

          <template v-slot:[`item.invitationStatus`]="{ item }">
            <span v-if="item.invitationStatus == 'INVITED'">
              <v-badge color="orange" inline left></v-badge>
              {{ $localizationService.localize("driver_page.inivited") }}
              <v-btn
                x-small
                dark
                color="secondary"
                @click="revokeInvitation(item)"
                :loading="invitationInProgess[item.id]"
              >
                {{ $localizationService.localize("driver_page.revoke_btn") }}
              </v-btn>
            </span>
            <span v-else-if="item.invitationStatus == 'JOINED'">
              <v-badge color="green" inline left></v-badge>
              {{ $localizationService.localize("driver_page.joined") }}
            </span>
            <span v-else-if="item.invitationStatus == 'NOT_INVITED'">
              <v-badge color="red" inline left></v-badge>
              {{ $localizationService.localize("driver_page.not_invited") }}
              <v-btn
                x-small
                class="theme-text-color"
                color="secondary"
                @click="sendInvitation(item)"
                :loading="invitationInProgess[item.id]"
                >{{
                  $localizationService.localize("driver_page.invite_btn")
                }}</v-btn
              >
            </span>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDateAndTime(item.updatedAt) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-2" @click="openEditDriverDialog(item)"
              >mdi-pencil</v-icon
            >
            <v-icon medium color="red" @click="openDeleteDriverDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import lodash from "lodash";
import { MessagingTargets, momentDateAndTime } from "@/utils/utils";
import EditDriverDialog from "./EditDriverDialog.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import MessageButton from "../MessageButton";
import TableSettings from "@/components/TableSettings.vue";
import tableSortParserMixin from "@/utils/table-sort-parser";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Drivers",

  mixins: [tableSortParserMixin],

  components: {
    EditDriverDialog,
    ConfirmationDialog,
    MessageButton,
    TableSettings,
  },

  data: () => ({
    MessagingTargets,
    localStorageTable: "drivers.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    searchString: "",
    invitationInProgess: {},

    headers: [
      {
        text: locService.localize("driver_page.header.name"),
        value: "name",
        sortable: true,
      },
      {
        text: locService.localize("driver_page.header.transporter_id"),
        value: "transporterId",
        sortable: true,
      },
      {
        text: locService.localize("driver_page.header.email"),
        value: "email",
        sortable: true,
      },
      {
        text: locService.localize("driver_page.header.phone"),
        value: "phone",
        sortable: true,
      },
      {
        text: locService.localize("driver_page.header.invitation_status"),
        value: "invitationStatus",
        sortable: false,
      },
      {
        text: locService.localize("driver_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("driver_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("driver_page.header.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
  }),

  computed: {
    ...mapState("driverModule", [
      "currentPageLoading",
      "currentPage",
      "displayDrivers",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),
    ...mapState("driverTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("driverTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("driverTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("driverModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    saveSettings() {
      this.$store.commit("driverTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("driver_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("driverTableSettingsModule/applyDefaultSettings");
    },

    openEditDriverDialog(driver) {
      this.$refs.editDriverDialog.openDialog(driver);
    },

    openDeleteDriverDialog(driver) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("driver_page.dialog.delete.title"),
        text: locService.localize("driver_page.dialog.delete.text", [
          driver.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          try {
            const result = await this.$store.dispatch(
              "driverModule/deleteDriver",
              driver,
            );
            Vue.toasted.success(result, {
              duration: 5000,
            });
          } catch (e) {
            Vue.toasted.error(e, {
              duration: 5000,
            });
          }
        },
      });
    },

    reloadPage() {
      this.$store.dispatch("driverModule/reloadPage");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/drivers/?${params.toString()}`);
      }
    },

    onInputSearchString: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("driverModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),

    async sendInvitation(driver) {
      this.invitationInProgess[driver.id] = true;

      if (driver.email) {
        try {
          const result = await this.$store.dispatch(
            "driverModule/inviteDriver",
            driver,
          );
          Vue.toasted.success(result, {
            duration: 5000,
          });
        } catch (e) {
          Vue.toasted.error(e, {
            duration: 5000,
          });
        }
      } else {
        Vue.toasted.error(
          locService.localize("driver_page.invite.error_msg.without_email"),
          {
            duration: 5000,
          },
        );
        return;
      }

      this.invitationInProgess[driver.id] = false;
    },

    async revokeInvitation(driver) {
      this.invitationInProgess[driver.id] = true;

      try {
        const result = await this.$store.dispatch(
          "driverModule/revokeDriverInvitation",
          driver,
        );
        Vue.toasted.success(result, {
          duration: 5000,
        });
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }

      this.invitationInProgess[driver.id] = false;
    },
  },

  created() {
    this.$store.dispatch("driverTableSettingsModule/loadDefaultSettingsFromStorage");

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("driverTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("driverTableSettingsModule/applyQuerySettings", query);

      if (query.searchString) {
        this.searchString = query.searchString;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.v-badge {
  vertical-align: middle;
}
.driver-table-display {
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-field {
  max-width: 250px;
}
</style>
