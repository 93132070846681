export class RouteStagingImportMappings {
    id;

    name;

    mappings;

    constructor(id, name, mappings) {
      this.id = id;
      this.name = name;
      this.mappings = mappings;
    }

    static fromDto(dto) {
      return new RouteStagingImportMappings(
        dto.id,
        dto.name,
        dto.mappings,
      );
    }
}
