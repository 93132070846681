<template>
  <v-dialog persistent v-model="open" max-width="650px">
    <v-card class="pb-1" :loading="loading">
      <v-card-title class="headline">
        {{
          $localizationService.localize(
            "coaching_page.create_coaching_dialog.title"
          )
        }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container class="pa-0 pb-2">
          <v-text-field
            :label="
              $localizationService.localize(
                'coaching_page.create_coaching_dialog.param.name'
              )
            "
            v-model="coaching.name"
            :disabled="userInputDisabled"
          ></v-text-field>
          <v-textarea
            v-model="coaching.description"
            :label="
              $localizationService.localize(
                'coaching_page.create_coaching_dialog.param.description'
              )
            "
            rows="3"
            :disabled="userInputDisabled"
          ></v-textarea>
          <CoachingDialogInfractionsTable :infractions="infractions" />
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="
                  $localizationService.localize(
                    'coaching_page.create_coaching_dialog.param.driver_name'
                  )
                "
                v-model="coaching.targetDriver.name"
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="popUpMenu"
                :nudge-right="50"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedTargetDateTime"
                    :label="
                      $localizationService.localize(
                        'coaching_page.create_coaching_dialog.param.target_time'
                      )
                    "
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="userInputDisabled"
                    :rules="targetDateTimeRules"
                  >
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="popUpMenu == 'time'"
                  v-model="targetTimeModel"
                  scrollable
                  @click:minute="popUpMenu = false"
                >
                </v-time-picker>
                <v-date-picker
                  v-else
                  v-model="targetDateModel"
                  @input="popUpMenu = 'time'"
                  :min="momentNow"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()" :disabled="userInputDisabled">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="createCoaching()"
          :disabled="userInputDisabled || !canCreate"
        >
          {{ $localizationService.localize("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Vue from "vue";
import { dateMomentFormat, dateTimeMomentFormat } from "../../utils/utils";
import CoachingDialogInfractionsTable from "./CoachingDialogInfractionsTable.vue";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CreateCoachingDialog",

  components: {
    CoachingDialogInfractionsTable,
  },

  data: () => ({
    targetDateTimeRules: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

    open: false,

    popUpMenu: false,

    targetDateModel: undefined,
    targetTimeModel: undefined,

    coaching: {
      name: "",
      description: "",
      targetDriver: {},
      targetDateTime: undefined,
    },

    infractions: [],
  }),

  watch: {
    targetDateModel() {
      this.updateDateTimeInCoaching();
    },
    targetTimeModel() {
      this.updateDateTimeInCoaching();
    },
  },

  computed: {
    ...mapState("coachingModule", ["processingCoaching"]),

    canCreate() {
      return (
        this.coaching.name
        && this.coaching.targetDriver
        && this.coaching.targetDateTime
        && this.infractions.length
      );
    },

    userInputDisabled() {
      return this.loading;
    },

    loading() {
      return this.processingCoaching;
    },

    formattedTargetDateTime() {
      return this.coaching.targetDateTime
        ? moment(this.coaching.targetDateTime).format(dateTimeMomentFormat)
        : "";
    },

    momentNow() {
      return moment().toISOString();
    },
  },

  emits: ["newCoachingCreated"],

  methods: {
    updateDateTimeInCoaching() {
      this.coaching.targetDateTime = `${this.targetDateModel}T${
        this.targetTimeModel || "00:00"
      }`;
    },

    openForNewCoaching(driver, infractions) {
      this.coaching = {
        targetDriver: driver,
        name: locService.localize(
          "coaching_page.create_coaching_dialog.open_for_new_coaching.name",
          [driver.name, moment().format(dateMomentFormat)],
        ),
        targetDateTime: undefined,
      };

      this.infractions = infractions;
      this.open = true;
    },

    async createCoaching() {
      try {
        const result = await this.$store.dispatch(
          "coachingModule/createCoaching",
          {
            name: this.coaching.name,
            description: this.coaching.description,
            targetDriverId: this.coaching.targetDriver.id,
            targetDateTime: this.coaching.targetDateTime,
            targetInfractionIds: this.infractions.map((it) => it.id),
          },
        );

        Vue.toasted.success(
          locService.localize("toasted.successfully_create", [result.name]),
          { duration: 5000 },
        );
        this.close();
        this.$emit("newCoachingCreated", result);
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }
    },

    close() {
      this.open = false;
    },
  },
};
</script>
