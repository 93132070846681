<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-card>
      <v-card-title class="d-flex justify-center">
        {{ $localizationService.localize("blank_page.no_pages") }}
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BlankPage",

  routing: {
    path: "/blank",
    hasAccess: () => true,
  },

  computed: {
    ...mapState("coreModule", ["drawer"]),
  },
};
</script>
