/* eslint-disable no-underscore-dangle */
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import axios from "axios";
import { store } from "../../main";
import { bodyWithAuthHeader, ROCKET_CHAT_API } from "../../utils/utils";

export default class WebsocketBulk {
  messages = {}

  handlers = {}

  subsActions = {}

  myUsername;

  toMarkAsSend = {}

  constructor(onReadyToWork) {
    this.onReadyToWork = onReadyToWork;
  }

  async connect() {
    const rawData = await axios.get(`${ROCKET_CHAT_API}/api/v1/users.info?userId=${store.state.rocketChatModule.userId}`, {
      headers: {
        "X-Auth-Token": store.state.rocketChatModule.token,
        "X-User-Id": store.state.rocketChatModule.userId,
      },
    });

    this.myUsername = rawData.data.user.username;

    console.log(`this.myUsername: ${this.myUsername}`);

    this.websocket = new WebSocket(`wss://${ROCKET_CHAT_API.replace("https://", "")}/websocket`);
    this.websocket.onmessage = this.onMessage.bind(this);
    this.websocket.onopen = this.onOpen.bind(this);
  }

  async markToSend(message) {
    const packetId = uuidv4();

    const healingTimeout = setTimeout(() => {
      console.log(`Self healing initiated: ${message.id} ${emoji}`);
      markToSend(message);
    }, 30000);

    this.handlers[message.id] = () => {
      clearTimeout(healingTimeout);
      // onEmojiSet(message);
    };

    try {
      this.toMarkAsSend[message.id] = () => {
        this.sendMessage({
          msg: "method",
          method: "setReaction",
          id: packetId,
          params: [
            "send_sms", // emoji,
            message.id,
            true,
          ],
        });
      };
    } catch (e) {
      console.error(e);
    }
  }

  async sendMessageToDriver(driver, message, onSent, onMarkedAsSent, onMarkedAsAccepted) {
    const messageRocketChatId = uuidv4();

    this.messages[messageRocketChatId] = {
      id: messageRocketChatId,
      status: "NEW",
      onSent,
      onMarkedAsSent,
      onMarkedAsAccepted,
    };

    try {
      const body = await bodyWithAuthHeader();
      const chatId = await API.get("core", `/messaging/direct/driver/${driver.id}`, body);
      const msg = await API.post("core", "/messaging/template-engine",
        { ...await bodyWithAuthHeader(), body: { recipient: driver, text: message } });
      const subId = uuidv4();

      this.sendMessage({
        id: subId,
        msg: "sub",
        name: "stream-room-messages",
        params: [
          chatId,
          false,
        ],
      });

      this.subsActions[subId] = () => this.sendMessage({
        msg: "method",
        method: "sendMessage",
        id: messageRocketChatId,
        params: [
          {
            _id: messageRocketChatId,
            rid: chatId,
            msg: msg.toString(),
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  }

  sendMessage(message) {
    this.websocket.send(JSON.stringify(message));
  }

  onOpen() {
    this.sendMessage({ msg: "connect", version: "1", support: ["1"] });
  }

  // eslint-disable-next-line class-methods-use-this
  onMessage(message) {
    message = JSON.parse(message.data);

    if (message.msg == "ping") {
      this.sendMessage({ msg: "pong" });
    }

    if (message.msg == "connected") {
      console.log("connected!");

      this.loginPacketId = uuidv4();

      this.sendMessage({
        id: this.loginPacketId,
        msg: "method",
        method: "login",
        params: [{ resume: store.state.rocketChatModule.token }],
      });
    }

    if (message.msg == "result") {
      if (message.id == this.loginPacketId) {
        console.log("WSS loged in");
        this.onReadyToWork();
      }

      const suspectMessage = this.messages[message.id];
      if (suspectMessage) {
        suspectMessage.status = "SENT";
        store.commit("rocketChatModule/updateHandledEventIds", message.id);
        suspectMessage.onSent(suspectMessage);
      }
    }

    if (message.msg == "changed" && message.collection == "stream-room-messages") {
      try {
        const arg = message.fields.args[0];

        if (arg.reactions) {
          const sentReaction = arg.reactions[":sms_sent:"];

          if (sentReaction && sentReaction.usernames.length >= 1) {
            // eslint-disable-next-line no-underscore-dangle
            const rcMessage = this.messages[arg._id];
            if (!rcMessage.onMarkedAsSentCalled) {
              rcMessage.onMarkedAsSent(rcMessage);
              rcMessage.onMarkedAsSentCalled = true;
            }
          }

          const acceptReaction = arg.reactions[":sms_in_process:"];

          if (acceptReaction && acceptReaction.usernames.length > 0) {
            // eslint-disable-next-line no-underscore-dangle
            const rcMessage = this.messages[arg._id];
            rcMessage.onMarkedAsAccepted(rcMessage);
          }

          const sendSms = arg.reactions[":send_sms:"];

          try {
            if (sendSms && sendSms.usernames.length > 0) {
            // eslint-disable-next-line no-underscore-dangle

              if (sendSms.usernames.includes(this.myUsername)) {
                const handler = this.handlers[arg._id];
                if (handler) {
                  handler();
                  this.handlers[arg._id] = undefined;
                  console.log("Clear self healing handler");
                }
              } else {
                const toMark = this.toMarkAsSend[arg._id];
                if (toMark) toMark();
              }
            }
          } catch (e) {
            console.log(e);
          }
          /*
          // To any reactions
          Object.entries(arg.reactions).forEach(([reaction, rData]) => {
            try {
              const sanitizedReactionName = reaction.replaceAll(":", "");
              if (rData.usernames && rData.usernames.find((suspect) => suspect == this.myUsername)) {
                const handler = this.handlers[`${arg._id}${sanitizedReactionName}`];
                if (handler) {
                  handler();
                  this.handlers[`${arg._id}${sanitizedReactionName}`] = undefined;
                  console.log("Clear self healing handler");
                }
              }
            } catch (e) {
              console.error(e);
            }
          });
          */
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (message.msg == "ready") {
      try {
        message.subs.forEach((it) => {
          try {
            this.subsActions[it]();
          } catch (e2) {
            console.error(e2);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}
/*
data class LoginPacket(val token: String, val id: UUID) {
  val msg = "method"
  val method = "login"
  val params = listOf(mapOf("resume" to token))
}
*/
