import lodash from "lodash";
import Vue from "vue";
import {
  REPORT_PROCESS_STATUS_CLASSIFIED,
  REPORT_PROCESS_STATUS_RECEIVED,
  REPORT_STATUS_DATA_NOT_FOUND,
  REPORT_STATUS_DATA_RECOGNITION_FAILED,
  REPORT_STATUS_DATE_UNAVAILABLE,
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  REPORT_STATUS_PARSED,
  REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING,
  REPORT_STATUS_TYPE_DETECTION_FAILED,
} from "./utils";

const locService = Vue.prototype.$localizationService;

export const CALENDAR_DISPLAY_STATUSES = lodash.keyBy([
  {
    value: REPORT_STATUS_TYPE_DETECTION_FAILED.value,
    text: locService.localize(
      "report_calendar.report_status_text.detection_failed",
    ),
  },
  {
    value: REPORT_STATUS_DATA_RECOGNITION_FAILED.value,
    text: locService.localize(
      "report_calendar.report_status_text.recognition_failed",
    ),
  },
  {
    value: REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING.value,
    text: locService.localize(
      "report_calendar.report_status_text.error_during_parsing",
    ),
  },
  {
    value: REPORT_STATUS_MANUAL_ACTION_REQUIRED.value,
    text: locService.localize(
      "report_calendar.report_status_text.mar",
    ),
  },
  {
    value: REPORT_STATUS_DATA_NOT_FOUND.value,
    text: locService.localize(
      "report_calendar.report_status_text.not_found",
    ),
  },
  {
    value: REPORT_STATUS_PARSED.value,
    text: locService.localize(
      "report_calendar.report_status_text.parsed",
    ),
  },
  {
    value: REPORT_PROCESS_STATUS_RECEIVED.value,
    text: locService.localize(
      "report_calendar.processing",
    ),
  },
  {
    value: REPORT_PROCESS_STATUS_CLASSIFIED.value,
    text: locService.localize(
      "report_calendar.processing",
    ),
  },
  {
    value: REPORT_STATUS_DATE_UNAVAILABLE.value,
    text: locService.localize(
      "report_calendar.report_status_text.date_unavailable",
    ),
  },
], "value");
