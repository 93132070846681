/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
export class VehicleInspectionItemScheme {
  id;

  name;

  instruction;

  createdAt;

  updatedAt;

  myOrder;

  constructor(
    id,
    name,
    instruction,
    createdAt,
    updatedAt,
    myOrder,
  ) {
    this.id = id;
    this.name = name;
    this.instruction = instruction;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.myOrder = myOrder;
  }

  static fromDto(dto) {
    switch (dto.type) {
      case "InspectionFailPassItem":
        return VehicleInspectionFailPassItemScheme.fromDto(dto);
      case "InspectionOdometerItem":
        return VehicleInspectionOdometerItemScheme.fromDto(dto);
      case "InspectionNumberValueItem":
        return VehicleInspectionNumberItemScheme.fromDto(dto);
    }
    throw new Error("Unsupported type!");
  }
}

export class VehicleInspectionFailPassItemScheme extends VehicleInspectionItemScheme {
  requirePhotoOnPass;

  requirePhotoOnFail;

  requireRepairVerification;

  get type() {
    return "InspectionFailPassItem";
  }

  constructor(
    id,
    name,
    instruction,
    createdAt,
    updatedAt,
    myOrder,
    requirePhotoOnPass,
    requirePhotoOnFail,
    requireRepairVerification,
  ) {
    super(id, name, instruction, createdAt, updatedAt, myOrder);

    this.requirePhotoOnPass = requirePhotoOnPass;
    this.requirePhotoOnFail = requirePhotoOnFail;
    this.requireRepairVerification = requireRepairVerification;
  }

  toDto() {
    return {
      id: this.id,
      name: this.name,
      instruction: this.instruction,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      myOrder: this.myOrder,
      requirePhotoOnPass: this.requirePhotoOnPass,
      requirePhotoOnFail: this.requirePhotoOnFail,
      requireRepairVerification: this.requireRepairVerification,
      type: this.type,
    };
  }

  static fromDto(dto) {
    return new VehicleInspectionFailPassItemScheme(
      dto.id,
      dto.name,
      dto.instruction,
      dto.createdAt,
      dto.updatedAt,
      dto.myOrder,
      dto.requirePhotoOnPass,
      dto.requirePhotoOnFail,
      dto.requireRepairVerification,
    );
  }
}

export class VehicleInspectionOdometerItemScheme extends VehicleInspectionItemScheme {
  requirePhoto;

  get type() {
    return "InspectionOdometerItem";
  }

  constructor(
    id,
    name,
    instruction,
    createdAt,
    updatedAt,
    myOrder,
    requirePhoto,
  ) {
    super(id, name, instruction, createdAt, updatedAt, myOrder);

    this.requirePhoto = requirePhoto;
  }

  toDto() {
    return {
      id: this.id,
      name: this.name,
      instruction: this.instruction,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      myOrder: this.myOrder,
      requirePhoto: this.requirePhoto,
      type: this.type,
    };
  }

  static fromDto(dto) {
    return new VehicleInspectionOdometerItemScheme(
      dto.id,
      dto.name,
      dto.instruction,
      dto.createdAt,
      dto.updatedAt,
      dto.myOrder,
      dto.requirePhoto,
    );
  }
}

export class VehicleInspectionNumberItemScheme extends VehicleInspectionItemScheme {
  requirePhoto;

  get type() {
    return "InspectionNumberValueItem";
  }

  constructor(
    id,
    name,
    instruction,
    createdAt,
    updatedAt,
    myOrder,
    requirePhoto,
  ) {
    super(id, name, instruction, createdAt, updatedAt, myOrder);

    this.requirePhoto = requirePhoto;
  }

  toDto() {
    return {
      id: this.id,
      name: this.name,
      instruction: this.instruction,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      myOrder: this.myOrder,
      requirePhoto: this.requirePhoto,
      type: this.type,
    };
  }

  static fromDto(dto) {
    return new VehicleInspectionNumberItemScheme(
      dto.id,
      dto.name,
      dto.instruction,
      dto.createdAt,
      dto.updatedAt,
      dto.myOrder,
      dto.requirePhoto,
    );
  }
}
