<template>
  <v-container fluid fill-height class="pa-0">
    <v-row class="text-center dashboard-row no-gutters">
      <v-col cols="12" class="pt-0 pr-0">
        <v-overlay
          :absolute="true"
          opacity="1"
          color="grafanaOverlayColor"
          :value="grafanaLoading"
        >
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
        <iframe
          v-show="!grafanaLoading && companyId && accessToken"
          id="dashboard-frame"
          :src="frameLink"
          width="100%"
          height="100%"
          frameborder="0"
          target="_self"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import uniq from "lodash/uniq";
import router from "../router";
import {
  localStorageDates,
  setLocalStorageData,
  getLocalStorageData,
} from "../utils/utils";
import { THEME } from "../app-theme";

export default {
  name: "Dashboard",

  props: ["grafanaPath"],

  data: () => ({
    grafanaLoading: true,
    query: {},
  }),

  methods: {
    startLoading() {
      this.grafanaLoading = true;
    },
    endLoading() {
      this.grafanaLoading = false;
    },
  },

  computed: {
    ...mapGetters("userModule", ["companyId", "accessToken"]),
    frameLink() {
      // if we got a URL change from grafana,
      // don't update state because this will trigger unnecessary iframe reload/navigation
      // then we get the query form the this.query (getting on mounted from $route.query)
      const grafanaPath = this.query.gpath || this.grafanaPath;

      const query = {
        ...this.query,
        "var-companyId": this.companyId,
        "var-token": this.accessToken,
        theme: THEME.grafanaTheme,
        orgId: 1,

        /**
         * Adding DIRTY-CHECK
         *
         * If this function was called, then URL query has been changed.
         * This means we likely navigated back or forth in history and we want our Grafana to reload.
         *
         * BUT! There is a chance that resulting "const link" won't differ from what it previously look like,
         * so Vue won't update binding and reload won't happen.
         *
         * To avoid this, we must make it dirty. Thus, adding dsps-ts dirtiness param.
         */
        "dsps-ts": new Date().getTime(),
      };

      const datesFromLocalStorage = getLocalStorageData(localStorageDates);
      if (datesFromLocalStorage) {
        query.from = datesFromLocalStorage[0];
        query.to = datesFromLocalStorage[1];
      }

      const queryParams = new URLSearchParams();
      Object.entries(query).forEach(
        ([paramName, paramValue]) => {
          if (Array.isArray(paramValue)) {
            paramValue.forEach((value) => queryParams.append(paramName, value));
          } else {
            queryParams.append(paramName, paramValue);
          }
        },
      );

      return `${
        process.env.VUE_APP_GRAFANA_PROXY
        || "https://grafana-proxy.demo.dsps.softwea.com"
      }${grafanaPath}?${queryParams.toString()}`;
    },
  },

  async mounted() {
    this.query = this.$route.query;
    const onGrafanaLoaded = () => {
      document
        .getElementById("dashboard-frame")
        .contentWindow.postMessage(
          // eslint-disable-next-line no-restricted-globals
          { type: "dsps.parent.register", url: location.origin },
          "*",
        );
      this.endLoading();
    };

    const onGrafanaUrlChanged = async (urlString) => {
      const url = new URL(urlString);

      url.searchParams.delete("var-token");

      const from = url.searchParams.get("from");
      const to = url.searchParams.get("to");
      setLocalStorageData(localStorageDates, [from, to]);

      // Grafana can have multiple values per each url param, for ex, ?var-columns=driver_late_count&var-columns=call_out_count
      // So we create an object that we're going to pass to Vue router: { "var-columns": [ "driver_late_count", "call_out_count" ] }
      const grafanaSearchParams = Array.from(url.searchParams.entries()).reduce((acc, [paramName, paramValue]) => {
        acc[paramName] = uniq((acc[paramName] || []).concat([paramValue]));
        return acc;
      }, {});

      try {
        await router.push({
          query: {
            ...this.$route.query,
            gpath: url.pathname,
            ...grafanaSearchParams,
          },
          params: { ...this.$route.params, fromGrafana: true },
        });
      } catch (e) {
        console.log(e.message);
      }
    };

    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    const grafanaLoadedEventName = "dsps.grafana.loaded";
    const urlChangedEventName = "dsps.grafana.url.changed";

    await eventer(
      messageEvent,
      // have to debounce because grafana may change its URL in multiple steps
      await debounce(async (e) => {
        // listen to grafana loaded event
        if (
          (e.data && e.data.type === grafanaLoadedEventName)
          || (e.message && e.message.type === grafanaLoadedEventName)
        ) {
          onGrafanaLoaded();
          // listen to grafana URL changed event
        } else if (
          (e.data && e.data.type === urlChangedEventName)
          || (e.message && e.message.type === urlChangedEventName)
        ) {
          let grafanaUrl;
          if (e.data) {
            grafanaUrl = e.data.url;
          } else if (e.message) {
            grafanaUrl = e.message.url;
          } else {
            grafanaUrl = null;
          }
          await onGrafanaUrlChanged(grafanaUrl);
          onGrafanaLoaded();
        }
      }, 500),
    );
  },
};
</script>

<style scoped>
.dashboard-row {
  height: 100%;
  position: relative;
}
</style>
