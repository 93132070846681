export class Scan {
    id;

    initiatedById;

    companyId;

    fromTime;

    toTime;

    targetTypes;

    criteriaId;

    startedAt;

    finishedAt;

    status;

    createdAt;

    createdInfractionIds;

    isInProcess() {
      return this.status != "COMPLETE" && this.status != "ENGINE_ERROR";
    }

    static fromDto(dto) {
      const scan = new Scan();

      scan.id = dto.id;
      scan.initiatedById = dto.initiatedBy;
      scan.companyId = dto.company;
      scan.fromTime = dto.fromTime;
      scan.toTime = dto.toTime;
      scan.targetTypes = dto.targetTypes;
      scan.criteriaId = dto.criteria;
      scan.startedAt = dto.startedAt;
      scan.finishedAt = dto.finishedAt;
      scan.status = dto.status;
      scan.createdAt = dto.createdAt;
      scan.createdInfractionIds = dto.createdInfractions;

      return scan;
    }
}
