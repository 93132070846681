






















import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { namespace } from "vuex-class";
import PhoneCallModule from "@/store/phone-call.store";
import { PhoneAgentDto } from "@/model/phone-agent.model";

const phoneCallModule = PhoneCallModule.namespace;
const rocketChatModule = namespace("rocketChatModule");
const userModule = namespace("userModule");
const locService = Vue.prototype.$localizationService;

@Component
export default class PhoneCall extends Vue {
  loading = false

  @rocketChatModule.Getter
  recipientId!: string

  @rocketChatModule.Getter
  deviceRecipientId!: string

  @rocketChatModule.State
  directMessagingChannel!: any;

  @rocketChatModule.State
  loadingDirectChannel!: boolean;

  @userModule.State
  selectedPhoneAgent!: PhoneAgentDto | null

  @phoneCallModule.Action
  call!: (payload: any) => Promise<void>

  async callPhone() {
    if (!this.selectedPhoneAgent) {
      Vue.toasted.error(
        locService.localize("rocket_chat.selected_phone_agent_not_found"),
        { duration: 5000 },
      );
      return;
    }
    this.loading = true;
    await this.call({ channelId: this.directMessagingChannel.id });
    this.loading = false;
  }

  async mounted() {
    getModule(PhoneCallModule, this.$store);
  }
}
