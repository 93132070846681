







































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TableSettings extends Vue {
  @Prop() pIsVisible: boolean | undefined;

  @Prop() pIsDisabled: boolean | undefined;

  @Prop() pSaveButtonTooltip: string | undefined;

  @Prop() pDefaultButtonTooltip: string | undefined;
}
