import moment from "moment";
import Vue from "vue";
import {
  REPORT_STATUSES_BY_VALUE,
  REPORT_STATUS_TYPE_DETECTION_FAILED,
  REPORT_PROCESS_STATUS_RECEIVED,
  REPORT_PROCESS_STATUS_CLASSIFIED,
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  REPORT_STATUS_DATA_NOT_FOUND,
  REPORT_STATUS_PARSED,
  REPORT_TYPES_BY_VALUE,
} from "../utils/utils";

const locService = Vue.prototype.$localizationService;

export class Report {
    id;

    type = locService.localize("model.report.type.not_classified") ;

    targetDate;

    uploadedAt;

    status = "UNKNOWN";

    warnings = [];

    // Optional
    file;

    binKey;

    createdAt;

    constructor(
      id,
      status,
      targetDate,
      type,
      warnings,
      uploadedAt,
      file,
      binKey,
      createdAt,
    ) {
      this.id = id;
      this.status = status;
      this.targetDate = targetDate;
      this.type = type;
      this.warnings = warnings;
      this.uploadedAt = uploadedAt;
      this.file = file;
      this.binKey = binKey;
      this.createdAt = createdAt;
    }

    getDisplayStatus() {
      return (REPORT_STATUSES_BY_VALUE[this.status] || {}).text
        || locService.localize("model.report.processing");
    }

    isInProcess() {
      return this.status == REPORT_PROCESS_STATUS_RECEIVED.value
            || this.status == REPORT_PROCESS_STATUS_CLASSIFIED.value;
    }

    get isParsed() {
      return this.status === REPORT_STATUS_PARSED.value;
    }

    get isMar() {
      return this.status === REPORT_STATUS_MANUAL_ACTION_REQUIRED.value;
    }

    get isError() {
      return this.status === REPORT_STATUS_DATA_NOT_FOUND.value;
    }

    get startDate() {
      return REPORT_TYPES_BY_VALUE[this.type].getStartDate(this.targetDate);
    }

    getFormattedTargetDate() {
      return this.targetDate ? moment(this.targetDate).format("MMM Do YY") : "";
    }

    getFormattedUploadedAt() {
      return this.uploadedAt ? moment(this.uploadedAt).format("MMM Do YY HH:mm") : "";
    }

    static fromDto(dto) {
      return new Report(
        dto.id,
        dto.status,
        dto.targetDate,
        dto.status == REPORT_STATUS_TYPE_DETECTION_FAILED.value
          ? locService.localize("model.report.type.unknown")
          : dto.displayTypeName,
        dto.parseWarnings,
        new Date(dto.uploadedAt * 1000),
        undefined,
        dto.binKey,
        dto.createdAt,
      );
    }
}
