<template>
  <Devices />
</template>

<script>
export default {
  name: "DevicesPage",

  routing: {
    localizationKey: "menu.tab.devices",
    icon: "mdi-cellphone",
    path: "/devices",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Devices: () => import("../components/devices/Devices.vue"),
  },
};
</script>
