<template>
  <v-text-field
    :label="label"
    :dense="!label"
    v-model="newStaging"
    error-count="0"
    hide-details
    @keydown.enter="$emit('submit')"
    @keydown.esc="$emit('cancel')"
    autofocus
  >
  </v-text-field>
</template>

<script>
export default {
  name: "StagingSelect",

  props: {
    value: String,
    label: String,
  },

  data: () => ({
    newStaging: "",
  }),

  watch: {
    newStaging() {
      this.$emit("input", this.newStaging);
    },
    value() {
      this.newStaging = this.value;
    },
  },

  mounted() {
    this.newStaging = this.value;
  },
};
</script>
