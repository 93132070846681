/* eslint-disable guard-for-in */
import API from "@aws-amplify/api";
import Vue from "vue";
import { Subject } from "../mar/subject.util-model";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {

  name: "marModule",
  namespaced: true,
  state: {

    requestAgents: undefined,
    currentSubject: undefined,
    currentRequestsComponents: undefined,
    sendingSolutions: false,
    loadingSubject: false,
    autoIgnoreRequests: [],
    subSystemLoading: false,
  },
  mutations: {

    updateAutoIgnoreRequests(state, newAutoIgnoreRequests) {
      state.autoIgnoreRequests = newAutoIgnoreRequests;
    },

    setRequestAgents(state, newRequestAgents) {
      state.requestAgents = newRequestAgents;
    },

    setCurrentSubject(state, newSubject) {
      state.currentSubject = newSubject;
      state.autoIgnoreRequests = [];
    },

    registerRequestComponent(state, component) {
      const prev = state.currentRequestsComponents[component.request.id];

      if (prev) {
        Object.assign(component.$data, prev.data);
      }

      Vue.set(state.currentRequestsComponents, component.request.id, { currentInstance: component, data: component.$data });
    },

    keepRequestComponentData(state, component) {
      Vue.set(state.currentRequestsComponents, component.request.id, {
        currentInstance: component,
        data: component.$data,
        lastSolution: component.solution,
      });
    },

    clearCurrentRequestsComponents(state) {
      state.currentRequestsComponents = {};
    },

    beginSendSolutions(state) {
      state.sendingSolutions = true;
    },

    endSendSolutions(state) {
      state.sendingSolutions = false;
    },

    beginLoadSubject(state) {
      state.loadingSubject = true;
    },

    endLoadSubject(state) {
      state.loadingSubject = false;
    },

    setSubSystemLoading(state, loading) {
      state.subSystemLoading = loading;
    },
  },
  actions: {

    loadRequestAgents({ commit, state }) {
      if (state.requestAgents) return;

      const context = require.context("@/mar/agents", false, /.\.vue$/);

      const modules = context.keys().map((x) => context(x).default);

      commit("setRequestAgents", Object.fromEntries(modules.map((module) => {
        if (!module.mixins) module.mixins = [];

        module.mixins.push({

          mounted() {
            this.$mar.registerRequestComponent(this);
          },

          beforeDestroy() {
            this.$mar.keepRequestComponentData(this);
          },
        });

        return [module.targetRequestType, module];
      })));
    },

    async loadSubject({ commit, state }, { id, type }) {
      commit("beginLoadSubject");
      commit("clearCurrentRequestsComponents");

      const body = await bodyWithAuthHeader();

      try {
        const requests = (await API.get("core", `/mar/${type}/${id}`, body)).map((dto) => {
          const agent = state.requestAgents[dto.type];

          return agent.toModel ? agent.toModel(dto) : dto;
        }).sort((a, b) => a.id.localeCompare(b.id));

        commit("setCurrentSubject", new Subject(id, type, requests));
      } catch (error) {
        Vue.toasted.error(error);
      } finally {
        commit("endLoadSubject");
      }
    },

    async sendSolutions({ state, commit }) {
      commit("beginSendSolutions");

      try {
        const solutions = [];
        const failedRequests = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const it of state.currentSubject.requests) {
          const component = state.currentRequestsComponents[it.id];

          let solution = it.getAutoGeneratedSolution();

          if (component) { solution = component.currentInstance.solution || component.lastSolution; }

          if (!solution && state.autoIgnoreRequests.find((suspect) => suspect.id == it.id)) {
            solution = it.getAutoIgnoreSolution();
          }

          if (!solution) throw new Error(locService.localize("store.mar.send_solutions_error_msg", [it.id]));

          solution.id = it.id;
          solution.type = it.type;

          try {
            // eslint-disable-next-line no-await-in-loop
            solution = solution.preSendTransformer ? (await solution.preSendTransformer()) : solution;
            solutions.push(solution);
          } catch (error) {
            Vue.toasted.error(error);
            failedRequests.push(it);
          }
        }

        const body = {
          ...await bodyWithAuthHeader(),
          body: solutions,
        };

        const request = API.post("core", "/mar/resolve", body);

        request.finally(() => commit("endSendSolutions"));
        request.catch((error) => handleHttpError(error));
        request.then(() => {
          state.currentSubject.requests = failedRequests;
        });

        return request;
      } catch (error) {
        Vue.toasted.error(error);
        commit("endSendSolutions");
      }
      return undefined;
    },
  },
  getters: {
    allSolutionsComplete: (state) => state.currentSubject && !state.currentSubject.requests.find((request) => {
      // find without any solution
      const component = state.currentRequestsComponents[request.id];

      return (
        !component
          ? !request.getAutoGeneratedSolution()
          : !component.currentInstance.solution
            && !component.lastSolution
      ) && !(state.autoIgnoreRequests.find((suspect) => suspect.id == request.id)
        ? request.getAutoIgnoreSolution()
        : false
      );
    }),
  },
};
