import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import { CloseIssueActionDraftTemplateDto, TriggerActionType } from "@/types/types";
import { TRIGGER_ACTION_CLOSE_ISSUE } from "@/utils/ts-utils";
import { UserInfo } from "@/model/user-info.model";
import { Trigger } from "@/model/trigger/trigger.model";

export class CloseIssueActionDraftTemplate extends ActionDraftTemplate {
    relatedVehicle: string;

    authorUser: UserInfo | null;

    authorTrigger: Trigger<any> | null;

    authorExpression: string | null;

    constructor(
      type: TriggerActionType,
      order: number,
      relatedVehicle: string,
      authorUser: UserInfo | null,
      authorTrigger: Trigger<any> | null,
      authorExpression: string | null,
    ) {
      super(type, order);
      this.relatedVehicle = relatedVehicle;
      this.authorUser = authorUser;
      this.authorTrigger = authorTrigger;
      this.authorExpression = authorExpression;
    }

    toDto() {
      return {
        type: this.type?.value,
        order: this.order,
        relatedVehicle: this.relatedVehicle,
        userId: this.authorUser?.id,
        triggerId: this.authorTrigger?.id,
        authorExpression: this.authorExpression,
      };
    }

    clone(): CloseIssueActionDraftTemplate {
      return new CloseIssueActionDraftTemplate(
        this.type,
        this.order,
        this.relatedVehicle,
        this.authorUser,
        this.authorTrigger,
        this.authorExpression,
      );
    }

    static fromDto(dto: CloseIssueActionDraftTemplateDto): CloseIssueActionDraftTemplate {
      return new CloseIssueActionDraftTemplate(
        TRIGGER_ACTION_CLOSE_ISSUE,
        dto.order || 0,
        dto.relatedVehicle || "",
        dto.authorUser || null,
        dto.authorTrigger || null,
        dto.authorExpression || "",
      );
    }

    static createEmpty() {
      return new CloseIssueActionDraftTemplate(
        TRIGGER_ACTION_CLOSE_ISSUE,
        0,
        "",
        null,
        null,
        null,
      );
    }
}
