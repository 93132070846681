export class VehicleActionType {
  constructor(
    public id: string,
    public name: string,
    public createdAt: string,
    public updatedAt: string,
    public mutable: boolean,
  ) {}

  static fromDto(dto: any) {
    return new VehicleActionType(
      dto.id,
      dto.name,
      dto.createdAt,
      dto.updatedAt,
      dto.mutable,
    );
  }

  static copy(obj: any) {
    return new VehicleActionType(
      obj.id,
      obj.name,
      obj.createdAt,
      obj.updatedAt,
      obj.mutable,
    );
  }
}
