<template>
  <v-dialog v-model="open" max-width="fit-content">
    <v-card :loading="loading" v-if="open">
      <v-stepper v-model="step" class="import-stepper">
        <v-stepper-header>
          <v-stepper-step
            v-for="it of headers"
            :key="it.step"
            :complete="step > it.step"
            :rules="[() => !it.errorText]"
            :step="it.step"
          >
            {{ it.displayText }}
            <small v-if="it.errorText">
              {{ it.errorText }}
            </small>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container class="container-padding">
              <div v-if="importMode == DRP_IMPORT_AMAZON_LOGISTICS">
                <ImportAmazonLogistics
                  ref="importAmazonLogistics"
                  :pLoading="loading"
                  :pTargetDate="pTargetDate"
                  @onInput="importAmazonLogisticsCompleted = $event"
                />
              </div>
            </v-container>
            <div class="d-flex">
              <v-btn text @click="closeDialog()">
                {{ $localizationService.localize("btn.cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="sendImportData()"
                :disabled="loading || !importAmazonLogisticsCompleted"
              >
                {{ $localizationService.localize("btn.continue") }}
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-container class="container-padding">
              <TemplateSettings
                ref="templateSettings"
                :pLoading="loading"
                :pImportMode="importMode"
                :pStoreModule="storeModule"
                @onChangeTemplate="selectedTemplate = $event"
              />
            </v-container>
            <div class="d-flex">
              <v-btn text @click="closeDialog()">
                {{ $localizationService.localize("btn.cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <!--
              <v-btn text :disabled="loading" @click="step = 1">
                {{
                  $localizationService.localize("drp_import.stepper.previous_btn")
                }}
              </v-btn>
              -->
              <v-btn
                text
                class="ml-2"
                color="primary"
                :disabled="loading || !selectedTemplate"
                @click="sendTemplateSettings()"
              >
                {{ $localizationService.localize("btn.continue") }}
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container class="container-padding">
              <MappingSettings
                ref="mappingStep"
                :pLoading="loading"
                :pImportSession="importSession"
                :pImportMode="importMode"
                :pStoreModule="storeModule"
                @onUpdateSelectedMappingState="modelMappingCompleted = $event"
              />
            </v-container>
            <div class="d-flex">
              <v-btn text @click="closeDialog()">
                {{ $localizationService.localize("btn.cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <!--
              <v-btn text :disabled="loading" @click="step = 1">
                {{
                  $localizationService.localize("drp_import.stepper.previous_btn")
                }}
              </v-btn>
              -->
              <v-btn
                text
                class="ml-2"
                color="primary"
                @click="initiationMapping()"
                :disabled="loading || !modelMappingCompleted"
              >
                {{
                  $localizationService.localize("drp_import.stepper.map_btn")
                }}
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="4">
            <ReportMar
              pDisableBackgroundColor
              :pContentPadding="'pl-4 pr-4 pb-2'"
              :pTitleIsVisible="false"
              :pCardMaxHeight="50"
              pHideLoading
            />
            <div class="d-flex">
              <v-btn text :disabled="sendingSolutions" @click="closeDialog()">
                {{ $localizationService.localize("btn.cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <!--
              <v-btn text :disabled="sendingSolutions" @click="step = 1">
                {{
                  $localizationService.localize("drp_import.stepper.previous_btn")
                }}
              </v-btn>
              -->
              <v-btn
                text
                class="ml-2"
                color="primary"
                :disabled="sendingSolutions || !allSolutionsComplete"
                @click="openApplyDialog()"
              >
                {{
                  $localizationService.localize("drp_import.stepper.apply_btn")
                }}
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <ApplyAmazonLogisticsDialog
      v-if="importMode == DRP_IMPORT_AMAZON_LOGISTICS"
      ref="applyDialog"
      @onApplyImportSession="applyImportSession($event)"
    />
    <ApplyRouteStagingDialog
      v-if="importMode == DRP_IMPORT_ROUTE_STAGING"
      ref="applyDialog"
      @onApplyImportSession="applyImportSession($event)"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import {
  DRP_IMPORT_AMAZON_LOGISTICS,
  DRP_IMPORT_ROUTE_STAGING,
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  REPORT_STATUS_PARSED,
} from "../../../utils/utils";

const locService = Vue.prototype.$localizationService;

const PARSER_TIMEOUT = 3000;

export default {
  name: "ImportStepper",

  components: {
    ImportAmazonLogistics: () => import("./ImportAmazonLogisticsStep.vue"),
    TemplateSettings: () => import("./TemplateSettingsStep.vue"),
    MappingSettings: () => import("./MappingSettingsStep.vue"),
    ReportMar: () => import("../../../mar/ReportMar.vue"),
    ApplyAmazonLogisticsDialog: () => import("./ApplyAmazonLogisticsDialog.vue"),
    ApplyRouteStagingDialog: () => import("./ApplyRouteStagingDialog.vue"),
  },

  props: {
    pTargetDate: String,
  },

  data() {
    return {
      DRP_IMPORT_AMAZON_LOGISTICS,
      DRP_IMPORT_ROUTE_STAGING,
      PARSER_TIMEOUT,

      open: false,
      loading: false,

      importMode: undefined,

      step: 1,

      headers: [],

      storeModule: undefined,
      importSession: undefined,

      // for step 1
      importAmazonLogisticsCompleted: undefined,

      // for step 2
      selectedTemplate: undefined,

      // for step 3
      modelMappingCompleted: undefined,
    };
  },

  computed: {
    ...mapState("marModule", ["sendingSolutions"]),
    ...mapGetters("marModule", ["allSolutionsComplete"]),
  },

  methods: {
    openDialog(importMode, files) {
      this.clearData();
      this.importMode = importMode;

      if (importMode == DRP_IMPORT_AMAZON_LOGISTICS) {
        this.storeModule = "importAmazonDataModule";

        this.headers = [
          {
            displayText: locService.localize(
              "drp_import.stepper.header.logistics.paste_text",
            ),
            step: "1",
            errorText: "",
          },
          {
            displayText: locService.localize(
              "drp_import.stepper.header.logistics.template_settings",
            ),
            step: "2",
            errorText: "",
          },
          {
            displayText: locService.localize(
              "drp_import.stepper.header.logistics.mapping_settings",
            ),
            step: "3",
            errorText: "",
          },
          {
            displayText: locService.localize(
              "drp_import.stepper.header.logistics.mar_resolution",
            ),
            step: "4",
            errorText: "",
          },
        ];

        this.step = 1;
      }

      if (importMode == DRP_IMPORT_ROUTE_STAGING) {
        this.storeModule = "importRouteStagingModule";

        this.headers = [
          {
            displayText: locService.localize(
              "drp_import.stepper.header.route_staging.upload_file",
            ),
            step: "1",
            errorText: "",
          },
          {
            displayText: locService.localize(
              "drp_import.stepper.header.route_staging.template_settings",
            ),
            step: "2",
            errorText: "",
          },
          {
            displayText: locService.localize(
              "drp_import.stepper.header.route_staging.mapping_settings",
            ),
            step: "3",
            errorText: "",
          },
        ];

        this.step = 2;

        const file = files[0];

        setTimeout(async () => {
          this.loading = true;

          await this.$store.dispatch("importRouteStagingModule/sendData", {
            file,
            targetDate: this.pTargetDate,
          });

          this.loading = false;
          this.importAmazonLogisticsCompleted = true;
        });
      }

      this.open = true;
    },

    closeDialog() {
      this.open = false;
      this.clearData();
    },

    clearData() {
      this.importAmazonLogisticsCompleted = undefined;
      this.selectedTemplate = undefined;
      this.importSession = undefined;
      this.modelMappingCompleted = undefined;

      (this.headers.find((it) => it.step == 1) || {}).errorText = "";

      if (this.$refs.importAmazonLogistics) { this.$refs.importAmazonLogistics.clearData(); }
      if (this.$refs.templateSettings) this.$refs.templateSettings.clearData();
      if (this.$refs.mappingStep) this.$refs.mappingStep.clearData();
      if (this.$refs.applyDialog) this.$refs.applyDialog.clearData();
    },

    async sendImportData() {
      this.loading = true;

      if (this.importMode == DRP_IMPORT_AMAZON_LOGISTICS) {
        this.headers.find((it) => it.step == 1).errorText = "";
        // TODO: refactor to interface implementation
        await this.$refs.importAmazonLogistics.sendData();
      }

      this.loading = false;

      this.step = 2;
    },

    async sendTemplateSettings() {
      this.loading = true;

      const data = {
        template: this.selectedTemplate,
      };

      if (this.importMode == DRP_IMPORT_AMAZON_LOGISTICS) {
        data.station = this.$refs.importAmazonLogistics.station;
      }

      this.importSession = await this.$store.dispatch(
        `${this.storeModule}/sendTemplateSettings`,
        data,
      );

      const refreshImportSession = setInterval(async () => {
        if (
          !this.importSession.rawTableModelJson
          && !this.importSession.lastParsingError
        ) {
          this.importSession = await this.$store.dispatch(
            `${this.storeModule}/getImportedFileParsingStatusById`,
            this.importSession.id,
          );
        } else {
          clearInterval(refreshImportSession);

          const errorString = this.importSession.lastParsingError;

          if (errorString) {
            if (this.importMode == DRP_IMPORT_AMAZON_LOGISTICS) {
              let errorTextInStepperHeader = "";

              try {
                // TODO: warning localization
                const errorIndex = +errorString
                  .split(
                    locService.localize(
                      "drp_import.stepper.parsing_error.part1",
                    ),
                  )[1]
                  .split(":")[0] - 1;

                // eslint-disable-next-line prefer-destructuring
                errorTextInStepperHeader = errorString.split(
                  locService.localize("drp_import.stepper.parsing_error.part2"),
                )[0];

                this.$refs.importAmazonLogistics.findErrorLine(errorIndex);
              } catch (e) {
                console.log(e);
              }

              this.headers.find((it) => it.step == 1)
                .errorText = errorTextInStepperHeader || errorString;

              this.loading = false;
              this.step = 1;
              return;
            }

            if (this.importMode == DRP_IMPORT_ROUTE_STAGING) {
              Vue.toasted.error(errorString, { duration: 5000 });
              this.closeDialog();
              return;
            }
          }

          if (this.importSession.rawTableModelJson) {
            await this.$refs.mappingStep.loadAmazonLogisticsReportModelProperties();

            this.loading = false;

            this.step = 3;
            this.$refs.mappingStep.selectDefault();
          }
        }
      }, PARSER_TIMEOUT);
    },

    async initiationMapping() {
      this.loading = true;

      await this.$refs.mappingStep.initiationMapping();

      const refreshImportSession = setInterval(async () => {
        if (
          this.importSession.status
          == REPORT_STATUS_MANUAL_ACTION_REQUIRED.value
        ) {
          clearInterval(refreshImportSession);

          await this.$mar.loadSubject(this.importSession.id, "report");

          this.loading = false;
          this.step = 4;
        } else if (this.importSession.status == REPORT_STATUS_PARSED.value) {
          clearInterval(refreshImportSession);
          this.loading = false;
          this.openApplyDialog();
        } else {
          this.importSession = await this.$store.dispatch(
            `${this.storeModule}/getImportedFileParsingStatusById`,
            this.importSession.id,
          );
        }
      }, PARSER_TIMEOUT);
    },

    openApplyDialog() {
      this.$refs.applyDialog.openDialog();
    },

    async applyImportSession(shouldReplace) {
      this.loading = true;

      if (this.importMode == DRP_IMPORT_AMAZON_LOGISTICS) {
        await this.$mar.sendSolutions();

        if (this.$mar.currentSubject.requests.length) {
          this.loading = false;
          return;
        }

        await this.$store.dispatch(
          "importAmazonDataModule/applyImportSession",
          {
            reportId: this.importSession.id,
            shouldReplaceDriver: shouldReplace,
          },
        );
      }

      if (this.importMode == DRP_IMPORT_ROUTE_STAGING) {
        await this.$store.dispatch(
          "importRouteStagingModule/applyImportSession",
          {
            reportId: this.importSession.id,
            shouldReplaceStaging: shouldReplace,
          },
        );
      }

      this.loading = false;
      this.closeDialog();
      this.$store.dispatch("dailyRoutePlannerModule/reloadPage");
    },
  },
};
</script>

<style lang="scss">
.import-stepper {
  &.v-stepper .v-stepper__step--active .v-stepper__label {
    text-shadow: none !important;
  }
  .container-padding {
    padding: 12px 17px !important;
  }
}
</style>
