/* eslint-disable */

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:11ded64c-e38e-431f-a74f-572f604ca19b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_9KSzGfYeL",
    "aws_user_pools_web_client_id": "2846nbb240aplqphpl5bc86i21",
    "oauth": {
        "domain": "softwea-prod-dspis.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.dspis.net/login/",
        "redirectSignOut": "https://app.dspis.net/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
