var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"driver-infractions-table",attrs:{"headers":_vm.headers,"items":_vm.visibleCriteria,"loading":_vm.loading,"multi-sort":"","show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.driver_infractions_table.title" ))+" ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field pt-0",attrs:{"label":_vm.$localizationService.localize(
              'coaching_page.driver_infractions_table.search'
            ),"hide-details":"","width":"200"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)]},proxy:true},{key:"header.data-table-select",fn:function(ref){return [(!_vm.allSelectLoading)?_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"small-checkbox flex-grow-0",attrs:{"value":_vm.headerRowState == 'selected',"indeterminate":_vm.headerRowState == 'indeterminate'},on:{"input":function($event){return _vm.onAllCriteriaSelectionChange($event)}}}):_c('v-progress-circular',{attrs:{"color":"primary","size":25,"width":3,"indeterminate":""}})]}},{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
return [(!_vm.criteriaSelectLoading[item.id])?_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"small-checkbox flex-grow-0",attrs:{"value":_vm.getCriteriaRowState(item) == 'selected',"indeterminate":_vm.getCriteriaRowState(item) == 'indeterminate'},on:{"input":function($event){return _vm.onCriteriaSelectionChange($event, item)}}}):_c('v-progress-circular',{attrs:{"color":"primary","size":25,"width":3,"indeterminate":""}})]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name))])]}},{key:"item.lastInfractionsDays",fn:function(ref){
            var item = ref.item;
return [_c('DriverInfractionsDots',{staticClass:"driver-infractions-dots",attrs:{"infractions":_vm.dotsInfractionsByCriteria[item.id]}})]}},{key:"item.lastInfraction",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionFormattedDate(_vm.lastInfractionsByCriteria[item.id]))+" ")]}},{key:"item.totalCount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.infractionsTotalCountByCriteria[item.id] || "-")+" ")]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('DriverInfractionsSubTable',{ref:("sub-table-" + (item.id)),attrs:{"targetDriverId":_vm.targetDriverId,"targetCriteriaId":item.id,"selectedInfractions":_vm.selectedInfractions,"totalCount":_vm.infractionsTotalCountByCriteria[item.id],"searchString":_vm.searchString},on:{"infractionSelectionChanged":function($event){return _vm.onInfractionSelectionChanged($event)}}})],1)]}}],null,true)}),_c('SomeDriverInfractionsSelectionNoteDialog',{ref:"someDriverInfractionsSelectionNoteDialog"}),_c('CreateCoachingDialog',{ref:"createCoachingDialog",on:{"newCoachingCreated":function($event){return _vm.onNewCoachingCreated($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }