<template>
  <VehicleActionTypes />
</template>

<script>
import VehicleActionTypes from "../components/reminders/vehicle-action-types/VehicleActionTypes";

export default {
  name: "VehicleActionTypesPage",
  components: { VehicleActionTypes },

  routing: {
    localizationKey: "menu.tab.vehicle_action_types",
    icon: "mdi-car-cog",
    path: "/vehicle-action-type",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>

<style scoped>

</style>
