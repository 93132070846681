import { API } from "aws-amplify";
import Vue from "vue";
import { bodyWithAuthHeader, localizationService } from "../utils/utils";

export default {

  name: "simpleModule",
  namespaced: true,
  state: {
    currentModel: undefined,
    vehicleModel: undefined,
    loadingModel: false,
  },
  mutations: {
    beginLoadingModel(state) {
      state.loadingModel = true;
    },

    setCurrentModel(state, newModel) {
      state.currentModel = newModel;
    },

    endLoadingModel(state) {
      state.loadingModel = false;
    },

    beginLoadingVehicleModel(state) {
      state.loadingVehicleModel = true;
    },

    setCurrentVehicleModel(state, newModel) {
      state.vehicleModel = newModel;
    },

    endLoadingVehicleModel(state) {
      state.loadingVehicleModel = false;
    },
  },
  actions: {

    async loadModelIfNeeded({ commit, state }) {
      if (state.currentModel || state.loadingModel) return;

      const body = await bodyWithAuthHeader();

      commit("beginLoadingModel");

      try {
        const model = await API.get("core", "/coaching/criteria/language/simple/model", body);

        localizationService.injectLocalizedNames(model);

        commit("setCurrentModel", model);
      } catch (error) {
        Vue.toasted.error(error);
      } finally {
        commit("endLoadingModel");
      }
    },

    async loadVehicleModel({ commit, state }) {
      if (state.vehicleModel || state.loadingVehicleModel) return;

      const body = await bodyWithAuthHeader();

      commit("beginLoadingVehicleModel");

      try {
        const model = await API.get("core", "/vehicle/model", body);
        model.properties = model.properties.filter((it) => it.name !== "rentalExpiry");

        localizationService.injectLocalizedNames(model);

        commit("setCurrentVehicleModel", model);
      } catch (error) {
        Vue.toasted.error(error);
      } finally {
        commit("endLoadingVehicleModel");
      }
    },
  },
};
