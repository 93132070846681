<template>
  <v-data-table
    class="mb-1"
    :headers="headers"
    :items="infractions"
    :item-class="getItemClass"
    :footer-props="footerProps"
    multi-sort
    :items-per-page="itemsPerPage"
    @update:options="paginate($event)"
    :height="infractionsTableHeight"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{
            $localizationService.localize(
              "coaching_page.coaching_dialog_infractions_table.title",
              [infractions.length]
            )
          }}
        </v-toolbar-title>
      </v-toolbar>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      {{ getInfractionFormattedCreatedAt(item) }}
    </template>

    <template v-slot:[`item.date`]="{ item }">
      {{ getInfractionFormattedDate(item) }}
    </template>

    <template v-slot:[`item.criteria`]="{ item }">
      {{ item.criteria.name }}
    </template>

    <template v-slot:[`item.detectedValue`]="{ item }">
      {{ item.detectedValue }}
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import { dateMomentFormat, dateTimeMomentFormat } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CoachingDialogInfractionsTable",

  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 15],
    },

    itemsPerPage: 5,
  }),

  props: {
    infractions: Array,
  },

  computed: {
    headers: () => [
      {
        text: locService.localize(
          "coaching_page.coaching_dialog_infractions_table.header.target_date",
        ),
        value: "date",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.coaching_dialog_infractions_table.header.criteria",
        ),
        value: "criteria",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.coaching_dialog_infractions_table.header.detected_value",
        ),
        value: "detectedValue",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.coaching_dialog_infractions_table.header.detected_at",
        ),
        value: "createdAt",
        sortable: true,
        align: "start",
      },
    ],

    infractionsTableHeight() {
      return (this.itemsPerPage + 1) * 48;
    },
  },

  methods: {
    paginate(data) {
      this.itemsPerPage = data.itemsPerPage;
    },

    getItemClass(infraction) {
      if (infraction.status == "NEW") return "new-infraction-table-item";
      if (infraction.status == "COACHING_ASSIGNED") { return "coaching-assigned-infraction-table-item"; }
      if (infraction.status == "RESOLVED") { return "resolved-infraction-table-item"; }
      return undefined;
    },

    getInfractionFormattedCreatedAt(infraction) {
      return moment(infraction.createdAt).format(dateTimeMomentFormat);
    },

    getInfractionFormattedDate(infraction) {
      if (!infraction) return "-";

      const dateCircumstance = infraction.circumstances.find(
        (it) => it.type == "DateCircumstance",
      );

      return dateCircumstance
        ? moment(dateCircumstance.date).format(dateMomentFormat)
        : locService.localize(
          "coaching_page.coaching_dialog_infractions_table.formatted_date.error.no_date",
        );
    },
  },
};
</script>
