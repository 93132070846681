<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open">
      <v-card-title>
        {{
          $localizationService.localize("drp.drivers_table.dialog.create.title")
        }}
      </v-card-title>

      <v-card-text>
        <v-autocomplete
          :label="
            $localizationService.localize(
              'drp.drivers_table.dialog.create.param.driver_name'
            )
          "
          :items="matchedDrivers"
          item-text="driver.name"
          return-object
          v-model="selectedDriver"
          hide-details
          :search-input.sync="searchDrivers"
          ref="inputDriver"
          :loading="loading"
        >
          <template v-slot:selection="data">
            <MatchedDriverLabel
              :driver="data.item.driver"
              :options="data.item.options"
              :additionalDataPerDriver="
                additionalDataPerMatchedDriver[data.item.driver.id]
              "
            />
          </template>

          <template v-slot:item="data">
            <div>
              <MatchedDriverLabel
                :driver="data.item.driver"
                :options="data.item.options"
                :additionalDataPerDriver="
                  additionalDataPerMatchedDriver[data.item.driver.id]
                "
              />
            </div>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false" :disabled="loading">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>

        <v-btn
          color="primary"
          text
          :disabled="loading || !selectedDriver"
          @click="createDriverEntry()"
        >
          {{ $localizationService.localize("btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "CreateEntryDialog",

  components: {
    MatchedDriverLabel: () => import("../drp-common/MatchedDriverLabel.vue"),
  },

  props: {
    driverEntries: Array,
  },

  watch: {
    searchDrivers() {
      if (this.searchDrivers) {
        this.loadFilteredDrivers();
      }
    },
  },

  data: () => ({
    searchDrivers: null,
    open: false,
    matchedDrivers: [],
    selectedDriver: undefined,
    loading: false,
    targetDate: undefined,
    additionalDataPerMatchedDriver: [],
  }),

  methods: {
    openDialog(targetDate) {
      this.matchedDrivers = [];
      this.open = true;
      this.targetDate = targetDate;
    },

    async createDriverEntry() {
      this.open = false;
      await this.$store.dispatch("dailyRoutePlannerModule/createDriverEntry", {
        driverId: this.selectedDriver.driver.id,
        targetDate: this.targetDate,
      });
      this.$emit("afterCreateEntry");
      this.selectedDriver = undefined;
    },

    // TODO: warning
    loadFilteredDrivers: debounce(function () {
      this.loading = true;

      this.$store.dispatch(
        "dailyRoutePlannerModule/loadFilteredDrivers",
        this.searchDrivers,
      ).then((data) => {
        if (data.length) {
          this.matchedDrivers = data
            .map((it) => ({
              driver: it.driver,
              options: this.driverEntries.some(
                (entry) => entry.driver.id == it.driver.id,
              ) ? {
                  disabled: true,
                  tooltipText: "Already Exists",
                  nameColor: "orange--text",
                } : undefined,
            }));

          data.forEach((it) => {
            this.additionalDataPerMatchedDriver[it.driver.id] = it.data;
          });
        } else {
          this.matchedDrivers = [];
          this.additionalDataPerMatchedDriver = [];
        }
        this.loading = false;
      });
    }, 300),
  },
};
</script>
