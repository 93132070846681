<template>
  <v-container fluid>
    <v-row align="start" justify="start">
      <v-col md="6" lg="4">
        <v-card elevation="2" :loading="companyLoading">
          <v-card-title>
            {{ $localizationService.localize("company_management_page.title") }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              class="mb-6"
              :label="
                $localizationService.localize(
                  'company_management_page.param.name'
                )
              "
              v-model="companyNameFieldValue"
              :disabled="companyLoading"
              :rules="nameRules"
              hide-details
            ></v-text-field>
            <v-autocomplete
              class="mb-6"
              :label="
                $localizationService.localize(
                  'company_management_page.param.time_zone'
                )
              "
              v-model="companyTimeZoneFieldValue"
              :items="availableTimeZones"
              :disabled="companyLoading"
              :loading="timeZonesLoading"
              hide-details
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              color="primary"
              @click="onSaveClick"
              :disabled="!saveButtonActive"
            >
              {{ $localizationService.localize("btn.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CompanyManagement",

  data: () => ({
    companyNameFieldValue: "",
    companyTimeZoneFieldValue: "",
    companyAutoCoachingEnabledValue: false,
    nameRules: [
      (value) => /^(?!\s*$).+/g.test(value)
        || locService.localize("error.validation.required"),
    ],
  }),

  computed: {
    ...mapState("companyModule", [
      "availableTimeZones",
      "timeZonesLoading",
      "company",
      "companyLoading",
    ]),

    saveButtonActive() {
      if (this.actionInProcess) return false;
      if (
        this.nameRules.find((rule) => rule(this.companyNameFieldValue) !== true)
      ) { return false; }

      return (
        this.company
        && (this.company.name != this.companyNameFieldValue
          || this.company.timeZone != this.companyTimeZoneFieldValue
          || this.company.autoCoachingEnabled
            != this.companyAutoCoachingEnabledValue)
      );
    },
  },

  watch: {
    company() {
      this.companyNameFieldValue = this.company.name;
      this.companyTimeZoneFieldValue = this.company.timeZone;
      this.companyAutoCoachingEnabledValue = this.company.autoCoachingEnabled;
    },
  },

  methods: {
    async onSaveClick() {
      this.$store.dispatch("companyModule/updateCompany", {
        newName: this.companyNameFieldValue,
        newTimeZone: this.companyTimeZoneFieldValue,
        newAutoCoachingEnabled: this.companyAutoCoachingEnabledValue,
      });
    },
  },

  mounted() {
    this.companyNameFieldValue = this.company ? this.company.name : "";
    this.companyTimeZoneFieldValue = this.company ? this.company.timeZone : "";
    this.companyAutoCoachingEnabledValue = this.company
      ? this.company.autoCoachingEnabled
      : false;

    this.$store.dispatch("companyModule/loadAvailableTimeZonesIfRequired");
  },
};
</script>
