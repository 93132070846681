var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"report-calendar-component pb-0",class:_vm.drawer ? 'drawer-open' : 'drawer-closed'},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$localizationService.localize("report_calendar.title")))]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":_vm.calendarDateRangeMenu ? 'primary' : ''},on:{"click":function($event){_vm.calendarDateRangeMenu = !_vm.calendarDateRangeMenu}}},[_vm._v("mdi-calendar")]),_c('v-menu',{ref:"calendarDateRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.selectedDatesRange,"transition":"scale-transition"},on:{"update:returnValue":function($event){_vm.selectedDatesRange=$event},"update:return-value":function($event){_vm.selectedDatesRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"date-container"},[_c('v-combobox',_vm._g(_vm._b({attrs:{"multiple":"","chips":"","label":_vm.$localizationService.localize(
                  'report_calendar.param.date_range'
                ),"readonly":""},model:{value:(_vm.selectedDatesRange),callback:function ($$v) {_vm.selectedDatesRange=$$v},expression:"selectedDatesRange"}},'v-combobox',attrs,false),on)),_c('v-text-field',_vm._g(_vm._b({staticClass:"closed-input",attrs:{"multiple":"","chips":"","small-chips":"","label":_vm.$localizationService.localize(
                  'report_calendar.param.date_range'
                ),"readonly":""},model:{value:(_vm.selectedDatesRange),callback:function ($$v) {_vm.selectedDatesRange=$$v},expression:"selectedDatesRange"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.calendarDateRangeMenu),callback:function ($$v) {_vm.calendarDateRangeMenu=$$v},expression:"calendarDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},model:{value:(_vm.selectedDatesRange),callback:function ($$v) {_vm.selectedDatesRange=$$v},expression:"selectedDatesRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.calendarDateRangeMenu = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.saveDatesRange(_vm.selectedDatesRange.sort())}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.ok"))+" ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"data-calendar",attrs:{"headers":_vm.headers,"items":_vm.defaultItems,"loading":_vm.calendarLoading,"loading-text":_vm.$localizationService.localize('common.loading'),"disable-pagination":"","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([_vm._l((_vm.dateHeaders),function(date,index){return {key:("item." + (date.value)),fn:function(ref){
                var item = ref.item;
return [_c('div',{key:index},[_c('CalendarChip',{attrs:{"pChip":_vm.getChip(item.reportType, date.value),"pTargetDate":date.value,"pLastDateInCalendar":_vm.lastDateInCalendar},on:{"onOpenActionDialog":function($event){return _vm.$emit('onOpenActionDialog', $event)},"onUploadReport":function($event){return _vm.uploadReport($event,  date.value)}}})],1)]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }