import Vue from "vue";
import VueRouter from "vue-router";

import CoachingPage from "../views/coaching/CoachingPage.vue";
import DailyRoutePlannerPage from "../views/DailyRoutePlannerPage.vue";
import CompanyManagementPage from "../views/CompanyManagementPage";

import { store } from "../main";

Vue.use(VueRouter);

const pagesContext = require.context("@/views", true, /.vue$/);
const pages = pagesContext.keys()
  .map((x) => (pagesContext(x).default))
  .filter((it) => it.routing)
  .map((it) => ({
    name: it.name,
    path: it.routing.path,
    component: it,
  }));

const routes = [
  ...pages,
  {
    path: "/coaching-table/:selectedTab",
    name: "CoachingPageTab",
    component: CoachingPage,
  },
  {
    path: "/planner/:date",
    name: "DailyRoutePlannerPageParamDate",
    component: DailyRoutePlannerPage,
  },
  {
    path: "/planner/:date/:selectedTab",
    name: "DailyRoutePlannerPageParamDateTab",
    component: DailyRoutePlannerPage,
  },
  {
    path: "/company_management/:selectedTab",
    name: "CompanyManagementPageTab",
    component: CompanyManagementPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.userModule.user) {
    await store.dispatch("userModule/checkLogin");
  }

  if (store.state.userModule.user) {
    store.state.userModule.user.applyRedirectRules(to, from, next);
  } else if (to.name !== "LoginPage") {
    next({ name: "LoginPage" });
  } else {
    next();
  }
});

export default router;
