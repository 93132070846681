import { Auth } from "aws-amplify";
import Vue from "vue";
import {
  USER_TYPES_BY_VALUE,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPE_DRIVER,
} from "../utils/utils";
import { store } from "../main";

export class User {
  id;

  email;

  name;

  type;

  companyName;

  companyId;

  cognitoUsername;

  blocked;

  constructor(
    id,
    email,
    name,
    companyName,
    companyId,
    type,
    isMaster,
    cognitoUsername,
    blocked,
  ) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.companyName = companyName;
    this.companyId = companyId;
    this.type = type;
    this.isMaster = isMaster;
    this.cognitoUsername = cognitoUsername;
    this.blocked = blocked;
  }

  static fromDto(dto) {
    return new User(
      dto.attributes.sub,
      dto.attributes.email,
      dto.attributes.name,
      dto.attributes["custom:companyName"],
      dto.attributes["custom:companyId"],
      USER_TYPES_BY_VALUE[dto.attributes["custom:type"]],
      dto.attributes["custom:master"],
      dto.username,
      dto.attributes["custom:blocked"] ? JSON.parse(dto.attributes["custom:blocked"]) : false,
    );
  }

  get isDriver() {
    return this.type === USER_TYPE_DRIVER;
  }

  get isManager() {
    return this.type === USER_TYPE_MANAGER;
  }

  get isAdmin() {
    return this.type === USER_TYPE_ADMIN;
  }

  get statusInCompany() {
    return this.type ? this.type.getStatus(this.companyName) : undefined;
  }

  get abbreviation() {
    return this.name
      ? this.name.match(/\b(\w)/g).join("")
      : Vue.prototype.$localizationService
        .localize("store.user.display_user_abbreviation");
  }

  isCooked() {
    return this.companyName != undefined;
  }

  static async getToken() {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }

  get defaultPageName() {
    return this.isDriver ? "BlankPage" : "HomePage";
  }

  checkPageForAvailability(targetPage) {
    if (!targetPage.name) {
      console.warn("Unexpected Page by route:", targetPage.path);
      return false;
    }

    const foundPage = store.state.pagesModule.pages.find((it) => (
      it.name === targetPage.name || targetPage.name.startsWith(it.name)
    ));

    return foundPage ? foundPage.routing.hasAccess(this) : false;
  }

  applyRedirectRules(to, from, next) {
    if (this.blocked && to.name !== "ForbiddenPage") {
      next({ name: "ForbiddenPage" });
    } else if (!this.checkPageForAvailability(to)) {
      next({ name: this.defaultPageName });
    } else {
      next();
    }
  }
}
