

































































































































import {
  Vue, Component, Watch,
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import BulkMessageTargetCard from "./BulkMessageTargetCard.vue";
// eslint-disable-next-line no-unused-vars
import { RocketChatDriver } from "@/types/types";
import BulkMessage from "@/store/bulk-message.store";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import TemplatesWritingAssistant from "@/components/templates-writing-assistant/TemplatesWritingAssistant.vue";
import { TwaMode } from "../../utils/ts-utils";

const bulkMessageModule = BulkMessage.namespace;

const debounce: any = require("lodash/debounce");

@Component({
  components: {
    TemplatesWritingAssistant,
    BulkMessageTargetCard,
    ConfirmationDialog,
  },
})

export default class BulkMessageDialog extends Vue {
  TwaMode = TwaMode;

  open = false;

  assistantHasError = false;

  loadTemplateButtonIsVisible = true;

  @bulkMessageModule.State
  text!: string

  @bulkMessageModule.State
  public sending!: boolean;

  matchedDrivers = [];

  matchedTemplates = [];

  searchDrivers = ""

  searchTemplates = ""

  loadingDrivers = false;

  loadingTemplates = false;

  selectedDriver: any | undefined = undefined;

  subTitle = ""

  @bulkMessageModule.State
  public drivers!: RocketChatDriver[];

  @bulkMessageModule.Mutation
  public addDriver!: (driver: RocketChatDriver) => void

  @bulkMessageModule.Mutation
  public removeDriver!: (driver: RocketChatDriver) => void

  @bulkMessageModule.Mutation
  public clearDrivers!: () => void

  @bulkMessageModule.Mutation
  public setText!: (text: string) => void

  @bulkMessageModule.Action
  public sendMessages!: (text: string) => Promise<void>

  changeDriver(event: any | undefined) {
    setTimeout(() => this.searchDrivers = "");
    if (!event) return;
    if (this.drivers.find((it) => it && it.id == event.id)) return;
    this.addDriver(event);
  }

  loadDrivers: any = debounce((self: any) => {
    self.loadingDrivers = true;
    self.$store.dispatch("reportModule/loadFilteredDrivers", {
      name: self.searchDrivers,
    }).then((data: any) => {
      self.matchedDrivers = data;
      self.loadingDrivers = false;
    });
  }, 300);

  loadTemplates: any = debounce((self: any) => {
    self.loadingTemplates = true;
    self.$store.dispatch("messageTemplatesModule/loadFilteredTemplates", {
      name: self.searchTemplates,
    }).then((data: any) => {
      self.matchedTemplates = data;
      self.loadingTemplates = false;
    });
  }, 300);

  @Watch("searchDrivers")
  loadFilteredDrivers() {
    this.loadDrivers(this);
  }

  @Watch("open")
  clickToBlankArea() {
    if (!this.sending) {
      this.setText("");
      this.clearDrivers();
    }
  }

  openDialog(drivers: Array<RocketChatDriver> | undefined = undefined): void {
    if (drivers && !this.drivers.length) {
      drivers.forEach((it) => setTimeout(() => this.addDriver({ ...it })));
    }
    this.open = true;
  }

  @Watch("searchTemplates")
  loadFilteredTemplates() {
    this.loadTemplates(this);
  }

  close(): void {
    this.open = false;
    if (!this.sending) {
      this.setText("");
      this.clearDrivers();
    }
  }

  /* This code block may be used in the next task
  openTerminateDialog(): void {
    (this.$refs.deleteDialog as InstanceType<typeof ConfirmationDialog>)
      .openDialog({
        header: this.locService.localize("bulk_message_dialog.delete.title") as string,
        text: this.locService.localize("bulk_message_dialog.delete.text") as string,
        submitText: this.locService.localize("btn.terminate") as string,
        submitColor: "red" as string,
        onSubmit: () => this.closeWebSocket(),
        open: true, // These fields are not needed, but the typescript requires them to be added(
        loading: true,
        cancelText: "",
      });
  }
   */

  async send(): Promise<void> {
    setTimeout(() => { this.open = false; }, 500);
    await this.sendMessages(this.text);
  }

  changeLoadTemplateButtonIsVisible(value: boolean): void {
    this.loadTemplateButtonIsVisible = value;
  }

  transferTextFromAssistant(text: string) {
    this.setText(text);
  }

  inputTemplate(template: string) {
    this.setText(template);
    setTimeout(() => (this.$refs.templateEditor as any).forceUpdate());
  }

  async mounted() {
    getModule(BulkMessage, this.$store);
  }
}
