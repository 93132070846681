import { TriggerActionUnitType } from "@/types/types";

export abstract class IssueUnitTemplate {
  constructor(
    public type: TriggerActionUnitType,
  ) {
  }

  abstract toDto(): any

  abstract clone(): IssueUnitTemplate
}
