import { LightweightReminderDto } from "@/types/types";

export class LightweightReminder {
  constructor(
    public id: string,
    public name: string,
  ) {}

  clone() {
    return new LightweightReminder(
      this.id,
      this.name,
    );
  }

  static fromDto(dto: LightweightReminderDto): LightweightReminder {
    return new LightweightReminder(
      dto.id,
      dto.name,
    );
  }
}
