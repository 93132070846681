<template>
  <v-dialog v-model="open" :width="qrSize + 50 + (isVehicle ? 460 : 0) + 'px'">
    <v-card v-if="vehicle">
      <v-card-title>
        <div>
          {{vehicle.name}}
          <div v-if="pIsVisibleFullData" class="card-id " :class="{'not-assigned': !vehicle.fuelCard}">{{
            vehicle.fuelCard ?
              $localizationService.localize("drp.vehicle_label.fuel_card_id", [vehicle.fuelCard.cardId]) :
              $localizationService.localize("drp.vehicle_label.fuel_card_not_assigned")
            }}</div>
        </div>
      </v-card-title>
      <v-card-text class="text-center d-flex pb-0">
        <div>
          <div><vue-qr :text="vehicle.vin" :size="qrSize"></vue-qr></div>
          {{text}}
        </div>
        <div v-if="isVehicle && open" class="ml-4 text-left">
          <VehicleIssues :vehicle="vehicle" :maxHeight="qrSize + 100" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false">
          {{ $localizationService.localize("btn.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQr from "vue-qr";
import Vue from "vue";
import VehicleIssues from "./vehicle-profile/VehicleIssues.vue";
import { IS_MOBILE } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "QrDialog",

  components: {
    VueQr,
    VehicleIssues,
  },

  props: {
    pIsVisibleFullData: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: locService.localize("vehicle_page.dialog.vin_qr.title"),
    },
    isVehicle: String,
  },

  data: () => ({
    open: false,

    vehicle: undefined,
  }),

  computed: {
    qrSize() {
      return IS_MOBILE ? 200 : 300;
    },
  },

  methods: {
    openDialog(vehicle) {
      this.vehicle = vehicle;
      this.open = true;
    },
  },
};
</script>

<style scoped lang="scss">
.card-id {
  font-size: 16px !important;
  &.not-assigned {
    color: orange;
  }
}
</style>
