import { convertFromMilliseconds, convertToMilliseconds } from "@/utils/ts-utils";

export class UnitedDurationDto {
  constructor(
        public unit: Unit,
        public valueInMilliseconds: number,
  ) {}
}

export class UnitedDuration {
    unit: Unit;

    value: number;

    constructor(unit: Unit, value: number) {
      this.unit = unit;
      this.value = value;
    }

    get valueInMilliseconds() {
      return convertToMilliseconds(this.value, this.unit);
    }

    static copy(obj: UnitedDuration) {
      return new UnitedDuration(
        obj.unit,
        obj.value,
      );
    }

    static fromDto(dto: any) {
      return new UnitedDuration(
        dto.unit.toLowerCase(),
        convertFromMilliseconds(dto.valueInMilliseconds, dto.unit.toLowerCase()),
      );
    }

    static toDto(dto: any) {
      return new UnitedDurationDto(
        dto.unit ? dto.unit.toUpperCase() : null,
        convertToMilliseconds(dto.value, dto.unit),
      );
    }
}

export enum Unit {
    DAY = "day",
    WEEK = "week",
    YEAR = "year",
}
