











































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PhoneAgentDto } from "@/model/phone-agent.model";
import { THEME } from "@/app-theme";

const phoneAgentModule = namespace("phoneAgentsModule");
const userModule = namespace("userModule");

@Component
export default class SelectPhoneAgentButton extends Vue {
  loading = false

  isOpenPhoneAgentList = false

  loadingPhoneAgents = false

  @userModule.State
  selectedPhoneAgent!: PhoneAgentDto | null

  @phoneAgentModule.State
  availablePhoneAgents!: PhoneAgentDto[]

  @phoneAgentModule.Action
  loadAvailablePhoneAgents!: () => Promise<PhoneAgentDto[]>

  @userModule.Action
  getSelectedUserPhoneAgent!: () => Promise<PhoneAgentDto>

  @userModule.Action
  updatePhoneAgent!: (phoneAgent: PhoneAgentDto) => Promise<PhoneAgentDto>

  async loadPhoneAgents() {
    this.loadingPhoneAgents = true;
    await this.loadAvailablePhoneAgents();
    this.loadingPhoneAgents = false;
  }

  openPhoneAgentList() {
    this.isOpenPhoneAgentList = true;
    if (this.availablePhoneAgents.length === 0) {
      this.loadPhoneAgents();
    }
  }

  closePhoneAgentList() {
    this.isOpenPhoneAgentList = false;
  }

  async selectPhoneAgent(item: PhoneAgentDto) {
    this.loading = true;
    this.closePhoneAgentList();
    await this.updatePhoneAgent(item);
    this.loading = false;
  }

  get selectBtnStyle() {
    return {
      borderTop: `2px solid ${THEME.themeInverseColor} !important`,
      borderRight: `2px solid ${THEME.themeInverseColor} !important`,
      borderLeft: `2px solid ${THEME.themeInverseColor} !important`,
    };
  }

  async mounted() {
    this.loading = true;
    await this.getSelectedUserPhoneAgent();
    this.loading = false;
  }
}
