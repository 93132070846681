<template>
  <Dashboard grafana-path="/d/OueQ5LpAZ/feedback-csv-dashboard" />
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "CustomerFeedbackCSVPage",

  routing: {
    localizationKey: "menu.tab.customer_feedback_csv",
    icon: "mdi-table-large",
    path: "/customer_feedback_csv",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Dashboard,
  },
};
</script>
