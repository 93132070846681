<template>
  <div class="d-flex align-center rescuer-widget" :style="marginByNestingIndex">
    <v-icon class="arrow-icon theme-text-color">
      mdi-call-missed
    </v-icon>
    <v-chip class="rescuer-chip pr-0">
      <div v-if="mode === VIEW_MODE" class="fill-height d-flex align-center">
        <span class="mr-2 d-flex align-center">
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <span
                class="editable-item theme-text-color"
                :class="{'orange--text text--darken-4': pRescue.packages < pRescue.stops }"
                @click="packages = pRescue.packages; mode = EDIT_PACKAGES_MODE"
                v-bind="attrs"
                v-on="on"
              >
                {{pRescue.packages}}
              </span>
            </template>
            <span>{{$localizationService.localize("drp.rescue_widget.packages")}}</span>
          </v-tooltip>
          <div class="theme-text-color ml-1 mr-1">/</div>
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <span
                class="editable-item theme-text-color"
                :class="{'orange--text text--darken-4': pRescue.stops === 0 }"
                @click="stops = pRescue.stops; mode = EDIT_STOPS_MODE"
                v-bind="attrs"
                v-on="on"
              >
                {{pRescue.stops}}
              </span>
            </template>
            <span>{{$localizationService.localize("drp.rescue_widget.stops")}}</span>
          </v-tooltip>
        </span>
        <span
          class="pr-2 pl-2 middle-field editable-item"
          :class="pRescue.status.color"
          @click="mode = EDIT_STATUS_MODE"
        >
          {{ pRescue.status.text }}
        </span>
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              class="bonus-btn pr-1"
              @click="updateBonus()"
              v-bind="attrs"
              v-on="on"
            >
              <div v-if="!pRescue.bonus.active" class="red--text print-d-none">
                <span>$</span>
              </div>
              <span v-else class="green--text">$</span>
            </v-btn>
          </template>
          <span>{{$localizationService.localize("drp.rescue_widget.bonus")}}</span>
        </v-tooltip>
      </div>
      <div v-else-if="mode === EDIT_PACKAGES_MODE" class="d-flex align-center">
       <span class="mr-1 theme-text-color">{{$localizationService.localize("drp.rescue_widget.packages")}}:</span>
        <v-text-field
          class="input-width"
          :class="{'incorrect-data': packages < pRescue.stops }"
          v-model="packages"
          autofocus
          type="number"
          min="0"
          @input="+packages < 0 ? packages = 0 : false"
        ></v-text-field>
      </div>
      <div v-else-if="mode === EDIT_STOPS_MODE" class="d-flex align-center">
        <span class="mr-1 theme-text-color">{{$localizationService.localize("drp.rescue_widget.stops")}}:</span>
        <v-text-field
          class="input-width"
          :class="{'incorrect-data': +stops === 0 }"
          v-model="stops"
          autofocus
          type="number"
          min="0"
          @input="+stops < 0 ? stops = 0 : false"
        ></v-text-field>
      </div>
      <div v-else-if="mode === EDIT_STATUS_MODE">
        <RescueStatusSelect
          class="rescue-status-select"
          :pStatus="pRescue.status"
          @onChangeStatus="onChangeStatus($event)"
        />
      </div>
      <div v-if="mode !== VIEW_MODE">
        <v-icon
          v-if="mode !== EDIT_STATUS_MODE"
          class="ml-2"
          color="green"
          :disabled="saveBtnIsDisabled"
          @click="save()"
        >mdi-check</v-icon>
        <v-icon
          class="ml-2 pr-2"
          color="red"
          @click="cancel()"
        >mdi-close</v-icon>
      </div>
    </v-chip>
  </div>
</template>

<script>
import lodash from "lodash";

const VIEW_MODE = "VIEW";
const EDIT_STATUS_MODE = "EDIT_STATUS";
const EDIT_PACKAGES_MODE = "EDIT_PACKAGES";
const EDIT_STOPS_MODE = "EDIT_STOPS";

export default {
  name: "RescueWidget",

  components: {
    RescueStatusSelect: () => import("./status/RescueStatusSelect.vue"),
  },

  props: {
    pRescue: Object,
    pNestingIndex: Number,
  },

  watch: {
    pRescue() {
      this.packages = this.pRescue.packages;
      this.stops = this.pRescue.stops;
    },
  },

  data() {
    return {
      VIEW_MODE,
      EDIT_STATUS_MODE,
      EDIT_PACKAGES_MODE,
      EDIT_STOPS_MODE,

      mode: undefined,

      packages: this.pRescue.packages,
      stops: this.pRescue.stops,
    };
  },

  computed: {
    marginByNestingIndex() {
      return { marginLeft: `${this.pNestingIndex * 30}px` };
    },

    saveBtnIsDisabled() {
      if (this.mode === EDIT_PACKAGES_MODE) {
        return this.packages === undefined
          || this.packages === ""
          || +this.packages === this.pRescue.packages;
      }
      if (this.mode === EDIT_STOPS_MODE) {
        return this.stops === undefined
          || this.stops === ""
          || +this.stops === this.pRescue.stops;
      }
      return false;
    },
  },

  methods: {
    updateBonus: lodash.debounce(function () {
      this.$emit("onChangeBonus", !this.pRescue.bonus.active);
    }, 300),

    onChangeStatus(status) {
      this.$emit("onChangeStatus", status);
      this.mode = VIEW_MODE;
    },

    save() {
      if (this.mode === EDIT_PACKAGES_MODE) {
        this.$emit("onChangePackages", +this.packages);
      }
      if (this.mode === EDIT_STOPS_MODE) {
        this.$emit("onChangeStops", +this.stops);
      }
      this.mode = VIEW_MODE;
    },

    cancel() {
      this.mode = VIEW_MODE;
    },
  },

  mounted() {
    this.mode = VIEW_MODE;
  },
};
</script>

<style lang="scss">
@import '../../../../../node_modules/vuetify/src/styles/styles';
.dark-local-user-theme {
  .rescuer-widget {
    .rescuer-chip {
      background-color: #24202F !important;
    }
  }
}
.light-local-user-theme {
  .rescuer-widget {
    .rescuer-chip {
      background-color: map-get($deep-purple, 'lighten-5') !important;
    }
  }
}
.rescuer-widget {
  .editable-item {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .arrow-icon {
    transform: rotate(45deg);
  }
  .input-width {
    width: 40px;
    .v-input__slot {
      margin: 0;
    }
    &.incorrect-data {
      input {
        color: var(--v-warning-darken1) !important;
      }
    }
  }
  .rescuer-chip {
    //min-width: 188px; TODO
    //width: 188px; TODO
    //max-width: 188px; TODO
    border: 2px solid map-get($deep-purple, 'darken-2') !important;

    .rescue-status-select {
      max-width: 115px;
    }

    .middle-field {
      height: inherit;
      display: flex;
      align-items: center;
      border-right: 2px solid map-get($deep-purple, 'darken-2') !important;
      border-left: 2px solid map-get($deep-purple, 'darken-2') !important;
    }
    .bonus-btn {
      border-radius: 0 !important;
      font-size: 20px;
      position: relative;
      .v-ripple__container {
        border-radius: 0 !important;
      }
      /*
      .dollar-line {
        position: absolute;
        top: 10px;
        left: 0;
        height: 2px;
        width: 25px;
        background: #F44336;
        transform: rotate(45deg);
      }
      */
    }
  }
}
</style>
