


















































































import {
  Component, Prop, Ref, Vue,
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import lodash from "lodash";
import moment from "moment";
import { Vehicle } from "@/model/vehicle.model";
import InspectionAssignmentsModule from "@/store/vehicle-inspection-assignments.store";
import {
  dateMomentFormat,
  defaultDateFormat,
  INSPECTION_FAIL_PASS_FAIL,
} from "@/utils/utils";
import VehicleInspectionAssignDialog from "@/components/vehicle-inspections/VehicleInspectionAssignDialog.vue";
import VehicleInspectionJsonDialog from "@/components/vehicle-inspections/VehicleInspectionJsonDialog.vue";
import { VehicleInspectionAssignment } from "@/model/inspection/vehicle-inspection-assigment.model";

const locService = Vue.prototype.$localizationService;

const inspectionAssignmentsModule = InspectionAssignmentsModule.namespace;

@Component({
  components: {
    VehicleInspectionAssignDialog,
    VehicleInspectionJsonDialog,
  },
})
export default class VehicleInspectionAssignments extends Vue {
  locService = locService;

  search: string | null = null;

  loading = false;

  targetDate: string = moment().format(defaultDateFormat);

  datePickerDialog = false;

  @Prop()
  vehicle!: Vehicle;

  @Prop()
  maxHeight: number | undefined;

  @inspectionAssignmentsModule.Action
  loadVehicleInspectionAssignments!: (data: any) => Promise<any[]>

  vehicleInspectionAssignments: VehicleInspectionAssignment[] = []

  @Ref("assignmentCardDialog")
  assignmentCardDialog!: any;

  get maxHeightStyle() {
    return `max-height: ${(this.maxHeight || Number.MAX_VALUE) - 64}px; overflow-y: auto;`;
  }

  realodAssignments() {
    this.loadInspectionAssignments();
  }

  formattedInspectionDate(assigment: any) {
    return moment(assigment.targetDate).format(dateMomentFormat); // Should be dateTimeShortMomentFormat
  }

  getInspectionAssignmentSubtitle(assignment: VehicleInspectionAssignment) {
    if (assignment.inspection) {
      const items = assignment.inspection.items;

      const successItems = items.filter((it: any) => it.answer != INSPECTION_FAIL_PASS_FAIL.value);
      if (successItems.length == items.length) return locService.localize("vehicle_profile.vehicle_inspections.item.subtitle.all_passed");

      const failItems = items.filter((it: any) => it.answer == INSPECTION_FAIL_PASS_FAIL.value);

      if (failItems.length == 1) {
        const noComment = locService.localize("vehicle_profile.vehicle_inspections.item.subtitle.no_comment");
        return `${failItems[0].scheme.name} - ${failItems[0].comment || noComment}`;
      }

      return locService.localize("vehicle_profile.vehicle_inspections.item.subtitle.failed_items", [failItems.length]);
    }

    return "Planned";
  }

  get formattedTargetDate() {
    return moment(this.targetDate).format(dateMomentFormat);
  }

  getInspectionAssignmentBackgroundColor(assignment: any) {
    if (assignment.inspection) {
      const items = assignment.inspection.items;

      const successItems = items.filter((it: any) => it.answer != INSPECTION_FAIL_PASS_FAIL.value);
      if (successItems.length == items.length) return "resolved-assignment-background";

      return "fail-assignment-background";
    }

    return "new-assignment-background";
  }

  openInspectionAssignmentDialog(assignment: any) {
    if (assignment.inspection) {
      (this.$refs.vehicleInspectionJsonDialog as any).openDialog(assignment.inspection);
    }
  }

  loadInspectionAssignments() {}

  reduceLoad() {}

  created() {
    this.loadInspectionAssignments = lodash.debounce(async () => {
      this.loading = true;
      this.vehicleInspectionAssignments = await this.loadVehicleInspectionAssignments({
        vehicleId: this.vehicle.id,
        search: this.search,
        targetDate: this.targetDate,
      });
      this.loading = false;
    }, 300);
  }

  decreaseDate() {
    this.targetDate = moment(this.targetDate)
      .add({ day: -1 })
      .format(defaultDateFormat);

    this.loadInspectionAssignments();
  }

  increaseDate() {
    this.targetDate = moment(this.targetDate)
      .add({ day: 1 })
      .format(defaultDateFormat);

    this.loadInspectionAssignments();
  }

  openAssignDialog() {
    (this.$refs.vehicleInspectionAssignDialog as any).openDialog({ targetVehicles: [this.vehicle] });
  }

  mounted() {
    getModule(InspectionAssignmentsModule, this.$store);
    this.vehicleInspectionAssignments = [];
    this.loadInspectionAssignments();
  }
}
