var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"vehicles",attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{ref:"table",staticClass:"drp-routes-table",class:{ 'border-top-color': _vm.filterPanelIsOpened },attrs:{"loading":_vm.pageLoading,"headers":_vm.displayHeaders,"multi-sort":"","items":_vm.displayRows,"hide-default-footer":"","expanded":_vm.expanded,"disable-pagination":"","options":{
          sortBy: _vm.routesSort.sortBy,
          sortDesc: _vm.routesSort.sortDesc,
        }},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
              'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.station",fn:function(ref){
            var item = ref.item;
return [_c('StationLabel',{attrs:{"stationsInUse":_vm.routePlan.stationsInUse,"targetStation":item.station},on:{"onChangeData":function($event){item.station = $event;
              _vm.saveRowData(item);}}})]}},{key:"item.staging",fn:function(ref){
              var item = ref.item;
return [_c('StagingLabel',{attrs:{"targetStaging":item.staging},on:{"onChangeData":function($event){item.staging = $event;
              _vm.saveRowData(item);}}})]}},{key:"item.route",fn:function(ref){
              var item = ref.item;
return [_c('RouteLabel',{attrs:{"targetRoute":item.route,"routesUsesCount":_vm.routePlan.routesUsesCount},on:{"onChangeData":function($event){item.route = $event;
              _vm.saveRowData(item);}}})]}},{key:"item.mnt",fn:function(ref){
              var item = ref.item;
return [_c('MntCheckbox',{attrs:{"targetRow":item},on:{"onChangeData":function($event){return _vm.saveEntryData(item)}}})]}},{key:"item.currentDriverEntry.status",fn:function(ref){
              var item = ref.item;
return [(item.currentDriverEntry)?_c('div',{staticClass:"driver-status-container"},[_c('DriverEntryStatus',{attrs:{"replacement-required":"","targetDate":_vm.targetDate,"targetDriverEntry":item.currentDriverEntry,"driverEntries":_vm.driverEntries,"additionalDataPerDriver":_vm.additionalDataPerDriver},on:{"onChangeData":function($event){item.currentDriverEntry.note = $event.newNote;
                item.currentDriverEntry.status = $event.selectedStatus;
                _vm.saveEntryOnChangeStatus(item, $event)}}})],1):_vm._e()]}},{key:"item.currentDriverEntry",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"driver-entry-select-container"},[_c('DriverEntrySelectLabel',{attrs:{"targetDate":_vm.targetDate,"targetDriverEntry":item.currentDriverEntry,"driverEntries":_vm.driverEntries,"additionalDataPerDriver":_vm.additionalDataPerDriver},on:{"onChangeData":function($event){item.currentDriverEntry = $event;
                _vm.saveRowData(item);}}})],1)]}},{key:"item.vehicle",fn:function(ref){
                var item = ref.item;
return [_c('VehicleLabel',{attrs:{"vehiclesUsesCount":_vm.routePlan.vehiclesUsesCount,"targetVehicle":item.vehicle,"printingDrpMode":_vm.printingDrpMode},on:{"onChangeData":function($event){item.vehicle = $event;
              _vm.saveRowData(item)}}})]}},{key:"item.devices",fn:function(ref){
              var item = ref.item;
return [_c('DeviceListLabel',{attrs:{"countDevicesInUse":_vm.routePlan.countDevicesInUse,"ownersPerDevice":_vm.routePlan.ownersPerDevice,"targetDevices":item.devices,"hostDriverEntry":item.currentDriverEntry},on:{"onChangeData":function($event){item.devices = $event;
              _vm.saveRowData(item)}}})]}},{key:"item.custom-expand",fn:function(ref){
              var item = ref.item;
return [_c('v-btn-toggle',{model:{value:(_vm.expandedPanelOpeningState[item.id]),callback:function ($$v) {_vm.$set(_vm.expandedPanelOpeningState, item.id, $$v)},expression:"expandedPanelOpeningState[item.id]"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":_vm.OPENED_RESCUES,"disabled":!item.rescuers.length,"icon":"","small":""},on:{"click":function($event){return _vm.toggleRow(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-truck-fast ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize("drp.routes_table.expanded.show_rescuers")))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":_vm.OPENED_HISTORY,"disabled":!item.replacementsHistory.length,"icon":"","small":""},on:{"click":function($event){return _vm.toggleRow(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-history ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize("drp.routes_table.expanded.show_history")))])])],1)]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteRowDialog(item)}}},[_vm._v("mdi-delete")]),_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 add-rescuer-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.createRescuer(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"track",attrs:{"small":""}},[_vm._v("mdi-truck-fast")]),_c('v-icon',{staticClass:"plus",attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.currentDriverEntry ? _vm.$localizationService.localize( "drp.routes_table.create_rescue.add_rescue_to", [item.currentDriverEntry.driver.name] ) : _vm.$localizationService.localize("drp.routes_table.create_rescue.add_driver_to_rescue"))+" ")])])],1)]}},{key:"expanded-item",fn:function(ref){
              var item = ref.item;
return [(_vm.expandedPanelOpeningState[item.id] === _vm.OPENED_HISTORY)?[_c('td'),_c('td'),_c('td'),_c('td'),_c('td',_vm._l((item.replacementsHistory),function(entry){return _c('div',{key:entry.id,staticClass:"entry-cell driver-status-container"},[_c('DriverEntryStatus',{attrs:{"is-disabled":"","targetDriverEntry":entry}})],1)}),0),_c('td',_vm._l((item.replacementsHistory),function(entry){return _c('div',{key:entry.id,staticClass:"entry-cell d-flex flex-row align-center"},[_c('DriverEntryLabel',{attrs:{"customClass":'replaced-driver-label',"targetDriverEntry":entry,"additionalDataPerDriver":_vm.additionalDataPerDriver[entry.driver.id] || {}}})],1)}),0),_c('td',{attrs:{"colspan":4}},_vm._l((item.replacementsHistory),function(entry){return _c('div',{key:entry.id,staticClass:"entry-cell"},[_c('NoteLabel',{attrs:{"isDisabled":true,"targetEntry":entry}})],1)}),0)]:_vm._e(),(_vm.expandedPanelOpeningState[item.id] === _vm.OPENED_RESCUES)?[_c('td',{staticClass:"pa-0",attrs:{"colspan":3}},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell pl-4 pr-4",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[_c('RescueWidget',{attrs:{"pRescue":rescuer,"pNestingIndex":rescuer.rescuersNestingIndexes},on:{"onChangeStatus":function($event){rescuer.status = $event;
                    _vm.saveRescuer(rescuer);},"onChangePackages":function($event){rescuer.packages = $event;
                    _vm.saveRescuer(rescuer);},"onChangeStops":function($event){rescuer.stops = $event;
                    _vm.saveRescuer(rescuer);},"onChangeBonus":function($event){rescuer.bonus.active = $event;
                    _vm.saveRescuer(rescuer);}}})],1)}),0),_c('td',{staticClass:"pa-0"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell pl-4 pr-4",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[_c('MntCheckbox',{attrs:{"targetRow":rescuer},on:{"onChangeData":function($event){return _vm.saveEntryData(rescuer)}}})],1)}),0),_c('td',{staticClass:"background-none pa-0"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell pl-4 pr-4",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[(rescuer.rescuer)?_c('DriverEntryStatus',{attrs:{"replacement-required":"","targetDate":_vm.targetDate,"targetDriverEntry":rescuer.rescuer,"driverEntries":_vm.driverEntries,"additionalDataPerDriver":_vm.additionalDataPerDriver},on:{"onChangeData":function($event){rescuer.rescuer.note = $event.newNote;
                    rescuer.rescuer.status = $event.selectedStatus;
                    _vm.saveEntryOnChangeStatus(rescuer, $event)}}}):_vm._e()],1)}),0),_c('td',{staticClass:"background-none pa-0"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell d-flex flex-row align-center pl-4 pr-4",class:{'driver-entry-rescue' : _vm.hoveredRescueIndex == index + item.id},style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[_c('DriverEntrySelectLabel',{staticClass:"full-width-desl",attrs:{"targetDate":_vm.targetDate,"targetDriverEntry":rescuer.rescuer,"driverEntries":_vm.driverEntries,"additionalDataPerDriver":_vm.additionalDataPerDriver},on:{"onChangeData":function($event){rescuer.rescuer = $event;
                    _vm.saveRescuer(rescuer);}}})],1)}),0),_c('td',{staticClass:"background-none pa-0"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell pl-4 pr-4",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[_c('VehicleLabel',{attrs:{"vehiclesUsesCount":_vm.routePlan.vehiclesUsesCount,"targetVehicle":rescuer.vehicle,"printingDrpMode":_vm.printingDrpMode},on:{"onChangeData":function($event){rescuer.vehicle = $event;
                    _vm.saveRescuer(rescuer);}}})],1)}),0),_c('td',{staticClass:"background-none pa-0"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell pl-4 pr-4",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[_c('DeviceListLabel',{attrs:{"countDevicesInUse":_vm.routePlan.countDevicesInUse,"ownersPerDevice":_vm.routePlan.ownersPerDevice,"targetDevices":rescuer.devices,"hostDriverEntry":rescuer.rescuer},on:{"onChangeData":function($event){rescuer.devices = $event;
                    _vm.saveRescuer(rescuer);}}})],1)}),0),_c('td',{staticClass:"pa-0 print-d-none"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell d-flex justify-start pl-4 pr-4",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}},[_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteRescuerDialog(rescuer, item)}}},[_vm._v("mdi-delete")]),(rescuer.rescuer)?_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 add-rescuer-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.createRescuer(rescuer)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"track",attrs:{"small":""}},[_vm._v("mdi-truck-fast")]),_c('v-icon',{staticClass:"plus",attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Rescuer to "+_vm._s(rescuer.rescuer.driver.name))])]):_vm._e()],1)}),0),_c('td',{staticClass:"pa-0 print-d-none"},_vm._l((_vm.displayRescuersByRowId[item.id]),function(rescuer,index){return _c('div',{key:rescuer.id,staticClass:"entry-cell justify-center",style:(_vm.getRowColor(rescuer)),on:{"mousemove":function($event){_vm.hoveredRescueIndex = index + item.id},"mouseleave":function($event){_vm.hoveredRescueIndex = undefined}}})}),0)]:_vm._e()]}}],null,true)}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }