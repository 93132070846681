<template>
 <VehicleReminders />
</template>

<script>
import VehicleReminders from "../components/reminders/VehicleReminders";

export default {
  name: "VehicleRemindersPage",
  components: { VehicleReminders },

  routing: {
    localizationKey: "menu.tab.vehicle_reminders",
    icon: "mdi-car-clock",
    path: "/vehicle-reminder",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>
