<template>
  <div v-if="property" class="incorrect-data">
    <v-text-field
      class="row-width"
      :label="
        $localizationService.localize(
          'mar_component.for_agents.item_properties.param.name'
        )
      "
      v-model="propertyDisplayName"
      disabled
    ></v-text-field>

    <div class="custom-block">
      <v-text-field
        :class="{ 'have-icon-close': mode == editMode }"
        class="row-width"
        :label="
          $localizationService.localize(
            'mar_component.for_agents.item_properties.param.value'
          )
        "
        v-model="textFieldValue"
        v-if="standardRowTypes.includes(property.type)"
        :rules="[validateValueRule]"
        :disabled="mode == viewMode"
      ></v-text-field>

      <div v-else-if="property.type == dateType" class="date-container">
        <v-text-field
          :class="{ 'have-icon-close': mode == editMode }"
          class="row-width"
          :label="
            $localizationService.localize(
              'mar_component.for_agents.item_properties.param.date_picker'
            )
          "
          v-model="rawSelectedValue"
          :rules="[validateValueRule]"
          :disabled="mode == viewMode"
        ></v-text-field>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="row-width closed-input"
              :class="{ 'have-icon-close': mode == editMode }"
              v-model="date"
              :disabled="mode == viewMode"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              {{ $localizationService.localize("btn.cancel") }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              {{ $localizationService.localize("btn.ok") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>

      <v-text-field
        v-else-if="property.type == instantType && mode == viewMode"
        class="row-width"
        :label="
          $localizationService.localize(
            'mar_component.for_agents.item_properties.param.value'
          )
        "
        v-model="rawSelectedValue"
        disabled
      ></v-text-field>

      <div
        v-else-if="property.type == instantType && mode != viewMode"
        class="date-container instant-container d-flex"
      >
        <v-text-field
          class="row-width"
          :label="
            $localizationService.localize(
              'mar_component.for_agents.item_properties.param.date_picker'
            )
          "
          v-model="date"
          :rules="[validateInstantLocalDate]"
        ></v-text-field>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="row-width-half closed-input date-field"
              :class="{ 'have-icon-close': mode == editMode }"
              v-model="date"
              :disabled="mode == viewMode"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              {{ $localizationService.localize("btn.cancel") }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              {{ $localizationService.localize("btn.ok") }}
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          class="row-width"
          :label="
            $localizationService.localize(
              'mar_component.for_agents.item_properties.param.time_picker'
            )
          "
          v-model="time"
          :rules="[validateInstantTime]"
        ></v-text-field>
        <v-menu
          ref="timeMenu"
          v-model="timeMenu"
          :close-on-content-click="false"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          min-width="290"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="row-width-half closed-input time-field"
              :class="{ 'have-icon-close': mode == editMode }"
              v-model="time"
              :disabled="mode == viewMode"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker v-model="time" use-seconds scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="timeMenu = false">
              {{ $localizationService.localize("btn.cancel") }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.timeMenu.save(time)">
              {{ $localizationService.localize("btn.ok") }}
            </v-btn>
          </v-time-picker>
        </v-menu>
      </div>

      <v-autocomplete
        v-else-if="property.type == enumType"
        class="row-width"
        :label="
          $localizationService.localize(
            'mar_component.for_agents.item_properties.param.value'
          )
        "
        v-model="textFieldValue"
        :items="values"
        item-text="displayName"
        item-value="value"
        :rules="[validateValueRule]"
        :disabled="mode == viewMode"
        hide-selected
      ></v-autocomplete>

      <v-btn
        class="btn-close-position"
        v-if="mode == editMode"
        elevation="2"
        x-small
        @click="cancelEdit()"
      >
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>

      <v-btn
        class="ml-2"
        elevation="2"
        x-small
        v-if="showEditBtn"
        @click="mode = editMode"
      >
        {{ $localizationService.localize("btn.edit") }}
      </v-btn>

      <div
        class="d-flex icons-status"
        :class="{ 'icons-with-icon-close': mode == editMode }"
        v-if="mode != viewMode"
      >
        <v-icon v-show="status" size="30" color="green"
          >mdi-checkbox-marked-circle-outline</v-icon
        >

        <v-icon v-show="!status" size="30" color="red"
          >mdi-alert-circle-outline</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { fixMode, viewMode, editMode } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "ItemPropertiesComponent",

  props: {
    property: Object,
    initMode: String,
  },

  data: () => ({
    viewMode,
    fixMode,
    editMode,

    menu: false,
    timeMenu: false,

    date: "",
    time: "",

    mode: "",
    textFieldValue: "",

    values: [],

    lockedItems: ["name", "transporterId", "timestamp"],
    standardRowTypes: [
      "Double",
      "Float",
      "Integer",
      "String",
      "Long",
      "Duration",
    ],
    enumType: "Enum",
    dateType: "LocalDate",
    instantType: "Instant",
    typesRules: {
      Float: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+(\.\d+)?)?%*$/.test(str);
        },
      },
      Double: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+(\.\d+)?)?%*$/.test(str);
        },
      },
      Integer: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+?)?$/.test(str);
        },
      },
      Long: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+?)?$/.test(str);
        },
      },
      String: {
        check: () => true,
      },
      LocalDate: {
        check: (str) => {
          if (!str) str = "";
          return moment(str).isValid();
        },
      },
      Time: {
        check: (str) => {
          if (!str) str = "";
          return moment(str, "HH:mm:ss", true).isValid();
        },
      },
      Instant: {
        check: (str) => {
          if (!str) str = "";
          return moment(str, "YYYY-MM-DDTHH:mm:ss", true).isValid();
        },
      },
      Duration: {
        check: (str) => {
          if (!str) str = "";
          return /^([1-9]+[0-9]*h *)*([1-9]+[0-9]*m *)*([1-9]+[0-9]*s)*$/.test(
            str,
          );
        },
        getErrorMessage: () => locService.localize(
          "mar_component.for_agents.item_properties.types_rules.error_msg",
        ),
      },
      Enum: {
        check: (str, values) => {
          if (!str) str = "";
          return values.map((value) => value.value).includes(str);
        },
      },
    },
  }),

  computed: {
    status() {
      return this.solution;
    },

    rawSelectedValue() {
      if (this.date) {
        return this.time ? `${this.date}T${this.time}` : this.date;
      }
      return this.textFieldValue;
    },

    showEditBtn() {
      return (
        this.mode == viewMode && !this.lockedItems.includes(this.property.name)
      );
    },

    solution() {
      return this.validateValue()
        ? {
          name: this.property.name,
          rawSelectedValue: this.rawSelectedValue,
        }
        : undefined;
    },

    propertyDisplayName() {
      return (
        Vue.prototype.$localizationService.namesMap[
          this.property.displayName
        ] || ""
      );
    },
  },

  methods: {
    validateValue() {
      try {
        return this.typesRules[this.property.type].check(
          this.rawSelectedValue,
          this.property.values,
        );
      } catch (error) {
        Vue.toasted.error(
          locService.localize(
            "mar_component.for_agents.item_properties.validate.can_not_validate_value",
            [this.propertyDisplayName],
          ),
        );
      }
      return undefined;
    },

    validateValueRule() {
      const rule = this.typesRules[this.property.type];

      return (
        this.validateValue()
        || (rule.getErrorMessage
          ? rule.getErrorMessage()
          : locService.localize(
            "mar_component.for_agents.item_properties.validate.unknown_value",
          ))
      );
    },

    validateInstantLocalDate() {
      const rule = this.typesRules.LocalDate;
      return (
        rule.check(this.date, this.property.values)
        || (rule.getErrorMessage
          ? rule.getErrorMessage()
          : locService.localize(
            "mar_component.for_agents.item_properties.validate.unknown_value",
          ))
      );
    },

    validateInstantTime() {
      const rule = this.typesRules.Time;
      return (
        rule.check(this.time, this.property.values)
        || (rule.getErrorMessage
          ? rule.getErrorMessage()
          : locService.localize(
            "mar_component.for_agents.item_properties.validate.unknown_value",
          ))
      );
    },

    cancelEdit() {
      this.mode = viewMode;
      this.date = "";
      this.time = "";
      this.resetTextField();
    },

    resetTextField() {
      this.textFieldValue = (
        this.values.find(
          (suspect) => suspect.displayName == this.property.rawValue,
        ) || { value: this.property.rawValue }
      ).value;
    },
  },

  beforeMount() {
    this.mode = this.initMode;
    this.values = [...this.property.values];
    this.resetTextField();

    if (
      this.property.type == this.enumType
      && !this.values.find(
        (suspect) => suspect.displayName == this.property.rawValue,
      )
    ) {
      this.values.push({
        displayName: this.property.rawValue,
        value: this.property.rawValue,
        disabled: true,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.incorrect-data {
  display: flex;
  justify-content: flex-start;
  .custom-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 55%;
    .row-width {
      max-width: 80%;
      margin-left: 16px;
      &.have-icon-close {
        max-width: 70%;
      }
    }
    .row-width-half {
      max-width: 40%;
      margin-left: 16px;
      &.have-icon-close {
        max-width: 70%;
      }
    }
    .btn-close-position {
      position: absolute;
      right: 50px;
      top: 23px;
    }
    .icons-status {
      margin-left: 15px;
      &.icons-with-icon-close {
        margin-left: 51px;
        margin-right: 9px;
      }
    }
  }

  .date-container {
    position: relative;
    width: 100%;
    .row-width {
      max-width: 95%;
      &.have-icon-close {
        max-width: 100%;
      }
    }
    .row-width-half {
      max-width: 47.5%;
      &.have-icon-close {
        max-width: 100%;
      }
    }
    .v-text-field.closed-input {
      opacity: 0;
      filter: alpha(opacity=0);
      position: absolute;
      top: -5%;
      left: 0;
      width: calc(100% + 32px);
    }
  }

  .instant-container {
    .v-text-field.closed-input {
      &.date-field {
        width: calc(50% + 16px);
      }
      &.time-field {
        left: unset !important;
        right: 0;
        width: calc(50% + 16px);
      }
    }
  }

  .row-width {
    max-width: 45%;
  }
}
@media screen and (max-width: 425px) {
  .incorrect-data {
    display: block;
    .custom-block {
      width: 100%;
      .row-width {
        margin-left: 0;
        max-width: 100%;
      }
      .btn-close-position {
        right: 45px;
      }
      .icons-status {
        &.icons-with-icon-close {
          margin-left: 62px;
        }
      }
    }
    .row-width {
      max-width: 100%;
    }
  }
}
</style>
