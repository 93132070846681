var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"drp-entries-table",class:{ 'border-top-color': _vm.pFilterPanelIsOpened },attrs:{"headers":_vm.displayHeaders,"items":_vm.displayRescuers,"loading":_vm.pPageLoading,"multi-sort":"","disable-pagination":"","hide-default-footer":"","options":{
      sortBy: _vm.rescuersSort.sortBy,
      sortDesc: _vm.rescuersSort.sortDesc,
    }},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
          'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
        }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('RescueStatus',{attrs:{"pRescuer":item},on:{"onSelectStatus":function($event){item.status = $event;
          _vm.saveRescue(item);}}})]}},{key:"item.rescuer",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"driver-entry-select-container"},[_c('DriverEntrySelectLabel',{attrs:{"targetDate":_vm.pTargetDate,"targetDriverEntry":item.rescuer,"driverEntries":_vm.pRoutePlan.driverEntries,"additionalDataPerDriver":_vm.pAdditionalDataPerDriver},on:{"onChangeData":function($event){item.rescuer = $event;
            _vm.saveRescue(item);}}})],1)]}},{key:"item.target",fn:function(ref){
            var item = ref.item;
return [(item.target.driverEntry)?_c('div',[_c('DriverEntryLabel',{attrs:{"targetDriverEntry":item.target.driverEntry,"additionalDataPerDriver":_vm.pAdditionalDataPerDriver[item.target.driverEntry.driver.id] || {},"isDuplicate":item.target.driverEntry.isWillBeDuplicate}})],1):_vm._e()]}},{key:"item.stops",fn:function(ref){
            var item = ref.item;
return [_c('ViewEditInteger',{attrs:{"pLabel":_vm.$localizationService.localize('drp.view_edit_integer.stops'),"pValue":item.stops,"pSpecialColors":[+item.stops <= 0 ? 'orange--text' : '']},on:{"onChangeValue":function($event){item.stops = $event;
          _vm.saveRescue(item);}}})]}},{key:"item.packages",fn:function(ref){
          var item = ref.item;
return [_c('ViewEditInteger',{attrs:{"pLabel":_vm.$localizationService.localize('drp.view_edit_integer.packages'),"pValue":item.packages,"pSpecialColors":[+item.packages < +item.stops ? 'orange--text' : '']},on:{"onChangeValue":function($event){item.packages = $event;
          _vm.saveRescue(item);}}})]}},{key:"item.bonus",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"small-checkbox",on:{"input":function($event){return _vm.saveRescue(item);}},model:{value:(item.bonus.active),callback:function ($$v) {_vm.$set(item.bonus, "active", $$v)},expression:"item.bonus.active"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.deleteRescue(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }