<template>
  <AutoCoachingTablesFacade />
</template>

<script>
import AutoCoachingTablesFacade from "../../components/coaching/AutoCoachingTablesFacade.vue";

export default {
  name: "CoachingPage",

  routing: {
    localizationKey: "menu.tab.coaching",
    icon: "mdi-school-outline",
    path: "/coaching-table",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: { AutoCoachingTablesFacade },
};
</script>
