import { render, staticRenderFns } from "./ResolvedCoachingInfractionsTable.vue?vue&type=template&id=d85c7bea&scoped=true&"
import script from "./ResolvedCoachingInfractionsTable.vue?vue&type=script&lang=js&"
export * from "./ResolvedCoachingInfractionsTable.vue?vue&type=script&lang=js&"
import style0 from "./ResolvedCoachingInfractionsTable.vue?vue&type=style&index=0&id=d85c7bea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d85c7bea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCol,VDataTable,VRow,VSpacer,VTextField,VToolbar,VToolbarTitle})
