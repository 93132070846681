<template>
  <v-autocomplete
    class="driver-autocomplete"
    :label="
      selectedEntryModel || isReplacement
        ? $localizationService.localize(
            'drp.entry_select.replacement_driver_label'
          )
        : $localizationService.localize('drp.entry_select.driver_label')
    "
    :items="items"
    :loading="loading"
    clearable
    clear-icon="mdi-delete"
    :item-text="getItemText"
    return-object
    v-model="selectedEntryModel"
    hide-details
    :search-input.sync="searchDriver"
    @click:clear="clearSelection()"
    @blur="clearOnBlur()"
    ref="inputDriver"
    :placeholder="$localizationService.localize('common.begin_typing')"
  >
    <template v-slot:selection="{ item }">
      <DriverEntryLabel
        v-if="item.id"
        :targetDriverEntry="item"
        :additionalDataPerDriver="
          additionalDataPerDriver[item.driver.id] || {}
        "
        :isDuplicate="item.isWillBeDuplicate"
      />
      <MatchedDriverLabel
        v-else
        :class="{ 'orange--text': item.isWillBeDuplicate }"
        :driver="item.driver"
        :additionalDataPerDriver="
          additionalDataPerMatchedDriver[item.driver.id] || {}
        "
      />
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content v-if="item.group">
        {{ item.header }}
      </v-list-item-content>
      <div v-else :class="getItemClass(item)">
        <DriverEntryLabel
          class="mt-2 mb-2"
          v-if="item.id"
          :targetDriverEntry="item"
          :additionalDataPerDriver="
            additionalDataPerDriver[item.driver.id] || {}
          "
          :isDuplicate="isDuplicateDriver(item)"
          :visibleOptionalParams="true"
        />
        <MatchedDriverLabel
          v-else
          :class="{ 'orange--text': isDuplicateDriver(item) }"
          :driver="item.driver"
          :additionalDataPerDriver="
            additionalDataPerMatchedDriver[item.driver.id] || {}
          "
        />
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import differenceWith from "lodash/differenceWith";
import debounce from "lodash/debounce";
import Vue from "vue";
import MatchedDriverLabel from "../MatchedDriverLabel.vue";
import DriverEntryLabel from "./DriverEntryLabel.vue";

const locService = Vue.prototype.$localizationService;

export default {
  name: "DriverEntrySelect",

  components: {
    DriverEntryLabel,
    MatchedDriverLabel,
  },

  props: {
    isReplacement: {
      type: Boolean,
      default: false,
    },
    driverEntries: Array,
    additionalDataPerDriver: Object,
    targetDriverEntry: Object,
    targetDate: String,
  },

  data() {
    return {
      searchDriver: null,

      selectedEntryModel: this.isReplacement
        ? undefined
        : this.targetDriverEntry,

      matchedDrivers: [],
      additionalDataPerMatchedDriver: [],

      loading: false,
    };
  },

  watch: {
    searchDriver() {
      if (this.searchDriver) {
        this.loadFilteredDrivers();
      }
    },

    async selectedEntryModel() {
      let selectedEntry;

      if (this.selectedEntryModel) {
        if (this.selectedEntryModel.id) {
          selectedEntry = this.selectedEntryModel;
        } else {
          selectedEntry = await this.$store.dispatch(
            "dailyRoutePlannerModule/createDriverEntry",
            {
              driverId: this.selectedEntryModel.driver.id,
              targetDate: this.targetDate,
            },
          );
        }
      }

      this.$emit("input", selectedEntry);
    },
  },

  computed: {
    items() {
      if (this.matchedDrivers.length) {
        return this.matchedDrivers;
      }

      const reserveEntries = this.driverEntries.filter((it) => it.reserve);
      const noReserveEntries = differenceWith(
        this.driverEntries,
        reserveEntries,
        (a, b) => a.id == b.id,
      );

      return [
        {
          group: true,
          header: locService.localize(
            "drp.entry_select.item_group_header.reserve",
          ),
        },
        ...reserveEntries,
        {
          group: true,
          header: locService.localize("drp.entry_select.item_group_header.all"),
        },
        ...noReserveEntries,
      ];
    },
  },

  methods: {
    onClick() {
      this.state = "edit";
    },

    cancel() {
      this.state = "view";
    },

    getItemText(item) {
      return item.driver ? item.driver.name : item.name;
    },

    isDuplicateDriver(entry) {
      return entry.usesCount > 0;
    },

    clearOnBlur() {
      if (this.matchedDrivers.length) {
        this.matchedDrivers = [];
        this.additionalDataPerMatchedDriver = [];
      }
    },

    clearSelection() {
      this.selectedEntryModel = undefined;
      if (this.matchedDrivers.length) {
        this.matchedDrivers = [];
        this.additionalDataPerMatchedDriver = [];
      }
    },

    getItemClass(item) {
      return item.reserve && this.selectedEntryModel != item
        ? "item-green-container"
        : "";
    },

    // TODO: warning
    loadFilteredDrivers: debounce(function () {
      this.loading = true;

      this.$store.dispatch(
        "dailyRoutePlannerModule/loadFilteredDrivers",
        this.searchDriver,
      ).then((data) => {
        if (data.length) {
          this.matchedDrivers = data
            .map(
              (it) => this.driverEntries.find(
                (entry) => entry.driver.id == it.driver.id,
              ) || { driver: it.driver },
            )
            .sort((a, b) => a.driver.name.localeCompare(b.driver.name));

          data.forEach((it) => {
            this.additionalDataPerMatchedDriver[it.driver.id] = it.data;
          });
        } else {
          this.matchedDrivers = [];
          this.additionalDataPerMatchedDriver = [];
        }

        this.loading = false;
      });
    }, 300),
  },
};
</script>

<style scoped lang="scss">
.select-from-routes {
  .driver-autocomplete {
    font-size: 14px;
    max-width: 400px;
    width: min-content;
  }
}
.item-green-container {
  width: 100%;
}
.item-green-container::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgb(76, 175, 80, 0.2) !important;
}
.decor-none {
  text-decoration: none;
}
.no-wrap-name {
  white-space: nowrap;
}
</style>
