















import {
  Vue, Component, Prop, Emit,
} from "vue-property-decorator";

@Component
export default class BulkMessageTargetCard extends Vue {
  @Prop()
  readonly driver: any

  @Prop()
  readonly beginSending: boolean | undefined

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onCancel(): void {}
}
