<template>
  <VehicleGroup />
</template>

<script>
import VehicleGroup from "../components/vehicle-groups/VehicleGroups";

export default {
  name: "VehicleGroupsPage",
  components: { VehicleGroup },

  routing: {
    localizationKey: "menu.tab.vehicle_groups",
    icon: "mdi-warehouse",
    path: "/vehicle-groups",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>

<style scoped>

</style>
