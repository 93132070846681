var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mb-1",attrs:{"headers":_vm.headers,"items":_vm.infractions,"item-class":_vm.getItemClass,"footer-props":_vm.footerProps,"multi-sort":"","items-per-page":_vm.itemsPerPage,"height":_vm.infractionsTableHeight},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.coaching_dialog_infractions_table.title", [_vm.infractions.length] ))+" ")])],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionFormattedCreatedAt(item))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionFormattedDate(item))+" ")]}},{key:"item.criteria",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.criteria.name)+" ")]}},{key:"item.detectedValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.detectedValue)+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }