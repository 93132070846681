<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
                editedVehicleShop.id
                    ? "vehicle_shop_page.dialog.add_and_edit.edit_title"
                    : "vehicle_shop_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  :rules="requiredRule"
                  v-model="editedVehicleShop.name"
                  :label="
                      $localizationService.localize(
                          'vehicle_shop_page.dialog.add_and_edit.param.name'
                      )
                  "
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  :rules="requiredRule"
                  v-model="editedVehicleShop.address"
                  :label="
                      $localizationService.localize(
                          'vehicle_shop_page.dialog.add_and_edit.param.address'
                      )
                  "
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="addOrSaveVehicleShop"
            :disabled="saveIsDisabled"
        >
          {{ $localizationService.localize(editedVehicleShop.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { isEqual } from "lodash";
import { VehicleShop } from "@/model/vehicle-shop.model";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditVehicleShopDialog",

  data: () => ({
    open: false,
    loading: false,

    editedVehicleShopOriginal: {},
    editedVehicleShop: {},

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

  }),

  computed: {
    saveIsDisabled() {
      return this.loading
          || !this.editedVehicleShop.name
          || !this.editedVehicleShop.address
          || isEqual(this.editedVehicleShop, this.editedVehicleShopOriginal);
    },
  },

  methods: {

    openDialog(vehicleShop) {
      this.editedVehicleShopOriginal = vehicleShop || {};
      this.editedVehicleShop = Object.assign(new VehicleShop(), this.editedVehicleShopOriginal);
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    async addOrSaveVehicleShop() {
      this.loading = true;
      const response = await this.$store.dispatch(
        "vehicleShopsModule/addOrSaveVehicleShop",
        this.editedVehicleShop,
      );
      this.loading = false;
      if (response) {
        this.$emit("onAddOrSave", response);
        this.open = false;
      }
    },
  },
};
</script>
