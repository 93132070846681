var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"vehicles-page",class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{staticClass:"vehicles-page-row",attrs:{"align":"start","justify":"start"}},[(_vm.overlayEnabled)?_c('v-col',{staticClass:"vehicles-overlay",attrs:{"cols":"12"}},[_c('v-overlay',{attrs:{"absolute":true,"opacity":"1","color":"grafanaOverlayColor","value":_vm.overlayEnabled}},[_c('v-progress-circular',{attrs:{"size":70,"color":"primary","indeterminate":""}})],1)],1):_vm._e(),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.overlayEnabled),expression:"!overlayEnabled"}],staticClass:"vehicles-page-col",attrs:{"cols":"12"}},[_c('v-data-table',{class:{'not-default-page-size': !this.isDefaultSize},attrs:{"headers":_vm.displayHeaders,"items":_vm.displayVehicles,"loading":_vm.pageLoading || !_vm.tableInited,"server-items-length":_vm.totalCount,"item-class":_vm.getItemClass,"options":{
          page: _vm.pageIndex,
          itemsPerPage: _vm.pageSize,
          sortBy: _vm.sort.sortBy,
          sortDesc: _vm.sort.sortDesc,
        },"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_page.title"))+" "),_c('SelectTableColumns',{class:{'d-none': _vm.printingPageMode},attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders},on:{"onChangeHeaders":function($event){return _vm.changeHeaders($event)}}}),_c('TableSettings',{staticClass:"ml-5",attrs:{"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize(
                    'vehicle_page.settings_tooltip.save'
                  ),"pDefaultButtonTooltip":_vm.$localizationService.localize(
                    'vehicle_page.settings_tooltip.apply_default'
                  )},on:{"onSaveSettings":function($event){return _vm.saveSettings()},"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()}}})],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":_vm.openRentalExpiryThresholdDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cog")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize('vehicle_page.settings')))])]),_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.printVehicles()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-printer")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize('vehicle_page.print_vehicles')))])]),_c('v-text-field',{staticClass:"search-field",class:{'d-none': _vm.printingPageMode && !_vm.searchString},attrs:{"label":_vm.$localizationService.localize('vehicle_page.search'),"hide-details":"","width":"200"},on:{"input":function($event){return _vm.searchStringDebounceWatch()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditVehicleDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_page.new_btn"))+" ")]),_c('EditVehiclesDialog',{ref:"vehiclesDialog",on:{"editedVehicle":function($event){return _vm.reloadPage()}}}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
              'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":("/vehicles/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.assignedTo",fn:function(ref){
            var item = ref.item;
return [(_vm.findingDrivers)?_c('v-progress-circular',{attrs:{"size":30,"color":"primary","indeterminate":""}}):_vm._e(),(!_vm.findingDrivers)?_c('span',{class:_vm.getDriverNameTextColor(item)},[_vm._v(" "+_vm._s((_vm.drpDriversAndVehicles[item.id] || []) .map(function (it) { return it.driver.name; }) .join(", ") || "Not assigned")+" ")]):_vm._e()]}},{key:"item.openIssuesCount",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"issues-chip-wrapper",on:{"click":function($event){return _vm.openIssuesFor(item)}}},[(item.openIssuesCount)?_c('v-chip',{staticClass:"issues-chip white--text",attrs:{"color":"red"}},[_c('v-avatar',{staticClass:"red darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s(item.openIssuesCount)+" ")]),_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_page.issues"))+" ")],1):_c('v-chip',{staticClass:"issues-chip"},[_vm._v(_vm._s(_vm.$localizationService.localize("vehicle_page.no_issues")))])],1)]}},{key:"item.vin",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","z-index":"100","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openVinQrDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-qrcode")])],1),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.vin))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_page.vin_qr"))+" ")])])]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.getDisplayType())+" ")]}},{key:"item.fuelCard",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.fuelCard ? item.fuelCard.cardId : "")+" ")])]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(" "+_vm._s(item.fuelCard ? _vm.$localizationService.localize("vehicle_page.card_number", [item.fuelCard.cardNumber]) : "")+" ")]),_c('div',[_vm._v(" "+_vm._s(item.fuelCard ? _vm.$localizationService.localize("vehicle_page.expiration_date", [item.fuelCard.validThru]) : "")+" ")])])])]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.updatedAt))+" ")]}},{key:"item.rentalExpiry",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.rentalExpiry))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:item.getStatusColor()},[_vm._v(" "+_vm._s(item.getDisplayStatus())+" ")])]}},{key:"item.vehicleShop",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.vehicleShop ? item.vehicleShop.name : "")+" ")]}},{key:"item.vehicleGroups",fn:function(ref){
            var item = ref.item;
return [(item.vehicleGroups.length <= 3 && item.vehicleGroups.length > 0)?_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.vehicleGroups),function(group){return _c('v-chip',{key:group.id},[_vm._v(" "+_vm._s(group.name)+" ")])}),1):(item.vehicleGroups.length > 3 && item.vehicleGroups.length > 0)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_page.vehicle_groups.count", [ item.vehicleGroups.length ]))+" ")])]}}],null,true)},[_c('v-container',{staticClass:"allowed-users-container"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.vehicleGroups),function(group){return _c('v-chip',{key:group.id},[_vm._v(" "+_vm._s(group.name)+" ")])}),1)],1)],1)],1)],1):_vm._e()]}},{key:"item.fleetManagementCompany",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.getDisplayFleetManagementCompany())+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openEditVehicleDialog(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteVehicleDialog(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1),_c('QrDialog',{ref:"vinQrDialog",attrs:{"isVehicle":"true"}}),_c('RentalExpiryDueSoonDialog',{ref:"rentalExpiryDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }