<template>
  <v-container fluid class="vehicles-page" :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start" class="vehicles-page-row">
      <v-col cols="12" v-if="overlayEnabled" class="vehicles-overlay">
        <v-overlay
          :absolute="true"
          opacity="1"
          color="grafanaOverlayColor"
          :value="overlayEnabled"
        >
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-col>
      <v-col cols="12" v-show="!overlayEnabled" class="vehicles-page-col">
        <v-data-table
          :class="{'not-default-page-size': !this.isDefaultSize}"
          :headers="displayHeaders"
          :items="displayVehicles"
          :loading="pageLoading || !tableInited"
          :server-items-length="totalCount"
          :item-class="getItemClass"
          @update:options="paginate($event)"
          :options="{
            page: pageIndex,
            itemsPerPage: pageSize,
            sortBy: sort.sortBy,
            sortDesc: sort.sortDesc,
          }"
          :footer-props="footerProps"
          multi-sort
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("vehicle_page.title") }}
                <SelectTableColumns
                  :headers="headers"
                  :selectedHeaders="selectedHeaders"
                  :class="{'d-none': printingPageMode}"
                  @onChangeHeaders="changeHeaders($event)"/>
                <TableSettings
                  class="ml-5"
                  :pIsVisible="tableInited && !isDefaultSettings"
                  :pSaveButtonTooltip="
                    $localizationService.localize(
                      'vehicle_page.settings_tooltip.save'
                    )
                  "
                  :pDefaultButtonTooltip="
                    $localizationService.localize(
                      'vehicle_page.settings_tooltip.apply_default'
                    )
                  "
                  @onSaveSettings="saveSettings()"
                  @onApplyDefaultSettings="applyDefaultSettings()"
                />
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip open-delay="500" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="openRentalExpiryThresholdDialog"
                    icon
                    class="mr-2"
                  >
                    <v-icon> mdi-cog</v-icon>
                  </v-btn>
                </template>
                <span>{{$localizationService.localize('vehicle_page.settings')}}</span>
              </v-tooltip>
              <v-tooltip open-delay="500" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="printVehicles()"
                    icon
                    class="mr-2"
                  >
                    <v-icon> mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>{{$localizationService.localize('vehicle_page.print_vehicles')}}</span>
              </v-tooltip>
              <v-text-field
                class="search-field"
                :class="{'d-none': printingPageMode && !searchString}"
                :label="$localizationService.localize('vehicle_page.search')"
                hide-details
                width="200"
                v-model="searchString"
                @input="searchStringDebounceWatch()"
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-2"
                @click="openEditVehicleDialog()"
              >
                {{ $localizationService.localize("vehicle_page.new_btn") }}
              </v-btn>
              <EditVehiclesDialog ref="vehiclesDialog" @editedVehicle="reloadPage()"/>
              <ConfirmationDialog ref="deleteDialog" />
            </v-toolbar>
          </template>

          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
              :key="header.value"
              :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="`/vehicles/${item.id}`">
              {{ item.name }}
            </router-link>
          </template>

          <template v-slot:[`item.assignedTo`]="{ item }">
            <v-progress-circular
              v-if="findingDrivers"
              :size="30"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <span v-if="!findingDrivers" :class="getDriverNameTextColor(item)">
              {{
                (drpDriversAndVehicles[item.id] || [])
                  .map((it) => it.driver.name)
                  .join(", ") || "Not assigned"
              }}
            </span>
          </template>

          <template v-slot:[`item.openIssuesCount`]="{ item }">
            <div @click="openIssuesFor(item)" class="issues-chip-wrapper">
              <v-chip v-if="item.openIssuesCount" color="red" class="issues-chip white--text">
                <v-avatar
                  left
                  class="red darken-4"
                >
                  {{item.openIssuesCount}}
                </v-avatar>
                {{ $localizationService.localize("vehicle_page.issues") }}
              </v-chip>
              <v-chip v-else class="issues-chip">{{ $localizationService.localize("vehicle_page.no_issues") }}</v-chip>
            </div>
          </template>

          <template v-slot:[`item.vin`]="{ item }">
            <v-tooltip bottom z-index="100" open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="openVinQrDialog(item)"
                  >
                    <v-icon>mdi-qrcode</v-icon>
                  </v-btn>
                  <span class="ml-1">{{ item.vin }}</span>
                </div>
              </template>
              <span>
                {{ $localizationService.localize("vehicle_page.vin_qr") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{ item.getDisplayType() }}
          </template>

          <template v-slot:[`item.fuelCard`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                 <span
                     v-bind="attrs"
                     v-on="on"
                 > {{ item.fuelCard ? item.fuelCard.cardId : "" }}
                 </span>
              </template>
              <div class="d-flex flex-column">
                <div>
                  {{ item.fuelCard ?
                    $localizationService.localize("vehicle_page.card_number", [item.fuelCard.cardNumber]) : "" }}
                </div>
                <div>
                  {{ item.fuelCard ?
                    $localizationService.localize("vehicle_page.expiration_date", [item.fuelCard.validThru]) : "" }}
                </div>
              </div>
            </v-tooltip>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ getFormattedDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ getFormattedDate(item.updatedAt) }}
          </template>

          <template v-slot:[`item.rentalExpiry`]="{ item }">
            {{ getFormattedDate(item.rentalExpiry) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span :class="item.getStatusColor()">
              {{ item.getDisplayStatus() }}
            </span>
          </template>

          <template v-slot:[`item.vehicleShop`]="{ item }">
            {{ item.vehicleShop ? item.vehicleShop.name : "" }}
          </template>

          <template v-slot:[`item.vehicleGroups`]="{ item }">
            <v-chip-group column v-if="item.vehicleGroups.length <= 3 && item.vehicleGroups.length > 0">
              <v-chip
                  v-for="group in item.vehicleGroups"
                  :key="group.id"
              >
                {{group.name}}
              </v-chip>
            </v-chip-group>
            <v-tooltip v-else-if="item.vehicleGroups.length > 3 && item.vehicleGroups.length > 0" bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs"
                    v-on="on"
                >
                  {{
                    $localizationService.localize("vehicle_page.vehicle_groups.count", [
                      item.vehicleGroups.length,
                    ])
                  }}
                </v-chip>
              </template>
              <v-container class="allowed-users-container">
                <v-row justify="center">
                  <v-col>
                    <v-chip-group column>
                      <v-chip
                          v-for="group in item.vehicleGroups"
                          :key="group.id"
                      >
                        {{group.name}}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-tooltip>
          </template>

          <template v-slot:[`item.fleetManagementCompany`]="{ item }">
            {{ item.getDisplayFleetManagementCompany() }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-icon medium class="mr-2" @click="openEditVehicleDialog(item)"
                >mdi-pencil</v-icon
              >
              <v-icon medium color="red" @click="openDeleteVehicleDialog(item)"
                >mdi-delete</v-icon
              >
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <QrDialog isVehicle="true" ref="vinQrDialog" />
    <RentalExpiryDueSoonDialog ref="rentalExpiryDialog"></RentalExpiryDueSoonDialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import lodash from "lodash";
import groupBy from "lodash/groupBy";
import EditVehiclesDialog from "./EditVehiclesDialog.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import TableSettings from "@/components/TableSettings.vue";
import QrDialog from "./QrDialog.vue";
import {
  dateTimeMomentFormat,
  defaultDateFormat,
} from "../../utils/utils";
import tableSortParserMixin from "../../utils/table-sort-parser";
import SelectTableColumns from "@/components/SelectTableColumns";
import { ISSUES_SEVERITY_CRITICAL, ISSUES_SEVERITY_WARNING } from "@/utils/ts-utils";
import RentalExpiryDueSoonDialog from "../reminders/RentalExpiryDueSoonDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Vehicle",

  mixins: [tableSortParserMixin],

  components: {
    RentalExpiryDueSoonDialog,
    SelectTableColumns,
    EditVehiclesDialog,
    ConfirmationDialog,
    QrDialog,
    TableSettings,
  },

  data: () => ({
    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    tableInited: false,

    overlayEnabled: false,
    printingPageMode: false,

    findingDrivers: false,

    searchString: "",

    headers: [
      {
        text: locService.localize("vehicle_page.header.name"),
        value: "name",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.vin"),
        value: "vin",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.open_issues_count"),
        value: "openIssuesCount",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.type"),
        value: "type",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.status"),
        value: "status",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.vehicle_shop"),
        value: "vehicleShop",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.vehicle_groups"),
        value: "vehicleGroups",
        sortable: false,
      },
      {
        text: locService.localize("vehicle_page.header.assigned_to"),
        value: "assignedTo",
        sortable: false,
      },
      {
        text: locService.localize("vehicle_page.header.make"),
        value: "make",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.model"),
        value: "model",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.fuel_card"),
        value: "fuelCard",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.license_plate"),
        value: "licensePlate",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.rental_expiry"),
        value: "rentalExpiry",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.rental_provider"),
        value: "rentalProvider",
        sortable: true,
      },
      {
        text: locService.localize(
          "vehicle_page.header.fleet_management_company",
        ),
        value: "fleetManagementCompany",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.notes"),
        value: "notes",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("coreModule", ["drawer"]),
    ...mapState("vehiclesModule", [
      "displayVehicles",
      "currentPage",
      "totalCount",
      "pageLoading",
    ]),
    ...mapState("dailyRoutePlannerModule", ["routePlan"]),

    ...mapState("vehicleTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
      "selectedHeaders",
    ]),
    ...mapGetters("vehicleTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    drpDriversAndVehicles() {
      return groupBy(
        lodash.flatten(this.routePlan.rows.map((it) => it.driverEntriesAndVehicles))
          .filter((it) => it.driver && it.vehicle),
        "vehicle.id",
      );
    },

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },

    displayHeaders() {
      const headers = this.headers.filter((it) => this.selectedHeaders.includes(it.value));
      return !this.printingPageMode
        ? headers
        : headers.filter((it) => it.value !== "actions");
    },
  },

  methods: {

    getItemClass(item) {
      if (item.highestIssuesSeverity == ISSUES_SEVERITY_CRITICAL) return "vehicle-with-critical-issues";
      if (item.highestIssuesSeverity == ISSUES_SEVERITY_WARNING) return "vehicle-with-warning-issues";
      return "";
    },

    openIssuesFor(item) {
      this.openVinQrDialog(item);
    },

    reloadPage() {
      this.$store.dispatch("vehiclesModule/reloadPage");
    },

    openRentalExpiryThresholdDialog() {
      this.$refs.rentalExpiryDialog.openDialog();
    },

    printVehicles() {
      this.overlayEnabled = true;
      this.$store.commit("coreModule/hideDrawerBeforePrint");
      setTimeout(() => {
        this.printingPageMode = true;
      }, 200);
      setTimeout(() => {
        window.print();
      }, 500);
    },

    getFormattedDate(date) {
      return date ? moment(date).format(dateTimeMomentFormat) : undefined;
    },

    getDriverNameTextColor(vehicle) {
      const drivers = this.drpDriversAndVehicles[vehicle.id] || [];
      if (drivers.length == 1) return "green--text";
      if (drivers.length > 1) return "orange--text";
      return undefined;
    },

    async paginate(data) {
      this.$store.commit("vehicleTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("vehiclesModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    saveSettings() {
      this.$store.commit("vehicleTableSettingsModule/saveSettings");

      Vue.toasted.success(
        "Settings have been saved!",
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("vehicleTableSettingsModule/applyDefaultSettings");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.selectedHeaders.length) {
        params.append("columns", JSON.stringify(this.selectedHeaders));
      }

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
        || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
        || this.$route.query.searchString != (params.get("searchString") || undefined)
        || this.$route.query.columns != (params.get("columns") || undefined)
        || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/vehicles/?${params.toString()}`);
      }
    },

    // TODO: warning
    searchStringDebounceWatch: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("vehiclesModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),

    openVinQrDialog(vehicle) {
      this.$refs.vinQrDialog.openDialog(vehicle);
    },

    openEditVehicleDialog(vehicle) {
      this.$refs.vehiclesDialog.openDialog(vehicle);
    },

    openDeleteVehicleDialog(vehicle) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("vehicle_page.dialog.delete.title"),
        text: locService.localize("vehicle_page.dialog.delete.text", [
          vehicle.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          try {
            const result = await this.$store.dispatch(
              "vehiclesModule/deleteVehicle",
              vehicle,
            );
            Vue.toasted.success(result, {
              duration: 5000,
            });
            this.reloadPage();
          } catch (e) {
            Vue.toasted.error(e, {
              duration: 5000,
            });
          }
        },
      });
    },

    changeHeaders(event) {
      this.$store.commit("vehicleTableSettingsModule/updateHeaders", event);
      this.updateRoutePath();
    },
  },

  created() {
    this.$store.dispatch("vehicleTableSettingsModule/loadDefaultSettingsFromStorage", { headers: this.headers.map((it) => it.value) });

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("vehicleTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("vehicleTableSettingsModule/applyQuerySettings", query);

      if (query.searchString) {
        this.searchString = query.searchString;
      }
    }
  },

  async mounted() {
    window.addEventListener("beforeprint", () => {
      if (!this.overlayEnabled) {
        this.overlayEnabled = true;
        this.printingPageMode = true;
        this.$store.commit("coreModule/hideDrawerBeforePrint");
      }
    });
    window.addEventListener("afterprint", () => {
      this.printingPageMode = false;
      this.overlayEnabled = false;
      this.$store.commit("coreModule/restoreDrawerAfterPrint");
    });

    this.findingDrivers = true;

    await this.$store.dispatch(
      "dailyRoutePlannerModule/loadPage",
      moment().format(defaultDateFormat),
    );

    this.findingDrivers = false;
  },
};
</script>

<style lang="scss">
.vehicles-page {
  .search-field {
    max-width: 250px;
  }
  .v-data-table-header-mobile {
    display: none;
  }
  .issues-chip {
    cursor: pointer;
  }
  .issues-chip-wrapper {
    width: min-content;
  }
  .vehicle-with-critical-issues {
    background-color: rgba(255, 0, 0, 0.1);
  }
  .vehicle-with-warning-issues {
    background-color: rgba(251, 140, 0, 0.15);
  }
}
@media print {
  .vehicles-page {
    max-width: 100% !important;
    padding: 0;
    margin: 0 !important;
    .v-toolbar {
      height: auto !important;
      .v-toolbar__content {
        padding: 0 !important;
        color: black;
        .v-text-field {
          margin-left: 15px;
          margin-top: 0 !important;
          .v-input__control > .v-input__slot:before {
            border-color: black;
          }
          label {
            color: black !important;
          }
          input {
            font-size: 15px;
            color: black !important;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
          }
        }
      }
    }

    .v-btn,
    .v-icon,
    .vehicles-overlay {
      display: none !important;
    }
    .vehicles-page-col {
      display: block !important;
    }

    table, th, td {
      max-width: 100% !important;
      border: 1px solid black;
    }

    th, td {
      height: 0 !important;
      color: black !important;
      padding-left: 2px !important;
      padding-right: 2px !important;
      font-size: 10px !important;

      div {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0 !important;
        width: auto !important;
      }
    }
    .issues-chip {
      color: black !important;
    }
  }
}
</style>
