import Vue from "vue";
import { API } from "aws-amplify";
import { dismissAction, bodyWithAuthHeader, REQUEST_CANCEL_MESSAGE } from "../utils/utils";

const API_PATH = "/coaching/infraction";

export default {

  name: "infractionModule",
  namespaced: true,
  state: {
    currentPageLoadRequest: undefined,
    currentTotalCountLoadRequest: undefined,

    currentPageLoading: false,
    currentPage: {
      infractions: [],
      index: 0,
      size: 25,
      searchString: "",
      sort: [],
    },
    displayInfractions: [],
    totalCount: 0,
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageInfractions(state, infractionsDto) {
      state.currentPage.infractions = [...infractionsDto];
      state.displayInfractions = state.currentPage.infractions;
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    async reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ state, commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          searchString: currentPage.searchString,
          sort: currentPage.sort.join("+"),
        },
      };

      try {
        if (state.currentPageLoadRequest) {
          API.cancel(state.currentPageLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentPageLoadRequest = API.get("core", API_PATH, body);
        const infractionsDto = await state.currentPageLoadRequest;
        commit("updateCurrentPageInfractions", infractionsDto);
      } catch (e) {
        if (API.isCancel(e)) {
          console.log(e);
          return;
        }
        Vue.toasted.error(e, { ...dismissAction });
      }
      commit("endLoadingPage");
    },

    async loadInfractionsForDriver({ commit }, { driverId, queryStringParameters }) {
      commit("");

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters,
      };

      return API.get("core", `${API_PATH}/driver/${driverId}`, body);
    },

    async loadTotalCount({ state, commit }, currentPage) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: currentPage.searchString,
        },
      };

      try {
        if (state.currentTotalCountLoadRequest) {
          API.cancel(state.currentTotalCountLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentTotalCountLoadRequest = API.get("core", `${API_PATH}/count`, body);
        const totalCount = await state.currentTotalCountLoadRequest;
        commit("updateTotalCount", totalCount);
      } catch (e) {
        if (API.isCancel(e)) {
          console.log(e);
          return;
        }
        Vue.toasted.error(e, { ...dismissAction });
      }
    },
  },
};
