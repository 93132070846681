<template>
  <Issues />
</template>

<script>
// @ is an alias to /src
import Issues from "@/components/issues/Issues.vue";

export default {
  name: "IssuesPage",
  routing: {
    localizationKey: "menu.tab.issues",
    icon: "mdi-alert-octagon",
    path: "/issues",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Issues,
  },
};
</script>
