<template>
  <div>
    <div
      v-if="state == 'view' && targetDriverEntry"
      class="cursor-pointer d-flex align-center desl-message-shower"
      @click="onClick()"
    >
      <DriverEntryLabel
        :targetDriverEntry="targetDriverEntry"
        :additionalDataPerDriver="
          additionalDataPerDriver[targetDriverEntry.driver.id] || {}
        "
        :isDuplicate="targetDriverEntry.isWillBeDuplicate"
        class="ml-2"
      />
      <MessageButton
          class="desl-message-button"
          :target="MessagingTargets.driver(targetDriverEntry.driver.id)"
      />
    </div>
    <div
      v-if="state == 'edit'"
      class="d-flex flex-row align-center select-from-routes"
    >
      <DriverEntrySelect
        class="pa-0 ma-0"
        :label="
          targetDriverEntry
            ? $localizationService.localize(
                'drp.entry_select_label.replacement_label'
              )
            : $localizationService.localize(
                'drp.entry_select_label.select_label'
              )
        "
        :additionalDataPerDriver="additionalDataPerDriver"
        :driverEntries="driverEntries"
        @input="onEntrySelect($event)"
        :targetDriverEntry="targetDriverEntry"
        :targetDate="targetDate"
      />
      <v-icon
        class="ml-2"
        v-if="targetDriverEntry"
        color="red"
        @click="cancel()"
        >mdi-close</v-icon
      >
    </div>
  </div>
</template>

<script>
import DriverEntryLabel from "./DriverEntryLabel.vue";
import DriverEntrySelect from "./DriverEntrySelect.vue";
import MessageButton from "../../../MessageButton";
import { MessagingTargets } from "../../../../utils/utils";

export default {
  name: "DriverEntrySelectLabel",

  components: {
    MessageButton,
    DriverEntryLabel,
    DriverEntrySelect,
  },

  props: {
    driverEntries: Array,
    additionalDataPerDriver: Object,
    targetDriverEntry: Object,
    targetDate: String,
  },

  data: () => ({
    MessagingTargets,
    state: "view",
  }),

  watch: {
    targetDriverEntry() {
      if (this.targetDriverEntry) {
        this.state = "view";
      } else {
        this.state = "edit";
      }
    },
  },

  methods: {
    onClick() {
      this.state = "edit";
    },

    cancel() {
      this.state = "view";
    },

    async onEntrySelect(entry) {
      if (entry) {
        this.state = "view";
      }

      this.$emit("onChangeData", entry);
    },
  },

  mounted() {
    if (this.targetDriverEntry) {
      this.state = "view";
    } else {
      this.state = "edit";
    }
  },
};
</script>

<style lang="scss">
.desl-message-button {
  opacity: 0;
}
.desl-message-shower:hover {
  .desl-message-button {
    opacity: 1;
  }
}
</style>
