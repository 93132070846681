<template>
  <v-simple-checkbox
    class="small-checkbox"
    v-model="mnt"
    v-ripple
    @input="onInput()"
  ></v-simple-checkbox>
</template>

<script>
import lodash from "lodash";

export default {
  name: "MntCheckbox",

  props: {
    targetRow: Object,
  },

  data() {
    return {
      mnt: this.targetRow.mnt,
    };
  },

  watch: {
    "targetRow.mnt": function () {
      this.mnt = this.targetRow.mnt;
    },
  },

  methods: {
    onInput: lodash.debounce(function () {
      this.targetRow.mnt = this.mnt;

      // this.targetRow.mnt - getter/setter
      // this.targetRow.mnt may not change
      if (this.mnt !== this.targetRow.mnt) {
        this.mnt = this.targetRow.mnt;
        return;
      }

      this.$emit("onChangeData");
    }, 300),
  },
};
</script>
