import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { VehicleInspectionScheme } from "../model/inspection/vehicle-inspection-scheme.model";
import { VehicleInspectionItemScheme } from "../model/inspection/vehicle-inspection-item-scheme.model";

const API_PATH = "/inspection/scheme";

const locService = Vue.prototype.$localizationService;

export default {
  name: "vehicleInspectionSchemesModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayVehicleInspectionSchemes: [],
    currentPage: {
      vehicleInspectionSchemes: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicleInspectionSchemes(state, vehicleInspectionSchemeDto) {
      state.currentPage.vehicleInspectionSchemes = [...vehicleInspectionSchemeDto];
      state.displayVehicleInspectionSchemes = state.currentPage.vehicleInspectionSchemes;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const vehicleInspectionSchemesDto = await API.get("core", API_PATH, body);
        commit("updateVehicleInspectionSchemes", vehicleInspectionSchemesDto.map(
          (vehicleInspectionScheme) => VehicleInspectionScheme.fromDto(vehicleInspectionScheme),
        ));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSaveVehicleInspectionItemScheme(context, item) {
      const body = { ...await bodyWithAuthHeader(), body: item };

      try {
        const shop = await (item.id ? API.put("core", `${API_PATH}/item/${item.type}`, body)
          : API.post("core", `${API_PATH}/${item.schemeId}/item/${item.type}`, body));
        return VehicleInspectionItemScheme.fromDto(shop);
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async addOrSaveVehicleInspectionScheme(context, vehicleInspectionScheme) {
      const body = { ...await bodyWithAuthHeader(), body: vehicleInspectionScheme };

      try {
        const shop = await (vehicleInspectionScheme.id ? API.put("core", `${API_PATH}/${vehicleInspectionScheme.id}`, body)
          : API.post("core", API_PATH, body));
        const shopDto = VehicleInspectionScheme.fromDto(shop);
        Vue.toasted.success(
          locService.localize(vehicleInspectionScheme.id ? "store.vehicle_inspection_scheme.save_vehicle_inspection_scheme_msg"
            : "store.vehicle_inspection_scheme.add_vehicle_inspection_scheme_msg", [vehicleInspectionScheme.name]),
          {
            duration: 5000,
          },
        );
        return shopDto;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deleteVehicleInspectionScheme(context, vehicleInspectionScheme) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicleInspectionScheme.id}`, body);
        Vue.toasted.success(locService.localize("store.vehicle_inspection_scheme.delete_vehicle_inspection_scheme_msg",
          [vehicleInspectionScheme.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredVehicleInspectionSchemes(context, searchString) {
      if (!searchString) return [];

      try {
        return await API.get("core", API_PATH,
          {
            ...await bodyWithAuthHeader(),
            queryStringParameters: {
              page: 0,
              pageSize: 99999999,
              sort: "name",
              searchString,
            },
          });
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },

    async loadAllVehicleInspectionSchemes() {
      try {
        return await API.get("core", `${API_PATH}`, await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },
  },
};
