import moment from "moment";

export class FuelCard {
    id;

    cardNumber;

    year;

    month;

    get validThru() {
      return moment().year(this.year).month(this.month - 1).format("MM/YY");
    }

    createdAt;

    updatedAt;

    get cardId() {
      return this.year && this.cardNumber ? (this.year % 10)
            + this.cardNumber.substring(this.cardNumber.length - 6, this.cardNumber.length - 1) : "";
    }

    constructor(
      id,
      cardNumber,
      month,
      year,
      createdAt,
      updatedAt,
    ) {
      this.id = id;
      this.cardNumber = cardNumber;
      this.month = month;
      this.year = year;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }

    static fromDto(dto) {
      return new FuelCard(
        dto.id,
        dto.cardNumber,
        dto.month,
        dto.year,
        dto.createdAt,
        dto.updatedAt,
      );
    }
}
