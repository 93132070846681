export class RouteStagingTemplate {
    id;

    name;

    rowsCountToSkipFromBegin;

    rowsCountToSkipFromEnd;

    shouldReadOnlyVisibleRows;

    targetSheetName;

    constructor(
      id,
      name,
      rowsCountToSkipFromBegin,
      rowsCountToSkipFromEnd,
      shouldReadOnlyVisibleRows,
      targetSheetName,
    ) {
      this.id = id;
      this.name = name;
      this.rowsCountToSkipFromBegin = rowsCountToSkipFromBegin;
      this.rowsCountToSkipFromEnd = rowsCountToSkipFromEnd;
      this.shouldReadOnlyVisibleRows = shouldReadOnlyVisibleRows;
      this.targetSheetName = targetSheetName;
    }

    static fromDto(dto) {
      return new RouteStagingTemplate(
        dto.id,
        dto.name,
        dto.rowsCountToSkipFromBegin,
        dto.rowsCountToSkipFromEnd,
        dto.shouldReadOnlyVisibleRows,
        dto.targetSheetName,
      );
    }
}
