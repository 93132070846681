















import {
  Vue, Component,
} from "vue-property-decorator";

const locService = Vue.prototype.$localizationService;

interface DialogVariables {
  attachment: any;
}

@Component
export default class AttachmentDialog extends Vue {
  open = false;

  loading = false;

  header: string = locService.localize("attachment_dialog.header");

  closeText: string = locService.localize("btn.close");

  attachment: any | undefined = {}

  openDialog({ attachment }: DialogVariables): void {
    this.attachment = attachment;
    this.open = true;
  }

  close(): void {
    this.open = false;
  }
}
