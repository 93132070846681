import lodash from "lodash";
import { getSortedData } from "../../utils/table-sorting";

export class Rescuable {
  rescuers;

  constructor(rescuers) {
    this.rescuers = rescuers;
  }

  get allRescuers() {
    return lodash.flatten(this.rescuers.map((it) => [it, ...it.allRescuers]));
  }

  rescuersHasCoincidences(checkOnValidForFilter) {
    return this.allRescuers.some((it) => checkOnValidForFilter(it));
  }

  getSortedRescuers(sort, valueAndSortFunc) {
    const sortedRescuers = sort.sortBy.length
      ? getSortedData(this.rescuers, sort, valueAndSortFunc)
      : lodash.sortBy(this.rescuers, "id");

    return lodash.flatten(
      sortedRescuers.map((it) => [it, ...it.getSortedRescuers(sort, valueAndSortFunc)]),
    );
  }
}
