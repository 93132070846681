import lodash from "lodash";

export default {
  methods: {
    getNotDefaultTableSortItems(sortPropertyName) {
      const current = this[sortPropertyName];
      const def = this.defaultSettings[sortPropertyName];
      const notDefaultItems = [];

      for (
        let i = 0;
        i < lodash.max([current.sortBy.length, def.sortBy.length]);
        i++
      ) {
        if (
          current.sortBy[i] !== def.sortBy[i]
          || current.sortDesc[i] !== def.sortDesc[i]
        ) {
          notDefaultItems.push(current.sortBy[i] || def.sortBy[i]);
        }
      }
      return lodash.union(
        notDefaultItems,
        lodash.xor(current.sortBy, def.sortBy),
      );
    },
  },
};
