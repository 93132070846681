import lodash from "lodash";
import { Driver } from "../driver.model";
import { Vehicle } from "../vehicle.model";
import { DRP_ENTRY_STATUSES, ENTRY_STATUS_NO_RESPONSE } from "../../utils/utils";
import { Device } from "../device.model";

export class DriverEntry {
    id;

    driver;

    status;

    reserve;

    note;

    mntVehicles;

    version;

    plan;

    lastAssignedDevices;

    lastAssignedVehicle;

    constructor(plan, id, driver, status, reserve, note, mntVehicles, version, lastAssignedDevices, lastAssignedVehicle) {
      this.plan = plan;
      this.id = id;
      this.driver = driver;
      this.status = status;
      this.reserve = reserve;
      this.note = note;
      this.mntVehicles = mntVehicles;
      this.version = version;
      this.lastAssignedDevices = lastAssignedDevices;
      this.lastAssignedVehicle = lastAssignedVehicle;
    }

    static fromDto(dto, plan) {
      return new DriverEntry(
        plan,
        dto.id,
        Driver.fromDto(dto.driver),
        DRP_ENTRY_STATUSES[dto.status],
        dto.reserve,
        dto.note,
        dto.mntVehicles.map((vehicle) => Vehicle.fromDto(vehicle)),
        dto.version,
        dto.lastAssignedDevices ? dto.lastAssignedDevices.map((device) => Device.fromDto(device)) : [],
        dto.lastAssignedVehicle ? Vehicle.fromDto(dto.lastAssignedVehicle) : null,
      );
    }

    get isWillBeDuplicate() {
      return this.usesCount > 1;
    }

    get usesCount() {
      return this.plan.driverEntriesUsesCount[this.id];
    }

    get routes() {
      return lodash.uniq([
        ...this.plan.rows,
        ...this.plan.allRescuers,
      ].filter((it) => it.route && it.driverEntry === this)
        .map((it) => it.route));
    }
}

export class DriverEntryDto {
    driverId;

    status;

    reserve;

    note;

    mntVehicleIds;

    version;

    constructor(driverId, status, reserve, note, mntVehicleIds, version) {
      this.driverId = driverId;
      this.status = status;
      this.reserve = reserve;
      this.note = note;
      this.mntVehicleIds = mntVehicleIds;
      this.version = version;
    }

    static toDto(driverEntry) {
      return new DriverEntryDto(
        driverEntry.driver.id,
        driverEntry.status.value,
        driverEntry.reserve,
        driverEntry.note,
        driverEntry.mntVehicles.map((vehicle) => vehicle.id),
        driverEntry.version,
      );
    }

    static createDefault(driverId) {
      return new DriverEntryDto(
        driverId,
        ENTRY_STATUS_NO_RESPONSE.value,
        false,
        "",
        [],
        0,
      );
    }
}
