var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditVehiclesDialog',{ref:"EditVehiclesDialog",on:{"editedVehicle":_vm.setSelectedVehicle}}),_c('div',{staticClass:"content-container"},[(!_vm.selectedAction.isMatchToExistingVehicle)?_c('v-text-field',{staticClass:"mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.vehicle_selection_request.param.name'
        ),"disabled":"","value":_vm.request.autoMatchedVehicle && _vm.selectedAction.isAcceptAutoMatched
          ? _vm.request.autoMatchedVehicle.name
          : ''}}):_vm._e(),(_vm.selectedAction.isMatchToExistingVehicle)?_c('v-autocomplete',{ref:"inputName",staticClass:"mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.vehicle_selection_request.param.name'
        ),"item-text":"text","item-value":"value","items":_vm.matchedVehicleEntries,"search-input":_vm.searchVehicle,"clearable":"","loading":_vm.loadingSearchVehicles,"disabled":!_vm.selectedAction.isMatchToExistingVehicle},on:{"update:searchInput":function($event){_vm.searchVehicle=$event},"update:search-input":function($event){_vm.searchVehicle=$event},"focus":function($event){return _vm.onNameFieldFocused()}},model:{value:(_vm.selectedVehicle),callback:function ($$v) {_vm.selectedVehicle=$$v},expression:"selectedVehicle"}}):_vm._e(),_c('v-text-field',{staticClass:"ml-2 mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.vehicle_selection_request.param.vin'
        ),"rules":[_vm.validateVin],"disabled":!_vm.selectedAction.isMatchToExistingVehicle},model:{value:(_vm.currentVin),callback:function ($$v) {_vm.currentVin=$$v},expression:"currentVin"}}),_c('div',{staticClass:"select-and-icon"},[_c('v-select',{ref:"selectAction",staticClass:"select-action-mobile",attrs:{"label":_vm.$localizationService.localize(
            'mar_component.vehicle_selection_request.param.select_action'
          ),"items":_vm.actionsList,"item-text":"text","item-value":"value","return-object":""},on:{"change":function($event){return _vm.onActionSelect($event)}},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),(_vm.status.type == 'LOADING')?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-progress-circular',{staticClass:"ml-4",attrs:{"size":25,"width":2.5,"color":"primary","indeterminate":""}})],1):(_vm.status.type == 'OK')?_c('v-icon',{staticClass:"ml-4 mr-2",attrs:{"size":"30","color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_c('v-icon',{staticClass:"ml-4 mr-2",attrs:{"size":"30","color":_vm.errorColor}},[_vm._v("mdi-alert-circle-outline")])],1)],1),(_vm.request.rawVin)?_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "mar_component.vehicle_selection_request.raw_data", [_vm.request.rawVin] ))+" ")]):_vm._e(),(!_vm.request.rawVin)?_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "mar_component.vehicle_selection_request.vin_not_found", [_vm.request.rowNumber] ))+" ")]):_vm._e(),(_vm.request.autoMatchedVehicle && !_vm.selectedAction.isAcceptAutoMatched)?_c('p',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "mar_component.vehicle_selection_request.auto_matched_vehicle", [_vm.request.autoMatchedVehicle.vin, _vm.request.autoMatchedVehicle.name] ))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }