import { VehicleActionType } from "@/model/reminder/vehicle-action-type.model";
import { UnitedDuration, UnitedDurationDto } from "@/model/reminder/united-duration.model";
import { localizationService } from "@/utils/utils";
import { Vehicle } from "../vehicle.model";
import { VehicleAction } from "@/model/reminder/vehicle-action.model";

const locService = localizationService;

export class ReminderStatus {
  static ON_WATCH = new ReminderStatus(locService.localize("reminder_status.on_watch"), "ON_WATCH")

  static DUE_SOON = new ReminderStatus(locService.localize("reminder_status.due_soon"), "DUE_SOON")

  static OVERDUE = new ReminderStatus(locService.localize("reminder_status.overdue"), "OVERDUE")

  static statuses = [ReminderStatus.ON_WATCH, ReminderStatus.DUE_SOON, ReminderStatus.OVERDUE];

  constructor(
    public name: string,
    public value: string,
  ) {}

  static fromDto(dto: string) {
    return this.statuses.find((it) => it.value == dto);
  }
}

class VehicleReminderMetadataDto {
  constructor(
      public vehicleId: string,
      public firstFireAt: string | null,
      public firstFireAtMeter: number | null,
  ) { }
}

export class VehicleReminderMetadata {
  constructor(
        public vehicle: Vehicle,
        public firstFireAt: string | null,
        public firstFireAtMeter: number | null,
  ) {
  }

  static fromDto(vehicle: Vehicle, firstFireAt: string, firstFireAtMeter: number) {
    return new VehicleReminderMetadata(
      Vehicle.fromDto(vehicle),
      firstFireAt,
      firstFireAtMeter,
    );
  }

  static toDto(vehicleReminderMetadata: VehicleReminderMetadata) {
    console.log(vehicleReminderMetadata);
    return new VehicleReminderMetadataDto(
      vehicleReminderMetadata.vehicle.id,
      vehicleReminderMetadata.firstFireAt ? vehicleReminderMetadata.firstFireAt : null,
      vehicleReminderMetadata.firstFireAtMeter ? vehicleReminderMetadata.firstFireAtMeter : null,
    );
  }

  static copy(obj: VehicleReminderMetadata) {
    return new VehicleReminderMetadata(
      obj.vehicle,
      obj.firstFireAt,
      obj.firstFireAtMeter,
    );
  }
}

class CreateReminderDto {
  constructor(
        public name: string,
        public vehiclesReminderMetadata: VehicleReminderMetadataDto[],
        public trackedActionTypeId: string,
        public dueSoonAt: UnitedDurationDto | null,
        public repeatEvery: UnitedDurationDto | null,
        public dueSoonAtMeter: number | null,
        public repeatEveryMeter: number | null,
        public comment: string | null,
  ) {}
}

class UpdateReminderDto {
  constructor(
      public name: string,
      public trackedVehicleId: string,
      public firstFireAt: string | null,
      public firstFireAtMeter: number | null,
      public trackedActionTypeId: string,
      public dueSoonAt: UnitedDurationDto | null,
      public repeatEvery: UnitedDurationDto | null,
      public dueSoonAtMeter: number | null,
      public repeatEveryMeter: number | null,
      public comment: string | null,
  ) {}
}

export class ReminderDto {
  constructor(
      public id: string,
      public name: string,
      public trackedVehicle: Vehicle,
      public trackedActionType: VehicleActionType,
      public firstFireAt: string | null,
      public firstFireAtMeter: number | null,
      public dueSoonAt: UnitedDuration,
      public repeatEvery: UnitedDuration,
      public dueSoonAtMeter: number | null,
      public repeatEveryMeter: number | null,
      public comment: string | null,
      public createdAt: string,
      public updatedAt: string,
      public lastActionOnTrackedType: VehicleAction | undefined,
      public createdAtMeterValue: number | null,
      public status: string,
      public mutable: boolean,
  ) {}
}

export class Reminder {
  constructor(
        public id: string,
        public name: string,
        public trackedVehicle: Vehicle,
        public firstFireAt: string | null,
        public firstFireAtMeter: number | null,
        public trackedActionType: VehicleActionType,
        public dueSoonAt: UnitedDuration | null,
        public repeatEvery: UnitedDuration | null,
        public dueSoonAtMeter: number | null,
        public repeatEveryMeter: number | null,
        public comment: string | null,
        public status: ReminderStatus | undefined,
        public createdAt: string,
        public updatedAt: string,
        public lastActionOnTrackedType: VehicleAction | null,
        public createdAtMeterValue: number | null,
        public mutable: boolean,
  ) {
  }

  static createEmpty(): Reminder {
    return new Reminder(
      "",
      "",
      {} as Vehicle,
      "",
      null,
      {} as VehicleActionType,
      {} as UnitedDuration,
      {} as UnitedDuration,
      null,
      null,
      "",
      undefined,
      "",
      "",
      null,
      null,
      true,
    );
  }

  static copy(obj: Reminder): Reminder {
    return new Reminder(
      obj.id,
      obj.name,
      obj.trackedVehicle,
      obj.firstFireAt,
      obj.firstFireAtMeter,
      VehicleActionType.copy(obj.trackedActionType),
      obj.dueSoonAt && Object.keys(obj.dueSoonAt).length > 0 ? UnitedDuration.copy(obj.dueSoonAt) : {} as UnitedDuration,
      obj.repeatEvery && Object.keys(obj.repeatEvery).length > 0 ? UnitedDuration.copy(obj.repeatEvery) : {} as UnitedDuration,
      obj.dueSoonAtMeter,
      obj.repeatEveryMeter,
      obj.comment,
      obj.status,
      obj.createdAt,
      obj.updatedAt,
      obj.lastActionOnTrackedType,
      obj.createdAtMeterValue,
      obj.mutable,
    );
  }

  static fromDto(dto: ReminderDto): Reminder {
    return new Reminder(
      dto.id,
      dto.name,
      Vehicle.fromDto(dto.trackedVehicle),
      dto.firstFireAt ? dto.firstFireAt : null,
      dto.firstFireAtMeter ? dto.firstFireAtMeter : null,
      VehicleActionType.fromDto(dto.trackedActionType),
      dto.dueSoonAt ? UnitedDuration.fromDto(dto.dueSoonAt) : {} as UnitedDuration,
      dto.repeatEvery ? UnitedDuration.fromDto(dto.repeatEvery) : {} as UnitedDuration,
      dto.dueSoonAtMeter ? dto.dueSoonAtMeter : null,
      dto.repeatEveryMeter,
      dto.comment ? dto.comment : null,
      ReminderStatus.fromDto(dto.status),
      dto.createdAt,
      dto.updatedAt,
      dto.lastActionOnTrackedType ? VehicleAction.fromDto(dto.lastActionOnTrackedType) : null,
      dto.createdAtMeterValue,
      dto.mutable,
    );
  }

  static toDto(reminder: Reminder, vehiclesReminderMetadata: VehicleReminderMetadata[]) {
    return new CreateReminderDto(
      reminder.name,
      vehiclesReminderMetadata.map((it) => VehicleReminderMetadata.toDto(it)),
      reminder.trackedActionType.id,
      reminder.dueSoonAt && Object.keys(reminder.dueSoonAt).length > 0 ? UnitedDuration.toDto(reminder.dueSoonAt) : null,
      reminder.repeatEvery && Object.keys(reminder.repeatEvery).length > 0 ? UnitedDuration.toDto(reminder.repeatEvery) : null,
      reminder.dueSoonAtMeter ? reminder.dueSoonAtMeter : null,
      reminder.repeatEveryMeter ? reminder.repeatEveryMeter : null,
      reminder.comment,
    );
  }

  static toUpdateDto(reminder: Reminder) {
    return new UpdateReminderDto(
      reminder.name,
      reminder.trackedVehicle.id,
      reminder.firstFireAt,
      reminder.firstFireAtMeter,
      (reminder.trackedActionType as VehicleActionType).id,
      reminder.dueSoonAt && Object.keys(reminder.dueSoonAt).length > 0 ? UnitedDuration.toDto(reminder.dueSoonAt) : null,
      reminder.repeatEvery && Object.keys(reminder.repeatEvery).length > 0 ? UnitedDuration.toDto(reminder.repeatEvery) : null,
      reminder.dueSoonAtMeter,
      reminder.repeatEveryMeter,
      reminder.comment,
    );
  }
}
