var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{class:{'not-default-page-size': !this.isDefaultSize},attrs:{"headers":_vm.headers,"items":_vm.displayUserInvitations,"loading":_vm.currentPageLoading || !_vm.userInvitationTableInited,"footer-props":_vm.footerProps,"search":_vm.searchString,"options":{
      page: _vm.pageIndex,
      itemsPerPage: _vm.pageSize,
      sortBy: _vm.sort.sortBy,
      sortDesc: _vm.sort.sortDesc,
     },"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event, _vm.searchString)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)}},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.accepted",fn:function(ref){
     var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.accepted ? 'green' : 'orange',"dark":""}},[_vm._v(" "+_vm._s(item.accepted ? _vm.$localizationService.localize( "company_management_page.user_invitations.user_accepted" ) : _vm.$localizationService.localize( "company_management_page.user_invitations.user_pending" ))+" ")])]}},{key:"item.userInfo.name",fn:function(ref){
     var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:{name : !item.userInfo}},[_vm._v(" "+_vm._s(item.userInfo ? item.userInfo.name : _vm.$localizationService.localize( "company_management_page.user_invitations.blank_name"))+" ")]),(item.userInfo)?_c('MessageButton',{attrs:{"target":_vm.MessagingTargets.user(item.userInfo.id)}}):_vm._e()],1)]}},{key:"item.userType",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.USER_TYPES_BY_VALUE[item.userType].text)+" ")]}},{key:"item.createdAt",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
     var item = ref.item;
return [_c('v-icon',{attrs:{"medium":"","color":"red","disabled":item.accepted},on:{"click":function($event){return _vm.openDeleteUserInvitationDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)},[_c('ConfirmationDialog',{ref:"deleteDialog"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }