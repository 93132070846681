<template>
  <v-container fluid>
    <v-row align="start" class="messageTemplates" justify="start">
      <v-col>
        <v-data-table
            :class="{'not-default-page-size': !this.isDefaultSize}"
            :footer-props="footerProps"
            :headers="headers"
            :items="displayMessageTemplates"
            :loading="pageLoading || !tableInited"
            :options="{
              page: pageIndex,
              itemsPerPage: pageSize,
              sortBy: sort.sortBy,
              sortDesc: sort.sortDesc,
             }"
            :server-items-length="totalCount"
            multi-sort
            @update:options="paginate($event)"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("message_template_page.title") }}
                <TableSettings
                    :pDefaultButtonTooltip="
                      $localizationService.localize(
                        'message_template_page.settings_tooltip.apply_default'
                        )
                    "
                    :pIsVisible="tableInited && !isDefaultSettings"
                    :pSaveButtonTooltip="
                      $localizationService.localize(
                        'message_template_page.settings_tooltip.save'
                      )
                    "
                    class="ml-5"
                    @onApplyDefaultSettings="applyDefaultSettings()"
                    @onSaveSettings="saveSettings()"
                />
              </v-toolbar-title>
              <v-spacer/>
              <v-text-field
                  v-model="searchString"
                  :label="$localizationService.localize('message_template_page.search')"
                  class="search-field"
                  hide-details
                  width="200"
                  @input="searchStringDebounceWatch()"
              ></v-text-field>
              <v-btn class="ml-2" color="primary" @click="openEditMessageTemplateDialog()">
                {{ $localizationService.localize("message_template_page.new_btn") }}
              </v-btn>
              <EditMessageTemplateDialog @onAddOrSave="reloadPage"  ref="messageTemplateDialog"/>
              <ConfirmationDialog ref="deleteDialog"/>
            </v-toolbar>
          </template>
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
                :key="header.value"
                :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.text`]="{ item }">
            <div class="text-column">
              {{ item.text }}
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div class="description-column">
              {{ item.description }}
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDateAndTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" medium @click="openEditMessageTemplateDialog(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon color="red" medium @click="openDeleteMessageTemplateDialog(item)"
            >mdi-delete
            </v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import lodash from "lodash";
import tableSortParserMixin from "../../utils/table-sort-parser";
import ConfirmationDialog from "../ConfirmationDialog";
import { momentDateAndTime } from "../../utils/utils";
import TableSettings from "../TableSettings";
import EditMessageTemplateDialog from "./EditMessageTemplateDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "MessageTemplates",

  mixins: [tableSortParserMixin],

  components: {
    EditMessageTemplateDialog,
    ConfirmationDialog,
    TableSettings,
  },

  computed: {
    ...mapState("messageTemplatesModule", [
      "displayMessageTemplates",
      "totalCount",
      "currentPage",
      "pageLoading",
    ]),
    ...mapState("messageTemplatesTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("messageTemplatesTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },

  data: () => ({

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    tableInited: false,

    searchString: "",

    headers: [
      {
        text: locService.localize("message_template_page.header.name"),
        value: "name",
        sortable: true,
        width: "10%",
      },
      {
        text: locService.localize("message_template_page.header.description"),
        value: "description",
        sortable: true,
        width: "30%",
      },
      {
        text: locService.localize("message_template_page.header.text"),
        value: "text",
        sortable: true,
        width: "30%",
      },
      {
        text: locService.localize("message_template_page.header.created_at"),
        value: "createdAt",
        sortable: true,
        width: "10%",
      },
      {
        text: locService.localize("message_template_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
        width: "10%",
      },
      {
        text: locService.localize("message_template_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
        width: "10%",
      },
    ],
  }),

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("messageTemplatesTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("messageTemplatesModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    openEditMessageTemplateDialog(messageTemplate) {
      this.$refs.messageTemplateDialog.openDialog(messageTemplate);
    },

    openDeleteMessageTemplateDialog(messageTemplate) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("message_template_page.dialog.delete.title"),
        text: locService.localize("message_template_page.dialog.delete.text", [
          messageTemplate.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          await this.$store.dispatch("messageTemplatesModule/deleteMessageTemplate", messageTemplate);
          this.reloadPage();
        },
      });
    },

    saveSettings() {
      this.$store.commit("messageTemplatesTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("message_template_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("messageTemplatesTableSettingsModule/applyDefaultSettings");
    },

    reloadPage() {
      this.$store.dispatch("messageTemplatesModule/reloadPage");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/messaging/templates/?${params.toString()}`);
      }
    },

    searchStringDebounceWatch: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("messageTemplatesModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),
  },

};
</script>

<style lang="scss">
.messageTemplates {
  .search-field {
    max-width: 250px;
  }

  .v-data-table-header-mobile {
    display: none;
  }

  .description-column {
    max-width: 30vw;
    word-break: break-all;
  }

  .text-column {
    max-width: 30vw;
    word-break: break-all;
  }
}
</style>
