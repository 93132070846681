import Vue from "vue";
import { API } from "aws-amplify";
import { dismissAction, bodyWithAuthHeader } from "../utils/utils";
import { Device } from "../model/device.model";

const locService = Vue.prototype.$localizationService;

const API_PATH = "/device";

export default {

  name: "devicesModule",
  namespaced: true,
  state: {
    pageLoading: false,
    displayDevices: [],
    currentPage: {
      devices: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateDevices(state, devicesDto) {
      state.currentPage.devices = [...devicesDto];
      state.displayDevices = state.currentPage.devices;
    },
  },
  actions: {

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const devicesDto = await API.get("core", API_PATH, body);

        commit("updateDevices", devicesDto.map((device) => Device.fromDto(device)));
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
      commit("endLoadingPage");
    },

    async deleteDevice({ commit, dispatch, state }, device) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${device.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(locService.localize("store.device.delete_device_msg", [device.name]), {
          duration: 5000,
        });
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async addOrSaveDevice({ commit, dispatch, state }, device) {
      const body = { ...await bodyWithAuthHeader(), body: device };

      try {
        await (device.id ? API.put("core", `${API_PATH}/${device.id}`, body) : API.post("core", API_PATH, body));
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");
        Vue.toasted.success(
          device.id
            ? locService.localize("store.device.save_device_msg", [device.name])
            : locService.localize("store.device.add_device_msg", [device.name]),
          {
            duration: 5000,
          },
        );
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },
  },
};
