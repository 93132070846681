var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.open)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize(_vm.editedFuelCard.id ? "fuel_card_page.dialog.add_and_edit.edit_title" : "fuel_card_page.dialog.add_and_edit.new_title" ))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'fuel_card_page.dialog.add_and_edit.param.card_number'
              ),"disabled":_vm.loading},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.editedFuelCard.cardNumber),callback:function ($$v) {_vm.$set(_vm.editedFuelCard, "cardNumber", $$v)},expression:"editedFuelCard.cardNumber"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":_vm.$localizationService.localize(
                        'fuel_card_page.dialog.add_and_edit.param.expiration_date'
                      ),"disabled":_vm.loading},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}],null,false,1514830465),model:{value:(_vm.expirationDateMenu),callback:function ($$v) {_vm.expirationDateMenu=$$v},expression:"expirationDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","type":"month"},on:{"input":_vm.closeMenu},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.expirationDateMenu = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'fuel_card_page.dialog.add_and_edit.param.card_id'
              ),"disabled":""},model:{value:(_vm.editedFuelCard.cardId),callback:function ($$v) {_vm.$set(_vm.editedFuelCard, "cardId", $$v)},expression:"editedFuelCard.cardId"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.loading || !_vm.editedFuelCard.cardNumber || !_vm.date},on:{"click":_vm.addOrSaveFuelCard}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize(_vm.editedFuelCard.id ? "btn.save" : "btn.add"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }