






















import {
  Vue, Component, Watch, Emit,
} from "vue-property-decorator";

const locService = Vue.prototype.$localizationService;

interface DialogVariables {
  open: boolean;
  loading: boolean;
  header: string;
  text: string;
  cancelText: string;
  submitText: string;
  submitColor: string;
  onSubmit: () => void;
}

@Component
export default class ConfirmationDialog extends Vue {
  open = false;

  loading = false;

  header: string = locService.localize("confirmation_dialog.header");

  text: string = locService.localize("confirmation_dialog.text");

  cancelText: string = locService.localize("btn.cancel");

  submitText: string = locService.localize("btn.submit");

  submitColor = "green";

  confirmed = false;

  onSubmit: () => void = () => {};

  @Watch("open")
  onChangeOpen(): void {
    if (!this.open) {
      this.onCloseDialog();
    }
  }

  @Emit("onCloseDialog")
  onCloseDialog(): boolean {
    return this.confirmed;
  }

  openDialog({
    header,
    text,
    cancelText,
    submitText,
    submitColor,
    onSubmit,
  }: DialogVariables): void {
    this.confirmed = false;
    if (header) {
      this.header = header;
    }
    if (text) {
      this.text = text;
    }
    if (cancelText) {
      this.cancelText = cancelText;
    }
    if (submitText) {
      this.submitText = submitText;
    }
    if (submitColor) {
      this.submitColor = submitColor;
    }
    if (onSubmit) {
      this.onSubmit = onSubmit;
    }
    this.open = true;
  }

  close(): void {
    this.open = false;
  }

  async submit(): Promise<void> {
    this.loading = true;
    this.confirmed = true;
    await this.onSubmit();
    this.loading = false;
    this.open = false;
  }
}
