<template>
  <Drivers />
</template>

<script>
// @ is an alias to /src
import Drivers from "@/components/drivers/Drivers.vue";

export default {
  name: "DriversPage",
  routing: {
    localizationKey: "menu.tab.drivers",
    icon: "mdi-card-account-details-outline",
    path: "/drivers",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Drivers,
  },
};
</script>
