<template>
  <v-autocomplete
    :label=label
    v-model="model"
    :dense="dense"
    :filled="filled"
    :disabled="disabled"
    :search-input.sync="targetSearch"
    item-text="title"
    item-value="id"
    return-object
    :filter="targetFilter"
    :items="targetItems"
    :autofocus="autofocus"
    :loading="targetSearchLoading"
    hide-details
    :multiple=multiple
    :chips=multiple
    deletable-chips
    clear-icon="mdi-delete"
    :clearable=true
    @change="changeItem">
    <template v-slot:selection="{ item }">
      <div :class="{'drp-vehicle-select': !multiple}">
        <v-list-item-content v-if="!multiple && item.model" :class="getVehicleClass(item.model)">
          {{ item && item.model && item.model.name }}
        </v-list-item-content>
        <v-chip v-else-if="multiple">
          {{item.title}}
        </v-chip>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content
        class="vts-target-list-item-content"
        :class="[item.subclass, getSelectedVehicleClass(item.model), ]"
      >
        <div class="item-container">
          <div class="d-flex flex-row">
            <div class="mr-4">
              <v-icon>{{ item.icon }}</v-icon>
            </div>
            <div class="title-container">
              <div class="item-title" :style="[getTitleMaxWidth(item), getTitleFont(item)]">{{ item.title }}</div>
              <div v-if="item.subtitle" class="subtitle-2 text--secondary font-weight-regular">{{ item.subtitle }}</div>
            </div>
          </div>
          <div v-if="item.metamark" class="meta-mark subtitle-2 text--secondary font-weight-regular">
            {{item.metamark }}
          </div>
        </div>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>

import moment from "moment";
import lodash from "lodash";
import Vue from "vue";
import { dateMomentFormat } from "@/utils/utils";
import { ISSUES_SEVERITY_CRITICAL } from "@/utils/ts-utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "VehicleTargetSelection",

  props: {
    targetVehicles: Array,
    label: String,
    targetVehicleGroups: {
      type: Array,
      required: false,
    },
    isRequiredGroups: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    filled: {
      type: Boolean,
      required: false,
      default: false,
    },
    vehiclesUsesCount: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    targetSearchLoading: false,
    targetSearch: "",
    targetSearchMatches: [],
    model: [],
  }),

  watch: {
    targetSearch() {
      if (this.targetSearch) {
        this.loadFilteredTargets();
      }
    },
  },

  computed: {
    targetItems() {
      const model = (this.multiple ? [...this.model] : [this.model]).filter((it) => Object.keys(it).length !== 0);
      return _.sortBy(_.uniqBy([...this.targetSearchMatches, ...model], "id"), "title");
    },
  },

  methods: {

    changeItem() {
      this.$emit("input", this.model);
    },

    loadFilteredTargets: lodash.debounce(async function () {
      this.targetSearchLoading = true;

      const data = await Promise.all([
        this.$store.dispatch(
          "vehiclesModule/searchVehiclesWithMetadata",
          {
            searchString: this.targetSearch,
            includeLastUpdatedOpenedIssue: true,
            useLightweightDto: true,
          },
        ),
        this.isRequiredGroups ? this.$store.dispatch(
          "vehicleGroupsModule/loadFilteredVehicleGroups",
          this.targetSearch,
        ) : [],
      ]);

      await this.setFilteredTargets(
        data[0],
        data[1],
      );
      this.targetSearchLoading = false;
    }, 300),

    async setFilteredTargets(targetVehicles, targetVehicleGroups) {
      if (!this.multiple && (targetVehicles[0] === undefined || targetVehicles[0] === null)) return;
      if (this.multiple && targetVehicles[0] === undefined && targetVehicleGroups[0] === undefined) return;
      const metadata = Object.fromEntries(targetVehicles.map((it) => [it.id, it.metadata]));
      const metadataRequired = targetVehicles.filter((it) => !_.get(it, "metadata.lastOpenedIssueIncluded")).map((it) => it.id);
      if (metadataRequired.length) {
        this.loading = true;
        Object.assign(
          metadata,
          await this.$store.dispatch(
            "vehiclesModule/loadMetadata",
            { ids: metadataRequired, includeLastUpdatedOpenedIssue: true },
          ),
        );
        this.loading = false;
      }

      this.targetSearchMatches = [
        ...(targetVehicles.filter((i) => i !== undefined) || []).map((it) => {
          const issue = (metadata[it.id] || {}).lastOpenedIssue;

          let subclass = "";

          if (issue) {
            subclass = issue.severity == ISSUES_SEVERITY_CRITICAL.value
              ? "coloring-critical"
              : "coloring-warning";
          }

          return {
            id: it.id,
            isVehicle: true,
            model: it,
            title: it.name || it.vin,
            subtitle: issue ? issue.title : undefined,
            subclass,
            icon: issue ? "mdi-truck-alert" : "mdi-truck",
            metamark: issue ? moment(issue.updatedAt).format(dateMomentFormat) : undefined,
          };
        }),
        ...(targetVehicleGroups.filter((i) => i !== undefined) || []).map((it) => ({
          id: it.id,
          isGroup: true,
          model: it,
          title: it.name,
          subtitle: locService.localize("vehicle_inspection_assign_dialog.vehicle_population", [(it.vehicles || []).length ?? it.vehiclesCount]),
          icon: "mdi-warehouse",
        })),
      ];
    },

    getSelectedVehicleClass(vehicle) {
      return this.vehiclesUsesCount && this.vehiclesUsesCount[vehicle && vehicle.id] > 1
        ? "orange--text"
        : "";
    },

    getVehicleClass(vehicle) {
      return this.vehiclesUsesCount && this.vehiclesUsesCount[vehicle && vehicle.id] > 0
        ? "orange--text"
        : "";
    },

    getTitleMaxWidth(item) {
      return {
        maxWidth: item.model && item.model.openIssuesCount ? "150px" : "300px",
      };
    },

    getTitleFont(item) {
      return item.model ? {} : {
        fontSize: "0.8125rem",
        fontWeight: "500",
        lineHeight: "1rem",
        marginLeft: "-16px",
      };
    },

    targetFilter() {
      return true;
    },
  },

  async mounted() {
    await this.setFilteredTargets(this.targetVehicles || [], this.targetVehicleGroups || []);
    if (!this.multiple) {
      this.model = this.targetSearchMatches.length > 0 ? { ...this.targetSearchMatches[0] } : {};
    } else {
      this.model = this.targetSearchMatches;
      this.$emit("input", this.model);
    }
  },
};
</script>

<style lang="scss">
.vts-target-list-item-content {
  margin: 0px -16px;
  padding: 12px 16px;
  height: calc(40px + 12px + 12px) !important;

  &.coloring-critical {
    background-color: rgba(255, 0, 0, 0.1);
  }

  &.coloring-warning {
    background-color: rgba(251, 140, 0, 0.15);
  }

  .item-container {
    display: flex;
    justify-content: space-between;
  }

  .title-container {
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  .item-title {
    color: var(--v-themeTextColor-base) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meta-mark {
    margin-left: 8px;
    font-size: 0.8rem !important;
  }
  .drp-vehicle-select {
    .v-list-item__content {
      display: block !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-wrap: normal;
      text-decoration: line-through;
    }
  }
}
</style>
