import Vue from "vue";
import { API } from "aws-amplify";
import { dismissAction, bodyWithAuthHeader, REQUEST_CANCEL_MESSAGE } from "../utils/utils";

const API_PATH = "/coaching";

const locService = Vue.prototype.$localizationService;

export default {

  name: "coachingModule",
  namespaced: true,
  state: {
    currentPageLoadRequest: undefined,
    currentTotalCountLoadRequest: undefined,

    pageLoading: false,
    displayCoaching: [],
    currentPage: {
      coaching: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
    processingCoaching: false,
    autoCoachingPerDriver: {},
    autoCoachingPerDriverLoading: {},
    previousAutoCoachingPerDriver: {},
    previousAutoCoachingPerDriverLoading: {},
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
      state.displayCoaching = [];
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, { totalCount, targetStatus }) {
      if (state.currentPage.targetStatus == targetStatus) {
        state.totalCount = totalCount;
      }
    },

    updateCoaching(state, { coachingDto, targetStatus }) {
      if (state.currentPage.targetStatus == targetStatus) {
        state.currentPage.coaching = [...coachingDto];
        state.displayCoaching = state.currentPage.coaching;
      }
    },

    beginProcessingCoaching(state) {
      state.processingCoaching = true;
    },

    endProcessingCoaching(state) {
      state.processingCoaching = false;
    },

    beginLoadAutoCoaching(state, driverId) {
      Vue.set(state.autoCoachingPerDriverLoading, driverId, true);
    },

    endLoadAutoCoaching(state, { coaching, driverId }) {
      Vue.set(state.autoCoachingPerDriver, driverId, coaching);
      Vue.set(state.autoCoachingPerDriverLoading, driverId, false);
    },

    beginLoadPreviousAutoCoaching(state, driverId) {
      Vue.set(state.previousAutoCoachingPerDriverLoading, driverId, true);
    },

    endLoadPreviousAutoCoaching(state, { coaching, driverId }) {
      Vue.set(state.previousAutoCoachingPerDriver, driverId, coaching);
      Vue.set(state.previousAutoCoachingPerDriverLoading, driverId, false);
    },
  },
  actions: {

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadTotalCount({ state, commit }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      if (state.currentPage.targetStatus) {
        body.queryStringParameters.targetStatus = state.currentPage.targetStatus;
      }

      try {
        if (state.currentTotalCountLoadRequest) {
          API.cancel(state.currentTotalCountLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentTotalCountLoadRequest = API.get("core", `${API_PATH}/count`, body);
        const totalCount = await state.currentTotalCountLoadRequest;
        commit("updateTotalCount", { totalCount, targetStatus: state.currentPage.targetStatus });
      } catch (e) {
        if (API.isCancel(e)) {
          console.log(e);
          return;
        }
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async loadPage({ state, commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      if (currentPage.targetStatus) {
        body.queryStringParameters.targetStatus = currentPage.targetStatus;
      }

      try {
        if (state.currentPageLoadRequest) {
          API.cancel(state.currentPageLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentPageLoadRequest = API.get("core", API_PATH, body);
        const coachingDto = await state.currentPageLoadRequest;
        commit("updateCoaching", { coachingDto, targetStatus: currentPage.targetStatus });
      } catch (e) {
        if (API.isCancel(e)) {
          console.log(e);
          return;
        }
        Vue.toasted.error(e, { ...dismissAction });
      }
      commit("endLoadingPage");
    },

    async createCoaching({ commit, dispatch, state }, coaching) {
      commit("beginProcessingCoaching");

      const body = { ...await bodyWithAuthHeader(), body: coaching };

      try {
        const result = (await API.post("core", API_PATH, body));
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");
        return result;
      } catch (e) {
        throw e.response.data.message;
      } finally {
        commit("endProcessingCoaching");
      }
    },

    async cancelCoaching({ dispatch, commit }, coaching) {
      commit("beginProcessingCoaching");

      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${coaching.id}`, body);
        dispatch("reloadPage");
        return locService.localize("store.coaching.cancel_coaching_msg", [coaching.name]);
      } catch (e) {
        throw e.response.data.message;
      } finally {
        commit("endProcessingCoaching");
      }
    },

    async resolveCoaching({ dispatch, commit }, { coaching, data }) {
      commit("beginProcessingCoaching");

      const body = {
        ...(await bodyWithAuthHeader()),

        body: data,
      };

      try {
        await API.post("core", `${API_PATH}/${coaching.id}/resolve`, body);
        dispatch("reloadPage");
        dispatch("loadAutoCoaching", coaching.targetDriver.id);
        dispatch("loadPreviousAutoCoaching", coaching.targetDriver.id);
      } catch (e) {
        throw e.response.data.message;
      } finally {
        commit("endProcessingCoaching");
      }
    },

    async loadById(context, coachingId) {
      const body = await bodyWithAuthHeader();

      try {
        return await API.get("core", `${API_PATH}/${coachingId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, {
          duration: 5000,
        });
      }
      return undefined;
    },

    async loadPreviousByCurrentId(context, coachingId) {
      const body = await bodyWithAuthHeader();

      try {
        // TODO: Refactor BackEnd!
        const previousCoaching = await API.get("core", `${API_PATH}/${coachingId}/previous_resolved`, body);
        return previousCoaching || undefined;
      } catch (e) {
        Vue.toasted.error(e.response.data.message, {
          duration: 5000,
        });
      }
      return undefined;
    },

    async loadAutoCoachingIfNeeded({ dispatch, state }, driverId) {
      if (state.autoCoachingPerDriverLoading[driverId]) return;

      dispatch("loadAutoCoaching", driverId);
    },

    async loadAutoCoaching({ commit }, driverId) {
      commit("beginLoadAutoCoaching", driverId);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: 0,
          pageSize: 1,
          sort: "-createdAt",
          targetDriverId: driverId,
          targetStatus: "NEW",
          fullDto: true,
        },
      };

      try {
        commit("endLoadAutoCoaching", { coaching: (await API.get("core", `${API_PATH}`, body))[0], driverId });
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async loadPreviousAutoCoachingIfNeeded({ dispatch, state }, driverId) {
      if (state.previousAutoCoachingPerDriverLoading[driverId]) return;

      dispatch("loadPreviousAutoCoaching", driverId);
    },

    async loadPreviousAutoCoaching({ commit }, driverId) {
      commit("beginLoadPreviousAutoCoaching", driverId);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: 0,
          pageSize: 1,
          sort: "-resolvedAt",
          targetDriverId: driverId,
          targetStatus: "RESOLVED",
          fullDto: true,
        },
      };

      try {
        commit("endLoadPreviousAutoCoaching", { coaching: (await API.get("core", `${API_PATH}`, body))[0], driverId });
      } catch (e) {
        throw e.response.data.message;
      }
    },
  },
};
