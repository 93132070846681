import lodash from "lodash";
import { getLocalStorageData, setLocalStorageData } from "../../utils/utils";

export default {

  name: "drpDriversTableSettingsModule",
  namespaced: true,
  state: {
    tableSettingsStorageKey: "drp.drivers.table.settings",

    sortPropertyName: "driversSort",

    driversSort: {
      sortBy: [],
      sortDesc: [],
    },

    defaultSettings: {
      driversSort: {
        sortBy: [],
        sortDesc: [],
      },
    },
  },

  mutations: {
    changeSettings(state, data) {
      state.driversSort = data.driversSort;
    },

    saveSettings(state) {
      const data = {
        driversSort: state.driversSort,
      };
      setLocalStorageData(state.tableSettingsStorageKey, JSON.stringify(data));
      state.defaultSettings = data;
    },

    applyDefaultSettings(state) {
      state.driversSort = { ...state.defaultSettings.driversSort };
    },

    applyQuerySettings(state, query) {
      if (query.driversSort) {
        state.driversSort = JSON.parse(query.driversSort);
      }
    },
  },

  actions: {
    loadDefaultSettingsFromStorage({ state }) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },
  },

  getters: {
    isDefaultSettings: (state, getters) => getters.isDefaultSort,
    isDefaultSort: (state) => lodash.isEqual(state.driversSort, state.defaultSettings.driversSort),
  },
};
