<template>
  <v-container fluid>
    <DriverCoachingWidget
      class="mb-3"
      :coaching="coaching"
      :coachingLoading="coachingLoading"
      :previousCoaching="previousCoaching"
      :previousCoachingLoading="previousCoachingLoading"
    />
    <ResolvedCoachingInfractionsTable
      :coaching="coaching"
      :coachingLoading="coachingLoading"
    />
  </v-container>
</template>

<script>
import DriverCoachingWidget from "../../components/coaching/DriverCoachingWidget.vue";
import ResolvedCoachingInfractionsTable from "../../components/coaching/ResolvedCoachingInfractionsTable.vue";

export default {
  name: "SpecificCoachingPage",

  routing: {
    path: "/coaching/:coachingId",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    DriverCoachingWidget,
    ResolvedCoachingInfractionsTable,
  },

  data: () => ({
    coaching: undefined,
    coachingLoading: false,
    previousCoaching: undefined,
    previousCoachingLoading: false,
  }),

  computed: {
    coachingId() {
      return this.$route.params.coachingId;
    },
  },

  methods: {
    async loadCoaching() {
      this.coachingLoading = true;
      this.coaching = await this.$store.dispatch(
        "coachingModule/loadById",
        this.coachingId,
      );
      this.coachingLoading = false;
    },

    async loadPreviousCoaching() {
      this.previousCoachingLoading = true;
      this.previousCoaching = await this.$store.dispatch(
        "coachingModule/loadPreviousByCurrentId",
        this.coachingId,
      );
      this.previousCoachingLoading = false;
    },
  },

  mounted() {
    this.loadCoaching();
    this.loadPreviousCoaching();
  },
};
</script>

<style></style>
