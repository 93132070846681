import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { THEME } from "../app-theme";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    ...THEME.vuetifyTheme,
    themes: {
      dark: {
        themeTextColor: "#ffffff",
        grafanaOverlayColor: "#000000",
        tableToolbarBackground: "#272727",
        greyColor: "#a2a2a2",
        greyColor2: "#7e7d7d",
        lightGreyColor: "#616161",
        cardColor: "#1E1E1E",
        exceptionColor: "#f44336",
      },
      light: {
        secondary: "#cce0fc",
        themeTextColor: "#000000",
        grafanaOverlayColor: "#ffffff",
        greyColor: "#a2a2a2",
        greyColor2: "#7e7d7d",
        lightGreyColor: "#eeeeee",
        cardColor: "#FFFFFF",
        exceptionColor: "#f44336",
      },
    },
    options: {
      customProperties: true,
    },
  },
});
