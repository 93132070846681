<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <!--show-select-->
    <v-data-table
      class="driver-infractions-table"
      :headers="headers"
      :items="visibleCriteria"
      :loading="loading"
      multi-sort
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{
              $localizationService.localize(
                "coaching_page.driver_infractions_table.title"
              )
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="search-field pt-0"
            :label="
              $localizationService.localize(
                'coaching_page.driver_infractions_table.search'
              )
            "
            hide-details
            width="200"
            v-model="searchString"
          >
          </v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`header.data-table-select`]="{}">
        <v-simple-checkbox
          v-if="!allSelectLoading"
          class="small-checkbox flex-grow-0"
          :value="headerRowState == 'selected'"
          :indeterminate="headerRowState == 'indeterminate'"
          @input="onAllCriteriaSelectionChange($event)"
          v-ripple
        ></v-simple-checkbox>
        <v-progress-circular
          v-else
          color="primary"
          :size="25"
          :width="3"
          indeterminate
        ></v-progress-circular>
      </template>

      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-simple-checkbox
          v-if="!criteriaSelectLoading[item.id]"
          class="small-checkbox flex-grow-0"
          :value="getCriteriaRowState(item) == 'selected'"
          :indeterminate="getCriteriaRowState(item) == 'indeterminate'"
          @input="onCriteriaSelectionChange($event, item)"
          v-ripple
        ></v-simple-checkbox>
        <v-progress-circular
          v-else
          color="primary"
          :size="25"
          :width="3"
          indeterminate
        ></v-progress-circular>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.name }}</h4>
      </template>

      <template v-slot:[`item.lastInfractionsDays`]="{ item }">
        <DriverInfractionsDots
          class="driver-infractions-dots"
          :infractions="dotsInfractionsByCriteria[item.id]"
        />
      </template>

      <template v-slot:[`item.lastInfraction`]="{ item }">
        {{ getInfractionFormattedDate(lastInfractionsByCriteria[item.id]) }}
      </template>

      <template v-slot:[`item.totalCount`]="{ item }">
        {{ infractionsTotalCountByCriteria[item.id] || "-" }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <DriverInfractionsSubTable
            :targetDriverId="targetDriverId"
            :targetCriteriaId="item.id"
            @infractionSelectionChanged="onInfractionSelectionChanged($event)"
            :selectedInfractions="selectedInfractions"
            :totalCount="infractionsTotalCountByCriteria[item.id]"
            :searchString="searchString"
            :ref="`sub-table-${item.id}`"
          />
        </td>
      </template>
    </v-data-table>
    <SomeDriverInfractionsSelectionNoteDialog
      ref="someDriverInfractionsSelectionNoteDialog"
    />
    <CreateCoachingDialog
      ref="createCoachingDialog"
      @newCoachingCreated="onNewCoachingCreated($event)"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { API } from "aws-amplify";
import moment from "moment";
import debounce from "lodash/debounce";
import DriverInfractionsSubTable from "./DriverInfractionsSubTable.vue";
import DriverInfractionsDots from "./DriverInfractionsDots.vue";
import SomeDriverInfractionsSelectionNoteDialog from "./SomeDriverInfractionsSelectionNoteDialog.vue";
import CreateCoachingDialog from "./CreateCoachingDialog.vue";
import {
  bodyWithAuthHeader,
  dismissAction,
  dateMomentFormat,
  defaultDateFormat,
} from "../../utils/utils";

const groupSelectionNoteKey = "note.group_selection";

const locService = Vue.prototype.$localizationService;

export default {
  props: {
    driver: Object,
  },

  data: () => ({
    allSelectLoading: false,

    criteriaSelectLoading: {},

    searchString: "",
    selectedInfractions: [],

    infractionsTotalCountByCriteria: {},
    newInfractionsTotalCountByCriteria: {},
    lastInfractionsByCriteria: {},
    dotsInfractionsByCriteria: {},
  }),

  components: {
    DriverInfractionsSubTable,
    SomeDriverInfractionsSelectionNoteDialog,
    DriverInfractionsDots,
    CreateCoachingDialog,
  },

  computed: {
    ...mapState("coreModule", ["drawer"]),
    ...mapState("criteriaModule", [
      "allCriteria",
      "allCriteriaLoaded",
      "allCriteriaLoading",
    ]),

    loading() {
      return (
        this.allCriteriaLoading
        || !Object.keys(this.infractionsTotalCountByCriteria).length
        || !Object.keys(this.newInfractionsTotalCountByCriteria).length
        || !Object.keys(this.lastInfractionsByCriteria).length
        || !Object.keys(this.dotsInfractionsByCriteria).length
        || !this.driver
      );
    },

    visibleCriteria() {
      return this.allCriteria.filter(
        (it) => !this.searchString || this.infractionsTotalCountByCriteria[it.id],
      );
    },

    headers: () => [
      {
        text: locService.localize(
          "coaching_page.driver_infractions_table.header.criteria",
        ),
        value: "name",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_table.header.last_infractions_in_2_weeks",
        ),
        value: "lastInfractionsDays",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_table.header.last_infraction",
        ),
        value: "lastInfraction",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_table.header.total_count",
        ),
        value: "totalCount",
        sortable: false,
        align: "start",
      },
      { text: "", value: "data-table-expand" },
    ],

    targetDriverId() {
      return this.$route.params.driverId;
    },

    headerRowState() {
      if (!this.selectedInfractions.length) return undefined;

      const newInfractionsTotalCount = Object.values(
        this.newInfractionsTotalCountByCriteria,
      ).reduce((a, b) => a + b);

      if (
        this.selectedInfractions.filter((it) => it.status == "NEW").length
        == newInfractionsTotalCount
      ) {
        return locService.localize(
          "coaching_page.driver_infractions_table.header_row_state.selected",
        );
      }

      return locService.localize(
        "coaching_page.driver_infractions_table.header_row_state.indeterminate",
      );
    },
  },

  watch: {
    async allCriteriaLoaded() {
      this.calcInfractionsTotalCountByCriteria();
      this.calcNewInfractionsTotalCountByCriteria();
      this.loadLastInfractionsByCriteria();
      this.loadDotsInfractionsByCriteria();
    },
    searchString() {
      this.searchStringDebounceWatch();
    },
  },

  methods: {
    async openCreateCoachingDialog() {
      this.$refs.createCoachingDialog.openForNewCoaching(this.driver, [
        ...this.selectedInfractions,
      ]);
    },

    onNewCoachingCreated() {
      this.loadDotsInfractionsByCriteria();

      Object.keys(this.$refs)
        .filter((it) => it.startsWith("sub-table-"))
        .map((it) => this.$refs[it].reloadPage());
    },

    async loadDotsInfractionsByCriteria() {
      this.dotsInfractionsByCriteria = {};

      const now = moment();

      const body = {
        ...(await bodyWithAuthHeader()),
        body: this.allCriteria.map((it) => ({
          targetDriverId: this.targetDriverId,
          targetCriteriaId: it.id,
          fromDate: now
            .clone()
            .subtract({ days: 13 })
            .format(defaultDateFormat),
          toDate: now.clone().format(defaultDateFormat),
          searchString: this.searchString,
        })),
      };

      this.dotsInfractionsByCriteria = Object.fromEntries(
        (await API.post("core", "/coaching/infraction/get_batch", body)).map(
          (it, index) => [this.allCriteria[index].id, it],
        ),
      );
    },

    getInfractionFormattedDate(infraction) {
      if (!infraction) return "-";

      const dateCircumstance = infraction.circumstances.find(
        (it) => it.type == "DateCircumstance",
      );

      return dateCircumstance
        ? moment(dateCircumstance.date).format(dateMomentFormat)
        : locService.localize(
          "coaching_page.driver_infractions_table.last_infraction_has_no_date",
        );
    },

    async loadLastInfractionsByCriteria() {
      this.lastInfractionsByCriteria = {};

      const body = {
        ...(await bodyWithAuthHeader()),
        body: this.allCriteria.map((it) => ({
          searchString: this.searchString,
          targetDriverId: this.targetDriverId,
          targetCriteriaId: it.id,
          page: 0,
          pageSize: 1,
          sort: "-date",
        })),
      };

      this.lastInfractionsByCriteria = Object.fromEntries(
        (await API.post("core", "/coaching/infraction/get_batch", body)).map(
          (it, index) => [this.allCriteria[index].id, it[0]],
        ),
      );
    },

    getCriteriaRowState(criteria) {
      const selectedInfractions = this.selectedInfractions.filter(
        (it) => it.criteria.id == criteria.id,
      );

      if (!selectedInfractions.length) return undefined;

      if (
        selectedInfractions.filter((it) => it.status == "NEW").length
        == this.newInfractionsTotalCountByCriteria[criteria.id]
      ) {
        return locService.localize(
          "coaching_page.driver_infractions_table.criteria_row_state.selected",
        );
      }

      return selectedInfractions.length > 0
        ? locService.localize(
          "coaching_page.driver_infractions_table.criteria_row_state.indeterminate",
        )
        : undefined;
    },

    async calcInfractionsTotalCountByCriteria() {
      this.infractionsTotalCountByCriteria = {};

      const body = {
        ...(await bodyWithAuthHeader()),
        body: this.allCriteria.map((it) => ({
          targetDriverId: this.targetDriverId,
          targetCriteriaId: it.id,
          searchString: this.searchString,
        })),
      };

      try {
        this.infractionsTotalCountByCriteria = Object.fromEntries(
          (
            await API.post("core", "/coaching/infraction/count_batch", body)
          ).map((it, index) => [this.allCriteria[index].id, it]),
        );
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async calcNewInfractionsTotalCountByCriteria() {
      this.newInfractionsTotalCountByCriteria = {};

      const body = {
        ...(await bodyWithAuthHeader()),
        body: this.allCriteria.map((it) => ({
          targetDriverId: this.targetDriverId,
          targetCriteriaId: it.id,
          targetStatus: "NEW",
        })),
      };

      try {
        this.newInfractionsTotalCountByCriteria = Object.fromEntries(
          (
            await API.post("core", "/coaching/infraction/count_batch", body)
          ).map((it, index) => [this.allCriteria[index].id, it]),
        );
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    onInfractionSelectionChanged({ infraction, value }) {
      if (value) {
        this.selectedInfractions.push(infraction);
      } else {
        this.selectedInfractions.splice(
          this.selectedInfractions.findIndex((it) => it.id == infraction.id),
          1,
        );
      }
    },

    async onCriteriaSelectionChange(newVal, item) {
      if (
        newVal
        && this.getCriteriaRowState(item)
          == locService.localize(
            "coaching_page.driver_infractions_table.criteria_row_state.indeterminate",
          )
      ) {
        newVal = false;
      }

      if (newVal) {
        if (!localStorage.getItem(groupSelectionNoteKey)) {
          this.$refs.someDriverInfractionsSelectionNoteDialog.openDialog();
          localStorage.setItem(groupSelectionNoteKey, "showed");
        }

        this.selectNewInfractions(item.id);
      } else {
        this.selectedInfractions = this.selectedInfractions.filter(
          (it) => it.criteria.id != item.id,
        );
      }
    },

    async onAllCriteriaSelectionChange(newVal) {
      if (
        newVal
        && this.headerRowState
          == locService.localize(
            "coaching_page.driver_infractions_table.header_row_state.indeterminate",
          )
      ) {
        newVal = false;
      }

      if (newVal) {
        if (!localStorage.getItem(groupSelectionNoteKey)) {
          this.$refs.someDriverInfractionsSelectionNoteDialog.openDialog();
          localStorage.setItem(groupSelectionNoteKey, "showed");
        }

        this.selectNewInfractions();
      } else {
        this.selectedInfractions = [];
      }
    },

    async selectNewInfractions(targetCriteriaId) {
      const body = {
        ...(await bodyWithAuthHeader()),
        queryStringParameters: {
          targetStatus: "NEW",
          targetDriverId: this.targetDriverId,
        },
      };

      if (targetCriteriaId) {
        body.queryStringParameters.targetCriteriaId = targetCriteriaId;
        Vue.set(this.criteriaSelectLoading, targetCriteriaId, true);
      } else {
        this.allSelectLoading = true;
        this.allCriteria.forEach((criteria) => Vue.set(this.criteriaSelectLoading, criteria.id, true));
      }

      try {
        const infractions = await API.get("core", "/coaching/infraction", body);

        infractions.forEach((infraction) => {
          if (!this.selectedInfractions.find((it) => it.id == infraction.id)) {
            this.selectedInfractions.push(infraction);
          }
        });
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      } finally {
        if (targetCriteriaId) {
          Vue.set(this.criteriaSelectLoading, targetCriteriaId, false);
        } else {
          this.allSelectLoading = false;
          this.allCriteria.forEach((criteria) => Vue.set(this.criteriaSelectLoading, criteria.id, false));
        }
      }
    },
  },

  async mounted() {
    this.$store.dispatch("criteriaModule/loadAllCriteriaIfNeeded");

    if (this.allCriteriaLoaded) {
      this.calcInfractionsTotalCountByCriteria();
      this.calcNewInfractionsTotalCountByCriteria();
      this.loadLastInfractionsByCriteria();
      this.loadDotsInfractionsByCriteria();
    }

    this.searchStringDebounceWatch = debounce(() => {
      this.calcInfractionsTotalCountByCriteria();
      this.loadLastInfractionsByCriteria();
      this.loadDotsInfractionsByCriteria();
    }, 600);
  },
};
</script>

<style scoped lang="scss">
.small-checkbox {
  max-width: fit-content;

  i {
    font-size: 20px !important;
  }

  .v-label {
    font-size: 14px;
  }

  .v-input--selection-controls__ripple {
    height: 28px;
    width: 28px;
    left: -9px;
    top: -9px;
  }
}
.search-field {
  max-width: 250px;
}
.driver-infractions-dots {
  min-width: 218px;
}
</style>
