import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { PhoneAgent } from "../model/phone-agent.model";

const API_PATH = "/messaging/phone-agents";

const locService = Vue.prototype.$localizationService;

export default {
  name: "phoneAgentsModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayPhoneAgents: [],
    currentPage: {
      phoneAgents: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
    availablePhoneAgents: [],
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updatePhoneAgents(state, phoneAgent) {
      state.currentPage.phoneAgents = [...phoneAgent];
      state.displayPhoneAgents = state.currentPage.phoneAgents;
    },

    updateAvailablePhoneAgents(state, phoneAgent) {
      state.availablePhoneAgents = [...phoneAgent];
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const phoneAgentsDto = await API.get("core", API_PATH, body);
        commit("updatePhoneAgents", phoneAgentsDto.map((phoneAgent) => PhoneAgent.fromDto(phoneAgent)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadAvailablePhoneAgents({ commit }, userId) {
      const body = {
        ...await bodyWithAuthHeader(),
      };

      if (userId) {
        body.queryStringParameters = {
          userId,
        };
      }

      try {
        const availablePhoneAgents = await API.get("core", `${API_PATH}/available-phone-agents`, body);
        if (!userId) {
          commit("updateAvailablePhoneAgents", availablePhoneAgents.map((phoneAgent) => PhoneAgent.fromDto(phoneAgent)));
        }
        return availablePhoneAgents;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSavePhoneAgent(context, phoneAgent) {
      const body = { ...await bodyWithAuthHeader(), body: PhoneAgent.toCreateDto(phoneAgent) };

      try {
        await (phoneAgent.id ? API.put("core", `${API_PATH}/${phoneAgent.id}`, body)
          : API.post("core", API_PATH, body));
        Vue.toasted.success(
          locService.localize(phoneAgent.id ? "store.phone_agent.save_phone_agent_msg"
            : "store.phone_agent.add_phone_agent_msg", [phoneAgent.name]),
          {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deletePhoneAgent(context, phoneAgent) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${phoneAgent.id}`, body);
        Vue.toasted.success(locService.localize("store.phone_agent.delete_phone_agent_msg",
          [phoneAgent.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },
  },
};
