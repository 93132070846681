<!--TODO: AFFECT -> ADD-->
<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{
          $localizationService.localize(
            "coaching_page.some_driver_infractions_selection_note_dialog.title"
          )
        }}
      </v-card-title>
      <v-card-text>
        {{
          $localizationService.localize(
            "coaching_page.some_driver_infractions_selection_note_dialog.text"
          )
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">
          {{ $localizationService.localize("btn.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SomeDriverInfractionsSelectionNoteDialog",

  data() {
    return {
      open: false,
    };
  },

  methods: {
    openDialog() {
      this.open = true;
    },

    close() {
      this.open = false;
    },
  },
};
</script>
