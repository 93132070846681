import Vue from "vue";
import { API } from "aws-amplify";
import { dismissAction, bodyWithAuthHeader } from "../utils/utils";
import { Scan } from "../model/scan.model";

const API_PATH = "/coaching/scan";

export default {

  name: "scanModule",
  namespaced: true,
  state: {
    processingScan: false,
    currentPageLoading: false,
    displayScansStatusUpdateInterval: undefined,
    currentPage: {
      scans: [],
      index: 0,
      size: 25,
    },
    displayScans: [],
    totalCount: 0,
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageScans(state, scansDto) {
      state.currentPage.scans = [...scansDto];
      state.displayScans = state.currentPage.scans;
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    beginProcessingScan(state) {
      state.processingScan = true;
    },

    endProcessingScan(state) {
      state.processingScan = false;
    },

    updateDisplayScans(state, scans) {
      state.displayScans = scans;
    },
  },
  actions: {

    async reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ state, commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");

      const mainBody = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        const scansDto = await API.get("core", API_PATH, mainBody);
        commit("updateCurrentPageScans", scansDto.map(Scan.fromDto));
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
      commit("endLoadingPage");

      if (!state.displayScansStatusUpdateInterval) {
        state.displayScansStatusUpdateInterval = setInterval(async () => {
          const targetScans = state.displayScans.filter((it) => it.isInProcess());

          if (!targetScans.length) return;

          const body = await bodyWithAuthHeader();

          body.body = targetScans.map((it) => it.id);

          try {
            const updatedScans = await API.post("core", `${API_PATH}/get_by_ids`, body);

            const scans = [...state.displayScans];

            scans.forEach((currentScan, index) => {
              const updatedScan = updatedScans.find((it) => it.id == currentScan.id);

              if (updatedScan) {
                scans[index] = Scan.fromDto(updatedScan);
              }
            });

            commit("updateDisplayScans", scans);
          } catch (e) {
            Vue.toasted.error(e);
          }
        }, 7500);
      }
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async requestScan({ commit, dispatch, state }, request) {
      const body = { ...await bodyWithAuthHeader(), body: request };
      commit("beginProcessingScan");
      try {
        const result = await API.post("core", API_PATH, body);
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");
        return result;
      } catch (e) {
        throw e.response.data.message;
      } finally {
        commit("endProcessingScan");
      }
    },
  },
};
