<template>
  <v-container fluid class="pa-0">
    <v-data-table
      :headers="headers"
      :items="displayUserInvitations"
      :loading="currentPageLoading || !userInvitationTableInited"
      @update:options="paginate($event, searchString)"
      :footer-props="footerProps"
      :class="{'not-default-page-size': !this.isDefaultSize}"
      :search="searchString"
      :options="{
        page: pageIndex,
        itemsPerPage: pageSize,
        sortBy: sort.sortBy,
        sortDesc: sort.sortDesc,
       }"
      multi-sort
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        <span
            :key="header.value"
            :class="{'not-default-header-sort': isNotDefaultSortItems.includes(header.value)}"
        >
          {{ header.text }}
        </span>
      </template>
      <template v-slot:[`item.accepted`]="{ item }">
        <v-chip :color="item.accepted ? 'green' : 'orange'" dark>
          {{
            item.accepted
              ? $localizationService.localize(
                  "company_management_page.user_invitations.user_accepted"
                )
              : $localizationService.localize(
                  "company_management_page.user_invitations.user_pending"
                )
          }}
        </v-chip>
      </template>

      <template v-slot:[`item.userInfo.name`]="{ item }">
        <div class="d-flex align-center">
          <div :class="{name : !item.userInfo}">
            {{item.userInfo
              ? item.userInfo.name
              : $localizationService.localize(
              "company_management_page.user_invitations.blank_name")
            }}
          </div>
          <MessageButton
            v-if="item.userInfo"
            :target="MessagingTargets.user(item.userInfo.id)"
          />
        </div>
      </template>

      <template v-slot:[`item.userType`]="{ item }">
        {{ USER_TYPES_BY_VALUE[item.userType].text }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ momentDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          medium
          color="red"
          @click="openDeleteUserInvitationDialog(item)"
          :disabled="item.accepted"
          >mdi-delete</v-icon
        >
      </template>
      <ConfirmationDialog ref="deleteDialog" />
    </v-data-table>
  </v-container>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { dateTimeMomentFormat, USER_TYPES_BY_VALUE, MessagingTargets } from "../../utils/utils";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import MessageButton from "../MessageButton";
import tableSortParserMixin from "../../utils/table-sort-parser";

const locService = Vue.prototype.$localizationService;

export default {
  name: "UserInvitations",

  props: {
    searchString: String,
  },

  mixins: [tableSortParserMixin],

  components: {
    MessageButton,
    ConfirmationDialog,
  },

  data: () => ({
    USER_TYPES_BY_VALUE,
    MessagingTargets,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },
    pageInfo: {},
  }),

  computed: {
    ...mapState("userInvitationModule", [
      "currentPageLoading",
      "displayUserInvitations",
      "userInvitationTableInited",
    ]),
    ...mapState("userInvitationTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("userInvitationTableSettingsModule", [
      "isDefaultUserInvitationSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },

    headers: () => [
      {
        text: locService.localize(
          "company_management_page.user_invitations.header.name",
        ),
        value: "userInfo.name",
        sortable: true,
      },
      {
        text: locService.localize(
          "company_management_page.user_invitations.header.email",
        ),
        value: "email",
        sortable: true,
      },
      {
        text: locService.localize(
          "company_management_page.user_invitations.header.user_type",
        ),
        value: "userType",
        sortable: true,
      },
      {
        text: locService.localize(
          "company_management_page.user_invitations.header.accepted",
        ),
        value: "accepted",
        sortable: true,
      },
      {
        text: locService.localize(
          "company_management_page.user_invitations.header.created_at",
        ),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize(
          "company_management_page.user_invitations.header.actions",
        ),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
  },

  watch: {
    searchString() {
      this.paginate(null, this.searchString);
    },
  },

  methods: {
    paginate(data, searchString) {
      if (!this.userInvitationTableInited) {
        this.loadPage();
      }
      if (data) {
        this.pageInfo = {
          index: data.page,
          size: data.itemsPerPage,
          sort: {
            sortBy: [...data.sortBy],
            sortDesc: [...data.sortDesc],
          },
        };
        this.$store.commit("userInvitationTableSettingsModule/changeSettings", this.pageInfo);
      }

      this.pageInfo.searchString = searchString;

      this.$emit("onUpdatePage", this.pageInfo);
    },

    async loadPage() {
      await this.$store.dispatch("userInvitationModule/loadPage");
    },

    saveSettings() {
      this.$store.commit("userInvitationTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("company_management_page.user_invitations.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("userInvitationTableSettingsModule/applyDefaultSettings");
    },

    openDeleteUserInvitationDialog(userInvitation) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize(
          "company_management_page.user_invitations.dialog.delete.title",
        ),
        text: locService.localize(
          "company_management_page.user_invitations.dialog.delete.text",
          [userInvitation.email],
        ),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          try {
            const result = await this.$store.dispatch(
              "userInvitationModule/deleteUserInvitation",
              userInvitation,
            );
            Vue.toasted.success(result, {
              duration: 5000,
            });
          } catch (e) {
            Vue.toasted.error(e, {
              duration: 5000,
            });
          }
        },
      });
    },

    momentDate(date) {
      return moment(date).format(dateTimeMomentFormat);
    },
  },

  created() {
    this.$store.dispatch("userInvitationTableSettingsModule/loadDefaultSettingsFromStorage");

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("userInvitationTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("userInvitationTableSettingsModule/applyQuerySettings", query);
    }
  },
};
</script>

<style scoped>
  .name {
    color: grey;
  }
</style>
