import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { MessageTemplate } from "../model/message-template.model";

const API_PATH = "/messaging/templates";

const locService = Vue.prototype.$localizationService;

export default {
  name: "messageTemplatesModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayMessageTemplates: [],
    currentPage: {
      messageTemplates: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateMessageTemplates(state, messageTemplateDto) {
      state.currentPage.messageTemplates = [...messageTemplateDto];
      state.displayMessageTemplates = state.currentPage.messageTemplates;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const messageTemplatesDto = await API.get("core", API_PATH, body);
        commit("updateMessageTemplates", messageTemplatesDto.map((messageTemplate) => MessageTemplate.fromDto(messageTemplate)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSaveMessageTemplate(context, messageTemplate) {
      const body = { ...await bodyWithAuthHeader(), body: messageTemplate };

      try {
        await (messageTemplate.id ? API.put("core", `${API_PATH}/${messageTemplate.id}`, body)
          : API.post("core", API_PATH, body));
        Vue.toasted.success(
          locService.localize(messageTemplate.id ? "store.message_template.save_message_template_msg"
            : "store.message_template.add_message_template_msg", [messageTemplate.name]),
          {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deleteMessageTemplate(context, messageTemplate) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${messageTemplate.id}`, body);
        Vue.toasted.success(locService.localize("store.message_template.delete_message_template_msg",
          [messageTemplate.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredTemplates(context, { name }) {
      if (!name) return [];

      try {
        return await API.get("core", `${API_PATH}/search?searchString=${name}`,
          await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(e);
      }
      return undefined;
    },

    async handleTemplate(context, { recipientId, msg }) {
      try {
        return API.post("core", "/messaging/template-engine",
          {
            ...await bodyWithAuthHeader(),
            body: {
              recipient: { id: recipientId },
              text: msg,
            },
          });
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },
  },
};
