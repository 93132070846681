<template>
  <div>
    <div class="content-container">
      <div class="d-flex flex-column justify-center">
        <div class="station-label-note text-subtitle-1">
          {{ $localizationService.localize("mar_component.missing_station_for_shift_report_selection_request.default_station_label_note") }}
        </div>
        <div class="text-subtitle-2">
          {{ $localizationService.localize("mar_component.missing_station_for_shift_report_selection_request.default_station_label_note_subline") }}
        </div>
      </div>
      <v-text-field
        v-model="selectedStation"
        error-count="0"
        hide-details
        :label="$localizationService.localize('mar_component.missing_station_for_shift_report_selection_request.default_station_label')"
      ></v-text-field>
      <v-icon
        v-if="selectedStationIsValid"
        class="ml-4 mr-2"
        size="30"
        color="green"
        >mdi-checkbox-marked-circle-outline</v-icon
      >

      <v-icon v-else class="ml-4 mr-2" size="30" :color="errorColor"
        >mdi-alert-circle-outline</v-icon
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import Vue from "vue";

// const locService = Vue.prototype.$localizationService;

export default {
  name: "MissingStationForShiftReportSelectionRequestAgent",
  targetRequestType: "MissingStationForShiftReportSelectionRequest",

  props: {
    request: Object,
  },

  data() {
    return ({
      selectedStation: this.request.selectedStation,
    });
  },

  computed: {
    ...mapState("marModule", ["autoIgnoreRequests"]),
    errorColor() {
      return this.autoIgnoreRequests.find(
        (suspect) => suspect.id == this.request.id,
      )
        ? "grey"
        : "red";
    },

    selectedStationIsValid() {
      return this.sanitizedSelectedStation.length > 0;
    },

    sanitizedSelectedStation() {
      return (this.selectedStation || "").trim();
    },

    solution() {
      if (!this.selectedStationIsValid) { return undefined; }

      return {
        selectedStation: this.sanitizedSelectedStation,
      };
    },
  },

  toModel(dto) {
    return {
      ...dto,

      getAutoGeneratedSolution() {
        return undefined;
      },

      getAutoIgnoreSolution() {
        return {
          selectedStation: "unknown",
        };
      },
    };
  },
};
</script>

<style lang="scss">
.content-container {
  display: flex;
  justify-content: space-between;
  .select-and-icon {
    display: flex;
  }
  .v-text-field {
    max-width: 220px;
  }
  .station-label-note {
    color: var(--v-themeTextColor-base) !important;
  }
}
@media screen and (max-width: 425px) {
  .content-container {
    display: block;
    .v-text-field {
      max-width: 90%;
    }
    .select-action-mobile {
      max-width: 75%;
    }
  }
}
</style>
