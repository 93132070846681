import { API } from "aws-amplify";
import Vue from "vue";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { Company } from "../model/company.model";
import { UnitedDuration } from "../model/reminder/united-duration.model";

const locService = Vue.prototype.$localizationService;

export default {

  name: "companyModule",
  namespaced: true,
  state: {
    company: undefined,
    companyLoading: false,
    availableTimeZones: [],
    timeZonesLoading: false,
  },
  mutations: {

    startLoadCompany(state) {
      state.companyLoading = true;
    },

    setCompany(state, newCompany) {
      state.company = newCompany;
    },

    endLoadCompany(state) {
      state.companyLoading = false;
    },

    startLoadTimeZones(state) {
      state.timeZonesLoading = true;
    },

    setTimeZones(state, newTimeZones) {
      state.availableTimeZones = newTimeZones;
    },

    endLoadTimeZones(state) {
      state.timeZonesLoading = false;
    },
  },
  actions: {

    async loadCompany({ commit }) {
      commit("startLoadCompany");

      const body = await bodyWithAuthHeader();

      try {
        const companyDto = await API.get("core", "/company", body);
        commit("setCompany", Company.fromDto(companyDto));
      } catch (e) {
        Vue.toasted.error(e);
      } finally {
        commit("endLoadCompany");
      }
    },

    async loadAvailableTimeZonesIfRequired({ commit, state }) {
      if (state.availableTimeZones.length) return;

      commit("startLoadTimeZones");

      const body = await bodyWithAuthHeader();

      try {
        const timeZones = await API.get("core", "/company/available_time_zones", body);
        commit("setTimeZones", timeZones);
      } catch (e) {
        Vue.toasted.error(e);
      } finally {
        commit("endLoadTimeZones");
      }
    },

    async updateCompany({ state, commit }, { newName, newTimeZone, newAutoCoachingEnabled }) {
      commit("startLoadCompany");

      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          id: state.company.id,
          name: newName || state.company.name,
          timeZoneId: newTimeZone || state.company.timeZone,
          autoCoachingEnabled: newAutoCoachingEnabled != undefined ? newAutoCoachingEnabled : state.company.autoCoachingEnabled,
        },
      };

      try {
        const companyDto = await API.put("core", "/company", body);
        commit("setCompany", Company.fromDto(companyDto));
        Vue.toasted.success(
          locService.localize("store.company_management.upd_initiated"),
        );
      } catch (e) {
        Vue.toasted.error(e);
      } finally {
        commit("endLoadCompany");
      }
    },

    async saveRentalExpiryDueSoonThreshold(context, data) {
      try {
        await API.post("core", "/company/rental-expiry", {
          ...await bodyWithAuthHeader(),
          body: UnitedDuration.toDto(data),
        });
        Vue.toasted.success(
          locService.localize("store.vehicle.save_rental_expiry_due_soon_threshold_msg"),
          { duration: 5000 },
        );
      } catch (e) {
        handleHttpError(e);
      }
    },
  },
};
