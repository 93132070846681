<template>
  <div>
    <EditVehiclesDialog
      ref="EditVehiclesDialog"
      @editedVehicle="setSelectedVehicle"
    />
    <div class="content-container">
      <v-text-field
        v-if="!selectedAction.isMatchToExistingVehicle"
        class="mr-2"
        :label="
          $localizationService.localize(
            'mar_component.vehicle_selection_request.param.name'
          )
        "
        disabled
        :value="
          request.autoMatchedVehicle && selectedAction.isAcceptAutoMatched
            ? request.autoMatchedVehicle.name
            : ''
        "
      ></v-text-field>

      <v-autocomplete
        v-if="selectedAction.isMatchToExistingVehicle"
        class="mr-2"
        ref="inputName"
        :label="
          $localizationService.localize(
            'mar_component.vehicle_selection_request.param.name'
          )
        "
        item-text="text"
        item-value="value"
        :items="matchedVehicleEntries"
        v-model="selectedVehicle"
        :search-input.sync="searchVehicle"
        clearable
        :loading="loadingSearchVehicles"
        @focus="onNameFieldFocused()"
        :disabled="!selectedAction.isMatchToExistingVehicle"
      ></v-autocomplete>

      <v-text-field
        class="ml-2 mr-2"
        :label="
          $localizationService.localize(
            'mar_component.vehicle_selection_request.param.vin'
          )
        "
        :rules="[validateVin]"
        :disabled="!selectedAction.isMatchToExistingVehicle"
        v-model="currentVin"
      ></v-text-field>
      <div class="select-and-icon">
        <v-select
          class="select-action-mobile"
          :label="
            $localizationService.localize(
              'mar_component.vehicle_selection_request.param.select_action'
            )
          "
          ref="selectAction"
          :items="actionsList"
          item-text="text"
          item-value="value"
          return-object
          v-model="selectedAction"
          @change="onActionSelect($event)"
        ></v-select>

        <div
          v-if="status.type == 'LOADING'"
          class="d-flex flex-column justify-center"
        >
          <v-progress-circular
            class="ml-4"
            :size="25"
            :width="2.5"
            color="primary"
            indeterminate
          >
          </v-progress-circular>
        </div>

        <v-icon
          v-else-if="status.type == 'OK'"
          class="ml-4 mr-2"
          size="30"
          color="green"
          >mdi-checkbox-marked-circle-outline</v-icon
        >

        <v-icon v-else class="ml-4 mr-2" size="30" :color="errorColor"
          >mdi-alert-circle-outline</v-icon
        >
      </div>
    </div>
    <p class="mb-1" v-if="request.rawVin">
      {{
        $localizationService.localize(
          "mar_component.vehicle_selection_request.raw_data",
          [request.rawVin]
        )
      }}
    </p>
    <p class="mb-1" v-if="!request.rawVin">
      {{
        $localizationService.localize(
          "mar_component.vehicle_selection_request.vin_not_found",
          [request.rowNumber]
        )
      }}
    </p>
    <p v-if="request.autoMatchedVehicle && !selectedAction.isAcceptAutoMatched">
      {{
        $localizationService.localize(
          "mar_component.vehicle_selection_request.auto_matched_vehicle",
          [request.autoMatchedVehicle.vin, request.autoMatchedVehicle.name]
        )
      }}
    </p>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import isEmpty from "lodash/isEmpty";
import { mapState } from "vuex";
import Vue from "vue";
import {
  CREATE_NEW_VEHICLE,
  ACCEPT_AUTO_MATCH,
  IGNORE_THIS_VEHICLE,
  MATCH_TO_EXISTING_VEHICLE,
} from "../../utils/utils";
import EditVehiclesDialog from "../../components/vehicles/EditVehiclesDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "VehicleSelectionRequest",
  targetRequestType: "VehicleSelectionRequest",

  props: {
    request: Object,
  },

  components: {
    EditVehiclesDialog,
  },

  data: () => ({
    searchVehicle: null,
    selectedAction: {},
    selectedVehicle: undefined,
    matchedVehicleEntries: [],
    currentVin: "",
    loadingVehicles: false,
    loadingSearchVehicles: false,
  }),

  computed: {
    ...mapState("marModule", ["autoIgnoreRequests"]),
    errorColor() {
      return this.autoIgnoreRequests.find(
        (suspect) => suspect.id == this.request.id,
      )
        ? "grey"
        : "red";
    },
    validateVin(value) {
      return (
        !value
        || this.selectedAction != MATCH_TO_EXISTING_VEHICLE
        || this.selectedVehicle != undefined
        || locService.localize(
          "mar_component.vehicle_selection_request.validate.unknown_vin",
        )
      );
    },
    solution() {
      if (
        this.selectedAction == MATCH_TO_EXISTING_VEHICLE
        && !this.selectedVehicle
      ) { return undefined; }

      return {
        action: this.selectedAction.value,
        vehicleId: this.selectedVehicle ? this.selectedVehicle.id : null,
      };
    },
    actionsList() {
      const list = [
        MATCH_TO_EXISTING_VEHICLE,
        IGNORE_THIS_VEHICLE,
        CREATE_NEW_VEHICLE,
      ];

      if (this.request.autoMatchedVehicle) list.push(ACCEPT_AUTO_MATCH);

      return list;
    },
    status() {
      if (this.selectedAction == ACCEPT_AUTO_MATCH) {
        return {
          type: "OK",
          description: locService.localize(
            "mar_component.vehicle_selection_request.status_description.auto_match.ok",
          ),
        };
      }
      if (this.selectedAction == MATCH_TO_EXISTING_VEHICLE) {
        if (this.selectedVehicle) {
          return {
            type: "OK",
            description: locService.localize(
              "mar_component.vehicle_selection_request.status_description.existing.ok",
              [this.selectedVehicle.vin, this.selectedVehicle.name],
            ),
          };
        }
        return this.loadingVehicles
          ? { type: "LOADING", description: locService.localize("common.loading") }
          : {
            type: "ERROR",
            description: locService.localize(
              "mar_component.vehicle_selection_request.status_description.existing.error",
            ),
          };
      }
      if (this.selectedAction == IGNORE_THIS_VEHICLE) {
        return {
          type: "OK",
          description: locService.localize(
            "mar_component.vehicle_selection_request.status_description.ignore.ok",
          ),
        };
      }

      return {
        type: "ERROR",
        description: locService.localize(
          "mar_component.vehicle_selection_request.status_description.unknown_state",
        ),
      };
    },
  },

  watch: {
    searchVehicle() {
      if (this.selectedVehicle && this.searchVehicle === this.selectedVehicle.name) {
        return;
      }

      if (this.searchVehicle) {
        this.loadFilteredVehicle();
      }
    },

    selectedVehicle(val) {
      this.currentVin = val ? val.vin : undefined;
    },

    currentVin: debounce(function (val) {
      if (this.selectedVehicle && val == this.selectedVehicle.vin) return;

      this.selectedVehicle = undefined;

      setTimeout(() => {
        this.loadingVehicles = true;
        this.$store
          .dispatch("vehiclesModule/loadFilteredVehicle", {
            vin: this.currentVin,
          })
          .then((data) => {
            if (data.length == 0) return;

            this.selectedVehicle = data[0];

            this.matchedVehicleEntries.push({
              text: this.selectedVehicle.name,
              value: this.selectedVehicle,
            });
          })
          .finally(() => (this.loadingVehicles = false));
      });
    }, 300),
  },

  methods: {
    onActionSelect(event) {
      this.selectedVehicle = undefined;

      if (event == ACCEPT_AUTO_MATCH) {
        this.currentVin = this.request.autoMatchedVehicle
          ? this.request.autoMatchedVehicle.vin
          : "";
      }

      if (event == CREATE_NEW_VEHICLE) {
        this.$refs.EditVehiclesDialog.openDialog({
          vin: this.request.rawVin,
        });
        this.$nextTick(() => (this.selectedAction = MATCH_TO_EXISTING_VEHICLE));
      }
    },

    // TODO: warning
    loadFilteredVehicle: debounce(function () {
      this.selectedVehicle = undefined;
      this.loadingSearchVehicles = true;
      this.currentVin = "";

      this.$store.dispatch("vehiclesModule/loadFilteredVehicle", {
        name: this.searchVehicle,
      }).then((data) => {
        this.matchedVehicleEntries = data.map((vehicle) => ({
          text: vehicle.name,
          value: vehicle,
        }));
        this.loadingSearchVehicles = false;
      });
    }, 300),

    setSelectedVehicle(vehicle) {
      this.selectedVehicle = vehicle;
      this.matchedVehicleEntries.push({
        text: vehicle.name || "",
        value: vehicle,
      });
      this.currentVin = vehicle.vin;
    },

    onNameFieldFocused() {
      this.clearMatchedVehicleEntries();
    },

    clearMatchedVehicleEntries() {
      this.matchedVehicleEntries = [];
    },
  },

  mounted() {
    this.clearMatchedVehicleEntries();

    if (!this.selectedAction || isEmpty(this.selectedAction)) {
      this.selectedAction = this.request.autoMatchedVehicle
        ? ACCEPT_AUTO_MATCH
        : MATCH_TO_EXISTING_VEHICLE;
    }

    if (this.selectedVehicle) {
      this.matchedVehicleEntries.push({
        text: this.selectedVehicle.name,
        value: this.selectedVehicle,
      });
    }

    if (this.selectedAction == ACCEPT_AUTO_MATCH) {
      this.currentVin = this.request.autoMatchedVehicle
        ? this.request.autoMatchedVehicle.vin
        : "";
    }
  },

  toModel(dto) {
    return {
      ...dto,

      getAutoGeneratedSolution() {
        return this.autoMatchedVehicle
          ? {
            action: ACCEPT_AUTO_MATCH.value,
            vehicleId: this.autoMatchedVehicle.id,
          }
          : undefined;
      },

      getAutoIgnoreSolution() {
        return {
          action: IGNORE_THIS_VEHICLE.value,
        };
      },
    };
  },
};
</script>

<style lang="scss">
.content-container {
  display: flex;
  justify-content: space-between;
  .select-and-icon {
    display: flex;
  }
  .v-text-field {
    max-width: 220px;
  }
}
@media screen and (max-width: 425px) {
  .content-container {
    display: block;
    .v-text-field {
      max-width: 90%;
    }
    .select-action-mobile {
      max-width: 75%;
    }
  }
}
</style>
