<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              editedVehicleGroup.id
                ? "vehicle_group_page.dialog.add_and_edit.edit_title"
                : "vehicle_group_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                :rules="requiredRule"
                v-model="editedVehicleGroup.name"
                :label="
                  $localizationService.localize(
                      'vehicle_group_page.dialog.add_and_edit.param.name'
                  )
                "
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="editedVehicleGroup.description"
                rows="3"
                :label="
                  $localizationService.localize(
                      'vehicle_group_page.dialog.add_and_edit.param.description'
                  )
                "
                :disabled="loading"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="GROUP_TYPES"
                v-model="groupType"
                item-text="text"
                item-value="value"
                return-object
                :disabled="isDisabledType"
                :loading="loadingModel || loadingVehicleModel"
                :label="$localizationService.localize('vehicle_group_page.dialog.add_and_edit.param.types.label')"
              />
            </v-col>
            <v-col
              v-if="groupType === GROUP_TYPE_CRITERIA"
              class="pt-0 d-flex justify-end"
              style="max-width: 36px !important;"
            >
              <v-icon
                class="add-criteria-btn"
                medium
                :disabled="isAddCriteriaDisabled"
                @click="addCriteria"
              >
                mdi-plus-circle
              </v-icon>
            </v-col>
          </v-row>
          <v-row v-if="groupType === GROUP_TYPE_GROUPING">
            <v-col>
              <v-autocomplete
                v-model="editedVehicleGroup.include"
                :search-input.sync="searchIncludeVehicleGroups"
                :loading="loadingIncludeGroups"
                :items="matchedIncludeGroups"
                cache-items
                item-text="name"
                item-value="id"
                return-object
                :label="
                $localizationService.localize('vehicle_group_page.dialog.add_and_edit.param.include')"
                :disabled="loading"
                chips
                multiple
                deletable-chips
              />
            </v-col>
          </v-row>
          <v-row v-if="groupType === GROUP_TYPE_GROUPING">
            <v-col>
              <v-autocomplete
                v-model="editedVehicleGroup.exclude"
                :search-input.sync="searchExcludeVehicleGroups"
                :loading="loadingExcludeGroups"
                :items="matchedExcludeGroups"
                item-text="name"
                item-value="id"
                cache-items
                return-object
                :label="
                $localizationService.localize('vehicle_group_page.dialog.add_and_edit.param.exclude')"
                :disabled="loading"
                chips
                multiple
                deletable-chips
              />
            </v-col>
          </v-row>
          <v-container v-if="groupType === GROUP_TYPE_CRITERIA && vehicleModel">
            <v-row v-for="(item, index) in criteria" :key="item.id">
              <v-col class="pl-0">
                <v-autocomplete
                  :label="
                    $localizationService.localize(
                      'vehicle_group_page.dialog.add_and_edit.criteria.property'
                    ) + '*'
                  "
                  v-model="item.property"
                  :items="properties"
                  item-text="displayName"
                  item-value="name"
                  return-value
                  :rules="requiredRule"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :label="
                    $localizationService.localize(
                      'vehicle_group_page.dialog.add_and_edit.criteria.operator'
                    ) + '*'
                  "
                  :items="availableOperators"
                  v-model="item.operator"
                  item-text="displayName"
                  item-value="name"
                  return-value
                  :rules="requiredRule"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-if="propertyValuesIsEnum[properties.map(it => it.name).indexOf(item.property)]"
                  :items="propertyValues[properties.map(it => it.name).indexOf(item.property)]"
                  :label="
                    $localizationService.localize('vehicle_group_page.dialog.add_and_edit.criteria.threshold_value')
                  "
                  item-text="displayName"
                  item-value="name"
                  return-value
                  v-model="item.thresholdValue"
                />
                <v-text-field
                  v-else
                  v-model="item.thresholdValue"
                  :label="
                    $localizationService.localize('vehicle_group_page.dialog.add_and_edit.criteria.threshold_value')
                  "
                ></v-text-field>
              </v-col>
              <v-col class="pr-0 pt-0 d-flex justify-end ma-xl-0" style="max-width: 24px !important;">
                <v-icon color="red" medium @click="deleteCriteria(index)"
                >mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-container>
          <div v-if="groupType === GROUP_TYPE_MANUAL">
            <v-row class="pt-0">
              <v-col>
                <v-autocomplete
                  v-model="editedVehicleGroup.vehicles"
                  :search-input.sync="searchVehicles"
                  cache-items
                  :loading="loadingVehicles"
                  :items="matchedVehicles"
                  item-text="name"
                  item-value="id"
                  return-object
                  :label="
                    $localizationService.localize(
                      'vehicle_group_page.dialog.add_and_edit.param.vehicles'
                    )"
                  :disabled="loading"
                  chips
                  multiple
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addOrSaveVehicleGroup"
          :disabled="loading || !editedVehicleGroup.name"
        >
          {{ $localizationService.localize("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import debounce from "lodash/debounce";
import { differenceWith, isEqual } from "lodash";
import { VehicleGroup } from "../../model/vehicle-group.model";
import {
  GROUP_TYPES, GROUP_TYPE_CRITERIA, GROUP_TYPE_MANUAL, GROUP_TYPE_GROUPING,
} from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditVehicleGroupDialog",

  data: () => ({
    GROUP_TYPES,
    GROUP_TYPE_CRITERIA,
    GROUP_TYPE_MANUAL,
    GROUP_TYPE_GROUPING,

    open: false,
    loading: false,
    loadingVehicles: false,
    loadingIncludeGroups: false,
    loadingExcludeGroups: false,

    isDisabledType: false,

    groupType: {},

    criteria: [],
    vehicles: [],

    searchVehicles: "",
    searchIncludeVehicleGroups: "",
    searchExcludeVehicleGroups: "",

    matchedIncludeGroups: [],
    matchedExcludeGroups: [],
    matchedVehicles: [],

    editedVehicleGroupOriginal: {},
    editedVehicleGroup: {},

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

  }),

  computed: {
    ...mapState("simpleModule", ["loadingVehicleModel", "loadingModel", "vehicleModel", "currentModel"]),

    properties() {
      return this.vehicleModel && this.vehicleModel.properties ? this.vehicleModel.properties : [];
    },

    propertyTypes() {
      return this.properties.map((it) => it.type);
    },

    propertyValues() {
      return this.propertyTypes.map((it) => it.values);
    },

    propertyValuesIsEnum() {
      return this.propertyTypes.map((it) => it.isEnum);
    },

    availableOperators() {
      return this.currentModel
        ? this.currentModel.operators.filter((operator) => (operator.supportedTypes === null)) : [];
    },

    isAddCriteriaDisabled() {
      return this.loading || this.loadingModel || this.loadingVehicleModel || !this.vehicleModel || !this.currentModel;
    },
  },

  watch: {
    searchIncludeVehicleGroups() {
      if (this.searchIncludeVehicleGroups) {
        this.loadIncludeGroups();
      }
    },

    searchExcludeVehicleGroups() {
      if (this.searchExcludeVehicleGroups) {
        this.loadExcludeGroups();
      }
    },

    searchVehicles() {
      if (this.searchVehicles) {
        this.loadVehicles();
      }
    },

    async groupType() {
      if (this.groupType === GROUP_TYPE_CRITERIA) {
        await this.$store.dispatch("simpleModule/loadVehicleModel");
        await this.$store.dispatch("simpleModule/loadModelIfNeeded");
      }
    },
  },

  methods: {

    openDialog(vehicleGroup) {
      this.editedVehicleGroupOriginal = vehicleGroup || {};
      this.editedVehicleGroup = Object.assign(new VehicleGroup(), this.editedVehicleGroupOriginal);

      this.matchedIncludeGroups = vehicleGroup && vehicleGroup.include;
      this.matchedExcludeGroups = vehicleGroup && vehicleGroup.exclude;
      this.matchedVehicles = vehicleGroup && vehicleGroup.vehicles;
      if (vehicleGroup) {
        this.isDisabledType = true;
        this.groupType = vehicleGroup.type;
      } else {
        this.isDisabledType = false;
        this.groupType = {};
      }
      this.criteria = vehicleGroup && vehicleGroup.criteria.length > 0 ? vehicleGroup.criteria.map((it) => JSON.parse(it)) : [];

      this.open = true;
    },

    closeDialog() {
      this.open = false;
      this.criteria = [];
      this.isDisabledType = false;
      this.groupType = {};
    },

    addCriteria() {
      this.criteria.push({});
    },

    deleteCriteria(index) {
      this.criteria.splice(index, 1);
    },

    loadIncludeGroups: debounce(async function () {
      this.loadingIncludeGroups = true;
      const data = await this.$store.dispatch("vehicleGroupsModule/loadFilteredVehicleGroups", this.searchIncludeVehicleGroups);
      this.matchedIncludeGroups = differenceWith(data, this.editedVehicleGroup.exclude ? [...this.editedVehicleGroup.exclude] : [], isEqual);
      this.loadingIncludeGroups = false;
    }, 600),

    loadExcludeGroups: debounce(async function () {
      this.loadingExcludeGroups = true;
      const data = await this.$store.dispatch("vehicleGroupsModule/loadFilteredVehicleGroups", this.searchExcludeVehicleGroups);
      this.matchedExcludeGroups = differenceWith(data, this.editedVehicleGroup.include ? [...this.editedVehicleGroup.include] : [], isEqual);
      this.loadingExcludeGroups = false;
    }, 600),

    loadVehicles: debounce(async function () {
      this.loadingVehicles = true;
      await this.$store.dispatch("vehiclesModule/loadFilteredVehicle", { name: this.searchVehicles })
        .then((data) => {
          this.matchedVehicles = data;
          this.loadingVehicles = false;
        });
      this.loadingVehicles = false;
    }, 600),

    async addOrSaveVehicleGroup() {
      this.loading = true;
      this.editedVehicleGroup.criteria = this.criteria.map((it) => JSON.stringify(it));
      if (this.editedVehicleGroup.id) {
        await this.$store.dispatch("vehicleGroupsModule/saveVehicleGroup", this.editedVehicleGroup);
      } else {
        switch (this.groupType) {
          case GROUP_TYPE_MANUAL: {
            await this.$store.dispatch(
              "vehicleGroupsModule/addManualVehicleGroup",
              this.editedVehicleGroup,
            );
            break;
          }
          case GROUP_TYPE_CRITERIA: {
            await this.$store.dispatch(
              "vehicleGroupsModule/addCriteriaVehicleGroup",
              this.editedVehicleGroup,
            );
            break;
          }
          case GROUP_TYPE_GROUPING: {
            await this.$store.dispatch(
              "vehicleGroupsModule/addGroupingVehicleGroup",
              this.editedVehicleGroup,
            );
            break;
          }
        }
      }

      this.$emit("onAddOrSave");
      this.loading = false;
      this.open = false;
    },
  },
};
</script>

<style lang="scss">
.add-criteria-btn {
  &:after {
    background: none !important;
  }
}
.description-column {
  max-width: 20vw;
  word-break: break-all;
}
</style>
