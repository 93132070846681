<template>
  <MessageTemplates />
</template>

<script>
import MessageTemplates from "../components/message-templates/MessageTemplates";

export default {
  name: "MessageTemplatesPage",

  routing: {
    localizationKey: "menu.tab.message_templates",
    icon: "mdi-message-text-outline",
    path: "/messaging/templates",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    MessageTemplates,
  },
};
</script>
