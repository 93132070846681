import lodash from "lodash";
import {
  getLocalStorageData,
  setLocalStorageData,
  OPENED_RESCUES,
} from "../../utils/utils";

const NO_RESCUERS = "NO RESCUERS";
const CLOSED_EXPANDED = "CLOSED EXPANDED";

export default {

  name: "drpRoutesTableSettingsModule",
  namespaced: true,
  state: {
    tableSettingsStorageKey: "drp.routes.table.settings",

    sortPropertyName: "routesSort",

    routesSort: {
      sortBy: [],
      sortDesc: [],
    },

    defaultSettings: {
      routesSort: {
        sortBy: [],
        sortDesc: [],
      },
    },

    tableInited: false,

    expanded: [],

    expandedPanelOpeningState: {},
  },

  mutations: {
    toggleRow(state, row) {
      const expandedIndex = state.expanded.findIndex((it) => it.id === row.id);
      if (expandedIndex === -1) {
        // on open expanded content
        state.expanded.push(row);
      } else if (!state.expandedPanelOpeningState[row.id]) {
        // on close expanded content
        state.expandedPanelOpeningState[row.id] = [CLOSED_EXPANDED];

        if (!row.rescuers.length) {
          state.expandedPanelOpeningState[row.id].push(NO_RESCUERS);
        }

        state.expanded.splice(expandedIndex, 1);
      } else {
        // because expanded content dont want to change
        state.expanded = [...state.expanded];
      }
    },

    openAllRescuers(state, { rows, tableInited }) {
      if (!state.expanded.length && !state.tableInited) {
        state.tableInited = tableInited;
        (rows || []).forEach((row) => {
          if (row.rescuers.length) {
            state.expandedPanelOpeningState[row.id] = OPENED_RESCUES;
            state.expanded.push(row);
          } else {
            state.expandedPanelOpeningState[row.id] = [CLOSED_EXPANDED, NO_RESCUERS];
          }
        });
      }
    },

    changeSettings(state, data) {
      state.routesSort = data.routesSort;
    },

    saveSettings(state) {
      const data = {
        routesSort: state.routesSort,
      };
      setLocalStorageData(state.tableSettingsStorageKey, JSON.stringify(data));
      state.defaultSettings = data;
    },

    applyDefaultSettings(state) {
      state.routesSort = { ...state.defaultSettings.routesSort };
    },

    applyQuerySettings(state, query) {
      if (query.routesSort) {
        state.routesSort = JSON.parse(query.routesSort);
      }
    },
  },

  actions: {
    checkRowsOnUpdateRescue({ state, commit }, rows) {
      rows.forEach((row) => {
        if (
          row.rescuers.length
          && (
            // !state.expandedPanelOpeningState[row.id] - when was detected new row
            // || ...  - when was detected row with updated rescue after refresh
            !state.expandedPanelOpeningState[row.id]
            || (typeof state.expandedPanelOpeningState[row.id] === "object"
              && state.expandedPanelOpeningState[row.id].includes(NO_RESCUERS))
          )
        ) {
          state.expandedPanelOpeningState[row.id] = OPENED_RESCUES;
          commit("toggleRow", row);
        }

        if (
          !row.rescuers.length
          && state.expandedPanelOpeningState[row.id] === OPENED_RESCUES
        ) {
          state.expandedPanelOpeningState[row.id] = [CLOSED_EXPANDED, NO_RESCUERS];
          commit("toggleRow", row);
        }
      });
    },

    openClosedRescueExpanded({ state, commit }, rows) {
      rows.forEach((row) => {
        if (
          row.rescuers.length
          && typeof state.expandedPanelOpeningState[row.id] === "object"
          && state.expandedPanelOpeningState[row.id].includes(CLOSED_EXPANDED)
        ) {
          state.expandedPanelOpeningState[row.id] = OPENED_RESCUES;
          commit("toggleRow", row);
        }
      });
    },

    loadDefaultSettingsFromStorage({ state }) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },
  },

  getters: {
    isDefaultSettings: (state, getters) => getters.isDefaultSort,
    isDefaultSort: (state) => lodash.isEqual(state.routesSort, state.defaultSettings.routesSort),
  },
};
