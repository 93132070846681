<template>
  <ScansTable />
</template>

<script>
import ScansTable from "../../components/coaching/ScansTable.vue";

export default {
  name: "CoachingScans",

  routing: {
    localizationKey: "coaching_page.menu.scan_for_infractions",
    path: "/scans",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: { ScansTable },
};
</script>
