// eslint-disable-next-line max-classes-per-file
import { GROUP_TYPES_BY_VALUE } from "../utils/utils";

export class VehicleGroup {
    id;

    name;

    description;

    include;

    exclude;

    vehicles;

    criteria;

    createdAt;

    updatedAt;

    type;

    constructor(
      id,
      name,
      description,
      include,
      exclude,
      vehicles,
      criteria,
      createdAt,
      updatedAt,
      type,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.include = include;
      this.exclude = exclude;
      this.vehicles = vehicles;
      this.criteria = criteria;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.type = type;
    }

    static fromDto(dto) {
      return new VehicleGroup(
        dto.id,
        dto.name,
        dto.description,
        dto.include,
        dto.exclude,
        dto.vehicles,
        dto.criteria,
        dto.createdAt,
        dto.updatedAt,
        GROUP_TYPES_BY_VALUE[dto.type],
      );
    }

    static toDto(vehicleGroup) {
      return new VehicleGroup(
        vehicleGroup.id,
        vehicleGroup.name,
        vehicleGroup.description,
        (vehicleGroup.include && vehicleGroup.include.map((it) => it.id)) || [],
        (vehicleGroup.exclude && vehicleGroup.exclude.map((it) => it.id)) || [],
        (vehicleGroup.vehicles && vehicleGroup.vehicles.map((it) => it.id)) || [],
        vehicleGroup.criteria || [],
      );
    }
}

export class VehicleGroupManualDto {
    id;

    name;

    description;

    vehicles;

    constructor(
      id,
      name,
      description,
      vehicles,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.vehicles = vehicles;
    }

    static toManualDto(vehicleGroup) {
      return new VehicleGroupManualDto(
        vehicleGroup.id,
        vehicleGroup.name,
        vehicleGroup.description,
        (vehicleGroup.vehicles && vehicleGroup.vehicles.map((it) => it.id)) || [],
      );
    }
}

export class VehicleGroupCriteriaDto {
    id;

    name;

    description;

    criteria;

    constructor(
      id,
      name,
      description,
      criteria,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.criteria = criteria;
    }

    static toCriteriaDto(vehicleGroup) {
      return new VehicleGroupCriteriaDto(
        vehicleGroup.id,
        vehicleGroup.name,
        vehicleGroup.description,
        vehicleGroup.criteria || [],
      );
    }
}

export class VehicleGroupGroupingDto {
    id;

    name;

    description;

    include;

    exclude;

    constructor(
      id,
      name,
      description,
      include,
      exclude,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.include = include;
      this.exclude = exclude;
    }

    static toGroupingDto(vehicleGroup) {
      return new VehicleGroupGroupingDto(
        vehicleGroup.id,
        vehicleGroup.name,
        vehicleGroup.description,
        (vehicleGroup.include && vehicleGroup.include.map((it) => it.id)) || [],
        (vehicleGroup.exclude && vehicleGroup.exclude.map((it) => it.id)) || [],
      );
    }
}
