












































































































import {
  Component, Emit, Vue, Watch,
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment-timezone";
import { VehicleActionType } from "@/model/reminder/vehicle-action-type.model";
import { VehicleAction } from "@/model/reminder/vehicle-action.model";
import { dateMomentFormat } from "@/utils/utils";
import VehicleTargetSelection from "@/components/vehicle-inspections/VehicleTargetSelection.vue";

const locService = Vue.prototype.$localizationService;

const vehicleActionTypesModule = namespace("vehicleActionTypesModule");
const vehicleActionsModule = namespace("vehicleActionsModule");
const companyModule = namespace("companyModule");

@Component({
  components: {
    VehicleTargetSelection,
  },
})
export default class SubmitActionDialog extends Vue {
  open = false;

  loading = false;

  isMenuOpen = false;

  doneAt = ""

  vehicleAction: VehicleAction = {} as VehicleAction;

  types: VehicleActionType[] = [];

  @companyModule.State
  company!: any

  requiredRule = [
    (value: any) => !!value || locService.localize("error.validation.required"),
  ];

  disableVehicleSelection = false;

  get saveIsDisabled() {
    return this.loading
        || !this.vehicleAction.doneAt
        || !this.vehicleAction.doneAtMeterValue
        || !this.vehicleAction.vehicle
        || !this.vehicleAction.type;
  }

  get displayDoneAt() {
    return this.doneAt ? moment(this.doneAt).format(dateMomentFormat) : undefined;
  }

  @Emit("onSubmit")
  onSubmit() {}

  @Watch("doneAt")
  async onChangeDoneAt() {
    this.vehicleAction.doneAt = moment.tz(this.doneAt, this.company.timeZone).utc().format();
  }

  @vehicleActionTypesModule.Action
  loadAllVehicleActionTypes!: () => Promise<VehicleActionType[]>

  @vehicleActionsModule.Action
  submitAction!: (action: VehicleAction) => Promise<void>

  openDialog(action: VehicleAction | undefined) {
    this.vehicleAction = action ? action.clone() : VehicleAction.createEmpty(undefined);
    this.doneAt = action && action.doneAt ? moment(action.doneAt).tz(this.company.timeZone).format().substring(0, 10) : "";
    this.disableVehicleSelection = !!action?.vehicle;
    this.open = true;
  }

  closeDialog() {
    this.open = false;
  }

  onVehicleSelect(event: any) {
    this.vehicleAction.vehicle = (event || {}).model || {};
  }

  async submit() {
    this.loading = true;
    await this.submitAction(this.vehicleAction);
    this.loading = false;
    this.onSubmit();
    this.closeDialog();
  }

  async mounted() {
    this.loading = true;
    this.types = await this.loadAllVehicleActionTypes();
    this.loading = false;
  }
}

