import Vue from "vue";
import { API } from "aws-amplify";
import { debounce } from "lodash";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";

const locService = Vue.prototype.$localizationService;

const API_PATH = "/driver";

export default {

  name: "driverModule",
  namespaced: true,
  state: {

    currentPageLoading: false,
    currentPage: {
      drivers: [],
      index: 0,
      size: 25,
    },
    displayDrivers: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageDrivers(state, driversDto) {
      state.currentPage.drivers = [...driversDto];
      state.displayDrivers = state.currentPage.drivers;
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    reloadPage: debounce(async ({ dispatch, state }) => {
      dispatch("loadPage", state.currentPage);
    }, 300),

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const driversDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageDrivers", driversDto);
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    async deleteDriver({ commit, dispatch, state }, driver) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${driver.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        return locService.localize("store.driver.delete_driver_msg", [driver.name]);
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async addOrSaveDriver({ commit, dispatch, state }, driver) {
      const body = { ...await bodyWithAuthHeader(), body: driver };

      try {
        const result = await (driver.id ? API.put("core", `${API_PATH}/${driver.id}`, body) : API.post("core", API_PATH, body));
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");
        return result;
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async inviteDriver({ dispatch }, driver) {
      const body = await bodyWithAuthHeader();

      try {
        await API.put("core", `${API_PATH}/${driver.id}/invite`, body);
        dispatch("reloadPage");
        return locService.localize("store.driver.invite_driver_msg", [driver.name]);
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async revokeDriverInvitation({ dispatch }, driver) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${driver.id}/invite`, body);
        dispatch("reloadPage");
        return locService.localize("store.driver.revoke_driver_invitation_msg", [driver.name]);
      } catch (e) {
        throw e.response.data.message;
      }
    },
  },
};
