var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"messageTemplates",attrs:{"align":"start","justify":"start"}},[_c('v-col',[_c('v-data-table',{class:{'not-default-page-size': !this.isDefaultSize},attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.displayMessageTemplates,"loading":_vm.pageLoading || !_vm.tableInited,"options":{
            page: _vm.pageIndex,
            itemsPerPage: _vm.pageSize,
            sortBy: _vm.sort.sortBy,
            sortDesc: _vm.sort.sortDesc,
           },"server-items-length":_vm.totalCount,"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("message_template_page.title"))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pDefaultButtonTooltip":_vm.$localizationService.localize(
                      'message_template_page.settings_tooltip.apply_default'
                      ),"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize(
                      'message_template_page.settings_tooltip.save'
                    )},on:{"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()},"onSaveSettings":function($event){return _vm.saveSettings()}}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize('message_template_page.search'),"hide-details":"","width":"200"},on:{"input":function($event){return _vm.searchStringDebounceWatch()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditMessageTemplateDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("message_template_page.new_btn"))+" ")]),_c('EditMessageTemplateDialog',{ref:"messageTemplateDialog",on:{"onAddOrSave":_vm.reloadPage}}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
              'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.text",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-column"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"description-column"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openEditMessageTemplateDialog(item)}}},[_vm._v("mdi-pencil ")]),_c('v-icon',{attrs:{"color":"red","medium":""},on:{"click":function($event){return _vm.openDeleteMessageTemplateDialog(item)}}},[_vm._v("mdi-delete ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }