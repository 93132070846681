<template>
    <div class="forbidden-container">
      <v-icon large>mdi-lock</v-icon>
      <div class="forbidden-message">
        <h1>{{ $localizationService.localize("forbidden_page.title") }}</h1>
        <p>{{ $localizationService.localize("forbidden_page.subtitle") }}</p>
      </div>
    </div>
</template>

<script>

export default {
  name: "ForbiddenPage",

  routing: {
    path: "/forbidden",
    hasAccess: () => true,
  },
};
</script>
<style lang="scss">
.forbidden-container {
  position: relative;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forbidden-message {
  text-align: center;
}
</style>
