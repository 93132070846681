<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title
        class="coaching-toolbar-title d-flex flex-row align-center"
      >
        <div class="d-flex flex-column">
          {{ coachingHeaderFirstLine }}
          <span v-if="coaching" class="coaching-second-line">
            {{ coachingHeaderSecondLine }}
          </span>
        </div>
        <template v-if="coaching">
          <div class="toolbar-cell-delimeter mr-4 ml-4"></div>
          <div class="d-flex flex-column">
            {{ coaching.targetInfractions.length }}
            <span class="coaching-second-line">
              {{
                $localizationService.localize(
                  "coaching_page.driver_coaching_widget.total_infractions_count"
                )
              }}
            </span>
          </div>
        </template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="coachingCanBeResolved"
        class="ml-4"
        color="primary"
        @click="openResolveCoachingDialog()"
        :disabled="!coaching"
      >
        {{
          $localizationService.localize(
            "coaching_page.driver_coaching_widget.btn_resolve_coaching"
          )
        }}
      </v-btn>
    </v-toolbar>
    <v-card :loading="loading">
      <v-card-text class="pa-0">
        <v-row class="ma-0 pt-3 pb-3 comparision-row">
          <template v-if="coaching && !noInfractionsSinceMode">
            <v-col cols="4" class="pt-0 pb-0">
              <h4 class="mb-2">
                {{
                  $localizationService.localize(
                    "coaching_page.driver_coaching_widget.infractions.most_frequent"
                  )
                }}
              </h4>
              <pie-chart
                v-if="coaching"
                class="most-frequent-chart"
                :chartdata="mostFrequentChartData"
                :options="frequentChartOptions"
              />
              <v-chip-group column>
                <v-chip
                  v-for="(
                    item, index
                  ) in mostFrequentInfractionsCriteriaEntries"
                  :key="item.criteria.name"
                  :class="Object.keys(colors)[index]"
                  class="white--text"
                >
                  <v-avatar
                    left
                    :class="`${Object.keys(colors)[index]} darken-4`"
                  >
                    {{ item.count }}
                  </v-avatar>
                  {{ item.criteria.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col class="middle-col pt-0 pb-0" v-if="coachingCanBeResolved">
              <h4 class="mb-2">
                {{
                  $localizationService.localize(
                    "coaching_page.driver_coaching_widget.infractions.most_repeat"
                  )
                }}
              </h4>
            </v-col>
            <v-col
              class="pt-0 pb-0"
              cols="4"
              :class="{ 'middle-col': !coachingCanBeResolved }"
            >
              <h4>
                {{
                  $localizationService.localize(
                    "coaching_page.driver_coaching_widget.comparison"
                  )
                }}:
                <span class="primary--text"
                  >{{
                    $localizationService.localize(
                      "coaching_page.driver_coaching_widget.current"
                    )
                  }}
                </span>
                /
                <span class="green--text"
                  >{{
                    $localizationService.localize(
                      "coaching_page.driver_coaching_widget.previous"
                    )
                  }}
                </span>
                {{
                  $localizationService.localize(
                    "coaching_page.driver_coaching_widget.session"
                  )
                }}
              </h4>
              <div
                v-if="
                  comparisionChartReady &&
                  mostFrequentInfractionsCriteriaEntries.length > 2
                "
                class="d-flex flex-column justify-center fill-height"
              >
                <radar-chart
                  class="comparision-chart"
                  :chartdata="comparisonChartData"
                  :options="comparisionChartOptions"
                />
              </div>
              <div
                v-else-if="mostFrequentInfractionsCriteriaEntries.length <= 2"
                class="d-flex justify-center align-center fill-height"
              >
                {{
                  $localizationService.localize(
                    "coaching_page.driver_coaching_widget.criteria_length_less_2"
                  )
                }}
              </div>
              <div
                v-else-if="!previousCoaching && !previousCoachingLoading"
                class="d-flex justify-center align-center fill-height"
              >
                {{
                  $localizationService.localize(
                    "coaching_page.driver_coaching_widget.no_previous_coaching"
                  )
                }}
              </div>
            </v-col>
            <v-col class="pt-0 pb-0" v-if="!coachingCanBeResolved">
              <h4>Note:</h4>
              <div>
                {{ coaching.resolutionNote }}
              </div>
            </v-col>
          </template>
          <template v-else-if="noInfractionsSinceMode">
            <v-col class="d-flex flex-row justify-center align-center">
              {{
                $localizationService.localize(
                  "coaching_page.driver_coaching_widget.no_infractions_since",
                  [previousCoachingFormattedResolvedAt]
                )
              }}
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <ResolveCoachingDialog ref="resolveCoachingDialog" />
  </div>
</template>

<script>
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import Vue from "vue";
import RadarChart from "./additionals/AutoCoachingComparison.vue";
import PieChart from "./additionals/AutoCoachingMostFrequentChart.vue";
import ResolveCoachingDialog from "./ResolveCoachingDialog.vue";
import {
  dateTimeMomentFormat,
  COACHING_STATUS_NEW,
  COACHING_STATUS_RESOLVED,
} from "../../utils/utils";
import { THEME, THEME_DARK_KEY } from "../../app-theme";

const locService = Vue.prototype.$localizationService;

export default {
  name: "DriverCoachingWidget",

  components: {
    RadarChart,
    PieChart,
    ResolveCoachingDialog,
  },

  props: {
    coaching: Object,
    coachingLoading: Boolean,
    previousCoaching: Object,
    previousCoachingLoading: Boolean,
  },

  data: () => ({
    frequentChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },

    colors: {
      pink: "#E91E63",
      purple: "#9C27B0",
      blue: "#2196F3",
      green: "#4CAF50",
      orange: "#FF9800",
      brown: "#795548",
    },
  }),

  computed: {
    comparisionChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scale: {
          gridLines: {
            color: THEME.storageThemeKey === THEME_DARK_KEY
              ? "rgba(255, 255, 255, 0.5)"
              : "rgba(0, 0, 0, 0.5)",
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
          pointLabels: {
            fontColor: THEME.storageThemeKey === THEME_DARK_KEY
              ? "white"
              : "black",
          },
        },
      };
    },

    loading() {
      return this.coachingLoading || this.previousCoachingLoading;
    },

    noInfractionsSinceMode() {
      return !this.coaching && !this.coachingLoading && this.previousCoaching;
    },

    mostFrequentChartData() {
      const entries = this.mostFrequentInfractionsCriteriaEntries;

      return {
        labels: entries.map((it) => it.criteria.name),
        datasets: [
          {
            label: locService.localize(
              "coaching_page.driver_coaching_widget.most_frequent_chart_data.label.current",
            ),
            data: entries.map((it) => it.count),
            fill: true,
            backgroundColor: entries.map(
              (it, index) => Object.values(this.colors)[index],
            ),
          },
        ],
      };
    },

    comparisonChartData() {
      const entries = this.mostFrequentInfractionsCriteriaEntries;

      const previousTargetInfractions = (this.previousCoaching || {}).targetInfractions || [];

      return {
        labels: entries.map((it) => it.criteria.name),
        datasets: [
          {
            label: locService.localize(
              "coaching_page.driver_coaching_widget.comparison_chart_data.label.current",
            ),
            data: entries.map((it) => it.count),
            fill: true,
            backgroundColor: "rgba(33, 150, 243, 0.2)",
            borderColor: "rgb(33, 150, 243)",
            pointBackgroundColor: "rgb(33, 150, 243)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(33, 150, 243)",
          },
          {
            label: locService.localize(
              "coaching_page.driver_coaching_widget.comparison_chart_data.label.previous",
            ),
            data: entries.map(
              (it) => previousTargetInfractions.filter(
                (suspect) => suspect.criteria.id == it.criteria.id,
              ).length,
            ),
            fill: true,
            backgroundColor: "rgba(76, 175, 80, 0.2)",
            borderColor: "rgb(76, 175, 80)",
            pointBackgroundColor: "rgb(76, 175, 80)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(76, 175, 80)",
          },
        ],
      };
    },

    coachingHeaderFirstLine() {
      if (!this.coaching) return locService.localize("common.loading");

      if (this.coaching.status == COACHING_STATUS_NEW.value) {
        return locService.localize(
          "coaching_page.driver_coaching_widget.header.first_line.current",
        );
      }
      if (this.coaching.status == COACHING_STATUS_RESOLVED.value) {
        return locService.localize(
          "coaching_page.driver_coaching_widget.header.first_line.resolved",
        );
      }
      return locService.localize(
        "coaching_page.driver_coaching_widget.header.first_line.unsupported",
      );
    },

    coachingHeaderSecondLine() {
      if (!this.coaching) return "";

      if (this.coaching.status == COACHING_STATUS_NEW.value) {
        return locService.localize(
          "coaching_page.driver_coaching_widget.header.second_line.since",
          [moment(this.coaching.createdAt).format(dateTimeMomentFormat)],
        );
      }
      if (this.coaching.status == COACHING_STATUS_RESOLVED.value) {
        return locService.localize(
          "coaching_page.driver_coaching_widget.header.second_line.resolved",
          [moment(this.coaching.resolvedAt).format(dateTimeMomentFormat)],
        );
      }
      return "";
    },

    previousCoachingFormattedResolvedAt() {
      return this.previousCoaching
        ? moment(this.previousCoaching.resolvedAt).format(dateTimeMomentFormat)
        : "";
    },

    comparisionChartReady() {
      return (
        this.mostFrequentInfractionsCriteriaEntries.length
        && this.previousCoaching
      );
    },

    mostFrequentInfractionsCriteriaEntries() {
      if (!this.coaching) return [];

      return uniqBy(
        this.coaching.targetInfractions.map((it) => it.criteria),
        "id",
      )
        .map((cr) => ({
          criteria: cr,
          count: this.coaching.targetInfractions.filter(
            (it) => cr.id == it.criteria.id,
          ).length,
        }))
        .sort((a, b) => {
          const countDelta = b.count - a.count;

          if (countDelta != 0) return countDelta;

          if (this.previousCoaching) {
            const aCriteriaContainsInPrevious = this.previousCoaching.targetInfractions.filter(
              (it) => it.criteria.id == a.criteria.id,
            );
            const bCriteriaContainsInPrevious = this.previousCoaching.targetInfractions.filter(
              (it) => it.criteria.id == b.criteria.id,
            );

            if (aCriteriaContainsInPrevious && !bCriteriaContainsInPrevious) return -1;
            if (
              !aCriteriaContainsInPrevious
              && bCriteriaContainsInPrevious
            ) return 1;
          }
          return a.criteria.name.localeCompare(b.criteria.name);
        })
        .splice(0, 6);
    },

    coachingCanBeResolved() {
      return this.coaching
        ? this.coaching.status == COACHING_STATUS_NEW.value
        : false;
    },
  },

  methods: {
    openResolveCoachingDialog() {
      this.$refs.resolveCoachingDialog.openDialog(this.coaching);
    },
  },
};
</script>
<style lang="scss" scoped>
.comparision-row {
  min-height: 300px;
}
.comparision-chart {
  position: relative;
  height: 300px;
  max-height: 300px;
  transform: translateY(-10px);
}
.most-frequent-chart {
  position: relative;
  height: 250px;
  max-height: 250px;
}
.middle-col {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.coaching-second-line {
  opacity: 0.5;
  font-size: 0.75em;
}
.toolbar-cell-delimeter {
  height: calc(100% - 12px);
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}
.coaching-toolbar-title {
  height: 100%;
  box-sizing: border-box;
}
</style>
