<template>
  <v-dialog v-model="open" max-width="1000px" content-class="message-template-dialog">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(editedMessageTemplate.id
              ? "message_template_page.dialog.add_and_edit.edit_title"
              : "message_template_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                :rules="requiredRule"
                v-model="editedMessageTemplate.name"
                :label="
                      $localizationService.localize(
                          'message_template_page.dialog.add_and_edit.param.name'
                      )
                  "
                :disabled="loading"
                class="common-fields"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  rows="3"
                  no-resize
                  v-model="editedMessageTemplate.description"
                  :label="
                      $localizationService.localize(
                          'message_template_page.dialog.add_and_edit.param.description'
                      )
                  "
                  :disabled="loading"
                  class="common-fields"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <TemplatesWritingAssistant
                :label="
                  $localizationService.localize(
                      'message_template_page.dialog.add_and_edit.param.text'
                  )
                "
                :rules="requiredRule"
                v-model="editedMessageTemplate.text"
                :mode="TwaMode.Textarea"
                ref="templateTextEditor"
                @onHasFailedValue="assistantHasError = $event"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addOrSaveMessageTemplate"
          :disabled="saveIsDisabled"
        >
          {{ $localizationService.localize(editedMessageTemplate.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import Vue from "vue";
import { MessageTemplate } from "../../model/message-template.model";
import TemplatesWritingAssistant from "../../components/templates-writing-assistant/TemplatesWritingAssistant";
import { TwaMode } from "../../utils/ts-utils";

const locService = Vue.prototype.$localizationService;
export default {
  name: "EditMessageTemplateDialog",

  components: {
    TemplatesWritingAssistant,
  },

  data: () => ({
    TwaMode,
    open: false,
    loading: false,

    editedMessageTemplateOriginal: {},
    editedMessageTemplate: {},

    assistantHasError: false,

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

  }),

  computed: {
    saveIsDisabled() {
      return this.loading
        || !this.editedMessageTemplate.name
        || !this.editedMessageTemplate.text
        || this.assistantHasError;
    },
  },

  methods: {

    openDialog(messageTemplate) {
      this.editedMessageTemplateOriginal = messageTemplate || {};
      this.editedMessageTemplate = Object.assign(new MessageTemplate(), this.editedMessageTemplateOriginal)
        || new MessageTemplate();

      this.open = true;

      setTimeout(() => this.$refs.templateTextEditor.forceUpdate());
    },

    closeDialog() {
      this.open = false;
    },

    async addOrSaveMessageTemplate() {
      this.loading = true;
      await this.$store.dispatch(
        "messageTemplatesModule/addOrSaveMessageTemplate",
        this.editedMessageTemplate,
      );
      this.$emit("onAddOrSave");
      this.loading = false;
      this.open = false;
    },
  },
};
</script>
<style lang="scss">
.message-template-dialog.v-dialog {
  overflow-y: visible !important;
}
.common-fields {
  max-width: 420px;
}
</style>
