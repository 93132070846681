import lodash from "lodash";
import { LightweightTriggerDto, TriggerType } from "@/types/types";
import { TRIGGER_TYPES } from "@/utils/ts-utils";

export class LightweightTrigger {
  constructor(
    public id: string,
    public name: string,
    public type: TriggerType | null,
    public priority: number,
  ) {
  }

  clone() {
    return new LightweightTrigger(
      this.id,
      this.name,
      this.type,
      this.priority,
    );
  }

  static fromDto(dto: LightweightTriggerDto): LightweightTrigger {
    return new LightweightTrigger(
      dto.id,
      dto.name,
      lodash.keyBy(TRIGGER_TYPES, "value")[dto.type],
      dto.priority,
    );
  }

  static createEmpty(): LightweightTrigger {
    return new LightweightTrigger(
      "",
      "",
      null,
      0,
    );
  }
}
