<template>
  <v-btn
    v-if="!targetDevices.length && state == 'view'"
    @click="beginEdit()"
    x-small
    class="theme-text-color"
    color="secondary"
  >
    {{ $localizationService.localize("drp.device_list_label.set_btn") }}
  </v-btn>
  <div v-else-if="state == 'view'" @click="beginEdit()">
    <v-menu open-on-hover bottom nudge-bottom="40" open-delay="100" close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <div class="device-container">
            <div v-bind="attrs" v-on="on"
                 v-for="(device, index) in targetDevices"
                 :key=index>
              <span :class="getDeviceClass(device)">{{device.name}}</span>
              <span v-if="index !== targetDevices.length - 1">, </span>
            </div>
          </div>
        </template>
        <v-list class="devices-menu" dense>
          <v-list-item
              v-for="item in targetDevices"
              :key="item.id"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
            <MessageButton
                class="ml-1"
                :target="MessagingTargets.device(item.id)"
            />
          </v-list-item>
        </v-list>
    </v-menu>
  </div>
  <div v-else class="edit-container d-flex flex-row align-center">
    <DeviceSelect
      v-model="newSelectedDevices"
      :countDevicesInUse="countDevicesInUse"
      :ownersPerDevice="ownersPerDevice"
      :hostDriverEntry="hostDriverEntry"
    />
    <v-icon class="ml-2" color="green" @click="save()">mdi-check</v-icon>
    <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
  </div>
</template>

<script>
import DeviceSelect from "./DeviceSelect";
import MessageButton from "../../../MessageButton";
import { MessagingTargets } from "../../../../utils/utils";

export default {
  name: "DeviceListLabel",

  components: {
    DeviceSelect,
    MessageButton,
  },

  props: {
    targetDevices: Array,
    countDevicesInUse: Object,
    ownersPerDevice: Object,
    hostDriverEntry: Object,
  },

  data: () => ({
    MessagingTargets,
    state: "view",
    newSelectedDevices: [],
  }),

  methods: {
    beginEdit() {
      this.newSelectedDevices = [...this.targetDevices];
      this.state = "edit";
    },

    save() {
      this.state = "view";
      this.$emit("onChangeData", [...this.newSelectedDevices]);
    },

    cancel() {
      this.state = "view";
    },

    isDuplicate(device) {
      return this.countDevicesInUse[device.id] > 1;
    },
    getDeviceClass(device) {
      return this.isDuplicate(device) ? "orange--text" : "";
    },
  },
};
</script>

<style scoped lang="scss">
.device-container {
  display: flex;
  flex-wrap: wrap;
}
.edit-container {
  max-width: 300px;
}
.devices-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
