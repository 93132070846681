<template>
  <InfractionsTable />
</template>

<script>
import InfractionsTable from "../../components/coaching/InfractionsTable.vue";

export default {
  name: "CoachingInfractions",

  routing: {
    localizationKey: "coaching_page.menu.infraction_list",
    path: "/infractions",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: { InfractionsTable },
};
</script>
