import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { TemplateConstant } from "../model/template-constants.model";

const API_PATH = "/messaging/template-constants";

const locService = Vue.prototype.$localizationService;

export default {
  name: "templateConstantsModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayTemplateConstants: [],
    currentPage: {
      templateConstants: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateTemplateConstants(state, templateConstant) {
      state.currentPage.templateConstants = [...templateConstant];
      state.displayTemplateConstants = state.currentPage.templateConstants;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const templateConstantsDto = await API.get("core", API_PATH, body);
        commit("updateTemplateConstants", templateConstantsDto.map((templateConstant) => TemplateConstant.fromDto(templateConstant)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSaveTemplateConstant(context, templateConstant) {
      const body = { ...await bodyWithAuthHeader(), body: templateConstant };

      try {
        await (templateConstant.id ? API.put("core", `${API_PATH}/${templateConstant.id}`, body)
          : API.post("core", API_PATH, body));
        Vue.toasted.success(
          locService.localize(templateConstant.id ? "store.template_constant.save_template_constant_msg"
            : "store.template_constant.add_template_constant_msg", [templateConstant.name]),
          {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deleteTemplateConstant(context, templateConstant) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${templateConstant.id}`, body);
        Vue.toasted.success(locService.localize("store.template_constant.delete_template_constant_msg",
          [templateConstant.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },
  },
};
