import Vue from "vue";
import { API } from "aws-amplify";
import { FuelCard } from "../model/fuel-card.model";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";

const API_PATH = "/fuelcards";

const locService = Vue.prototype.$localizationService;

export default {
  name: "fuelCardsModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayFuelCards: [],
    currentPage: {
      fuelCards: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateFuelCards(state, fuelCardDto) {
      state.currentPage.fuelCards = [...fuelCardDto];
      state.displayFuelCards = state.currentPage.fuelCards;
    },

    addOrChangeFuelCard(state, fuelCardDto) {
      const index = state.currentPage.fuelCards.findIndex((it) => it.id === fuelCardDto.id);

      if (index !== -1) {
        state.currentPage.fuelCards.splice(index, 1, fuelCardDto);
      } else {
        state.currentPage.fuelCards.push(fuelCardDto);
        state.totalCount++;
      }
      state.displayFuelCards = state.currentPage.fuelCards;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const fuelCardsDto = await API.get("core", API_PATH, body);
        commit("updateFuelCards", fuelCardsDto.map((fuelCard) => FuelCard.fromDto(fuelCard)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSaveFuelCard({ commit }, fuelCard) {
      const body = { ...await bodyWithAuthHeader(), body: fuelCard };

      try {
        const data = await (fuelCard.id ? API.put("core", `${API_PATH}/${fuelCard.id}`, body) : API.post("core", API_PATH, body));
        const fuelCardDto = FuelCard.fromDto(data);
        commit("addOrChangeFuelCard", fuelCardDto);
        Vue.toasted.success(
          locService.localize(fuelCard.id ? "store.fuel_card.save_fuel_card_msg"
            : "store.fuel_card.add_fuel_card_msg", [fuelCard.cardNumber]),
          {
            duration: 5000,
          },
        );
        return fuelCardDto;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deleteFuelCard({ commit, dispatch, state }, fuelCard) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${fuelCard.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(locService.localize("store.fuel_card.delete_fuel_card_msg", [fuelCard.cardNumber]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredFuelCard(context, searchString) {
      if (!searchString) return [];

      const body = await bodyWithAuthHeader();

      try {
        const fuelCardsDto = await API.get("core", `/fuelcards/search?searchString=${searchString || ""}`, body);

        return fuelCardsDto.map((fuelCard) => FuelCard.fromDto(fuelCard));
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },
  },
};
