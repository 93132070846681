var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"650px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"pb-1",attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.create_coaching_dialog.title" ))+" ")]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-container',{staticClass:"pa-0 pb-2"},[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
              'coaching_page.create_coaching_dialog.param.name'
            ),"disabled":_vm.userInputDisabled},model:{value:(_vm.coaching.name),callback:function ($$v) {_vm.$set(_vm.coaching, "name", $$v)},expression:"coaching.name"}}),_c('v-textarea',{attrs:{"label":_vm.$localizationService.localize(
              'coaching_page.create_coaching_dialog.param.description'
            ),"rows":"3","disabled":_vm.userInputDisabled},model:{value:(_vm.coaching.description),callback:function ($$v) {_vm.$set(_vm.coaching, "description", $$v)},expression:"coaching.description"}}),_c('CoachingDialogInfractionsTable',{attrs:{"infractions":_vm.infractions}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_coaching_dialog.param.driver_name'
                ),"readonly":"","hide-details":""},model:{value:(_vm.coaching.targetDriver.name),callback:function ($$v) {_vm.$set(_vm.coaching.targetDriver, "name", $$v)},expression:"coaching.targetDriver.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"nudge-right":50,"transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedTargetDateTime,"label":_vm.$localizationService.localize(
                      'coaching_page.create_coaching_dialog.param.target_time'
                    ),"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.userInputDisabled,"rules":_vm.targetDateTimeRules}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.popUpMenu),callback:function ($$v) {_vm.popUpMenu=$$v},expression:"popUpMenu"}},[(_vm.popUpMenu == 'time')?_c('v-time-picker',{attrs:{"scrollable":""},on:{"click:minute":function($event){_vm.popUpMenu = false}},model:{value:(_vm.targetTimeModel),callback:function ($$v) {_vm.targetTimeModel=$$v},expression:"targetTimeModel"}}):_c('v-date-picker',{attrs:{"min":_vm.momentNow},on:{"input":function($event){_vm.popUpMenu = 'time'}},model:{value:(_vm.targetDateModel),callback:function ($$v) {_vm.targetDateModel=$$v},expression:"targetDateModel"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.userInputDisabled},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.userInputDisabled || !_vm.canCreate},on:{"click":function($event){return _vm.createCoaching()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.create"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }