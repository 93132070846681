<template>
  <v-dialog v-model="open" max-width="800px">
    <ReportMar
      pDefaultActionButtonsIsVisible
      :pCardMaxHeight="70"
      :pContentPadding="'pl-6 pr-6 pb-2'"
      @onCloseDialog="open = false"
      @onSaveDataFromDialog="save()"
      ref="reportMar"
    />
  </v-dialog>
</template>

<script>
export default {
  name: "ReportWarningsDialog",

  components: {
    ReportMar: () => import("../../mar/ReportMar.vue"),
  },

  watch: {
    open() {
      if (this.open === false) {
        this.$emit("onCloseDialog", this.targetReportResolved);
      }
    },
  },

  data: () => ({
    open: false,
    targetReportResolved: false,
  }),

  methods: {
    openDialog(report) {
      this.targetReportResolved = false;
      this.open = true;
      this.$mar.loadSubject(report.id, "report");
      setTimeout(() => this.$refs.reportMar.clean());
    },

    async save() {
      await this.$mar.sendSolutions();
      if (!this.$mar.currentSubject.requests.length) {
        await this.$store.dispatch("reportModule/reloadPage");
        this.targetReportResolved = true;
        this.open = false;
      }
    },
  },
};
</script>
