import Vue from "vue";
import { Auth, Storage, API } from "aws-amplify";
// eslint-disable-next-line camelcase,@typescript-eslint/camelcase
import { v4 as uuid_v4 } from "uuid";
import {
  bodyWithAuthHeader,
  RTL_TEMPLATE_TYPE,
  AMAZON_LOGISTICS_IMPORT_MAPPING_TYPE,
} from "../utils/utils";
import { RawTextLinesTemplate } from "../model/import-amazon-data/raw-text-lines-template.model";
import { AmazonLogisticsImportMappings } from "../model/import-amazon-data/amazon-logistics-import-mappings.model";

export default {

  name: "importAmazonDataModule",
  namespaced: true,
  state: {
    amazonFileKey: undefined,
  },
  mutations: {
    updateAmazonFileKey(state, key) {
      state.amazonFileKey = key;
    },
  },
  actions: {
    async sendData({ commit, rootState }, { importData, targetDate }) {
      const contentType = "text/plain";

      importData.id = uuid_v4();

      const key = `cognito/dsps/${(await Auth.currentSession()).getIdToken().payload.sub}/${importData.id}`;

      return Storage.put(key, importData.logisticsData, {

        contentType,
        metadata: {
          id: importData.id,
          "target-date": targetDate,
          uploader: rootState.userModule.user.id,
        },
      })
        .then(async () => {
          commit("updateAmazonFileKey", `public/${key}`);
        });
    },

    async sendTemplateSettings({ state }, { template, station }) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          binKey: state.amazonFileKey,
          importTemplateId: template.id,
          station,
        },
      };

      try {
        return await API.post("core", "/drp/amazon_logistics/begin", body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async getImportedFileParsingStatusById(context, reportId) {
      const body = await bodyWithAuthHeader();

      try {
        return await API.get("core", `/drp/amazon_logistics/status/${reportId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async loadFilteredTemplates(context, searchString) {
      const body = await bodyWithAuthHeader();

      try {
        const templatesDto = await API.get("core", `/import/template?searchString=${searchString || ""}&targetType=${RTL_TEMPLATE_TYPE}`, body);

        return templatesDto.map((template) => RawTextLinesTemplate.fromDto(template));
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async addNewTemplate(context, template) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          name: template.name,
          lines: [],
        },
      };

      try {
        const templateDto = await API.post("core", `/import/template/${RTL_TEMPLATE_TYPE}`, body);
        return RawTextLinesTemplate.fromDto(templateDto);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async updateTemplate(context, template) {
      const body = { ...await bodyWithAuthHeader(), body: template };

      try {
        const templateDto = await API.put("core", `/import/template/${template.id}`, body);
        return RawTextLinesTemplate.fromDto(templateDto);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async deleteTemplate(context, template) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `/import/template/${template.id}`, body);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
    },

    async initiationMapping(context, { reportId, modelMappingsId }) {
      const body = { ...await bodyWithAuthHeader(), body: { modelMappingsId } };

      try {
        return await API.post("core", `/drp/amazon_logistics/map/${reportId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async loadFilteredModelMappings(context, searchString) {
      const body = await bodyWithAuthHeader();

      try {
        const mappingsDto = await API.get("core",
          `/import/mappings?searchString=${searchString || ""}&targetType=${AMAZON_LOGISTICS_IMPORT_MAPPING_TYPE}`, body);
        return mappingsDto.map((mapping) => AmazonLogisticsImportMappings.fromDto(mapping));
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async addNewModelMapping(context, modelMapping) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          name: modelMapping.name,
          mappings: {},
        },
      };

      try {
        const mappingDto = await API.post("core", `/import/mappings/${AMAZON_LOGISTICS_IMPORT_MAPPING_TYPE}`, body);
        return AmazonLogisticsImportMappings.fromDto(mappingDto);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
      return undefined;
    },

    async updateModelMapping(context, modelMapping) {
      const body = { ...await bodyWithAuthHeader(), body: modelMapping };

      try {
        const mappingDto = await API.put("core", `/import/mappings/${modelMapping.id}`, body);
        return AmazonLogisticsImportMappings.fromDto(mappingDto);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
      return undefined;
    },

    async deleteModelMapping(context, modelMapping) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `/import/mappings/${modelMapping.id}`, body);
      } catch (e) {
        Vue.toasted.error(e.response.data.message, { duration: 5000 });
      }
    },

    async applyImportSession(context, { reportId, shouldReplaceDriver }) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          shouldReplaceDriver,
        },
      };

      try {
        await API.post("core", `/drp/amazon_logistics/apply/${reportId}`, body);
      } catch (e) {
        Vue.toasted.error(e.response ? e.response.data.message : e, { duration: 5000 });
      }
    },
  },
};
