import { TemplateUnitsDto, TriggerActionUnitType } from "@/types/types";
import { IssueUnitTemplate } from "@/model/trigger/action-draft-templates/issue/issue-unit-template.model";
import { CREATE_ISSUE_UNITS_AGENT_VEHICLE } from "@/utils/ts-utils";

export class VehicleIssueUnitTemplate extends IssueUnitTemplate {
  vehicleContextSource: string;

  constructor(
    type: TriggerActionUnitType,
    vehicleContextSource: string,
  ) {
    super(type);
    this.vehicleContextSource = vehicleContextSource;
  }

  toDto() {
    return {
      type: this.type?.value,
      vehicleContextSource: this.vehicleContextSource,
    };
  }

  clone(): VehicleIssueUnitTemplate {
    return new VehicleIssueUnitTemplate(
      this.type,
      this.vehicleContextSource,
    );
  }

  static fromDto(dto: TemplateUnitsDto): VehicleIssueUnitTemplate {
    return new VehicleIssueUnitTemplate(
      CREATE_ISSUE_UNITS_AGENT_VEHICLE,
      dto.vehicleContextSource || "",
    );
  }

  static createEmpty(): VehicleIssueUnitTemplate {
    return new VehicleIssueUnitTemplate(
      CREATE_ISSUE_UNITS_AGENT_VEHICLE,
      "",
    );
  }
}
