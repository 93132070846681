import { TriggerActionType } from "@/types/types";

export abstract class ActionDraftTemplate {
  constructor(
    public type: TriggerActionType,
    public order: number,
  ) {
  }

  abstract toDto(): any;

  abstract clone(): ActionDraftTemplate;
}
