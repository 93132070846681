<template>
  <v-container fluid>
    <v-row align="start" class="vehicle-action-types" justify="start">
      <v-col>
        <v-data-table
          :class="{'not-default-page-size': !this.isDefaultSize}"
          :footer-props="footerProps"
          :headers="headers"
          :items="displayVehicleActionTypes"
          :loading="pageLoading || !tableInited"
          :options="{
            page: pageIndex,
            itemsPerPage: pageSize,
            sortBy: sort.sortBy,
            sortDesc: sort.sortDesc,
           }"
          :server-items-length="totalCount"
          multi-sort
          @update:options="paginate($event)"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("vehicle_action_type_page.title") }}
                <TableSettings
                  :pDefaultButtonTooltip="
                    $localizationService.localize(
                        'vehicle_action_type_page.settings_tooltip.apply_default'
                    )
                  "
                    :pIsVisible="tableInited && !isDefaultSettings"
                    :pSaveButtonTooltip="
                      $localizationService.localize(
                        'vehicle_action_type_page.settings_tooltip.save'
                      )
                    "
                    class="ml-5"
                    @onApplyDefaultSettings="applyDefaultSettings()"
                    @onSaveSettings="saveSettings()"
                />
              </v-toolbar-title>
              <v-spacer/>
              <v-text-field
                v-model="searchString"
                :label="$localizationService.localize('vehicle_action_type_page.search')"
                class="search-field"
                hide-details
                width="200"
                @input="loadOnInputSearchString()"
              ></v-text-field>
              <v-btn class="ml-2" color="primary" @click="openEditVehicleActionTypeDialog()">
                {{ $localizationService.localize("vehicle_action_type_page.new_btn") }}
              </v-btn>
              <EditVehicleActionTypeDialog @onAddOrSave="reloadPage" ref="vehicleActionTypeDialog"/>
              <ConfirmationDialog ref="deleteDialog"/>
            </v-toolbar>
          </template>
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
              :key="header.value"
              :class="{
              'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDateAndTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" :disabled="!item.mutable" medium @click="openEditVehicleActionTypeDialog(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon color="red" :disabled="!item.mutable" medium @click="openDeleteVehicleActionTypeDialog(item)"
            >mdi-delete
            </v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import lodash from "lodash";
import tableSortParserMixin from "../../../utils/table-sort-parser";
import ConfirmationDialog from "../../ConfirmationDialog";
import TableSettings from "../../TableSettings";
import { momentDateAndTime } from "../../../utils/utils";
import EditVehicleActionTypeDialog from "./EditVehicleActionTypeDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "VehicleActionTypes",

  mixins: [tableSortParserMixin],

  components: {
    EditVehicleActionTypeDialog,
    ConfirmationDialog,
    TableSettings,
  },

  computed: {
    ...mapState("vehicleActionTypesModule", [
      "displayVehicleActionTypes",
      "totalCount",
      "currentPage",
      "pageLoading",
    ]),
    ...mapState("vehicleActionTypesTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("vehicleActionTypesTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },
  data: () => ({

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    tableInited: false,

    searchString: "",

    headers: [
      {
        text: locService.localize("vehicle_action_type_page.header.name"),
        value: "name",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_action_type_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_action_type_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("vehicle_action_type_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("vehicleActionTypesTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("vehicleActionTypesModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    openEditVehicleActionTypeDialog(vehicleActionType) {
      this.$refs.vehicleActionTypeDialog.openDialog(vehicleActionType);
    },

    openDeleteVehicleActionTypeDialog(vehicleActionType) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("vehicle_action_type_page.dialog.delete.title"),
        text: locService.localize("vehicle_action_type_page.dialog.delete.text", [
          vehicleActionType.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          await this.$store.dispatch("vehicleActionTypesModule/deleteVehicleActionType", vehicleActionType);
          this.reloadPage();
        },
      });
    },

    saveSettings() {
      this.$store.commit("vehicleActionTypesTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("vehicle_action_type_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("vehicleActionTypesTableSettingsModule/applyDefaultSettings");
    },

    reloadPage() {
      this.$store.dispatch("vehicleActionTypesModule/reloadPage");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/vehicle-action-type/?${params.toString()}`);
      }
    },

    loadOnInputSearchString: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("vehicleActionTypesModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),
  },

  created() {
    this.$store.dispatch("vehicleActionTypesTableSettingsModule/loadDefaultSettingsFromStorage");

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("vehicleActionTypesTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("vehicleActionTypesTableSettingsModule/applyQuerySettings", query);

      if (query.searchString) {
        this.searchString = query.searchString;
      }
    }
  },

};
</script>

<style lang="scss">
.vehicle-action-types {
  .search-field {
    max-width: 250px;
  }

  .v-data-table-header-mobile {
    display: none;
  }
}
</style>
