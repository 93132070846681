<template>
  <v-container fluid class="pa-0">
    <v-data-table
      :class="{'not-default-page-size': !this.isDefaultSize}"
      :footer-props="footerProps"
      :headers="headers"
      :items="displayUsers"
      :loading="pageLoading || !userTableInited"
      :search="searchString"
      :options="{
        page: pageIndex,
        itemsPerPage: pageSize,
        sortBy: sort.sortBy,
        sortDesc: sort.sortDesc,
       }"
      multi-sort
      @update:options="paginate($event, searchString)"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        <span
          :key="header.value"
          :class="{'not-default-header-sort': isNotDefaultSortItems.includes(header.value)}"
        >
          {{ header.text }}
        </span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ USER_TYPES_BY_VALUE[item.type].text }}
      </template>
      <template v-slot:[`item.blocked`]="{ item }">
        <div class="red--text font-weight-bold" v-if="item.blocked">
          {{
            item.blocked && $localizationService.localize("user_page.blocked")
          }}
        </div>
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ momentDateAndTime(item.updatedAt) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="openEditUserDialog(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <EditUserDialog ref="userDialog"/>
  </v-container>
</template>

<script>

import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import tableSortParserMixin from "../../utils/table-sort-parser";
import { momentDateAndTime, USER_TYPES_BY_VALUE } from "../../utils/utils";
import EditUserDialog from "./EditUserDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Users",

  props: {
    searchString: String,
  },

  mixins: [tableSortParserMixin],

  components: {
    EditUserDialog,
  },

  computed: {
    ...mapState("userModule", [
      "user",
      "displayUsers",
      "pageLoading",
      "userTableInited",
    ]),
    ...mapState("usersTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("usersTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },
  data: () => ({
    USER_TYPES_BY_VALUE,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    pageInfo: {},

    headers: [
      {
        text: locService.localize("user_page.header.name"),
        value: "name",
        sortable: true,
      },
      {
        text: locService.localize("user_page.header.email"),
        value: "email",
        sortable: true,
      },
      {
        text: locService.localize("user_page.header.type"),
        value: "type",
        sortable: true,
      },
      {
        text: locService.localize("user_page.header.selected_phone_agent"),
        value: "selectedPhoneAgent.name",
        sortable: true,
      },
      {
        text: locService.localize("user_page.header.blocked"),
        value: "blocked",
        sortable: true,
      },
      {
        text: locService.localize("user_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("user_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),

  watch: {
    searchString() {
      this.paginate(null, this.searchString);
    },
  },

  methods: {
    momentDateAndTime,

    paginate(data, searchString) {
      if (!this.userTableInited) {
        this.loadPage();
      }
      if (data) {
        this.pageInfo = {
          index: data.page,
          size: data.itemsPerPage,
          sort: {
            sortBy: [...data.sortBy],
            sortDesc: [...data.sortDesc],
          },
        };
        this.$store.commit("usersTableSettingsModule/changeSettings", this.pageInfo);
      }

      this.pageInfo.searchString = searchString;

      this.$emit("onUpdatePage", this.pageInfo);
    },

    async loadPage() {
      await this.$store.dispatch("userModule/loadPage");
    },

    saveSettings() {
      this.$store.commit("usersTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("user_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("usersTableSettingsModule/applyDefaultSettings");
    },

    openEditUserDialog(user) {
      this.$refs.userDialog.openDialog(user);
    },
  },

  created() {
    this.$store.dispatch("usersTableSettingsModule/loadDefaultSettingsFromStorage");

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("usersTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("usersTableSettingsModule/applyQuerySettings", query);
    }
  },

};
</script>

<style lang="scss">
.users {
  .search-field {
    max-width: 250px;
  }

  .v-data-table-header-mobile {
    display: none;
  }
}
</style>
