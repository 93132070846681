<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            editedDriver.id
              ? $localizationService.localize(
                  "driver_page.dialog.add_and_edit.edit_title"
                )
              : $localizationService.localize(
                  "driver_page.dialog.add_and_edit.new_title"
                )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container v-if="open">
          <v-row>
            <v-text-field
              v-model="editedDriver.name"
              :label="
                $localizationService.localize(
                  'driver_page.dialog.add_and_edit.param.name'
                ) + '*'
              "
              :rules="nameRules"
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedDriver.transporterId"
              :label="
                $localizationService.localize(
                  'driver_page.dialog.add_and_edit.param.transporter_id'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row class="row-combobox">
            <v-combobox
              v-model="editedDriver.aliases"
              :label="
                $localizationService.localize(
                  'driver_page.dialog.add_and_edit.param.aliases'
                )
              "
              :disabled="loading"
              append-icon=""
              chips
              multiple
              deletable-chips
              :delimiters="[',']"
            >
            </v-combobox>
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedDriver.email"
              :label="
                $localizationService.localize(
                  'driver_page.dialog.add_and_edit.param.email'
                )
              "
              :rules="emailRules"
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedDriver.phone"
              :label="
                $localizationService.localize(
                  'driver_page.dialog.add_and_edit.param.phone'
                )
              "
              :rules="phoneRules"
              :disabled="loading"
            ></v-text-field>
            <div>Please enter number in international format.<br>Example: +15168887766</div>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false" :disabled="loading">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addDriver"
          :disabled="!saveButtonActive"
        >
          {{
            editedDriver.id
              ? $localizationService.localize("btn.save")
              : $localizationService.localize("btn.add")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { EMAIL_REGEX } from "@/utils/utils";
import PhoneNumberValidator from "../../utils/phone-number-validator";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CreateDriverDialog",

  data: () => ({
    editedDriverOriginal: {},
    editedDriver: {},

    open: false,
    loading: false,

    nameRules: [(value) => /^(?!\s*$).+/g.test(value) || "Required"],

    emailRules: [
      (value) => !value
        || EMAIL_REGEX.test(value)
        || locService.localize("error.validation.valid_email"),
    ],

    phoneRules: [
      (value) => !value
        || PhoneNumberValidator.isValid(value)
        || locService.localize("error.validation.valid_phone"),
    ],
  }),

  computed: {
    ...mapState("driverModule", ["processingDriver"]),
    saveButtonActive() {
      if (this.loading) return false;

      if (
        this.nameRules.find(
          (rule) => rule(this.editedDriver.name) !== true || !this.editedDriver.name,
        )
        || this.emailRules.find(
          (rule) => rule(this.editedDriver.email) !== true,
        )
        || this.phoneRules.find((rule) => rule(this.editedDriver.phone) !== true)
      ) {
        return false;
      }

      return Object.keys(this.editedDriver).find(
        (key) => this.editedDriverOriginal[key] != this.editedDriver[key],
      );
    },
  },

  methods: {
    openDialog(driver, originalDriver) {
      this.editedDriverOriginal = originalDriver || driver || {};
      this.editedDriver = { ...driver || {} };
      this.open = true;
    },

    async addDriver() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch(
          "driverModule/addOrSaveDriver",
          this.editedDriver,
        );
        this.$emit("editedDriver", result);
        Vue.toasted.success(
          this.editedDriver.id
            ? locService.localize("toasted.successfully_upd", [result.name])
            : locService.localize("toasted.successfully_add", [result.name]),
          {
            duration: 5000,
          },
        );
        this.open = false;
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.row-combobox {
  margin-top: 0 !important;
}
</style>
