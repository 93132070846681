<template>
  <v-dialog v-model="open" max-width="600px">
    <v-card :loading="loading" class="create-rescuer-card">
      <v-card-title>
        {{$localizationService.localize('drp.rescuers_table.dialog.create.title')}}
      </v-card-title>

      <v-card-text class="d-flex">
        <v-row>
          <v-col class="d-flex align-end">
            <v-autocomplete
              :label="$localizationService.localize('drp.rescuers_table.dialog.create.param.select_route')"
              v-model="selectedRow"
              :items="routes"
              item-text="row.route"
              item-value="row"
              :disabled="loading"
              @change="selectedRescuee = undefined"
            >
              <template v-slot:item="{ item }">
                <div class="align-center">
                  {{item.row.route}}
                  <p class="fs-12px mb-0 red--text">
                    {{getDescriptions(item.row)}}
                  </p>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="$localizationService.localize('drp.rescuers_table.dialog.create.param.select_rescuee')"
              v-model="selectedRescuee"
              :items="rescuees"
              :disabled="loading || !selectedRow"
              item-text="driverEntry"
              item-value="driverEntry"
              return-object
            >
              <template v-slot:selection="{ item }">
                <DriverEntryLabel
                  :targetDriverEntry="item.driverEntry"
                  :isDuplicate="item.driverEntry.isWillBeDuplicate"
                  :additionalDataPerDriver="
                    pAdditionalDataPerDriver[item.driverEntry.driver.id] || {}
                  "
                />
              </template>
              <template v-slot:item="{ item }">
                <div>
                  <DriverEntryLabel
                    class="mt-2 mb-2"
                    visibleOptionalParams
                    :targetDriverEntry="item.driverEntry"
                    :isDuplicate="item.driverEntry.isWillBeDuplicate"
                    :additionalDataPerDriver="
                      pAdditionalDataPerDriver[item.driverEntry.driver.id] || {}
                    "
                  />
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false" :disabled="loading">
          {{$localizationService.localize('btn.cancel')}}
        </v-btn>

        <v-btn
          color="primary"
          text
          :disabled="loading || !selectedRescuee || !selectedRow"
          @click="createRescue()"
        >
          {{$localizationService.localize('btn.add')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CreateRescueDialog",

  components: {
    DriverEntryLabel: () => import("../drp-common/entry/DriverEntryLabel.vue"),
  },

  props: {
    pAdditionalDataPerDriver: Object,
    pRoutePlan: Object,
  },

  data: () => ({
    open: false,
    loading: false,

    selectedRescuee: undefined,
    selectedRow: undefined,
  }),

  computed: {
    routes() {
      return this.pRoutePlan.rows.filter((row) => row.route).map((row) => ({
        row,
        disabled: !row.driverEntry && row.allRescuers.every((it) => !it.driverEntry),
      }));
    },

    rescuees() {
      if (!this.selectedRow) return [];

      return [this.selectedRow, ...this.selectedRow.allRescuers].filter((it) => it.driverEntry);
    },
  },

  methods: {
    openDialog() {
      this.selectedRescuee = undefined;
      this.selectedRow = undefined;
      this.open = true;
    },

    getDescriptions(row) {
      return [row, ...row.allRescuers].every((it) => !it.driverEntry)
        ? locService.localize("drp.rescuers_table.dialog.create.no_drivers_on_route")
        : undefined;
    },

    async createRescue() {
      this.loading = true;
      await this.$store.dispatch("dailyRoutePlannerModule/createRescue", this.selectedRescuee);
      this.loading = false;
      this.open = false;
    },
  },
};
</script>

<style lang="scss">
.create-rescuer-card {
  .v-text-field__details {
    display: none !important;
  }
}
</style>
