import { ActionDraftTemplate } from "@/model/trigger/action-draft-templates/action-draft-templates.model";
import {
  ActionDraftTemplateDto, TriggerActionDraftUnit, TriggerActionType,
} from "@/types/types";
import { IssueUnitTemplate } from "@/model/trigger/action-draft-templates/issue/issue-unit-template.model";
import { TRIGGER_ACTION_CREATE_ISSUE } from "@/utils/ts-utils";
import { VehicleIssueUnitTemplate } from "@/model/trigger/action-draft-templates/issue/units/vehicle-issue-unit-template.model";

const unitClasses = {
  [TriggerActionDraftUnit.RelatedVehicleDataUnit]: VehicleIssueUnitTemplate,
};

export class IssueActionDraftTemplate extends ActionDraftTemplate {
  issueTitle: string;

  issueDescription: string;

  issueEssence: string;

  units: IssueUnitTemplate[];

  constructor(
    type: TriggerActionType,
    order: number,
    issueTitle: string,
    issueDescription: string,
    issueEssence: string,
    units: IssueUnitTemplate[],
  ) {
    super(type, order);
    this.issueTitle = issueTitle;
    this.issueDescription = issueDescription;
    this.issueEssence = issueEssence;
    this.units = units;
  }

  toDto() {
    return {
      type: this.type?.value,
      order: this.order,
      issueTitle: this.issueTitle,
      issueDescription: this.issueDescription,
      issueEssence: this.issueEssence,
      units: this.units.map((it) => it.toDto()),
    };
  }

  clone(): IssueActionDraftTemplate {
    return new IssueActionDraftTemplate(
      this.type,
      this.order,
      this.issueTitle,
      this.issueDescription,
      this.issueEssence,
      [...this.units],
    );
  }

  static fromDto(dto: ActionDraftTemplateDto): IssueActionDraftTemplate {
    console.log(unitClasses);
    return new IssueActionDraftTemplate(
      TRIGGER_ACTION_CREATE_ISSUE,
      dto.order || 0,
      dto.issueTitle || "",
      dto.issueDescription || "",
      dto.issueEssence || "",
      (dto.units || []).map((it) => unitClasses[it.type].fromDto(it)),
    );
  }

  static createEmpty() {
    return new IssueActionDraftTemplate(
      TRIGGER_ACTION_CREATE_ISSUE,
      0,
      "",
      "",
      "",
      [],
    );
  }
}
