var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"drp-entries-table",class:{ 'border-top-color': _vm.filterPanelIsOpened },attrs:{"headers":_vm.displayHeaders,"items":_vm.displayDrivers,"loading":_vm.pageLoading,"multi-sort":"","hide-default-footer":"","disable-pagination":"","options":{
      sortBy: _vm.driversSort.sortBy,
      sortDesc: _vm.driversSort.sortDesc,
    }},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
          'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
        }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('DriverEntryStatus',{attrs:{"targetDriverEntry":item},on:{"onChangeData":function($event){item.note = $event.newNote;
          item.status = $event.selectedStatus;
          _vm.saveData(item)}}})]}},{key:"item.reserve",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"small-checkbox",on:{"input":function($event){return _vm.saveData(item)}},model:{value:(item.reserve),callback:function ($$v) {_vm.$set(item, "reserve", $$v)},expression:"item.reserve"}})]}},{key:"item.driver",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('DriverEntryLabel',{attrs:{"targetDriverEntry":item,"additionalDataPerDriver":_vm.additionalDataPerDriver[item.driver.id] || {}}}),_c('MessageButton',{attrs:{"target":_vm.MessagingTargets.driver(item.driver.id)}})],1)]}},{key:"item.note",fn:function(ref){
          var item = ref.item;
return [_c('NoteLabel',{attrs:{"targetEntry":item},on:{"onChangeData":function($event){item.note = $event;
          _vm.saveData(item);}}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteEntryDialog(item)}}},[_vm._v("mdi-delete ")])]}}],null,true)}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }