<template>
  <VehicleProfile />
</template>

<script>
import VehicleProfile from "@/components/vehicles/vehicle-profile/VehicleProfile.vue";

export default {
  name: "VehicleProfilePage",
  routing: {
    path: "/vehicles/:id",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    VehicleProfile,
  },
};
</script>

<style scoped>

</style>
