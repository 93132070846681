<template>
   <div
     v-if="chatIsVisible"
     class="rocket-chat-container"
     :class="{'active': chatOpened}"
     :style="chatStyle"
   >
     <div class="chat-action-container mr-3 ">
       <v-badge
         color="red"
         overlap
         :content="unreadMessagesCount"
         :value="unreadMessagesCount"
       >
         <v-btn
           :loading="loading"
           class="chat-action-btn white"
           :style="actionBtnStyle"
           @click="!chatOpened && authed ? onOpenChat() : onCloseChat()"
         >
           <v-icon class="chat-icon-color">{{
             !chatOpened ? "mdi-message-text-outline" : "mdi-close"
           }}</v-icon>
         </v-btn>
       </v-badge>
     </div>
     <div class="chat-action-container secondary-action mr-3 ">
       <v-btn
           :loading="loading"
           class="chat-action-btn white"
           :style="actionBtnStyle"
           @click="openBulkMessageDialog()"
       >
         <v-icon v-if="!sending" class="chat-icon-color">mdi-account-multiple</v-icon>
         <v-progress-circular
             class="progress-circular"
             v-if="sending"
             :rotate="-90"
             :value="sentAsPercentage"
             :style="progressStyle"
             :size="28"
             width="3"
         >
           {{ this.sentCount }}
         </v-progress-circular>
       </v-btn>
       <BulkMessageDialog ref="bulkMessageDialog" />
     </div>
     <div class="send-template">
       <v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">
           <v-icon
               v-bind="attrs"
               v-on="on"
               dense
               light
               :disabled="!recipientId"
               class="template-btn"
               :class="{'loading' : loadingDirectChannel}"
               @click="openSendMessageTemplateDialog()"
           >
             mdi-message-text-outline
           </v-icon>
         </template>
         <span>{{ $localizationService.localize("rocket_chat.send_template") }}</span>
       </v-tooltip>
       <SendMessageTemplateDialog ref="sendMessageTemplateDialog"/>
     </div>
     <iframe
       v-if="token && userId"
       ref="messaging"
       :src="ROCKET_CHAT_API"
       title="myframe"
     />
     <RocketChatSettings v-if="chatOpened"/>
     <SelectedRabbit/>
     <PhoneCall/>
     <div class="chat-action-container select-phone-agent mr-3 " v-if="chatOpened">
       <SelectPhoneAgentButton/>
     </div>
   </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import { IS_MOBILE, ROCKET_CHAT_API } from "../utils/utils";
import { THEME } from "../app-theme";
import SendMessageTemplateDialog from "@/components/message-templates/SendMessageTemplateDialog";
import RocketChatSettings from "@/components/RocketChatSettings";
import SelectedRabbit from "@/components/messaging/SelectedRabbit";
import PhoneCall from "@/components/phone-calls/PhoneCall";
import SelectPhoneAgentButton from "@/components/phone-agents/SelectPhoneAgentButton";

const EVENT_STARTUP = "startup";
const EVENT_CUSTOM_SCRIPT_LOGGED_IN = "Custom_Script_Logged_In";
const EVENT_UNREAD_CHANGED = "unread-changed";
const EVENT_NEW_MESSAGE = "new-message";
const EVENT_ROOM_OPENED = "room-opened";
const EVENT_NOTIFICATION = "notification";
const EVENT_MENU_STATE_UPDATE = "menu-state-update";
const EVENT_UNREAD_CHANGED_BY_SUBSCRIPTION = "unread-changed-by-subscription";

const locService = Vue.prototype.$localizationService;

export default {
  name: "RocketChat",

  components: {
    SendMessageTemplateDialog,
    SelectedRabbit,
    SelectPhoneAgentButton,
    PhoneCall,
    RocketChatSettings,
    BulkMessageDialog: () => import("./messaging/BulkMessageDialog"),
  },

  data: () => ({
    ROCKET_CHAT_API,

    loading: true,
    settingsIsVisible: false,
  }),

  computed: {
    ...mapState("rocketChatModule", [
      "token",
      "authed",
      "userId",
      "channels",
      "chatOpened",
      "handledEventIds",
      "selectedChannel",
      "menuOpened",
      "loadingDirectChannel",
    ]),
    ...mapState("userModule", ["user"]),
    ...mapState("rocketChatSettingsModule", ["userMessagingSettings"]),
    ...mapState("bulkMessage", ["sending"]),
    ...mapState("bulkMessage", ["sentCount"]),
    ...mapGetters("rocketChatModule", ["unreadMessagesCount", "recipientId"]),
    ...mapGetters("bulkMessage", ["sentAsPercentage"]),

    chatIsVisible() {
      return this.user;
    },

    chatStyle() {
      const { bar, top } = this.$vuetify.application;

      return {
        width: IS_MOBILE ? "100vw" : "30vw",
        height: IS_MOBILE ? `calc(100vh - ${(top + bar) * 2}px)` : "75vh",
        bottom: IS_MOBILE ? `calc(-100vh + ${(top + bar) * 2}px)` : "-75vh",
        border: `2px solid ${THEME.themeInverseColor} !important`,
      };
    },

    actionBtnStyle() {
      return {
        borderTop: `2px solid ${THEME.themeInverseColor} !important`,
        borderRight: `2px solid ${THEME.themeInverseColor} !important`,
        borderLeft: `2px solid ${THEME.themeInverseColor} !important`,
      };
    },
    settingsStyle() {
      return {
        borderTop: `2px solid ${THEME.themeInverseColor} !important`,
        borderBottom: `2px solid ${THEME.themeInverseColor} !important`,
        borderLeft: "2px solid #a2a2a2 !important",
        borderRight: "0px !important",
      };
    },
    btnSettingsStyle() {
      return {
        borderTop: `2px solid ${THEME.themeInverseColor} !important`,
        borderBottom: `2px solid ${THEME.themeInverseColor} !important`,
        borderLeft: `2px solid ${THEME.themeInverseColor} !important`,
        height: "64px",
        minWidth: "36px",
        maxWidth: "36px",
      };
    },
    progressStyle() {
      return {
        color: "limegreen !important",
      };
    },
  },

  methods: {

    openBulkMessageDialog() {
      this.$refs.bulkMessageDialog.openDialog();
    },

    onCloseChat() {
      this.$store.commit("rocketChatModule/changeChatOpenedState", false);
      this.goToHome();
    },

    onOpenChat() {
      this.$store.commit("rocketChatModule/changeChatOpenedState", true);
      if (this.selectedChannel) {
        this.goToChannel(this.selectedChannel);
      }
    },

    async authenticateUser(eventData) {
      const eventName = eventData.eventName;

      if (eventName === EVENT_STARTUP && this.$refs.messaging) {
        await this.$store.commit("rocketChatModule/initIframe", this.$refs.messaging);
      }

      if (eventName === EVENT_CUSTOM_SCRIPT_LOGGED_IN) {
        await this.loadChannelsUnreadMessages();
        const globalChannel = this.channels.find((it) => it.name.startsWith("global"));
        if (!globalChannel) {
          Vue.toasted.error(locService.localize("rocket_chat.not_found_global_chat"));
        }
        this.$store.commit("rocketChatModule/setSelectedChannel", globalChannel);
        this.goToHome();
        this.$store.commit("rocketChatModule/endAuth");
        this.loading = false;
      }
    },

    async loadChannelsUnreadMessages() {
      await this.$store.dispatch("rocketChatModule/loadChannelsUnreadMessages");
    },

    goToChannel(channel) {
      this.$store.dispatch("rocketChatModule/goToChannel", channel.name);
      this.clearUnreadMessagesForChannel(channel);
    },

    goToHome() {
      this.$store.dispatch("rocketChatModule/goToHome");
    },

    clearAllUnreadMessages() {
      this.$store.commit("rocketChatModule/clearAllUnreadMessages");
    },

    clearUnreadMessagesForChannel(channel) {
      channel.unread = 0;
    },

    async updateChannelsData(eventData) {
      const eventName = eventData.eventName;

      // init channels
      if (
        eventData.data.t === "p" // p - groups
        && !this.channels.some((it) => it.rid === eventData.data.rid)
        && eventName === EVENT_UNREAD_CHANGED_BY_SUBSCRIPTION
      ) {
        this.$store.commit("rocketChatModule/addNewChannel", {
          channel: {
            rid: eventData.data.rid,
            name: eventData.data.name,
            alert: eventData.data.alert,
            unread: 0,
          },
          eventId: eventData.data._id,
        });
      }

      if (eventName === EVENT_NOTIFICATION) {
        this.findAndUpdateChannel(
          eventData.data.notification.payload.rid,
          eventData.data.notification.payload._id,
        );
      }

      if (
        typeof eventData.data === "object"
        && (eventData.data.u || {})._id !== this.userId
        && eventName === EVENT_NEW_MESSAGE
      ) {
        this.findAndUpdateChannel(eventData.data.rid, eventData.data._id);
      }

      if (
        typeof eventData.data === "object"
          && (eventData.data.u || {})._id === this.userId
          && eventName === EVENT_NEW_MESSAGE && this.userMessagingSettings.automaticallySendSms
          && typeof eventData.data.reactions === "object"
          && ":send_sms:" in eventData.data.reactions
          && !this.handledEventIds.includes(eventData.data._id)
      ) {
        this.$store.commit("rocketChatModule/updateHandledEventIds", eventData.data._id);
        await this.$store.dispatch("rocketChatModule/postReaction", {
          messageId: eventData.data._id,
          emoji: "send_sms",
          shouldReact: true,
        });
      }

      // if no unread messages
      if (eventData.data === "" && eventName === EVENT_UNREAD_CHANGED) {
        this.clearAllUnreadMessages();
      }
    },

    findAndUpdateChannel(roomId, eventId) {
      const foundChannel = this.channels.find((it) => it.rid === roomId);

      if (!foundChannel || this.handledEventIds.includes(eventId)) {
        return;
      }

      if (foundChannel.rid !== this.selectedChannel.rid || !this.chatOpened) {
        foundChannel.unread++;
      }

      this.$store.commit("rocketChatModule/updateHandledEventIds", eventId);
    },

    async changeSelectedChannel(eventData) {
      const foundChannel = this.channels.find((it) => it.rid === eventData.data._id);
      if (foundChannel) {
        this.$store.commit("rocketChatModule/setSelectedChannel", foundChannel);
        await this.$store.dispatch("rocketChatModule/getDirectMessagingChannel", foundChannel.rid);
        this.clearUnreadMessagesForChannel(this.selectedChannel);
      }
    },

    openSendMessageTemplateDialog() {
      this.$refs.sendMessageTemplateDialog.openDialog();
    },

    isEventOnUpdateChannels(eventName) {
      return [
        EVENT_UNREAD_CHANGED,
        EVENT_NEW_MESSAGE,
        EVENT_UNREAD_CHANGED_BY_SUBSCRIPTION,
        EVENT_NOTIFICATION,
      ].includes(eventName);
    },

    installChatListener() {
      window.addEventListener("message", async (e) => {
        const eventName = e.data.eventName;
        if (eventName === EVENT_ROOM_OPENED) {
          this.changeSelectedChannel(e.data);
        }

        if (eventName === EVENT_MENU_STATE_UPDATE) {
          const menuIsOpened = e.data.data.menuIsOpened;
          setTimeout(() => this.$store.commit("rocketChatModule/updateMenuOpenedState", menuIsOpened),
            !menuIsOpened ? 100 : 0);
        }

        if (this.isEventOnUpdateChannels(eventName)) {
          await this.updateChannelsData(e.data);
        }

        if (this.authed) return;

        await this.authenticateUser(e.data);
      });
    },
  },

  async mounted() {
    if (!this.user || !this.user.isCooked()) return;

    await this.$store.dispatch("rocketChatModule/loadUserData");

    this.installChatListener();
  },
};
</script>

<style lang="scss">
  .rocket-chat-container {
    position: fixed;
    right: 0;
    min-width: 500px;
    transition: .2s;
    z-index: 105;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    &.active {
      bottom: 0 !important;
    }

    iframe {
      height: 100%;
      width: 100%;
    }

    .chat-action-container {
      position: absolute;
      top: -37px;
      right: 0;
      .chat-action-btn {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none !important;
        color: var(--v-greyColor-base);

        .chat-icon-color {
          color: rgba(0, 0, 0, 0.7) !important;
        }
      }

      &.secondary-action {

        right: 90px;
      }

      &.select-phone-agent {
        right: 180px;
      }
    }
    .v-badge__wrapper {
      span {
        inset: auto auto calc(100% - 15px) calc(100% - 15px) !important;
      }
    }
  }

@media (max-width: 500px) {
  .rocket-chat-container {
    min-width: auto;
  }
}
  .progress-circular {
    bottom: 1px;
  }
  .send-template {
    position: absolute;
    top: 20px;
    right: 50px;
  }
  .template-btn {
    background: none !important;
    height: 28px !important;
    width: 28px !important;
    min-width: 28px !important;
    border-radius: 2px !important;
    color: #2f343d !important;
    box-shadow: none !important;
    &.loading {
      animation-name: blink;
      animation-timing-function: linear;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
    &:hover {
      background-color: #e5e7ea !important;
    }
    &:after {
      background-color: #e5e7ea !important;
      border-radius: 2px !important;
    }
    @keyframes blink {
      50% {
        opacity: 0;
      }
    }
  }
</style>
