<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline"
          >{{
            editedVehicle.id
              ? $localizationService.localize(
                  "vehicle_page.dialog.add_and_edit.edit_title"
                )
              : $localizationService.localize(
                  "vehicle_page.dialog.add_and_edit.new_title"
                )
          }}
        </span>
      </v-card-title>

      <v-card-text class="max-height-content">
        <v-container v-if="open">
          <v-row>
            <v-text-field
              v-model="editedVehicle.name"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.name'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              :rules="vinRules"
              v-model="editedVehicle.vin"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.vin'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
              v-model="editedVehicle.type"
              :items="VEHICLE_TYPES"
              item-text="text"
              item-value="value"
              return-value
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.vehicle_type'
                )
              "
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="editedVehicle.status"
              :items="VEHICLE_STATUSES"
              item-text="text"
              item-value="value"
              return-value
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.status'
                )
              "
              :disabled="loading"
              @change="onChangeVehicleStatus($event)"
            >
            </v-select>
          </v-row>
          <v-row v-if="VEHICLE_OUT_OF_SERVICE_STATUSES.some((it) => it.value === editedVehicle.status)">
            <v-autocomplete
                :label="$localizationService.localize('vehicle_page.dialog.add_and_edit.param.vehicle_shop')"
                :items="matchedVehicleShops"
                item-text="name"
                return-object
                clear-icon="mdi-delete"
                clearable
                v-model="editedVehicle.vehicleShop"
                :search-input.sync="searchVehicleShop"
                :loading="loadingVehicleShops"
                :disabled="loading"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $localizationService.localize("vehicle_shop_page.address", [item.address]) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:append-outer>
                <v-icon @click="openEditVehicleShopDialog">mdi-car-wrench</v-icon>
              </template>
            </v-autocomplete>
            <EditVehicleShopDialog @onAddOrSave="applyCreatedVehicleShop($event)" ref="vehicleShopDialog" />
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedVehicle.make"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.make'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedVehicle.model"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.model'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-autocomplete
              :label="$localizationService.localize('vehicle_page.dialog.add_and_edit.param.fuel_card')"
              :items="matchedFuelCards"
              item-text="cardNumber"
              :filter="filterObject"
              return-object
              clear-icon="mdi-delete"
              clearable
              v-model="editedVehicle.fuelCard"
              ref="inputName"
              :search-input.sync="searchFuelCard"
              :loading="loadingFuelCard"
              :disabled="loading"
              :placeholder="$localizationService.localize('common.begin_typing')"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{item.cardNumber}}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $localizationService.localize("vehicle_page.card_id", [item.cardId]) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:append-outer>
                <v-icon @click="openEditFuelCardDialog">mdi-credit-card</v-icon>
              </template>
            </v-autocomplete>
            <EditFuelCardDialog @onAddOrSaveCard="applyCreatedCardNumber" ref="fuelCardsDialog" />
          </v-row>
          <v-row>
            <v-text-field
              v-model="editedVehicle.licensePlate"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.license_plate'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <!-- v-show="editedVehicle.type == VEHICLE_TYPE_RENTAL.value" -->
          <v-row>
            <v-menu
              v-model="rentalExpiryMenu"
              :close-on-content-click="false"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedVehicle.rentalExpiry"
                  :label="
                    $localizationService.localize(
                      'vehicle_page.dialog.add_and_edit.param.rental_expiry'
                    )
                  "
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editedVehicle.rentalExpiry"
                no-title
                @input="rentalExpiryMenu = false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="rentalExpiryMenu = false">
                  {{ $localizationService.localize("btn.cancel") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-row>
          <!-- v-show="editedVehicle.type == VEHICLE_TYPE_RENTAL.value" -->
          <v-row>
            <v-text-field
              v-model="editedVehicle.rentalProvider"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.rental_provider'
                )
              "
              :disabled="loading"
            ></v-text-field>
          </v-row>
          <!-- // TODO: remove clearable attr -->
          <!-- v-show="editedVehicle.type == VEHICLE_TYPE_BRANDED.value" -->
          <v-row>
            <v-select
              v-model="editedVehicle.fleetManagementCompany"
              :items="VEHICLE_FLEET_MANAGEMENT_COMPANY"
              item-text="text"
              item-value="value"
              return-value
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.fleet_management_company'
                )
              "
              :disabled="loading"
              clearable
            >
            </v-select>
          </v-row>
          <v-row>
            <v-textarea
              :disabled="loading"
              v-model="editedVehicle.notes"
              :label="
                $localizationService.localize(
                  'vehicle_page.dialog.add_and_edit.param.notes'
                )
              "
            ></v-textarea>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false" :disabled="loading">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addVehicle"
          :disabled="!saveButtonActive"
        >
          {{
            editedVehicle.id
              ? $localizationService.localize("btn.save")
              : $localizationService.localize("btn.add")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import debounce from "lodash/debounce";
import {
  VEHICLE_TYPES,
  VEHICLE_TYPE_RENTAL,
  VEHICLE_TYPE_BRANDED,
  VEHICLE_TYPE_OWNED,
  VEHICLE_STATUSES,
  VEHICLE_FLEET_MANAGEMENT_COMPANY,
  VEHICLE_OUT_OF_SERVICE_STATUSES,
} from "../../utils/utils";
import { Vehicle } from "../../model/vehicle.model";
import EditFuelCardDialog from "../../components/fuel-cards/EditFuelCardDialog";
import EditVehicleShopDialog from "../vehicle-shops/EditVehicleShopDialog";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditVehiclesDialog",

  data: () => ({
    VEHICLE_TYPE_RENTAL,
    VEHICLE_TYPE_BRANDED,
    VEHICLE_TYPE_OWNED,
    VEHICLE_STATUSES,
    VEHICLE_TYPES,
    VEHICLE_FLEET_MANAGEMENT_COMPANY,
    VEHICLE_OUT_OF_SERVICE_STATUSES,

    searchFuelCard: null,
    searchVehicleShop: null,
    loadingFuelCard: false,
    loadingVehicleShops: false,
    open: false,
    loading: false,
    rentalExpiryMenu: false,

    matchedFuelCards: [],
    matchedVehicleShops: [],

    editedVehicleOriginal: {},
    editedVehicle: {},

    vinRules: [
      (value) => /^(?!\s*$).+/g.test(value)
        || locService.localize("error.validation.required"),
    ],
  }),

  watch: {
    searchFuelCard() {
      // when the user selects a fuel card
      // the search string changes and the req sending again
      if ((this.editedVehicle.fuelCard || {}).cardNumber === this.searchFuelCard) {
        return;
      }

      if (this.searchFuelCard) {
        this.loadFilteredFuelCard();
      }
    },
  },

  components: {
    EditVehicleShopDialog,
    EditFuelCardDialog,
  },

  computed: {
    saveButtonActive() {
      return !this.loading && !this.vinRules.find(
        (rule) => rule(this.editedVehicle.vin) !== true || !this.editedVehicle.vin,
      );
    },
  },
  methods: {

    openEditFuelCardDialog() {
      this.$refs.fuelCardsDialog.openDialog();
    },

    openEditVehicleShopDialog() {
      this.$refs.vehicleShopDialog.openDialog();
    },

    openDialog(vehicle) {
      this.editedVehicleOriginal = vehicle || {};
      this.editedVehicle = { ...this.editedVehicleOriginal };

      if (!this.editedVehicle.id) {
        this.editedVehicle = Vehicle.createNew(vehicle);
      }

      this.matchedFuelCards = vehicle && vehicle.fuelCard ? [vehicle.fuelCard] : [];

      this.open = true;
    },

    async addVehicle() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch(
          "vehiclesModule/addOrSaveVehicle",
          this.editedVehicle,
        );
        this.$emit("editedVehicle", result);
        Vue.toasted.success(
          this.editedVehicle.id
            ? locService.localize("toasted.successfully_upd", [
              result.name || result.vin,
            ])
            : locService.localize("toasted.successfully_add", [
              result.name || result.vin,
            ]),
          {
            duration: 5000,
          },
        );
        this.open = false;
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }
      this.loading = false;
    },

    loadFilteredFuelCard: debounce(function () {
      this.loadingFuelCard = true;

      this.$store.dispatch("fuelCardsModule/loadFilteredFuelCard", this.searchFuelCard)
        .then((data) => {
          this.matchedFuelCards = data;
          this.loadingFuelCard = false;
        });
    }, 300),

    filterObject(item) {
      return (item.cardNumber || item.cardId);
    },

    applyCreatedCardNumber(fuelCard) {
      this.matchedFuelCards = [fuelCard];
      this.editedVehicle.fuelCard = fuelCard;
    },

    applyCreatedVehicleShop(vehicleShop) {
      this.matchedVehicleShops.push(vehicleShop);
      this.editedVehicle.vehicleShop = vehicleShop;
    },

    onChangeVehicleStatus(status) {
      if (!VEHICLE_OUT_OF_SERVICE_STATUSES.some((it) => it.value === status)) {
        this.editedVehicle.vehicleShop = null;
      }
    },
  },

  async mounted() {
    this.matchedVehicleShops = await this.$store.dispatch("vehicleShopsModule/loadAllVehicleShops");
  },
};
</script>
<style scoped>
.max-height-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
