







































import {
  Component, Emit, Prop, Vue,
} from "vue-property-decorator";
import AddRelatedVehicle from "@/components/issues/issue-card/data-unit-agents/AddRelatedVehicle.vue";
import { Vehicle } from "@/model/vehicle.model";
import { IssueDataUnitVehicle } from "@/model/issue/units/issue-data-unit-vehicle.model";
import { Issue } from "@/model/issue/issue.model";

const locService = Vue.prototype.$localizationService;

@Component({
  components: { AddRelatedVehicle },
})
export default class RelatedVehicleDataUnitAgent extends Vue {
  static agentName = "RelatedVehicleDataUnitAgent"

  locService = locService;

  @Prop()
  dataUnits!: IssueDataUnitVehicle[]

  @Prop()
  issue!: Issue;

  @Emit("onAddUnit")
  emitToAddSelectedVehicle(vehicle: Vehicle) {
    return IssueDataUnitVehicle.createNew(vehicle);
  }

  @Emit("onRemoveDataUnit")
  emitToRemoveSelectedVehicle(unit: IssueDataUnitVehicle) {
    return unit;
  }
}
