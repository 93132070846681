import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { Reminder } from "../model/reminder/reminder.model";

const API_PATH = "/vehicles/vehicle-reminder";

const locService = Vue.prototype.$localizationService;

export default {
  name: "vehicleRemindersModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayVehicleReminders: [],
    currentPage: {
      vehicleReminders: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicleReminders(state, vehicleReminderDto) {
      state.currentPage.vehicleReminders = [...vehicleReminderDto];
      state.displayVehicleReminders = state.currentPage.vehicleReminders;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const vehicleRemindersDto = await API.get("core", API_PATH, body);
        commit("updateVehicleReminders", vehicleRemindersDto.map((vehicleReminder) => Reminder.fromDto(vehicleReminder)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSaveVehicleReminder(context, data) {
      try {
        await (data.vehicleReminder.id ? API.put("core", `${API_PATH}/${data.vehicleReminder.id}`,
          { ...await bodyWithAuthHeader(), body: Reminder.toUpdateDto(data.vehicleReminder) })
          : API.post("core", API_PATH, { ...await bodyWithAuthHeader(), body: Reminder.toDto(data.vehicleReminder, data.reminderMetaData) }));
        Vue.toasted.success(
          locService.localize(data.vehicleReminder.id ? "store.vehicle_reminder.save_vehicle_reminder_msg"
            : "store.vehicle_reminder.add_vehicle_reminder_msg", [data.vehicleReminder.name]),
          {
            duration: 5000,
          },
        );
      } catch (e) {
        handleHttpError(e);
      }
    },

    async deleteVehicleReminder(context, vehicleReminder) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicleReminder.id}`, body);
        Vue.toasted.success(locService.localize("store.vehicle_reminder.delete_vehicle_reminder_msg",
          [vehicleReminder.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredVehicleReminders(context, searchString) {
      if (!searchString) return [];

      try {
        return await API.get("core", `${API_PATH}/search?searchString=${searchString}`,
          await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },

    async loadAllVehicleReminders() {
      try {
        return await API.get("core", `${API_PATH}`, await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },
  },
};
