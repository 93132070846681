import {
  Action, Module, Mutation, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
import { API } from "aws-amplify";
import { store } from "@/main";
import { TriggerDto } from "@/types/types";
import { Trigger } from "@/model/trigger/trigger.model";

import { bodyWithAuthHeader, handleHttpError, localizationService } from "@/utils/utils";

const moduleName = "triggersModule";

const API_PATH = "/trigger";

/*
const dtoTmp: TriggerDto = {
  id: "4b1h2kj3h412nklj412bn3k4",
  type: TriggerTypes.VehicleChanged,
  name: "Test trigger 2",
  priority: 0,
  toValueCondition: "{\"thresholdValue\":\"trigger trigger\",\"operator\":\"EQUAL_TO\",\"property\":\"name\"}",
  actionDraftTemplates: [
    {
      type: TriggerActionDraft.CreateIssueActionDraftTemplate,
      issueTitle: "'Lol test isssue for vehicle:' + vehicle.name",
      issueDescription: "'Test description'",
      order: 0,
      units: [
        {
          type: TriggerActionDraftUnit.RelatedVehicleIssueDataUnitTemplate,
          // eslint-disable-next-line no-template-curly-in-string
          vehicleContextSource: "${vehicle.name}",
        },
      ],
    },
  ],
};
*/
@Module({
  name: moduleName, namespaced: true, store,
})
export default class TriggersModule extends VuexModule {
  static get namespace() {
    return namespace(moduleName);
  }

  displayTriggers: Trigger<any>[] = [];

  currentPageLoading = false;

  currentPage = {
    page: 0,
    pageSize: 25,
    sort: [],
    searchString: "",
    triggers: [] as Trigger<any>[],
  };

  totalCount = 0;

  loadingModels = false;

  currentModels: any;

  @Mutation
  startLoadingPage(currentPage: any) {
    this.currentPageLoading = true;
    this.currentPage = currentPage;
  }

  @Mutation
  endLoadingPage() {
    this.currentPageLoading = false;
  }

  @Mutation
  updateCurrentPageTriggers(dto: TriggerDto[]) {
    this.currentPage.triggers = dto.map((it) => Trigger.fromDto(it));
    this.displayTriggers = this.currentPage.triggers;
  }

  @Mutation
  updateTotalCount(newValue: number) {
    this.totalCount = newValue;
  }

  @Mutation
  beginLoadingModels() {
    this.loadingModels = true;
  }

  @Mutation
  setTriggerModels(newModels: any) {
    this.currentModels = newModels;
  }

  @Mutation
  endLoadingModels() {
    this.loadingModels = false;
  }

  @Action({ rawError: true })
  async reloadPage() {
    await this.loadPage(this.currentPage);
  }

  @Action({ rawError: true })
  async loadPage(currentPage: any): Promise<void> {
    this.startLoadingPage(currentPage);
    await this.loadTotalCount();

    const body = {
      ...await bodyWithAuthHeader(),
      queryStringParameters: {
        page: currentPage.index,
        pageSize: currentPage.size,
        sort: currentPage.sort.join("+"),
        searchString: currentPage.searchString,
      },
    };

    try {
      const triggersDto = await API.get("core", API_PATH, body);
      this.updateCurrentPageTriggers(triggersDto);
    } catch (e) {
      console.error(e);
      handleHttpError(e);
    }

    this.endLoadingPage();
  }

  @Action({ rawError: true })
  // eslint-disable-next-line consistent-return
  async loadAllTriggers(): Promise<any> {
    const body = {
      ...await bodyWithAuthHeader(),
    };

    try {
      return await API.get("core", API_PATH, body);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async loadTotalCount() {
    const body = {
      ...await bodyWithAuthHeader(),
      queryStringParameters: {
        searchString: this.currentPage.searchString,
      },
    };

    try {
      const count = await API.get("core", `${API_PATH}/count`, body);
      this.updateTotalCount(count);
    } catch (e) {
      handleHttpError(e);
    }
  }

  @Action({ rawError: true })
  async addOrSaveTrigger(trigger: Trigger<any>): Promise<Trigger<any> | undefined> {
    const body = { ...await bodyWithAuthHeader(), body: trigger.toDto() };

    try {
      const triggerDto = await (trigger.id
        ? API.put("core", API_PATH, body)
        : API.post("core", API_PATH, body)
      );

      if (!trigger.id) {
        this.updateTotalCount(this.totalCount + 1);
      }

      this.reloadPage();

      return Trigger.fromDto(triggerDto);
    } catch (e: any) {
      handleHttpError(e);
    }
    return undefined;
  }

  @Action({ rawError: true })
  async deleteTrigger(trigger: Trigger<any>) {
    const body = { ...await bodyWithAuthHeader(), body: trigger.toDto() };

    try {
      await API.del("core", `${API_PATH}/${trigger.id}`, body);

      this.reloadPage();

      return localizationService.localize("store.trigger.delete_trigger_msg", [trigger.name]);
    } catch (e: any) {
      handleHttpError(e);
    }

    return undefined;
  }

  @Action({ rawError: true })
  async loadTriggerModelsIfNeeded() {
    if (this.currentModels || this.loadingModels) return;

    // const body = await bodyWithAuthHeader();

    this.beginLoadingModels();

    try {
      // const models = await API.get("core", "/report/models", body);

      // localizationService.injectLocalizedNames(models);
      //
      // this.setTriggerModels(models);
    } catch (e: any) {
      // handleHttpError(e);
    }
    this.endLoadingModels();
  }
}
