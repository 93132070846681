var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"issue-table-display",class:{'not-default-page-size': !this.isDefaultSize},attrs:{"headers":_vm.headers,"items":_vm.displayIssues,"loading":_vm.currentPageLoading || !_vm.tableInited,"server-items-length":_vm.totalCount,"options":{
          page: _vm.pageIndex,
          itemsPerPage: _vm.pageSize,
          sortBy: _vm.sort.sortBy,
          sortDesc: _vm.sort.sortDesc,
        },"footer-props":_vm.footerProps,"multi-sort":"","item-class":_vm.getIssueBackgroundColor},on:{"update:options":function($event){return _vm.paginate($event)},"click:row":_vm.openIssueCardDialog},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("issue_page.title"))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize('issue_page.settings_tooltip.save'),"pDefaultButtonTooltip":_vm.$localizationService.localize('issue_page.settings_tooltip.apply_default')},on:{"onSaveSettings":function($event){return _vm.saveSettings()},"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()}}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize('issue_page.search'),"hide-details":""},on:{"input":function($event){return _vm.onInputSearchString()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openIssueCardDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("issue_page.new_btn"))+" ")]),_c('IssueCardDialog',{ref:"issueCardDialog",on:{"onCloseDialog":_vm.reloadPage}}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
              'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"content-max-width"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status)?_c('div',{class:item.status.color},[_vm._v(" "+_vm._s(item.status.text)+" ")]):_vm._e()]}},{key:"item.severity",fn:function(ref){
            var item = ref.item;
return [(item.severity)?_c('div',{class:item.severity.color},[_vm._v(" "+_vm._s(item.severity.text)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.essence",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"content-max-width"},[_vm._v(" "+_vm._s(item.essence)+" ")])]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"content-max-width"},[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }