var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{class:{'not-default-page-size': !this.isDefaultSize},attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.displayUsers,"loading":_vm.pageLoading || !_vm.userTableInited,"search":_vm.searchString,"options":{
      page: _vm.pageIndex,
      itemsPerPage: _vm.pageSize,
      sortBy: _vm.sort.sortBy,
      sortDesc: _vm.sort.sortDesc,
     },"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event, _vm.searchString)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)}},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.type",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.USER_TYPES_BY_VALUE[item.type].text)+" ")]}},{key:"item.blocked",fn:function(ref){
     var item = ref.item;
return [(item.blocked)?_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" "+_vm._s(item.blocked && _vm.$localizationService.localize("user_page.blocked"))+" ")]):_vm._e()]}},{key:"item.updatedAt",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
     var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openEditUserDialog(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)}),_c('EditUserDialog',{ref:"userDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }