import {
  IssueDataUnitType,
  IssueSeverity,
  IssueStatus,
  IssueUnitType,
  TriggerActionDraft,
  TriggerActionDraftUnit,
  TriggerActionType,
  TriggerActionUnitType,
  TriggerType,
  TriggerTypes,
} from "@/types/types";
import { localizationService } from "@/utils/utils";
import { Unit } from "@/model/reminder/united-duration.model";

export const ISSUES_STATUS_OPEN: IssueStatus = {
  text: localizationService.localize("issues_status.open"),
  value: "OPEN",
  color: "red--text",
  background: "red",
};
export const ISSUES_STATUS_RESOLVED: IssueStatus = {
  text: localizationService.localize("issues_status.resolved"),
  background: "green",
  value: "RESOLVED",
  color: "green--text",
};

export const ISSUE_STATUSES = [
  ISSUES_STATUS_OPEN,
  ISSUES_STATUS_RESOLVED,
];

export const ISSUES_SEVERITY_CRITICAL: IssueSeverity = {
  text: localizationService.localize("issues_severity.critical"),
  value: "CRITICAL",
  color: "red--text",
  background: "red",
};
export const ISSUES_SEVERITY_WARNING: IssueSeverity = {
  text: localizationService.localize("issues_severity.warning"),
  background: "orange",
  value: "WARNING",
  color: "orange--text",
};

export const ISSUE_SEVERITIES = [
  ISSUES_SEVERITY_CRITICAL,
  ISSUES_SEVERITY_WARNING,
];

export const AUTHOR_AGENT_NAME = "AuthorAgent";
export const USER_AUTHOR_AGENT_NAME = `${IssueDataUnitType.UserAuthorDataUnit}Agent`;
export const TRIGGER_AUTHOR_AGENT_NAME = `${IssueDataUnitType.TriggerAuthorDataUnit}Agent`;
export const REMINDER_AUTHOR_AGENT_NAME = `${IssueDataUnitType.ReminderAuthorDataUnit}Agent`;

export const RELATED_VEHICLE_AGENT_NAME = `${IssueDataUnitType.RelatedVehicleDataUnit}Agent`;

export const ISSUE_DATA_USER_UNIT_TYPE: IssueUnitType = {
  type: IssueDataUnitType.UserAuthorDataUnit,
  mainAgentName: AUTHOR_AGENT_NAME,
  subAgentName: USER_AUTHOR_AGENT_NAME,
};

export const ISSUE_DATA_REMINDER_UNIT_TYPE: IssueUnitType = {
  type: IssueDataUnitType.ReminderAuthorDataUnit,
  mainAgentName: AUTHOR_AGENT_NAME,
  subAgentName: REMINDER_AUTHOR_AGENT_NAME,
};
export const ISSUE_DATA_TRIGGER_UNIT_TYPE: IssueUnitType = {
  type: IssueDataUnitType.TriggerAuthorDataUnit,
  mainAgentName: AUTHOR_AGENT_NAME,
  subAgentName: TRIGGER_AUTHOR_AGENT_NAME,
};
export const ISSUE_DATA_VEHICLE_UNIT_TYPE: IssueUnitType = {
  type: IssueDataUnitType.RelatedVehicleDataUnit,
  mainAgentName: RELATED_VEHICLE_AGENT_NAME,
};

export const ISSUE_DATA_UNIT_TYPES: IssueUnitType[] = [
  ISSUE_DATA_REMINDER_UNIT_TYPE,
  ISSUE_DATA_USER_UNIT_TYPE,
  ISSUE_DATA_TRIGGER_UNIT_TYPE,
  ISSUE_DATA_VEHICLE_UNIT_TYPE,
];

export const getAbbreviation = (str: string) => (str ? (str.match(/\b(\w)/g) || []).join("") : "");

export const CREATE_ISSUE_UNITS_AGENT_VEHICLE: TriggerActionUnitType = {
  text: localizationService.localize("trigger_page.action_type.create_issue.unit.related_vehicle_issue"),
  value: TriggerActionDraftUnit.RelatedVehicleDataUnit,
  agentName: `${TriggerActionDraftUnit.RelatedVehicleDataUnit}TemplateAgent`,
};

export const CREATE_ISSUE_UNITS_AGENTS: TriggerActionUnitType[] = [
  CREATE_ISSUE_UNITS_AGENT_VEHICLE,
];

export const TRIGGER_ACTION_CREATE_ISSUE: TriggerActionType = {
  text: localizationService.localize("trigger_page.action_type.create_issue"),
  value: TriggerActionDraft.CreateIssueActionDraftTemplate,
  agentName: `${TriggerActionDraft.CreateIssueActionDraftTemplate}Agent`,
  agents: CREATE_ISSUE_UNITS_AGENTS,
};

export const TRIGGER_ACTION_CLOSE_ISSUE: TriggerActionType = {
  text: localizationService.localize("trigger_page.action_type.close_issue"),
  value: TriggerActionDraft.CloseIssueActionDraftTemplate,
  agentName: `${TriggerActionDraft.CloseIssueActionDraftTemplate}Agent`,
};

export const TRIGGER_ACTION_TYPES: TriggerActionType[] = [
  TRIGGER_ACTION_CREATE_ISSUE, TRIGGER_ACTION_CLOSE_ISSUE,
];

export const TRIGGER_TYPES: TriggerType[] = [
  {
    text: localizationService.localize("trigger_page.type.vehicle_changed"),
    value: TriggerTypes.VehicleChangedTrigger,
    agentName: `${TriggerTypes.VehicleChangedTrigger}Agent`,
  },
];

export enum TwaMode {
  TextField,
  Textarea
}

export const convertFromMilliseconds = (ms: number, format: Unit) => {
  switch (format) {
    case Unit.DAY.valueOf():
      return Math.floor(ms / (1000 * 60 * 60 * 24));
    case Unit.WEEK.valueOf():
      return Math.floor(ms / (1000 * 60 * 60 * 24 * 7));
    case Unit.YEAR.valueOf():
      return Math.floor(ms / (1000 * 60 * 60 * 24 * 365));
    default: return 0;
  }
};

export const convertToMilliseconds = (value: number, format: Unit) => {
  switch (format) {
    case Unit.DAY:
      return Math.floor(value * 1000 * 60 * 60 * 24);
    case Unit.WEEK:
      return Math.floor(value * 1000 * 60 * 60 * 24 * 7);
    case Unit.YEAR:
      return Math.floor(value * 1000 * 60 * 60 * 24 * 365);
    default: return 0;
  }
};
