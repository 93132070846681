
















































import {
  Component, Emit, Prop, Vue,
} from "vue-property-decorator";
import { IssueActionDraftTemplate } from "@/model/trigger/action-draft-templates/issue/issue-action-draft-template.model";
import TriggerAgentsModule from "@/store/trigger-agents.store";
import { CREATE_ISSUE_UNITS_AGENTS, TwaMode } from "@/utils/ts-utils";
import TemplatesWritingAssistant from "@/components/templates-writing-assistant/TemplatesWritingAssistant.vue";
import { TriggerActionUnitType } from "@/types/types";
import { IssueUnitTemplate } from "@/model/trigger/action-draft-templates/issue/issue-unit-template.model";
import { AssistantConstant } from "@/model/assistant-constant.model";

const triggerAgentsModule = TriggerAgentsModule.namespace;

const locService = Vue.prototype.$localizationService;

console.log("CREATE_ISSUE_UNITS_AGENTSCREATE_ISSUE_UNITS_AGENTSCREATE_ISSUE_UNITS_AGENTSCREATE_ISSUE_UNITS_AGENTS");
console.log(CREATE_ISSUE_UNITS_AGENTS);

@Component({
  components: {
    TemplatesWritingAssistant,
  },
})
export default class CreateIssueActionDraftTemplateAgent extends Vue {
  static agentName = "CreateIssueActionDraftTemplateAgent";

  locService = locService

  TwaMode = TwaMode;

  CREATE_ISSUE_UNITS_AGENTS = CREATE_ISSUE_UNITS_AGENTS;

  // TODO: bind to backend
  specificConstants = (() => {
    const vehicle = new AssistantConstant(
      "vehicle",
      "Changed vehicle",
      undefined,
    );

    return ["name", "vin", "type", "make", "model", "licensePlate", "rentalProvider", "fleetManagementCompany", "notes", "status"]
      .map((it: string) => new AssistantConstant(
        it,
        `${it.replaceAll("_", " ")} of changed vehicle`,
        vehicle,
      ));
  })()

  issueActionTemplate: IssueActionDraftTemplate | null = null;

  @Prop()
  actionDraftTemplate!: IssueActionDraftTemplate | null;

  @triggerAgentsModule.State
  issueUnitAgents!: any;

  get actionTemplate() {
    return this.issueActionTemplate;
  }

  get disableSave() {
    return !this.issueActionTemplate?.issueTitle;
  }

  @Emit("onGetDisableSave")
  emitDisableSave() {
    return this.disableSave;
  }

  getUnitDataByType(type: TriggerActionUnitType) {
    if (!this.issueActionTemplate) return undefined;

    const index = this.issueActionTemplate.units.findIndex((unit) => unit.type === type);

    return {
      index,
      unit: this.issueActionTemplate.units[index ?? this.issueActionTemplate.units.length],
    };
  }

  addUnit(unit: IssueUnitTemplate) {
    this.issueActionTemplate?.units.push(unit);
  }

  updatedUnit(data: { index: number; unit: IssueUnitTemplate }) {
    this.issueActionTemplate?.units.splice(data.index, 1, data.unit);
  }

  removeUnit(index: number) {
    this.issueActionTemplate?.units.splice(index, 1);
  }

  mounted() {
    if (!this.actionDraftTemplate) {
      this.issueActionTemplate = IssueActionDraftTemplate.createEmpty();
    } else {
      this.issueActionTemplate = this.actionDraftTemplate;
    }
    this.emitDisableSave();
  }
}
