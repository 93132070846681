<template>
  <VehicleActions />
</template>

<script>
import VehicleActions from "../components/reminders/vehicle-actions/VehicleActions";

export default {
  name: "VehicleActionsPage",
  components: { VehicleActions },

  routing: {
    localizationKey: "menu.tab.vehicle_actions",
    icon: "mdi-car-speed-limiter",
    path: "/vehicle-action",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>

<style scoped>

</style>
