<template>
  <v-text-field
    :label="label"
    :dense="!label"
    v-model="newRoute"
    :class="routeClass"
    error-count="0"
    hide-details
    @keydown.enter="$emit('submit')"
    @keydown.esc="$emit('cancel')"
    autofocus
  >
  </v-text-field>
</template>

<script>
export default {
  name: "RouteSelect",

  props: {
    routesUsesCount: Object,
    value: String,
    label: String,
  },

  data: () => ({
    newRoute: "",
  }),

  watch: {
    newRoute() {
      this.$emit("input", this.newRoute);
    },
    value() {
      this.newRoute = this.value;
    },
  },

  computed: {
    routeClass() {
      return this.newRoute !== this.value
        && this.routesUsesCount[this.newRoute] >= 1
        ? "route-label-edit-orange--text"
        : "";
    },
  },

  mounted() {
    this.newRoute = this.value;
  },
};
</script>

<style lang="scss">
.route-label-edit-orange--text {
  input {
    color: #ff9800 !important; //orange--text
  }
}
</style>
