import lodash from "lodash";
import {
  defaultDateFormat,
  REPORT_PROCESS_STATUS_CLASSIFIED,
  REPORT_PROCESS_STATUS_RECEIVED,
  REPORT_STATUS_DATA_NOT_FOUND,
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  REPORT_STATUS_PARSED,
  REPORT_TYPES_BY_VALUE,
} from "../utils/utils";
import {
  CALENDAR_DISPLAY_STATUSES,
} from "../utils/report-calendar";

export class CalendarChip {
  get color() {
    if (this.isParsed) return "green";
    if (this.isMar) return "orange";
    return "red";
  }

  get hasError() {
    return this.isMar || this.isError;
  }

  get status() {
    if (this.reports.some((it) => it.status === REPORT_STATUS_MANUAL_ACTION_REQUIRED.value)) {
      return CALENDAR_DISPLAY_STATUSES[REPORT_STATUS_MANUAL_ACTION_REQUIRED.value];
    }

    if (this.reports.some((it) => it.status === REPORT_STATUS_DATA_NOT_FOUND.value)) {
      return CALENDAR_DISPLAY_STATUSES[REPORT_STATUS_DATA_NOT_FOUND.value];
    }

    return CALENDAR_DISPLAY_STATUSES[this.reports[0].status];
  }

  get inProcess() {
    return this.status.value === REPORT_PROCESS_STATUS_RECEIVED.value
      || this.status.value === REPORT_PROCESS_STATUS_CLASSIFIED.value;
  }

  get isParsed() {
    return this.status.value === REPORT_STATUS_PARSED.value;
  }

  get isMar() {
    return this.status.value === REPORT_STATUS_MANUAL_ACTION_REQUIRED.value;
  }

  get isError() {
    return this.status.value === REPORT_STATUS_DATA_NOT_FOUND.value;
  }

  get hasAction() {
    return this.isMar || this.isError;
  }

  get startDate() {
    return this.reports[0].startDate.format(defaultDateFormat);
  }

  get endDate() {
    return this.reports[0].targetDate;
  }

  get type() {
    return REPORT_TYPES_BY_VALUE[this.reports[0].type];
  }

  constructor(reports) {
    this.reports = reports;
  }

  static toModel(reports) {
    return new CalendarChip(reports);
  }

  static getChips(reports) {
    const filteredReports = reports.filter((it) => (
      CALENDAR_DISPLAY_STATUSES[it.status]
      && REPORT_TYPES_BY_VALUE[it.type]
    ));

    return Object.values(lodash.groupBy(filteredReports, (it) => it.type + it.targetDate))
      .map((it) => this.toModel(it));
  }
}
