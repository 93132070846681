var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.open)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( _vm.editedTemplateConstant.id ? "template_constant_page.dialog.add_and_edit.edit_title" : "template_constant_page.dialog.add_and_edit.new_title" ))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.nameRule,"label":_vm.$localizationService.localize(
                        'template_constant_page.dialog.add_and_edit.param.name'
                    ),"disabled":_vm.loading},model:{value:(_vm.editedTemplateConstant.name),callback:function ($$v) {_vm.$set(_vm.editedTemplateConstant, "name", $$v)},expression:"editedTemplateConstant.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.requiredRule,"label":_vm.$localizationService.localize(
                        'template_constant_page.dialog.add_and_edit.param.value'
                    ),"disabled":_vm.loading},model:{value:(_vm.editedTemplateConstant.value),callback:function ($$v) {_vm.$set(_vm.editedTemplateConstant, "value", $$v)},expression:"editedTemplateConstant.value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"rows":"3","no-resize":"","label":_vm.$localizationService.localize(
                        'template_constant_page.dialog.add_and_edit.param.description'
                    ),"disabled":_vm.loading},model:{value:(_vm.editedTemplateConstant.description),callback:function ($$v) {_vm.$set(_vm.editedTemplateConstant, "description", $$v)},expression:"editedTemplateConstant.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.loading
            || !_vm.editedTemplateConstant.name
            || !_vm.editedTemplateConstant.value},on:{"click":_vm.addOrSaveTemplateConstant}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize(_vm.editedTemplateConstant.id ? "btn.save" : "btn.add"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }