var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.open)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_action_page.dialog.submit.title"))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('VehicleTargetSelection',{staticClass:"mb-6",attrs:{"label":_vm.$localizationService.localize('vehicle_inspection_assign_dialog.target_vehicles'),"target-vehicles":_vm.vehicleAction.vehicle.id ? [_vm.vehicleAction.vehicle] : [],"disabled":_vm.disableVehicleSelection,"is-required-groups":false,"clearable":true},on:{"input":function($event){return _vm.onVehicleSelect($event)}}}),_c('v-select',{attrs:{"rules":_vm.requiredRule,"items":_vm.types,"item-text":"name","item-value":"id","return-object":"","label":_vm.$localizationService.localize(
                      'vehicle_action_page.dialog.submit.param.action_type'
                  ),"disabled":_vm.loading},model:{value:(_vm.vehicleAction.type),callback:function ($$v) {_vm.$set(_vm.vehicleAction, "type", $$v)},expression:"vehicleAction.type"}}),_c('v-text-field',{attrs:{"rules":_vm.requiredRule,"type":"number","label":_vm.$localizationService.localize(
                      'vehicle_action_page.dialog.submit.param.mileage'
                  ),"disabled":_vm.loading},model:{value:(_vm.vehicleAction.doneAtMeterValue),callback:function ($$v) {_vm.$set(_vm.vehicleAction, "doneAtMeterValue", $$v)},expression:"vehicleAction.doneAtMeterValue"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.requiredRule,"value":_vm.displayDoneAt,"label":_vm.$localizationService.localize(
                        'vehicle_action_page.dialog.submit.param.date'
                    ),"disabled":_vm.loading,"readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,3263467183),model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('v-date-picker',{on:{"input":function($event){_vm.isMenuOpen = false}},model:{value:(_vm.doneAt),callback:function ($$v) {_vm.doneAt=$$v},expression:"doneAt"}})],1),_c('v-textarea',{attrs:{"label":_vm.$localizationService.localize(
                    'vehicle_action_page.dialog.submit.param.comment'
                ),"disabled":_vm.loading,"rows":"3"},model:{value:(_vm.vehicleAction.comment),callback:function ($$v) {_vm.$set(_vm.vehicleAction, "comment", $$v)},expression:"vehicleAction.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.saveIsDisabled},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.submit"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }