import { API } from "aws-amplify";
import Vue from "vue";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { VehicleInspection } from "../model/inspection/vehicle-inspection.model";

const API_PATH = "/inspection";

const locService = Vue.prototype.$localizationService;

export default {
  name: "vehicleInspectionModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayVehicleInspections: [],
    currentPage: {
      vehicleInspections: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicleInspections(state, vehicleInspectionDto) {
      state.currentPage.vehicleInspections = [...vehicleInspectionDto];
      state.displayVehicleInspections = state.currentPage.vehicleInspections;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
          filter: currentPage.filter,
        },
      };

      try {
        const vehicleInspectionsDto = await API.get("core", API_PATH, body);
        commit("updateVehicleInspections", vehicleInspectionsDto.map(
          (vehicleInspection) => VehicleInspection.fromDto(vehicleInspection),
        ));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
          filter: state.currentPage.filter,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async deleteVehicleInspection(context, vehicleInspection) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicleInspection.id}`, body);
        Vue.toasted.success(locService.localize("store.vehicle_inspection.delete_vehicle_inspection_msg"), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

  },
};
