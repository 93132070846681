import lodash from "lodash";
import { Vehicle } from "../vehicle.model";
import { Device } from "../device.model";
import { DailyRouteRescueBonus } from "./rescue-bonus.model";
import { Rescuable } from "./rescuable.model";
import { DRP_RESCUE_STATUSES, RESCUE_STATUS_PLANNED } from "../../utils/utils";
import { getMnt, updateMnt } from "../../utils/drp-mnt";

export class DailyRouteRescue extends Rescuable {
  id;

  target;

  get route() {
    return this.target.route;
  }

  rescuer;

  set driverEntry(value) {
    this.rescuer = value;
  }

  get driverEntry() {
    return this.rescuer;
  }

  get rescueCreationApiPath() {
    return `/drp/rescue/${this.id}/rescue`;
  }

  vehicle;

  devices;

  status;

  stops;

  packages;

  bonus;

  version;

  constructor(
    id,
    target,
    rescuer,
    vehicle,
    devices,
    status,
    stops,
    packages,
    bonus,
    version,
  ) {
    super([]);
    this.id = id;
    this.target = target;
    this.rescuer = rescuer;
    this.vehicle = vehicle;
    this.devices = devices;
    this.status = status;
    this.stops = stops;
    this.packages = packages;
    this.bonus = bonus;
    this.version = version;
  }

  get driverEntriesAndVehicles() {
    return lodash.flatten([
      {
        driver: (this.driverEntry || {}).driver,
        vehicle: this.vehicle,
      },
      lodash.flatten(this.rescuers.map((it) => it.driverEntriesAndVehicles)),
    ]);
  }

  get rescuersNestingIndexes() {
    return this.target.rescuersNestingIndexes + 1;
  }

  static getRescueRowColor(nestingIndex) {
    const value = nestingIndex + 1;
    return {
      backgroundColor: `rgba(255, 152, 0, 0.${value < 10 ? value : 9}) !important`,
      borderColor: "#ff9800 !important",
    };
  }

  static fromDto(dto, target, driverEntriesMap) {
    const drpRescue = new DailyRouteRescue(
      dto.id,
      target,
      driverEntriesMap[(dto.rescuer || {}).id],
      dto.vehicle ? Vehicle.fromDto(dto.vehicle) : undefined,
      dto.devices.map((device) => Device.fromDto(device)),
      DRP_RESCUE_STATUSES[dto.status],
      dto.stops,
      dto.packages,
      DailyRouteRescueBonus.fromDto(dto.bonus),
      dto.version,
    );

    drpRescue.rescuers = dto.rescuers.map((rescuer) => DailyRouteRescue.fromDto(rescuer, drpRescue, driverEntriesMap));
    return drpRescue;
  }

  get mnt() {
    return getMnt(this.rescuer, this.vehicle);
  }

  set mnt(value) {
    updateMnt(value, this.rescuer, this.vehicle);
  }
}

export class DailyRouteRescueDto {
  rescuer;

  vehicle;

  devices;

  status;

  stops;

  packages;

  bonus;

  version;

  constructor(
    rescuer,
    vehicle,
    devices,
    status,
    stops,
    packages,
    bonus,
    version,
  ) {
    this.rescuer = rescuer;
    this.vehicle = vehicle;
    this.devices = devices;
    this.status = status;
    this.stops = stops;
    this.packages = packages;
    this.bonus = bonus;
    this.version = version;
  }

  static toDto(rescue) {
    return new DailyRouteRescueDto(
      (rescue.rescuer || {}).id,
      (rescue.vehicle || {}).id,
      rescue.devices.map((device) => device.id),
      rescue.status.value,
      rescue.stops,
      rescue.packages,
      rescue.bonus,
      rescue.version,
    );
  }

  static createDefault() {
    return new DailyRouteRescueDto(
      null,
      null,
      [],
      RESCUE_STATUS_PLANNED.value,
      0,
      0,
      {
        active: false,
      },
      0,
    );
  }
}
