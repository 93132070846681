var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"resolved-coaching-infractions-table-display",attrs:{"headers":_vm.headers,"items":_vm.displayInfractions,"loading":_vm.coachingLoading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.resolved_coaching_infractions_table.title" ))+" ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize(
                'coaching_page.resolved_coaching_infractions_table.search'
              ),"hide-details":"","width":"200"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.targetDate",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDate(item.targetDate.date))+" ")]}},{key:"item.drivers",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"drivers-list-string"},[_vm._l((_vm.getInfractionDriverView(item.drivers)),function(driver){return [_c('router-link',{key:("link-" + (driver.id)),staticClass:"driver-name",attrs:{"to":("/infractions/driver/" + (driver.id))}},[_vm._v(_vm._s(driver.name))]),_c('span',{key:("spacer-" + (driver.id)),staticClass:"driver-names-delimeter"},[_vm._v(", ")])]})],2)]}},{key:"item.vehicles",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionVehicleView(item.vehicles))+" ")]}},{key:"item.criteria",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.criteria.name)+" ")]}},{key:"item.detectedValue",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.detectedValue)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }