









import { Component, Prop, Vue } from "vue-property-decorator";
import { IssueDataUnitTrigger } from "@/model/issue/units/issue-data-unit-trigger.model";

@Component({})
export default class TriggerAuthorDataUnitAgent extends Vue {
  static agentName = "TriggerAuthorDataUnitAgent"

  @Prop()
  dataUnit!: IssueDataUnitTrigger
}
