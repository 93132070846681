<template>
  <div>
    <ReportCalendar
      v-if="!IS_MOBILE"
      @onOpenActionDialog="openActionDialog($event)"
      @onUploadReport="openUploadReportDialog($event)"
    />
    <ReportsTable
      @onOpenMarDialog="openMarDialog($event)"
      @onUploadReport="openUploadReportDialog($event)"
      @onDeleteReport="openDeleteDialog($event)"
    />
    <UploadReportDialog ref="uploadReportDialog" />
    <ReportMarDialog ref="reportMarDialog" @onCloseDialog="tryToOpenMarDialogForPendingReports($event)" />
    <ConfirmationDialog ref="deleteDialog" @onCloseDialog="tryToOpenDeleteDialogForPendingReports($event)"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import ReportsTable from "../components/reports/ReportsTable";
import ReportCalendar from "../components/reports/report-calendar/Calendar";
import ReportMarDialog from "../components/reports/ReportMarDialog";
import UploadReportDialog from "../components/reports/UploadReportDialog";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import {
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  IS_MOBILE,
  REPORT_STATUS_DATA_NOT_FOUND,
  REPORT_TYPES_BY_VALUE,
} from "../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "UploadPage",

  routing: {
    localizationKey: "menu.tab.upload_a_report",
    icon: "mdi-image",
    path: "/upload",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    ReportsTable,
    ReportCalendar,
    ReportMarDialog,
    UploadReportDialog,
    ConfirmationDialog,
  },

  data: () => ({
    IS_MOBILE,

    targetStatuses: [
      REPORT_STATUS_MANUAL_ACTION_REQUIRED.value,
      REPORT_STATUS_DATA_NOT_FOUND.value,
    ],
  }),

  computed: {
    ...mapState("reportModule", [
      "calendarReportsFromApi",
      "pendingReports",
    ]),
  },

  methods: {
    tryToOpenDialog(confirmed, targetReportStatus, openDialog) {
      if (!confirmed) {
        this.$store.commit("reportModule/updatePendingReports", []);
        return;
      }

      if (this.pendingReports.length) {
        const pendingReports = this.calendarReportsFromApi.filter((it) => (
          this.pendingReports.some((pendingReport) => pendingReport.id === it.id)
        ));

        // if all reports was resolved on backend
        if (
          pendingReports.length
          && pendingReports.every((it) => !this.targetStatuses.includes(it.status))
        ) {
          this.$store.commit("reportModule/updatePendingReports", []);
          return;
        }

        const foundReport = pendingReports.find((it) => it.status === targetReportStatus);

        if (foundReport) {
          this.$store.commit("reportModule/removeReportFromPending", foundReport);
          openDialog(foundReport);
        }
      }
    },

    tryToOpenMarDialogForPendingReports(targetReportResolved) {
      this.tryToOpenDialog(
        targetReportResolved,
        REPORT_STATUS_MANUAL_ACTION_REQUIRED.value,
        this.openMarDialog,
      );
    },

    tryToOpenDeleteDialogForPendingReports(itemWasDeleted) {
      this.tryToOpenDialog(
        itemWasDeleted,
        REPORT_STATUS_DATA_NOT_FOUND.value,
        this.openDeleteDialog,
      );
    },

    openMarDialog(report) {
      if (report.status === REPORT_STATUS_MANUAL_ACTION_REQUIRED.value) {
        this.$refs.reportMarDialog.openDialog(report);
      }
    },

    openDeleteDialog(report) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("report_page.dialog.delete.title"),
        text: locService.localize("report_page.dialog.delete.text", [
          report.targetDate,
          (REPORT_TYPES_BY_VALUE[report.type] || { text: "Unknown" }).text,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          try {
            const result = await this.$store.dispatch("reportModule/deleteReport", report);
            Vue.toasted.success(result, {
              duration: 5000,
            });
          } catch (e) {
            Vue.toasted.error(e, {
              duration: 5000,
            });
          }
        },
      });
    },

    openActionDialog(report) {
      if (report.isMar) {
        this.openMarDialog(report);
      }
      if (report.isError) {
        this.openDeleteDialog(report);
      }
    },

    openUploadReportDialog(data) {
      this.$refs.uploadReportDialog.openDialog(data);
    },
  },
};
</script>
