<template>
  <v-btn
    v-if="!targetStation && state == 'view'"
    @click="state = 'edit'"
    x-small
    class="theme-text-color"
    color="secondary"
  >
    {{ $localizationService.localize("drp.station_label.set_btn") }}
  </v-btn>
  <div
    v-else-if="state == 'view'"
    :class="getStationClass(targetStation)"
    @click="state = 'edit'"
  >
    <b>{{ targetStation }}</b>
  </div>
  <div v-else class="station-select d-flex flex-row align-center">
    <StationSelect
      :stationsInUse="stationsInUse"
      :value="targetStation"
      @input="newStation = $event"
      @onSelect="
        newStation = $event;
        save();
      "
      @submit="save()"
      @cancel="cancel()"
    />
    <v-icon class="ml-2" color="green" @click="save()">mdi-check</v-icon>
    <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
  </div>
</template>

<script>
import StationSelect from "./StationSelect.vue";
import { getStationClass } from "../../../../utils/utils";

export default {
  name: "StationLabel",

  components: {
    StationSelect,
  },

  props: {
    stationsInUse: Array,
    targetStation: String,
  },

  data: () => ({
    state: "view",
    newStation: "",
  }),

  methods: {
    getStationClass,

    save() {
      this.state = "view";
      this.$emit("onChangeData", this.newStation);
    },
    cancel() {
      this.state = "view";
    },
  },
};
</script>

<style scoped lang="scss">
.station-select {
  min-width: 100px;
  max-width: 150px;
}
</style>
