<template>
  <div
    class="d-flex align-center"
    :class="{'disabled-driver-container pl-4 w-100': options.disabled}"
    v-if="driver"
    @click="stopOnSelectDisabledDriver($event)"
  >
    <v-tooltip top open-delay="500" :disabled="!options.disabled">
      <template v-slot:activator="{ on, attrs }">
        <span
          class="no-wrap driver-name"
          v-bind="attrs"
          v-on="on"
          :class="options.nameColor"
        >
          {{ driver.name }}
        </span>
      </template>
      <span>{{ options.tooltipText }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <router-link
          :to="`/infractions/driver/${driver.id}`"
          class="decor-none"
        >
          <v-chip
            v-if="infractionsCount"
            v-bind="attrs"
            v-on="on"
            class="ml-2 red no-elastic-chip white--text"
            @click.stop=""
          >
            <v-avatar left class="red darken-4">
              {{ infractionsCount }}
            </v-avatar>
            {{
              $localizationService.localize(
                "drp.matched_driver_label.infractions"
              )
            }}
          </v-chip>
        </router-link>
      </template>
      <div class="inf-chip-card">
        <v-chip
          v-for="item of infractionsPerCriteria"
          :key="item.criteria.id"
          class="red mr-2 mb-1 mt-1 white--text"
        >
          <v-avatar left class="red darken-4">
            {{ item.infractionIds.length }}
          </v-avatar>
          {{ item.criteria.name }}
        </v-chip>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "MatchedDriverLabel",

  props: {
    driver: Object,
    additionalDataPerDriver: Object,
    options: {
      type: Object,
      default: Object,
    },
  },

  computed: {
    infractionsPerCriteria() {
      return this.driver
        ? this.additionalDataPerDriver.infractionsPerCriteria || []
        : [];
    },

    infractionsCount() {
      return this.infractionsPerCriteria.map((it) => it.infractionIds).flat()
        .length;
    },
  },

  methods: {
    stopOnSelectDisabledDriver(e) {
      if (this.options.disabled) {
        e.stopPropagation();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inf-chip-card {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
}
.decor-none {
  text-decoration: none;
}
.no-wrap {
  white-space: nowrap;
}
.disabled-driver-container {
  position: absolute !important;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default !important;
  .driver-name {
    opacity: 0.7;
  }
}
</style>
