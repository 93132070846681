import { Auth, Storage, API } from "aws-amplify";
import moment from "moment";
// eslint-disable-next-line camelcase,@typescript-eslint/camelcase
import { v4 as uuid_v4 } from "uuid";
import Vue from "vue";
import { Report } from "../model/report.model";
import {
  bodyWithAuthHeader,
  REPORT_PROCESS_STATUS_RECEIVED,
  localizationService,
  CUMULATIVE_REPORTS,
} from "../utils/utils";
import { store } from "../main";

const locService = Vue.prototype.$localizationService;

export default {

  name: "reportModule",
  namespaced: true,
  state: {

    currentPageLoading: false,
    calendarLoading: false,
    currentPage: {
      reports: [],
      index: 0,
      size: 25,
    },
    displayReports: [],
    calendarReportsFromApi: [],
    calendarDates: [],
    matchedDrivers: [],
    totalCount: 0,
    processingReport: false,
    reportLastActionStatus: "",
    currentActionReport: {},
    reportsUploadingInProgress: false,
    reportsUploadingStatus: {},
    displayReportsStatusUpdateInterval: undefined,
    reportModels: undefined,
    loadingReportModels: false,
    pendingReports: [],
  },
  mutations: {

    beginLoadingModels(state) {
      state.loadingReportModels = true;
    },

    endLoadingModels(state) {
      state.loadingReportModels = false;
    },

    setReportModels(state, newModels) {
      state.reportModels = newModels;
    },

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageReports(state, reportsDto) {
      state.currentPage.reports = [...reportsDto];
      state.displayReports = state.currentPage.reports;
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    updateReportLastActionStatus(state, reportLastActionStatus) {
      state.reportLastActionStatus = reportLastActionStatus;
    },

    startProcessingReport(state, report) {
      state.processingReport = true;
      state.currentActionReport = report;
    },

    endProcessingReport(state) {
      state.processingReport = false;
    },

    updateReportsUploadingStatus(state, { id, value }) {
      Vue.set(state.reportsUploadingStatus, id, value);
    },

    beginUploading(state) {
      state.reportsUploadingInProgress = true;
    },

    endUploading(state) {
      state.reportsUploadingInProgress = false;
      state.reportsUploadingStatus = {};
    },

    updateDisplayReports(state, reports) {
      state.displayReports = reports;
    },

    setCalendarLoading(state, loading) {
      state.calendarLoading = loading;
    },

    setMarLoading(state, loading) {
      store.commit("marModule/setSubSystemLoading", loading);
    },

    updateReportCalendar(state, calendarReports) {
      state.calendarReportsFromApi = [...calendarReports];
    },
    updateCalendarDates(state, calendarDates) {
      state.calendarDates = [...calendarDates];
    },
    updateMatchedDrivers(state, driversDto) {
      if (!driversDto.length) {
        state.matchedDrivers = [{
          name: locService.localize("store.report.matched_drivers.no_name"),
          transporterId: locService.localize("store.report.matched_drivers.no_transporterId"),
          disabled: true,
        }];
      } else { state.matchedDrivers = [...driversDto]; }
    },

    updatePendingReports(state, pendingReports) {
      state.pendingReports = pendingReports;
    },

    removeReportFromPending(state, report) {
      const index = state.pendingReports.findIndex((pendingReport) => pendingReport.id === report.id);
      if (index !== -1) {
        state.pendingReports.splice(index, 1);
      }
    },
  },
  actions: {

    async loadModelsIfNeeded({ commit, state }) {
      if (state.reportModels || state.loadingReportModels) return;

      const body = await bodyWithAuthHeader();

      commit("beginLoadingModels");

      try {
        const models = await API.get("core", "/report/models", body);

        localizationService.injectLocalizedNames(models);

        commit("setReportModels", models);
      } catch (error) {
        Vue.toasted.error(error);
      } finally {
        commit("endLoadingModels");
      }
    },

    async uploadReports({ commit, dispatch, state }, reports) {
      commit("beginUploading");

      await Promise.all(reports.map((report) => dispatch("uploadReport", report)));

      commit("updateTotalCount", state.totalCount + reports.length);

      dispatch("reloadPage");

      setTimeout(() => commit("endUploading"), 500);
    },

    async retryParse({ dispatch }, report) {
      console.log(report);
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          binKey: report.binKey,
        },
      };

      await API.post("core", "/report/retry", body);

      dispatch("reloadPage");
    },

    async uploadReport({
      commit, state, dispatch, rootState,
    }, report) {
      let contentType;

      if (report.file.name.endsWith(".pdf")) {
        contentType = "application/pdf";
      } else if (report.file.name.endsWith(".csv")) {
        contentType = "text/csv";
      } else if (report.file.name.endsWith(".xlsx")) {
        contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }

      if (!contentType) {
        throw locService.localize("store.report.unsupported_file_format");
      }

      report.id = uuid_v4();

      const key = `cognito/dsps/${(await Auth.currentSession()).getIdToken().payload.sub}/${report.id}`;

      return Storage.put(key, report.file, {

        contentType,
        metadata: {

          id: report.id,
          "file-name": Buffer.from(report.file.name).toString("base64"),
          "target-date": moment(report.targetDate).local().format("YYYY-MM-DD"),
          uploader: rootState.userModule.user.id,
        },
        progressCallback: (progress) => {
          commit("updateReportsUploadingStatus", { id: report.id, value: (progress.loaded / progress.total) * 100 });
        },
      })
        .then(async () => {
          const body = await bodyWithAuthHeader();

          body.body = {
            key: `public/${key}`,
          };

          try {
            setTimeout(() => dispatch("loadReportCalendar", state.calendarDates), 800);
            await API.post("core", "/report", body);
            commit("updateReportsUploadingStatus", { id: [report.id], value: "success" });
            report.uploadedAt = Date.now();
            report.status = REPORT_PROCESS_STATUS_RECEIVED.value;
          } catch (error) {
            commit("updateReportsUploadingStatus", { id: [report.id], value: error.message });
          }
        })
        .catch((error) => commit("updateReportsUploadingStatus", { id: [report.id], value: error.message }));
    },

    async reloadPage({ dispatch, state }) {
      await dispatch("loadPage", state.currentPage);
      await dispatch("loadReportCalendar", state.calendarDates);
    },

    async loadPage({ commit, state, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);
      commit("setMarLoading", true);

      await dispatch("loadTotalCount");

      const mainBody = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        const reportsDto = await API.get("core", "/report", mainBody);
        commit("updateCurrentPageReports", reportsDto.map((dto) => Report.fromDto(dto)));
      } catch (e) {
        Vue.toasted.error(e);
      } finally {
        commit("endLoadingPage");
        commit("setMarLoading", false);
      }

      if (!state.displayReportsStatusUpdateInterval) {
        state.displayReportsStatusUpdateInterval = setInterval(async () => {
          const targetReports = state.displayReports.filter((report) => report.isInProcess());

          if (!targetReports.length) return;

          const body = await bodyWithAuthHeader();

          body.body = targetReports.map((report) => report.id);
          try {
            const updatedReports = await API.post("core", "/report/get_by_ids", body);

            const reports = [...state.displayReports];

            reports.forEach((currentReport, index) => {
              const updatedReport = updatedReports.find((uReport) => uReport.id == currentReport.id);

              if (updatedReport) {
                reports[index] = Report.fromDto(updatedReport);
              }

              if (
                updatedReport
                  && (CUMULATIVE_REPORTS.includes(updatedReport.displayTypeName))
              ) {
                dispatch("reloadPage");
              }
            });

            dispatch("loadReportCalendar", state.calendarDates);
            commit("updateDisplayReports", reports);
          } catch (e) {
            Vue.toasted.error(e);
          }
        }, 7500);
      }
    },

    async loadTotalCount({ commit }) {
      const body = {
        ...await bodyWithAuthHeader(),
      };

      try {
        const count = await API.get("core", "/report/count", body);
        commit("updateTotalCount", count);
      } catch (e) {
        Vue.toasted.error(e);
      }
    },

    async deleteReport({ commit, dispatch, state }, report) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `/report/${report.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        return locService.localize("store.report.successfully_deleted");
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async loadReportCalendar({ state, commit }, datesArr) {
      commit("setCalendarLoading", true);
      commit("setMarLoading", true);

      if (datesArr) {
        commit("updateCalendarDates", datesArr);
      }

      const from = state.calendarDates[0];
      const to = state.calendarDates[1];
      const body = await bodyWithAuthHeader();

      try {
        const reports = await API.get("core", `/report/get_by_dates_range?from=${from}&to=${to}`, body);
        commit("updateReportCalendar", reports.map(Report.fromDto));
        commit("setCalendarLoading", false);
        commit("setMarLoading", false);
      } catch (error) {
        Vue.toasted.error(error);
      }
    },

    async loadFilteredDrivers({ commit }, { transporterId, name }) {
      if (!transporterId && !name) return [];

      const body = await bodyWithAuthHeader();

      try {
        const driversDto = await API.get("core", `/driver/search?transporterId=${transporterId || ""}&name=${name || ""}`, body);

        commit("updateMatchedDrivers", driversDto);

        return driversDto;
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },
  },
};
