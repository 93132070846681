import lodash from "lodash";
import moment from "moment";
import { IssueDataUnit } from "@/model/issue/data-unit.model";
import {
  IssueDataUnitToDto, IssueDataUnitType, IssueDto, IssueSeverity, IssueStatus, IssueToDto,
} from "@/types/types";
import {
  ISSUE_STATUSES, ISSUES_STATUS_OPEN, ISSUES_SEVERITY_CRITICAL, ISSUE_SEVERITIES,
} from "@/utils/ts-utils";
import { IssueDataUnitUser } from "@/model/issue/units/issue-data-unit-user.model";
import { IssueDataUnitTrigger } from "@/model/issue/units/issue-data-unit-trigger.model";
import { IssueDataUnitVehicle } from "@/model/issue/units/issue-data-unit-vehicle.model";
import { IssueDataUnitReminder } from "./units/issue-data-unit-reminder.model";

const unitClasses = {
  [IssueDataUnitType.UserAuthorDataUnit]: IssueDataUnitUser,
  [IssueDataUnitType.TriggerAuthorDataUnit]: IssueDataUnitTrigger,
  [IssueDataUnitType.RelatedVehicleDataUnit]: IssueDataUnitVehicle,
  [IssueDataUnitType.ReminderAuthorDataUnit]: IssueDataUnitReminder,
};

export class Issue {
  constructor(
    public id: string,
    public createdAt: string,
    public title: string,
    public description: string,
    public essence: string,
    public status: IssueStatus,
    public dataUnits: IssueDataUnit[],
    public severity: IssueSeverity,
  ) { }

  get backgroundColor() {
    if (this.status == ISSUES_STATUS_OPEN) return this.severity.background;
    return this.status.background;
  }

  clone(): Issue {
    return new Issue(
      this.id,
      this.createdAt,
      this.title,
      this.description,
      this.essence,
      this.status,
      [...this.dataUnits],
      this.severity,
    );
  }

  static fromDto(dto: IssueDto): Issue {
    return new Issue(
      dto.id,
      dto.createdAt,
      dto.title,
      dto.description,
      dto.essence,
      lodash.keyBy(ISSUE_STATUSES, "value")[dto.status],
      // WARNING: danger for fail fast
      dto.dataUnits.map((it) => (unitClasses[it.type] || { fromDto: (stub: any) => stub }).fromDto(it)),
      lodash.keyBy(ISSUE_SEVERITIES, "value")[dto.severity],
    );
  }

  static createEmpty(): Issue {
    return new Issue(
      "",
      moment().toISOString(),
      "",
      "",
      "",
      ISSUES_STATUS_OPEN,
      [],
      ISSUES_SEVERITY_CRITICAL,
    );
  }

  toDto(): IssueToDto {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      essence: this.essence,
      status: this.status.value,
      // WARNING: danger for fail fast
      dataUnits: this.dataUnits.map((it) => (it.toDto ? it.toDto() : it))
        .filter((it) => it) as IssueDataUnitToDto[],
      severity: this.severity.value,
    };
  }
}
