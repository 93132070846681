

























































































import {
  Component, Ref, Emit, Prop, Watch,
} from "vue-property-decorator";
import { mixins } from "vue-class-component";
import lodash from "lodash";
import { mixin as clickAway } from "vue-clickaway";
import { VueClass } from "vue-class-component/lib/declarations";
import { VuetifyPreset } from "vuetify";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { NoCache } from "@/utils/decorators";
import AssistantConstantModule from "@/store/templates-writing-assistant.store";
import { AssistantConstant } from "@/model/assistant-constant.model";
import { TwaMode } from "@/utils/ts-utils";
import { TwaModeType } from "@/types/types";

/* eslint-disable no-useless-escape */
const REGEX_CONSTANTS = /\${(([A-Za-z0-9._\-\[\]]?)*)}?/g;
const REGEX_FULL_CONSTANTS = /(\${([A-Za-z0-9._\-\[\]]?)+})/g;
const REGEX_CONSTANTS_WITH_SPACES = /(\${([A-Za-z0-9._\-\[\]]?)+(\s)+})/g;
const REGEX_HIGHLIGHTED_CONSTANTS = /(\${<mark>([A-Za-z0-9._\-\[\]]?)+<\/mark>)/g;
const REGEX_EMPTY_CONSTANTS = /(\${(?![A-Za-z0-9._\-\[\]])(?!}))/g;
const REGEX_DOUBLE_CLOSED_SYMBOL_CONSTANTS = /(\${((?![A-Za-z0-9._\-\[\]])|([A-Za-z0-9._\-\[\]])+)}})/g;
const REGEX_ALL_CONSTANTS = /(\${([A-Za-z0-9._\-\[\]]?)+(\s?)(}*)?)/g;

const REGEX_ENTER = /\r?\n/;
const JOIN_ENTER = "\r\n";

const assistantConstantModule = AssistantConstantModule.namespace;

type TravelKey = {
  name: string;
  code: number;
  getNewHoveredConstantIndex: (it: number) => number;
}

export const TWA_TEXTAREA_MODE: TwaModeType = {
  type: TwaMode.Textarea,
  componentHeight: 170,
  backdropHeight: 140,
  componentClass: "textarea-container",
  insideComponent: "textarea",
};

export const TWA_TEXT_FIELD_MODE: TwaModeType = {
  type: TwaMode.TextField,
  componentHeight: 66,
  backdropHeight: 56,
  componentClass: "text-field-container",
  insideComponent: "input",
};

export const TWA_MODES: TwaModeType[] = [
  TWA_TEXTAREA_MODE,
  TWA_TEXT_FIELD_MODE,
];

@Component
export default class TemplatesWritingAssistant extends mixins(clickAway as VueClass<object & Record<never, any>>) {
  TwaMode = TwaMode;

  @Prop(String)
  readonly value!: string;

  textareaValue = this.value || "";

  loading = false;

  assistantActivated = false;

  lastTargetRowIndex: number | undefined;

  constantIndexInLine: number | undefined;

  userInputConstantName = "";

  hoveredConstantIndex = 0;

  itemHeight = 30;

  autoFocus = false;

  @Prop(String)
  readonly label!: string;

  @Prop({ required: false, default: () => [] })
  readonly rules!: any;

  @Prop()
  mode!: TwaMode

  @Prop()
  rows: number | undefined;

  @Ref("backdrop")
  backdrop!: HTMLDivElement;

  @Ref("highlights")
  highlights!: HTMLDivElement;

  @Ref("selectedItems")
  selectedItems!: Array<HTMLDivElement>;

  @Ref("textarea")
  textarea!: VuetifyPreset;

  @Ref("constantsContainer")
  constantsContainer!: HTMLDivElement;

  @assistantConstantModule.State
  constants!: AssistantConstant[];

  @Prop()
  specificConstants: AssistantConstant[] | undefined;

  @assistantConstantModule.Action
  loadConstants!: () => Promise<void>;

  @Watch("textareaValue")
  transferData() {
    this.emitTextValue();
  }

  @Emit("input")
  emitTextValue() {
    return this.textareaValue;
  }

  get currentMode() {
    return lodash.keyBy(TWA_MODES, "type")[this.mode];
  }

  get componentHeight() {
    const height = this.rows ? this.rows * 35 : this.currentMode.componentHeight;
    return {
      height: `${height}px`,
    };
  }

  get backdropStyle() {
    return {
      height: `${this.currentMode.backdropHeight}px`,
    };
  }

  get assistantTravelKeys(): TravelKey[] {
    return [
      {
        name: "up",
        code: 38,
        getNewHoveredConstantIndex: (it: number): number => (
          it === 0
            ? this.displayConstants.length - 1
            : it - 1
        ),
      },
      {
        name: "down",
        code: 40,
        getNewHoveredConstantIndex: (it: number): number => (
          it === this.displayConstants.length - 1
            ? 0
            : it + 1
        ),
      },
    ];
  }

  get allConstants(): AssistantConstant[] {
    if (this.specificConstants) {
      return lodash.flatten((this.specificConstants || []).map((it) => it.allConstants));
    }

    return lodash.flatten((this.constants || []).map((it) => it.allConstants));
  }

  get displayConstants(): AssistantConstant[] {
    return !this.userInputConstantName
      ? this.allConstants
      : this.allConstants.filter((it: AssistantConstant) => (
        it.fullName.startsWith(this.userInputConstantName)
      ));
  }

  get highlightedText(): string {
    const rawMatches = [];
    let match: RegExpExecArray | null;
    const C_REGEX_CONSTANTS = /\${(([A-Za-z0-9._\-\[\]]?)*)}?/g;
    // eslint-disable-next-line no-cond-assign
    while ((match = C_REGEX_CONSTANTS.exec(this.textareaValue)) != null) {
      rawMatches.push(match);
    }

    const notCorrectConstants = rawMatches
      .map((it, constantIndex) => ({
        text: it[1],
        fullLength: it[0].length - 1,
        index: it.index,
        constantIndex,
      }))
      .filter((it) => !this.allConstants.some((item) => item.fullName === it.text) || this.textareaValue.charAt(it.index + it.fullLength) != "}");

    let constantIndexCursor = -1;

    const replace = (matchedText: string): string => {
      constantIndexCursor++;

      if (notCorrectConstants.some((it) => it.constantIndex == constantIndexCursor)) {
        return `<mark>${matchedText}</mark>`;
      }

      return `<mark class="correct-constant">${matchedText}</mark>`;
    };

    return this.textareaValue.replaceAll(/\r?\n/g, "<br>")
      .replaceAll(" ", "&nbsp;")
      .replace(REGEX_CONSTANTS, replace);
  }

  @NoCache
  get assistantStyle(): { top: string } {
    // 1.75rem (28px) - default vuetify textarea line-height
    const newTopValue = 28 * ((this.lastTargetRowIndex || 0) + 1);
    return {
      top: `${newTopValue > this.currentMode.backdropHeight ? this.currentMode.backdropHeight : newTopValue}px`,
    };
  }

  @Emit("onHasFailedValue")
  emitTextareaValueValidState(): boolean {
    //
    const rawMatches = [];
    let match: RegExpExecArray | null;
    const C_REGEX_CONSTANTS = /\${(([A-Za-z0-9._\-\[\]]?)*)}?/g;
    // eslint-disable-next-line no-cond-assign
    while ((match = C_REGEX_CONSTANTS.exec(this.textareaValue)) != null) {
      rawMatches.push(match);
    }

    const notCorrectConstants = rawMatches
      .map((it, constantIndex) => ({
        text: it[1],
        fullLength: it[0].length - 1,
        index: it.index,
        constantIndex,
      }))
      .filter((it) => !this.allConstants.some((item) => item.fullName === it.text) || this.textareaValue.charAt(it.index + it.fullLength) != "}");
    //

    return !!notCorrectConstants.length
      || !![...this.highlightedText.matchAll(REGEX_HIGHLIGHTED_CONSTANTS)].length
      || !![...this.highlightedText.matchAll(REGEX_EMPTY_CONSTANTS)].length
      || !this.textareaValue || !this.rules;
  }

  @Watch("value")
  changeValue() {
    this.textareaValue = this.value;
  }

  mouseOver(index: number) {
    this.hoveredConstantIndex = index;
    this.itemHeight = this.selectedItems[index].offsetHeight;
  }

  forceUpdate() {
    this.onChangeValue(this.textareaValue);
  }

  async onChangeValue(newTextareaValue: string): Promise<void> {
    const {
      lastTargetRowIndex,
      userInputConstantName,
      constantIndexInLine,
    } = this.getTargetConstantParams(this.textareaValue, newTextareaValue);

    this.userInputConstantName = userInputConstantName || "";
    if (lastTargetRowIndex !== undefined) {
      this.lastTargetRowIndex = lastTargetRowIndex;
    }
    if (constantIndexInLine !== undefined) {
      this.constantIndexInLine = constantIndexInLine;
    }

    // get textarea cursor before changes
    const textareaCursor = this.textarea.$refs.input.selectionStart;
    // get textarea scroll before changes
    const textareaScrollTop = this.textarea.$refs.input.scrollTop;

    const previousTextAreaValue = this.textareaValue;
    this.textareaValue = newTextareaValue;
    // we need to wait for the rest of the processes to complete,
    // otherwise the cursor does not change and the textarea will not be updated
    await Vue.nextTick();

    this.textareaValue = this.getNewTextareaValue(newTextareaValue, previousTextAreaValue);
    // we need to wait for the textarea processes to complete.
    await Vue.nextTick();

    // when we rewrite textarea, the cursor changes
    if (
      this.textareaValue !== newTextareaValue
      && this.textarea.$refs.input.selectionStart !== textareaCursor
    ) {
      this.onChangeTextarea(textareaCursor, textareaScrollTop);
    }

    this.highlightBackground();

    this.assistantActivated = lastTargetRowIndex !== undefined;
    if (this.assistantActivated) {
      // we need to wait for the assistant to open
      await Vue.nextTick();
      this.constantsContainer.scrollTop = 0;
      this.hoveredConstantIndex = 0;
    }
  }

  getNewTextareaValue(textareaValue: string, previousTextAreaValue: string): string {
    const rows = textareaValue.split(REGEX_ENTER);
    const lastTargetRowIndex = this.lastTargetRowIndex || 0;
    const targetRow = rows[lastTargetRowIndex];

    // targetRow may be undefined since the user can delete the last target row
    if (!targetRow) {
      this.lastTargetRowIndex = rows.length - 1;
      return textareaValue;
    }

    return this.getChangedTextareaValue(
      rows,
      targetRow,
      previousTextAreaValue.split(REGEX_ENTER)[lastTargetRowIndex],
      lastTargetRowIndex,
    );
  }

  getChangedTextareaValue(
    rows: string[],
    targetRow: string,
    targetRowInPreviousTextareaValue: string,
    lastTargetRowIndex: number,
  ): string {
    const failedConstants = this.getFailedConstants(targetRow);

    const replace = (
      match: string, p1: string, p2: string, p3: number, str: string, offset: number,
    ) => {
      const failedConstant = failedConstants.find((it) => (
        (it?.constant && match === it?.constant[0]
          && offset === it?.constant.index)
        || (it?.doubleClosedConstant && match === it?.doubleClosedConstant[0]
          && offset === it.doubleClosedConstant.index)
        || (it?.constantWithSpaces && match === it?.constantWithSpaces[0]
          && offset === it.constantWithSpaces.index)
      ));

      if (!failedConstant) return match;

      if (failedConstant.doubleClosedConstant) {
        const targetStr = failedConstant.doubleClosedConstant[0];
        return targetStr.substr(0, targetStr.length - 1);
      }

      if (failedConstant.constantWithSpaces) {
        return failedConstant.constantWithSpaces[0].split(" ")
          .join("");
      }

      const failedConstantStr = failedConstant.constant[0];

      return failedConstantStr;
    };

    if (failedConstants.length) {
      rows[lastTargetRowIndex] = targetRow.replace(REGEX_ALL_CONSTANTS, replace);
    }

    // detect Enter at the end of constant
    // ${asd
    // }
    const rowUnderTargetRow = rows[lastTargetRowIndex + 1];
    if (rowUnderTargetRow && rowUnderTargetRow.startsWith("}")) {
      rows[lastTargetRowIndex] += rowUnderTargetRow;
      rows.splice(lastTargetRowIndex + 1, 1);
    }

    return rows.join(JOIN_ENTER);
  }

  getFailedConstants(targetRow: string): ({
    constant: RegExpMatchArray;
    doubleClosedConstant: RegExpMatchArray | undefined;
    constantWithSpaces: RegExpMatchArray | undefined;
  } | undefined)[] {
    const constants = [...targetRow.matchAll(REGEX_CONSTANTS)];
    const fullConstants = [...targetRow.matchAll(REGEX_FULL_CONSTANTS)];
    const constantsWithSpaces = [...targetRow.matchAll(REGEX_CONSTANTS_WITH_SPACES)];
    const doubleClosedConstantsConstants = [...targetRow.matchAll(REGEX_DOUBLE_CLOSED_SYMBOL_CONSTANTS)];

    return constants.map((constant) => {
      const fullConstant = fullConstants.find((it) => (
        it[0].startsWith(constant[0])
        && it[0].split("}")[0] === constant[0]
        && it.index === constant.index
      ));

      const doubleClosedConstant = doubleClosedConstantsConstants.find((it) => (
        it[0].startsWith(constant[0])
        && it[0].split("}")[0] === constant[0]
        && it.index === constant.index
      ));

      const constantWithSpaces = constantsWithSpaces.find((it) => (
        it[0].startsWith(constant[0])
        && it[0].split("}")[0].split(" ")[0] === constant[0]
        && it.index === constant.index
      ));

      if (
        fullConstant
        && !constantWithSpaces
        && !doubleClosedConstant
      ) {
        return undefined;
      }

      return {
        constant,
        doubleClosedConstant,
        constantWithSpaces,
      };
    })
      .filter((it) => it);
  }

  getTargetConstantParams(oldTextareaValue: string, newTextareaValue: string): {
    lastTargetRowIndex: number | undefined;
    constantIndexInLine: number | undefined;
    userInputConstantName: string | undefined;
  } {
    const targetConstant = this.getUserModifiedConstant(oldTextareaValue, newTextareaValue);

    if (!targetConstant) {
      return {
        lastTargetRowIndex: undefined,
        constantIndexInLine: undefined,
        userInputConstantName: undefined,
      };
    }

    const {
      targetRowIndex,
      constantIndexInLine,
    } = this.getTargetConstantIndexes(targetConstant, newTextareaValue);
    return {
      lastTargetRowIndex: targetRowIndex,
      constantIndexInLine,
      userInputConstantName: targetConstant[0].split("${")[1],
    };
  }

  getTargetConstantIndexes(targetConstant: RegExpMatchArray, newTextareaValue: string): {
    targetRowIndex: number; // for calculate top css at assistant card
    constantIndexInLine: number; // for apply constant from assistant card -> onSelectConstant()
  } {
    const preString = newTextareaValue.slice(0, targetConstant.index)
      .split(REGEX_ENTER)
      .pop();

    const fullTargetRow = preString + newTextareaValue.slice(targetConstant.index, newTextareaValue.length)
      .split(REGEX_ENTER)[0];

    const rows = newTextareaValue.split(REGEX_ENTER);

    const targetRowIndex = rows.findIndex((it) => it === fullTargetRow);

    const rowsBeforeTargetLine = rows.splice(0, targetRowIndex);

    const symbolsCountBeforeTargetLine = lodash.sum(rowsBeforeTargetLine.map((it) => it.length));

    let constantIndexInLine = (targetConstant.index || symbolsCountBeforeTargetLine) - symbolsCountBeforeTargetLine;

    // we need to drop count of enters
    if (symbolsCountBeforeTargetLine !== 0) {
      constantIndexInLine -= rowsBeforeTargetLine.length;
    }

    return {
      targetRowIndex,
      constantIndexInLine,
    };
  }

  getUserModifiedConstant(oldTextareaValue: string, newTextareaValue: string): RegExpMatchArray | undefined {
    const newTextareaValueConstants = [...newTextareaValue.matchAll(REGEX_CONSTANTS)];
    const oldTextareaValueConstants = [...oldTextareaValue.matchAll(REGEX_CONSTANTS)];

    return newTextareaValueConstants.find((newMatchedValue, index) => {
      const oldValue = oldTextareaValueConstants[index];
      if (!oldValue) {
        return true;
      }
      let valueWithMinLength;
      let valueWithMaxLength;

      if (newMatchedValue[0].length < oldValue[0].length) {
        valueWithMinLength = newMatchedValue[0];
        valueWithMaxLength = oldValue[0];
      } else {
        valueWithMinLength = oldValue[0];
        valueWithMaxLength = newMatchedValue[0];
      }

      return valueWithMinLength !== valueWithMaxLength
        ? valueWithMaxLength.startsWith(valueWithMinLength)
        : false;
    });
  }

  onEnterConstant(event: KeyboardEvent, selectedConstant: AssistantConstant): void {
    if (!this.assistantActivated || !selectedConstant) return;

    event.preventDefault();

    this.onSelectConstant(selectedConstant.fullName);
  }

  onSelectConstant(selectedConstantFullName: string): void {
    if (this.lastTargetRowIndex === undefined) return;

    const rows = this.textareaValue.split(REGEX_ENTER);
    const targetRow = rows[this.lastTargetRowIndex];

    // replace target constant in target row from textareaValue
    const replace = (
      match1: string, match2: string, text: string, offset: number, fullString: string,
    ): string => {
      const matchedValue = (match1.split("${")[1] || match1.split("${")[0]).replaceAll("}", "");
      // if do not check offset !== this.constantIndexInLine
      // ${}  ${hi -> press to select from card -> ${hi}  ${hi}
      // and was matched all!
      if (
        matchedValue !== this.userInputConstantName
        || offset !== this.constantIndexInLine
      ) {
        return match1;
      }

      return fullString.slice(offset, fullString.length)[match1.length] === "}"
        ? `\${${selectedConstantFullName} `
        : `\${${selectedConstantFullName}} `;
    };

    rows[this.lastTargetRowIndex] = targetRow.replace(REGEX_CONSTANTS, replace);

    const textareaCursor = this.textarea.$refs.input.selectionStart;
    const textareaScrollTop = this.textarea.$refs.input.scrollTop;

    this.textareaValue = rows.join(JOIN_ENTER);
    this.onChangeTextarea(textareaCursor + selectedConstantFullName.length + 1, textareaScrollTop);
    this.assistantActivated = false;
    this.highlightBackground();
  }

  onChangeTextarea(textareaCursor: number, textareaScrollTop: number) {
    setTimeout(() => {
      const textarea: HTMLTextAreaElement | null = document.querySelector(
        `.${this.currentMode.componentClass} ${this.currentMode.insideComponent}`,
      );
      if (textarea) {
        textarea.setSelectionRange(textareaCursor, textareaCursor);
      }
      this.textarea.$refs.input.scrollTop = textareaScrollTop;
    });
  }

  onClickToConstant(fullName: string): void {
    this.onSelectConstant(fullName);
    this.textarea.focus();
  }

  onPressArrow(event: KeyboardEvent): void {
    if (!this.assistantActivated) return;

    const foundEvent = this.assistantTravelKeys.find((it) => it.code === event.keyCode);
    if (foundEvent) {
      event.preventDefault();
      this.hoveredConstantIndex = foundEvent.getNewHoveredConstantIndex(this.hoveredConstantIndex);
      this.constantsContainer.scrollTop = this.hoveredConstantIndex * this.itemHeight;
    }
  }

  onOutsideClick(): void {
    this.assistantActivated = false;
  }

  highlightBackground(): void {
    setTimeout(() => {
      this.highlights.innerHTML = this.highlightedText;
      //* If the content in .highlights el was rewrote, we need to recalc .backdrop el scroll
      this.recalcScroll();
      this.emitTextareaValueValidState();
    });
  }

  recalcScroll(): void {
    this.backdrop.scrollTop = this.textarea.$refs.input.scrollTop;
    this.backdrop.scrollLeft = this.textarea.$refs.input.scrollLeft;
  }

  onFocus() {
    this.autoFocus = true;
  }

  async mounted(): Promise<void> {
    getModule(AssistantConstantModule, this.$store);
    this.loading = true;
    await this.loadConstants();
    this.loading = false;
    this.forceUpdate();
  }
}
