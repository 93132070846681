var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"report-component pt-0",class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-1",attrs:{"align":"start","justify":"start"}},[_c('v-col',{class:{ 'pt-0': _vm.IS_MOBILE },attrs:{"cols":"12"}},[_c('v-data-table',{class:!_vm.IS_MOBILE ? 'mt-3' : 'd-none-empty-sort',attrs:{"headers":_vm.headers,"items":_vm.displayReports,"footer-props":_vm.footerProps,"loading":_vm.currentPageLoading,"server-items-length":_vm.totalCount},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$localizationService.localize("report_page.title"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("report_page.upload_btn"))+" "),_c('input',{staticClass:"file-input",attrs:{"type":"file","name":"upload","multiple":"","accept":".pdf, .csv, .xlsx"},on:{"input":_vm.uploadReport}})])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":!_vm.isError(item.status) && !_vm.isStuck(item),"medium":""},on:{"click":function($event){return _vm.retryParse(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-replay ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize("report_page.retry_tooltip")))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteReportDialog(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize("report_page.remove_tooltip")))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.REPORT_TYPES_BY_VALUE[item.type] || {}).text || item.type)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":item.status != _vm.REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING.value},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:_vm.getStatusTextColorClass(item),style:(item.status == _vm.REPORT_STATUS_MANUAL_ACTION_REQUIRED.value
                    ? { cursor: 'pointer' }
                    : {}),on:{"click":function($event){return _vm.openMarDialog(item)}}},'span',attrs,false),on),[_vm._v(_vm._s(item.getDisplayStatus())+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.REPORT_STATUS_PARSED.value),expression:"item.status == REPORT_STATUS_PARSED.value"}],staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"green"}},[_vm._v("mdi-check")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.REPORT_STATUS_DATA_NOT_FOUND.value),expression:"item.status == REPORT_STATUS_DATA_NOT_FOUND.value"}],staticClass:"ml-1 theme-text-color",attrs:{"x-small":"","color":"secondary"},on:{"click":function($event){return _vm.openDeleteReportDialog(item)}}},[_vm._v(_vm._s(_vm.$localizationService.localize("btn.delete")))]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  item.status == _vm.REPORT_STATUS_MANUAL_ACTION_REQUIRED.value
                ),expression:"\n                  item.status == REPORT_STATUS_MANUAL_ACTION_REQUIRED.value\n                "}],staticClass:"ml-1 theme-text-color",attrs:{"x-small":"","color":"secondary"},on:{"click":function($event){return _vm.openMarDialog(item)}}},[_vm._v(_vm._s(_vm.$localizationService.localize("btn.resolve")))]),_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
                  item.status ==
                  _vm.REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING.value
                ),expression:"\n                  item.status ==\n                  REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING.value\n                "}],staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  item.status == _vm.REPORT_STATUS_TYPE_DETECTION_FAILED.value ||
                  item.status ==
                    _vm.REPORT_STATUS_DATA_RECOGNITION_FAILED.value ||
                  item.status == _vm.REPORT_STATUS_ALREADY_EXIST.value ||
                  item.status == _vm.REPORT_STATUS_DATE_UNAVAILABLE.value ||
                  item.status == _vm.REPORT_STATUS_NOT_SUPPORTED_ANYMORE.value
                ),expression:"\n                  item.status == REPORT_STATUS_TYPE_DETECTION_FAILED.value ||\n                  item.status ==\n                    REPORT_STATUS_DATA_RECOGNITION_FAILED.value ||\n                  item.status == REPORT_STATUS_ALREADY_EXIST.value ||\n                  item.status == REPORT_STATUS_DATE_UNAVAILABLE.value ||\n                  item.status == REPORT_STATUS_NOT_SUPPORTED_ANYMORE.value\n                "}],staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"red"}},[_vm._v("mdi-alert-circle-outline")]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(item.isInProcess()),expression:"item.isInProcess()"}],staticClass:"ml-1 mb-1",class:{
                  'orange--text': _vm.isStuck(item),
                  'primary--text': !_vm.isStuck(item)
                },attrs:{"size":15,"width":2,"indeterminate":""}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize("error.contact_with_admin")))])]),(_vm.isStuck(item))?_c('div',{staticClass:"text--secondary caption"},[_vm._v(_vm._s(_vm.$localizationService.localize("model.report.stuck")))]):_vm._e()]}},{key:"item.targetDate",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedTargetDate[item.id])+" ")]}},{key:"item.uploadedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedUploadedAt[item.id])+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }