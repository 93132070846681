var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.reportsUploadingInProgress}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$localizationService.localize( "report_page.dialog.upload.title" )))])]),_c('v-card-text',[_c('v-container',_vm._l((_vm.toUploadReports),function(report){return _c('v-row',{key:report.id,staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                  'report_page.dialog.upload.param.name'
                ),"disabled":""},model:{value:(report.file.name),callback:function ($$v) {_vm.$set(report.file, "name", $$v)},expression:"report.file.name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"nudge-right":50,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":report.getFormattedTargetDate(),"label":_vm.$localizationService.localize(
                      'report_page.dialog.upload.param.target_date'
                    ),"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.reportsUploadingInProgress}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(report.menu),callback:function ($$v) {_vm.$set(report, "menu", $$v)},expression:"report.menu"}},[_c('v-date-picker',{on:{"input":function($event){report.menu = false}},model:{value:(report.targetDate),callback:function ($$v) {_vm.$set(report, "targetDate", $$v)},expression:"report.targetDate"}})],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-container',{staticClass:"pa-0 justify-center",attrs:{"fill-height":""}},[(
                  _vm.reportsUploadingStatus[report.id] &&
                  _vm.reportsUploadingStatus[report.id] != 'success'
                )?_c('v-progress-circular',{attrs:{"rotate":-90,"size":25,"width":2.5,"value":_vm.reportsUploadingStatus[report.id] || 0,"color":"primary"}}):_vm._e(),(
                  _vm.reportsUploadingStatus[report.id] == 'success'
                )?_c('v-icon',{attrs:{"size":"30","color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e()],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.reportsUploadingInProgress},on:{"click":function($event){_vm.open = false}}},[_vm._v(_vm._s(_vm.$localizationService.localize("btn.cancel")))]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.reportsUploadingInProgress},on:{"click":function($event){return _vm.uploadReports()}}},[_vm._v(_vm._s(_vm.$localizationService.localize("btn.upload")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }