<template>
  <div fluid>
    <v-row align="start" justify="start" class="vehicles">
      <v-col cols="12">
        <v-data-table
          class="drp-routes-table"
          :class="{ 'border-top-color': filterPanelIsOpened }"
          :loading="pageLoading"
          :headers="displayHeaders"
          multi-sort
          :items="displayRows"
          hide-default-footer
          ref="table"
          :expanded="expanded"
          disable-pagination
          @update:options="paginate($event)"
          :options="{
            sortBy: routesSort.sortBy,
            sortDesc: routesSort.sortDesc,
          }"
        >
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
              :key="header.value"
              :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.station`]="{ item }">
            <StationLabel
              :stationsInUse="routePlan.stationsInUse"
              :targetStation="item.station"
              @onChangeData="
                item.station = $event;
                saveRowData(item);
              "
            />
          </template>

          <template v-slot:[`item.staging`]="{ item }">
            <StagingLabel
              :targetStaging="item.staging"
              @onChangeData="
                item.staging = $event;
                saveRowData(item);
              "
            />
          </template>

          <template v-slot:[`item.route`]="{ item }">
            <RouteLabel
              :targetRoute="item.route"
              :routesUsesCount="routePlan.routesUsesCount"
              @onChangeData="
                item.route = $event;
                saveRowData(item);
              "
            />
          </template>

          <template v-slot:[`item.mnt`]="{ item }">
            <MntCheckbox
              :targetRow="item"
              @onChangeData="saveEntryData(item)"
            />
          </template>

          <template v-slot:[`item.currentDriverEntry.status`]="{ item }">
            <div v-if="item.currentDriverEntry" class="driver-status-container">
              <DriverEntryStatus
                replacement-required
                :targetDate="targetDate"
                :targetDriverEntry="item.currentDriverEntry"
                :driverEntries="driverEntries"
                :additionalDataPerDriver="additionalDataPerDriver"
                @onChangeData="
                  item.currentDriverEntry.note = $event.newNote;
                  item.currentDriverEntry.status = $event.selectedStatus;
                  saveEntryOnChangeStatus(item, $event)
                "
              />
            </div>
          </template>

          <template v-slot:[`item.currentDriverEntry`]="{ item }">
            <div class="driver-entry-select-container">
              <DriverEntrySelectLabel
                :targetDate="targetDate"
                :targetDriverEntry="item.currentDriverEntry"
                :driverEntries="driverEntries"
                :additionalDataPerDriver="additionalDataPerDriver"
                @onChangeData="
                  item.currentDriverEntry = $event;
                  saveRowData(item);
                "
              />
            </div>
          </template>

          <template v-slot:[`item.vehicle`]="{ item }">
            <VehicleLabel
              :vehiclesUsesCount="routePlan.vehiclesUsesCount"
              :targetVehicle="item.vehicle"
              :printingDrpMode="printingDrpMode"
              @onChangeData="
                item.vehicle = $event;
                saveRowData(item)
              "
            />
          </template>

          <template v-slot:[`item.devices`]="{ item }">
            <DeviceListLabel
              :countDevicesInUse="routePlan.countDevicesInUse"
              :ownersPerDevice="routePlan.ownersPerDevice"
              :targetDevices="item.devices"
              :hostDriverEntry="item.currentDriverEntry"
              @onChangeData="
                item.devices = $event;
                saveRowData(item)
              "
            />
          </template>

          <template v-slot:[`item.custom-expand`]="{ item }">
            <v-btn-toggle v-model="expandedPanelOpeningState[item.id]">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :value="OPENED_RESCUES"
                    :disabled="!item.rescuers.length"
                    icon
                    small
                    @click="toggleRow(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-truck-fast
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$localizationService.localize("drp.routes_table.expanded.show_rescuers")}}</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :value="OPENED_HISTORY"
                    :disabled="!item.replacementsHistory.length"
                    icon
                    small
                    @click="toggleRow(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-history
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$localizationService.localize("drp.routes_table.expanded.show_history")}}</span>
              </v-tooltip>
            </v-btn-toggle>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center justify-start">
              <v-icon
                medium
                color="red"
                @click="openDeleteRowDialog(item)"
              >mdi-delete</v-icon>
              <v-tooltip open-delay="500" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="ml-2 add-rescuer-btn"
                    @click="createRescuer(item)"
                  >
                    <v-icon small class="track">mdi-truck-fast</v-icon>
                    <v-icon small class="plus">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>
                {{item.currentDriverEntry
                  ? $localizationService.localize(
                    "drp.routes_table.create_rescue.add_rescue_to",
                    [item.currentDriverEntry.driver.name]
                  )
                : $localizationService.localize("drp.routes_table.create_rescue.add_driver_to_rescue") }}

              </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:expanded-item="{ item }">
            <template v-if="expandedPanelOpeningState[item.id] === OPENED_HISTORY">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div
                  class="entry-cell driver-status-container"
                  v-for="entry in item.replacementsHistory"
                  :key="entry.id"
                >
                  <DriverEntryStatus
                    is-disabled
                    :targetDriverEntry="entry"
                  />
                </div>
              </td>
              <td>
                <div
                  class="entry-cell d-flex flex-row align-center"
                  v-for="entry in item.replacementsHistory"
                  :key="entry.id"
                >
                  <DriverEntryLabel
                    :customClass="'replaced-driver-label'"
                    :targetDriverEntry="entry"
                    :additionalDataPerDriver="
                      additionalDataPerDriver[entry.driver.id] || {}
                    "
                  />
                </div>
              </td>
              <td :colspan="4">
                <div
                  class="entry-cell"
                  v-for="entry in item.replacementsHistory"
                  :key="entry.id"
                >
                  <NoteLabel :isDisabled="true" :targetEntry="entry"/>
                </div>
              </td>
            </template>
            <template v-if="expandedPanelOpeningState[item.id] === OPENED_RESCUES">
              <td :colspan="3" class="pa-0">
                <div
                  class="entry-cell pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <RescueWidget
                    :pRescue="rescuer"
                    :pNestingIndex="rescuer.rescuersNestingIndexes"
                    @onChangeStatus="
                      rescuer.status = $event;
                      saveRescuer(rescuer);
                    "
                    @onChangePackages="
                      rescuer.packages = $event;
                      saveRescuer(rescuer);
                    "
                    @onChangeStops="
                      rescuer.stops = $event;
                      saveRescuer(rescuer);
                    "
                    @onChangeBonus="
                      rescuer.bonus.active = $event;
                      saveRescuer(rescuer);
                    "
                  />
                </div>
              </td>
              <td class="pa-0">
                <div
                  class="entry-cell pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <MntCheckbox
                    :targetRow="rescuer"
                    @onChangeData="saveEntryData(rescuer)"
                  />
                </div>
              </td>
              <td class="background-none pa-0">
                <div
                  class="entry-cell pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <DriverEntryStatus
                    v-if="rescuer.rescuer"
                    replacement-required
                    :targetDate="targetDate"
                    :targetDriverEntry="rescuer.rescuer"
                    :driverEntries="driverEntries"
                    :additionalDataPerDriver="additionalDataPerDriver"
                    @onChangeData="
                      rescuer.rescuer.note = $event.newNote;
                      rescuer.rescuer.status = $event.selectedStatus;
                      saveEntryOnChangeStatus(rescuer, $event)
                    "
                  />
                </div>
              </td>
              <td class="background-none pa-0" >
                <div
                  class="entry-cell d-flex flex-row align-center pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  :class="{'driver-entry-rescue' : hoveredRescueIndex == index + item.id}"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <DriverEntrySelectLabel
                    class="full-width-desl"
                    :targetDate="targetDate"
                    :targetDriverEntry="rescuer.rescuer"
                    :driverEntries="driverEntries"
                    :additionalDataPerDriver="additionalDataPerDriver"
                    @onChangeData="
                      rescuer.rescuer = $event;
                      saveRescuer(rescuer);
                    "
                  />
                </div>
              </td>
              <td class="background-none pa-0">
                <div
                  class="entry-cell pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <VehicleLabel
                    :vehiclesUsesCount="routePlan.vehiclesUsesCount"
                    :targetVehicle="rescuer.vehicle"
                    :printingDrpMode="printingDrpMode"
                    @onChangeData="
                      rescuer.vehicle = $event;
                      saveRescuer(rescuer);
                    "
                  />
                </div>
              </td>
              <td class="background-none pa-0">
                <div
                  class="entry-cell pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <DeviceListLabel
                    :countDevicesInUse="routePlan.countDevicesInUse"
                    :ownersPerDevice="routePlan.ownersPerDevice"
                    :targetDevices="rescuer.devices"
                    :hostDriverEntry="rescuer.rescuer"
                    @onChangeData="
                      rescuer.devices = $event;
                      saveRescuer(rescuer);
                    "
                  />
                </div>
              </td>
              <td class="pa-0 print-d-none">
                <div
                  class="entry-cell d-flex justify-start pl-4 pr-4"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                >
                  <v-icon
                    medium
                    color="red"
                    @click="openDeleteRescuerDialog(rescuer, item)"
                  >mdi-delete</v-icon>
                  <v-tooltip open-delay="500" v-if="rescuer.rescuer" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="ml-2 add-rescuer-btn"
                        @click="createRescuer(rescuer)"
                      >
                        <v-icon small class="track">mdi-truck-fast</v-icon>
                        <v-icon small class="plus">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Rescuer to {{ rescuer.rescuer.driver.name }}</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="pa-0 print-d-none">
                <div
                  class="entry-cell justify-center"
                  :style="getRowColor(rescuer)"
                  v-for="(rescuer, index) in displayRescuersByRowId[item.id]"
                  :key="rescuer.id"
                  @mousemove="hoveredRescueIndex = index + item.id"
                  @mouseleave="hoveredRescueIndex = undefined"
                ></div>
              </td>
            </template>
          </template>
        </v-data-table>
        <ConfirmationDialog ref="confirmationDialog"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import DriverEntryLabel from "../drp-common/entry/DriverEntryLabel";
import DriverEntrySelectLabel from "../drp-common/entry/DriverEntrySelectLabel";
import DriverEntryStatus from "../drp-common/entry/status/DriverEntryStatus";
import VehicleLabel from "../drp-common/vehicle/VehicleLabel";
import DeviceListLabel from "../drp-common/device/DeviceListLabel";
import RouteLabel from "../drp-common/route/RouteLabel";
import StagingLabel from "../drp-common/staging/StagingLabel";
import StationLabel from "../drp-common/station/StationLabel";
import NoteLabel from "../drp-common/note/NoteLabel";
import MntCheckbox from "../drp-common/MntCheckbox";
import RescueWidget from "../drp-common/rescue/RescueWidget";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import {
  ENTRY_STATUS_CONFIRMED,
  OPENED_RESCUES,
  OPENED_HISTORY,
} from "../../../utils/utils";
import tableSortParserMixin from "../../../utils/table-sort-parser";
import { DailyRouteRescue } from "@/model/daily-route-planner/rescue.model";

const locService = Vue.prototype.$localizationService;

export default {
  name: "DailyRoutePlannerTable",

  mixins: [tableSortParserMixin],

  components: {
    MntCheckbox,
    ConfirmationDialog,
    DriverEntryLabel,
    DriverEntrySelectLabel,
    DriverEntryStatus,
    VehicleLabel,
    DeviceListLabel,
    RouteLabel,
    StagingLabel,
    StationLabel,
    NoteLabel,
    RescueWidget,
  },

  props: {
    routePlan: Object,
    rows: Array,
    pageLoading: Boolean,
    filterState: Object,
    targetDate: String,
    driverEntries: Array,
    additionalDataPerDriver: Object,
    searchString: String,
    filterPanelIsOpened: Boolean,
    printingDrpMode: Boolean,
    pageIsMounted: Boolean,
  },

  watch: {
    pageIsMounted() {
      if (this.pageIsMounted === true) {
        this.openAllRescuers();
      }
    },
    rows() {
      if (this.pageIsMounted === true) {
        this.$store.dispatch("drpRoutesTableSettingsModule/checkRowsOnUpdateRescue", this.rows);
      }
      this.tableInited = true;
    },
  },

  data: () => ({
    OPENED_RESCUES,
    OPENED_HISTORY,

    hoveredRescueIndex: undefined,

    tableInited: false,

    headers: [
      {
        text: locService.localize("drp.routes_table.header.station"),
        value: "station",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.localeCompare(b);
        },
      },
      {
        text: locService.localize("drp.routes_table.header.staging"),
        value: "staging",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.localeCompare(b);
        },
      },
      {
        text: locService.localize("drp.routes_table.header.route"),
        value: "route",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.localeCompare(b);
        },
      },
      {
        text: locService.localize("drp.routes_table.header.mnt"),
        value: "mnt",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return 1;
        },
      },
      {
        text: locService.localize("drp.routes_table.header.confirmation"),
        value: "currentDriverEntry.status",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          if (a == ENTRY_STATUS_CONFIRMED) return -1;
          if (b == ENTRY_STATUS_CONFIRMED) return 1;
          return a.text.localeCompare(b.text);
        },
      },
      {
        text: locService.localize("drp.routes_table.header.current_driver"),
        value: "currentDriverEntry",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.driver.name.localeCompare(b.driver.name);
        },
      },
      {
        text: locService.localize("drp.routes_table.header.vehicle"),
        value: "vehicle",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.name.localeCompare(b.name);
        },
      },
      {
        text: locService.localize("drp.routes_table.header.device"),
        value: "devices",
        sortable: true,
        sort: (a, b) => a
          .map((aN) => aN.name)
          .join()
          .localeCompare(b.map((bN) => bN.name).join()),
      },
      {
        text: locService.localize("drp.routes_table.header.actions"),
        value: "actions",
        align: "start",
        sortable: false,
      },
      {
        text: "",
        value: "custom-expand",
        sortable: false,
        width: "24px",
      },
    ],
  }),

  computed: {
    ...mapState("drpRoutesTableSettingsModule", [
      "routesSort",
      "defaultSettings",
      "sortPropertyName",
      "expanded",
      "expandedPanelOpeningState",
    ]),
    ...mapGetters("drpRoutesTableSettingsModule", [
      "isDefaultSort",
    ]),

    displayRescuersByRowId() {
      if (
        !this.routesSort
        || !this.headers
        || !this.headers.length
        || !this.routePlan.id
      ) return {};

      const valueAndSort = this.headers.map((it) => {
        let value = it.value;
        if (it.value === "currentDriverEntry") {
          value = "rescuer";
        }
        if (it.value === "currentDriverEntry.status") {
          value = "rescuer.status";
        }
        return {
          value,
          sort: it.sort,
        };
      });

      const sort = { ...this.routesSort };
      sort.sortBy = sort.sortBy.map((it) => {
        if (it === "currentDriverEntry") {
          return "rescuer";
        }
        if (it === "currentDriverEntry.status") {
          return "rescuer.status";
        }
        return it;
      });

      return this.routePlan.getAllRescuers(sort, valueAndSort);
    },

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems(this.sortPropertyName)
        : [];
    },

    displayHeaders() {
      return !this.printingDrpMode
        ? this.headers
        : this.headers.filter((it) => it.value !== "custom-expand" && it.value !== "actions");
    },

    displayRows() {
      let rows = this.rows;

      if (this.searchString || this.filterState.criteriaCount) {
        rows = rows.filter((row) => this.itemHasCoincidences(row));
        this.$store.dispatch("drpRoutesTableSettingsModule/openClosedRescueExpanded", rows);
      }

      return rows;
    },
  },

  methods: {
    itemHasCoincidences(verifiableItem) {
      return (verifiableItem.isRow && verifiableItem.isClear)
        || (this.checkOnValidBySearch(verifiableItem) && this.checkOnValidByCriteria(verifiableItem))
        || verifiableItem.rescuersHasCoincidences(this.itemHasCoincidences);
    },

    checkOnValidBySearch(verifiableItem) {
      const str = this.searchString.trim().toLowerCase();

      if (str) {
        return (
          (verifiableItem.isRow
            && (
              (verifiableItem.station && verifiableItem.station.toLowerCase().includes(str))
              || (verifiableItem.staging && verifiableItem.staging.toLowerCase().includes(str))
              || (verifiableItem.route && verifiableItem.route.toLowerCase().includes(str))
            ))
          || (verifiableItem.status && verifiableItem.status.text.toLowerCase().includes(str))
          || (verifiableItem.driverEntry
            && (verifiableItem.driverEntry.driver.name.toLowerCase().includes(str)
              || verifiableItem.driverEntry.status.text.toLowerCase().includes(str))
          )
          || (verifiableItem.vehicle && (
            verifiableItem.vehicle.name.toLowerCase().includes(str)
            || (
              verifiableItem.vehicle.fuelCard
              && (
                verifiableItem.vehicle.fuelCard.cardId.toLowerCase().includes(str)
                || verifiableItem.vehicle.fuelCard.cardNumber.toLowerCase().includes(str)
              )
            )
          ))
          || (verifiableItem.devices.find((device) => device.name.toLowerCase().includes(str)))
        );
      }
      return true;
    },

    checkOnValidByCriteria(verifiableItem) {
      if (this.filterState.criteriaCount) {
        return verifiableItem.driverEntry
          ? this.filterState.driverIds.includes(verifiableItem.driverEntry.driver.id)
          : false;
      }
      return true;
    },

    openAllRescuers() {
      this.$store.commit("drpRoutesTableSettingsModule/openAllRescuers", {
        rows: this.rows,
        tableInited: this.tableInited,
      });
    },

    getRowColor(rescuer) {
      return DailyRouteRescue.getRescueRowColor(
        rescuer.rescuersNestingIndexes,
      );
    },

    paginate(data) {
      this.$store.commit("drpRoutesTableSettingsModule/changeSettings", {
        routesSort: {
          sortBy: data.sortBy,
          sortDesc: data.sortDesc,
        },
      });
      this.$emit("onUpdateSort");
    },

    async createRescuer(target) {
      if (!target.driverEntry && !target.rescuer) {
        Vue.toasted.error(
          locService.localize("drp.routes_table.create_rescue.add_driver_to_rescue"),
          { duration: 3000 },
        );
        return;
      }

      await this.$store.dispatch("dailyRoutePlannerModule/createRescue", target);

      if (target.isRow) {
        this.expandedPanelOpeningState[target.id] = OPENED_RESCUES;
        this.toggleRow(target);
      }
    },

    saveRescuer(rescuer) {
      this.$store.dispatch("dailyRoutePlannerModule/saveRescue", rescuer);
    },

    openDeleteRescuerDialog(rescue, row) {
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize("drp.rescuers_table.dialog.delete.title"),
        text: locService.localize("drp.rescuers_table.dialog.delete.text"),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          await this.$store.dispatch("dailyRoutePlannerModule/deleteRescue", rescue);
          if (!row.rescuers.length) {
            this.expandedPanelOpeningState[row.id] = undefined;
            this.toggleRow(row);
          }
        },
      });
    },

    saveRowData(item) {
      this.$store.dispatch("dailyRoutePlannerModule/saveRow", item);
    },

    async saveEntryOnChangeStatus(item, { replacement }) {
      await this.saveEntryData(item);

      if (replacement) {
        item.driverEntry = replacement;
        if (item.isRow) {
          this.saveRowData(item);
        } else {
          this.saveRescuer(item);
        }
      }
    },

    async saveEntryData(item) {
      await this.$store.dispatch("dailyRoutePlannerModule/saveDriverEntry", item.driverEntry);
    },

    openDeleteRowDialog(row) {
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize("drp.routes_table.dialog.delete.title"),
        text: locService.localize("drp.routes_table.dialog.delete.text"),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$store.dispatch("dailyRoutePlannerModule/deleteRow", {
          rowId: row.id,
          version: row.version,
        }),
      });
    },

    toggleRow(item) {
      Vue.nextTick(() => {
        this.$store.commit("drpRoutesTableSettingsModule/toggleRow", item);
      });
    },
  },
  mounted() {
    // for first page visit
    if ((this.rows || []).every((it) => !this.expandedPanelOpeningState[it.id])) {
      this.openAllRescuers();
    }
    if (this.pageIsMounted === true) {
      this.$store.dispatch("drpRoutesTableSettingsModule/checkRowsOnUpdateRescue", this.rows);
    }
  },
};
</script>

<style scoped lang="scss">
.add-rescuer-btn {
  .track {
    position: absolute;
    top: -8px;
    left: 5px;
    opacity: 0.5;
  }
  .plus {
    position: absolute;
    top: -18px;
    left: 13px;
  }
  &:hover {
    .track {
      opacity: 1;
    }
  }
}
.small-checkbox {
  max-width: fit-content;

  i {
    font-size: 20px !important;
  }

  .v-label {
    font-size: 14px;
  }

  .v-input--selection-controls__ripple {
    height: 28px;
    width: 28px;
    left: -9px;
    top: -9px;
  }
}

.entry-cell {
  height: 48px;
  max-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

<style lang="scss">
.light-local-user-theme {
  .drp-routes-table {
    &.border-top-color {
      border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    tr {
      td:nth-child(1),
      th:nth-child(1),
      td:nth-child(2),
      th:nth-child(2),
      td:nth-child(3),
      th:nth-child(3),
      td:nth-child(4),
      th:nth-child(4) {
        background-color: rgba(100, 100, 100, 0.05); // DSPS-147
      }
    }
  }
}

.dark-local-user-theme {
  .drp-routes-table {
    &.border-top-color {
      border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    tr {
      td:nth-child(1),
      th:nth-child(1),
      td:nth-child(2),
      th:nth-child(2),
      td:nth-child(3),
      th:nth-child(3),
      td:nth-child(4),
      th:nth-child(4) {
        background-color: rgba(255, 255, 255, 0.1); // DSPS-147
      }
    }
  }
}

.drp-routes-table {
  tr.v-data-table__expanded__content {
    box-shadow: none !important;
  }

  tr.v-data-table__expanded__row:hover {
    .desl-message-button {
      opacity: 1;
    }
  }

  tr:hover:not(.v-data-table__expanded__content) {
    .desl-message-button {
      opacity: 1;
    }
  }

  .replaced-driver-label {
    text-decoration: line-through;
  }

  .driver-entry-rescue {
    .desl-message-button {
      opacity: 1;
    }
  }

  .driver-entry-select-container {
    max-width: 375px;
    padding-top: 12px;
    padding-bottom: 8px;
  }

  .full-width-desl {
    width: 100%;
  }
}

</style>
