<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
                editedTemplateConstant.id
                    ? "template_constant_page.dialog.add_and_edit.edit_title"
                    : "template_constant_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  :rules="nameRule"
                  v-model="editedTemplateConstant.name"
                  :label="
                      $localizationService.localize(
                          'template_constant_page.dialog.add_and_edit.param.name'
                      )
                  "
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  :rules="requiredRule"
                  v-model="editedTemplateConstant.value"
                  :label="
                      $localizationService.localize(
                          'template_constant_page.dialog.add_and_edit.param.value'
                      )
                  "
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  rows="3"
                  no-resize
                  v-model="editedTemplateConstant.description"
                  :label="
                      $localizationService.localize(
                          'template_constant_page.dialog.add_and_edit.param.description'
                      )
                  "
                  :disabled="loading"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="addOrSaveTemplateConstant"
            :disabled="
              loading
              || !editedTemplateConstant.name
              || !editedTemplateConstant.value
            "
        >
          {{ $localizationService.localize(editedTemplateConstant.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { TemplateConstant } from "../../model/template-constants.model";
import { CONSTANT_NAME_REGEX } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditTemplateConstantsDialog",

  data: () => ({
    open: false,
    loading: false,

    editedTemplateConstantOriginal: {},
    editedTemplateConstant: {},

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

    nameRule: [
      (value) => CONSTANT_NAME_REGEX.test(value) || locService.localize("error.validation.valid_template_name"),
    ],

  }),

  methods: {

    openDialog(templateConstant) {
      this.editedTemplateConstantOriginal = templateConstant || {};
      this.editedTemplateConstant = Object.assign(new TemplateConstant(), this.editedTemplateConstantOriginal);
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    async addOrSaveTemplateConstant() {
      this.loading = true;
      await this.$store.dispatch(
        "templateConstantsModule/addOrSaveTemplateConstant",
        this.editedTemplateConstant,
      );
      this.$emit("onAddOrSave");
      this.loading = false;
      this.open = false;
    },
  },
};
</script>
