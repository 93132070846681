<template>
  <v-dialog v-model="open" max-width="60%">
    <v-card>
      <v-card-title class="d-flex align-baseline">
        <span class="headline flex-grow-1">
          {{ $localizationService.localize("vehicle_reminder_page.meter_per_vehicle_dialog.title")}}
        </span>
    </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense v-for="item in targets" :key="item.id" align-content="center" justify="center">
            <v-col class="mb-3 pb-0" align-self="center">
              <div class="target-name">{{item.name || item.vin}}</div>
            </v-col>
            <v-col class="mb-0">
             <v-text-field
               dense
               :label="$localizationService.localize('vehicle_reminder_page.dialog.due_meter')"
               suffix="mi"
               :value="getValue(item)"
               type="number"
               @input="changeMileageByTarget(item, $event)"
             />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="confirm"
        >
          {{ $localizationService.localize("btn.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "MileagePerVehicleDialog",
  props: {
    targets: Array,
  },

  data: () => ({
    open: false,
    targetsByMileage: [],
  }),

  methods: {
    openDialog() {
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    confirm() {
      this.$emit("onEditTargetsByMileage", this.targetsByMileage);
      this.closeDialog();
    },

    changeMileageByTarget(item, value) {
      const index = this.targetsByMileage.findIndex((it) => it.vehicle.id === item.id);
      if (index !== -1) {
        this.targetsByMileage[index].firstFireAtMeter = value;
      } else this.targetsByMileage.push({ vehicle: item, firstFireAtMeter: value });
    },

    getValue(item) {
      const index = this.targetsByMileage.findIndex((it) => it.vehicle.id === item.id);
      if (index !== -1) {
        return this.targetsByMileage[this.targetsByMileage.findIndex((it) => it.vehicle.id === item.id)].firstFireAtMeter;
      }
      return "";
    },

  },
};
</script>

<style scoped lang="scss">
.target-name {
  font-size: 15px;
  color: var(--v-themeTextColor-base) !important;

}
.general-date {
  width: 30%;
}
</style>
