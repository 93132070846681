<template>
  <div>
    <v-data-table
      class="drp-entries-table"
      :class="{ 'border-top-color': filterPanelIsOpened }"
      :headers="displayHeaders"
      :items="displayDrivers"
      :loading="pageLoading"
      multi-sort
      hide-default-footer
      disable-pagination
      @update:options="paginate($event)"
      :options="{
        sortBy: driversSort.sortBy,
        sortDesc: driversSort.sortDesc,
      }"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        <span
          :key="header.value"
          :class="{
            'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
          }"
        >
          {{ header.text }}
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <DriverEntryStatus
          :targetDriverEntry="item"
          @onChangeData="
            item.note = $event.newNote;
            item.status = $event.selectedStatus;
            saveData(item)
          "
        />
      </template>

      <template v-slot:[`item.reserve`]="{ item }">
        <v-simple-checkbox
          class="small-checkbox"
          v-model="item.reserve"
          v-ripple
          @input="saveData(item)"
        ></v-simple-checkbox>
      </template>

      <template v-slot:[`item.driver`]="{ item }">
        <div style="display: flex">
          <DriverEntryLabel
            :targetDriverEntry="item"
            :additionalDataPerDriver="
              additionalDataPerDriver[item.driver.id] || {}
            "
          />
          <MessageButton
            :target="MessagingTargets.driver(item.driver.id)"
          />
        </div>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <NoteLabel
          :targetEntry="item"
          @onChangeData="
            item.note = $event;
            saveData(item);
          "
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium color="red" @click="openDeleteEntryDialog(item)"
        >mdi-delete
        </v-icon
        >
      </template>
    </v-data-table>
    <ConfirmationDialog ref="confirmationDialog"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import DriverEntryLabel from "../drp-common/entry/DriverEntryLabel.vue";
import DriverEntryStatus from "../drp-common/entry/status/DriverEntryStatus.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import NoteLabel from "../drp-common/note/NoteLabel";
import { ENTRY_STATUS_CONFIRMED, MessagingTargets } from "../../../utils/utils";
import tableSortParserMixin from "../../../utils/table-sort-parser";
import MessageButton from "../../MessageButton";

const locService = Vue.prototype.$localizationService;

export default {
  name: "DailyRoutePlannerDrivers",

  mixins: [tableSortParserMixin],

  components: {
    ConfirmationDialog,
    DriverEntryLabel,
    DriverEntryStatus,
    NoteLabel,
    MessageButton,
  },

  props: {
    pageLoading: Boolean,
    filterState: Object,
    driverEntries: Array,
    additionalDataPerDriver: Object,
    searchString: String,
    filterPanelIsOpened: Boolean,
    printingDrpMode: Boolean,
  },

  data: () => ({
    MessagingTargets,
    headers: [
      {
        text: locService.localize("drp.drivers_table.header.confirmation"),
        value: "status",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          if (a === ENTRY_STATUS_CONFIRMED) return -1;
          if (b === ENTRY_STATUS_CONFIRMED) return 1;
          return a.text.localeCompare(b.text);
        },
      },
      {
        text: locService.localize("drp.drivers_table.header.is_reserve"),
        value: "reserve",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return 1;
        },
      },
      {
        text: locService.localize("drp.drivers_table.header.driver"),
        value: "driver",
        sortable: true,
        sort: (a, b) => a.name.localeCompare(b.name),
      },
      {
        text: locService.localize("drp.drivers_table.header.note"),
        value: "note",
        width: "40%",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.localeCompare(b);
        },
      },
      {
        text: locService.localize("drp.drivers_table.header.actions"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState("drpDriversTableSettingsModule", [
      "driversSort",
      "defaultSettings",
      "sortPropertyName",
    ]),
    ...mapGetters("drpDriversTableSettingsModule", [
      "isDefaultSort",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems(this.sortPropertyName)
        : [];
    },

    displayHeaders() {
      return !this.printingDrpMode
        ? this.headers
        : this.headers.filter((it) => it.value !== "actions");
    },

    displayDrivers() {
      let { driverEntries } = this;

      if (this.searchString) {
        const str = this.searchString.trim().toLowerCase();

        driverEntries = (this.driverEntries || []).filter(
          (driverEntry) => (driverEntry.driver
              && driverEntry.driver.name.toLowerCase().includes(str))
            || (driverEntry.status
              && driverEntry.getDisplayStatus().toLowerCase().includes(str)),
        );
      }

      return this.filterState.criteriaCount
        ? driverEntries.filter((entry) => this.filterState.driverIds.includes(entry.driver.id))
        : driverEntries;
    },
  },

  methods: {
    paginate(data) {
      this.$store.commit("drpDriversTableSettingsModule/changeSettings", {
        driversSort: {
          sortBy: data.sortBy,
          sortDesc: data.sortDesc,
        },
      });
      this.$emit("onUpdateSort");
    },

    async saveData(item) {
      await this.$store.dispatch(
        "dailyRoutePlannerModule/saveDriverEntry",
        item,
      );
    },

    openDeleteEntryDialog(entry) {
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize("drp.drivers_table.dialog.delete.title"),
        text: locService.localize("drp.drivers_table.dialog.delete.text", [
          entry.driver.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$store.dispatch(
          "dailyRoutePlannerModule/deleteDriverEntry",
          { entryId: entry.id, version: entry.version },
        ),
      });
    },
  },
};
</script>

<style lang="scss">
.light-local-user-theme {
  .drp-entries-table {
    &.border-top-color {
      border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.dark-local-user-theme {
  .drp-entries-table {
    &.border-top-color {
      border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
  }
}

.drp-entries-table {
  .small-checkbox {
    max-width: fit-content;

    i {
      font-size: 20px !important;
    }

    .v-label {
      font-size: 14px;
    }

    .v-input--selection-controls__ripple {
      height: 28px;
      width: 28px;
      left: -9px;
      top: -9px;
    }
  }

  .search-field {
    max-width: 250px;
  }
}
</style>
