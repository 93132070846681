<template>
  <div class="d-flex flex-row align-center entry-label">
    <div :class="{ 'driver-routes-z-index': visibleOptionalParams }">
      <v-tooltip open-delay="500" bottom :disabled="!isDuplicate">
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            :class="[fullNameClass, customClass]"
            class="w-110 no-wrap"
            >{{ targetDriverEntry.driver.name }}</span
          >
        </template>
        <span>{{
          $localizationService.localize("drp.value_already_in_use")
        }}</span>
      </v-tooltip>
      <div v-if="visibleOptionalParams">
        <span class="sub-text mr-2" v-if="targetDriverEntry.routes.length == 1">
          {{ targetDriverEntry.routes[0] }}
        </span>
        <v-tooltip v-else-if="targetDriverEntry.routes.length > 1" open-delay="500" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="sub-text mr-2">
              {{
                $localizationService.localize("drp.entry_label.routes_count", [
                  targetDriverEntry.routes.length,
                ])
              }}
            </span>
          </template>
          <span> {{ targetDriverEntry.routes.join(", ") }} </span>
        </v-tooltip>
        <span class="sub-text" :class="targetDriverEntry.status.color">
          {{ targetDriverEntry.status.text }}
        </span>
      </div>
    </div>
    <div class="d-flex justify-center align-center margin-left">
      <v-tooltip v-if="targetDriverEntry.reserve" open-delay="300" top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            class="margin-left green no-elastic-chip white--text"
          >
            {{
              $localizationService.localize(
                "drp.entry_label.is_reserve.not_full"
              )
            }}
          </v-chip>
        </template>
        {{ $localizationService.localize("drp.entry_label.is_reserve.full") }}
      </v-tooltip>
      <v-tooltip open-delay="300" top>
        <template v-slot:activator="{ on, attrs }">
          <router-link
            :to="`/infractions/driver/${targetDriverEntry.driver.id}`"
            class="decor-none"
            @click.stop=""
          >
            <v-chip
              v-if="infractionsCount"
              v-bind="attrs"
              v-on="on"
              class="infractions-chip margin-left red no-elastic-chip white--text"
            >
              <v-avatar left class="red darken-4 print-ma-0">
                {{ infractionsCount }}
              </v-avatar>
              {{ $localizationService.localize("drp.entry_label.infractions") }}
            </v-chip>
          </router-link>
        </template>
        <div class="inf-chip-card">
          <v-chip
            v-for="item of infractionsPerCriteria"
            :key="item.criteria.id"
            class="red mr-2 mb-1 mt-1 white--text"
          >
            <v-avatar left class="red darken-4">
              {{ item.infractionIds.length }}
            </v-avatar>
            {{ item.criteria.name }}
          </v-chip>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "DriverEntryLabel",

  props: {
    customClass: String,
    targetDriverEntry: Object,
    nameClass: String,
    additionalDataPerDriver: Object,
    isDuplicate: Boolean,
    visibleOptionalParams: Boolean,
  },

  computed: {
    fullNameClass() {
      return `${this.nameClass} ${this.isDuplicate ? "orange--text" : ""}`;
    },

    infractionsPerCriteria() {
      return this.targetDriverEntry.driver
        ? this.additionalDataPerDriver.infractionsPerCriteria || []
        : [];
    },

    infractionsCount() {
      return this.infractionsPerCriteria.map((it) => it.infractionIds).flat()
        .length;
    },
  },
};
</script>

<style scoped lang="scss">
.driver-routes-z-index {
  z-index: 9999;
}
.inf-chip-card {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
}
.decor-none {
  text-decoration: none;
}
.sub-text {
  font-size: 12.5px;
}
.no-wrap {
  white-space: nowrap;
}
.replaced-driver-label {
  text-decoration: line-through;
}
.tooltip-infraction-chips-container {
  max-width: 350px;
}
.infractions-chip {
  cursor: pointer;
}
.margin-left {
  margin-left: 8px;
}
</style>
