<template>
  <div>
    <div
      v-if="state == 'view'"
      class="driver-entry-status-view-container"
      @click="!isDisabled ? (state = 'edit') : ''"
      :class="{ 'btn-cursor': !isDisabled }"
    >
      <span v-if="targetDriverEntry.status" :class="targetDriverEntry.status.color">
        {{ targetDriverEntry.status.text }}
      </span>

      <v-btn
        v-else
        x-small
        class="theme-text-color"
        color="secondary"
        :disabled="isDisabled"
      >
        {{
          $localizationService.localize("drp.driver_entry_status.set_status")
        }}
      </v-btn>
    </div>
    <div
      v-else
      class="driver-entry-status-edit-container d-flex flex-row align-center"
    >
      <v-select
        class="status-select pa-0 ma-0"
        :items="availableStatuses"
        :value="targetDriverEntry.status"
        error-count="0"
        hide-details
        item-text="text"
        item-value="value"
        return-object
        @change="onSelect($event)"
      >
        <template v-slot:selection="{ item }">
          <div :class="item.color">{{ item.text }}</div>
        </template>
        <template v-slot:item="{ item }">
          <div :class="item.color">{{ item.text }}</div>
        </template>
      </v-select>
      <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
    </div>
    <DriverEntryStatusChangeDialog
      :targetDate="targetDate"
      :driverEntries="driverEntries"
      :additionalDataPerDriver="additionalDataPerDriver"
      ref="driverEntryStatusChangeDialog"
    />
  </div>
</template>

<script>
import DriverEntryStatusChangeDialog from "./DriverEntryStatusChangeDialog";
import { DRP_ENTRY_STATUSES } from "../../../../../utils/utils";

export default {
  name: "DriverEntryStatus",

  components: {
    DriverEntryStatusChangeDialog,
  },

  props: {
    targetDate: String,
    targetDriverEntry: Object,
    replacementRequired: {
      type: Boolean,
      default: false,
    },
    driverEntries: Array,
    additionalDataPerDriver: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    state: "view",
    availableStatuses: Object.values(DRP_ENTRY_STATUSES),
  }),

  methods: {
    onSelect(selectedStatus) {
      if (!selectedStatus) return;

      this.$refs.driverEntryStatusChangeDialog.openDialog({
        targetDriverEntry: this.targetDriverEntry,
        selectedStatus,
        replacementRequired: this.replacementRequired,
        onConfirm: (note, replacement) => {
          this.state = "view";
          this.$emit("onChangeData", {
            newNote: note,
            selectedStatus,
            replacement,
          });
        },
        onCancel: this.cancel,
      });
    },

    cancel() {
      this.state = "view";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-cursor {
  cursor: pointer;
}
.driver-entry-status-view-container {
  width: fit-content;
}
.driver-entry-status-edit-container {
  width: 150px;
}
</style>
<style lang="scss">
.driver-entry-status-edit-container {
  .status-select {
    .v-select__selections input{
      display: none !important;
    }
  }
}
</style>
