var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditDriverDialog',{ref:"editDriverDialog",on:{"editedDriver":_vm.setSelectedDriver}}),_c('div',{staticClass:"content-container w-100"},[(!_vm.selectedAction.isMatchToExistingDriver && !_vm.selectedAction.isCreateNewDriver)?_c('v-text-field',{staticClass:"mr-2",class:{'partial-auto-match-border-color': _vm.shouldHighlightPartialAutoMatch},attrs:{"label":_vm.$localizationService.localize(
          'mar_component.driver_selection_request.param.name'
        ),"disabled":"","value":_vm.request.autoMatchedDriver && _vm.selectedAction.isAcceptAutoMatched
          ? _vm.request.autoMatchedDriver.name
          : ''}}):_vm._e(),(_vm.selectedAction.isMatchToExistingDriver)?_c('v-autocomplete',{ref:"inputName",staticClass:"mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.driver_selection_request.param.name'
        ),"item-text":"text","item-value":"value","items":_vm.matchedDriversEntries,"search-input":_vm.searchDriver,"loading":_vm.loadingDriversSearch,"clearable":"","disabled":!_vm.selectedAction.isMatchToExistingDriver},on:{"update:searchInput":function($event){_vm.searchDriver=$event},"update:search-input":function($event){_vm.searchDriver=$event},"focus":function($event){return _vm.onNameFieldFocused()}},model:{value:(_vm.selectedDriver),callback:function ($$v) {_vm.selectedDriver=$$v},expression:"selectedDriver"}}):_vm._e(),(_vm.selectedAction.isCreateNewDriver)?_c('v-text-field',{staticClass:"mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.driver_selection_request.param.name'
        )},model:{value:(_vm.newDriverName),callback:function ($$v) {_vm.newDriverName=$$v},expression:"newDriverName"}}):_vm._e(),(!_vm.selectedAction.isCreateNewDriver)?_c('v-text-field',{staticClass:"mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.driver_selection_request.param.transporter_id'
        ),"rules":[_vm.validateTransporterId],"disabled":!_vm.selectedAction.isMatchToExistingDriver},model:{value:(_vm.currentTransporterId),callback:function ($$v) {_vm.currentTransporterId=$$v},expression:"currentTransporterId"}}):_vm._e(),(_vm.selectedAction.isCreateNewDriver)?_c('v-text-field',{staticClass:"mr-2",attrs:{"label":_vm.$localizationService.localize(
          'mar_component.driver_selection_request.param.transporter_id'
        )},model:{value:(_vm.newTransporterId),callback:function ($$v) {_vm.newTransporterId=$$v},expression:"newTransporterId"}}):_vm._e(),_c('div',{staticClass:"select-and-icon"},[_c('v-select',{ref:"selectAction",staticClass:"select-action-mobile",attrs:{"label":_vm.$localizationService.localize(
            'mar_component.driver_selection_request.param.select_action'
          ),"items":_vm.actionsList,"item-text":"text","item-value":"value","return-object":""},on:{"change":function($event){return _vm.onActionSelect($event)}},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),(_vm.status.type == 'LOADING')?_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-progress-circular',{staticClass:"ml-4",attrs:{"size":25,"width":2.5,"color":"primary","indeterminate":""}})],1):(_vm.status.type == 'OK')?_c('v-icon',{staticClass:"ml-4 mr-2",attrs:{"size":"30","color":_vm.shouldHighlightPartialAutoMatch || (_vm.selectedAction || {}).isCreateNewDriver ? 'orange' : 'green'}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_c('v-icon',{staticClass:"ml-4 mr-2",attrs:{"size":"30","color":_vm.errorColor}},[_vm._v("mdi-alert-circle-outline")])],1)],1),_c('p',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(
    _vm.$localizationService.localize(
      'mar_component.driver_selection_request.raw_data',
      [_vm.rawName, _vm.request.rawTransporterId || '']
    )
   )}}),(_vm.request.autoMatchedDriver && !_vm.selectedAction.isAcceptAutoMatched)?_c('p',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "mar_component.driver_selection_request.auto_matched_driver", [_vm.request.autoMatchedDriver.name] ))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }