import { IssueDataUnit } from "@/model/issue/data-unit.model";
import { IssueDataUnitDto, IssueUnitType, LightweightTriggerDto } from "@/types/types";
import { ISSUE_DATA_TRIGGER_UNIT_TYPE } from "@/utils/ts-utils";
import { LightweightTrigger } from "@/model/trigger/trigger.lightweight-model";

export class IssueDataUnitTrigger extends IssueDataUnit {
    trigger: LightweightTrigger;

    constructor(
      id: string,
      type: IssueUnitType,
      trigger: LightweightTrigger,
    ) {
      super(id, type);
      this.trigger = trigger;
    }

    static fromDto(dto: IssueDataUnitDto): IssueDataUnit {
      return new IssueDataUnitTrigger(
        dto.id,
        ISSUE_DATA_TRIGGER_UNIT_TYPE,
        LightweightTrigger.fromDto(dto.trigger as LightweightTriggerDto),
      );
    }
}
