import { IssueDataUnit } from "@/model/issue/data-unit.model";
import { Vehicle } from "@/model/vehicle.model";
import { IssueDataUnitDto, IssueUnitType } from "@/types/types";
import { ISSUE_DATA_VEHICLE_UNIT_TYPE } from "@/utils/ts-utils";

export class IssueDataUnitVehicle extends IssueDataUnit {
    vehicle: Vehicle | null;

    constructor(
      id: string,
      type: IssueUnitType,
      vehicle: Vehicle | null,
    ) {
      super(id, type);
      this.vehicle = vehicle;
    }

    static fromDto(dto: IssueDataUnitDto): IssueDataUnit {
      return new IssueDataUnitVehicle(
        dto.id,
        ISSUE_DATA_VEHICLE_UNIT_TYPE,
        dto.vehicle ? Vehicle.fromDto(dto.vehicle) : null,
      );
    }

    toDto() {
      return {
        id: this.id,
        type: this.type.type,
        vehicleId: this.vehicle?.id,
      };
    }

    static createNew(vehicle: Vehicle) {
      return new IssueDataUnitVehicle(
        "",
        ISSUE_DATA_VEHICLE_UNIT_TYPE,
        vehicle,
      );
    }
}
