<template>
  <div>
    <v-row>
      <v-col class="d-flex">
        <div class="title-width">
          <h3>
            {{
              $localizationService.localize(
                "mar_component.report_item_properties_fix_r_a.row"
              )
            }}
            #{{ request.rowNumber }}
          </h3>
          <span>
            {{
              $localizationService.localize(
                "mar_component.report_item_properties_fix_r_a.unrecognized_fields"
              )
            }}
            :
            {{ getPropertiesFixesRow(request.requiredFixes) }}</span
          >
          <p v-if="userFixes.length">
            {{
              $localizationService.localize(
                "mar_component.report_item_properties_fix_r_a.fixed_or_edited"
              )
            }}
            :
            {{ getPropertiesFixesRow(userFixes) }}
          </p>
        </div>
        <div class="btn-width d-flex justify-end align-center">
          <v-btn
            color="primary"
            @click="
              open = true;
              save = false;
            "
          >
            {{ $localizationService.localize("btn.edit") }}
          </v-btn>

          <v-icon v-show="status" class="ml-3 mr-3" size="30" color="green"
            >mdi-checkbox-marked-circle-outline</v-icon
          >

          <v-icon
            v-show="!status"
            class="ml-3 mr-3"
            size="30"
            :color="errorColor"
            >mdi-alert-circle-outline</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="open" max-width="700px">
      <v-card class="card-height">
        <div class="card-container">
          <v-card-title>
            <h3>
              {{
                $localizationService.localize(
                  "mar_component.report_item_properties_fix_r_a.row"
                )
              }}
              #{{ request.rowNumber }}
            </h3>
          </v-card-title>
          <v-card-text class="card-text-height">
            <div v-for="(item, index) in request.properties" :key="index">
              <ItemPropertiesComponent
                ref="itemPropertiesComponent"
                :property="item"
                :initMode="getMode(item)"
              />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="open = false">
              {{ $localizationService.localize("btn.close") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              :disabled="checkSaveButtonIsDisabled()"
              @click="
                open = false;
                updateCurrentSolution();
              "
            >
              {{ $localizationService.localize("btn.save") }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import ItemPropertiesComponent from "../for-agents/ItemPropertiesComponent";
import { fixMode, viewMode, editMode } from "../../utils/utils";

export default {
  name: "ReportItemPropertiesFixRequest",
  targetRequestType: "ReportItemPropertiesFixRequest",

  components: {
    ItemPropertiesComponent,
  },

  props: {
    request: Object,
  },

  data: () => ({
    open: false,

    currentSolution: undefined,
    userFixes: [],

    itemPropertiesComponents: [],
  }),

  watch: {
    open() {
      this.$nextTick(() => {
        this.itemPropertiesComponents = [...this.$refs.itemPropertiesComponent];
      });
    },
  },

  computed: {
    ...mapState("marModule", ["autoIgnoreRequests"]),
    errorColor() {
      return this.autoIgnoreRequests.find(
        (suspect) => suspect.id == this.request.id,
      )
        ? "grey"
        : "red";
    },

    status() {
      return this.solution;
    },

    solution() {
      return this.currentSolution;
    },
  },

  methods: {
    updateCurrentSolution() {
      this.currentSolution = {
        fixes: this.itemPropertiesComponents
          .filter((component) => component.mode != viewMode)
          .map((component) => component.solution),
      };

      this.userFixes = this.currentSolution.fixes.map((item) => item.name);
    },

    getMode(item) {
      if (this.request.requiredFixes.includes(item.name)) return fixMode;
      if (this.userFixes.includes(item.name)) return editMode;
      return viewMode;
    },

    getPropertiesFixesRow(fixes) {
      return this.request.properties
        .filter((item) => fixes.includes(item.name))
        .map(
          (item) => Vue.prototype.$localizationService.namesMap[item.displayName],
        )
        .join(", ");
    },

    checkSaveButtonIsDisabled() {
      return !!(
        this.itemPropertiesComponents.length
        && this.itemPropertiesComponents.find(
          (item) => !item.solution && item.mode != viewMode,
        )
      );
    },
  },

  toModel(dto) {
    return {
      ...dto,

      getAutoGeneratedSolution() {
        return undefined;
      },

      getAutoIgnoreSolution() {
        return {
          fixes: this.properties
            .filter((prop) => this.requiredFixes.includes(prop.name))
            .map((prop) => ({
              name: prop.name,
              rawSelectedValue: "",
            })),
        };
      },
    };
  },
};
</script>

<style scoped lang="scss">
.title-width {
  width: 80%;
  h3 {
    color: white;
  }
}
.btn-width {
  width: 20%;
}
.card-height {
  max-height: 90vh;
  .card-container {
    padding: 0 12px 0 12px;
    .card-text-height {
      max-height: 70vh;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}
@media screen and (max-width: 425px) {
  .title-width {
    max-width: 70vw;
  }
  .btn-width {
    min-width: 30vw;
  }
}
</style>
