











import {
  Component, Emit, Vue, Watch,
} from "vue-property-decorator";
import { Issue } from "@/model/issue/issue.model";
import IssueCard from "@/components/issues/issue-card/IssueCard.vue";

@Component({
  components: {
    IssueCard,
  },
})
export default class IssueCardDialog extends Vue {
  issue!: Issue;

  open = false;

  @Watch("open")
  onChangeOpen() {
    if (!this.open) {
      this.emitOnCloseDialog();
    }
  }

  @Emit("onCloseDialog")
  emitOnCloseDialog() {
    return true;
  }

  openDialog(issue: Issue | undefined) {
    this.issue = issue || Issue.createEmpty();
    this.open = true;
  }
}
