export class RtlLineTemplate {
    id;

    name;

    regex;

    optional;

    constructor(id, name, regex, optional) {
      this.id = id;
      this.name = name;
      this.regex = regex;
      this.optional = optional;
    }

    static fromDto(dto) {
      return new RtlLineTemplate(
        dto.id,
        dto.name,
        dto.regex,
        dto.optional,
      );
    }
}
