<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card :loading="userInputDisabled">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              "coaching_page.request_scan_dialog.title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container v-if="open">
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="fromDateDialog"
                :nudge-right="50"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="scanRequest.fromTime"
                    :label="
                      $localizationService.localize(
                        'coaching_page.request_scan_dialog.param.from_date'
                      )
                    "
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="userInputDisabled"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="scanRequest.fromTime"
                  :max="scanRequest.toTime"
                  @input="fromDateDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="toDateDialog"
                :nudge-right="50"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="scanRequest.toTime"
                    :label="
                      $localizationService.localize(
                        'coaching_page.request_scan_dialog.param.to_date'
                      )
                    "
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="userInputDisabled"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="scanRequest.toTime"
                  :min="scanRequest.fromTime"
                  @input="toDateDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.request_scan_dialog.param.target_report_types'
                  )
                "
                class="flex-grow-0"
                v-model="scanRequest.targetTypes"
                :items="availableReportTypes"
                multiple
                :disabled="userInputDisabled"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.request_scan_dialog.param.target_criteria'
                  )
                "
                class="flex-grow-0"
                v-model="scanRequest.criteria"
                :items="availableCriteria"
                multiple
                :disabled="userInputDisabled"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="requestScan"
          :disabled="!scanButtonActive || userInputDisabled"
        >
          {{ $localizationService.localize("btn.scan") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

const locService = Vue.prototype.$localizationService;

export default {
  name: "RequestScanDialog",

  data: () => ({
    open: false,

    fromDateDialog: undefined,
    toDateDialog: undefined,

    scanRequest: {
      targetTypes: [],
      criteria: [],
    },
  }),
  computed: {
    ...mapState("reportModule", ["loadingReportModels", "reportModels"]),
    ...mapState("criteriaModule", ["allCriteria"]),
    ...mapState("scanModule", ["processingScan"]),

    userInputDisabled() {
      return this.loadingReportModels || this.processingScan;
    },

    availableReportTypes() {
      const allSelected = this.scanRequest.targetTypes.find(
        (it) => it == "ALL",
      );

      return [
        {
          text: locService.localize(
            "coaching_page.request_scan_dialog.param.available_report_types_all",
          ),
          value: "ALL",
        },
        ...(this.reportModels || [])
          .filter((it) => it.name != "Prototype" && !it.isInternal)
          .map((it) => ({
            text: it.displayName,
            value: it.name,
            disabled: allSelected,
          })),
      ];
    },

    availableCriteria() {
      const allTypesSelected = this.scanRequest.targetTypes.find(
        (it) => it == "ALL",
      );

      const allCriteriaSelected = this.scanRequest.criteria.find(
        (it) => it == "ALL",
      );

      return [
        {
          text: locService.localize(
            "coaching_page.request_scan_dialog.param.all_for_target_report_types",
          ),
          value: "ALL",
        },
        ...this.allCriteria
          .filter(
            (it) => this.scanRequest.targetTypes.includes(it.reportType)
              || allTypesSelected,
          )
          .map((it) => ({
            text: it.name,
            value: it.id,
            disabled: allCriteriaSelected,
          })),
      ];
    },

    scanButtonActive() {
      return (
        this.scanRequest.fromTime
        && this.scanRequest.toTime
        && this.scanRequest.targetTypes.length
        && this.scanRequest.criteria.length
      );
    },
  },
  watch: {
    "scanRequest.targetTypes": function (val) {
      if (val.find((it) => it == "ALL") && val.length > 1) {
        this.scanRequest.targetTypes = ["ALL"];
      }
    },
  },
  methods: {
    openDialog() {
      this.scanRequest = {
        targetTypes: [],
        criteria: [],
      };
      this.$store.dispatch("reportModule/loadModelsIfNeeded");
      this.$store.dispatch("criteriaModule/loadAllCriteriaIfNeeded");
      this.open = true;
    },

    async requestScan() {
      try {
        await this.$store.dispatch("scanModule/requestScan", {
          fromTime: `${this.scanRequest.fromTime}T00:00:00`,
          toTime: `${this.scanRequest.toTime}T23:59:59.999999999`,
          targetTypes:
            this.scanRequest.targetTypes[0] == "ALL"
              ? this.reportModels
                .map((it) => it.name)
                .filter((it) => it != "Prototype")
              : this.scanRequest.targetTypes,
          criteria:
            this.scanRequest.criteria[0] == "ALL"
              ? this.allCriteria.map((it) => it.id)
              : this.scanRequest.criteria,
        });

        Vue.toasted.success(
          locService.localize(
            "coaching_page.request_scan_dialog.scan_successfully_requested",
          ),
          { duration: 5000 },
        );

        this.open = false;
      } catch (e) {
        Vue.toasted.error(e, { duration: 5000 });
      }
    },
  },
};
</script>
