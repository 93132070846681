import {
  Action, Module, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
import { API } from "aws-amplify";
import { store } from "@/main";

import { bodyWithAuthHeader, handleHttpError } from "@/utils/utils";

const API_PATH = "/inspection/assignment";

const moduleName = "vehicleInspectionAssignmentsModule";

@Module({
  name: moduleName, namespaced: true, store,
})
export default class InspectionAssignmentsModule extends VuexModule {
  static get namespace() {
    return namespace(moduleName);
  }

  @Action
  async assignInspection(data: any): Promise<boolean> {
    const body = {
      ...await bodyWithAuthHeader(),
      body: {
        targetVehicleIds: data.targetVehicleIds,
        targetVehicleGroupIds: data.targetVehicleGroupIds,
        targetDate: data.targetDate,
        targetSchemeId: data.targetSchemeId,
      },
    };

    try {
      await API.post("core", API_PATH, body);
      return true;
    } catch (e: any) {
      handleHttpError(e);
      return false;
    }
  }

  @Action({ rawError: true })
  async loadVehicleInspectionAssignments(data: any): Promise<any[]> {
    const pageSize = 25;

    const body = {
      ...await bodyWithAuthHeader(),
      queryStringParameters: {
        targetVehicleId: data.vehicleId,
        targetDate: data.targetDate,
        searchString: data.search,
        pageSize,
        page: data.page,
      },
    };

    try {
      return await API.get("core", API_PATH, body);
    } catch (e: any) {
      handleHttpError(e);
    }

    return [];
  }
}
