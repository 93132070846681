<template>
  <v-container fluid>
    <v-row align="start" class="phoneAgents" justify="start">
      <v-col>
        <v-data-table
            :class="{'not-default-page-size': !this.isDefaultSize}"
            :footer-props="footerProps"
            :headers="headers"
            :items="displayPhoneAgents"
            :loading="pageLoading || !tableInited"
            :options="{
              page: pageIndex,
              itemsPerPage: pageSize,
              sortBy: sort.sortBy,
              sortDesc: sort.sortDesc,
             }"
            :server-items-length="totalCount"
            multi-sort
            @update:options="paginate($event)"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("phone_agent_page.title") }}
                <TableSettings
                    :pDefaultButtonTooltip="
                      $localizationService.localize(
                        'phone_agent_page.settings_tooltip.apply_default'
                        )
                    "
                    :pIsVisible="tableInited && !isDefaultSettings"
                    :pSaveButtonTooltip="
                      $localizationService.localize(
                        'phone_agent_page.settings_tooltip.save'
                      )
                    "
                    class="ml-5"
                    @onApplyDefaultSettings="applyDefaultSettings()"
                    @onSaveSettings="saveSettings()"
                />
              </v-toolbar-title>
              <v-spacer/>
              <v-text-field
                  v-model="searchString"
                  :label="$localizationService.localize('phone_agent_page.search')"
                  class="search-field"
                  hide-details
                  width="200"
                  @input="searchStringDebounceWatch()"
              ></v-text-field>
              <v-btn class="ml-2" color="primary" @click="openEditPhoneAgentDialog()">
                {{ $localizationService.localize("phone_agent_page.new_btn") }}
              </v-btn>
              <EditPhoneAgentDialog @onAddOrSave="reloadPage" ref="phoneAgentDialog"/>
              <ConfirmationDialog ref="deleteDialog"/>
            </v-toolbar>
          </template>
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
                :key="header.value"
                :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.allowedUsers`]="{ item }">
            <div v-if="item.allowedUsers.length === 0" :class="{greyText : item.allowedUsers.length === 0}">
              {{
                $localizationService.localize("phone_agent_page.allowed_users.none")
              }}
            </div>
            <v-chip-group column v-else-if="item.allowedUsers.length <= 3 && item.allowedUsers.length > 0">
              <v-chip
                  v-for="user in item.allowedUsers"
                  :key="user.id"
              >
                {{user.name}}
              </v-chip>
            </v-chip-group>
            <v-tooltip v-else bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs"
                    v-on="on"
                >
                  {{
                    $localizationService.localize("phone_agent_page.allowed_users.users_count", [
                      item.allowedUsers.length,
                    ])
                  }}
                </v-chip>
              </template>
              <v-container class="allowed-users-container">
                <v-row justify="center">
                  <v-col>
                    <v-chip-group column>
                      <v-chip
                          v-for="user in item.allowedUsers"
                          :key="user.id"
                      >
                        {{user.name}}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-tooltip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDateAndTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.lastSeen`]="{ item }">
            <div :class="{greyText : !item.lastSeen}">
              {{
                item.lastSeen ? momentDateAndTime(item.lastSeen) :
                    $localizationService.localize("phone_agent_page.missing_last_seen")
              }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              @click="openVinQrDialog(item)"
            >
            <v-icon>mdi-qrcode</v-icon>
            </v-btn>
            <v-icon class="mr-2" medium @click="openEditPhoneAgentDialog(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon color="red" medium @click="openDeletePhoneAgentDialog(item)"
            >mdi-delete
            </v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <QrDialog
        :text="$localizationService.localize('phone_agent_page.qr_dialog_description')"
        ref="vinQrDialog" />
  </v-container>
</template>

<script>

import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import lodash from "lodash";
import { API } from "aws-amplify";
import tableSortParserMixin from "../../utils/table-sort-parser";
import ConfirmationDialog from "../ConfirmationDialog";
import TableSettings from "../TableSettings";
import EditPhoneAgentDialog from "./EditPhoneAgentDialog";
import QrDialog from "@/components/vehicles/QrDialog";
import { momentDateAndTime } from "@/utils/utils";
import { bodyWithAuthHeader } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "PhoneAgents",

  mixins: [tableSortParserMixin],

  components: {
    EditPhoneAgentDialog,
    ConfirmationDialog,
    TableSettings,
    QrDialog,
  },

  computed: {
    ...mapState("phoneAgentsModule", [
      "displayPhoneAgents",
      "totalCount",
      "currentPage",
      "pageLoading",
    ]),
    ...mapState("phoneAgentsTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("phoneAgentsTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },
  data: () => ({

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    tableInited: false,

    searchString: "",

    headers: [
      {
        text: locService.localize("phone_agent_page.header.name"),
        value: "name",
        sortable: true,
      },
      {
        text: locService.localize("phone_agent_page.header.last_seen"),
        value: "lastSeen",
        sortable: true,
      },
      {
        text: locService.localize("phone_agent_page.header.allowed_users"),
        value: "allowedUsers",
        sortable: false,
        width: "20%",
      },
      {
        text: locService.localize("phone_agent_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("phone_agent_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("phone_agent_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("phoneAgentsTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("phoneAgentsModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    openEditPhoneAgentDialog(phoneAgent) {
      this.$refs.phoneAgentDialog.openDialog(phoneAgent);
    },

    async openVinQrDialog(item) {
      const body = await bodyWithAuthHeader();

      const container = await API.get("core", `/phone-agent/${item.id}/current_auth_token`, body);

      if (!container.bind) {
        this.$refs.vinQrDialog.openDialog({ name: item.name, vin: container.token });
      } else {
        this.$refs.deleteDialog.openDialog({
          header: "Warning",
          text: "Target phone agent already authed. Generation of new key will break previous key.",
          submitText: "generate",
          submitColor: "primary",
          onSubmit: async () => {
            const newCodeContainer = await API.post("core", `/phone-agent/${item.id}/update_token`, body);
            this.$refs.vinQrDialog.openDialog({ name: item.name, vin: newCodeContainer.token });
          },
        });
      }
    },

    openDeletePhoneAgentDialog(phoneAgent) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("phone_agent_page.dialog.delete.title"),
        text: locService.localize("phone_agent_page.dialog.delete.text", [
          phoneAgent.name,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          await this.$store.dispatch("phoneAgentsModule/deletePhoneAgent", phoneAgent);
          this.reloadPage();
        },
      });
    },

    saveSettings() {
      this.$store.commit("phoneAgentsTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("phone_agent_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("phoneAgentsTableSettingsModule/applyDefaultSettings");
    },

    reloadPage() {
      this.$store.dispatch("phoneAgentsModule/reloadPage");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/messaging/phone-agents/?${params.toString()}`);
      }
    },

    searchStringDebounceWatch: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("phoneAgentsModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),
  },

};
</script>

<style lang="scss">
.phoneAgents {
  .search-field {
    max-width: 250px;
  }

  .v-data-table-header-mobile {
    display: none;
  }

  .greyText {
    color: grey;
  }
}
.allowed-users-container {
  max-width: 180px;
  padding: 0;
}
</style>
