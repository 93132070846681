<template>
 <VehicleShop />
</template>

<script>
import VehicleShop from "../components/vehicle-shops/VehicleShops";

export default {
  name: "VehicleShopsPage",
  components: { VehicleShop },

  routing: {
    localizationKey: "menu.tab.vehicle_shops",
    icon: "mdi-car-wrench",
    path: "/vehicle-shop",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>
