<template>
  <v-container class="report-calendar-component pb-0" :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-toolbar flat>
      <v-toolbar-title>{{
        $localizationService.localize("report_calendar.title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-icon
          :color="calendarDateRangeMenu ? 'primary' : ''"
          @click="calendarDateRangeMenu = !calendarDateRangeMenu"
        >mdi-calendar</v-icon>
        <v-menu
          ref="calendarDateRangeMenu"
          v-model="calendarDateRangeMenu"
          :close-on-content-click="false"
          :return-value.sync="selectedDatesRange"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="date-container">
              <v-combobox
                v-model="selectedDatesRange"
                multiple
                chips
                :label="
                  $localizationService.localize(
                    'report_calendar.param.date_range'
                  )
                "
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-combobox>
              <v-text-field
                v-model="selectedDatesRange"
                class="closed-input"
                multiple
                chips
                small-chips
                :label="
                  $localizationService.localize(
                    'report_calendar.param.date_range'
                  )
                "
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </div>
          </template>
          <v-date-picker v-model="selectedDatesRange" range no-title>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="calendarDateRangeMenu = false">
              {{ $localizationService.localize("btn.cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDatesRange(selectedDatesRange.sort())"
            >
              {{ $localizationService.localize("btn.ok") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="defaultItems"
      :loading="calendarLoading"
      :loading-text="$localizationService.localize('common.loading')"
      disable-pagination
      hide-default-footer
      disable-sort
      class="data-calendar"
    >
      <template
        v-for="(date, index) in dateHeaders"
        v-slot:[`item.${date.value}`]="{ item }"
      >
        <div :key="index">
          <CalendarChip
            :pChip="getChip(item.reportType, date.value)"
            :pTargetDate="date.value"
            :pLastDateInCalendar="lastDateInCalendar"
            @onOpenActionDialog="$emit('onOpenActionDialog', $event)"
            @onUploadReport="uploadReport($event,  date.value)"
          />
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";

import {
  dateFormatUnixMillisecondTimestamp,
  defaultDateFormat,
  dateMomentFormat,
  localStorageDates,
  getLocalStorageDateForCalendar,
  setLocalStorageData,
  REPORT_TYPES,
} from "../../../utils/utils";

import { CalendarChip } from "../../../model/calendar-chip.model";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Calendar",

  components: {
    CalendarChip: () => import("./CalendarChip"),
  },

  data() {
    return {
      selectedDatesRange: [
        moment().day(0).format(defaultDateFormat),
        moment().day(6).format(defaultDateFormat),
      ],
      calendarDateRangeMenu: false,
    };
  },

  computed: {
    ...mapState("coreModule", ["drawer"]),
    ...mapState("reportModule", [
      "calendarReportsFromApi",
      "calendarLoading",
    ]),

    dateHeaders() {
      return [...this.headers].splice(1, this.headers.length);
    },

    headers() {
      const headers = [{
        text: locService.localize("report_calendar.header.report_types"),
        value: "reportType",
      }];

      const { startDate, endDate } = this.getCorrectHeaderDates();

      for (let m = startDate; m.diff(endDate) <= 0; m.add(1, "days")) {
        headers.push({
          text: m.format(dateMomentFormat),
          value: m.format(defaultDateFormat),
        });
      }

      return headers;
    },

    lastDateInCalendar() {
      return (this.dateHeaders[this.dateHeaders.length - 1] || {}).value;
    },

    defaultItems() {
      return REPORT_TYPES.map((it) => ({
        reportType: it.text,
      }));
    },

    chips() {
      return CalendarChip.getChips(this.calendarReportsFromApi);
    },
  },

  methods: {
    getChip(reportType, targetDate) {
      return this.chips.find((chip) => (
        chip.type.text === reportType
        && moment(targetDate).isBetween(
          chip.startDate,
          chip.endDate,
          undefined,
          "[]",
        )
      ));
    },

    getCorrectHeaderDates() {
      const selectedDatesRange = [...this.selectedDatesRange].sort();

      let startDate;
      let endDate;

      if (selectedDatesRange.length === 1) {
        if (moment(selectedDatesRange[0]).isSameOrBefore(moment())) {
          startDate = moment(selectedDatesRange[0]);
          endDate = moment();
        } else {
          startDate = moment();
          endDate = moment(selectedDatesRange[0]);
        }
      } else {
        startDate = moment(selectedDatesRange[0]);
        endDate = moment(selectedDatesRange[1]);
      }

      return { startDate, endDate };
    },

    uploadReport(event, targetDate) {
      this.$emit("onUploadReport", { event, targetDate });
    },

    saveDatesRange(range) {
      if (range.length !== 2) {
        Vue.toasted.error(
          locService.localize("report_calendar.error.date_range"), {
            duration: 3000,
          },
        );
        return;
      }

      this.$refs.calendarDateRangeMenu.save(range);

      this.$store.dispatch("reportModule/loadReportCalendar", range);

      setLocalStorageData(localStorageDates, [
        moment(range[0]).add(1, "d").utc()
          .startOf("d")
          .format(dateFormatUnixMillisecondTimestamp),
        moment(range[1]).add(1, "d").utc()
          .endOf("d")
          .format(dateFormatUnixMillisecondTimestamp),
      ]);
    },
  },

  mounted() {
    const datesFromLocalStorage = getLocalStorageDateForCalendar(localStorageDates);
    if (datesFromLocalStorage) {
      this.selectedDatesRange = datesFromLocalStorage;
    }

    this.$store.dispatch("reportModule/loadReportCalendar", this.selectedDatesRange);
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
.report-calendar-component {
  .active-chip {
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 0.3s;
  }
  .v-chip--disabled {
    opacity: 1 !important;
  }

  .v-menu__content.theme--dark.menuable__content__active {
    z-index: 999 !important;
  }
  .v-progress-circular {
    left: 20% !important;
  }

  .button-hover {
    border-radius: 16px;
    opacity: 0;
    height: 32px !important;
    width: 70px !important;
  }
  .button-hover:hover {
    opacity: 1;
  }
  .file-input {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: -50%;
    left: -16px;
    width: calc(100% + 32px);
    height: 36px;
    cursor: pointer !important;
  }
  @media screen and (max-width: 425px) {
    .v-toolbar__title {
      max-width: 80px !important;
    }
  }
  @media screen and (max-width: 350px) {
    .v-toolbar__title {
      max-width: 50px !important;
    }
  }
  .file-input::-webkit-file-upload-button {
    cursor: pointer;
  }
  .theme--dark.v-chip:not(.v-chip--active),
  .v-chip--hover {
    background: none;
  }

  .v-toolbar .v-input {
    margin-top: 25px;
  }

  .v-chip.v-size--default {
    font-size: 0.8rem !important;
  }

  .v-chip.v-chip--no-color.theme--dark.v-size--default {
    min-width: 70px !important;
  }

  .v-progress-linear.v-progress-linear--absolute.theme--dark {
    position: relative;
    z-index: 99;
  }
  .date-container {
    position: relative;
    .v-text-field.closed-input {
      opacity: 0;
      filter: alpha(opacity=0);
      position: absolute;
      top: -5%;
      left: 0;
      width: calc(100% + 32px);
      cursor: pointer !important;
    }
    input {
      width: 0 !important;
      min-width: 0 !important;
    }
    .v-text-field .v-input__append-inner {
      display: none;
    }
  }

  .data-calendar.v-data-table > .v-data-table__wrapper > table {
    thead > tr:last-child > th:not(:first-child) {
      text-align: center !important;
      white-space: nowrap !important;
    }
    thead.v-data-table-header > tr:last-child > th:first-child,
    tbody > tr > td:first-child {
      position: sticky !important;
      left: 0 !important;
      min-width: 200px !important;
      display: flex;
      align-items: center;
      background-color: map-get($material-dark-elevation-colors, "1");
      z-index: 8;
    }
  }

  .data-calendar.v-data-table > .v-data-table__wrapper > table > tbody > tr {
    td:not(:first-child) {
      padding: 0;

      .chip-container {
        &.chip-row-start {
          margin-left: 12px;
          .active-chip {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
        &.chip-row-middle {
          .active-chip {
            border-radius: 0;
          }
        }
        &.chip-row-end {
          margin-right: 12px;
          .active-chip {
            padding-left: 24px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
    }
    .chip-container:not(.chip-row-start, .chip-row-middle, .chip-row-end) {
      margin: 0 12px;
    }
  }
}

.light-local-user-theme {
  .data-calendar {
    &.v-data-table > .v-data-table__wrapper > table {
      thead.v-data-table-header > tr:last-child > th:first-child,
      tbody > tr > td:first-child {
        background-color: white !important;
      }
    }
  }
}
</style>
