var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.editedDriver.id ? _vm.$localizationService.localize( "driver_page.dialog.add_and_edit.edit_title" ) : _vm.$localizationService.localize( "driver_page.dialog.add_and_edit.new_title" ))+" ")])]),_c('v-card-text',[(_vm.open)?_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'driver_page.dialog.add_and_edit.param.name'
              ) + '*',"rules":_vm.nameRules,"disabled":_vm.loading},model:{value:(_vm.editedDriver.name),callback:function ($$v) {_vm.$set(_vm.editedDriver, "name", $$v)},expression:"editedDriver.name"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'driver_page.dialog.add_and_edit.param.transporter_id'
              ),"disabled":_vm.loading},model:{value:(_vm.editedDriver.transporterId),callback:function ($$v) {_vm.$set(_vm.editedDriver, "transporterId", $$v)},expression:"editedDriver.transporterId"}})],1),_c('v-row',{staticClass:"row-combobox"},[_c('v-combobox',{attrs:{"label":_vm.$localizationService.localize(
                'driver_page.dialog.add_and_edit.param.aliases'
              ),"disabled":_vm.loading,"append-icon":"","chips":"","multiple":"","deletable-chips":"","delimiters":[',']},model:{value:(_vm.editedDriver.aliases),callback:function ($$v) {_vm.$set(_vm.editedDriver, "aliases", $$v)},expression:"editedDriver.aliases"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'driver_page.dialog.add_and_edit.param.email'
              ),"rules":_vm.emailRules,"disabled":_vm.loading},model:{value:(_vm.editedDriver.email),callback:function ($$v) {_vm.$set(_vm.editedDriver, "email", $$v)},expression:"editedDriver.email"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'driver_page.dialog.add_and_edit.param.phone'
              ),"rules":_vm.phoneRules,"disabled":_vm.loading},model:{value:(_vm.editedDriver.phone),callback:function ($$v) {_vm.$set(_vm.editedDriver, "phone", $$v)},expression:"editedDriver.phone"}}),_c('div',[_vm._v("Please enter number in international format."),_c('br'),_vm._v("Example: +15168887766")])],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.saveButtonActive},on:{"click":_vm.addDriver}},[_vm._v(" "+_vm._s(_vm.editedDriver.id ? _vm.$localizationService.localize("btn.save") : _vm.$localizationService.localize("btn.add"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }