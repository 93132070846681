import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { VehicleActionType } from "../model/reminder/vehicle-action-type.model";

const API_PATH = "/vehicles/vehicle-action-type";

const locService = Vue.prototype.$localizationService;

export default {
  name: "vehicleActionTypesModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayVehicleActionTypes: [],
    currentPage: {
      vehicleActionTypes: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicleActionTypes(state, vehicleActionTypeDto) {
      state.currentPage.vehicleActionTypes = [...vehicleActionTypeDto];
      state.displayVehicleActionTypes = state.currentPage.vehicleActionTypes;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const vehicleActionTypesDto = await API.get("core", API_PATH, body);
        commit("updateVehicleActionTypes", vehicleActionTypesDto.map((vehicleActionType) => VehicleActionType.fromDto(vehicleActionType)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async addOrSaveVehicleActionType(context, vehicleActionType) {
      const body = { ...await bodyWithAuthHeader(), body: vehicleActionType };

      try {
        const vehicleActionTypeDto = await (vehicleActionType.id ? API.put("core", `${API_PATH}/${vehicleActionType.id}`, body)
          : API.post("core", API_PATH, body));
        const vehicleAction = VehicleActionType.fromDto(vehicleActionTypeDto);
        Vue.toasted.success(
          locService.localize(vehicleActionType.id ? "store.vehicle_action_type.save_vehicle_action_type_msg"
            : "store.vehicle_action_type.add_vehicle_action_type_msg", [vehicleActionType.name]),
          {
            duration: 5000,
          },
        );
        return vehicleAction;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deleteVehicleActionType(context, vehicleActionType) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicleActionType.id}`, body);
        Vue.toasted.success(locService.localize("store.vehicle_action_type.delete_vehicle_action_type_msg",
          [vehicleActionType.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredVehicleActionTypes(context, searchString) {
      if (!searchString) return [];

      try {
        return await API.get("core", `${API_PATH}/search?searchString=${searchString}`,
          await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },

    async loadAllVehicleActionTypes() {
      try {
        return await API.get("core", `${API_PATH}`, await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },
  },
};
