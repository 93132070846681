export class VehicleShop {
    id;

    name;

    address;

    createdAt;

    updatedAt;

    constructor(
      id,
      name,
      address,
      createdAt,
      updatedAt,
    ) {
      this.id = id;
      this.name = name;
      this.address = address;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }

    static fromDto(dto) {
      return new VehicleShop(
        dto.id,
        dto.name,
        dto.address,
        dto.createdAt,
        dto.updatedAt,
      );
    }
}
