import {
  VuexModule, Module, Mutation,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";

import { IS_MOBILE } from "@/utils/utils";

const moduleName = "coreModule";

@Module({ name: moduleName, namespaced: true })
export default class CoreModule extends VuexModule {
  drawer = !IS_MOBILE;

  private drawerStateBeforePrint = false;

  static get namespace() {
    return namespace(moduleName);
  }

  @Mutation
  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  @Mutation
  hideDrawerBeforePrint() {
    this.drawerStateBeforePrint = this.drawer;
    this.drawer = false;
  }

  @Mutation
  restoreDrawerAfterPrint() {
    this.drawer = this.drawerStateBeforePrint;
  }

  @Mutation
  drawerLinkClicked() {
    if (IS_MOBILE) {
      this.drawer = false;
    }
  }
}
