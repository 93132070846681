<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize("user_page.dialog.title")
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-select
              :items="USER_TYPES"
              :label="$localizationService.localize('user_page.dialog.param.type')"
              v-model="editedUser.type"
              :disabled="loading"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              :items="phoneAgents"
              item-text="name"
              return-object
              :label="$localizationService.localize('user_page.dialog.param.selected_phone_agent')"
              v-model="editedUser.selectedPhoneAgent"
              :disabled="loading"
              :loading="loadingPhoneAgents"
              @click="loadPhoneAgents()"
            ></v-select>
          </v-row>
          <v-row style="margin: -12px">
            <v-switch
              v-model="editedUser.blocked"
              :disabled="loading"
              :label="$localizationService.localize('user_page.dialog.param.blocked')"
            ></v-switch>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="updateUser"
            :disabled="loading || !editedUser.type || loadingPhoneAgents"
        >
          {{ $localizationService.localize("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { USER_TYPES } from "../../utils/utils";
import { UserInfo } from "../../model/user-info.model";

export default {
  name: "EditUserDialog",

  data: () => ({
    USER_TYPES,
    open: false,
    loading: false,
    loadingPhoneAgents: false,

    editedUserOriginal: {},
    editedUser: {},

    phoneAgents: [],

  }),

  methods: {

    async openDialog(user) {
      this.editedUserOriginal = user || {};
      this.editedUser = Object.assign(new UserInfo(), this.editedUserOriginal);
      this.open = true;
      await this.loadPhoneAgents();
    },

    closeDialog() {
      this.phoneAgents = [];
      this.open = false;
    },

    async loadPhoneAgents() {
      this.loadingPhoneAgents = true;
      this.phoneAgents = await this.$store.dispatch(
        "phoneAgentsModule/loadAvailablePhoneAgents", this.editedUser.id,
      );
      this.loadingPhoneAgents = false;
    },

    async updateUser() {
      this.loading = true;
      await this.$store.dispatch(
        "userModule/updateUser", this.editedUser,
      );
      this.loading = false;
      this.closeDialog();
    },
  },
};
</script>
