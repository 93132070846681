import lodash from "lodash";
import { DriverEntry } from "./driver-entry.model";
import { Vehicle } from "../vehicle.model";
import { Device } from "../device.model";
import { DailyRouteRescue } from "./rescue.model";
import { getMnt, updateMnt } from "../../utils/drp-mnt";
import { Rescuable } from "./rescuable.model";

export class DailyRoutePlanRow extends Rescuable {
  isRow = true;

  id;

  station;

  staging;

  route;

  currentDriverEntry;

  rescuersNestingIndexes = -1

  set driverEntry(value) {
    this.currentDriverEntry = value;
  }

  get driverEntry() {
    return this.currentDriverEntry;
  }

  get driverEntriesAndVehicles() {
    return lodash.flatten([
      {
        driver: (this.driverEntry || {}).driver,
        vehicle: this.vehicle,
      },
      lodash.flatten(this.rescuers.map((it) => it.driverEntriesAndVehicles)),
    ]);
  }

  get rescueCreationApiPath() {
    return `/drp/row/${this.id}/rescue`;
  }

  get isClear() {
    return (
      !this.station
      && !this.staging
      && !this.route
      && !this.currentDriverEntry
      && !this.vehicle
      && !this.devices.length
      && !this.rescuers.length
    );
  }

  vehicle;

  devices;

  replacementsHistory;

  version;

  constructor(
    id,
    station,
    staging,
    route,
    currentDriverEntry,
    vehicle,
    devices,
    replacementsHistory,
    version,
  ) {
    super([]);
    this.id = id;
    this.station = station;
    this.staging = staging;
    this.route = route;
    this.currentDriverEntry = currentDriverEntry;
    this.vehicle = vehicle;
    this.devices = devices;
    this.replacementsHistory = replacementsHistory;
    this.version = version;
  }

  static fromDto(dto, driverEntriesMap) {
    const self = new DailyRoutePlanRow(
      dto.id,
      dto.station,
      dto.staging,
      dto.route,
      driverEntriesMap[(dto.currentDriverEntry || {}).id],
      dto.vehicle ? Vehicle.fromDto(dto.vehicle) : undefined,
      dto.devices.map((device) => Device.fromDto(device)),
      dto.replacementsHistory.map((driver) => DriverEntry.fromDto(driver)),
      dto.version,
    );

    self.rescuers = dto.rescuers.map((rescue) => DailyRouteRescue.fromDto(rescue, self, driverEntriesMap));

    return self;
  }

  get mnt() {
    return getMnt(this.currentDriverEntry, this.vehicle);
  }

  set mnt(value) {
    updateMnt(value, this.currentDriverEntry, this.vehicle);
  }
}

export class DailyRoutePlanRowDto {
    id;

    station;

    staging;

    route;

    currentDriverEntryId;

    vehicleId;

    deviceIds;

    version;

    constructor(
      id,
      station,
      staging,
      route,
      currentDriverEntryId,
      vehicleId,
      deviceIds,
      version,
    ) {
      this.id = id;
      this.station = station;
      this.staging = staging;
      this.route = route;
      this.currentDriverEntryId = currentDriverEntryId;
      this.vehicleId = vehicleId;
      this.deviceIds = deviceIds;
      this.version = version;
    }

    static toDto(row) {
      return new DailyRoutePlanRowDto(
        row.id,
        row.station,
        row.staging,
        row.route,
        row.currentDriverEntry ? row.currentDriverEntry.id : null,
        row.vehicle ? row.vehicle.id : null,
        row.devices.map((device) => device.id),
        row.version,
      );
    }

    static createDefault() {
      return new DailyRoutePlanRowDto(
        "",
        "",
        "",
        "",
        "",
        "",
        [],
        0,
      );
    }
}
