var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"phoneAgents",attrs:{"align":"start","justify":"start"}},[_c('v-col',[_c('v-data-table',{class:{'not-default-page-size': !this.isDefaultSize},attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.displayPhoneAgents,"loading":_vm.pageLoading || !_vm.tableInited,"options":{
            page: _vm.pageIndex,
            itemsPerPage: _vm.pageSize,
            sortBy: _vm.sort.sortBy,
            sortDesc: _vm.sort.sortDesc,
           },"server-items-length":_vm.totalCount,"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("phone_agent_page.title"))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pDefaultButtonTooltip":_vm.$localizationService.localize(
                      'phone_agent_page.settings_tooltip.apply_default'
                      ),"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize(
                      'phone_agent_page.settings_tooltip.save'
                    )},on:{"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()},"onSaveSettings":function($event){return _vm.saveSettings()}}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize('phone_agent_page.search'),"hide-details":"","width":"200"},on:{"input":function($event){return _vm.searchStringDebounceWatch()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditPhoneAgentDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("phone_agent_page.new_btn"))+" ")]),_c('EditPhoneAgentDialog',{ref:"phoneAgentDialog",on:{"onAddOrSave":_vm.reloadPage}}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
              'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.allowedUsers",fn:function(ref){
            var item = ref.item;
return [(item.allowedUsers.length === 0)?_c('div',{class:{greyText : item.allowedUsers.length === 0}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("phone_agent_page.allowed_users.none"))+" ")]):(item.allowedUsers.length <= 3 && item.allowedUsers.length > 0)?_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.allowedUsers),function(user){return _c('v-chip',{key:user.id},[_vm._v(" "+_vm._s(user.name)+" ")])}),1):_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$localizationService.localize("phone_agent_page.allowed_users.users_count", [ item.allowedUsers.length ]))+" ")])]}}],null,true)},[_c('v-container',{staticClass:"allowed-users-container"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.allowedUsers),function(user){return _c('v-chip',{key:user.id},[_vm._v(" "+_vm._s(user.name)+" ")])}),1)],1)],1)],1)],1)]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.updatedAt))+" ")]}},{key:"item.lastSeen",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:{greyText : !item.lastSeen}},[_vm._v(" "+_vm._s(item.lastSeen ? _vm.momentDateAndTime(item.lastSeen) : _vm.$localizationService.localize("phone_agent_page.missing_last_seen"))+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openVinQrDialog(item)}}},[_c('v-icon',[_vm._v("mdi-qrcode")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openEditPhoneAgentDialog(item)}}},[_vm._v("mdi-pencil ")]),_c('v-icon',{attrs:{"color":"red","medium":""},on:{"click":function($event){return _vm.openDeletePhoneAgentDialog(item)}}},[_vm._v("mdi-delete ")])]}}],null,true)})],1)],1),_c('QrDialog',{ref:"vinQrDialog",attrs:{"text":_vm.$localizationService.localize('phone_agent_page.qr_dialog_description')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }