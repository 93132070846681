import lodash from "lodash";
import { getLocalStorageData, setLocalStorageData } from "../../utils/utils";

export default {

  name: "drpFilterPanelTableSettingsModule",
  namespaced: true,
  state: {
    tableSettingsStorageKey: "drp.filter.panel.table.settings",

    criteriaFilter: [],

    defaultSettings: {
      criteriaFilter: [],
    },
  },

  mutations: {
    changeCriteriaFilter(state, newCriteriaFilter) {
      state.criteriaFilter = newCriteriaFilter;
    },

    changeSettings(state, data) {
      state.criteriaFilter = data.criteriaFilter;
    },

    saveSettings(state) {
      const data = {
        criteriaFilter: state.criteriaFilter,
      };
      setLocalStorageData(state.tableSettingsStorageKey, JSON.stringify(data));
      state.defaultSettings = data;
    },

    applyDefaultSettings(state) {
      state.criteriaFilter = state.defaultSettings.criteriaFilter;
    },

    applyQuerySettings(state, query) {
      if (query.criteriaFilter) {
        state.criteriaFilter = JSON.parse(query.criteriaFilter);
      }
    },
  },

  actions: {
    loadDefaultSettingsFromStorage({ state }) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },
  },

  getters: {
    isDefaultSettings: (state, getters) => getters.isDefaultCriteriaFilter,
    isDefaultCriteriaFilter: (state) => lodash.isEqual(state.criteriaFilter, state.defaultSettings.criteriaFilter),
  },
};
