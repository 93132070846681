import { UnitedDuration } from "./reminder/united-duration.model";

export class Company {
    id;

    name;

    timeZone;

    autoCoachingEnabled;

    rentalExpiryDueSoonThreshold;

    constructor(id, name, timeZone, autoCoachingEnabled, rentalExpiryDueSoonThreshold) {
      this.id = id;
      this.name = name;
      this.timeZone = timeZone;
      this.autoCoachingEnabled = autoCoachingEnabled;
      this.rentalExpiryDueSoonThreshold = rentalExpiryDueSoonThreshold;
    }

    static fromDto(dto) {
      return new Company(
        dto.id,
        dto.name,
        dto.timeZoneId,
        dto.autoCoachingEnabled,
        dto.rentalExpiryDueSoonThreshold ? UnitedDuration.fromDto(dto.rentalExpiryDueSoonThreshold) : null,
      );
    }
}
