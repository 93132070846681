const libPhoneNumbers = require("libphonenumbers");

export default class PhoneNumberValidator {
  static isValid(value) {
    const phoneUtil = libPhoneNumbers.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(value, "US");
      return phoneUtil.isValidNumber(number) && value.startsWith("+");
    } catch (e) {
      return false;
    }
  }
}
