





























import {
  Component, Emit, Vue, Watch,
} from "vue-property-decorator";
import debounce from "lodash/debounce";
import { namespace } from "vuex-class";
import { Vehicle } from "@/model/vehicle.model";

const BTN_STATE = "BTN";
const SELECT_STATE = "SELECT";

const locService = Vue.prototype.$localizationService;

const vehiclesModule = namespace("vehiclesModule");

@Component({})
export default class SelectRelatedVehicle extends Vue {
  locService = locService;

  BTN_STATE = BTN_STATE;

  SELECT_STATE = SELECT_STATE;

  state = BTN_STATE;

  selectedVehicle: Vehicle | null = null;

  loading = false;

  searchVehicleStr: string | null = null;

  matchedVehicles: Vehicle[] = [];

  @vehiclesModule.Action
  searchVehicles!: (search: string) => Promise<any[]>

  @Watch("searchVehicleStr")
  onChangeSearchVehicle() {
    if (this.searchVehicleStr) {
      this.loadFilteredVehicle();
    }
  }

  @Emit("onSelectVehicle")
  emitNewVehicle() {
    return this.selectedVehicle;
  }

  changeStateToBtn() {
    this.state = BTN_STATE;
  }

  changeStateToSelect() {
    this.state = SELECT_STATE;
  }

  onChangeSelectedVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
  }

  saveVehicle() {
    this.emitNewVehicle();
    this.changeStateToBtn();
  }

  loadFilteredVehicle() {}

  mounted() {
    this.loadFilteredVehicle = debounce(async () => {
      if (!this.searchVehicleStr) return;

      this.loading = true;
      this.matchedVehicles = await this.searchVehicles(this.searchVehicleStr);
      this.loading = false;
    }, 300);
  }
}
