export class TemplateConstant {
    id;

    name;

    description;

    value;

    createdAt;

    updatedAt;

    constructor(
      id,
      name,
      description,
      value,
      createdAt,
      updatedAt,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.value = value;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }

    static fromDto(dto) {
      return new TemplateConstant(
        dto.id,
        dto.name,
        dto.description,
        dto.value,
        dto.createdAt,
        dto.updatedAt,
      );
    }
}
