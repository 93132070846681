<template>
  <v-dialog v-model="open" max-width="500px" @click:outside="cancel()">
    <v-card v-if="open">
      <v-card-title class="headline">
        {{ $localizationService.localize("drp.entry_status_dialog.title") }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="newNote"
          :label="
            $localizationService.localize('drp.entry_status_dialog.param.note')
          "
          rows="3"
          no-resize
        ></v-textarea>
        <DriverEntrySelect
          v-model="selectedReplacement"
          v-if="viewReplacementSelect"
          is-replacement
          :driverEntries="driverEntries"
          :additionalDataPerDriver="additionalDataPerDriver"
          :targetDate="targetDate"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn text color="primary" @click="confirm()">
          {{ $localizationService.localize("btn.change") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DriverEntrySelect from "../DriverEntrySelect";
import { ENTRY_STATUS_CONFIRMED } from "../../../../../utils/utils";

export default {
  name: "DriverEntryStatusChangeDialog",

  components: {
    DriverEntrySelect,
  },

  props: {
    driverEntries: Array,
    additionalDataPerDriver: Object,
    targetDate: String,
  },

  data: () => ({
    open: false,
    targetDriverEntry: undefined,
    newNote: "",
    onConfirm: undefined,
    onCancel: undefined,
    selectedStatus: undefined,
    replacementRequired: false,
    selectedReplacement: undefined,
  }),

  computed: {
    viewReplacementSelect() {
      return (
        this.replacementRequired
        && this.selectedStatus !== ENTRY_STATUS_CONFIRMED
      );
    },
  },

  methods: {
    openDialog({
      targetDriverEntry,
      selectedStatus,
      replacementRequired,
      onConfirm,
      onCancel,
    }) {
      this.open = true;
      this.targetDriverEntry = targetDriverEntry;
      this.selectedStatus = selectedStatus;
      this.replacementRequired = replacementRequired;
      this.onConfirm = onConfirm;
      this.onCancel = onCancel;
      this.newNote = this.targetDriverEntry.note;
    },

    confirm() {
      if (this.onConfirm) {
        this.onConfirm(this.newNote, this.selectedReplacement);
      }
      this.close();
    },

    cancel() {
      if (this.onCancel) {
        this.onCancel();
      }
      this.close();
    },

    close() {
      this.open = false;
      this.originalStatus = {};
      this.newNote = "";
    },
  },
};
</script>
