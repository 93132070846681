



























































import { Component, Prop, Vue } from "vue-property-decorator";
import AttachmentDialog from "../attachments/AttachmentDialog.vue";
import {
  INSPECTION_FAIL_PASS_PASS,
  INSPECTION_FAIL_PASS_FAIL,
} from "@/utils/utils";
import { VehicleInspection } from "@/model/inspection/vehicle-inspection.model";

@Component({
  components: {
    AttachmentDialog,
  },
})
export default class VehicleInspectionData extends Vue {
  @Prop()
  inspection!: VehicleInspection;

  INSPECTION_FAIL_PASS_PASS = INSPECTION_FAIL_PASS_PASS;

  INSPECTION_FAIL_PASS_FAIL = INSPECTION_FAIL_PASS_FAIL;

  get inspectionItems() {
    return this.inspection ? [...this.inspection.items].sort((a, b) => a.scheme.myOrder - b.scheme.myOrder) : [];
  }

  openAttachment(attachment: any) {
    (this.$refs.attachmentDialog as any).openDialog({ attachment });
  }

  supportedAttachments(item: any) {
    return item.attachments.filter((it: any) => it.thumbnail);
  }
}
