<template>
  <v-btn
    v-if="!targetRoute && state == 'view'"
    @click="state = 'edit'"
    x-small
    class="theme-text-color"
    color="secondary"
    >{{ $localizationService.localize("drp.route_label.set_btn") }}</v-btn
  >
  <div v-else-if="state == 'view'" :class="routeClass" @click="state = 'edit'">
    <v-tooltip open-delay="500" bottom :disabled="!isDuplicate">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">{{ targetRoute }}</span>
      </template>
      <span>{{
        $localizationService.localize("drp.value_already_in_use")
      }}</span>
    </v-tooltip>
  </div>
  <div v-else class="route-select d-flex flex-row align-center">
    <RouteSelect
      :routesUsesCount="routesUsesCount"
      :value="targetRoute"
      @input="newRoute = $event"
      @submit="save()"
      @cancel="cancel()"
    />
    <v-icon class="ml-2" color="green" @click="save()">mdi-check</v-icon>
    <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
  </div>
</template>

<script>
import RouteSelect from "./RouteSelect.vue";

export default {
  name: "RouteLabel",

  components: {
    RouteSelect,
  },

  props: {
    targetRoute: String,
    routesUsesCount: Object,
  },

  data: () => ({
    state: "view",
    newRoute: "",
  }),

  computed: {
    isDuplicate() {
      return this.routesUsesCount[this.targetRoute] > 1;
    },
    routeClass() {
      return this.isDuplicate ? "orange--text" : "";
    },
  },

  methods: {
    save() {
      this.state = "view";
      this.$emit("onChangeData", this.newRoute);
    },
    cancel() {
      this.state = "view";
    },
  },
};
</script>

<style scoped lang="scss">
.route-select {
  min-width: 100px;
  max-width: 150px;
}
</style>
