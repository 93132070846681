




















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { API } from "aws-amplify";
import { ChannelMessagingSettings, ChannelMessagingSettingsBehaviour } from "@/types/types";
import RocketChatSettingsModule from "@/store/rocket-chat-settings.store";
import {
  bodyWithAuthHeader, CAD, DEFAULT, PERSONAL,
} from "@/utils/utils";

const rocketChatSettingsModule = RocketChatSettingsModule.namespace;
const rocketChatModule = namespace("rocketChatModule");

@Component
export default class SelectedRabbit extends Vue {
  @rocketChatModule.State
  menuOpened!: boolean

  @rocketChatModule.Getter
  recipientId!: string

  @rocketChatSettingsModule.State
  channelMessagingSettings!: ChannelMessagingSettings;

  @rocketChatSettingsModule.State
  lastSelectedChannel!: string

  @rocketChatSettingsModule.Mutation
  updateSelectedRecipientPhone!: (value: any) => void

  @rocketChatSettingsModule.Mutation
  changeChannelMessagingSettings!: (value: any) => void

  @rocketChatSettingsModule.State
  selectedRecipientPhone!: any

  get selectedDevice() {
    return this.selectedRecipientPhone || {};
  }

  checkDevice() {
    return this.channelMessagingSettings
      && this.channelMessagingSettings.driverPhones.some((it) => it.id == this.selectedDevice.id && !(it as any).disabled);
  }

  async mounted() {
    if (!(window as any).checkInterval) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      (window as any).checkInterval = setInterval(async () => {
        const lastSelectedChannel = self.lastSelectedChannel;

        if (self.lastSelectedChannel) {
          const body = await bodyWithAuthHeader();
          const settings = await API.get("core", `/messaging/channel-settings/audience/${lastSelectedChannel}/available-phone-numbers`, body);

          if (self.lastSelectedChannel != lastSelectedChannel) {
            self.updateSelectedRecipientPhone(undefined);
            return;
          }

          settings.driverPhones = [
            {
              id: null, name: DEFAULT.text, number: null, behaviour: ChannelMessagingSettingsBehaviour.DEFAULT,
            },
            {
              id: null, name: PERSONAL.text, number: null, behaviour: ChannelMessagingSettingsBehaviour.PERSONAL,
            },
            {
              id: null, name: CAD.text, number: null, behaviour: ChannelMessagingSettingsBehaviour.CAD,
            },
            ...settings.driverPhones];

          const selectedPhone = settings.selectedDevice;

          if (settings.selectedDevice && !settings.driverPhones.find((it: any) => it.id == selectedPhone.id)) {
            selectedPhone.disabled = true;
            settings.driverPhones.push(selectedPhone);
          }

          self.changeChannelMessagingSettings(settings);
          self.updateSelectedRecipientPhone(selectedPhone);
        }
      }, 5000);
    }
  }
}
