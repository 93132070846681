var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
  card: true,
  'bmtc-inprocess': _vm.driver.status === 'processing',
  'bmtc-sent': _vm.driver.status === 'done'
}},[_c('v-card-title',{staticClass:"bmtc-headline"},[_vm._v(_vm._s(_vm.driver.name))]),_c('v-card-subtitle',{class:{
    'bmtc-subtitle': true,
    'bmtc-phone-not-found': !_vm.driver.phone
  }},[_vm._v(_vm._s(_vm.driver.phone || "Phone not found"))]),(!_vm.beginSending)?_c('v-icon',{staticClass:"close-icon",on:{"click":function($event){return _vm.onCancel()}}},[_vm._v("mdi-close")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }