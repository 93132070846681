var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.editedVehicle.id ? _vm.$localizationService.localize( "vehicle_page.dialog.add_and_edit.edit_title" ) : _vm.$localizationService.localize( "vehicle_page.dialog.add_and_edit.new_title" ))+" ")])]),_c('v-card-text',{staticClass:"max-height-content"},[(_vm.open)?_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.name'
              ),"disabled":_vm.loading},model:{value:(_vm.editedVehicle.name),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "name", $$v)},expression:"editedVehicle.name"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"rules":_vm.vinRules,"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.vin'
              ),"disabled":_vm.loading},model:{value:(_vm.editedVehicle.vin),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "vin", $$v)},expression:"editedVehicle.vin"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.VEHICLE_TYPES,"item-text":"text","item-value":"value","return-value":"","label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.vehicle_type'
              )},model:{value:(_vm.editedVehicle.type),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "type", $$v)},expression:"editedVehicle.type"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.VEHICLE_STATUSES,"item-text":"text","item-value":"value","return-value":"","label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.status'
              ),"disabled":_vm.loading},on:{"change":function($event){return _vm.onChangeVehicleStatus($event)}},model:{value:(_vm.editedVehicle.status),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "status", $$v)},expression:"editedVehicle.status"}})],1),(_vm.VEHICLE_OUT_OF_SERVICE_STATUSES.some(function (it) { return it.value === _vm.editedVehicle.status; }))?_c('v-row',[_c('v-autocomplete',{attrs:{"label":_vm.$localizationService.localize('vehicle_page.dialog.add_and_edit.param.vehicle_shop'),"items":_vm.matchedVehicleShops,"item-text":"name","return-object":"","clear-icon":"mdi-delete","clearable":"","search-input":_vm.searchVehicleShop,"loading":_vm.loadingVehicleShops,"disabled":_vm.loading},on:{"update:searchInput":function($event){_vm.searchVehicleShop=$event},"update:search-input":function($event){_vm.searchVehicleShop=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_shop_page.address", [item.address]))+" ")])],1)]}},{key:"append-outer",fn:function(){return [_c('v-icon',{on:{"click":_vm.openEditVehicleShopDialog}},[_vm._v("mdi-car-wrench")])]},proxy:true}],null,false,2273098105),model:{value:(_vm.editedVehicle.vehicleShop),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "vehicleShop", $$v)},expression:"editedVehicle.vehicleShop"}}),_c('EditVehicleShopDialog',{ref:"vehicleShopDialog",on:{"onAddOrSave":function($event){return _vm.applyCreatedVehicleShop($event)}}})],1):_vm._e(),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.make'
              ),"disabled":_vm.loading},model:{value:(_vm.editedVehicle.make),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "make", $$v)},expression:"editedVehicle.make"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.model'
              ),"disabled":_vm.loading},model:{value:(_vm.editedVehicle.model),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "model", $$v)},expression:"editedVehicle.model"}})],1),_c('v-row',[_c('v-autocomplete',{ref:"inputName",attrs:{"label":_vm.$localizationService.localize('vehicle_page.dialog.add_and_edit.param.fuel_card'),"items":_vm.matchedFuelCards,"item-text":"cardNumber","filter":_vm.filterObject,"return-object":"","clear-icon":"mdi-delete","clearable":"","search-input":_vm.searchFuelCard,"loading":_vm.loadingFuelCard,"disabled":_vm.loading,"placeholder":_vm.$localizationService.localize('common.begin_typing')},on:{"update:searchInput":function($event){_vm.searchFuelCard=$event},"update:search-input":function($event){_vm.searchFuelCard=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.cardNumber))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_page.card_id", [item.cardId]))+" ")])],1)]}},{key:"append-outer",fn:function(){return [_c('v-icon',{on:{"click":_vm.openEditFuelCardDialog}},[_vm._v("mdi-credit-card")])]},proxy:true}],null,false,3229582483),model:{value:(_vm.editedVehicle.fuelCard),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "fuelCard", $$v)},expression:"editedVehicle.fuelCard"}}),_c('EditFuelCardDialog',{ref:"fuelCardsDialog",on:{"onAddOrSaveCard":_vm.applyCreatedCardNumber}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.license_plate'
              ),"disabled":_vm.loading},model:{value:(_vm.editedVehicle.licensePlate),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "licensePlate", $$v)},expression:"editedVehicle.licensePlate"}})],1),_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$localizationService.localize(
                    'vehicle_page.dialog.add_and_edit.param.rental_expiry'
                  ),"disabled":_vm.loading,"readonly":""},model:{value:(_vm.editedVehicle.rentalExpiry),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "rentalExpiry", $$v)},expression:"editedVehicle.rentalExpiry"}},'v-text-field',attrs,false),on))]}}],null,false,2276579914),model:{value:(_vm.rentalExpiryMenu),callback:function ($$v) {_vm.rentalExpiryMenu=$$v},expression:"rentalExpiryMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.rentalExpiryMenu = false}},model:{value:(_vm.editedVehicle.rentalExpiry),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "rentalExpiry", $$v)},expression:"editedVehicle.rentalExpiry"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.rentalExpiryMenu = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")])],1)],1)],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.rental_provider'
              ),"disabled":_vm.loading},model:{value:(_vm.editedVehicle.rentalProvider),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "rentalProvider", $$v)},expression:"editedVehicle.rentalProvider"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.VEHICLE_FLEET_MANAGEMENT_COMPANY,"item-text":"text","item-value":"value","return-value":"","label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.fleet_management_company'
              ),"disabled":_vm.loading,"clearable":""},model:{value:(_vm.editedVehicle.fleetManagementCompany),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "fleetManagementCompany", $$v)},expression:"editedVehicle.fleetManagementCompany"}})],1),_c('v-row',[_c('v-textarea',{attrs:{"disabled":_vm.loading,"label":_vm.$localizationService.localize(
                'vehicle_page.dialog.add_and_edit.param.notes'
              )},model:{value:(_vm.editedVehicle.notes),callback:function ($$v) {_vm.$set(_vm.editedVehicle, "notes", $$v)},expression:"editedVehicle.notes"}})],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.saveButtonActive},on:{"click":_vm.addVehicle}},[_vm._v(" "+_vm._s(_vm.editedVehicle.id ? _vm.$localizationService.localize("btn.save") : _vm.$localizationService.localize("btn.add"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }