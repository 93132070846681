<template>
  <Dashboard grafana-path="/d/OgaiwKFvn/fico-dashboard" />
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "FicoPage",

  routing: {
    localizationKey: "menu.tab.fico",
    icon: "mdi-table-large",
    path: "/fico",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: {
    Dashboard,
  },
};
</script>
