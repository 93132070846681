<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              "company_management_page.user_invitations.dialog.add.title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row v-if="open">
            <v-col cols="6">
              <v-text-field
                v-model="editedUserInvitation.email"
                :label="
                  $localizationService.localize(
                    'company_management_page.user_invitations.dialog.add.param.email'
                  )
                "
                :rules="emailRules"
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="editedUserInvitation.userType"
                :items="USER_TYPES"
                item-text="text"
                item-value="value"
                :label="
                  $localizationService.localize(
                    'company_management_page.user_invitations.dialog.add.param.user_type'
                  )
                "
                persistent-hint
                single-line
                :disabled="loading"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false" :disabled="loading">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addUserInvitation"
          :disabled="!saveButtonActive"
        >
          {{ $localizationService.localize("btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { EMAIL_REGEX, USER_TYPES, USER_TYPE_ADMIN } from "@/utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CreateUserInvitationDialog",

  data: () => ({
    editedUserInvitationOriginal: {},
    editedUserInvitation: {},

    open: false,
    loading: false,

    emailRules: [
      (value) => EMAIL_REGEX.test(value)
        || locService.localize("error.validation.valid_email"),
    ],

    USER_TYPES,
  }),

  computed: {
    ...mapState("userInvitationModule", ["processingUserInvitation"]),
    saveButtonActive() {
      if (this.loading) return false;

      if (
        this.emailRules.find(
          (rule) => rule(this.editedUserInvitation.email) !== true,
        )
      ) {
        return false;
      }

      return Object.keys(this.editedUserInvitation).find(
        (key) => this.editedUserInvitationOriginal[key]
          != this.editedUserInvitation[key],
      );
    },
  },

  methods: {
    openDialog() {
      this.editedUserInvitationOriginal = { userType: USER_TYPE_ADMIN.value };
      this.editedUserInvitation = {

        ...this.editedUserInvitationOriginal,
      };
      this.open = true;
    },

    async addUserInvitation() {
      this.loading = true;

      try {
        const result = await this.$store.dispatch(
          "userInvitationModule/addUserInvitation",
          this.editedUserInvitation,
        );
        Vue.toasted.success(result, {
          duration: 5000,
        });
        this.open = false;
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }
      this.loading = false;
    },
  },
};
</script>
