














































import {
  Component, Emit, Prop, Vue, Watch,
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import TriggerAgentsModule from "@/store/trigger-agents.store";
import { TwaMode } from "@/utils/ts-utils";
import TemplatesWritingAssistant from "@/components/templates-writing-assistant/TemplatesWritingAssistant.vue";
import { CloseIssueActionDraftTemplate } from "@/model/trigger/action-draft-templates/issue/close-issue-action-draft-template.model";
import TriggersModule from "@/store/triggers.store";
import { Trigger } from "@/model/trigger/trigger.model";
import { TriggerDto } from "@/types/types";
import { UserInfo } from "@/model/user-info.model";
import { AssistantConstant } from "@/model/assistant-constant.model";

const triggerAgentsModule = TriggerAgentsModule.namespace;
const userModule = namespace("userModule");
const triggerModule = TriggersModule.namespace;

const locService = Vue.prototype.$localizationService;

@Component({
  components: {
    TemplatesWritingAssistant,
  },
})
export default class CloseIssueActionDraftTemplateAgent extends Vue {
  static agentName = "CloseIssueActionDraftTemplateAgent";

  locService = locService

  TwaMode = TwaMode;

  issueActionTemplate: CloseIssueActionDraftTemplate | null = null;

  search = "";

  model: UserInfo | Trigger<any> | string | null = null;

  triggers: Trigger<any>[] = [];

  @Prop()
  actionDraftTemplate!: CloseIssueActionDraftTemplate | null;

  @triggerAgentsModule.State
  issueUnitAgents!: any;

  @userModule.State
  displayUsers!: UserInfo[]

  @userModule.Action
  loadPage!: () => Promise<UserInfo>

  @triggerModule.Action
  loadAllTriggers!: () => Promise<TriggerDto[]>

  // TODO: bind to backend
  specificConstants = [
    new AssistantConstant(
      "vehicle",
      "Changed vehicle",
      undefined,
    ),
  ]

  get actionTemplate() {
    return this.issueActionTemplate;
  }

  get disableSave() {
    return (!this.issueActionTemplate?.authorUser || !this.issueActionTemplate?.authorTrigger || !this.issueActionTemplate?.authorExpression)
        && !this.issueActionTemplate?.relatedVehicle;
  }

  get autoCompleteItems(): (UserInfo | Trigger<any>)[] {
    const users = [...this.displayUsers].map((it) => UserInfo.fromDto(it));
    return [...users, ...this.triggers];
  }

  get isAutocomplete() {
    if (this.search && this.search.startsWith("${")) {
      this.issueActionTemplate!.authorExpression = this.search;
      this.$nextTick(() => (this.$refs.twa as InstanceType<typeof TemplatesWritingAssistant>).onFocus());
      return false;
    }
    return true;
  }

  @Watch("model")
  handleModel() {
    if (this.model instanceof UserInfo) {
      this.issueActionTemplate!.authorUser = this.model;
    } else if (this.model instanceof Trigger) this.issueActionTemplate!.authorTrigger = this.model;
  }

  @Emit("onGetDisableSave")
  emitDisableSave() {
    return this.disableSave;
  }

  determineInputType(event: string) {
    this.search = event;
  }

  async mounted() {
    if (!this.actionDraftTemplate) {
      this.issueActionTemplate = CloseIssueActionDraftTemplate.createEmpty();
    } else {
      this.issueActionTemplate = this.actionDraftTemplate;
      if (this.issueActionTemplate.authorExpression) {
        this.search = this.issueActionTemplate.authorExpression;
      } else if (this.issueActionTemplate.authorUser) {
        this.model = this.issueActionTemplate.authorUser;
      } else if (this.issueActionTemplate.authorTrigger) {
        this.model = this.issueActionTemplate.authorTrigger;
      }
    }
    await this.loadPage();
    const pureTriggers = await this.loadAllTriggers();
    this.triggers = pureTriggers.map((it) => Trigger.fromDto(it));
    this.emitDisableSave();
  }
}
