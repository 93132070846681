<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-data-table
          class="issue-table-display"
          :class="{'not-default-page-size': !this.isDefaultSize}"
          :headers="headers"
          :items="displayIssues"
          :loading="currentPageLoading || !tableInited"
          :server-items-length="totalCount"
          :options="{
            page: pageIndex,
            itemsPerPage: pageSize,
            sortBy: sort.sortBy,
            sortDesc: sort.sortDesc,
          }"
          @update:options="paginate($event)"
          :footer-props="footerProps"
          multi-sort
          @click:row="openIssueCardDialog"
          :item-class="getIssueBackgroundColor"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("issue_page.title") }}
                <TableSettings
                  class="ml-5"
                  :pIsVisible="tableInited && !isDefaultSettings"
                  :pSaveButtonTooltip="$localizationService.localize('issue_page.settings_tooltip.save')"
                  :pDefaultButtonTooltip=
                    "$localizationService.localize('issue_page.settings_tooltip.apply_default')"
                  @onSaveSettings="saveSettings()"
                  @onApplyDefaultSettings="applyDefaultSettings()"
                />
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchString"
                :label="$localizationService.localize('issue_page.search')"
                class="search-field"
                hide-details
                @input="onInputSearchString()"
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-2"
                @click="openIssueCardDialog()"
              >
                {{ $localizationService.localize("issue_page.new_btn") }}
              </v-btn>
              <IssueCardDialog ref="issueCardDialog" @onCloseDialog="reloadPage" />
              <ConfirmationDialog ref="deleteDialog" />
            </v-toolbar>
          </template>

          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
              :key="header.value"
              :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="content-max-width">
              {{ item.name }}
            </div>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div v-if="item.status" :class="item.status.color">
              {{ item.status.text }}
            </div>
          </template>

          <template v-slot:[`item.severity`]="{ item }">
            <div v-if="item.severity" :class="item.severity.color">
              {{ item.severity.text }}
            </div>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
              {{ momentDateAndTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.essence`]="{ item }">
            <div class="content-max-width">
              {{ item.essence }}
            </div>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div class="content-max-width">
              {{ item.description }}
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import lodash from "lodash";
import { getModule } from "vuex-module-decorators";
import { momentDateAndTime } from "@/utils/utils";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import IssueCardDialog from "@/components/issues/issue-card/IssueCardDialog.vue";
import TableSettings from "@/components/TableSettings.vue";
import tableSortParserMixin from "@/utils/table-sort-parser";
import IssuesModule from "@/store/issues.store";
import {
  ISSUES_SEVERITY_CRITICAL, ISSUES_SEVERITY_WARNING, ISSUES_STATUS_OPEN, ISSUES_STATUS_RESOLVED,
} from "@/utils/ts-utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Issues",

  mixins: [tableSortParserMixin],

  components: {
    IssueCardDialog,
    ConfirmationDialog,
    TableSettings,
  },

  data: () => ({
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    searchString: "",
    invitationInProgess: {},

    headers: [
      {
        text: locService.localize("issue_page.header.title"),
        value: "title",
        sortable: true,
      },
      {
        text: locService.localize("issue_page.header.status"),
        value: "status",
        sortable: true,
      },
      {
        text: locService.localize("issue_page.header.severity"),
        value: "severity",
        sortable: true,
      },
      {
        text: locService.localize("issue_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("issue_page.header.essence"),
        value: "essence",
        sortable: true,
      },
      {
        text: locService.localize("issue_page.header.description"),
        value: "description",
        sortable: true,
      },
    ],
  }),

  computed: {
    ...mapState("issuesModule", [
      "currentPageLoading",
      "currentPage",
      "displayIssues",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),
    ...mapState("issueTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("issueTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("issueTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    getIssueBackgroundColor(issue) {
      if (issue.status == ISSUES_STATUS_OPEN) {
        if (issue.severity == ISSUES_SEVERITY_CRITICAL) return "critical-issue-background";
        if (issue.severity == ISSUES_SEVERITY_WARNING) return "warning-issue-background";
      }
      if (issue.status == ISSUES_STATUS_RESOLVED) return "resolved-issue-background";
      return "";
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("issuesModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    saveSettings() {
      this.$store.commit("issueTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("issue_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("issueTableSettingsModule/applyDefaultSettings");
    },

    openIssueCardDialog(issue) {
      this.$refs.issueCardDialog.openDialog(issue);
    },

    reloadPage() {
      this.$store.dispatch("issuesModule/reloadPage");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
        || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
        || this.$route.query.searchString != (params.get("searchString") || undefined)
        || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/issues/?${params.toString()}`);
      }
    },

    onInputSearchString: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("issuesModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),
  },

  created() {
    getModule(IssuesModule, this.$store);

    this.$store.dispatch("issueTableSettingsModule/loadDefaultSettingsFromStorage");

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("issueTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("issueTableSettingsModule/applyQuerySettings", query);

      if (query.searchString) {
        this.searchString = query.searchString;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.v-badge {
  vertical-align: middle;
}
.issue-table-display {
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;

  .content-max-width {
    display: flex;
    align-items: center;
    white-space: normal;
    width: 100%;
    height: 100%;
    line-height: 20px;
  }
}
.search-field {
  max-width: 250px;
}
</style>

<style lang="scss">
.issue-table-display {
  .critical-issue-background {
    background-color: rgba(255, 0, 0, 0.1);
  }

  .warning-issue-background {
    background-color: rgba(251, 140, 0, 0.15);
  }

  .resolved-issue-background {
    background-color: rgba(76, 175, 80, 0.1);
  }
}
</style>
