export class DailyRouteRescueBonus {
  active;

  constructor(active) {
    this.active = active;
  }

  static fromDto(dto) {
    return new DailyRouteRescueBonus(dto.active);
  }
}
