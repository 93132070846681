import { Vehicle } from "@/model/vehicle.model";
import { TwaMode } from "@/utils/ts-utils";
import { Trigger } from "@/model/trigger/trigger.model";
import { UserInfo } from "@/model/user-info.model";

export type RocketChatDriver = {
    name: string;
    id: string;
    phone: string | undefined;
};

export type DeviceSelectionEntry = {
    id: string | null;
    number: string;
    name: string;
    behaviour: ChannelMessagingSettingsBehaviour;
};

export type UserMessagingSettings = {
    automaticallySendSms: boolean;
    defaultMessagingBehaviour: UserMessagingSettingsBehaviour;
}

export enum UserMessagingSettingsBehaviour {
    PERSONAL = "PERSONAL", CAD = "CAD"
}

export enum ChannelMessagingSettingsBehaviour {
    PERSONAL = "PERSONAL", CAD = "CAD", DEFAULT = "DEFAULT", CUSTOM = "CUSTOM"
}

export type ChannelMessagingSettings = {
    driverPhones: DeviceSelectionEntry[];
    selectedDevice: DeviceSelectionEntry;
    behaviour: ChannelMessagingSettingsBehaviour;
};

export type IssueDto = {
    id: string;
    createdAt: string;
    title: string;
    description: string;
    essence: string;
    status: string;
    dataUnits: IssueDataUnitDto[];
    severity: string;
}

export type IssueDataUnitDto = {
    id: string;
    issueId: string;
    type: IssueDataUnitType;
    vehicle: Vehicle | null;
    user: IssueUnitUser | null;
    trigger: TriggerDto | null;
    reminder: LightweightReminderDto | null;
}

export enum IssueDataUnitType {
    UserAuthorDataUnit="UserAuthorDataUnit",
    RelatedVehicleDataUnit="RelatedVehicleDataUnit",
    TriggerAuthorDataUnit="TriggerAuthorDataUnit",
    ReminderAuthorDataUnit="ReminderAuthorDataUnit"
}

export type IssueStatus = {
    text: string;
    value: string;
    color: string;
    background: string;
}

export type IssueSeverity = {
    text: string;
    value: string;
    color: string;
    background: string;
}

export type IssueUnitType = {
    type: IssueDataUnitType;
    mainAgentName: string;
    subAgentName?: string;
}

export type IssueUnitUser = {
    id: string;
    name: string;
}

export type IssueDataUnitToDto = {
    id?: string;
    type: IssueDataUnitType;
    vehicleId?: string;
}

export type IssueToDto = {
    id: string;
    title: string;
    description: string;
    essence: string;
    status: string;
    dataUnits: IssueDataUnitToDto[];
    severity: string;
}

export type TriggerActionUnitType = {
    text: string;
    value: TriggerActionDraftUnit;
    agentName: string;
}

export type TemplateUnitsDto = {
    type: TriggerActionDraftUnit;
    vehicleContextSource?: string;
}

export type TriggerActionType = {
    text: string;
    value: TriggerActionDraft;
    agentName: string;
    agents?: TriggerActionUnitType[];
}

export type ActionDraftTemplateDto = {
    type: TriggerActionDraft;
    issueTitle?: string;
    issueDescription?: string;
    issueEssence?: string;
    order?: number;
    units?: TemplateUnitsDto[];
}

export type CloseIssueActionDraftTemplateDto = {
    type: TriggerActionDraft;
    relatedVehicle?: string;
    authorUser?: UserInfo | null;
    authorTrigger?: Trigger<any> | null;
    authorExpression?: string;
    order?: number;
}

export type TriggerDto = {
    id: string;
    name: string;
    type: TriggerTypes;
    priority: number;
    actionDraftTemplates: ActionDraftTemplateDto[];
}

export type VehicleChangedTriggerDto = TriggerDto & {
    toValueCondition: string;
    fromValueCondition: string;
}

export type LightweightTriggerDto = {
    id: string;
    name: string;
    type: TriggerTypes;
    priority: number;
}

export type LightweightReminderDto = {
    id: string;
    name: string;
}

export enum TriggerActionDraft {
    CreateIssueActionDraftTemplate = "CreateIssueActionDraftTemplate",
    CloseIssueActionDraftTemplate = "CloseIssueActionDraftTemplate"
}

export enum TriggerActionDraftUnit {
    RelatedVehicleDataUnit = "RelatedVehicleDataUnit"
}

export enum TriggerTypes {
    VehicleChangedTrigger = "VehicleChangedTrigger"
}

export type SimpleValueCondition = {
    property: string;
    operator: string;
    thresholdValue: string;
}

export type TriggerType = {
    text: string;
    value: TriggerTypes;
    agentName: string;
}

export type TwaModeType = {
    type: TwaMode;
    componentHeight: number;
    backdropHeight: number;
    componentClass: string;
    insideComponent: string;
}
