import {
  Action, Module, Mutation, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
// eslint-disable-next-line no-unused-vars
import { Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { RocketChatDriver } from "@/types/types";
import WebsocketBulk from "@/components/messaging/WebsocketBulk";

const moduleName = "bulkMessage";
const locService = Vue.prototype.$localizationService;

@Module({ namespaced: true, name: moduleName })
export default class BulkMessage extends VuexModule {
    public drivers: RocketChatDriver[] = []

    public sending = false;

    w: WebsocketBulk | undefined = undefined

    sentCount = 0

    text = ""

    get sentAsPercentage() {
      return (this.sentCount / this.recipients.length) * 100;
    }

    get recipients() {
      return this.drivers.filter((it) => it && it.phone);
    }

    @Mutation
    public addDriver(driver: RocketChatDriver): void {
      this.drivers.push(driver);
    }

    @Mutation
    public clearDrivers(): void {
      this.drivers.length = 0;
    }

    @Mutation
    public removeDriver(driver: RocketChatDriver): void {
      this.drivers.splice(this.drivers.findIndex((it) => it && driver && it.id == driver.id), 1);
    }

    @Mutation
    public incrementSentCount(): void {
      this.sentCount += 1;
    }

    @Mutation
    public clearSent(): void {
      this.sentCount = 0;
    }

    @Mutation
    public startSending(): void {
      this.sending = true;
    }

    @Mutation
    public finishSending(): void {
      this.sending = false;
    }

    @Mutation
    public setText(text: string): void {
      this.text = text;
    }

    @Action({ rawError: true })
    public async sendMessages(text: string): Promise<void> {
      this.startSending();
      let w: any;
      await new Promise((resolve) => {
        this.w = new WebsocketBulk(resolve);
        this.w.connect();
        w = this.w;
      });

      // eslint-disable-next-line no-array-constructor
      const completeHandlers = new Array<Promise<any>>();
      // eslint-disable-next-line no-restricted-syntax
      for (const driver of this.recipients) {
        // eslint-disable-next-line no-loop-func
        const completeHandler = new Promise<void>((resolveComplete) => {
          // eslint-disable-next-line no-loop-func
          w.sendMessageToDriver(driver, text,
            (message: any) => {
              if (message.status == "SENT") {
                w.markToSend(message);
              }
            },
            () => {
              // @ts-ignore
              Vue.set(driver, "status", "done");
              this.incrementSentCount();
              resolveComplete();
            },
            () => {
              // @ts-ignore
              Vue.set(driver, "status", "processing");
            });
        });
        completeHandlers.push(completeHandler);
      }

      Promise.all(completeHandlers).then(() => {
        Vue.toasted.success(
          locService.localize("bulk_message_dialog.success_sent", [text.slice(0, 20), this.sentCount]),
          { duration: 15000 },
        );
        this.clearSent();
        this.finishSending();
        this.clearDrivers();
        this.setText("");
        this.closeWebSocket();
      });
    }

    @Action({ rawError: true })
    public closeWebSocket() {
      try {
            this.w?.websocket?.close();
            this.w = undefined;
      } catch (e) {
        console.error(e);
      }
    }

    static get namespace() {
      return namespace(moduleName);
    }
}
