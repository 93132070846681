<template>
  <v-container fluid>
    <v-row align="start" class="fuelCards" justify="start">
      <v-col cols="12">
        <v-data-table
            :class="{'not-default-page-size': !this.isDefaultSize}"
            :footer-props="footerProps"
            :headers="headers"
            :items="displayFuelCards"
            :loading="pageLoading || !tableInited"
            :options="{
            page: pageIndex,
            itemsPerPage: pageSize,
            sortBy: sort.sortBy,
            sortDesc: sort.sortDesc,
            }"
            :server-items-length="totalCount"
            multi-sort
            @update:options="paginate($event)"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{ $localizationService.localize("fuel_card_page.title") }}
                <TableSettings
                    :pDefaultButtonTooltip="
                    $localizationService.localize(
                      'fuel_card_page.settings_tooltip.apply_default'
                    )
                  "
                    :pIsVisible="tableInited && !isDefaultSettings"
                    :pSaveButtonTooltip="
                    $localizationService.localize(
                      'fuel_card_page.settings_tooltip.save'
                    )
                  "
                    class="ml-5"
                    @onApplyDefaultSettings="applyDefaultSettings()"
                    @onSaveSettings="saveSettings()"
                />
              </v-toolbar-title>
              <v-spacer/>
              <v-text-field
                  v-model="searchString"
                  :label="$localizationService.localize('fuel_card_page.search')"
                  class="search-field"
                  hide-details
                  width="200"
                  @input="searchStringDebounceWatch()"
              ></v-text-field>
              <v-btn class="ml-2" color="primary" @click="openEditFuelCardDialog()">
                {{ $localizationService.localize("fuel_card_page.new_btn") }}
              </v-btn>
              <EditFuelCardDialog ref="fuelCardsDialog"/>
              <ConfirmationDialog ref="deleteDialog"/>
            </v-toolbar>
          </template>
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
                :key="header.value"
                :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.validThru`]="{ item }">
            {{ item.validThru }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDateAndTime(item.updatedAt) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" medium @click="openEditFuelCardDialog(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon color="red" medium @click="openDeleteFuelCardDialog(item)"
            >mdi-delete
            </v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import lodash from "lodash";
import EditFuelCardDialog from "./EditFuelCardDialog";
import ConfirmationDialog from "../ConfirmationDialog";
import TableSettings from "@/components/TableSettings.vue";
import tableSortParserMixin from "../../utils/table-sort-parser";
import { momentDateAndTime } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "FuelCards",

  mixins: [tableSortParserMixin],

  components: {
    EditFuelCardDialog,
    ConfirmationDialog,
    TableSettings,
  },

  computed: {
    ...mapState("fuelCardsModule", [
      "displayFuelCards",
      "totalCount",
      "currentPage",
      "pageLoading",
    ]),
    ...mapState("fuelCardTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("fuelCardTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },
  },

  data: () => ({

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    localStorageTable: "fuel_cards.table.size",
    tableInited: false,

    searchString: "",

    headers: [
      {
        text: locService.localize("fuel_card_page.header.card_number"),
        value: "cardNumber",
        sortable: true,
      },
      {
        text: locService.localize("fuel_card_page.header.expiration_date"),
        value: "validThru",
        sortable: true,
      },
      {
        text: locService.localize("fuel_card_page.header.card_id"),
        value: "cardId",
        sortable: true,
      },
      {
        text: locService.localize("fuel_card_page.header.created_at"),
        value: "createdAt",
        sortable: true,
      },
      {
        text: locService.localize("fuel_card_page.header.updated_at"),
        value: "updatedAt",
        sortable: true,
      },
      {
        text: locService.localize("fuel_card_page.header.actions"),
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),

  methods: {
    momentDateAndTime,

    paginate(data) {
      this.$store.commit("fuelCardTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      this.loadPage({
        index: data.page - 1,
        size: data.itemsPerPage,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
        searchString: this.searchString,
      });
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("fuelCardsModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    openEditFuelCardDialog(fuelCard) {
      this.$refs.fuelCardsDialog.openDialog(fuelCard);
    },

    openDeleteFuelCardDialog(fuelCard) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize("fuel_card_page.dialog.delete.title"),
        text: locService.localize("fuel_card_page.dialog.delete.text", [
          fuelCard.cardNumber,
        ]),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$store.dispatch("fuelCardsModule/deleteFuelCard", fuelCard),
      });
    },

    saveSettings() {
      this.$store.commit("fuelCardTableSettingsModule/saveSettings");

      Vue.toasted.success(
        locService.localize("fuel_card_page.settings_msg.save"),
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("fuelCardTableSettingsModule/applyDefaultSettings");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
          || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
          || this.$route.query.searchString != (params.get("searchString") || undefined)
          || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/fuelcards/?${params.toString()}`);
      }
    },

    searchStringDebounceWatch: lodash.debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("fuelCardsModule/loadPage", newPage);
      this.updateRoutePath();
    }, 600),
  },
};
</script>

<style lang="scss">
.fuelCards {
  .search-field {
    max-width: 250px;
  }

  .v-data-table-header-mobile {
    display: none;
  }
}
</style>
