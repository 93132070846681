import { render, staticRenderFns } from "./VehicleInspectionsPage.vue?vue&type=template&id=91703b84&"
import script from "./VehicleInspectionsPage.vue?vue&type=script&lang=js&"
export * from "./VehicleInspectionsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports