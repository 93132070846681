export class Subject {
  id

  type

  requests

  constructor(id, type, requests) {
    this.id = id;
    this.type = type;
    this.requests = requests;
  }
}

export class DefaultSubjectTypes {
  static REPORT = "report"
}
