// @ts-ignore
import lodash from "lodash";

export class AssistantConstant {
  name: string;

  description: string;

  children: AssistantConstant[] | undefined = [];

  parent: AssistantConstant | undefined;

  get fullName(): string {
    return this.parent ? `${this.parent.fullName}.${this.name}` : this.name;
  }

  get allConstants(): AssistantConstant[] {
    return this.children
      ? lodash.flatten([this, ...this.children.map((it) => it.allConstants)])
        .filter((it) => it.children && it.children.length === 0)
      : [];
  }

  constructor(
    name: string,
    description: string,
    parent: AssistantConstant | undefined,
  ) {
    this.name = name;
    this.description = description;
    this.parent = parent;
  }

  static fromDto(dto: AssistantConstantDto, parent: AssistantConstant | undefined) {
    const constant = new AssistantConstant(dto.name, dto.description, parent);
    constant.children = dto.childrens ? dto.childrens.map((it) => (
      AssistantConstant.fromDto(it, constant)
    )) : [];
    return constant;
  }
}

export type AssistantConstantDto = {
  name: string;

  description: string;

  childrens: AssistantConstantDto[];
}
