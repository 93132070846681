var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"scans-table-display",attrs:{"headers":_vm.headers,"items":_vm.displayScans,"loading":_vm.currentPageLoading,"server-items-length":_vm.totalCount,"footer-props":_vm.footerProps},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.scans_table.title" ))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openRequestScanDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.scan"))+" ")]),_c('CoachingMenu',{staticClass:"ml-2"}),_c('RequestScanDialog',{ref:"requestScanDialog"})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateTime(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'COMPLETE')?_c('div',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.scans_table.status.complete" ))+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"green"}},[_vm._v("mdi-check")])],1):(item.status == 'ENGINE_ERROR')?_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.scans_table.status.engine_error" ))+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1):_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.scans_table.status.processing" ))+" "),_c('v-progress-circular',{staticClass:"ml-1 mb-1",attrs:{"size":15,"width":2,"color":"primary","indeterminate":""}})],1)]}},{key:"item.newInfractions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "COMPLETE" ? item.createdInfractionIds.length : "-")+" ")]}},{key:"item.scanTimeRange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateTime(item.fromTime))+" "),_c('div',{staticClass:"range-delimeter"},[_vm._v("-")]),_vm._v(" "+_vm._s(_vm.momentDateTime(item.toTime))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }