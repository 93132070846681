import lodash from "lodash";
import { DailyRoutePlanRow } from "./row.model";
import { DriverEntry } from "./driver-entry.model";

export class DailyRoutePlan {
    id;

    targetDate;

    rows;

    driverEntries;

    version;

    get driverEntriesMap() {
      return lodash.keyBy(this.driverEntries, "id");
    }

    constructor(id, targetDate, version) {
      this.id = id;
      this.targetDate = targetDate;
      this.version = version;
    }

    static fromDto(dto) {
      const plan = new DailyRoutePlan(dto.id, dto.targetDate, dto.version);
      plan.driverEntries = dto.driverEntries.map((driver) => DriverEntry.fromDto(driver, plan));
      plan.rows = dto.rows.map((row) => DailyRoutePlanRow.fromDto(row, plan.driverEntriesMap));
      return plan;
    }

    get allRescuers() {
      return lodash.flatten(this.rows.map((it) => it.allRescuers));
    }

    getAllRescuers(sort, valueAndSort) {
      return Object.fromEntries(
        this.rows.map((it) => [it.id, it.getSortedRescuers(sort, valueAndSort)]),
      );
    }

    get driverEntriesUsesCount() {
      const driverEntries = [...this.rows, ...this.allRescuers].mapNotNull((it) => it.driverEntry);

      return Object.fromEntries(
        driverEntries.map((it) => [it.id, driverEntries.filter((item) => item.id === it.id).length]),
      );
    }

    get allVehicles() {
      return [...this.rows, ...this.allRescuers].mapNotNull((it) => it.vehicle);
    }

    get vehiclesUsesCount() {
      const vehicles = [...this.rows, ...this.allRescuers].mapNotNull((it) => it.vehicle);

      return Object.fromEntries(
        vehicles.map((it) => [it.id, vehicles.filter((item) => item.id === it.id).length]),
      );
    }

    get countDevicesInUse() {
      const devices = lodash.flatten([...this.rows, ...this.allRescuers].map((it) => it.devices));
      return Object.fromEntries(
        devices.map((it) => [it.id, devices.filter((item) => item.id === it.id).length]),
      );
    }

    get ownersPerDevice() {
      const rowsAndRescues = lodash.flatten([...this.rows, ...this.allRescuers]);
      const devices = lodash.uniqBy(lodash.flatten(rowsAndRescues.map((it) => it.devices)), (it) => it.id);

      return Object.fromEntries(
        devices.map((it) => [
          it.id,
          lodash.uniqBy(
            rowsAndRescues.filter((suspect) => suspect.devices.find((sd) => sd.id == it.id))
              .mapNotNull((rowRescue) => rowRescue.currentDriverEntry || rowRescue.rescuer)
              .mapNotNull((entry) => entry.driver),
            (driver) => driver.id,
          ),
        ]),
      );
    }

    get stationsInUse() {
      return this.rows.mapNotNull((it) => it.station);
    }

    get routesUsesCount() {
      const routes = this.rows.mapNotNull((it) => it.route);
      return Object.fromEntries(
        routes.map((it) => [it, routes.filter((item) => item === it).length]),
      );
    }
}
