<template>
  <div>
    <EditDriverDialog
      ref="editDriverDialog"
      @editedDriver="setSelectedDriver"
    />
    <div class="content-container w-100">
      <v-text-field
        v-if="!selectedAction.isMatchToExistingDriver && !selectedAction.isCreateNewDriver"
        class="mr-2"
        :class="{'partial-auto-match-border-color': shouldHighlightPartialAutoMatch}"
        :label="
          $localizationService.localize(
            'mar_component.driver_selection_request.param.name'
          )
        "
        disabled
        :value="
          request.autoMatchedDriver && selectedAction.isAcceptAutoMatched
            ? request.autoMatchedDriver.name
            : ''
        "
      ></v-text-field>

      <v-autocomplete
        v-if="selectedAction.isMatchToExistingDriver"
        class="mr-2"
        ref="inputName"
        :label="
          $localizationService.localize(
            'mar_component.driver_selection_request.param.name'
          )
        "
        item-text="text"
        item-value="value"
        :items="matchedDriversEntries"
        v-model="selectedDriver"
        :search-input.sync="searchDriver"
        :loading="loadingDriversSearch"
        clearable
        @focus="onNameFieldFocused()"
        :disabled="!selectedAction.isMatchToExistingDriver"
      ></v-autocomplete>

      <v-text-field
        v-if="selectedAction.isCreateNewDriver"
        class="mr-2"
        :label="
          $localizationService.localize(
            'mar_component.driver_selection_request.param.name'
          )
        "
        v-model="newDriverName"
      ></v-text-field>

      <v-text-field
        v-if="!selectedAction.isCreateNewDriver"
        class="mr-2"
        :label="
          $localizationService.localize(
            'mar_component.driver_selection_request.param.transporter_id'
          )
        "
        :rules="[validateTransporterId]"
        :disabled="!selectedAction.isMatchToExistingDriver"
        v-model="currentTransporterId"
      ></v-text-field>

      <v-text-field
        v-if="selectedAction.isCreateNewDriver"
        class="mr-2"
        :label="
          $localizationService.localize(
            'mar_component.driver_selection_request.param.transporter_id'
          )
        "
        v-model="newTransporterId"
      ></v-text-field>

      <div class="select-and-icon">
        <v-select
          class="select-action-mobile"
          :label="
            $localizationService.localize(
              'mar_component.driver_selection_request.param.select_action'
            )
          "
          ref="selectAction"
          :items="actionsList"
          item-text="text"
          item-value="value"
          return-object
          v-model="selectedAction"
          @change="onActionSelect($event)"
        ></v-select>

        <div
          v-if="status.type == 'LOADING'"
          class="d-flex flex-column justify-center"
        >
          <v-progress-circular
            class="ml-4"
            :size="25"
            :width="2.5"
            color="primary"
            indeterminate
          >
          </v-progress-circular>
        </div>

        <v-icon
          v-else-if="status.type == 'OK'"
          class="ml-4 mr-2"
          size="30"
          :color="shouldHighlightPartialAutoMatch || (selectedAction || {}).isCreateNewDriver ? 'orange' : 'green'"
          >mdi-checkbox-marked-circle-outline</v-icon
        >

        <v-icon v-else class="ml-4 mr-2" size="30" :color="errorColor"
          >mdi-alert-circle-outline</v-icon
        >
      </div>
    </div>
    <p class="mb-1" v-html="
      $localizationService.localize(
        'mar_component.driver_selection_request.raw_data',
        [rawName, request.rawTransporterId || ''],
      )
     "></p>
    <p v-if="request.autoMatchedDriver && !selectedAction.isAcceptAutoMatched">
      {{
        $localizationService.localize(
          "mar_component.driver_selection_request.auto_matched_driver",
          [request.autoMatchedDriver.name]
        )
      }}
    </p>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import isEmpty from "lodash/isEmpty";
import { mapState } from "vuex";
import Vue from "vue";
import {
  CREATE_NEW_DRIVER,
  ACCEPT_AUTO_MATCH,
  IGNORE_THIS_DRIVER,
  MATCH_TO_EXISTING_DRIVER,
} from "../../utils/utils";
import EditDriverDialog from "../../components/drivers/EditDriverDialog";
import { store } from "../../main";

const locService = Vue.prototype.$localizationService;

function createNewDriverSolution(name, transporterId) {
  return {
    action: CREATE_NEW_DRIVER,
    specialClass: "orange",
    async preSendTransformer() {
      const result = await store.dispatch(
        "driverModule/addOrSaveDriver",
        {
          name,
          transporterId,
        },
      );

      return {
        ...this,
        action: MATCH_TO_EXISTING_DRIVER.value,
        driverId: result.id,
      };
    },
  };
}

export default {
  name: "DriverSelectionRequest",
  targetRequestType: "DriverSelectionRequest",

  props: {
    request: Object,
  },

  components: {
    EditDriverDialog,
  },

  data: () => ({
    loadingDriversSearch: false,
    searchDriver: null,
    selectedAction: {},
    selectedDriver: undefined,
    matchedDriversEntries: [],
    currentTransporterId: "",
    loadingDrivers: false,
    newDriverName: undefined,
    newTransporterId: undefined,
  }),

  computed: {
    ...mapState("marModule", ["autoIgnoreRequests"]),

    rawName() {
      if (!this.shouldHighlightPartialAutoMatch) {
        return this.request.rawName;
      }

      const autoMatchedName = this.request.autoMatchedDriver.name.split(" ");
      const rawName = this.request.rawName.split(" ");

      return rawName.map((word, index) => (
        word != autoMatchedName[index]
          ? `<span class="partial-highlight-text">${word}</span>`
          : word
      )).join(" ");
    },
    shouldHighlightPartialAutoMatch() {
      return this.request.isPartialAutoMatch() && (this.selectedAction || {}).isAcceptAutoMatched;
    },
    errorColor() {
      return this.autoIgnoreRequests.find(
        (suspect) => suspect.id == this.request.id,
      )
        ? "grey"
        : "red";
    },
    validateTransporterId(value) {
      return (
        !value
        || this.selectedAction != MATCH_TO_EXISTING_DRIVER
        || this.selectedDriver != undefined
        || locService.localize(
          "mar_component.driver_selection_request.validate.unknown_tranporter_id",
        )
      );
    },
    solution() {
      const newDriverName = this.newDriverName;
      const newTransporterId = this.newTransporterId;

      if (
        this.selectedAction == MATCH_TO_EXISTING_DRIVER
        && !this.selectedDriver
      ) { return undefined; }

      if (this.selectedAction == CREATE_NEW_DRIVER) {
        return createNewDriverSolution(newDriverName, newTransporterId);
      }

      return {
        action: this.selectedAction.value,
        driverId: this.selectedDriver ? this.selectedDriver.id : null,
        specialClass: this.selectedAction == ACCEPT_AUTO_MATCH && this.request.isPartialAutoMatch() ? "orange" : "",
      };
    },
    actionsList() {
      const list = [
        MATCH_TO_EXISTING_DRIVER,
        IGNORE_THIS_DRIVER,
        CREATE_NEW_DRIVER,
      ];

      if (this.request.autoMatchedDriver) list.push(ACCEPT_AUTO_MATCH);

      return list;
    },
    status() {
      if (this.selectedAction == ACCEPT_AUTO_MATCH) {
        return {
          type: "OK",
          description: locService.localize(
            "mar_component.driver_selection_request.status_description.auto_match.ok",
          ),
        };
      }
      if (this.selectedAction == CREATE_NEW_DRIVER) {
        return {
          type: "OK",
          description: locService.localize(
            "mar_component.driver_selection_request.status_description.create_new.ok",
          ),
        };
      }
      if (this.selectedAction == MATCH_TO_EXISTING_DRIVER) {
        if (this.selectedDriver) {
          return {
            type: "OK",
            description: locService.localize(
              "mar_component.driver_selection_request.status_description.existing.ok",
              [this.selectedDriver.name, this.request.rawName],
            ),
          };
        }
        return this.loadingDrivers
          ? { type: "LOADING", description: locService.localize("common.loading") }
          : {
            type: "ERROR",
            description: locService.localize(
              "mar_component.driver_selection_request.status_description.existing.error",
            ),
          };
      }
      if (this.selectedAction == IGNORE_THIS_DRIVER) {
        return {
          type: "OK",
          description: locService.localize(
            "mar_component.driver_selection_request.status_description.ignore.ok",
          ),
        };
      }

      return {
        type: "ERROR",
        description: locService.localize(
          "mar_component.driver_selection_request.status_description.unknown_state",
        ),
      };
    },
  },

  watch: {
    searchDriver() {
      if (this.selectedDriver && this.searchDriver === this.selectedDriver.name) {
        return;
      }

      if (this.searchDriver) {
        this.loadFilteredDrivers();
      }
    },

    selectedDriver(val) {
      this.currentTransporterId = val ? val.transporterId : undefined;
    },

    currentTransporterId: debounce(function (val) {
      if (this.selectedDriver && val == this.selectedDriver.transporterId) { return; }

      this.selectedDriver = undefined;

      this.loadingDrivers = true;
      this.$store.dispatch("reportModule/loadFilteredDrivers", {
        transporterId: this.currentTransporterId,
      }).then((data) => {
        if (data.length == 0) return;

        this.selectedDriver = data[0];

        this.matchedDriversEntries.push({
          text: this.selectedDriver.name,
          value: this.selectedDriver,
        });
      }).finally(() => (this.loadingDrivers = false));
    }, 300),
  },

  methods: {
    onActionSelect(event) {
      this.selectedDriver = undefined;

      if (event == ACCEPT_AUTO_MATCH) {
        this.currentTransporterId = this.request.autoMatchedDriver
          ? this.request.autoMatchedDriver.transporterId
          : "";
      }
    },

    // TODO: warning
    loadFilteredDrivers: debounce(function () {
      this.selectedDriver = undefined;
      this.currentTransporterId = "";
      this.loadingDriversSearch = true;

      this.$store.dispatch("reportModule/loadFilteredDrivers", {
        name: this.searchDriver,
      }).then((data) => {
        this.matchedDriversEntries = data.map((driver) => ({
          text: driver.name,
          value: driver,
        }));
        this.loadingDriversSearch = false;
      });
    }, 300),

    setSelectedDriver(driver) {
      this.selectedDriver = driver;
      this.matchedDriversEntries.push({
        text: driver.name,
        value: driver,
      });
      this.currentTransporterId = driver.transporterId;
    },

    onNameFieldFocused() {
      this.clearMatchedDriversEntries();
    },

    clearMatchedDriversEntries() {
      this.matchedDriversEntries = [];
    },
  },

  mounted() {
    this.clearMatchedDriversEntries();

    if (!this.selectedAction || isEmpty(this.selectedAction)) {
      this.selectedAction = this.request.autoMatchedDriver
        ? ACCEPT_AUTO_MATCH
        : MATCH_TO_EXISTING_DRIVER;
    }

    if (this.selectedDriver) {
      this.matchedDriversEntries.push({
        text: this.selectedDriver.name,
        value: this.selectedDriver,
      });
    }

    if (this.selectedAction == ACCEPT_AUTO_MATCH) {
      this.currentTransporterId = this.request.autoMatchedDriver
        ? this.request.autoMatchedDriver.transporterId
        : "";
    }

    if (this.newDriverName === undefined) {
      this.newDriverName = this.request.rawName;
    }

    if (this.newTransporterId === undefined) {
      this.newTransporterId = this.request.rawTransporterId;
    }
  },

  toModel(dto) {
    return {
      ...dto,

      isPartialAutoMatch() {
        return (this.autoMatchedDriver || {}).name
        && (this.autoMatchedDriver || {}).name != this.rawName;
      },

      getAutoGeneratedSolution() {
        if (this.autoMatchedDriver) {
          return {
            action: ACCEPT_AUTO_MATCH.value,
            driverId: this.autoMatchedDriver.id,
            specialClass: this.isPartialAutoMatch() ? "orange" : "",
          };
        }

        return undefined; // createNewDriverSolution(this.rawName, this.rawTransporterId);
      },

      getAutoIgnoreSolution() {
        return {
          action: IGNORE_THIS_DRIVER.value,
        };
      },
    };
  },
};
</script>

<style lang="scss">
.content-container {
  display: flex;
  justify-content: space-between;
  .select-and-icon {
    display: flex;
  }
  .v-text-field {
    max-width: 220px;
  }
}
@media screen and (max-width: 425px) {
  .content-container {
    display: block;
    .v-text-field {
      max-width: 90%;
    }
    .select-action-mobile {
      max-width: 75%;
    }
  }
}
</style>
