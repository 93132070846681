var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"criteria-table-display",attrs:{"headers":_vm.headers,"items":_vm.displayCriteria,"loading":_vm.currentPageLoading,"server-items-length":_vm.totalCount,"footer-props":_vm.footerProps},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.criteria_table.title" ))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openCreateCriteriaDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.criteria_table.new_btn" ))+" ")]),_c('CoachingMenu',{staticClass:"ml-2"}),_c('CreateCriteriaDialog',{ref:"createCriteriaDialog"}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDate(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openCreateCriteriaDialog(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteCriteriaDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }