export class Device {
    id;

    name;

    number;

    createdAt;

    updatedAt;

    constructor(id, name, number, createdAt, updatedAt) {
      this.id = id;
      this.name = name;
      this.number = number;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }

    static fromDto(dto) {
      return new Device(
        dto.id,
        dto.name,
        dto.number,
        dto.createdAt,
        dto.updatedAt,
      );
    }
}
