var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"infractions-sub-table-display",attrs:{"headers":_vm.headers,"items":_vm.displayInfractions,"item-class":_vm.getItemClass,"loading":_vm.currentPageLoading,"server-items-length":_vm.totalCount,"footer-props":_vm.footerProps,"multi-sort":"","custom-sort":_vm.sortStub},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"small-checkbox flex-grow-0",attrs:{"value":_vm.selectedInfractions.find(function (it) { return it.id == item.id; }) != undefined},on:{"input":function($event){return _vm.onInfractionSelectionChange($event, item)}}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionDateView(item))+" ")]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"drivers-list-string"},[_vm._l((_vm.getInfractionDriverView(item)),function(driver){return [_c('router-link',{key:("link-" + (driver.id)),staticClass:"driver-name",attrs:{"to":("/infractions/driver/" + (driver.id))}},[_vm._v(_vm._s(driver.name))]),_c('span',{key:("spacer-" + (driver.id)),staticClass:"driver-names-delimeter"},[_vm._v(", ")])]})],2)]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionVehicleView(item))+" ")]}},{key:"item.detectedValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.detectedValue)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'RESOLVED')?_c('div',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.driver_infractions_sub_table.status.resolved" ))+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"green"}},[_vm._v("mdi-check")])],1):(item.status == 'NEW')?_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.driver_infractions_sub_table.status.new" ))+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1):(item.status == 'COACHING_ASSIGNED')?_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.driver_infractions_sub_table.status.coaching_assigned" ))+" ")]):_c('div',[_vm._v(_vm._s(item.status))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }