import {
  Action, Module, Mutation, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
import lodash from "lodash";
import { store } from "@/main";

const moduleName = "issueAgentsModule";

@Module({
  name: moduleName, namespaced: true, store,
})
export default class IssueAgentsModule extends VuexModule {
  static get namespace() {
    return namespace(moduleName);
  }

  dataUnitAgents: any

  authorAgents: any

  @Mutation
  setDataUnitAgents(agents: any) {
    this.dataUnitAgents = agents;
  }

  @Mutation
  setAuthorAgents(agents: any) {
    console.log(agents);
    this.authorAgents = agents;
  }

  @Action({ rawError: true })
  async loadAgents() {
    await this.loadDataUnitAgents();
    await this.loadAuthorAgents();
  }

  @Action({ rawError: true })
  async loadDataUnitAgents() {
    const context = await require.context("@/components/issues/issue-card/data-unit-agents", false, /.Agent.vue$/);

    const modules = context.keys().map((x) => context(x).default);

    this.setDataUnitAgents(lodash.keyBy(modules, "agentName"));
  }

  @Action({ rawError: true })
  async loadAuthorAgents() {
    const context = await require.context("@/components/issues/issue-card/data-unit-agents/author-subagents", false, /.Agent.vue$/);

    const modules = context.keys().map((x) => context(x).default);

    this.setAuthorAgents(lodash.keyBy(modules, "agentName"));
  }
}
