import { IssueDataUnit } from "@/model/issue/data-unit.model";
import { IssueDataUnitDto, IssueUnitType, LightweightReminderDto } from "@/types/types";
import { ISSUE_DATA_REMINDER_UNIT_TYPE } from "@/utils/ts-utils";
import { LightweightReminder } from "@/model/reminder/reminder.lightweight-model";

export class IssueDataUnitReminder extends IssueDataUnit {
    reminder: LightweightReminder;

    constructor(
      id: string,
      type: IssueUnitType,
      reminder: LightweightReminder,
    ) {
      super(id, type);
      this.reminder = reminder;
    }

    static fromDto(dto: IssueDataUnitDto): IssueDataUnit {
      return new IssueDataUnitReminder(
        dto.id,
        ISSUE_DATA_REMINDER_UNIT_TYPE,
        LightweightReminder.fromDto(dto.reminder as LightweightReminderDto),
      );
    }
}
