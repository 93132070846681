<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-data-table
          class="criteria-table-display"
          :headers="headers"
          :items="displayCriteria"
          :loading="currentPageLoading"
          :server-items-length="totalCount"
          @update:options="paginate($event)"
          :footer-props="footerProps"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{
                  $localizationService.localize(
                    "coaching_page.criteria_table.title"
                  )
                }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openCreateCriteriaDialog()">
                {{
                  $localizationService.localize(
                    "coaching_page.criteria_table.new_btn"
                  )
                }}
              </v-btn>
              <CoachingMenu class="ml-2" />
              <CreateCriteriaDialog ref="createCriteriaDialog" />
              <ConfirmationDialog ref="deleteDialog" />
            </v-toolbar>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ momentDate(item.updatedAt) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-2" @click="openCreateCriteriaDialog(item)"
              >mdi-pencil</v-icon
            >
            <v-icon medium color="red" @click="openDeleteCriteriaDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import CreateCriteriaDialog from "./CreateCriteriaDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import CoachingMenu from "./CoachingMenu.vue";
import { paginateTable, dateTimeMomentFormat } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Criteria",

  components: {
    CreateCriteriaDialog,
    ConfirmationDialog,
    CoachingMenu,
  },

  data: () => ({
    localStorageTable: "criteria.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },
  }),

  computed: {
    ...mapState("criteriaModule", [
      "currentPageLoading",
      "currentPage",
      "displayCriteria",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),

    headers: () => [
      {
        text: locService.localize("coaching_page.criteria_table.header.name"),
        value: "name",
        sortable: false,
      },
      {
        text: locService.localize(
          "coaching_page.criteria_table.header.created_at",
        ),
        value: "createdAt",
        sortable: false,
      },
      {
        text: locService.localize(
          "coaching_page.criteria_table.header.actions",
        ),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTable,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.$store.dispatch("criteriaModule/loadPage", currentPage);
      this.tableInited = true;
    },
    reloadPage() {
      this.$store.dispatch("criteriaModule/reloadPage");
    },
    momentDate(date) {
      return date ? moment(date).format(dateTimeMomentFormat) : "";
    },
    openCreateCriteriaDialog(criteria) {
      this.$refs.createCriteriaDialog.openDialog(criteria);
    },
    openDeleteCriteriaDialog(criteria) {
      this.$refs.deleteDialog.openDialog({
        header: locService.localize(
          "coaching_page.criteria_table.dialog.delete.title",
        ),
        text: locService.localize(
          "coaching_page.criteria_table.dialog.delete.text",
          [criteria.name],
        ),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => {
          try {
            const result = await this.$store.dispatch(
              "criteriaModule/deleteCriteria",
              criteria,
            );
            Vue.toasted.success(result, {
              duration: 5000,
            });
          } catch (e) {
            Vue.toasted.error(e, {
              duration: 5000,
            });
          }
        },
      });
    },
  },
};
</script>
