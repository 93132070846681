<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
                editedPhoneAgent.id
                ? "phone_agent_page.dialog.add_and_edit.edit_title"
                : "phone_agent_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  :rules="requiredRule"
                  v-model="editedPhoneAgent.name"
                  :label="
                      $localizationService.localize(
                          'phone_agent_page.dialog.add_and_edit.param.name'
                      )
                  "
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="phone-agent-combobox">
            <v-combobox
                v-model="editedPhoneAgent.allowedUsers"
                :items="matchedUsers"
                :label="
                  $localizationService.localize(
                    'phone_agent_page.dialog.add_and_edit.param.allowed_users'
                  )
                "
                :disabled="loading"
                item-text="name"
                item-value="id"
                return-object
                @focus="loadUsers"
                :loading="loadingUsers"
                chips
                multiple
                deletable-chips
                :delimiters="[',']"
                @change="checkInputUser($event)"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.email}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip>
                  {{item.name}}
                </v-chip>
              </template>
            </v-combobox>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="addOrSavePhoneAgent"
            :disabled="loading || !editedPhoneAgent.name"
        >
          {{ $localizationService.localize(editedPhoneAgent.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { PhoneAgent } from "@/model/phone-agent.model";
import { USER_TYPE_DRIVER } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditPhoneAgentsDialog",

  data: () => ({
    open: false,
    loading: false,

    editedPhoneAgentOriginal: {},
    editedPhoneAgent: {},

    matchedUsers: [],
    loadingUsers: false,

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

  }),

  methods: {

    openDialog(phoneAgent) {
      this.editedPhoneAgentOriginal = phoneAgent || {};
      this.editedPhoneAgent = Object.assign(new PhoneAgent(), this.editedPhoneAgentOriginal);
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    checkInputUser(e) {
      if (typeof e[e.length - 1] !== "object") {
        e.pop();
      }
    },

    async addOrSavePhoneAgent() {
      this.loading = true;
      await this.$store.dispatch(
        "phoneAgentsModule/addOrSavePhoneAgent",
        this.editedPhoneAgent,
      );
      this.$emit("onAddOrSave");
      this.loading = false;
      this.open = false;
    },

    async loadUsers() {
      this.loadingUsers = true;
      this.matchedUsers = this.matchedUsers.length > 0 ? this.matchedUsers
        : await this.$store.dispatch("userModule/loadPage")
          .then((res) => res.filter((it) => it.type !== USER_TYPE_DRIVER.value));
      this.loadingUsers = false;
    },
  },
};
</script>

<style lang="scss">
.phone-agent-combobox {
  margin: 0 !important;
}
</style>
