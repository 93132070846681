<template>
  <div class="criteria-filter-container">
    <div class="criteria-chips-flex criteria-filter-margin">
      <InfractionChip
        v-for="item of selectedCriteriaChips"
        :key="`${item.criteria.id} ${item.operator} ${item.infractionCount}`"
        class="criteria-filter-margin"
        :displayCriteria="displayCriteria"
        :infractionChip="item"
        :selectedCriteriaChips="selectedCriteriaChips"
        :isDefaultChip="isDefaultChip(item)"
        @onCancel="newFilterMode = false"
        @onSave="saveCriteriaChip($event, item)"
        @onDeleteChip="deleteCriteriaChip($event)"
      />
      <div>
        <InfractionChip
          v-if="newFilterMode"
          :displayCriteria="displayCriteria"
          :selectedCriteriaChips="selectedCriteriaChips"
          @onCancel="newFilterMode = false"
          @onSave="saveCriteriaChip($event)"
        />
        <v-btn
          v-if="!newFilterMode"
          x-small
          @click="newFilterMode = true"
          :disabled="!displayCriteria.length"
        >
          {{
            $localizationService.localize("drp.criteria_filter.add_filter_btn")
          }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import uniqBy from "lodash/uniqBy";
import Vue from "vue";

export default {
  name: "InfractionCriteriaFilter",

  components: {
    InfractionChip: () => import("./InfractionChip.vue"),
  },

  props: {
    driverEntries: Array,
    additionalDataPerDriver: Object,
  },

  data: () => ({
    newFilterMode: false,

    filteredDriverIds: [],
    selectedCriteriaChips: [],
  }),

  computed: {
    ...mapState("drpFilterPanelTableSettingsModule", [
      "defaultSettings",
    ]),

    driverIds() {
      return this.driverEntries.map((entry) => entry.driver.id);
    },

    displayCriteria() {
      const criteria = [];

      this.driverIds.forEach((driverId) => {
        criteria.push(
          ...this.additionalDataPerDriver[driverId].infractionsPerCriteria.map(
            (it) => ({
              id: it.criteria.id,
              name: it.criteria.name,
            }),
          ),
        );
      });

      return uniqBy(criteria, "id").sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  methods: {
    isDefaultChip(chip) {
      return this.defaultSettings.criteriaFilter.some((it) => (
        it.criteriaId == chip.criteria.id
        && it.operator == chip.operator
        && +it.infractionCount == +chip.infractionCount
      ));
    },

    applyDefaultFilters() {
      this.selectedCriteriaChips = this.defaultSettings.criteriaFilter.map((it) => ({
        criteria: this.displayCriteria.find((criteria) => criteria.id == it.criteriaId),
        operator: it.operator,
        infractionCount: +it.infractionCount,
      }));

      if (this.selectedCriteriaChips.find((it) => !it.criteria)) {
        this.selectedCriteriaChips = this.selectedCriteriaChips.filter((it) => it.criteria);

        Vue.toasted.error(
          "Some of your criteria filters do not exists on this day.",
          { duration: 3000 },
        );
      }

      this.updateRowsBySelectedCriteriaFilter();
    },

    parseCriteriaFromUrl(data) {
      data.forEach((it) => {
        const criteria = this.displayCriteria.find(
          (displayCriteria) => displayCriteria.id == it.criteriaId,
        );

        if (
          criteria
          && !this.selectedCriteriaChips.some(
            (chip) => chip.criteria.id == criteria.id
              && chip.operator == it.operator
              && chip.infractionCount == it.infractionCount,
          )
        ) {
          this.selectedCriteriaChips.push({
            criteria,
            operator: it.operator,
            infractionCount: +it.infractionCount,
          });
        }
      });

      this.updateRowsBySelectedCriteriaFilter();
    },

    updateRowsBySelectedCriteriaFilter() {
      this.filteredDriverIds = this.filterDriverIdsByCriteria();

      this.updateRows();
    },

    checkDriverCriteriaByChipValue(chip, driverInfractions) {
      const driverCriteria = driverInfractions.find(
        (it) => it.criteria.id == chip.criteria.id,
      );

      if (chip.operator == "=") {
        return driverCriteria.infractionIds.length == +chip.infractionCount;
      }
      if (chip.operator == "!=") {
        return driverCriteria.infractionIds.length != +chip.infractionCount;
      }
      if (chip.operator == ">") {
        return driverCriteria.infractionIds.length > +chip.infractionCount;
      }
      if (chip.operator == "<") {
        return driverCriteria.infractionIds.length < +chip.infractionCount;
      }

      return undefined;
    },

    filterDriverIdsByCriteria() {
      return this.driverIds.filter((driverId) => {
        const driverInfractions = this.additionalDataPerDriver[driverId].infractionsPerCriteria;

        const driverCriteriaIds = driverInfractions.map((it) => it.criteria.id);

        if (
          this.selectedCriteriaChips.every(
            (chip) => driverCriteriaIds.includes(chip.criteria.id)
              && this.checkDriverCriteriaByChipValue(chip, driverInfractions),
          )
        ) {
          return driverId;
        }

        return undefined;
      });
    },

    saveCriteriaChip(chip, originalChip = undefined) {
      if (originalChip) {
        const criteriaChipIndex = this.selectedCriteriaChips.findIndex(
          (it) => it.criteria.id == originalChip.criteria.id
            && it.operator == originalChip.operator
            && it.infractionCount == originalChip.infractionCount,
        );

        this.selectedCriteriaChips.splice(criteriaChipIndex, 1, chip);
      } else {
        this.selectedCriteriaChips.push(chip);

        this.newFilterMode = false;
      }

      this.updateRowsBySelectedCriteriaFilter();
    },

    deleteCriteriaChip(chip) {
      const criteriaChipIndex = this.selectedCriteriaChips.findIndex((it) => (
        it.criteria.id == chip.criteria.id
        && it.operator == chip.operator
        && it.infractionCount == chip.infractionCount
      ));
      this.selectedCriteriaChips.splice(criteriaChipIndex, 1);

      if (this.selectedCriteriaChips.length) {
        this.filteredDriverIds = this.filterDriverIdsByCriteria();
      } else {
        this.filteredDriverIds = [];
      }

      this.updateRows();
    },

    updateRows() {
      this.$emit("onSelectCriteria", {
        driverIds: this.filteredDriverIds,
        criteriaCount: this.selectedCriteriaChips.length,
        criteriaRouteParams: this.selectedCriteriaChips.map((chip) => ({
          criteriaId: chip.criteria.id,
          operator: chip.operator,
          infractionCount: +chip.infractionCount,
        })),
      });
    },
  },
};
</script>

<style lang="scss">
.criteria-filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .criteria-filter-margin {
    margin-right: 8px;
  }
  .criteria-chips-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>
