<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12" class="pb-0">
        <!--show-select-->
        <v-data-table
          class="infractions-sub-table-display"
          :headers="headers"
          :items="displayInfractions"
          :item-class="getItemClass"
          :loading="currentPageLoading"
          :server-items-length="totalCount"
          @update:options="paginate($event)"
          :footer-props="footerProps"
          multi-sort
          :custom-sort="sortStub"
        >
          <template v-slot:[`item.data-table-select`]="{ item }">
            <v-simple-checkbox
              class="small-checkbox flex-grow-0"
              :value="
                selectedInfractions.find((it) => it.id == item.id) != undefined
              "
              @input="onInfractionSelectionChange($event, item)"
              v-ripple
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.date`]="{ item }">
            {{ getInfractionDateView(item) }}
          </template>

          <template v-slot:[`item.driver`]="{ item }">
            <span class="drivers-list-string">
              <template v-for="driver in getInfractionDriverView(item)">
                <router-link
                  class="driver-name"
                  :key="`link-${driver.id}`"
                  :to="`/infractions/driver/${driver.id}`"
                  >{{ driver.name }}</router-link
                >
                <span
                  :key="`spacer-${driver.id}`"
                  class="driver-names-delimeter"
                  >,
                </span>
              </template>
            </span>
          </template>

          <template v-slot:[`item.vehicle`]="{ item }">
            {{ getInfractionVehicleView(item) }}
          </template>

          <template v-slot:[`item.detectedValue`]="{ item }">
            {{ item.detectedValue }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div v-if="item.status == 'RESOLVED'" class="green--text">
              {{
                $localizationService.localize(
                  "coaching_page.driver_infractions_sub_table.status.resolved"
                )
              }}
              <v-icon size="1.25em" color="green" class="ml-1 mb-1"
                >mdi-check</v-icon
              >
            </div>
            <div v-else-if="item.status == 'NEW'" class="red--text">
              {{
                $localizationService.localize(
                  "coaching_page.driver_infractions_sub_table.status.new"
                )
              }}
              <v-icon size="1.25em" color="red" class="ml-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
            </div>
            <div
              v-else-if="item.status == 'COACHING_ASSIGNED'"
              class="primary--text"
            >
              {{
                $localizationService.localize(
                  "coaching_page.driver_infractions_sub_table.status.coaching_assigned"
                )
              }}
            </div>
            <div v-else>{{ item.status }}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import { API } from "aws-amplify";
import debounce from "lodash/debounce";
import {
  dismissAction, bodyWithAuthHeader, dateMomentFormat, dateTimeMomentFormat,
} from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "InfractionsTable",

  props: {
    targetDriverId: String,
    targetCriteriaId: String,
    selectedInfractions: Array,
    totalCount: Number,
    searchString: String,
  },

  data: () => ({
    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    currentPageLoading: false,
    currentPage: {
      infractions: [],
      index: 0,
      size: 25,
      searchString: "",
      acsSort: [],
      descSort: [],
    },
    displayInfractions: [],
  }),

  computed: {
    ...mapState("coreModule", ["drawer"]),

    headers: () => [
      {
        text: locService.localize(
          "coaching_page.driver_infractions_sub_table.header.target_date",
        ),
        value: "date",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_sub_table.header.driver",
        ),
        value: "driver",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_sub_table.header.vehicle",
        ),
        value: "vehicle",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_sub_table.header.detected_value",
        ),
        value: "detectedValue",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_sub_table.header.status",
        ),
        value: "status",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.driver_infractions_sub_table.header.detected_at",
        ),
        value: "createdAt",
        sortable: true,
        align: "start",
      },
    ],
  },

  emits: ["infractionSelectionChanged"],

  watch: {
    searchString() {
      this.searchStringDebounceWatch();
    },

    totalCount() {
      this.loadPage(this.currentPage);
    },
  },

  methods: {
    onInfractionSelectionChange(val, item) {
      this.$emit("infractionSelectionChanged", {
        infraction: item,
        value: val,
      });
    },

    reloadPage() {
      this.loadPage(this.currentPage);
    },

    getItemClass(infraction) {
      if (infraction.status == "NEW") return "new-infraction-table-item";
      if (infraction.status == "COACHING_ASSIGNED") { return "coaching-assigned-infraction-table-item"; }
      if (infraction.status == "RESOLVED") { return "resolved-infraction-table-item"; }
      return undefined;
    },

    paginate(data) {
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
        searchString: this.searchString,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
      };

      this.loadPage(currentPage);
    },

    async loadPage(currentPage) {
      this.currentPageLoading = true;
      this.currentPage = currentPage;

      const body = {
        ...(await bodyWithAuthHeader()),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          searchString: currentPage.searchString,
          sort: currentPage.sort.join("+"),
          targetDriverId: this.targetDriverId,
          targetCriteriaId: this.targetCriteriaId,
        },
      };

      try {
        const infractionsDto = await API.get(
          "core",
          "/coaching/infraction",
          body,
        );
        this.currentPage.infractions = [...infractionsDto];
        this.displayInfractions = this.currentPage.infractions;
      } catch (e) {
        Vue.toasted.error(e, { ...dismissAction });
      }

      this.currentPageLoading = false;
    },

    sortStub(items) {
      return items;
    },

    momentDateAndTime(date) {
      return date ? moment(date).format(dateTimeMomentFormat) : "";
    },

    momentDate(date) {
      return date ? moment(date).format(dateMomentFormat) : "";
    },

    getInfractionDateView(item) {
      const circumstance = item.circumstances.find(
        (it) => it.type == "DateCircumstance",
      );
      return circumstance ? this.momentDate(circumstance.date) : "-";
    },

    getInfractionDriverView(item) {
      return item.circumstances
        .filter((it) => it.type == "DriverCircumstance")
        .map((it) => it.driver)
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    getInfractionVehicleView(item) {
      return (
        item.circumstances
          .filter((it) => it.type == "UsedVehicleCircumstance")
          .map((it) => it.vehicle.name || it.vehicle.vin)
          .sort((a, b) => a.localeCompare(b))
          .join(", ") || "-"
      );
    },
  },

  mounted() {
    this.searchStringDebounceWatch = debounce(() => {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.loadPage(newPage);
    }, 600);
  },
};
</script>

<style scoped lang="scss">
.small-checkbox {
  max-width: fit-content;

  i {
    font-size: 20px !important;
  }

  .v-label {
    font-size: 14px;
  }

  .v-input--selection-controls__ripple {
    height: 28px;
    width: 28px;
    left: -9px;
    top: -9px;
  }
}
.search-field {
  max-width: 250px;
}
.drivers-list-string {
  .driver-name {
    color: inherit !important;
    text-decoration: underline;
  }

  .driver-names-delimeter:last-child {
    display: none !important;
  }
}
</style>
