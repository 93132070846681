









import { Component, Prop, Vue } from "vue-property-decorator";
import { IssueDataUnitReminder } from "@/model/issue/units/issue-data-unit-reminder.model";

@Component({})
export default class ReminderAuthorDataUnitAgent extends Vue {
  static agentName = "ReminderAuthorDataUnitAgent"

  @Prop()
  dataUnit!: IssueDataUnitReminder
}
