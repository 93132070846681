<template>
  <CriteriaTable />
</template>

<script>
import CriteriaTable from "../../components/coaching/CriteriaTable.vue";

export default {
  name: "CoachingCriteria",

  routing: {
    localizationKey: "coaching_page.menu.manage_criteria",
    path: "/infractions/criteria",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: { CriteriaTable },
};
</script>
