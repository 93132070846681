


































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getModule } from "vuex-module-decorators";
import { THEME } from "@/app-theme";
import RocketChatSettingsModule from "@/store/rocket-chat-settings.store";
import {
  DeviceSelectionEntry,
  ChannelMessagingSettings,
  ChannelMessagingSettingsBehaviour,
  UserMessagingSettings, UserMessagingSettingsBehaviour,
} from "@/types/types";
import {
  CAD, DEFAULT, PERSONAL,
} from "@/utils/utils";

const rocketChatSettingsModule = RocketChatSettingsModule.namespace;
const rocketChatModule = namespace("rocketChatModule");

@Component
export default class RocketChatSettings extends Vue {
  loadingSettings = false;

  settingsIsVisible = false;

  @rocketChatSettingsModule.State
  selectedRecipientPhone!: DeviceSelectionEntry | undefined;

  @rocketChatModule.State
  directMessagingChannel!: any;

  @rocketChatSettingsModule.State
  loadingMessagingSettings!: boolean;

  @rocketChatSettingsModule.State
  userMessagingSettings!: UserMessagingSettings | undefined;

  @rocketChatSettingsModule.State
  channelMessagingSettings!: ChannelMessagingSettings;

  @rocketChatModule.Getter
  recipientId!: string;

  userBehaviour: boolean | undefined;

  @rocketChatSettingsModule.Mutation
  changeChannelMessagingSettings!: (settings: ChannelMessagingSettings | undefined) => void;

  @rocketChatSettingsModule.Action
  getChannelSettings!: (channelId: string) => Promise<void>;

  @rocketChatSettingsModule.Action
  changeDevice!: (payload: any) => Promise<void>;

  @rocketChatSettingsModule.Action
  getUserSettings!: () => Promise<UserMessagingSettings | undefined>;

  @rocketChatSettingsModule.Action
  updateUserSettings!: (payload: any) => Promise<void>;

  get settingsStyle() {
    return {
      borderTop: `2px solid ${THEME.themeInverseColor} !important`,
      borderBottom: `2px solid ${THEME.themeInverseColor} !important`,
      borderLeft: "2px solid var(--v-greyColor-base) !important",
      borderRight: "0px !important",
    };
  }

  get btnSettingsStyle() {
    return {
      borderTop: `2px solid ${THEME.themeInverseColor} !important`,
      borderBottom: `2px solid ${THEME.themeInverseColor} !important`,
      borderLeft: `2px solid ${THEME.themeInverseColor} !important`,
      height: "64px",
      minWidth: "36px",
      maxWidth: "36px",
    };
  }

  get selectedDevice() {
    if (this.channelMessagingSettings) {
      switch (this.channelMessagingSettings.behaviour) {
        case ChannelMessagingSettingsBehaviour.CAD:
          return this.channelMessagingSettings.driverPhones.find((it) => it.name == CAD.text);
        case ChannelMessagingSettingsBehaviour.DEFAULT:
          return this.channelMessagingSettings.driverPhones.find((it) => it.name == DEFAULT.text);
        case ChannelMessagingSettingsBehaviour.PERSONAL:
          return this.channelMessagingSettings.driverPhones.find((it) => it.name == PERSONAL.text);
        default: return this.selectedRecipientPhone;
      }
    } else return null;
  }

  openSettings(): void {
    this.settingsIsVisible = true;
  }

  closeSettings(): void {
    this.settingsIsVisible = false;
  }

  async updateAutoSendSms(value: boolean) {
    const settings = this.userMessagingSettings;
    if (settings) {
      settings.automaticallySendSms = value;
    }
    await this.updateUserSettings(settings);
  }

  async updateBehaviour(value: boolean) {
    const settings = this.userMessagingSettings;
    if (settings) {
      settings.defaultMessagingBehaviour = value ? UserMessagingSettingsBehaviour.CAD
        : UserMessagingSettingsBehaviour.PERSONAL;
    }
    this.$nextTick(() => this.userBehaviour = value);
    await this.updateUserSettings(settings);
  }

  async changePhone(phone: DeviceSelectionEntry): Promise<void> {
    await this.changeDevice({
      channelId: this.directMessagingChannel.id,
      deviceId: phone.id || null,
      behaviour: phone.id ? ChannelMessagingSettingsBehaviour.CUSTOM : phone.behaviour,
      device: phone,
    });
  }

  @Watch("directMessagingChannel")
  async loadDriver() {
    if (this.recipientId) {
      this.changeChannelMessagingSettings(undefined);
      await this.getChannelSettings(this.directMessagingChannel.id);
    }
  }

  async mounted() {
    getModule(RocketChatSettingsModule, this.$store);
    this.loadingSettings = true;
    await this.getUserSettings();
    this.userBehaviour = this.userMessagingSettings && this.userMessagingSettings.defaultMessagingBehaviour != PERSONAL.value;
    this.loadingSettings = false;
  }
}
