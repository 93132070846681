




















































































































































































import {
  Component, Emit, Prop, Ref, Vue,
} from "vue-property-decorator";
import { Vehicle } from "@/model/vehicle.model";
import { VehicleShop } from "@/model/vehicle-shop.model";
import { FuelCard } from "@/model/fuel-card.model";
import {
  VEHICLE_FLEET_MANAGEMENT_COMPANY, VEHICLE_OUT_OF_SERVICE_STATUSES, VEHICLE_STATUSES, VEHICLE_TYPES,
} from "@/utils/utils";
import ReadEditTextarea from "@/components/common/read-edit-field/ReadEditTextarea.vue";

const locService = Vue.prototype.$localizationService;

@Component({
  components: {
    ReadEditTextarea,
    ReadEditAutocomplete: () => import("@/components/common/read-edit-field/ReadEditAutocomplete.vue"),
    ReadEditSelect: () => import("@/components/common/read-edit-field/ReadEditSelect.vue"),
    ReadEditTextField: () => import("@/components/common/read-edit-field/ReadEditTextField.vue"),
    ReadEditDatePicker: () => import("@/components/common/read-edit-field/ReadEditDatePicker.vue"),
    EditFuelCardDialog: () => import("@/components/fuel-cards/EditFuelCardDialog.vue"),
    EditVehicleShopDialog: () => import("@/components/vehicle-shops/EditVehicleShopDialog.vue"),
  },
})
export default class VehicleInfo extends Vue {
  locService = locService;

  VEHICLE_OUT_OF_SERVICE_STATUSES = VEHICLE_OUT_OF_SERVICE_STATUSES;

  VEHICLE_FLEET_MANAGEMENT_COMPANY = VEHICLE_FLEET_MANAGEMENT_COMPANY;

  VEHICLE_STATUSES = VEHICLE_STATUSES;

  VEHICLE_TYPES = VEHICLE_TYPES;

  @Prop()
  vehicle!: Vehicle;

  currentVehicle: Vehicle = this.vehicle;

  @Prop()
  updating!: boolean;

  @Ref("fuelCardsDialog")
  fuelCardsDialog!: any;

  @Ref("vehicleShopDialog")
  vehicleShopDialog!: any;

  @Emit("onUpdateVehicle")
  emitUpdatedVehicle() {
    return this.currentVehicle;
  }

  openEditFuelCardDialog() {
    this.fuelCardsDialog.openDialog();
  }

  openEditVehicleShopDialog() {
    this.vehicleShopDialog.openDialog();
  }

  onSaveName(name: string) {
    this.currentVehicle.name = name;
    this.emitUpdatedVehicle();
  }

  onSaveVin(vin: string) {
    this.currentVehicle.vin = vin;
    this.emitUpdatedVehicle();
  }

  onSaveType(type: { text: string; value: string }) {
    this.currentVehicle.type = type.value;
    this.emitUpdatedVehicle();
  }

  onSaveStatus(status: { text: string; value: string }) {
    this.currentVehicle.status = status.value;
    if (!VEHICLE_OUT_OF_SERVICE_STATUSES.some((it) => it.value === this.currentVehicle.status)) {
      this.currentVehicle.vehicleShop = null;
    }
    this.emitUpdatedVehicle();
  }

  onSaveNotes(notes: string) {
    this.currentVehicle.notes = notes;
    this.emitUpdatedVehicle();
  }

  onSaveMake(make: string) {
    this.currentVehicle.make = make;
    this.emitUpdatedVehicle();
  }

  onSaveModel(model: string) {
    this.currentVehicle.model = model;
    this.emitUpdatedVehicle();
  }

  onSaveLicensePlate(licensePlate: string) {
    this.currentVehicle.licensePlate = licensePlate;
    this.emitUpdatedVehicle();
  }

  onSaveRentalExpiry(rentalExpiry: string) {
    this.currentVehicle.rentalExpiry = rentalExpiry;
    this.emitUpdatedVehicle();
  }

  onSaveRentalProvider(rentalProvider: string) {
    this.currentVehicle.rentalProvider = rentalProvider;
    this.emitUpdatedVehicle();
  }

  onSaveFleetManagementCompany(fleetManagementCompany: { text: string; value: string } | null) {
    this.currentVehicle.fleetManagementCompany = fleetManagementCompany?.value;
    this.emitUpdatedVehicle();
  }

  onSaveFuelCard(fuelCard: FuelCard | null) {
    this.currentVehicle.fuelCard = fuelCard;
    this.emitUpdatedVehicle();
  }

  onSaveVehicleShop(vehicleShop: VehicleShop | null) {
    this.currentVehicle.vehicleShop = vehicleShop;
    this.emitUpdatedVehicle();
  }
}
