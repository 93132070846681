// eslint-disable-next-line no-unused-vars
import { User } from "@/model/user.model";

export type PhoneAgentDto = {
    id: string;

    name: string;

    lastSeen: Date;

    createdAt: Date;

    updatedAt: Date;

    allowedUsers: User[];
}

export type PhoneAgentCreateDto = {
    id: string;

    name: string;

    lastSeen: Date;

    createdAt: Date;

    updatedAt: Date;

    allowedUsers: string[];
}

export class PhoneAgent {
    id;

    name;

    lastSeen;

    createdAt;

    updatedAt;

    allowedUsers;

    constructor(
      id: string,
      name: string,
      lastSeen: Date,
      createdAt: Date,
      updatedAt: Date,
      allowedUsers: User[],
    ) {
      this.id = id;
      this.name = name;
      this.lastSeen = lastSeen;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.allowedUsers = allowedUsers;
    }

    static fromDto(dto: PhoneAgentDto) {
      return new PhoneAgent(
        dto.id,
        dto.name,
        dto.lastSeen,
        dto.createdAt,
        dto.updatedAt,
        dto.allowedUsers,
      );
    }

    static toCreateDto(dto: any): PhoneAgentCreateDto {
      return {
        allowedUsers: (dto.allowedUsers || []).map((it: User) => it.id),
        createdAt: dto.createdAt,
        id: dto.id,
        lastSeen: dto.lastSeen,
        name: dto.name,
        updatedAt: dto.updatedAt,
      };
    }
}
