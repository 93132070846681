import { Action, Module, VuexModule } from "vuex-module-decorators";
import { namespace } from "vuex-class";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "@/utils/utils";

const moduleName = "phoneCallModule";

@Module({ name: moduleName, namespaced: true })
export default class PhoneCallModule extends VuexModule {
  @Action({ rawError: true })
  public async call(payload: any): Promise<void> {
    const body = {
      ...await bodyWithAuthHeader(),
      body: { channelId: payload.channelId },
    };

    try {
      await API.post("core", "/phone-call", body);
    } catch (e) {
      handleHttpError(e);
    }
  }

  static get namespace() {
    return namespace(moduleName);
  }
}
