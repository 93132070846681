import { Auth, API } from "aws-amplify";
import moment from "moment";
import Vue from "vue";
import { User } from "../model/user.model";
import router from "../router";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { UserInfo } from "../model/user-info.model";
import { PhoneAgent } from "../model/phone-agent.model";

const locService = Vue.prototype.$localizationService;

export default {

  name: "userModule",
  namespaced: true,
  state: {
    authInProgress: false,
    user: undefined,
    userCooked: false,
    accessToken: undefined,
    selectedPhoneAgent: null,
    pageLoading: false,
    displayUsers: [],
    userTableInited: false,
  },
  mutations: {

    startAuth(state) {
      state.authInProgress = true;
    },

    endAuth(state) {
      state.authInProgress = false;
    },

    setAccessToken(state, newToken) {
      state.accessToken = newToken.getJwtToken();

      setTimeout(async () => {
        const rUser = await Auth.currentAuthenticatedUser();
        const rSession = await Auth.currentSession();

        rUser.refreshSession(rSession.refreshToken, (err, session) => {
          this.commit("userModule/setAccessToken", session.getIdToken());
        });
      }, (newToken.payload.exp - moment().unix() - 60) * 1000);
    },

    updateUser(state, cognitoUser) {
      const user = User.fromDto(cognitoUser);

      if (!user.isCooked()) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        setTimeout(async () => {
          await Auth.currentAuthenticatedUser({ bypassCache: true });

          self.dispatch("userModule/checkLogin");
        }, 15000);
      } else {
        this.dispatch("companyModule/loadCompany");
      }

      state.user = user;
      state.userCooked = user.isCooked();
    },

    updateCompanyName(state, newName) {
      state.user.companyName = newName;
    },

    clearUser(state) {
      state.user = undefined;
    },

    updateSelectedPhoneAgent(state, phoneAgent) {
      state.selectedPhoneAgent = phoneAgent;
    },

    startLoadingPage(state) {
      state.pageLoading = true;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateUsers(state, user) {
      state.displayUsers = [...user];
    },

    initUserTable(state) {
      state.userTableInited = true;
    },
  },
  actions: {
    async checkLogin({ commit }) {
      commit("startAuth");

      const user = await Auth.currentUserInfo();

      if (user && user.id) {
        commit("updateUser", user);
        commit("setAccessToken", (await Auth.currentSession()).getIdToken());
      }

      commit("endAuth");
    },

    async checkLoginAndRedirect({ dispatch, state }) {
      dispatch("checkLogin");

      if (state.user) {
        if (router.currentRoute.name == "LoginPage") {
          router.push("/");
        }
      } else if (router.currentRoute.name != "LoginPage") {
        router.push("/login");
      }
    },

    signIn({ commit }) {
      commit("startAuth");
      Auth.federatedSignIn({ provider: "LoginWithAmazon" });
    },

    async signOut({ commit }) {
      commit("startAuth");

      await Auth.signOut();
      commit("clearUser");

      router.push("/login");
      commit("endAuth");
    },

    async loadPage({ commit }) {
      commit("startLoadingPage");
      try {
        const usersDto = await API.get("core", "/users", {
          ...await bodyWithAuthHeader(),
        });
        commit("updateUsers", usersDto.map((user) => UserInfo.fromDto(user)));
        commit("initUserTable");
        commit("endLoadingPage");
        return usersDto;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async updateUser({ dispatch }, user) {
      const body = { ...await bodyWithAuthHeader(), body: UserInfo.toDto(user) };

      try {
        await API.put("core", `/users/${user.id}`, body);
        Vue.toasted.success(locService.localize("store.user.update_msg", [user.name]), {
          duration: 5000,
        });
        await dispatch("loadPage");
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async getSelectedUserPhoneAgent({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const selectedPhoneAgent = await API.get("core", "/users/phone-agent", body);
        commit("updateSelectedPhoneAgent", selectedPhoneAgent ? PhoneAgent.fromDto(selectedPhoneAgent) : null);
      } catch (e) {
        handleHttpError(e);
      }
    },

    async updatePhoneAgent({ commit }, phoneAgent) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: { phoneAgentId: phoneAgent.id },
      };

      try {
        await API.post("core", "/users/phone-agent", body);
        commit("updateSelectedPhoneAgent", Object.assign(new PhoneAgent(), phoneAgent));
      } catch (e) {
        handleHttpError(e);
      }
    },
  },
  getters: {
    companyId: (state) => state.user?.companyId,
    accessToken: (state) => state.accessToken,
  },
};
