import Vue from "vue";
import { API } from "aws-amplify";
import {
  handleHttpError,
  bodyWithAuthHeader,
  REQUEST_CANCEL_MESSAGE,
} from "../utils/utils";
import { DailyRoutePlan } from "../model/daily-route-planner/plan.model";
import { DailyRoutePlanRow, DailyRoutePlanRowDto } from "../model/daily-route-planner/row.model";
import { DriverEntry, DriverEntryDto } from "../model/daily-route-planner/driver-entry.model";
import { Device } from "../model/device.model";
import { Vehicle } from "../model/vehicle.model";
import { DailyRouteRescue, DailyRouteRescueDto } from "../model/daily-route-planner/rescue.model";

export default {

  name: "dailyRoutePlannerModule",
  namespaced: true,
  state: {
    currentPageLoadRequest: undefined,

    pageLoading: false,
    synchronization: false,

    routePlan: {
      rows: [],
      driverEntries: [],
    },
    targetDate: undefined,
    additionalDataPerDriver: {},
  },
  mutations: {

    loadingPlan(state, targetDate) {
      state.pageLoading = true;
      state.targetDate = targetDate;
    },

    startLoadingPage(state) {
      state.pageLoading = true;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateRoutePlan(state, routePlan) {
      state.routePlan = routePlan;
    },

    updateAdditionalDataPerDriver(state, additionalDataPerDriverDto) {
      state.additionalDataPerDriver = additionalDataPerDriverDto;
    },

    startSynchronization(state) {
      state.synchronization = true;
    },

    endSynchronization(state) {
      state.synchronization = false;
    },

    addDriverEntryToEntries(state, driverEntry) {
      state.routePlan.driverEntries.push(driverEntry);
    },

    deleteRowFromRows(state, rowId) {
      state.routePlan.rows = state.routePlan.rows.filter((row) => row.id != rowId);
    },

    addRowToRows(state, routePlanRow) {
      state.routePlan.rows.push(routePlanRow);
    },

    updateRescue(state, { rescue, func }) {
      const findRescue = (arr) => {
        const targetItem = arr.find((it) => it.id === rescue.target.id);
        if (targetItem) {
          func(targetItem);
          return;
        }

        arr.forEach((it) => {
          findRescue(it.rescuers);
        });
      };

      for (let i = 0; i < state.routePlan.rows.length; i++) {
        const row = state.routePlan.rows[i];
        if (rescue.target.id === row.id) {
          func(row);
          return;
        }
        findRescue(row.rescuers);
      }
    },
  },
  actions: {

    reloadPage({ state, dispatch }) {
      dispatch("loadPage", state.targetDate);
    },

    async loadPage({ state, commit, dispatch }, targetDate) {
      commit("loadingPlan", targetDate);

      const body = {
        ...await bodyWithAuthHeader(),
      };

      let routePlanDto;
      try {
        if (state.currentPageLoadRequest) {
          API.cancel(state.currentPageLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentPageLoadRequest = API.get("core", `/drp/plan/${targetDate}`, body);
        routePlanDto = await state.currentPageLoadRequest;
      } catch (e) {
        if (API.isCancel(e)) {
          console.info(e);
          return;
        }
        routePlanDto = await dispatch("createRoutePlan", targetDate);
      }

      if (routePlanDto.driverEntries.length) {
        const additionalDataPerDriver = await dispatch(
          "getDataPerDriverByDriverIds",
          routePlanDto.driverEntries.map((entry) => entry.driver.id).join(),
        );

        commit("updateAdditionalDataPerDriver", additionalDataPerDriver);
      }

      commit("updateRoutePlan", DailyRoutePlan.fromDto(routePlanDto));

      commit("endLoadingPage");
    },

    async getDataPerDriverByDriverIds(context, driverIds) {
      try {
        return await API.get("core", `/drp/drivers_data_batch?driverIds=${driverIds}`, { ...await bodyWithAuthHeader() });
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async getDataPerVehicle() {
      try {
        return await API.get("core", "/drp/vehicles_data_batch", { ...await bodyWithAuthHeader() });
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async createRoutePlan(context, targetDate) {
      try {
        return await API.post("core", `/drp/plan/${targetDate}`, { ...await bodyWithAuthHeader() });
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async saveRow({ commit }, row) {
      commit("startSynchronization");

      const body = { ...await bodyWithAuthHeader(), body: DailyRoutePlanRowDto.toDto(row) };

      try {
        const rowDto = await API.put("core", `/drp/row/${row.id}`, body);
        row.vehicle = rowDto.vehicle ? Vehicle.fromDto(rowDto.vehicle) : null;
        row.version = rowDto.version;
        row.devices = rowDto.devices.length ? rowDto.devices.map((device) => Device.fromDto(device)) : [];
        row.replacementsHistory = rowDto.replacementsHistory;
        // TODO: add full sync
        if (row.driverEntry) {
          row.driverEntry.version = rowDto.driverEntry.version;
        }
      } catch (e) {
        handleHttpError(e);
      }
      commit("endSynchronization");
    },

    async addRow({ state, commit }, { targetDate }) {
      commit("startLoadingPage");

      const body = { ...await bodyWithAuthHeader(), body: DailyRoutePlanRowDto.createDefault() };

      try {
        const routePlanRowDto = await API.post("core", `/drp/plan/${targetDate}/row`, body);

        commit("addRowToRows", DailyRoutePlanRow.fromDto(routePlanRowDto, state.routePlan.driverEntriesMap));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async deleteRow({ commit }, { rowId, version }) {
      commit("startLoadingPage");

      const body = { ...await bodyWithAuthHeader(), body: { version } };

      try {
        await API.del("core", `/drp/row/${rowId}`, body);

        commit("deleteRowFromRows", rowId);
      } catch (e) {
        handleHttpError(e);
      }

      commit("endLoadingPage");
    },

    async saveDriverEntry({ commit }, entry) {
      commit("startSynchronization");

      const body = { ...await bodyWithAuthHeader(), body: DriverEntryDto.toDto(entry) };

      try {
        const driverEntryDto = await API.put("core", `/drp/driver_entry/${entry.id}`, body);
        entry.version = driverEntryDto.version;
      } catch (e) {
        handleHttpError(e);
      }

      commit("endSynchronization");
    },

    async createDriverEntry({ state, commit, dispatch }, { driverId, targetDate }) {
      commit("startLoadingPage");

      const body = { ...await bodyWithAuthHeader(), body: DriverEntryDto.createDefault(driverId) };

      try {
        const driverEntryDto = await API.post("core", `/drp/plan/${targetDate}/driver_entry`, body);

        const driverEntry = DriverEntry.fromDto(driverEntryDto, state.routePlan);

        const additionalDataPerDriver = await dispatch(
          "getDataPerDriverByDriverIds",
          driverEntry.driver.id,
        );

        commit("updateAdditionalDataPerDriver", { ...state.additionalDataPerDriver, ...additionalDataPerDriver });

        commit("addDriverEntryToEntries", driverEntry);

        commit("endLoadingPage");

        return driverEntry;
      } catch (e) {
        handleHttpError(e);
      }

      commit("endLoadingPage");
      return undefined;
    },

    async deleteDriverEntry({ state, commit }, { entryId, version }) {
      commit("startLoadingPage");

      const body = { ...await bodyWithAuthHeader(), body: { version } };

      try {
        await API.del("core", `/drp/driver_entry/${entryId}`, body);

        const index = state.routePlan.driverEntries.findIndex((entry) => entry.id === entryId);
        state.routePlan.driverEntries.splice(index, 1);
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async createRescue({ state, commit }, target) {
      commit("startSynchronization");

      const body = { ...await bodyWithAuthHeader(), body: DailyRouteRescueDto.createDefault() };

      try {
        const rescueDto = await API.post("core", target.rescueCreationApiPath, body);
        const rescue = DailyRouteRescue.fromDto(rescueDto, target, state.routePlan.driverEntriesMap);

        commit("updateRescue", {
          rescue,
          func: (targetItem) => {
            targetItem.rescuers.push(rescue);
          },
        });
      } catch (e) {
        handleHttpError(e);
      }

      commit("endSynchronization");
    },

    async saveRescue({ commit }, rescue) {
      commit("startSynchronization");

      const body = { ...await bodyWithAuthHeader(), body: DailyRouteRescueDto.toDto(rescue) };
      try {
        const rescueDto = await API.put("core", `/drp/rescue/${rescue.id}`, body);
        rescue.vehicle = rescueDto.vehicle ? Vehicle.fromDto(rescueDto.vehicle) : null;
        rescue.version = rescueDto.version;
        rescue.devices = rescueDto.devices.length ? rescueDto.devices.map((device) => Device.fromDto(device)) : [];
        // TODO: add full sync
        if (rescue.driverEntry) {
          rescue.driverEntry.version = rescueDto.driverEntry.version;
        }
      } catch (e) {
        handleHttpError(e);
      }
      commit("endSynchronization");
    },

    async deleteRescue({ commit }, rescue) {
      commit("startLoadingPage");

      const body = { ...await bodyWithAuthHeader(), body: { version: rescue.version } };

      try {
        await API.del("core", `/drp/rescue/${rescue.id}`, body);

        commit("updateRescue", {
          rescue,
          func: (targetItem) => {
            const foundIndex = targetItem.rescuers.findIndex((it) => it.id === rescue.id);
            if (foundIndex !== -1) {
              targetItem.rescuers.splice(foundIndex, 1);
            }
          },
        });
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadFilteredDrivers(context, name) {
      if (!name) return [];

      const body = await bodyWithAuthHeader();

      try {
        return await API.get("core", `/drp/search_drivers?name=${name}`, body);
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },

    async loadFilteredDevices(context, name) {
      if (!name) return [];

      const body = {
        ...await bodyWithAuthHeader(),
        searchString: name,
      };

      try {
        const devices = await API.get("core", "/device", body);

        return devices.map((device) => Device.fromDto(device));
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },

    async loadFilteredVehicle(context, searchString) {
      if (!searchString) return [];

      const body = await bodyWithAuthHeader();

      try {
        const vehiclesDto = await API.get("core", `/vehicle/search?searchString=${searchString || ""}`, body);

        return vehiclesDto.map((vehicle) => Vehicle.fromDto(vehicle));
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },
  },
};
