<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              "vehicle_inspection_assign_dialog.title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-menu
                v-model="targetDateMenu"
                :close-on-content-click="false"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="targetDate"
                    :label="$localizationService.localize('vehicle_inspection_assign_dialog.target_date')"
                    :disabled="loading"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="targetDate"
                  no-title
                  @input="targetDateMenu = false"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="targetDateMenu = false">
                    {{ $localizationService.localize("btn.cancel") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                :label="$localizationService.localize('vehicle_inspection_assign_dialog.target_scheme')"
                v-model="targetSchemeId"
                :search-input.sync="schemeSearch"
                item-text="name"
                item-value="id"
                :items="schemeSearchMatches"
                :loading="schemeSearchLoading"
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <VehicleTargetSelection
                :label="$localizationService.localize('vehicle_inspection_assign_dialog.target_vehicles')"
                :target-vehicle-groups=targetVehicleGroups
                :target-vehicles=targetVehicles
                :is-required-groups=true
                :multiple=true
                @input="onTargetSelect($event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="assignInspection"
            :disabled="saveIsDisabled"
        >
          {{
            $localizationService.localize(
              "vehicle_inspection_assign_dialog.assign"
            )
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import lodash from "lodash";
import moment from "moment";
import VehicleTargetSelection from "@/components/vehicle-inspections/VehicleTargetSelection";

const locService = Vue.prototype.$localizationService;

// WARNING: Id traceability required for Vehicle and VehicleGroup!
export default {
  name: "VehicleInspectionAssignDialog",
  components: { VehicleTargetSelection },
  data: () => ({
    open: false,
    loading: false,

    schemeSearchLoading: false,
    schemeSearch: null,
    schemeSearchMatches: [],

    targetDateMenu: false,

    targetVehicles: [],
    targetVehicleGroups: [],
    targets: [],

    targetDate: undefined,
    targetSchemeId: undefined,

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],
  }),

  watch: {

    schemeSearch() {
      if (this.schemeSearch) {
        this.loadFilteredSchemes();
      }
    },
  },

  computed: {

    targetVehicleIds() {
      return this.targets.filter((it) => it.isVehicle).map((it) => it.id);
    },

    targetVehicleGroupIds() {
      return this.targets.filter((it) => it.isGroup).map((it) => it.id);
    },

    saveIsDisabled() {
      return this.loading
          || (!this.targetVehicleIds.length && !this.targetVehicleGroupIds.length)
          || !this.targetDate
          || !this.targetSchemeId;
    },
  },

  methods: {

    openDialog({ targetVehicles, targetVehicleGroups }) {
      this.targets = [];
      this.targetDate = undefined;
      this.targetSchemeId = undefined;
      this.open = true;

      this.targetVehicleGroups = targetVehicleGroups || [];
      this.targetVehicles = targetVehicles || [];
    },

    closeDialog() {
      this.open = false;
    },

    async assignInspection() {
      this.loading = true;
      const response = await this.$store.dispatch(
        "vehicleInspectionAssignmentsModule/assignInspection",
        {
          targetVehicleIds: this.targetVehicleIds,
          targetVehicleGroupIds: this.targetVehicleGroupIds,
          targetDate: moment(this.targetDate).toISOString(),
          targetSchemeId: this.targetSchemeId,
        },
      );
      this.loading = false;
      if (response) {
        this.$emit("assignmentCreated", response);
        this.open = false;
      }
    },

    loadFilteredSchemes: lodash.debounce(async function () {
      this.schemeSearchLoading = true;
      this.schemeSearchMatches = await this.$store.dispatch(
        "vehicleInspectionSchemesModule/loadFilteredVehicleInspectionSchemes",
        this.schemeSearch,
      );
      this.schemeSearchLoading = false;
    }, 300),

    onTargetSelect(targets) {
      this.targets = targets;
    },
  },
};
</script>

<style lang="scss" scoped>

.target-list-item-content {
  margin: 0px -16px;
  padding: 12px 16px;
  height: calc(40px + 12px + 12px) !important;

  &.coloring-warning {
    background-color: rgba(255, 0, 0, 0.1);
  }
}

.meta-mark {
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 0.8rem !important;
}

</style>
