export class MessageTemplate {
    id;

    name;

    description;

    text;

    createdAt;

    updatedAt;

    constructor(
      id,
      name,
      description,
      text,
      createdAt,
      updatedAt,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.text = text;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }

    static fromDto(dto) {
      return new MessageTemplate(
        dto.id,
        dto.name,
        dto.description,
        dto.text,
        dto.createdAt,
        dto.updatedAt,
      );
    }
}
