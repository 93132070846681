import lodash from "lodash";
import { getLocalStorageData, setLocalStorageData } from "../../utils/utils";

export default {

  name: "resolvedCoachingTableSettingsModule",
  namespaced: true,
  state: {
    tableSettingsStorageKey: "resolved.coaching.table.settings",

    resolvedPageIndex: 1,

    resolvedPageSize: 25,
    resolvedSort: {
      sortBy: [],
      sortDesc: [],
    },

    defaultSettings: {
      resolvedPageSize: 25,
      resolvedSort: {
        sortBy: [],
        sortDesc: [],
      },
    },
  },

  mutations: {
    changeSettings(state, data) {
      state.resolvedPageIndex = data.index;
      state.resolvedPageSize = data.size;
      state.resolvedSort = data.sort;
    },

    saveSettings(state) {
      const data = {
        resolvedPageSize: state.resolvedPageSize,
        resolvedSort: state.resolvedSort,
      };
      setLocalStorageData(state.tableSettingsStorageKey, JSON.stringify(data));
      state.defaultSettings = data;
    },

    applyDefaultSettings(state) {
      state.resolvedSort = { ...state.defaultSettings.resolvedSort };
      state.resolvedPageSize = state.defaultSettings.resolvedPageSize;
    },

    applyQuerySettings(state, query) {
      if (query.resolvedPageIndex) {
        state.resolvedPageIndex = +query.resolvedPageIndex;
      }
      if (query.resolvedPageSize) {
        state.resolvedPageSize = +query.resolvedPageSize;
      }
      if (query.resolvedSort) {
        state.resolvedSort = JSON.parse(query.resolvedSort);
      }
    },
  },

  actions: {
    loadDefaultSettingsFromStorage({ state }) {
      const data = getLocalStorageData(state.tableSettingsStorageKey);
      if (data) {
        state.defaultSettings = JSON.parse(data);
      }
    },
  },

  getters: {
    isDefaultResolvedSettings: (state, getters) => getters.isDefaultResolvedSort && getters.isDefaultResolvedSize,
    isDefaultResolvedSort: (state) => lodash.isEqual(state.resolvedSort, state.defaultSettings.resolvedSort),
    isDefaultResolvedSize: (state) => state.resolvedPageSize === state.defaultSettings.resolvedPageSize,
  },
};
