import { RtlLineTemplate } from "./rtl-line-template.model";

export class RawTextLinesTemplate {
    id;

    name;

    lines;

    constructor(id, name, lines) {
      this.id = id;
      this.name = name;
      this.lines = lines;
    }

    static fromDto(dto) {
      return new RawTextLinesTemplate(
        dto.id,
        dto.name,
        dto.lines.map((line) => RtlLineTemplate.fromDto(line)),
      );
    }
}
