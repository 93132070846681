









































































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";
import debounce from "lodash/debounce";
import TemplatesWritingAssistant from "@/components/templates-writing-assistant/TemplatesWritingAssistant.vue";

const rocketChatModule = namespace("rocketChatModule");

@Component({ components: { TemplatesWritingAssistant } })
export default class SendMessageTemplateDialog extends Vue {
  open = false;

  sending = false;

  loadingTemplates = false;

  handlingTemplate = false;

  text = "";

  searchTemplates = "";

  matchedTemplates = [];

  assistantHasError = false;

  loadTemplateButtonIsVisible = true;

  processedTemplate = "";

  @rocketChatModule.State
  selectedChannel!: any;

  @rocketChatModule.Getter
  recipientId!: string;

  @rocketChatModule.Action
  postMessage!: (payload: any) => Promise<any>;

  @rocketChatModule.Action
  postReaction!: (payload: any) => Promise<void>;

  @Watch("text")
  loadProcessedTemplate() {
    this.handleTemplate(this);
  }

  @Watch("searchTemplates")
  loadFilteredTemplates() {
    this.loadTemplates(this);
  }

  openDialog(): void {
    this.open = true;
  }

  closeDialog(): void {
    this.open = false;
    this.text = "";
    this.processedTemplate = "";
  }

  async sendMessage(): Promise<void> {
    this.sending = true;
    const message = await this.postMessage({ roomId: this.selectedChannel.rid, text: this.processedTemplate });
    // eslint-disable-next-line no-underscore-dangle
    const messageId = message.data.message._id;

    setTimeout(async () => this.postReaction(
      {
        messageId,
        emoji: "send_sms",
        shouldReact: true,
      },
    ), 100);
    this.sending = false;
    this.closeDialog();
  }

  changeLoadTemplateButtonIsVisible(value: boolean): void {
    this.loadTemplateButtonIsVisible = value;
  }

  transferTextFromAssistant(text: string) {
    this.text = text;
  }

  inputTemplate(template: string) {
    this.text = template;
    setTimeout(() => (this.$refs.templateEditor as any).forceUpdate());
  }

  loadTemplates: any = debounce((self: any) => {
    self.loadingTemplates = true;
    self.$store.dispatch("messageTemplatesModule/loadFilteredTemplates", {
      name: self.searchTemplates,
    }).then((data: any) => {
      self.matchedTemplates = data;
      self.loadingTemplates = false;
    });
  }, 300);

  handleTemplate: any = debounce((self: any) => {
    self.handlingTemplate = true;
    self.$store.dispatch("messageTemplatesModule/handleTemplate", {
      recipientId: self.recipientId,
      msg: self.text,
    }).then((data: any) => {
      self.processedTemplate = data;
      self.handlingTemplate = false;
    });
  }, 1000);
}

