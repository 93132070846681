

























































































import {
  Component, Emit, Prop, Vue,
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Issue } from "@/model/issue/issue.model";
import ReadEditTextField from "@/components/common/read-edit-field/ReadEditTextField.vue";
import ReadEditTextarea from "@/components/common/read-edit-field/ReadEditTextarea.vue";
import { IssueSeverity, IssueStatus } from "@/types/types";
import { IssueDataUnit } from "@/model/issue/data-unit.model";
import { User } from "@/model/user.model";
import IssueAgentsModule from "@/store/issue-agents.store";
import ReadEditSelect from "@/components/common/read-edit-field/ReadEditSelect.vue";
import {
  AUTHOR_AGENT_NAME, ISSUE_STATUSES, RELATED_VEHICLE_AGENT_NAME, ISSUE_SEVERITIES,
} from "@/utils/ts-utils";
import { IssueDataUnitUser } from "@/model/issue/units/issue-data-unit-user.model";
import IssuesModule from "@/store/issues.store";

const locService = Vue.prototype.$localizationService;

const issueAgentsModule = IssueAgentsModule.namespace;

const issuesModule = IssuesModule.namespace;

const userModule = namespace("userModule");

@Component({
  components: {
    ReadEditSelect,
    ReadEditTextarea,
    ReadEditTextField,
  },
})
export default class EditIssueDialog extends Vue {
  ISSUE_STATUSES = ISSUE_STATUSES;

  ISSUE_SEVERITIES = ISSUE_SEVERITIES;

  locService = locService;

  @Prop()
  issue!: Issue;

  currentIssue = this.issue.clone();

  @Prop()
  isDialog!: boolean;

  @issueAgentsModule.State
  dataUnitAgents!: any

  @issuesModule.Action
  addOrSaveIssueUnit!: (_: {issueId: string | undefined; dataUnit: IssueDataUnit}) => Promise<void>

  @issuesModule.Action
  deleteIssueUnit!: (issueId: string) => Promise<void>

  @userModule.State
  user!: User;

  @issuesModule.Action
  addOrSaveIssue!: (issue: Issue) => Promise<Issue>;

  get unitsByAgents() {
    if (!this.currentIssue.dataUnits) return [];

    const dataUnitAgentNames = [
      AUTHOR_AGENT_NAME,
      RELATED_VEHICLE_AGENT_NAME,
    ];

    return dataUnitAgentNames.map((it) => ({
      agentName: it,
      units: !this.currentIssue.id && it === AUTHOR_AGENT_NAME
        ? [
          IssueDataUnitUser.createUnitWithUser(this.user),
        ]
        : this.currentIssue.dataUnits.filter((unit) => unit.type.mainAgentName === it),
    }));
  }

  @Emit("onCloseDialog")
  emitOnCloseDialog() {
    return true;
  }

  onSaveName(name: string) {
    this.currentIssue.title = name;
    this.updateIssue();
  }

  onSaveEssence(essence: string) {
    this.currentIssue.essence = essence;
    this.updateIssue();
  }

  onSaveDescription(description: string) {
    this.currentIssue.description = description;
    this.updateIssue();
  }

  onSaveStatus(status: IssueStatus) {
    this.currentIssue.status = status;
    this.updateIssue();
  }

  onSaveSeverity(severity: IssueSeverity) {
    this.currentIssue.severity = severity;
    this.updateIssue();
  }

  async onAddDataUnit(unit: IssueDataUnit) {
    this.currentIssue.dataUnits.push(unit);
    await this.addOrSaveIssueUnit({ issueId: this.currentIssue.id, dataUnit: unit });
  }

  async onRemoveDataUnit(unit: IssueDataUnit) {
    this.currentIssue.dataUnits = this.currentIssue.dataUnits.filter((it) => it.id !== unit.id);
    await this.deleteIssueUnit(unit.id);
  }

  async updateIssue() {
    this.currentIssue = await this.addOrSaveIssue(this.currentIssue);
  }
}
