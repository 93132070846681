var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row align-center entry-label"},[_c('div',{class:{ 'driver-routes-z-index': _vm.visibleOptionalParams }},[_c('v-tooltip',{attrs:{"open-delay":"500","bottom":"","disabled":!_vm.isDuplicate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"w-110 no-wrap",class:[_vm.fullNameClass, _vm.customClass]},'span',attrs,false),on),[_vm._v(_vm._s(_vm.targetDriverEntry.driver.name))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$localizationService.localize("drp.value_already_in_use")))])]),(_vm.visibleOptionalParams)?_c('div',[(_vm.targetDriverEntry.routes.length == 1)?_c('span',{staticClass:"sub-text mr-2"},[_vm._v(" "+_vm._s(_vm.targetDriverEntry.routes[0])+" ")]):(_vm.targetDriverEntry.routes.length > 1)?_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"sub-text mr-2"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$localizationService.localize("drp.entry_label.routes_count", [ _vm.targetDriverEntry.routes.length ]))+" ")])]}}],null,false,3851702290)},[_c('span',[_vm._v(" "+_vm._s(_vm.targetDriverEntry.routes.join(", "))+" ")])]):_vm._e(),_c('span',{staticClass:"sub-text",class:_vm.targetDriverEntry.status.color},[_vm._v(" "+_vm._s(_vm.targetDriverEntry.status.text)+" ")])],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-center align-center margin-left"},[(_vm.targetDriverEntry.reserve)?_c('v-tooltip',{attrs:{"open-delay":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"margin-left green no-elastic-chip white--text"},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "drp.entry_label.is_reserve.not_full" ))+" ")])]}}],null,false,1416216790)},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("drp.entry_label.is_reserve.full"))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{staticClass:"decor-none",attrs:{"to":("/infractions/driver/" + (_vm.targetDriverEntry.driver.id))},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.infractionsCount)?_c('v-chip',_vm._g(_vm._b({staticClass:"infractions-chip margin-left red no-elastic-chip white--text"},'v-chip',attrs,false),on),[_c('v-avatar',{staticClass:"red darken-4 print-ma-0",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.infractionsCount)+" ")]),_vm._v(" "+_vm._s(_vm.$localizationService.localize("drp.entry_label.infractions"))+" ")],1):_vm._e()],1)]}}])},[_c('div',{staticClass:"inf-chip-card"},_vm._l((_vm.infractionsPerCriteria),function(item){return _c('v-chip',{key:item.criteria.id,staticClass:"red mr-2 mb-1 mt-1 white--text"},[_c('v-avatar',{staticClass:"red darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s(item.infractionIds.length)+" ")]),_vm._v(" "+_vm._s(item.criteria.name)+" ")],1)}),1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }