import {
  Action, Module, Mutation, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
import { API } from "aws-amplify";
import {
  bodyWithAuthHeader, CAD, DEFAULT, handleHttpError, PERSONAL,
} from "@/utils/utils";
import { store } from "@/main";
// eslint-disable-next-line no-unused-vars
import {
  DeviceSelectionEntry, ChannelMessagingSettings, UserMessagingSettings, ChannelMessagingSettingsBehaviour,
} from "@/types/types";

const moduleName = "rocketChatSettingsModule";

@Module({
  name: moduleName, namespaced: true, store,
})
export default class RocketChatSettingsModule extends VuexModule {
  static get namespace() {
    return namespace(moduleName);
  }

  userMessagingSettings: UserMessagingSettings | null | undefined = null;

  channelMessagingSettings: ChannelMessagingSettings | null | undefined = null;

  selectedRecipientPhone: DeviceSelectionEntry | null | undefined = null;

  loadingMessagingSettings = false;

  lastSelectedChannel = ""

  REALPHONENOTAITEM: DeviceSelectionEntry | null = null;

  @Mutation
  changeLastSelectedChannel(value: string) {
    this.lastSelectedChannel = value;
  }

  @Mutation
  changeLoadingMessagingSettings(value: boolean) {
    this.loadingMessagingSettings = value;
  }

  @Mutation
  changeUserMessagingSettings(settings: UserMessagingSettings) {
    this.userMessagingSettings = settings;
  }

  @Mutation
  updateSelectedRecipientPhone(phone: DeviceSelectionEntry | null | undefined) {
    this.selectedRecipientPhone = phone;

    if (!phone) return;

    const suspect: any = this.channelMessagingSettings?.driverPhones?.find((it) => it.id == phone?.id);

    if (suspect) {
      suspect.disabled = (phone as any).disabled;
    }
  }

  @Mutation
  updateREALPHONENOTAITEM(value: any) {
    this.REALPHONENOTAITEM = value;
  }

  @Mutation
  changeChannelMessagingSettings(settings: ChannelMessagingSettings | undefined) {
    this.channelMessagingSettings = settings;
  }

  @Action({ rawError: true })
  public async getChannelSettings(channelId: string): Promise<void> {
    this.changeLoadingMessagingSettings(true);
    const body = await bodyWithAuthHeader();

    try {
      this.changeLastSelectedChannel(channelId);
      const settings = await API.get("core", `/messaging/channel-settings/audience/${channelId}/available-phone-numbers`, body);
      settings.driverPhones = [
        {
          id: null, name: DEFAULT.text, number: null, behaviour: ChannelMessagingSettingsBehaviour.DEFAULT,
        },
        {
          id: null, name: PERSONAL.text, number: null, behaviour: ChannelMessagingSettingsBehaviour.PERSONAL,
        },
        {
          id: null, name: CAD.text, number: null, behaviour: ChannelMessagingSettingsBehaviour.CAD,
        },
        ...settings.driverPhones];

      const selectedPhone = settings.selectedDevice;

      if (settings.selectedDevice && !settings.driverPhones.find((it: any) => it.id == selectedPhone.id)) {
        selectedPhone.disabled = true;
        settings.driverPhones.push(selectedPhone);
      }

      this.updateSelectedRecipientPhone(selectedPhone);
      this.changeChannelMessagingSettings(settings);
    } catch (e) {
      handleHttpError(e);
    } finally {
      this.changeLoadingMessagingSettings(false);
    }
  }

  @Action({ rawError: true })
  // eslint-disable-next-line class-methods-use-this
  public async changeDevice(payload: any): Promise<void> {
    this.changeLoadingMessagingSettings(true);
    const body = {
      ...await bodyWithAuthHeader(),
      body: { deviceId: payload.deviceId, behaviour: payload.behaviour },
    };

    try {
      await API.post("core", `/messaging/channel-settings/audience/${payload.channelId}/target-device`, body);
      this.updateSelectedRecipientPhone(payload.device);
    } catch (e) {
      handleHttpError(e);
    } finally {
      this.changeLoadingMessagingSettings(false);
    }
  }

  @Action({ rawError: true })
  // eslint-disable-next-line class-methods-use-this
  public async getUserSettings(): Promise<void> {
    this.changeLoadingMessagingSettings(true);
    const body = {
      ...await bodyWithAuthHeader(),
    };

    try {
      const settings = await API.get("core", "/messaging/global-settings", body);
      this.changeUserMessagingSettings(settings);
    } catch (e) {
      handleHttpError(e);
    } finally {
      this.changeLoadingMessagingSettings(false);
    }
  }

  @Action({ rawError: true })
  // eslint-disable-next-line class-methods-use-this
  public async updateUserSettings(settings: UserMessagingSettings): Promise<void> {
    this.changeLoadingMessagingSettings(true);
    const body = {
      ...await bodyWithAuthHeader(),
      body: settings,
    };

    try {
      const userSettings = await API.post("core", "/messaging/global-settings", body);
      this.changeUserMessagingSettings(userSettings);
    } catch (e) {
      handleHttpError(e);
    } finally {
      this.changeLoadingMessagingSettings(false);
    }
  }
}
