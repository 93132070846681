export const getSortedData = (arr, sort, valueAndSortFunc) => {
  if (!arr.length) return [];

  for (let i = sort.sortBy.length - 1; i >= 0; i--) {
    const sortBy = sort.sortBy[i];
    const sortDesc = sort.sortDesc[i];
    const currentSort = valueAndSortFunc.find((it) => it.value === sortBy).sort;

    arr.sort((a, b) => {
      let aValue = a;
      let bValue = b;
      const nestingParams = sortBy.split(".");

      if (nestingParams.length) {
        nestingParams.forEach((it) => {
          aValue = aValue ? aValue[it] : undefined;
          bValue = bValue ? bValue[it] : undefined;
        });
      } else {
        aValue = a[sortBy];
        bValue = b[sortBy];
      }

      return sortDesc ? currentSort(bValue, aValue) : currentSort(aValue, bValue);
    });
  }

  return arr;
};
