/* eslint-disable no-unused-vars */
import Vue, { Component } from "vue";
import Vuex from "vuex";
import Amplify from "aws-amplify";
import Toasted from "vue-toasted";
import { datadogRum, RumUserConfiguration } from "@datadog/browser-rum";
import { config, getModule } from "vuex-module-decorators";
import { ThisTypedComponentOptionsWithArrayProps } from "vue/types/options";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import awsconfig from "./aws-exports";
import { MAR_SUBJECT_TYPES } from "./utils/mar-utils";
import { inSheepsClothing } from "./utils/utils";
import IssueAgentsModule from "@/store/issue-agents.store";
import TriggerAgentsModule from "@/store/trigger-agents.store";
import { VehicleChangedTrigger } from "./model/trigger/vehicle-changed.trigger.model";

const requiredForInit = [VehicleChangedTrigger];

config.rawError = true;

axios.interceptors.request.use((requestConfig) => {
  if (inSheepsClothing) {
    const tokenPayload = localStorage.getItem("original_cognito_id_token")?.split(".")[1];

    if (!tokenPayload) throw Error("original_cognito_id_token is not found!");

    requestConfig.headers["Master-Email"] = JSON.parse(atob(tokenPayload)).email;
  }

  return requestConfig;
}, (error) => Promise.reject(error));

Amplify.configure({

  ...awsconfig,
  API: {

    endpoints: [

      {
        name: "core",
        endpoint: process.env.VUE_APP_CORE_ENDPOINT || "https://be.demo.dsps.softwea.com",
      },
    ],
  },
  Storage: {

    AWSS3: {

      bucket: process.env.VUE_APP_REPORTS_S3 || "softwea-dsps-demo-reports-bucket",
      region: "us-east-2",
    },
  },
});

Vue.config.productionTip = false;
Vue.use(Vuex);

Vue.use(Toasted);

const storeModulesContext = require.context("@/store", true, /\.store\.(js|ts)$/);

const store = new Vuex.Store({
  modules: Object.fromEntries(
    storeModulesContext.keys()
      .map((x) => storeModulesContext(x).default)
    // eslint-disable-next-line no-underscore-dangle
      .map((component) => [component._vmdModuleName || component.name, component]),
  ),
});

store.dispatch("marModule/loadRequestAgents");

getModule(IssueAgentsModule, store);
store.dispatch("issueAgentsModule/loadAgents");

getModule(TriggerAgentsModule, store);
store.dispatch("triggerAgentsModule/loadAgents");

const mar = {

  registerRequestComponent(component: Component): void {
    store.commit("marModule/registerRequestComponent", component);
  },

  keepRequestComponentData(component: Component): void {
    store.commit("marModule/keepRequestComponentData", component);
  },

  loadSubject(id: string, type: MAR_SUBJECT_TYPES): void {
    store.dispatch("marModule/loadSubject", { id, type });
  },

  sendSolutions() {
    return store.dispatch("marModule/sendSolutions");
  },

  updateAutoIgnoreRequests(newAutoIgnoreRequests: Record<string, any>[]): void {
    store.commit("marModule/updateAutoIgnoreRequests", newAutoIgnoreRequests);
  },

  get currentSubject() {
    return (store as any).state.marModule.currentSubject;
  },
};

Vue.prototype.$mar = mar;

const vueFilesContext = require.context("@/views", true, /.vue$/);
const viewPages = vueFilesContext.keys()
  .map((x) => (vueFilesContext(x).default))
  .filter((component) => component.routing)
  .map((component) => ({
    name: component.name,
    routing: component.routing,
  }));

store.commit("pagesModule/setViewPages", viewPages);

const Application = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  store,
} as ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, never>)
  .$mount("#app");

export { Application, store };

datadogRum.init({
  applicationId: process.env.VUE_APP_RUM_APPLICATION_ID || "e9f2a3f7-9110-4b2f-88e2-514fa95f1860",
  clientToken: process.env.VUE_APP_RUM_CLIENT_TOKEN || "pub7df0b189b15c77ebbb9dc755be118d58",
  site: "datadoghq.com",
  service: process.env.VUE_APP_RUM_SERVICE || "demo.dsps.softwea.com",
  env: process.env.VUE_APP_RUM_ENV || "demo",
  // Specify a version number to identify the deployed version of your application in Datadog
  forwardErrorsToLogs: true,
  sampleRate: 100,
  trackInteractions: true,
} as RumUserConfiguration);

Object.defineProperty(Array.prototype, "mapNotNull", {
  enumerable: false,
  configurable: false,
  writable: false,
  value(transformer: Function) {
    return this.map(transformer).filter((it: any) => it);
  },
});
