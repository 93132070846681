import Vue from "vue";
import { API } from "aws-amplify";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";
import { VehicleAction } from "../model/reminder/vehicle-action.model";

const API_PATH = "/vehicles/vehicle-action";

const locService = Vue.prototype.$localizationService;

export default {
  name: "vehicleActionsModule",
  namespaced: true,

  state: {
    pageLoading: false,
    displayVehicleActions: [],
    currentPage: {
      vehicleActions: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },

  mutations: {

    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicleActions(state, vehicleActionDto) {
      state.currentPage.vehicleActions = [...vehicleActionDto];
      state.displayVehicleActions = state.currentPage.vehicleActions;
    },
  },

  actions: {

    async loadPage({ dispatch, commit }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        const vehicleActionsDto = await API.get("core", API_PATH, body);
        commit("updateVehicleActions", vehicleActionsDto.map((vehicleAction) => VehicleAction.fromDto(vehicleAction)));
      } catch (e) {
        handleHttpError(e);
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit, state }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        handleHttpError(e);
      }
    },

    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async submitAction(context, vehicleAction) {
      const body = { ...await bodyWithAuthHeader(), body: VehicleAction.toDto(vehicleAction) };

      try {
        const response = await (vehicleAction.id ? API.put("core", `${API_PATH}/${vehicleAction.id}`, body)
          : API.post("core", API_PATH, body));
        console.log(response);
        const vehicleActionDto = VehicleAction.fromDto(response);
        console.log(VehicleAction.fromDto(response));
        Vue.toasted.success(
          locService.localize("store.vehicle_action.submit_action_success", [vehicleAction.type.name]),
          {
            duration: 5000,
          },
        );
        return vehicleActionDto;
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },

    async deleteVehicleAction(context, vehicleAction) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicleAction.id}`, body);
        Vue.toasted.success(locService.localize("store.vehicle_actions.delete_vehicle_actions_msg",
          [vehicleAction.name]), {
          duration: 5000,
        });
      } catch (e) {
        handleHttpError(e);
      }
    },

    async loadFilteredVehicleActions(context, searchString) {
      if (!searchString) return [];

      try {
        return await API.get("core", `${API_PATH}/search?searchString=${searchString}`,
          await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },

    async loadAllVehicleActions() {
      try {
        return await API.get("core", `${API_PATH}`, await bodyWithAuthHeader());
      } catch (error) {
        handleHttpError(error);
      }
      return undefined;
    },
  },
};
