<template>
  <div>
    <v-data-table
      class="drp-entries-table"
      :class="{ 'border-top-color': pFilterPanelIsOpened }"
      :headers="displayHeaders"
      :items="displayRescuers"
      :loading="pPageLoading"
      multi-sort
      disable-pagination
      hide-default-footer
      @update:options="paginate($event)"
      :options="{
        sortBy: rescuersSort.sortBy,
        sortDesc: rescuersSort.sortDesc,
      }"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        <span
          :key="header.value"
          :class="{
            'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
          }"
        >
          {{ header.text }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <RescueStatus
          :pRescuer="item"
          @onSelectStatus="
            item.status = $event;
            saveRescue(item);
          "
        />
      </template>

      <template v-slot:[`item.rescuer`]="{ item }">
        <div class="driver-entry-select-container">
          <DriverEntrySelectLabel
            :targetDate="pTargetDate"
            :targetDriverEntry="item.rescuer"
            :driverEntries="pRoutePlan.driverEntries"
            :additionalDataPerDriver="pAdditionalDataPerDriver"
            @onChangeData="
              item.rescuer = $event;
              saveRescue(item);
            "
          />
        </div>
      </template>

      <template v-slot:[`item.target`]="{ item }">
        <div v-if="item.target.driverEntry">
          <DriverEntryLabel
            :targetDriverEntry="item.target.driverEntry"
            :additionalDataPerDriver="
              pAdditionalDataPerDriver[item.target.driverEntry.driver.id] || {}
            "
            :isDuplicate="item.target.driverEntry.isWillBeDuplicate"
          />
        </div>
      </template>

      <template v-slot:[`item.stops`]="{ item }">
        <ViewEditInteger
          :pLabel="$localizationService.localize('drp.view_edit_integer.stops')"
          :pValue="item.stops"
          :pSpecialColors="[+item.stops <= 0 ? 'orange--text' : '']"
          @onChangeValue="
            item.stops = $event;
            saveRescue(item);
          "
        />
      </template>

      <template v-slot:[`item.packages`]="{ item }">
        <ViewEditInteger
          :pLabel="$localizationService.localize('drp.view_edit_integer.packages')"
          :pValue="item.packages"
          :pSpecialColors="[+item.packages < +item.stops ? 'orange--text' : '']"
          @onChangeValue="
            item.packages = $event;
            saveRescue(item);
          "
        />
      </template>

      <template v-slot:[`item.bonus`]="{ item }">
        <v-simple-checkbox
          class="small-checkbox"
          v-model="item.bonus.active"
          v-ripple
          @input="saveRescue(item);"
        ></v-simple-checkbox>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium color="red" @click="deleteRescue(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <ConfirmationDialog ref="confirmationDialog"/>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import tableSortParserMixin from "../../../utils/table-sort-parser";
import {
  RESCUE_STATUS_PLANNED,
  RESCUE_STATUS_COMPLETED,
} from "../../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "DailyRoutePlannerRescues",

  mixins: [tableSortParserMixin],

  components: {
    DriverEntrySelectLabel: () => import("../drp-common/entry/DriverEntrySelectLabel"),
    DriverEntryLabel: () => import("../drp-common/entry/DriverEntryLabel.vue"),
    RescueStatus: () => import("../drp-common/rescue/status/RescueStatus.vue"),
    ConfirmationDialog: () => import("@/components/ConfirmationDialog.vue"),
    ViewEditInteger: () => import("../drp-common/ViewEditInteger.vue"),
  },

  props: {
    pTargetDate: String,
    pPageLoading: Boolean,
    pRoutePlan: Object,
    pAdditionalDataPerDriver: Object,
    pSearchString: String,
    pFilterPanelIsOpened: Boolean,
    pPrintingDrpMode: Boolean,
    pFilterState: Object,
  },

  data: () => ({
    headers: [
      {
        text: locService.localize("drp.rescuers_table.header.status"),
        value: "status",
        sortable: true,
        sort: (a, b) => {
          if (a === RESCUE_STATUS_PLANNED) return -1;
          if (b === RESCUE_STATUS_PLANNED) return 1;
          if (a === RESCUE_STATUS_COMPLETED) return -1;
          if (b === RESCUE_STATUS_COMPLETED) return 1;
          return a.value.localeCompare(b.value);
        },
      },
      {
        text: locService.localize("drp.rescuers_table.header.route"),
        value: "route",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.localeCompare(b);
        },
      },
      {
        text: locService.localize("drp.rescuers_table.header.rescuer"),
        value: "rescuer",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.driver.name.localeCompare(b.driver.name);
        },
      },
      {
        text: locService.localize("drp.rescuers_table.header.rescuee"),
        value: "target",
        sortable: true,
        sort: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a.driverEntry.driver.name.localeCompare(b.driverEntry.driver.name);
        },
      },
      {
        text: locService.localize("drp.rescuers_table.header.stops"),
        value: "stops",
        sortable: true,
      },
      {
        text: locService.localize("drp.rescuers_table.header.packages"),
        value: "packages",
        sortable: true,
      },
      {
        text: locService.localize("drp.rescuers_table.header.bonus"),
        value: "bonus",
        sortable: true,
        sort: (a, b) => {
          if (!a.active) return 1;
          if (!b.active) return -1;
          return 1;
        },
        align: "start",
      },
      {
        text: locService.localize("drp.rescuers_table.header.actions"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState("drpRescuersTableSettingsModule", [
      "rescuersSort",
      "defaultSettings",
      "sortPropertyName",
    ]),
    ...mapGetters("drpRescuersTableSettingsModule", [
      "isDefaultSort",
    ]),

    displayHeaders() {
      return !this.pPrintingDrpMode
        ? this.headers
        : this.headers.filter((it) => it.value !== "actions");
    },

    displayRescuers() {
      if (!this.pRoutePlan.id) {
        return [];
      }

      let rescuers = this.pRoutePlan.allRescuers;

      if (this.pSearchString) {
        const str = this.pSearchString.trim().toLowerCase();
        rescuers = rescuers.filter(
          (it) => (it.status && it.status.text.toLowerCase().includes(str))
            || (it.route && it.route.toLowerCase().includes(str))
            || (it.rescuer && it.rescuer.driver.name.toLowerCase().includes(str))
            || (it.target.driverEntry && it.target.driverEntry.driver.name.toLowerCase().includes(str))
            || (`${it.stops}`.toLowerCase().includes(str))
            || (`${it.packages}`.toLowerCase().includes(str)),
        );
      }

      return this.pFilterState.criteriaCount
        ? rescuers.filter((it) => (
          this.pFilterState.driverIds.includes(
            it.target.driverEntry.driver.id,
          )
          || (
            it.rescuer
            && this.pFilterState.driverIds.includes(it.rescuer.driver.id)
          )
        ))
        : rescuers;
    },

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems(this.sortPropertyName)
        : [];
    },
  },

  methods: {
    paginate(data) {
      this.$store.commit("drpRescuersTableSettingsModule/changeSettings", {
        rescuersSort: {
          sortBy: data.sortBy,
          sortDesc: data.sortDesc,
        },
      });
      this.$emit("onUpdateSort");
    },

    saveRescue(rescue) {
      this.$store.dispatch("dailyRoutePlannerModule/saveRescue", rescue);
    },

    deleteRescue(rescue) {
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize("drp.rescuers_table.dialog.delete.title"),
        text: locService.localize("drp.rescuers_table.dialog.delete.text"),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$store.dispatch("dailyRoutePlannerModule/deleteRescue", rescue),
      });
    },
  },
};
</script>

<style scoped>

</style>
