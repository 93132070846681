<template>
  <v-autocomplete
    class="w-110"
    :label="label"
    :dense="!label"
    :items="stationsInUse"
    v-model="newStation"
    :class="`drp-station-select ${getStationClass(
      $refs.inputStation ? $refs.inputStation.internalSearch : ''
    )}`"
    error-count="0"
    hide-details
    :search-input.sync="searchStation"
    @keydown.enter="$emit('submit')"
    @keydown.esc="$emit('cancel')"
    autofocus
    item-text="name"
    item-value="value"
    return-object
    ref="inputStation"
    :placeholder="$localizationService.localize('common.begin_typing')"
  >
    <template v-slot:item="data">
      <span :class="`drp-station-select ${getStationClass(data.item)}`">
        {{ data.item }}
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { getStationClass } from "../../../../utils/utils";

export default {
  name: "StationSelect",

  props: {
    value: String,
    label: String,
    stationsInUse: Array,
  },

  data() {
    return {
      searchStation: null,
      newStation: this.value,
    };
  },

  watch: {
    searchStation() {
      this.$emit("input", this.searchStation);
    },
    newStation() {
      this.$emit("onSelect", this.newStation);
    },
  },

  methods: {
    getStationClass,
  },
};
</script>

<style lang="scss">
.drp-station-select {
  &.pink--text.text--lighten-1 input {
    color: #ec407a !important;
  }
  &.purple--text.text--lighten-1 input {
    color: #ab47bc !important;
  }
  &.indigo--text.text--lighten-1 input {
    color: #5c6bc0 !important;
  }
  &.blue--text.text--lighten-1 input {
    color: #42a5f5 !important;
  }
  &.cyan--text.text--lighten-1 input {
    color: #26c6da !important;
  }
  &.teal--text.text--lighten-1 input {
    color: #26a69a !important;
  }
  &.purple--text.text--lighten-1 input {
    color: #8e24aa !important;
  }
}
</style>
