import { IssueDataUnit } from "@/model/issue/data-unit.model";
import { IssueDataUnitDto, IssueUnitType, IssueUnitUser } from "@/types/types";
import { ISSUE_DATA_USER_UNIT_TYPE } from "@/utils/ts-utils";

export class IssueDataUnitUser extends IssueDataUnit {
    user: IssueUnitUser;

    constructor(
      id: string,
      type: IssueUnitType,
      user: IssueUnitUser,
    ) {
      super(id, type);
      this.user = user;
    }

    static createUnitWithUser(user: IssueUnitUser): IssueDataUnit {
      return new IssueDataUnitUser(
        "",
        ISSUE_DATA_USER_UNIT_TYPE,
        user,
      );
    }

    static fromDto(dto: IssueDataUnitDto): IssueDataUnit {
      return new IssueDataUnitUser(
        dto.id,
        ISSUE_DATA_USER_UNIT_TYPE,
        dto.user as IssueUnitUser,
      );
    }
}
