<template>
  <div>
    <v-tooltip open-delay="500" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            :disabled="!authed"
            @click.stop="openMessageDialog(target)"
        >
          <v-icon>mdi-message-text-outline</v-icon>
        </v-btn>
      </template>
      <div>
        {{ $localizationService.localize("btn.message") }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: "MessageButton",

  props: {
    target: String,
  },

  computed: {
    ...mapState("rocketChatModule", [
      "authed",
    ]),
  },

  methods: {
    async openMessageDialog(target) {
      await this.$store.dispatch("rocketChatModule/getCustomDirectChannel", target);
    },
  },
};

</script>
