import { VehicleActionType } from "@/model/reminder/vehicle-action-type.model";
import { Vehicle } from "@/model/vehicle.model";
import { Reminder } from "@/model/reminder/reminder.model";

export class SubmitAction {
  constructor(
    public vehicleId: string,
    public vehicleActionTypeId: string,
    public doneAt: string,
    public doneAtMeter: number,
    public comment: string | null,
  ) {}
}

export class VehicleAction {
  constructor(
    public id: string | undefined,
    public type: VehicleActionType,
    public vehicle: Vehicle,
    public doneAt: string,
    public doneAtMeterValue: number,
    public comment: string | null,
    public createdAt: string,
    public updatedAt: string,
  ) {}

  clone(): VehicleAction {
    return new VehicleAction(
      this.id,
      this.type,
      this.vehicle,
      this.doneAt,
      this.doneAtMeterValue,
      this.comment,
      this.createdAt,
      this.updatedAt,
    );
  }

  static createEmpty(reminder: Reminder | undefined) {
    return new VehicleAction(
      undefined,
      reminder ? reminder.trackedActionType : {} as VehicleActionType,
      reminder ? reminder.trackedVehicle : {} as Vehicle,
      "",
      reminder ? reminder.trackedVehicle.lastKnownOdometerValueForNow : "",
      null,
      "",
      "",
    );
  }

  static fromDto(dto: any) {
    return new VehicleAction(
      dto.id,
      VehicleActionType.fromDto(dto.vehicleActionType),
      Vehicle.fromDto(dto.vehicle),
      dto.doneAt,
      dto.doneAtMeterValue,
      dto.comment,
      dto.createdAt,
      dto.updatedAt,
    );
  }

  static toDto(vehicleAction: VehicleAction) {
    return new SubmitAction(
      vehicleAction.vehicle.id,
      vehicleAction.type.id,
      vehicleAction.doneAt,
      vehicleAction.doneAtMeterValue,
      vehicleAction.comment,
    );
  }
}
