






























































































































































import {
  Component, Emit, Prop, Vue,
} from "vue-property-decorator";
import _ from "lodash";
import { VehicleChangedTrigger } from "@/model/trigger/vehicle-changed.trigger.model";

const locService = Vue.prototype.$localizationService;

@Component({})
export default class VehicleChangedTriggerAgent extends Vue {
  static agentName = "VehicleChangedTriggerAgent"

  locService = locService;

  @Prop()
  trigger!: VehicleChangedTrigger;

  toValueCondition = JSON.parse(this.trigger.toValueCondition || "{}");

  fromValueCondition = JSON.parse(this.trigger.fromValueCondition || "{}");

  property = this.toValueCondition?.property || this.fromValueCondition?.property || {};

  toValueConditionModel = this.toValueCondition || {};

  fromValueConditionModel = this.fromValueCondition || {};

  rentalExpiryMenu = false;

  onChangeProperty() {
    this.fromValueConditionModel = { property: this.property };
    this.toValueConditionModel = { property: this.property };
    this.updatedConditions();
  }

  // eslint-disable-next-line consistent-return
  updatedConditions() {
    const fromConditionIsEmpty = this.conditionIsEmpty(this.fromValueConditionModel);
    const toConditionIsEmpty = this.conditionIsEmpty(this.toValueConditionModel);

    let result: any = {
      fromValueCondition: !fromConditionIsEmpty ? JSON.stringify(this.fromValueConditionModel) : undefined,
      toValueCondition: !toConditionIsEmpty ? JSON.stringify(this.toValueConditionModel) : undefined,
    };

    if (!toConditionIsEmpty && !this.validateCondition(this.toValueConditionModel)) {
      result = undefined;
    } else if (!fromConditionIsEmpty && !this.validateCondition(this.fromValueConditionModel)) {
      result = undefined;
    } else if (toConditionIsEmpty && fromConditionIsEmpty) {
      result = undefined;
    }

    this.emitUpdatedConditions(result);
  }

  @Emit("onUpdate")
  // eslint-disable-next-line no-unused-vars
  emitUpdatedConditions(conditions: any) {}

  // TODO: inject types
  conditionIsEmpty(condition: any) {
    return _.isEmpty(condition.operator) && _.isEmpty(condition.thresholdValue);
  }

  validateCondition(condtion: any) {
    if (!condtion.property) return false;
    if (!condtion.operator) return false;
    if (!condtion.thresholdValue) return false;

    return true;
  }

  get properties() {
    const vehicleModel = this.$store.state.simpleModule.vehicleModel;
    return vehicleModel && vehicleModel.properties ? vehicleModel.properties : [];
  }

  get propertyObj() {
    return this.properties.find((it: any) => it.name == this.property);
  }

  get propertyIsEnum() {
    return this.propertyObj ? this.propertyObj.type.isEnum : undefined;
  }

  get propertyIsDate() {
    return this.propertyObj ? this.propertyObj.type.name === "LocalDate" : undefined;
  }

  get propertyValues() {
    if (!this.propertyObj) return [];
    return this.propertyObj.type.values;
  }

  async mounted() {
    await this.$store.dispatch("simpleModule/loadVehicleModel");
    await this.$store.dispatch("simpleModule/loadModelIfNeeded");
  }

  get availableOperators() {
    const currentModel = this.$store.state.simpleModule.currentModel;

    const result = currentModel
      ? currentModel.operators.filter((operator: any) => (operator.supportedTypes === null)) : [];

    result.push({
      name: "",
      displayName: "---",
    });

    return result;
  }

  get availableProperties() {
    const vehicleModel = this.$store.state.simpleModule.vehicleModel;

    return vehicleModel && vehicleModel.properties ? vehicleModel.properties : [];
  }
}
