<template>
  <v-dialog v-model="open" max-width="700px">
    <v-card :loading="userInputDisabled">
      <v-card-title>
        <span class="headline">
          {{
            criteria.id
              ? $localizationService.localize(
                  "coaching_page.create_criteria_dialog.title_edit"
                )
              : $localizationService.localize(
                  "coaching_page.create_criteria_dialog.title_create"
                )
          }}
        </span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container v-if="open">
          <v-row>
            <v-col>
              <v-text-field
                v-model="criteria.name"
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.name'
                  ) + '*'
                "
                :disabled="userInputDisabled"
                :rules="nameRules"
              ></v-text-field>
              <v-textarea
                v-model="criteria.description"
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.description'
                  )
                "
                :disabled="userInputDisabled"
                rows="4"
                no-resize
              ></v-textarea>
            </v-col>
            <v-col class="d-flex flex-column justify-space-between">
              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.report_type'
                  ) + '*'
                "
                class="flex-grow-0"
                v-model="criteria.reportType"
                :items="availableReportTypes"
                item-text="displayName"
                return-object
                :disabled="userInputDisabled || editMode"
                :rules="reportTypeRules"
              ></v-autocomplete>

              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.group_rows_by'
                  )
                "
                class="flex-grow-0"
                v-model="criteria.groupBy"
                :items="availableGroupingProperties"
                item-text="displayName"
                item-value="name"
                multiple
                :disabled="userInputDisabled || editMode"
              ></v-autocomplete>

              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.property'
                  ) + '*'
                "
                class="flex-grow-0"
                v-model="criteria.property"
                :items="availableProperties"
                item-text="displayName"
                return-object
                :disabled="userInputDisabled || editMode"
                :rules="propertyRules"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.function'
                  )
                "
                v-model="criteria.func"
                :items="availableFunctions"
                item-text="displayName"
                return-object
                :disabled="userInputDisabled || editMode"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="d-flex">
              <p class="mr-2 mb-2 condition-brackets">(</p>
              <template v-for="arg in functionArguments">
                <v-text-field
                  v-if="!propertyValues.length"
                  :key="arg.name"
                  :label="arg.displayName"
                  v-model="criteria.args[arg.name]"
                  :disabled="userInputDisabled || editMode"
                ></v-text-field>
                <v-autocomplete
                  v-if="propertyValues.length"
                  :key="arg.name"
                  :label="arg.displayName"
                  v-model="criteria.args[arg.name]"
                  item-text="displayName"
                  item-value="name"
                  :items="propertyValues"
                  :disabled="userInputDisabled || editMode"
                ></v-autocomplete>
              </template>
              <v-text-field
                v-if="!functionArguments.length"
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.no_arguments'
                  )
                "
                disabled
              ></v-text-field>
              <p class="ml-2 mb-2 condition-brackets">)</p>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.operator'
                  ) + '*'
                "
                v-model="criteria.operator"
                :items="availableOperators"
                item-text="displayName"
                return-object
                :disabled="userInputDisabled || editMode"
                :rules="operatorRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-if="expressionLeftSideTypeIsEnum"
                :items="propertyValues"
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.threshold_value'
                  )
                "
                item-text="displayName"
                item-value="name"
                v-model="criteria.thresholdValue"
                :disabled="userInputDisabled || editMode"
              ></v-autocomplete>
              <v-text-field
                v-else
                :label="
                  $localizationService.localize(
                    'coaching_page.create_criteria_dialog.param.threshold_value'
                  )
                "
                v-model="criteria.thresholdValue"
                :rules="[validateThresholdValueRule]"
                :disabled="userInputDisabled || editMode"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false" :disabled="!loaded">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="createCriteria"
          :disabled="!canCreateOrSave"
        >
          {{
            criteria.id
              ? $localizationService.localize("btn.save")
              : $localizationService.localize("btn.create")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CreateCriteriaDialog",

  data: () => ({
    criteriaDto: undefined,
    criteria: {
      name: "",
      description: "",
      func: "",
      operator: undefined,
      property: undefined,
      reportType: undefined,
      args: {},
      thresholdValue: undefined,
      groupBy: [],
    },
    originalCriteria: undefined,
    open: false,
    editMode: false,

    nameRules: [
      (value) => /^(?!\s*$).+/g.test(value)
        || locService.localize("error.validation.required"),
    ],
    reportTypeRules: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],
    propertyRules: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],
    operatorRules: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

    disabledGroupingProperties: ["driverName", "transporterId", "name", "vin"],
    disabledToSelectProperties: ["~driver", "~vehicle"],

    typesRules: {
      Float: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+(\.\d+)?)?%*$/.test(str);
        },
      },
      Double: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+(\.\d+)?)?%*$/.test(str);
        },
      },
      Integer: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+?)?%*$/.test(str);
        },
      },
      Long: {
        check: (str) => {
          if (!str) str = "";
          return /^[+-]*(\d+?)?%*$/.test(str);
        },
      },
      String: {
        check: () => true,
      },
      Enum: {
        check: (str, values) => {
          if (!str) str = "";
          return values.map((value) => value.value).includes(str);
        },
      },
    },
  }),

  computed: {
    ...mapState("criteriaModule", ["processingCriteria"]),
    ...mapState("simpleModule", ["loadingModel", "currentModel"]),
    ...mapState("reportModule", ["loadingReportModels", "reportModels"]),
    createButtonActive() {
      if (!this.loaded) return false;
      return true;
    },

    userInputDisabled() {
      return this.processingCriteria || !this.loaded;
    },

    loaded() {
      return !(this.loadingModel || this.loadingReportModels);
    },

    // TODO: improve
    canCreateOrSave() {
      if (this.editMode) {
        if (!this.criteria.name) return false;
        if (this.criteria.name != this.originalCriteria.name) return true;
        if (this.criteria.description != this.originalCriteria.description) return true;

        return false;
      }
      if (!this.criteria.name) return false;
      if (!this.criteria.reportType) return false;
      if (!this.criteria.property) return false;
      if (!this.criteria.operator) return false;

      return true;
    },

    availableFunctions() {
      const propertyTypeName = this.criteria.property
        ? this.criteria.property.type.name
        : undefined;
      return this.currentModel
        ? [
          ...this.currentModel.functions.filter((suspect) => (suspect.supportedTypes
            ? suspect.supportedTypes.map((it) => it.name).includes(propertyTypeName)
            : true)),
          { displayName: "---", arguments: [] },
        ]
        : [];
    },

    availableOperators() {
      const leftSideTypeName = this.expressionLeftSideType
        ? this.expressionLeftSideType.name
        : "";
      return this.currentModel
        ? this.currentModel.operators.filter((operator) => (operator.supportedTypes
          ? !!operator.supportedTypes.find(
            (suspect) => suspect.name == leftSideTypeName,
          )
          : true))
        : [];
    },

    availableReportTypes() {
      return this.reportModels
        ? this.reportModels.filter((suspect) => suspect.name != "Prototype" && !suspect.isInternal)
        : [];
    },

    availableProperties() {
      return this.criteria.reportType
        ? this.criteria.reportType.itemModels[0].properties.filter(
          (suspect) => !this.disabledToSelectProperties.includes(suspect.name),
        )
        : [];
    },

    availableGroupingProperties() {
      return this.criteria.reportType
        ? this.criteria.reportType.itemModels[0].properties.filter(
          (suspect) => !this.disabledGroupingProperties.includes(suspect.name),
        )
        : [];
    },

    functionArguments() {
      return this.criteria.func ? this.criteria.func.arguments : [];
    },

    propertyValues() {
      return this.criteria.property ? this.criteria.property.type.values : [];
    },

    expressionLeftSideType() {
      if (this.criteria.func) {
        if (
          this.criteria.property
          && this.criteria.func.returnType.name
            == "InheritPropertyTypeArgumentType"
        ) {
          return this.criteria.property.type;
        }
        return this.criteria.func.returnType;
      }
      return this.criteria.property ? this.criteria.property.type : undefined;
    },

    expressionLeftSideTypeIsEnum() {
      return this.expressionLeftSideType
        ? this.expressionLeftSideType.isEnum
        : false;
    },
  },

  watch: {
    "criteria.func": function (newVal) {
      if (this.editMode) return;

      this.criteria.args = {};

      if (!newVal) return;

      if (newVal.displayName == "---") {
        this.criteria.func = undefined;
      }
    },
    "criteria.property": function () {
      if (this.editMode) return;

      this.criteria.func = undefined;
      this.criteria.operator = undefined;
      this.criteria.thresholdValue = undefined;
    },
    "criteria.reportType": function () {
      if (this.editMode) return;
      this.criteria.property = undefined;
    },

    loaded(val) {
      if (val && this.criteriaDto) {
        this.initCriteriaFromDto(this.criteriaDto);
        this.criteriaDto = undefined;
      }
    },
  },

  methods: {
    openDialog(criteriaDto) {
      this.criteria = {
        name: "",
        description: "",
        func: "",
        operator: undefined,
        property: undefined,
        reportType: undefined,
        args: {},
        thresholdValue: undefined,
      };
      this.originalCriteria = { ...this.criteria };

      if (criteriaDto) {
        this.editMode = true;
        if (this.currentModel) {
          this.initCriteriaFromDto(criteriaDto);
        } else {
          this.criteriaDto = criteriaDto;
        }
      } else {
        this.editMode = false;
      }

      this.$store.dispatch("simpleModule/loadModelIfNeeded");
      this.$store.dispatch("reportModule/loadModelsIfNeeded");

      this.open = true;
    },

    initCriteriaFromDto(criteriaDto) {
      const simpleData = JSON.parse(criteriaDto.condition);

      const reportType = this.reportModels.find(
        (suspect) => suspect.name == criteriaDto.reportType,
      );

      this.criteria = {
        id: criteriaDto.id,
        name: criteriaDto.name,
        description: criteriaDto.description,
        func: this.currentModel.functions.find(
          (suspect) => suspect.name == simpleData.function,
        ),
        args: simpleData.args,
        thresholdValue: simpleData.thresholdValue,
        operator: this.currentModel.operators.find(
          (suspect) => suspect.name == simpleData.operator,
        ),
        property: reportType.itemModels[0].properties.find(
          (suspect) => suspect.name == simpleData.property,
        ),
        reportType,
        groupBy: reportType.itemModels[0].properties.filter((suspect) => simpleData.groupBy.includes(suspect.name)),
      };

      this.originalCriteria = { ...this.criteria };

      this.editMode = true;
    },

    validateThresholdValueRule() {
      if (!this.expressionLeftSideType) return true;

      const rule = this.typesRules[this.expressionLeftSideType.name];

      return (
        rule.check(
          this.criteria.thresholdValue,
          this.criteria.property.values,
        )
        || (rule.getErrorMessage
          ? rule.getErrorMessage()
          : locService.localize("error.validation.unknown_value"))
      );
    },

    async createCriteria() {
      this.$store
        .dispatch("criteriaModule/addOrSaveCriteria", {
          id: this.criteria.id,
          name: this.criteria.name,
          description: this.criteria.description,
          reportType: this.criteria.reportType.name,
          condition: JSON.stringify({
            function: this.criteria.func ? this.criteria.func.name : "NONE",
            args: this.criteria.args,
            thresholdValue: this.criteria.thresholdValue,
            operator: this.criteria.operator.name,
            property: this.criteria.property.name,
            groupBy: this.criteria.groupBy || [],
          }),
          conditionLanguage: "Simple",
        })
        .then((result) => {
          Vue.toasted.success(
            this.criteria.id
              ? locService.localize("toasted.successfully_upd", [result.name])
              : locService.localize("toasted.successfully_create", [
                result.name,
              ]),
            {
              duration: 5000,
            },
          );
          this.open = false;
        })
        .catch((e) => {
          Vue.toasted.error(e, {
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.row-combobox {
  margin-top: 0 !important;
}
.condition-brackets {
  display: flex !important;
  align-items: center !important;
  font-size: 1.5em !important;
}
</style>
