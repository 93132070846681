var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.open)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( _vm.editedPhoneAgent.id ? "phone_agent_page.dialog.add_and_edit.edit_title" : "phone_agent_page.dialog.add_and_edit.new_title" ))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.requiredRule,"label":_vm.$localizationService.localize(
                        'phone_agent_page.dialog.add_and_edit.param.name'
                    ),"disabled":_vm.loading},model:{value:(_vm.editedPhoneAgent.name),callback:function ($$v) {_vm.$set(_vm.editedPhoneAgent, "name", $$v)},expression:"editedPhoneAgent.name"}})],1)],1),_c('v-row',{staticClass:"phone-agent-combobox"},[_c('v-combobox',{attrs:{"items":_vm.matchedUsers,"label":_vm.$localizationService.localize(
                  'phone_agent_page.dialog.add_and_edit.param.allowed_users'
                ),"disabled":_vm.loading,"item-text":"name","item-value":"id","return-object":"","loading":_vm.loadingUsers,"chips":"","multiple":"","deletable-chips":"","delimiters":[',']},on:{"focus":_vm.loadUsers,"change":function($event){return _vm.checkInputUser($event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.email)+" ")])],1)]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,2901953143),model:{value:(_vm.editedPhoneAgent.allowedUsers),callback:function ($$v) {_vm.$set(_vm.editedPhoneAgent, "allowedUsers", $$v)},expression:"editedPhoneAgent.allowedUsers"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.loading || !_vm.editedPhoneAgent.name},on:{"click":_vm.addOrSavePhoneAgent}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize(_vm.editedPhoneAgent.id ? "btn.save" : "btn.add"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }