










import {
  Component, Emit, Prop, Vue,
} from "vue-property-decorator";
import TemplatesWritingAssistant from "@/components/templates-writing-assistant/TemplatesWritingAssistant.vue";
import { TwaMode } from "@/utils/ts-utils";
import { VehicleIssueUnitTemplate } from "@/model/trigger/action-draft-templates/issue/units/vehicle-issue-unit-template.model";
import { AssistantConstant } from "@/model/assistant-constant.model";

const locService = Vue.prototype.$localizationService;

@Component({
  components: {
    TemplatesWritingAssistant,
  },
})
export default class RelatedVehicleDataUnitTemplateAgent extends Vue {
  static agentName = "RelatedVehicleDataUnitTemplateAgent";

  TwaMode = TwaMode;

  locService = locService;

  // TODO: bind to backend
  specificConstants = [
    new AssistantConstant(
      "vehicle",
      "Changed vehicle",
      undefined,
    ),
  ]

  @Prop()
  data!: {
    unit: VehicleIssueUnitTemplate;
    index: number;
  }

  currentUnit!: VehicleIssueUnitTemplate;

  @Emit("onUpdatedUnit")
  emitUpdatedUnit() {
    return {
      unit: this.currentUnit,
      index: this.data.index,
    };
  }

  created() {
    if (!this.data.unit) {
      this.currentUnit = VehicleIssueUnitTemplate.createEmpty();
    } else {
      this.currentUnit = this.data.unit.clone();
    }
  }
}
