<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              editedVehicleActionType.id
                ? "vehicle_action_type_page.dialog.add_and_edit.edit_title"
                : "vehicle_action_type_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                :rules="requiredRule"
                v-model="editedVehicleActionType.name"
                :label="
                  $localizationService.localize(
                    'vehicle_action_type_page.dialog.add_and_edit.param.name'
                  )
                "
                :disabled="loading"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="addOrSaveVehicleActionType"
          :disabled="saveIsDisabled"
        >
          {{ $localizationService.localize(editedVehicleActionType.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { isEqual } from "lodash";
import { VehicleActionType } from "../../../model/reminder/vehicle-action-type.model";

const locService = Vue.prototype.$localizationService;

export default {
  name: "EditVehicleActionTypeDialog",

  data: () => ({
    open: false,
    loading: false,

    editedVehicleActionTypeOriginal: {},
    editedVehicleActionType: {},

    requiredRule: [
      (value) => !!value || locService.localize("error.validation.required"),
    ],

  }),

  computed: {
    saveIsDisabled() {
      return this.loading
        || !this.editedVehicleActionType.name
        || isEqual(this.editedVehicleActionType, this.editedVehicleActionTypeOriginal);
    },
  },

  methods: {

    openDialog(vehicleActionType) {
      this.editedVehicleActionTypeOriginal = vehicleActionType || {};
      this.editedVehicleActionType = Object.assign(new VehicleActionType(), this.editedVehicleActionTypeOriginal);
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    async addOrSaveVehicleActionType() {
      this.loading = true;
      const response = await this.$store.dispatch(
        "vehicleActionTypesModule/addOrSaveVehicleActionType",
        this.editedVehicleActionType,
      );
      this.loading = false;
      if (response) {
        this.$emit("onAddOrSave", VehicleActionType.fromDto(response));
        this.open = false;
      }
    },
  },
};
</script>
