
















































import {
  Component, Emit, Prop, Vue, Watch,
} from "vue-property-decorator";

@Component
export default class SelectTableColumns extends Vue {
  @Prop({ required: true, default: [] })
  readonly headers!: any[];

  @Prop({ required: true, default: [] })
  readonly selectedHeaders!: string[];

  model: any[] = this.mappedSelectedHeaders

  get icon() {
    if (this.selectedHeaders.length == this.headers.length) return "mdi-close-box";
    if (this.selectedHeaders.length) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  get mappedSelectedHeaders() {
    return this.headers.filter((it) => this.selectedHeaders.includes(it.value));
  }

  toggle() {
    this.$nextTick(() => {
      if (this.selectedHeaders.length == this.headers.length) {
        this.model = [];
      } else {
        this.model = [...this.headers];
      }
      this.onChangeHeaders(this.model);
    });
  }

  @Watch("selectedHeaders")
  onUpdateSelectedHeaders() {
    this.model = this.mappedSelectedHeaders;
  }

  @Emit("onChangeHeaders")
  onChangeHeaders(val: any[]): any[] {
    return val.map((it) => it.value);
  }
}
