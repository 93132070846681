<template>
  <div>
    <h2 class="ma-4">{{ driver ? driver.name : "loading..." }}</h2>
    <DriverCoachingWidget
      class="ml-3 mr-3"
      :coaching="coaching"
      :coachingLoading="coachingLoading"
      :previousCoaching="previousCoaching"
      :previousCoachingLoading="previousCoachingLoading"
    />
    <DriverInfractionsTable :driver="driver" />
  </div>
</template>

<script>
import { API } from "aws-amplify";
import { mapState } from "vuex";
import DriverInfractionsTable from "../../components/coaching/DriverInfractionsTable.vue";
import DriverCoachingWidget from "../../components/coaching/DriverCoachingWidget.vue";
import { bodyWithAuthHeader } from "../../utils/utils";

export default {
  name: "CoachingDriverInfractions",

  routing: {
    path: "/infractions/driver/:driverId",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },

  components: { DriverInfractionsTable, DriverCoachingWidget },

  data: () => ({
    driver: undefined,
  }),

  computed: {
    ...mapState("coachingModule", [
      "autoCoachingPerDriver",
      "previousAutoCoachingPerDriver",
      "autoCoachingPerDriverLoading",
      "previousAutoCoachingPerDriverLoading",
    ]),

    targetDriverId() {
      return this.$route.params.driverId;
    },

    coaching() {
      return this.autoCoachingPerDriver[this.targetDriverId];
    },

    coachingLoading() {
      return this.autoCoachingPerDriverLoading[this.targetDriverId];
    },

    previousCoaching() {
      return this.previousAutoCoachingPerDriver[this.targetDriverId];
    },

    previousCoachingLoading() {
      return this.previousAutoCoachingPerDriverLoading[this.targetDriverId];
    },
  },

  async mounted() {
    this.$store.dispatch(
      "coachingModule/loadAutoCoachingIfNeeded",
      this.targetDriverId,
    );
    this.$store.dispatch(
      "coachingModule/loadPreviousAutoCoachingIfNeeded",
      this.targetDriverId,
    );

    this.driver = await API.get(
      "core",
      `/driver/${this.targetDriverId}`,
      await bodyWithAuthHeader(),
    );
  },
};
</script>
