<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          {{ $localizationService.localize("coaching_page.menu.btn") }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in routes" :key="index">
          <router-link :to="item.path" class="route">
            <v-list-item-title>{{
              $localizationService.localize(item.localizationKey)
            }}</v-list-item-title>
          </router-link>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CoachingInfractions from "../../views/coaching/CoachingInfractions";
import CoachingScans from "../../views/coaching/CoachingScans";
import CoachingCriteria from "../../views/coaching/CoachingCriteria";

export default {
  name: "CoachingMenu",

  data: () => ({}),

  computed: {
    ...mapState("userModule", ["user"]),

    routes() {
      if (!this.user) return [];

      return [
        CoachingInfractions.routing,
        CoachingScans.routing,
        CoachingCriteria.routing,
      ].filter((it) => it.hasAccess(this.user));
    },
  },
};
</script>

<style scoped>
.route {
  text-decoration: none;
  color: var(--themeTextColor-base);
}
</style>
