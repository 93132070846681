import { render, staticRenderFns } from "./CreateIssueActionDraftTemplateAgent.vue?vue&type=template&id=5d45c6f1&scoped=true&"
import script from "./CreateIssueActionDraftTemplateAgent.vue?vue&type=script&lang=ts&"
export * from "./CreateIssueActionDraftTemplateAgent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d45c6f1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
