<template>
  <v-container fluid :class="drawer ? 'drawer-open' : 'drawer-closed'">
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-data-table
          class="infractions-table-display"
          :class="{'not-default-page-size': !isDefaultSize}"
          :headers="headers"
          :items="displayInfractions"
          :loading="currentPageLoading || !tableInited"
          :server-items-length="totalCount"
          @update:options="paginate($event)"
          :footer-props="footerProps"
          multi-sort
          :custom-sort="sortStub"
          :item-class="getItemClass"
          :options="{
            page: pageIndex,
            itemsPerPage: pageSize,
            sortBy: sort.sortBy,
            sortDesc: sort.sortDesc,
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">
                {{
                  $localizationService.localize(
                    "coaching_page.infractions_table.title"
                  )
                }}
                <TableSettings
                  class="ml-5"
                  :pIsVisible="tableInited && !isDefaultSettings"
                  :pSaveButtonTooltip="
                    $localizationService.localize(
                      'coaching_page.infractions_table.settings_tooltip.save'
                    )
                  "
                  :pDefaultButtonTooltip="
                    $localizationService.localize(
                      'coaching_page.infractions_table.settings_tooltip.apply_default'
                    )
                  "
                  @onSaveSettings="saveSettings()"
                  @onApplyDefaultSettings="applyDefaultSettings()"
                />
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                class="search-field mr-2"
                :label="
                  $localizationService.localize(
                    'coaching_page.infractions_table.search'
                  )
                "
                hide-details
                width="200"
                v-model="searchString"
                @input="onSearchStringChange()"
              >
              </v-text-field>
              <CoachingMenu />
            </v-toolbar>
          </template>

          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <span
              :key="header.value"
              :class="{
                'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
              }"
            >
              {{ header.text }}
            </span>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ momentDateAndTime(item.createdAt) }}
          </template>

          <template v-slot:[`item.date`]="{ item }">
            {{ getInfractionDateView(item) }}
          </template>

          <template v-slot:[`item.driver`]="{ item }">
            <span class="drivers-list-string">
              <template v-for="driver in getInfractionDriverView(item)">
                <router-link
                  class="driver-name"
                  :key="`link-${driver.id}`"
                  :to="`/infractions/driver/${driver.id}`"
                  >{{ driver.name }}</router-link
                >
                <span
                  :key="`spacer-${driver.id}`"
                  class="driver-names-delimeter"
                  >,
                </span>
              </template>
            </span>
          </template>

          <template v-slot:[`item.vehicle`]="{ item }">
            {{ getInfractionVehicleView(item) }}
          </template>

          <template v-slot:[`item.criteria`]="{ item }">
            {{ item.criteria.name }}
          </template>

          <template v-slot:[`item.detectedValue`]="{ item }">
            {{ item.detectedValue }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div v-if="item.status == 'RESOLVED'" class="green--text">
              {{
                $localizationService.localize(
                  "coaching_page.infractions_table.status.resolved"
                )
              }}
              <v-icon size="1.25em" color="green" class="ml-1 mb-1"
                >mdi-check</v-icon
              >
            </div>
            <div v-else-if="item.status == 'NEW'" class="red--text">
              {{
                $localizationService.localize(
                  "coaching_page.infractions_table.status.new"
                )
              }}
              <v-icon size="1.25em" color="red" class="ml-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
            </div>
            <div
              v-else-if="item.status == 'COACHING_ASSIGNED'"
              class="primary--text"
            >
              {{
                $localizationService.localize(
                  "coaching_page.infractions_table.status.coaching_assigned"
                )
              }}
            </div>
            <div v-else>{{ item.status }}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import debounce from "lodash/debounce";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import lodash from "lodash";
import {
  dateMomentFormat,
  dateTimeMomentFormat,
} from "../../utils/utils";
import tableSortParserMixin from "../../utils/table-sort-parser";

const locService = Vue.prototype.$localizationService;

export default {
  name: "InfractionsTable",

  mixins: [tableSortParserMixin],

  components: {
    CoachingMenu: () => import("./CoachingMenu.vue"),
    TableSettings: () => import("@/components/TableSettings.vue"),
  },

  data: () => ({
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    searchString: "",
  }),

  computed: {
    ...mapState("infractionModule", [
      "currentPageLoading",
      "currentPage",
      "displayInfractions",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),

    ...mapState("infractionsTableSettingsModule", [
      "pageIndex",
      "pageSize",
      "sort",
      "defaultSettings",
    ]),
    ...mapGetters("infractionsTableSettingsModule", [
      "isDefaultSettings",
      "isDefaultSort",
      "isDefaultSize",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultSort
        ? this.getNotDefaultTableSortItems("sort")
        : [];
    },

    headers: () => [
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.target_date",
        ),
        value: "date",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.driver",
        ),
        value: "driver",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.vehicle",
        ),
        value: "vehicle",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.criteria",
        ),
        value: "criteria",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.detected_value",
        ),
        value: "detectedValue",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.status",
        ),
        value: "status",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.infractions_table.header.detected_at",
        ),
        value: "createdAt",
        sortable: true,
        align: "start",
      },
    ],
  },

  methods: {
    async paginate(data) {
      this.$store.commit("infractionsTableSettingsModule/changeSettings", {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      });

      this.updateRoutePath();

      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
        searchString: this.searchString,
        sort: data.sortBy.map((it, i) => (data.sortDesc[i] ? `-${it}` : it)),
      };

      this.loadPage(currentPage);
    },

    loadPage: lodash.debounce(async function (currentPage) {
      await this.$store.dispatch("infractionModule/loadPage", currentPage);
      this.tableInited = true;
    }, 500),

    saveSettings() {
      this.$store.commit("infractionsTableSettingsModule/saveSettings");

      Vue.toasted.success(
        "Settings have been saved!",
        { duration: 3000 },
      );
    },

    applyDefaultSettings() {
      this.$store.commit("infractionsTableSettingsModule/applyDefaultSettings");
    },

    updateRoutePath() {
      const params = new URLSearchParams();

      params.append("pageSize", this.pageSize);

      params.append("pageIndex", this.pageIndex);

      params.append("sort", JSON.stringify(this.sort));

      if (this.searchString) {
        params.append("searchString", this.searchString);
      }

      if (
        this.$route.query.pageSize != (params.get("pageSize") || undefined)
        || this.$route.query.pageIndex != (params.get("pageIndex") || undefined)
        || this.$route.query.searchString != (params.get("searchString") || undefined)
        || this.$route.query.sort != (params.get("sort") || undefined)
      ) {
        this.$router.push(`/infractions/?${params.toString()}`);
      }
    },

    getItemClass(infraction) {
      if (infraction.status == "NEW") return "new-infraction-table-item";
      if (infraction.status == "COACHING_ASSIGNED") { return "coaching-assigned-infraction-table-item"; }
      if (infraction.status == "RESOLVED") { return "resolved-infraction-table-item"; }
      return undefined;
    },

    sortStub(items) {
      return items;
    },

    // TODO: refactor
    onSearchStringChange: debounce(function () {
      const newPage = { ...this.currentPage };

      newPage.searchString = this.searchString;

      this.$store.dispatch("infractionModule/loadPage", newPage);
      this.updateRoutePath();
    }, 300),

    momentDateAndTime(date) {
      return date ? moment(date).format(dateTimeMomentFormat) : "";
    },

    momentDate(date) {
      return date ? moment(date).format(dateMomentFormat) : "";
    },

    getInfractionDateView(item) {
      const circumstance = item.circumstances.find(
        (it) => it.type == "DateCircumstance",
      );
      return circumstance ? this.momentDate(circumstance.date) : "-";
    },

    getInfractionDriverView(item) {
      return item.circumstances
        .filter((it) => it.type == "DriverCircumstance")
        .map((it) => it.driver)
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    getInfractionVehicleView(item) {
      return (
        item.circumstances
          .filter((it) => it.type == "UsedVehicleCircumstance")
          .map((it) => it.vehicle.name || it.vehicle.vin)
          .sort((a, b) => a.localeCompare(b))
          .join(", ") || "-"
      );
    },
  },
  created() {
    this.$store.dispatch("infractionsTableSettingsModule/loadDefaultSettingsFromStorage");

    const query = this.$route.query || {};

    if (!Object.keys(query).length) {
      this.$store.commit("infractionsTableSettingsModule/applyDefaultSettings");
    } else {
      this.$store.commit("infractionsTableSettingsModule/applyQuerySettings", query);

      if (query.searchString) {
        this.searchString = query.searchString;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.search-field {
  max-width: 250px;
}
.drivers-list-string {
  .driver-name {
    color: inherit !important;
    text-decoration: underline;
  }

  .driver-names-delimeter:last-child {
    display: none !important;
  }
}
</style>
