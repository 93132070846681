/* eslint-disable max-len */
import moment from "moment";
import Vue from "vue";
import lodash from "lodash";
import { User } from "../model/user.model";
import { dateTimeParse } from "./date-parser";
import * as enUsPack from "../assets/langs/en-us.json";
import LocalizationService from "../services/localization.service";

export const ROCKET_CHAT_API = process.env.VUE_APP_ROCKET_CHAT_ENDPOINT || "https://chat.demo.dsps.softwea.com";

export const MessagingTargets = {
  user(id) {
    return `/messaging/direct/${id}`;
  },
  driver(id) {
    return `/messaging/direct/driver/${id}`;
  },
  device(id) {
    return `/messaging/direct/device/${id}`;
  },
};

export const REQUEST_CANCEL_MESSAGE = "The request is deprecated.";

export const localizationService = new LocalizationService();
localizationService.initLanguage(enUsPack.default);
Vue.prototype.$localizationService = localizationService;

export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);
export const DRP_ROUTES_TAB = "ROUTES";
export const DRP_DRIVERS_TAB = "DRIVERS";
export const DRP_RESCUE_TAB = "RESCUE";

export const DRP_IMPORT_AMAZON_LOGISTICS = "IMPORT_AMAZON_LOGISTICS";
export const DRP_IMPORT_ROUTE_STAGING = "IMPORT_ROUTE_STAGING";

export const RTL_TEMPLATE_TYPE = "RawTextLinesTemplate";
export const XLSX_TEMPLATE_TYPE = "XlsxTemplate";

export const AMAZON_LOGISTICS_IMPORT_MAPPING_TYPE = "AmazonLogisticsImportMappings";
export const ROUTE_STAGING_IMPORT_MAPPING_TYPE = "RouteStagingImportMappings";

export const USERS_TAB = "USERS";
export const USER_INVITATIONS_TAB = "USER_INVITATIONS";

export const dismissAction = {
  action: {
    text: "Dismiss",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

export const handleHttpError = (e) => {
  let message = e.response ? e.response.data.message : e;

  if ((e.response || {}).status == 409) {
    message = "Changes are not saved because your page is out of sync with server. Please reload the data.";
  }

  if ((e.response || {}).status == 403) {
    message = "Error 403 Forbidden";
  }

  Vue.toasted.error(message, { ...dismissAction });
};

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
export const CONSTANT_NAME_REGEX = /^[A-Za-z0-9_-]+$/;

export const bodyWithAuthHeader = async () => ({
  headers: {
    Authorization: await User.getToken(),
  },
});

export const dateTimeMomentFormat = "MMM Do YY HH:mm:ss";
export const dateTimeShortMomentFormat = "MMM Do YY HH:mm";
export const dateMomentFormat = "MMM Do YY";

export const MATCH_TO_EXISTING_DRIVER = {
  text: localizationService.localize("driver_selection_request.action.match_to_existing"),
  value: "MATCH_TO_EXISTING_DRIVER",
  isMatchToExistingDriver: true,
};
export const IGNORE_THIS_DRIVER = {
  text: localizationService.localize("driver_selection_request.action.ignore"),
  value: "IGNORE_THIS_DRIVER",
  isIgnoreThisDriver: true,
};
export const ACCEPT_AUTO_MATCH = {
  text: localizationService.localize("driver_selection_request.action.accept_auto"),
  value: "ACCEPT_AUTO_MATCH",
  isAcceptAutoMatched: true,
};
export const CREATE_NEW_DRIVER = {
  text: localizationService.localize("driver_selection_request.action.create_new"),
  value: "CREATE_NEW_DRIVER",
  isCreateNewDriver: true,
};

export const MATCH_TO_EXISTING_VEHICLE = {
  text: localizationService.localize("vehicle_selection_request.action.match_to_existing"),
  value: "MATCH_TO_EXISTING_VEHICLE",
  isMatchToExistingVehicle: true,
};
export const IGNORE_THIS_VEHICLE = {
  text: localizationService.localize("vehicle_selection_request.action.ignore"),
  value: "IGNORE_THIS_VEHICLE",
  isIgnoreThisVehicle: true,
};
export const CREATE_NEW_VEHICLE = {
  text: localizationService.localize("vehicle_selection_request.action.create_new"),
  value: "",
};

export const defaultDateFormat = "YYYY-MM-DD";
export const dateFormatUnixMillisecondTimestamp = "x";
export const localStorageDates = "Saved dates";

export const REPORT_TYPE_DA_DAILY = {
  text: localizationService.localize("report.da_daily"),
  value: "DA Daily",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_SERVICE_DETAILS_DAILY = {
  text: localizationService.localize("report.service_details_daily"),
  value: "Service Details Daily",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_DA_PERFORMANCE = {
  text: localizationService.localize("report.da_performance"),
  value: "DA Performance",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_SHIFT_DAILY = {
  text: localizationService.localize("report.shift_daily"),
  value: "Shift Daily",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_CUSTOM_FEEDBACK_WEEKLY = {
  text: localizationService.localize("report.customer_feedback_weekly"),
  value: "Customer Feedback Weekly",
  getStartDate: (targetDate) => moment(targetDate).day(0),
};
export const REPORT_TYPE_CUSTOM_POSITIVE_WEEKLY_FEEDBACK = {
  text: localizationService.localize("report.customer_positive_weekly_feedback"),
  value: "Customer Positive Weekly Feedback",
  getStartDate: (targetDate) => moment(targetDate).day(0),
};
export const REPORT_TYPE_CUSTOM_POSITIVE_DAILY_FEEDBACK = {
  text: localizationService.localize("report.customer_positive_daily_feedback"),
  value: "Customer Positive Daily Feedback",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_CUSTOM_NEGATIVE_WEEKLY_FEEDBACK = {
  text: localizationService.localize("report.customer_negative_weekly_feedback"),
  value: "Customer Negative Weekly Feedback",
  getStartDate: (targetDate) => moment(targetDate).day(0),
};
export const REPORT_TYPE_CUSTOM_NEGATIVE_DAILY_FEEDBACK = {
  text: localizationService.localize("report.customer_negative_daily_feedback"),
  value: "Customer Negative Daily Feedback",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_FICO_DAILY = {
  text: localizationService.localize("report.fico_daily"),
  value: "Fico Daily",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_FICO_WEEKLY = {
  text: localizationService.localize("report.fico_weekly"),
  value: "Fico Weekly",
  getStartDate: (targetDate) => moment(targetDate).day(0),
};
export const REPORT_TYPE_SAFETY_DAILY = {
  text: localizationService.localize("report.safety_daily"),
  value: "Safety Daily",
  getStartDate: (targetDate) => moment(targetDate),
};
export const REPORT_TYPE_SAFETY_WEEKLY = {
  text: localizationService.localize("report.safety_weekly"),
  value: "Safety Weekly",
  getStartDate: (targetDate) => moment(targetDate).day(0),
};
export const REPORT_TYPE_NETRADYNE_ALERTS = {
  text: localizationService.localize("report.netradyne_alerts"),
  value: "Netradyne Alerts",
  getStartDate: (targetDate) => moment(targetDate),
};

export const REPORT_TYPES = [
  REPORT_TYPE_DA_DAILY,
  REPORT_TYPE_SERVICE_DETAILS_DAILY,
  REPORT_TYPE_DA_PERFORMANCE,
  REPORT_TYPE_SHIFT_DAILY,
  REPORT_TYPE_CUSTOM_FEEDBACK_WEEKLY,
  REPORT_TYPE_NETRADYNE_ALERTS,
  REPORT_TYPE_CUSTOM_POSITIVE_WEEKLY_FEEDBACK,
  REPORT_TYPE_CUSTOM_POSITIVE_DAILY_FEEDBACK,
  REPORT_TYPE_CUSTOM_NEGATIVE_WEEKLY_FEEDBACK,
  REPORT_TYPE_CUSTOM_NEGATIVE_DAILY_FEEDBACK,
  REPORT_TYPE_FICO_DAILY,
  REPORT_TYPE_FICO_WEEKLY,
  REPORT_TYPE_SAFETY_DAILY,
  REPORT_TYPE_SAFETY_WEEKLY,
];
export const REPORT_TYPES_BY_VALUE = lodash.keyBy(REPORT_TYPES, "value");

export const CUMULATIVE_REPORTS = [
  REPORT_TYPE_SERVICE_DETAILS_DAILY.value,
  REPORT_TYPE_NETRADYNE_ALERTS.value,
];

export const VEHICLE_TYPE_BRANDED = {
  text: localizationService.localize("vehicle_utils.type.branded"),
  value: "BRANDED",
};
export const VEHICLE_TYPE_OWNED = {
  text: localizationService.localize("vehicle_utils.type.owned"),
  value: "OWNED",
};
export const VEHICLE_TYPE_RENTAL = {
  text: localizationService.localize("vehicle_utils.type.rental"),
  value: "RENTAL",
};
export const VEHICLE_TYPE_NON_BRANDED_LEASED = {
  text: localizationService.localize("vehicle_utils.type.non_bounded_leased"),
  value: "NON_BRANDED_LEASED",
};

export const VEHICLE_TYPES = [
  VEHICLE_TYPE_BRANDED,
  VEHICLE_TYPE_OWNED,
  VEHICLE_TYPE_RENTAL,
  VEHICLE_TYPE_NON_BRANDED_LEASED,
];

export const VEHICLE_STATUS_ACTIVE = {
  text: localizationService.localize("vehicle_utils.status.active"),
  value: "ACTIVE",
  color: "green--text",
};
export const VEHICLE_STATUS_INACTIVE = {
  text: localizationService.localize("vehicle_utils.status.inactive"),
  value: "INACTIVE",
  color: "red--text",
};
export const VEHICLE_STATUS_GROUNDED = {
  text: localizationService.localize("vehicle_utils.status.grounded"),
  value: "GROUNDED",
  color: "red--text",
};
export const VEHICLE_STATUS_OUT_OF_SERVICE_PREVENTIVE_MAINTENANCE = {
  text: localizationService.localize("vehicle_utils.status.out_of_service.preventive_maintenance"),
  value: "OUT_OF_SERVICE_PREVENTIVE_MAINTENANCE",
  color: "red--text",
};
export const VEHICLE_STATUS_OUT_OF_SERVICE_BODY_SHOP = {
  text: localizationService.localize("vehicle_utils.status.out_of_service.body_shop"),
  value: "OUT_OF_SERVICE_BODY_SHOP",
  color: "red--text",
};
export const VEHICLE_STATUS_OUT_OF_SERVICE_MECHANIC_SHOP = {
  text: localizationService.localize("vehicle_utils.status.out_of_service.mechanic_shop"),
  value: "OUT_OF_SERVICE_MECHANIC_SHOP",
  color: "red--text",
};

export const VEHICLE_OUT_OF_SERVICE_STATUSES = [
  VEHICLE_STATUS_OUT_OF_SERVICE_PREVENTIVE_MAINTENANCE,
  VEHICLE_STATUS_OUT_OF_SERVICE_BODY_SHOP,
  VEHICLE_STATUS_OUT_OF_SERVICE_MECHANIC_SHOP,
];

export const VEHICLE_STATUSES = [
  VEHICLE_STATUS_ACTIVE,
  VEHICLE_STATUS_INACTIVE,
  VEHICLE_STATUS_GROUNDED,
  ...VEHICLE_OUT_OF_SERVICE_STATUSES,
];

export const VEHICLE_FLEET_MANAGEMENT_COMPANY_ELEMENT = {
  text: localizationService.localize("vehicle_utils.fleet_management_company.element"),
  value: "ELEMENT",
};
export const VEHICLE_FLEET_MANAGEMENT_COMPANY_LEASE_PLAN = {
  text: localizationService.localize("vehicle_utils.fleet_management_company.lease_plan"),
  value: "LEASE_PLAN",
};
export const VEHICLE_FLEET_MANAGEMENT_COMPANY = [
  VEHICLE_FLEET_MANAGEMENT_COMPANY_ELEMENT,
  VEHICLE_FLEET_MANAGEMENT_COMPANY_LEASE_PLAN,
];

export const fixMode = "fix";
export const viewMode = "view";
export const editMode = "edit";

export const REPORT_PROCESS_STATUS_CLASSIFIED = {
  value: "CLASSIFIED",
};
export const REPORT_PROCESS_STATUS_RECEIVED = {
  value: "RECEIVED",
};

export const REPORT_STATUS_ALREADY_EXIST = {
  text: localizationService.localize("report_utils.status.already_exists"),
  value: "ALREADY_EXIST",
};
export const REPORT_STATUS_TYPE_DETECTION_FAILED = {
  text: localizationService.localize("report_utils.status.type_detection_failed"),
  value: "TYPE_DETECTION_FAILED",
};
export const REPORT_STATUS_DATA_NOT_FOUND = {
  text: localizationService.localize("report_utils.status.data_not_found"),
  value: "DATA_NOT_FOUND",
};
export const REPORT_STATUS_DATA_RECOGNITION_FAILED = {
  text: localizationService.localize("report_utils.status.data_recognition_failed"),
  value: "DATA_RECOGNITION_FAILED",
};
export const REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING = {
  text: localizationService.localize("report_utils.status.system_error_during_parsing"),
  value: "SYSTEM_ERROR_DURING_PARSING",
};
export const REPORT_STATUS_MANUAL_ACTION_REQUIRED = {
  text: localizationService.localize("report_utils.status.manual_action_required"),
  value: "MANUAL_ACTION_REQUIRED",
};
export const REPORT_STATUS_PARSED = {
  text: localizationService.localize("report_utils.status.parsed"),
  value: "PARSED",
};
export const REPORT_STATUS_DATE_UNAVAILABLE = {
  text: localizationService.localize("report_utils.status.date_unavailable"),
  value: "DATE_UNAVAILABLE",
};
export const REPORT_STATUS_NOT_SUPPORTED_ANYMORE = {
  text: localizationService.localize("report_utils.status.not_supported_anymore"),
  value: "NOT_SUPPORTED_ANYMORE",
};

export const REPORT_STATUSES = [
  REPORT_STATUS_ALREADY_EXIST,
  REPORT_STATUS_TYPE_DETECTION_FAILED,
  REPORT_STATUS_DATA_NOT_FOUND,
  REPORT_STATUS_DATA_RECOGNITION_FAILED,
  REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING,
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  REPORT_STATUS_PARSED,
  REPORT_STATUS_DATE_UNAVAILABLE,
  REPORT_STATUS_NOT_SUPPORTED_ANYMORE,
];

export const REPORT_STATUSES_BY_VALUE = lodash.keyBy(REPORT_STATUSES, "value");

export const setLocalStorageData = (key, data) => {
  localStorage.setItem(key, data);
};
export const getLocalStorageData = (key) => {
  const data = localStorage.getItem(key);
  return data ? data.split(",") : undefined;
};

const createDateEnd = (dates, { dateTime, unit }) => (dates[0] == dates[1] ? moment(dateTime).endOf(unit) : moment());

const dateParser = (dateRange) => {
  const newDates = [...dateRange];
  const dataFromParser = dateTimeParse(newDates[0]);
  return [dataFromParser.dateTime, createDateEnd(dateRange, dataFromParser)];
};

export const getLocalStorageDateForCalendar = (key) => {
  const data = localStorage.getItem(key);
  if (data) {
    const formattedDates = data
      .split(",")
      .map((date) => moment(+date).utc().format(defaultDateFormat));

    if (formattedDates.includes("Invalid date")) {
      return dateParser(data.split(","))
        .map((date) => date.utc().format(defaultDateFormat));
    }
    return formattedDates;
  }
  return undefined;
};

export const paginateTable = (data, localStoragePage, tableInited) => {
  if (tableInited) setLocalStorageData(localStoragePage, data.itemsPerPage);

  return tableInited ? data.itemsPerPage : +(getLocalStorageData(localStoragePage) || [25])[0];
};
export const DRP_ROW_STATION_COLORS = [
  "pink--text text--lighten-1",
  "purple--text text--lighten-1",
  "indigo--text text--lighten-1",
  "blue--text text--lighten-1",
  "cyan--text text--lighten-1",
  "teal--text text--lighten-1",
  "purple--text text--lighten-1",
];

export const getStationClass = (station) => {
  const stationCode = station
    ? station.split("")
      .reduce(
        (accumulator, current, index) => accumulator + current.charCodeAt(0) * (index + 1),
        0,
      )
    : 0;

  return DRP_ROW_STATION_COLORS[stationCode % DRP_ROW_STATION_COLORS.length];
};

export const ENTRY_STATUS_CONFIRMED = {
  value: "CONFIRMED",
  text: localizationService.localize("entry.status.confirmed"),
  color: "green--text",
};
export const ENTRY_STATUS_NO_RESPONSE = {
  value: "NO_RESPONSE",
  text: localizationService.localize("entry.status.no_response"),
  color: "red--text",
};
export const ENTRY_STATUS_LATE = {
  value: "LATE",
  text: localizationService.localize("entry.status.late"),
  color: "red--text",
};
export const ENTRY_STATUS_CALL_OUT = {
  value: "CALL_OUT",
  text: localizationService.localize("entry.status.call_out"),
  color: "red--text",
};
export const DRP_ENTRY_STATUSES = {
  [ENTRY_STATUS_CONFIRMED.value]: ENTRY_STATUS_CONFIRMED,
  [ENTRY_STATUS_NO_RESPONSE.value]: ENTRY_STATUS_NO_RESPONSE,
  [ENTRY_STATUS_LATE.value]: ENTRY_STATUS_LATE,
  [ENTRY_STATUS_CALL_OUT.value]: ENTRY_STATUS_CALL_OUT,
};

export const RESCUE_STATUS_PLANNED = {
  text: localizationService.localize("rescue_display_type.planned"),
  value: "PLANNED",
  color: "green--text",
};
export const RESCUE_STATUS_COMPLETED = {
  text: localizationService.localize("rescue_display_type.completed"),
  value: "COMPLETED",
  color: "green--text",
};
export const RESCUE_STATUS_REFUSED = {
  text: localizationService.localize("rescue_display_type.refused"),
  value: "REFUSED",
  color: "red--text",
};
export const RESCUE_STATUS_CANCELLED = {
  text: localizationService.localize("rescue_display_type.cancelled"),
  value: "CANCELLED",
  color: "red--text",
};
export const DRP_RESCUE_STATUSES = lodash.keyBy([
  RESCUE_STATUS_PLANNED,
  RESCUE_STATUS_COMPLETED,
  RESCUE_STATUS_REFUSED,
  RESCUE_STATUS_CANCELLED,
], "value");

export const momentDateAndTime = (date) => (date ? moment(date).format(dateTimeMomentFormat) : "");

export const momentDate = (date) => (date ? moment(date).format(dateMomentFormat) : "");

export const COACHING_STATUS_NEW = { value: "NEW" };
export const COACHING_STATUS_RESOLVED = { value: "RESOLVED" };

export const COACHING_STATUSES = {

  [COACHING_STATUS_NEW.value]: COACHING_STATUS_NEW,
  [COACHING_STATUS_RESOLVED.value]: COACHING_STATUS_RESOLVED,
};

export const INFRACTION_CIRCUMSTANCE_DATE = { value: "DateCircumstance" };
export const INFRACTION_CIRCUMSTANCE_DRIVER = { value: "DriverCircumstance" };
export const INFRACTION_CIRCUMSTANCE_USED_VEHICLE = { value: "UsedVehicleCircumstance" };

export const INFRACTION_CIRCUMSTANCES = {

  [INFRACTION_CIRCUMSTANCE_DATE.value]: INFRACTION_CIRCUMSTANCE_DATE,
  [INFRACTION_CIRCUMSTANCE_DRIVER.value]: INFRACTION_CIRCUMSTANCE_DRIVER,
  [INFRACTION_CIRCUMSTANCE_USED_VEHICLE.value]: INFRACTION_CIRCUMSTANCE_USED_VEHICLE,
};

export const OPENED_RESCUES = "RESCUES";
export const OPENED_HISTORY = "HISTORY";

export const USER_TYPE_ADMIN = {
  text: localizationService.localize("user_display_type.admin"),
  value: "ADMIN",
  getStatus: (companyName) => (
    localizationService.localize("store.user.display_user_status.admin", [companyName])
  ),
};
export const USER_TYPE_MANAGER = {
  text: localizationService.localize("user_display_type.manager"),
  value: "MANAGER",
  getStatus: (companyName) => (
    localizationService.localize("store.user.display_user_status.manager", [companyName])
  ),
};
export const USER_TYPE_DRIVER = {
  text: localizationService.localize("user_display_type.driver"),
  value: "DRIVER",
  getStatus: (companyName) => (
    localizationService.localize("store.user.display_user_status.driver", [companyName])
  ),
};

export const USER_TYPES = [USER_TYPE_ADMIN, USER_TYPE_MANAGER, USER_TYPE_DRIVER];

export const USER_TYPES_BY_VALUE = lodash.keyBy(USER_TYPES, "value");

export const PERSONAL = {
  text: localizationService.localize("rocket_chat.settings.messaging_behaviour.personal"),
  value: "PERSONAL",
};
export const CAD = {
  text: localizationService.localize("rocket_chat.settings.messaging_behaviour.cad"),
  value: "CAD",
};
export const CUSTOM = {
  text: null,
  value: "CUSTOM",
};
export const DEFAULT = {
  text: localizationService.localize("rocket_chat.settings.messaging_behaviour.default"),
  value: "DEFAULT",
};

export const GROUP_TYPE_MANUAL = {
  text: localizationService.localize("vehicle_group_page.dialog.add_and_edit.param.types.manual"),
  value: "ManualVehicleGroup",
};
export const GROUP_TYPE_CRITERIA = {
  text: localizationService.localize("vehicle_group_page.dialog.add_and_edit.param.types.criteria"),
  value: "CriteriaVehicleGroup",
};
export const GROUP_TYPE_GROUPING = {
  text: localizationService.localize("vehicle_group_page.dialog.add_and_edit.param.types.grouping"),
  value: "GroupingVehicleGroup",
};

export const GROUP_TYPES = [GROUP_TYPE_MANUAL, GROUP_TYPE_CRITERIA, GROUP_TYPE_GROUPING];

export const GROUP_TYPES_BY_VALUE = lodash.keyBy(GROUP_TYPES, "value");

const cognitoKeys = Object.keys(localStorage).filter((it) => it.startsWith("CognitoIdentityServiceProvider"));
const lastAuthUser = localStorage.getItem(cognitoKeys.find((it) => it.endsWith("LastAuthUser")));
const originalLastAuthUser = localStorage.getItem("original_cognito_last_auth_user");
export const inSheepsClothing = lastAuthUser && originalLastAuthUser && lastAuthUser != originalLastAuthUser;

export const INSPECTION_ODOMETER_ITEM = "InspectionOdometerItem";
export const INSPECTION_FAIL_PASS_ITEM = "InspectionFailPassItem";
export const INSPECTION_NUMBER_VALUE_ITEM = "InspectionNumberValueItem";

export const INPSECTION_ITEM_TYPES = [
  INSPECTION_ODOMETER_ITEM,
  INSPECTION_FAIL_PASS_ITEM,
  INSPECTION_NUMBER_VALUE_ITEM,
];

export const INSPECTION_FAIL_PASS_PASS = {
  text: localizationService.localize("vehicle_inspection_page.values.pass"),
  value: "PASS",
};
export const INSPECTION_FAIL_PASS_FAIL = {
  text: localizationService.localize("vehicle_inspection_page.values.fail"),
  value: "FAIL",
};

export const INSPECTION_FAIL_PASS_NONE = {
  text: localizationService.localize("vehicle_inspection_page.values.none"),
  value: "",
};
export const INSPECTIONS_FAIL_PASS_VALUES = [INSPECTION_FAIL_PASS_NONE, INSPECTION_FAIL_PASS_PASS, INSPECTION_FAIL_PASS_FAIL];

export const randomMoment = (startMoment, endMoment = moment()) => moment(startMoment + Math.random() * (endMoment - startMoment));
