<template>
  <v-row align="start" justify="start">
    <v-col cols="12">
      <v-data-table
        class="resolved-coaching-infractions-table-display"
        :headers="headers"
        :items="displayInfractions"
        :loading="coachingLoading"
        @update:options="paginate($event)"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{
                $localizationService.localize(
                  "coaching_page.resolved_coaching_infractions_table.title"
                )
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              class="search-field"
              :label="
                $localizationService.localize(
                  'coaching_page.resolved_coaching_infractions_table.search'
                )
              "
              hide-details
              width="200"
              v-model="searchString"
            >
            </v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ momentDateAndTime(item.createdAt) }}
        </template>

        <template v-slot:[`item.targetDate`]="{ item }">
          {{ momentDate(item.targetDate.date) }}
        </template>

        <template v-slot:[`item.drivers`]="{ item }">
          <span class="drivers-list-string">
            <template v-for="driver in getInfractionDriverView(item.drivers)">
              <router-link
                class="driver-name"
                :key="`link-${driver.id}`"
                :to="`/infractions/driver/${driver.id}`"
                >{{ driver.name }}</router-link
              >
              <span :key="`spacer-${driver.id}`" class="driver-names-delimeter"
                >,
              </span>
            </template>
          </span>
        </template>

        <template v-slot:[`item.vehicles`]="{ item }">
          {{ getInfractionVehicleView(item.vehicles) }}
        </template>

        <template v-slot:[`item.criteria`]="{ item }">
          {{ item.criteria.name }}
        </template>

        <template v-slot:[`item.detectedValue`]="{ item }">
          {{ item.detectedValue }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import {
  paginateTable,
  momentDate,
  momentDateAndTime,
  INFRACTION_CIRCUMSTANCE_DATE,
  INFRACTION_CIRCUMSTANCE_DRIVER,
  INFRACTION_CIRCUMSTANCE_USED_VEHICLE,
} from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "ResolvedCoachingInfractionsTable",

  props: {
    coaching: Object,
    coachingLoading: Boolean,
  },

  data: () => ({
    localStorageTable: "resolved-coaching-infractions.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    searchString: "",
  }),

  computed: {
    headers: () => [
      {
        text: locService.localize(
          "coaching_page.resolved_coaching_infractions_table.header.target_date",
        ),
        value: "targetDate",
        sortable: true,
        align: "start",
        sort: (a, b) => {
          if (!a) return -1;
          if (!b) return 1;

          return moment(a.date).valueOf() - moment(b.date).valueOf();
        },
      },
      {
        text: locService.localize(
          "coaching_page.resolved_coaching_infractions_table.header.drivers",
        ),
        value: "drivers",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.resolved_coaching_infractions_table.header.vehicles",
        ),
        value: "vehicles",
        sortable: false,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.resolved_coaching_infractions_table.header.criteria",
        ),
        value: "criteria",
        sortable: true,
        align: "start",
        sort: (a, b) => a.name.localeCompare(b.name),
      },
      {
        text: locService.localize(
          "coaching_page.resolved_coaching_infractions_table.header.detected_value",
        ),
        value: "detectedValue",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.resolved_coaching_infractions_table.header.detected_at",
        ),
        value: "createdAt",
        sortable: true,
        align: "start",
        sort: (a, b) => moment(a).valueOf() - moment(b).valueOf(),
      },
    ],

    mappedItems() {
      return this.coaching
        ? this.coaching.targetInfractions.map((infraction) => ({
          targetDate: infraction.circumstances.find(
            (it) => it.type == INFRACTION_CIRCUMSTANCE_DATE.value,
          ),
          drivers: infraction.circumstances.filter(
            (it) => it.type == INFRACTION_CIRCUMSTANCE_DRIVER.value,
          ),
          vehicles: infraction.circumstances.filter(
            (it) => it.type == INFRACTION_CIRCUMSTANCE_USED_VEHICLE.value,
          ),
          criteria: infraction.criteria,
          detectedValue: infraction.detectedValue,
          createdAt: infraction.createdAt,
        }))
        : [];
    },

    displayInfractions() {
      const ss = this.searchString.toLowerCase();

      return this.mappedItems.filter(
        (it) => this.momentDateAndTime(it.targetDate.date)
          .toLowerCase()
          .includes(ss)
          || this.getInfractionDriverView(it.drivers)
            .map((item) => item.name)
            .join(" ")
            .toLowerCase()
            .includes(ss)
          || this.getInfractionVehicleView(it.vehicles)
            .toLowerCase()
            .includes(ss)
          || it.criteria.name.toLowerCase().includes(ss)
          || it.detectedValue.toLowerCase().includes(ss)
          || this.momentDateAndTime(it.createdAt).toLowerCase().includes(ss),
      );
    },
  },

  methods: {
    momentDateAndTime,
    momentDate,

    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTable,
        this.tableInited,
      );

      this.tableInited = true;
    },

    getInfractionDriverView(drivers) {
      return drivers
        .map((it) => it.driver)
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    getInfractionVehicleView(vehicles) {
      return (
        vehicles
          .map((it) => it.vehicle.name || it.vehicle.vin)
          .sort((a, b) => a.localeCompare(b))
          .join(", ") || "-"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.search-field {
  max-width: 250px;
}
.drivers-list-string {
  .driver-name {
    color: inherit !important;
    text-decoration: underline;
  }

  .driver-names-delimeter:last-child {
    display: none !important;
  }
}
</style>
