import { SimpleUserInfo } from "@/model/user-info.model";
import { VehicleInspectionAssignment } from "@/model/inspection/vehicle-inspection-assigment.model";

type InspectionItemScheme = {
  name: string;
  myOrder: number;
}

type InspectionItem = {
    id: string;
    answer: any;
    attachments: any[];
    comment: string | undefined;
    scheme: InspectionItemScheme;
    type: string;
}

export class VehicleInspection {
    id: string;

    assignment: VehicleInspectionAssignment;

    inspector: SimpleUserInfo;

    startDate: Date;

    endDate: Date;

    items: InspectionItem[] = [];

    constructor(
      id: string,
      assignment: VehicleInspectionAssignment,
      inspector: SimpleUserInfo,
      startDate: Date,
      endDate: Date,
      items: InspectionItem[],
    ) {
      this.id = id;
      this.assignment = assignment;
      this.inspector = inspector;
      this.startDate = startDate;
      this.endDate = endDate;
      this.items = items;
    }

    static fromDto(dto: any) {
      return new VehicleInspection(
        dto.id,
        VehicleInspectionAssignment.fromDto(dto.assignment),
        SimpleUserInfo.fromDto(dto.inspector),
        dto.startDate,
        dto.endDate,
        dto.items as InspectionItem[],
      );
    }
}
