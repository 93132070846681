<template>
  <v-btn
    v-if="!targetVehicle && state == 'view'"
    @click="onClick()"
    x-small
    class="theme-text-color"
    color="secondary"
  >
    {{ $localizationService.localize("drp.vehicle_label.set_btn") }}
  </v-btn>
  <div v-else-if="state == 'view'" @click="onClick()" :class="vehicleClass">
    <v-tooltip open-delay="500" bottom :disabled="!isDuplicate">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="d-flex align-center vehicle-name">
          <v-tooltip :open-delay="targetVehicle.fuelCard ? 0 : 500" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click.stop="openVinQrDialog(targetVehicle)"
                :class="highlightClass"
              >
                <v-icon>mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <div>
              <span v-if="targetVehicle.fuelCard">{{
                $localizationService.localize("drp.vehicle_label.fuel_card_id", [targetVehicle.fuelCard.cardId])
              }}</span>
              <div>
                {{$localizationService.localize("drp.vehicle_label.click_to_show_qr")}}
              </div>
            </div>
          </v-tooltip>

          <span class="ml-2">{{ targetVehicle.name || targetVehicle.vin }}</span>
          <span class="ml-2" v-if="printingDrpMode && targetVehicle.fuelCard"> {{
            $localizationService.localize("drp.vehicle_label.fuel_card_id", [targetVehicle.fuelCard.cardId])
          }} </span>
        </div>
        <QrDialog pIsVisibleFullData isVehicle="true" ref="vinQrDialog" />
      </template>
      <span>{{
        $localizationService.localize("drp.value_already_in_use")
      }}</span>
    </v-tooltip>
  </div>
  <div v-else class="vehicle-select d-flex flex-row align-center">
    <VehicleTargetSelection
      class="pa-0 ma-0"
      :label="$localizationService.localize('drp.vehicle_label.label_select')"
      :target-vehicles="[targetVehicle]"
      :is-required-groups=false
      :clearable=true
      :autofocus=true
      :vehicles-uses-count=vehiclesUsesCount
      @input="onVehicleSelect($event)"
    />
    <v-icon class="ml-2" color="red" @click="cancel()">mdi-close</v-icon>
  </div>
</template>

<script>
import QrDialog from "../../../vehicles/QrDialog.vue";
import VehicleTargetSelection from "../../../vehicle-inspections/VehicleTargetSelection";
import { ISSUES_SEVERITY_CRITICAL, ISSUES_SEVERITY_WARNING } from "@/utils/ts-utils";

export default {
  name: "VehicleLabel",

  components: {
    QrDialog,
    VehicleTargetSelection,
  },

  props: {
    vehiclesUsesCount: Object,
    targetVehicle: Object,
    printingDrpMode: Boolean,
  },

  data: () => ({
    state: "view",
  }),

  computed: {
    isDuplicate() {
      return (
        this.vehiclesUsesCount[this.targetVehicle.id] > 1
      );
    },
    vehicleClass() {
      return this.isDuplicate ? "orange--text" : "";
    },
    highlightClass() {
      if (!this.targetVehicle) return false;
      if (this.targetVehicle.highestIssuesSeverity == ISSUES_SEVERITY_CRITICAL) return "vehicle-drp-additional-data-highlight";
      if (this.targetVehicle.highestIssuesSeverity == ISSUES_SEVERITY_WARNING) return "vehicle-drp-additional-data-highlight-warning";
      return "";
    },
  },

  methods: {
    openVinQrDialog(vehicle) {
      this.$refs.vinQrDialog.openDialog(vehicle);
    },
    onVehicleSelect(newVehicle) {
      this.state = "view";
      this.$emit("onChangeData", newVehicle ? newVehicle.model : null);
    },
    onClick() {
      this.state = "edit";
    },
    cancel() {
      this.state = "view";
    },
  },
};
</script>

<style scoped lang="scss">
.vehicle-select {
  max-width: 200px;
}
.vehicle-name {
  cursor: pointer;
}
</style>

<style lang="scss">
.vehicle-drp-additional-data-highlight {
  color: white !important;
  background-color: #F44336;
}

.vehicle-drp-additional-data-highlight-warning {
  color: white !important;
  background-color: #FF9800;
}
</style>
