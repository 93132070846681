<template>
  <TemplateConstants/>
</template>

<script>
import TemplateConstants from "../components/template-constants/TemplateConstants";

export default {
  name: "TemplateConstantsPage",
  components: { TemplateConstants },

  routing: {
    localizationKey: "menu.tab.template_constants",
    icon: "mdi-message-draw",
    path: "/messaging/template-constants",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>
