import { VehicleInspectionItemScheme } from "./vehicle-inspection-item-scheme.model";

export class VehicleInspectionScheme {
    id;

    name;

    description;

    targetVehicles;

    targetVehicleGroups;

    createdAt;

    updatedAt;

    items;

    constructor(
      id,
      name,
      description,
      targetVehicles,
      targetVehicleGroups,
      createdAt,
      updatedAt,
      items,
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.targetVehicles = targetVehicles;
      this.targetVehicleGroups = targetVehicleGroups;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.items = items;
    }

    static fromDto(dto) {
      return new VehicleInspectionScheme(
        dto.id,
        dto.name,
        dto.description,
        dto.targetVehicles,
        dto.targetVehicleGroups,
        dto.createdAt,
        dto.updatedAt,
        dto.items.map(VehicleInspectionItemScheme.fromDto),
      );
    }
}
