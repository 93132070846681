


















































import {
  Component, Prop, Ref, Vue,
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import lodash from "lodash";
import { Vehicle } from "@/model/vehicle.model";
import { Issue } from "@/model/issue/issue.model";
import IssuesModule from "@/store/issues.store";
import IssueCardDialog from "@/components/issues/issue-card/IssueCardDialog.vue";
import { ISSUES_SEVERITY_CRITICAL, ISSUES_STATUS_OPEN, ISSUES_STATUS_RESOLVED } from "@/utils/ts-utils";
import { IssueStatus } from "@/types/types";

const locService = Vue.prototype.$localizationService;

const issueModule = IssuesModule.namespace;

@Component({
  components: { IssueCardDialog },
})
export default class VehicleIssues extends Vue {
  locService = locService;

  search: string | null = null;

  loading = false;

  @Prop()
  vehicle!: Vehicle;

  @Prop()
  maxHeight: number | undefined;

  @Prop()
  minHeight: number | undefined;

  @issueModule.State
  vehicleIssues!: Issue[];

  @issueModule.Action
  loadVehicleIssues!: (data: any) => Promise<Issue[]>

  @issueModule.Mutation
  clearVehicleIssues!: () => void;

  @Ref("issueCardDialog")
  issueCardDialog!: any;

  selectedStatuses: IssueStatus[] = [ISSUES_STATUS_OPEN];

  get maxHeightStyle() {
    return `max-height: ${(this.maxHeight || 999999999) - 64}px; overflow-y: auto;`;
  }

  get chips() {
    return [
      {
        status: ISSUES_STATUS_OPEN,
        textColor: "white--text",
      },
      {
        status: ISSUES_STATUS_RESOLVED,
        textColor: "white--text",
      },
    ];
  }

  getIssueEssenceString(issue: Issue) {
    return (issue.essence || issue.description || "No essence").substring(0, 150);
  }

  getIssueBackgroundColor(issue: Issue) {
    if (issue.status == ISSUES_STATUS_OPEN) {
      return issue.severity == ISSUES_SEVERITY_CRITICAL
        ? "critical-issue-background"
        : "warning-issue-background";
    }
    if (issue.status == ISSUES_STATUS_RESOLVED) return "resolved-issue-background";
    return "";
  }

  selectStatus(status: IssueStatus) {
    const index = this.selectedStatuses.findIndex((it) => it === status);

    if (index !== -1) {
      if (this.selectedStatuses.length === 1) return;

      this.selectedStatuses.splice(index, 1);
    } else {
      this.selectedStatuses.push(status);
    }

    this.loadIssues();
  }

  openIssueDialog(issue: Issue) {
    this.issueCardDialog.openDialog(issue);
  }

  loadIssues() {}

  reduceLoad() {}

  created() {
    this.loadIssues = lodash.debounce(async () => {
      this.loading = true;
      await this.loadVehicleIssues({
        vehicleId: this.vehicle.id,
        search: this.search,
        statuses: this.selectedStatuses,
      });
      this.loading = false;
    }, 300);

    this.reduceLoad = lodash.debounce(async () => {
      this.loading = true;
      await this.loadVehicleIssues({
        vehicleId: this.vehicle.id,
        search: this.search,
        statuses: this.selectedStatuses,
        reduceMode: true,
      });
      this.loading = false;
    }, 300);
  }

  mounted() {
    getModule(IssuesModule, this.$store);
    this.clearVehicleIssues();
    this.loadIssues();
  }
}
