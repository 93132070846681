const THEME_KEY = "app-dsps-theme";
export const THEME_DARK_KEY = "dark";
export const THEME_LIGHT_KEY = "light";

export const THEME = {
  lightTheme: {
    light: true,
  },
  darkTheme: {
    dark: true,
  },

  switchTheme: (theme) => {
    if (theme == THEME_DARK_KEY) { localStorage.setItem(THEME_KEY, THEME_LIGHT_KEY); }

    if (theme == THEME_LIGHT_KEY) { localStorage.setItem(THEME_KEY, THEME_DARK_KEY); }

    document.location.reload();
  },

  get storageThemeKey() {
    return localStorage.getItem(THEME_KEY) == THEME_DARK_KEY ? THEME_DARK_KEY : THEME_LIGHT_KEY;
  },

  get grafanaTheme() {
    return this.storageThemeKey;
  },

  get vuetifyTheme() {
    return this.storageThemeKey == THEME_DARK_KEY ? this.darkTheme : this.lightTheme;
  },

  get themeClass() {
    return this.storageThemeKey == THEME_DARK_KEY ? "dark-local-user-theme" : "light-local-user-theme";
  },

  get themeInverseColor() {
    return this.storageThemeKey == THEME_DARK_KEY ? "white" : "#a2a2a2";
  },

  get mainTheme() {
    return this.storageThemeKey;
  },
};
