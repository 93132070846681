<template>
 <VehicleInspectionSchemes />
</template>

<script>
import VehicleInspectionSchemes from "../components/vehicle-inspections/VehicleInspectionSchemes";

export default {
  name: "VehicleInspectionSchemesPage",
  components: { VehicleInspectionSchemes },

  routing: {
    localizationKey: "menu.tab.vehicle_inspection_schemes",
    icon: "mdi-content-paste",
    path: "/vehicle-inspection-scheme",
    hasAccess: (user) => user.isAdmin || user.isManager,
  },
};
</script>
