import {
  Action, Module, Mutation, VuexModule,
} from "vuex-module-decorators";
import { namespace } from "vuex-class";
import { API } from "aws-amplify";
import { store } from "@/main";
import { Issue } from "@/model/issue/issue.model";
import { IssueDto, IssueStatus } from "@/types/types";

import { bodyWithAuthHeader, handleHttpError } from "@/utils/utils";
import { IssueDataUnit } from "@/model/issue/data-unit.model";

const API_PATH = "/issue";

const moduleName = "issuesModule";

@Module({
  name: moduleName, namespaced: true, store,
})
export default class IssuesModule extends VuexModule {
  static get namespace() {
    return namespace(moduleName);
  }

  displayIssues: Issue[] = [];

  currentPageLoading = false;

  currentPage = {
    page: 0,
    pageSize: 25,
    sort: [],
    searchString: "",
    issues: [] as Issue[],
  };

  totalCount = 0;

  vehicleIssues: Issue[] = [];

  @Mutation
  startLoadingPage(currentPage: any) {
    this.currentPageLoading = true;
    this.currentPage = { ...currentPage };
  }

  @Mutation
  endLoadingPage() {
    this.currentPageLoading = false;
  }

  @Mutation
  updateCurrentPageIssues(dto: IssueDto[]) {
    this.currentPage.issues = dto.map((it) => Issue.fromDto(it));
    this.displayIssues = this.currentPage.issues;
  }

  @Mutation
  updateTotalCount(newValue: number) {
    this.totalCount = newValue;
  }

  @Mutation
  updateVehicleIssues(issues: Issue[]) {
    this.vehicleIssues = issues;
  }

  @Mutation
  clearVehicleIssues() {
    this.vehicleIssues = [];
  }

  @Action({ rawError: true })
  async reloadPage() {
    await this.loadPage(this.currentPage);
  }

  @Action({ rawError: true })
  async loadPage(currentPage: any): Promise<void> {
    this.startLoadingPage(currentPage);
    await this.loadTotalCount();
    const body = {
      ...await bodyWithAuthHeader(),
      queryStringParameters: {
        page: currentPage.index,
        pageSize: currentPage.size,
        sort: currentPage.sort.join("+"),
        searchString: currentPage.searchString,
      },
    };

    try {
      const issuesDto = await API.get("core", API_PATH, body);
      this.updateCurrentPageIssues(issuesDto);
    } catch (e) {
      console.error(e);
      handleHttpError(e);
    }
    this.endLoadingPage();
  }

  @Action({ rawError: true })
  async loadTotalCount() {
    const body = {
      ...await bodyWithAuthHeader(),
      queryStringParameters: {
        searchString: this.currentPage.searchString,
      },
    };

    try {
      const count = await API.get("core", `${API_PATH}/count`, body);
      this.updateTotalCount(count);
    } catch (e) {
      handleHttpError(e);
    }
  }

  @Action({ rawError: true })
  async addOrSaveIssue(issue: Issue): Promise<Issue | undefined> {
    const body = { ...await bodyWithAuthHeader(), body: issue.toDto() };

    try {
      const issueDto = await (issue.id
        ? API.put("core", `${API_PATH}/${issue.id}`, body)
        : API.post("core", API_PATH, body)
      );

      if (!issue.id) {
        this.updateTotalCount(this.totalCount + 1);
      }

      return Issue.fromDto(issueDto);
    } catch (e: any) {
      handleHttpError(e);
    }
    return undefined;
  }

  @Action({ rawError: true })
  async addOrSaveIssueUnit(_: {issueId: string | undefined; dataUnit: IssueDataUnit}): Promise<void> {
    const body = { ...await bodyWithAuthHeader(), body: _.dataUnit.toDto() };

    try {
      await (_.dataUnit.id
        ? API.put("core", `${API_PATH}/unit/${_.dataUnit.id}`, body)
        : API.post("core", `${API_PATH}/${_.issueId}/unit`, body)
      );
    } catch (e: any) {
      handleHttpError(e);
    }
  }

  @Action({ rawError: true })
  async deleteIssueUnit(issueId: string): Promise<void> {
    const body = { ...await bodyWithAuthHeader() };

    try {
      await API.del("core", `${API_PATH}/unit/${issueId}`, body);
    } catch (e: any) {
      handleHttpError(e);
    }
  }

  @Action({ rawError: true })
  async loadVehicleIssues(data: any): Promise<void> {
    const pageSize = 25;

    const body = {
      ...await bodyWithAuthHeader(),
      queryStringParameters: {
        vehicleId: data.vehicleId,
        searchString: data.search,
        statuses: data.statuses.map((it: IssueStatus) => it.value).join(","),
        pageSize,
        page: data.educeMode ? Math.ceil(this.vehicleIssues.length / pageSize) : (data.page || 0),
      },
    };

    try {
      const loadedIssues: Issue[] = (await API.get("core", API_PATH, body)).map(Issue.fromDto);

      if (loadedIssues) {
        if (data.reduceMode && this.vehicleIssues.length % pageSize != 0) return;

        this.updateVehicleIssues(data.reduceMode ? [...this.vehicleIssues, ...loadedIssues] : loadedIssues);
      }
    } catch (e: any) {
      handleHttpError(e);
    }
  }
}
