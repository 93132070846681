<template>
  <v-container
    fluid
    class="report-component pt-0"
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-row align="start" justify="start" class="mt-1">
      <v-col cols="12" :class="{ 'pt-0': IS_MOBILE }">
        <v-data-table
          :class="!IS_MOBILE ? 'mt-3' : 'd-none-empty-sort'"
          :headers="headers"
          :items="displayReports"
          :footer-props="footerProps"
          :loading="currentPageLoading"
          :server-items-length="totalCount"
          @update:options="paginate($event)"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $localizationService.localize("report_page.title") }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary">
                {{ $localizationService.localize("report_page.upload_btn") }}
                <input
                  @input="uploadReport"
                  type="file"
                  name="upload"
                  class="file-input"
                  multiple
                  accept=".pdf, .csv, .xlsx"
                />
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="!isError(item.status) && !isStuck(item)"
                  medium
                  @click="retryParse(item)"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-replay
                </v-icon>
              </template>
              <span>{{ $localizationService.localize("report_page.retry_tooltip") }}</span>
            </v-tooltip>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="red"
                  @click="openDeleteReportDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-delete</v-icon
                >
              </template>
              <span>{{ $localizationService.localize("report_page.remove_tooltip") }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{
              (REPORT_TYPES_BY_VALUE[item.type] || {}).text || item.type
            }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip
              bottom
              :disabled="
                item.status != REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING.value
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    item.status == REPORT_STATUS_MANUAL_ACTION_REQUIRED.value
                      ? { cursor: 'pointer' }
                      : {}
                  "
                  v-bind:class="getStatusTextColorClass(item)"
                  @click="openMarDialog(item)"
                  >{{ item.getDisplayStatus() }}
                </span>
                <v-icon
                  v-show="item.status == REPORT_STATUS_PARSED.value"
                  size="1.25em"
                  color="green"
                  class="ml-1 mb-1"
                  >mdi-check</v-icon
                >

                <v-btn
                  class="ml-1 theme-text-color"
                  v-show="item.status == REPORT_STATUS_DATA_NOT_FOUND.value"
                  x-small
                  color="secondary"
                  @click="openDeleteReportDialog(item)"
                  >{{ $localizationService.localize("btn.delete") }}</v-btn
                >

                <v-btn
                  class="ml-1 theme-text-color"
                  v-show="
                    item.status == REPORT_STATUS_MANUAL_ACTION_REQUIRED.value
                  "
                  x-small
                  color="secondary"
                  @click="openMarDialog(item)"
                  >{{ $localizationService.localize("btn.resolve") }}</v-btn
                >

                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-show="
                    item.status ==
                    REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING.value
                  "
                  size="1.25em"
                  color="red"
                  class="ml-1 mb-1"
                  >mdi-alert-circle-outline</v-icon
                >
                <v-icon
                  v-show="
                    item.status == REPORT_STATUS_TYPE_DETECTION_FAILED.value ||
                    item.status ==
                      REPORT_STATUS_DATA_RECOGNITION_FAILED.value ||
                    item.status == REPORT_STATUS_ALREADY_EXIST.value ||
                    item.status == REPORT_STATUS_DATE_UNAVAILABLE.value ||
                    item.status == REPORT_STATUS_NOT_SUPPORTED_ANYMORE.value
                  "
                  size="1.25em"
                  color="red"
                  class="ml-1 mb-1"
                  >mdi-alert-circle-outline</v-icon
                >
                <v-progress-circular
                  v-show="item.isInProcess()"
                  :size="15"
                  :width="2"
                  class="ml-1 mb-1"
                  :class="{
                    'orange--text': isStuck(item),
                    'primary--text': !isStuck(item)
                  }"
                  indeterminate
                ></v-progress-circular>
              </template>
              <span>{{
                $localizationService.localize("error.contact_with_admin")
              }}</span>
            </v-tooltip>
            <div
              v-if="isStuck(item)"
              class="text--secondary caption"
            >{{
              $localizationService.localize("model.report.stuck")
            }}</div>
          </template>
          <template v-slot:[`item.targetDate`]="{ item }">
            {{ formattedTargetDate[item.id] }}
          </template>
          <template v-slot:[`item.uploadedAt`]="{ item }">
            {{ formattedUploadedAt[item.id] }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import {
  IS_MOBILE,
  REPORT_STATUS_MANUAL_ACTION_REQUIRED,
  REPORT_STATUS_DATA_NOT_FOUND,
  REPORT_STATUS_PARSED,
  REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING,
  REPORT_STATUS_DATA_RECOGNITION_FAILED,
  REPORT_STATUS_TYPE_DETECTION_FAILED,
  REPORT_STATUS_ALREADY_EXIST,
  REPORT_PROCESS_STATUS_CLASSIFIED,
  REPORT_PROCESS_STATUS_RECEIVED,
  REPORT_STATUS_DATE_UNAVAILABLE,
  REPORT_TYPES_BY_VALUE,
  REPORT_STATUS_NOT_SUPPORTED_ANYMORE,
} from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "Reports",

  data: () => ({
    IS_MOBILE,
    REPORT_TYPES_BY_VALUE,
    REPORT_STATUS_MANUAL_ACTION_REQUIRED,
    REPORT_STATUS_DATA_NOT_FOUND,
    REPORT_STATUS_PARSED,
    REPORT_STATUS_SYSTEM_ERROR_DURING_PARSING,
    REPORT_STATUS_DATA_RECOGNITION_FAILED,
    REPORT_STATUS_TYPE_DETECTION_FAILED,
    REPORT_STATUS_ALREADY_EXIST,
    REPORT_PROCESS_STATUS_CLASSIFIED,
    REPORT_PROCESS_STATUS_RECEIVED,
    REPORT_STATUS_DATE_UNAVAILABLE,
    REPORT_STATUS_NOT_SUPPORTED_ANYMORE,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },
  }),

  computed: {
    ...mapState("reportModule", [
      "reportsUploadingStatus",
      "reportsUploadingInProgress",
      "currentPageLoading",
      "currentPage",
      "displayReports",
      "totalCount",
      "processingReport",
    ]),
    ...mapState("coreModule", ["drawer"]),
    headers: () => [
      {
        text: locService.localize("report_page.header.target_date"),
        value: "targetDate",
        sortable: false,
      },
      {
        text: locService.localize("report_page.header.type"),
        value: "type",
        sortable: false,
      },
      {
        text: locService.localize("report_page.header.status"),
        value: "status",
        sortable: false,
      },
      {
        text: locService.localize("report_page.header.upload_at"),
        value: "uploadedAt",
        sortable: false,
      },
      {
        text: locService.localize("report_page.header.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],

    formattedTargetDate() {
      const result = {};

      this.displayReports.forEach(
        (report) => (result[report.id] = report.getFormattedTargetDate()),
      );

      return result;
    },
    formattedUploadedAt() {
      const result = {};

      this.displayReports.forEach(
        (report) => (result[report.id] = report.getFormattedUploadedAt()),
      );

      return result;
    },
  },

  methods: {

    async retryParse(report) {
      try {
        const result = await this.$store.dispatch("reportModule/retryParse", report);
        Vue.toasted.success(result, {
          duration: 5000,
        });
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }
    },

    isStuck(report) {
      return report.isInProcess() && moment().diff(moment(report.createdAt || report.uploadedAt), "minute") >= 10;
    },

    isError(status) {
      return ![
        REPORT_PROCESS_STATUS_RECEIVED.value,
        REPORT_PROCESS_STATUS_CLASSIFIED.value,
        REPORT_STATUS_PARSED.value,
        REPORT_STATUS_MANUAL_ACTION_REQUIRED.value,
      ].includes(status);
    },

    getStatusTextColorClass(report) {
      const status = report.status;

      if (status == "UNKNOWN") return "red--text";
      if (status == REPORT_STATUS_DATA_NOT_FOUND.value) return "red--text";
      if ([
        REPORT_PROCESS_STATUS_RECEIVED.value,
        REPORT_PROCESS_STATUS_CLASSIFIED.value,
      ].includes(status)) return this.isStuck(report) ? "orange--text" : "primary--text";
      if (status == REPORT_STATUS_PARSED.value) return "green--text";
      if (status == REPORT_STATUS_MANUAL_ACTION_REQUIRED.value) return "orange--text";
      return "red--text";
    },

    paginate(data) {
      this.$store.dispatch("reportModule/loadPage", {
        index: data.page - 1,
        size: data.itemsPerPage,
      });
    },

    openMarDialog(report) {
      this.$emit("onOpenMarDialog", report);
    },

    uploadReport(event) {
      if (event.target.files.length) {
        this.$emit("onUploadReport", { event });
      }
    },

    reloadPage() {
      this.$store.dispatch("reportModule/reloadPage");
    },

    openDeleteReportDialog(report) {
      this.$emit("onDeleteReport", report);
    },
  },

  mounted() {
    this.$store.dispatch("reportModule/loadPage", { index: 0, size: 25 });
  },
};
</script>

<style scoped lang="scss">
.file-input {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: -50%;
  left: -16px;
  width: calc(100% + 32px);
  height: 36px;
  cursor: pointer !important;
}

.file-input::-webkit-file-upload-button {
  cursor: pointer;
}

.green--text {
  cursor: default;
}

.row-display {
  display: none !important;
}
</style>
<style lang="scss">
.report-component {
  .d-none-empty-sort {
    .v-data-table-header {
      display: none;
    }
  }
}
</style>
