<template>
  <v-dialog v-model="open" max-width="600px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          {{ $localizationService.localize("vehicle_page.settings_dialog.title")}}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="d-inline-flex align-baseline">
            <v-col>
              <div class="text">
                {{ $localizationService.localize("vehicle_page.settings_dialog.rental_expire_due_soon")}}
              </div>
            </v-col>
            <v-col>
              <div class="d-flex">
                <v-text-field
                  v-model="rentalExpiryDueSoonThreshold.value"
                  dense
                  type="number"
                  :label="$localizationService.localize('vehicle_reminder_page.dialog.due_soon')"
                />
                <v-select
                  v-model="rentalExpiryDueSoonThreshold.unit"
                  :items="Object.values(dateUnits)"
                  class="double-select-2"
                  dense
                  :label="$localizationService.localize('vehicle_reminder_page.dialog.unit')"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="confirm"
          :disabled="disabled"
        >
          {{ $localizationService.localize("btn.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from "vuex";
import { isEqual } from "lodash";
import { Unit, UnitedDuration } from "../../model/reminder/united-duration.model";

export default {
  name: "RentalExpiryDueSoonDialog",

  data: () => ({
    open: false,
    rentalExpiryDueSoonThreshold: {},
    originalRentalExpiryDueSoonThreshold: {},
    dateUnits: Unit,
    loading: false,
  }),

  computed: {
    ...mapState("companyModule", ["company"]),

    disabled() {
      return isEqual(this.originalRentalExpiryDueSoonThreshold, this.rentalExpiryDueSoonThreshold);
    },
  },

  methods: {
    async openDialog() {
      this.open = true;
      this.originalRentalExpiryDueSoonThreshold = this.company.rentalExpiryDueSoonThreshold
        ? UnitedDuration.copy(this.company.rentalExpiryDueSoonThreshold) : new UnitedDuration(Unit.WEEK, 1);
      this.rentalExpiryDueSoonThreshold = UnitedDuration.copy(this.originalRentalExpiryDueSoonThreshold);
    },

    closeDialog() {
      this.open = false;
      this.rentalExpiryDueSoonThreshold = {};
    },

    async confirm() {
      this.loading = true;
      await this.saveRentalExpiryDueSoonThreshold();
      this.loading = false;
      this.closeDialog();
    },

    async saveRentalExpiryDueSoonThreshold() {
      await this.$store.dispatch("companyModule/saveRentalExpiryDueSoonThreshold",
        this.rentalExpiryDueSoonThreshold);
    },

  },
};
</script>

<style scoped lang="scss">
.double-select-2 {
  width: 40%;
}

.text {
  color: var(--v-themeTextColor-base) !important;
  font-size: 1rem;
}
</style>
