/**
Helper function to parse a number, text or Date to a DateTime value. If a timeZone is supplied the incoming value
is parsed with that @param timeZone as a base. The only exception to this is if the passed value is in a UTC-based
format. Then it will use UTC as the base. If no format is specified the current system format will be assumed.

It can also parse the Grafana quick date and time format, e.g. now-6h will be parsed as Date.now() - 6 hours and
returned as a valid DateTime value.

If no options are supplied, then default values are used. For more details please see.

@param value - should be a parsable date and time value
@param options - {timeZone, roundUp}

If the input is a Grafana quick date, e.g. now-6h, then you can specify this to control
whether the last part of the date and time value is included or excluded.

Example: now-6h and the current time is 12:20:00 if @param roundUp is set to true
the returned DateTime value will be 06:00:00.

We don`t use @param timeZone
*/
import Vue from "vue";
import moment from "moment";

const locService = Vue.prototype.$localizationService;

const units = ["y", "M", "w", "d", "h", "m", "s"];

function parseDateMath(mathString, time, roundUp) {
  const strippedMathString = mathString.replace(/\s/g, "");
  const dateTime = time;
  let i = 0;
  const len = strippedMathString.length;
  let unit;
  while (i < len) {
    const c = strippedMathString.charAt(i++); // get symbol / - +
    let type;
    let num;

    if (c === "/") {
      type = 0;
    } else if (c === "+") {
      type = 1;
    } else if (c === "-") {
      type = 2;
    } else {
      Vue.toasted.error(locService.localize("date_parser.unexpected_symbol", [c]));
      return undefined;
    }

    if (Number.isNaN(+strippedMathString.charAt(i))) {
      num = 1;
    } else { // get number from string
      const numFrom = i;
      while (!Number.isNaN(+strippedMathString.charAt(i))) {
        i++;
        if (i > 10) {
          return undefined;
        }
      }
      num = +strippedMathString.substring(numFrom, i);
    }

    if (type === 0 && num !== 1) {
      // rounding is only allowed on whole, single, units (eg M or 1M, not 0.5M or 2M)
      Vue.toasted.error(locService.localize("date_parser.unexpected_expression", [mathString]));
      return undefined;
    }

    unit = strippedMathString.charAt(i++); // get letter from string

    if (!units.includes(unit)) {
      Vue.toasted.error(locService.localize("date_parser.unexpected_symbol", [unit]));
      return undefined;
    }

    if (type === 0) {
      if (roundUp) {
        dateTime.endOf(unit);
      } else {
        dateTime.startOf(unit);
      }
    } else if (type === 1) {
      dateTime.add(num, unit);
    } else if (type === 2) {
      dateTime.subtract(num, unit);
    }
  }

  return { dateTime, unit };
}

function parse(text, roundUp) {
  const time = moment(); // get time to now;
  const mathString = text.substring("now".length); // get another string;

  if (!mathString.length) {
    Vue.toasted.error(locService.localize("date_parser.cannot_be_parsed", [text]));
    return undefined;
  }

  return parseDateMath(mathString, time, roundUp);
}

function parseString(value, options = undefined) {
  if (value.indexOf("now") !== -1) {
    const parsed = parse(value, options ? options.roundUp : undefined);
    return parsed || moment();
  }

  Vue.toasted.error(locService.localize("date_parser.cannot_be_parsed", [value]));
  return undefined;
}

export function dateTimeParse(value, options) {
  if (typeof value === "string") {
    return parseString(value, options);
  }

  Vue.toasted.error(locService.localize("date_parser.is_not_a_string", [value]));
  return undefined;
}
