<template>
  <v-card width="256px" v-if="user" :style="styles" class="rounded-0 drawer-card" >
    <v-navigation-drawer permanent fill-height>
      <div
        v-if="user.isDriver || user.blocked"
        class="d-flex justify-center pt-4"
      >
        {{ $localizationService.localize("menu.no_navigation") }}
      </div>
      <v-list dense nav class="drawer-list">
        <router-link
          v-for="item in navigationList"
          :key="item.localizationKey"
          :to="item.path"
          active-class="active-menu-item"
        >
          <v-list-item
            link
            @click="onMenuClick"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $localizationService.localize(item.localizationKey)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <TreeviewMenu @onMenuClick="onMenuClick()"/>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="onLogoutClick" :disabled="authInProgress" :class="{ 'drawer-back-to-master-button': inSheepsClothing }">
            {{ inSheepsClothing ? $localizationService.localize("common.back_to_master") : $localizationService.localize("common.logout") }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DriversPage from "../../views/DriversPage";
import DevicesPage from "../../views/DevicesPage";
import CoachingPage from "../../views/coaching/CoachingPage";
import IssuesPage from "@/views/IssuesPage";
import TriggersPage from "@/views/TriggersPage";
import { inSheepsClothing } from "@/utils/utils";

export default {
  name: "NavigationDrawer",

  components: {
    TreeviewMenu: () => import("./TreeviewMenu"),
  },

  data() {
    return {
      inSheepsClothing,
      drawer: true,
    };
  },

  computed: {
    ...mapState("userModule", ["authInProgress", "user"]),

    styles() {
      const { bar, top } = this.$vuetify.application;

      return {
        height: `calc(100vh - ${top + bar}px) !important`,
      };
    },

    navigationList() {
      if (!this.user || this.user.blocked) return [];
      return [
        DriversPage,
        DevicesPage,
        CoachingPage,
        IssuesPage,
        TriggersPage,
      ].map((it) => it.routing)
        .filter((it) => it.hasAccess(this.user));
    },
  },

  methods: {
    onMenuClick() {
      this.$store.commit("coreModule/drawerLinkClicked");
    },

    onLogoutClick() {
      if (inSheepsClothing) {
        const cognitoKeys = Object.keys(localStorage).filter((it) => it.startsWith("CognitoIdentityServiceProvider"));

        const idTokenKey = cognitoKeys.find((it) => it.endsWith("idToken"));
        const refreshTokenKey = cognitoKeys.find((it) => it.endsWith("refreshToken"));
        const lastAuthUserKey = cognitoKeys.find((it) => it.endsWith("LastAuthUser"));

        const originalIdToken = localStorage.getItem("original_cognito_id_token");
        const originalRefreshToken = localStorage.getItem("original_cognito_refresh_token");
        const originalLastAuthUser = localStorage.getItem("original_cognito_last_auth_user");

        const originalIdTokenKey = idTokenKey.replace(this.user.cognitoUsername, originalLastAuthUser);
        const originalRefreshTokenKey = refreshTokenKey.replace(this.user.cognitoUsername, originalLastAuthUser);
        const originalLastAuthUserKey = lastAuthUserKey.replace(this.user.cognitoUsername, originalLastAuthUser);

        cognitoKeys.forEach((it) => localStorage.removeItem(it));

        localStorage.setItem(originalIdTokenKey, originalIdToken);
        localStorage.setItem(originalRefreshTokenKey, originalRefreshToken);
        localStorage.setItem(originalLastAuthUserKey, originalLastAuthUser);

        window.location.reload();
      } else {
        this.$store.dispatch("userModule/signOut");
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>

<style lang="scss">
@import '"~vuetify/src/styles/styles.sass';
.drawer-list {
  .v-treeview-node__content {
    margin-left: 3px !important;
  }
  .active-menu-item {
    .v-list-item {
      background-color: map-get($grey, "darken-2") !important;
    }
  }
  .not-active-item {
    opacity: 0.5;
  }
  .disabled-item {
    position: relative;
    left: -24px;
    opacity: 0.5;
    .v-list-item__content {
      margin-left: 2px;
    }
    .v-list-item__icon {
      margin-right: 10px !important;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-treeview-node__label {
    overflow: visible;
  }
  .v-treeview-node__level {
    width: 12px !important;
  }
  .v-list-item__icon {
    margin-right: 11px !important;
  }
  .v-expansion-panels {
    .v-expansion-panel {
      background-color: map-get(
        $material-dark-elevation-colors,
        "12"
      ) !important;
    }
    .v-expansion-panel--active {
      margin-top: 0;
    }
    .v-expansion-panel--active + .v-expansion-panel {
      margin-top: 0;
    }
  }
}

.light-local-user-theme {
  .drawer-list {
    .active-menu-item {
      .v-list-item {
        background-color: map-get($grey, "lighten-2") !important;
      }
    }
  }
}

.drawer-back-to-master-button {
  background-color: gold !important;
  color: black !important;
}
</style>
