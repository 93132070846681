var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"driver-table-display",class:{'not-default-page-size': !this.isDefaultSize},attrs:{"headers":_vm.headers,"items":_vm.displayDrivers,"loading":_vm.currentPageLoading || !_vm.tableInited,"server-items-length":_vm.totalCount,"options":{
          page: _vm.pageIndex,
          itemsPerPage: _vm.pageSize,
          sortBy: _vm.sort.sortBy,
          sortDesc: _vm.sort.sortDesc,
        },"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("driver_page.title"))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize('driver_page.settings_tooltip.save'),"pDefaultButtonTooltip":_vm.$localizationService.localize('driver_page.settings_tooltip.apply_default')},on:{"onSaveSettings":function($event){return _vm.saveSettings()},"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()}}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize('driver_page.search'),"hide-details":""},on:{"input":function($event){return _vm.onInputSearchString()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditDriverDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("driver_page.new_btn"))+" ")]),_c('EditDriverDialog',{ref:"editDriverDialog"}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
            'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":"","z-index":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.aliases.length ? item.aliases.join(", ") : _vm.$localizationService.localize( "driver_page.does_not_have_aliases" )))])]),_c('MessageButton',{staticClass:"message-button ml-1",attrs:{"target":_vm.MessagingTargets.driver(item.id)}})],1)]}},{key:"item.invitationStatus",fn:function(ref){
            var item = ref.item;
return [(item.invitationStatus == 'INVITED')?_c('span',[_c('v-badge',{attrs:{"color":"orange","inline":"","left":""}}),_vm._v(" "+_vm._s(_vm.$localizationService.localize("driver_page.inivited"))+" "),_c('v-btn',{attrs:{"x-small":"","dark":"","color":"secondary","loading":_vm.invitationInProgess[item.id]},on:{"click":function($event){return _vm.revokeInvitation(item)}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("driver_page.revoke_btn"))+" ")])],1):(item.invitationStatus == 'JOINED')?_c('span',[_c('v-badge',{attrs:{"color":"green","inline":"","left":""}}),_vm._v(" "+_vm._s(_vm.$localizationService.localize("driver_page.joined"))+" ")],1):(item.invitationStatus == 'NOT_INVITED')?_c('span',[_c('v-badge',{attrs:{"color":"red","inline":"","left":""}}),_vm._v(" "+_vm._s(_vm.$localizationService.localize("driver_page.not_invited"))+" "),_c('v-btn',{staticClass:"theme-text-color",attrs:{"x-small":"","color":"secondary","loading":_vm.invitationInProgess[item.id]},on:{"click":function($event){return _vm.sendInvitation(item)}}},[_vm._v(_vm._s(_vm.$localizationService.localize("driver_page.invite_btn")))])],1):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openEditDriverDialog(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteDriverDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }