var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"inputDriver",staticClass:"driver-autocomplete",attrs:{"label":_vm.selectedEntryModel || _vm.isReplacement
      ? _vm.$localizationService.localize(
          'drp.entry_select.replacement_driver_label'
        )
      : _vm.$localizationService.localize('drp.entry_select.driver_label'),"items":_vm.items,"loading":_vm.loading,"clearable":"","clear-icon":"mdi-delete","item-text":_vm.getItemText,"return-object":"","hide-details":"","search-input":_vm.searchDriver,"placeholder":_vm.$localizationService.localize('common.begin_typing')},on:{"update:searchInput":function($event){_vm.searchDriver=$event},"update:search-input":function($event){_vm.searchDriver=$event},"click:clear":function($event){return _vm.clearSelection()},"blur":function($event){return _vm.clearOnBlur()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
return [(item.id)?_c('DriverEntryLabel',{attrs:{"targetDriverEntry":item,"additionalDataPerDriver":_vm.additionalDataPerDriver[item.driver.id] || {},"isDuplicate":item.isWillBeDuplicate}}):_c('MatchedDriverLabel',{class:{ 'orange--text': item.isWillBeDuplicate },attrs:{"driver":item.driver,"additionalDataPerDriver":_vm.additionalDataPerMatchedDriver[item.driver.id] || {}}})]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [(item.group)?_c('v-list-item-content',[_vm._v(" "+_vm._s(item.header)+" ")]):_c('div',{class:_vm.getItemClass(item)},[(item.id)?_c('DriverEntryLabel',{staticClass:"mt-2 mb-2",attrs:{"targetDriverEntry":item,"additionalDataPerDriver":_vm.additionalDataPerDriver[item.driver.id] || {},"isDuplicate":_vm.isDuplicateDriver(item),"visibleOptionalParams":true}}):_c('MatchedDriverLabel',{class:{ 'orange--text': _vm.isDuplicateDriver(item) },attrs:{"driver":item.driver,"additionalDataPerDriver":_vm.additionalDataPerMatchedDriver[item.driver.id] || {}}})],1)]}}]),model:{value:(_vm.selectedEntryModel),callback:function ($$v) {_vm.selectedEntryModel=$$v},expression:"selectedEntryModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }