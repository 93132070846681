<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>
        {{
          $localizationService.localize(
            "coaching_page.resolve_coaching_dialog.title"
          )
        }}
      </v-card-title>

      <v-card-text class="pb-2 pr-4">
        <v-textarea
          v-model="resolution.note"
          :label="
            $localizationService.localize(
              'coaching_page.resolve_coaching_dialog.param.note'
            )
          "
          rows="3"
          no-resize
          hide-details
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="open = false">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
          text
          class="ml-4"
          color="primary"
          :disabled="userInputDisabled"
          @click="resolve()"
        >
          {{ $localizationService.localize("btn.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Vue from "vue";
import { dateTimeMomentFormat } from "../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "ResolveCoachingDialog",

  data: () => ({
    open: false,

    resolution: {
      note: "",
    },

    coaching: undefined,
  }),

  computed: {
    ...mapState("coachingModule", ["processingCoaching"]),

    userInputDisabled() {
      return this.loading;
    },

    loading() {
      return this.processingCoaching;
    },

    formattedTargetDateTime() {
      return this.coaching.targetDateTime
        ? moment(this.coaching.targetDateTime).format(dateTimeMomentFormat)
        : "";
    },
  },

  methods: {
    openDialog(coaching) {
      this.coaching = coaching;
      this.open = true;
    },

    async resolve() {
      try {
        await this.$store.dispatch("coachingModule/resolveCoaching", {
          coaching: this.coaching,
          data: this.resolution,
        });
        Vue.toasted.success(
          locService.localize(
            "coaching_page.resolve_coaching_dialog.coaching_successfully_resolved",
          ),
          { duration: 5000 },
        );
        this.open = false;
      } catch (e) {
        Vue.toasted.error(e, {
          duration: 5000,
        });
      }
    },

    close() {
      this.clear();
      this.open = false;
    },

    clear() {
      this.resolution = {
        note: "",
      };
    },
  },
};
</script>
