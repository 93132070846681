<template>
  <PhoneAgents/>
</template>

<script>

import PhoneAgents from "../components/phone-agents/PhoneAgents";

export default {
  name: "PhoneAgentsPage",

  routing: {
    localizationKey: "menu.tab.phone_agents",
    icon: "mdi-cellphone-wireless",
    path: "/messaging/phone-agents",
    hasAccess: (user) => user.isAdmin,
  },

  components: {
    PhoneAgents,
  },
};
</script>
