import Vue from "vue";
import { API } from "aws-amplify";
import { Vehicle, VehicleDto } from "../model/vehicle.model";
import {
  dismissAction, bodyWithAuthHeader, REQUEST_CANCEL_MESSAGE, handleHttpError,
} from "../utils/utils";

const locService = Vue.prototype.$localizationService;

const API_PATH = "/vehicle";

export default {

  name: "vehiclesModule",
  namespaced: true,
  state: {
    currentPageLoadRequest: undefined,
    currentTotalCountLoadRequest: undefined,

    pageLoading: false,
    displayVehicles: [],
    matchedVehicles: [],
    currentPage: {
      vehicles: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.pageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.pageLoading = false;
    },

    updateTotalCount(state, totalCount) {
      state.totalCount = totalCount;
    },

    updateVehicles(state, vehiclesDto) {
      state.currentPage.vehicles = [...vehiclesDto];
      state.displayVehicles = state.currentPage.vehicles;
    },

    updateMatchedVehicles(state, vehiclesDto) {
      if (!vehiclesDto.length) {
        state.matchedVehicles = [{
          name: locService.localize("store.vehicle.matched_vehicles.no_name"),
          vin: locService.localize("store.vehicle.matched_vehicles.no_vin"),
          disabled: true,
        }];
      } else { state.matchedVehicles = [...vehiclesDto]; }
    },
  },
  actions: {
    reloadPage({
      dispatch,
      state,
    }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadTotalCount({
      state,
      commit,
    }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString: state.currentPage.searchString,
        },
      };

      try {
        if (state.currentTotalCountLoadRequest) {
          API.cancel(state.currentTotalCountLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentTotalCountLoadRequest = API.get("core", `${API_PATH}/count`, body);
        const count = await state.currentTotalCountLoadRequest;
        commit("updateTotalCount", count);
      } catch (e) {
        if (API.isCancel(e)) {
          console.log(e);
          return;
        }
        Vue.toasted.error(e, { ...dismissAction });
      }
    },

    async loadPage({
      state,
      commit,
      dispatch,
    }, currentPage) {
      commit("startLoadingPage", currentPage);

      await dispatch("loadTotalCount");

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
          sort: currentPage.sort.join("+"),
          searchString: currentPage.searchString,
        },
      };

      try {
        if (state.currentPageLoadRequest) {
          API.cancel(state.currentPageLoadRequest, REQUEST_CANCEL_MESSAGE);
        }
        state.currentPageLoadRequest = API.get("core", API_PATH, body);
        const vehiclesDto = await state.currentPageLoadRequest;
        commit("updateVehicles", vehiclesDto.map((vehicle) => Vehicle.fromDto(vehicle)));
      } catch (e) {
        if (API.isCancel(e)) {
          console.log(e);
          return;
        }
        Vue.toasted.error(e, { ...dismissAction });
      }
      commit("endLoadingPage");
    },

    async deleteVehicle(context, vehicle) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${vehicle.id}`, body);
        return locService.localize("store.vehicle.delete_vehicle_msg", [vehicle.name]);
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async addOrSaveVehicle(context, vehicle) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: VehicleDto.toDto(vehicle),
      };
      try {
        const result = await (vehicle.id ? API.put("core", `${API_PATH}/${vehicle.id}`, body) : API.post("core", API_PATH, body));
        return result;
      } catch (e) {
        throw e.response.data.message;
      }
    },

    async loadFilteredVehicle({ commit }, {
      vin,
      name,
    }) {
      if (!vin && !name) return [];

      const body = await bodyWithAuthHeader();

      try {
        const vehiclesDto = await API.get("core", `${API_PATH}/search?vin=${vin || ""}&name=${name || ""}`, body);

        commit("updateMatchedVehicles", vehiclesDto);

        return vehiclesDto;
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },

    async searchVehicles({ dispatch }, searchString) {
      return dispatch("searchVehiclesWithMetadata", { searchString });
    },

    async loadMetadata(context, { ids, includeLastUpdatedOpenedIssue }) {
      if (!ids || !ids.length) return [];

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          vehicleIds: ids,
          includeLastUpdatedOpenedIssue,
        },
      };

      try {
        const metadataDto = await API.get(
          "core",
          "/vehicle/metadata",
          body,
        );

        return metadataDto;
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },

    async searchVehiclesWithMetadata(context, { searchString, includeLastUpdatedOpenedIssue, useLightweightDto }) {
      if (!searchString) return [];

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          searchString,
          includeLastUpdatedOpenedIssue,
          useLightweightDto,
        },
      };

      try {
        const vehiclesDto = await API.get(
          "core",
          "/vehicle/search",
          body,
        );

        return vehiclesDto.map((vehicle) => Vehicle.fromDto(vehicle));
      } catch (error) {
        Vue.toasted.error(error);
      }
      return undefined;
    },

    async loadVehicleById(context, id) {
      const body = await bodyWithAuthHeader();

      try {
        const vehicleDto = await API.get("core", `${API_PATH}/${id}`, body);
        return Vehicle.fromDto(vehicleDto);
      } catch (e) {
        handleHttpError(e);
      }
      return undefined;
    },
  },
};
