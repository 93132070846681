var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"vehicle-inspection",attrs:{"align":"start","justify":"start"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",class:{'not-default-page-size': !this.isDefaultSize},attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.displayVehicleInspections,"loading":_vm.pageLoading || !_vm.tableInited,"options":{
          page: _vm.pageIndex,
          itemsPerPage: _vm.pageSize,
          sortBy: _vm.sort.sortBy,
          sortDesc: _vm.sort.sortDesc,
        },"expanded":_vm.expanded,"show-expand":"","server-items-length":_vm.totalCount,"multi-sort":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("vehicle_inspection_page.title"))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pDefaultButtonTooltip":_vm.$localizationService.localize(
                    'vehicle_inspection_page.settings_tooltip.apply_default'
                    ),"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize(
                    'vehicle_inspection_page.settings_tooltip.save'
                  )},on:{"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()},"onSaveSettings":function($event){return _vm.saveSettings()}}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex mt-6"},[_c('v-select',{staticClass:"mr-4 filter-field",attrs:{"items":_vm.INSPECTIONS_FAIL_PASS_VALUES,"item-text":"text","item-value":"value","label":"Fail/Pass Filter","value":_vm.filter},on:{"change":function($event){return _vm.changeFilter($event)}}}),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize('vehicle_inspection_page.search'),"hide-details":"","width":"200"},on:{"input":function($event){return _vm.loadOnInputSearchString()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
            'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.assignment.targetVehicle",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignment.targetVehicle.name)+" ")]}},{key:"item.assignment.targetScheme",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignment.targetScheme.name)+" ")]}},{key:"item.inspector",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.inspector.name)+" ")]}},{key:"item.assignment.targetDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDate(item.assignment.targetDate))+" ")]}},{key:"item.startDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.endDate))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red","medium":""},on:{"click":function($event){return _vm.openDeleteVehicleInspectionDialog(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('VehicleInspectionData',{attrs:{"inspection":item}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }