<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(
              "vehicle_inspection_json_dialog.title"
            )
          }}
        </span>
      </v-card-title>

      <VehicleInspectionData :inspection=inspection />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VehicleInspectionData from "@/components/vehicle-inspections/VehicleInspectionData";

export default {
  name: "VehicleInspectionJsonDialog",

  components: {
    VehicleInspectionData,
  },

  data: () => ({
    open: false,
    inspection: undefined,
  }),

  methods: {

    openDialog(inspection) {
      this.inspection = inspection;
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },
  },
};
</script>
