<template>
  <v-app-bar fixed app v-if="user" class="dsps-app-bar" :class="appBarClasses">
    <v-app-bar-nav-icon @click.stop="onBurgerClick"></v-app-bar-nav-icon>
    <v-toolbar-title :class="{ 'pa-0': IS_MOBILE }">
      <router-link class="theme-text-color" to="/">
        {{ $localizationService.localize("company_name.small") }}
      </router-link>
    </v-toolbar-title>
    <div class="ml-4">
      <MasterDoor v-if="user.isMaster"/>
    </div>
    <v-spacer></v-spacer>
    <ThemeSwitcher />
    <div>
      <v-list-item class="pl-1 text-right">
        <v-list-item-content>
          <v-list-item-title>
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.statusInCompany }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-avatar color="primary" class="white--text" size="42">{{
      user.abbreviation
    }}</v-avatar>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { inSheepsClothing, IS_MOBILE } from "../utils/utils";

export default {
  name: "AppBar",

  components: {
    ThemeSwitcher: () => import("./ThemeSwitcher.vue"),
    MasterDoor: () => import("./MasterDoor.vue"),
  },

  computed: {
    ...mapState("userModule", ["authInProgress", "user"]),

    appBarClasses() {
      return inSheepsClothing ? "dsps-app-behalf-bar" : "";
    },
  },

  data: () => ({
    IS_MOBILE,
  }),

  methods: {
    onBurgerClick() {
      this.$store.commit("coreModule/toggleDrawer");
    },
  },
};
</script>

<style lang="scss">
.dsps-app-bar {
  .v-list-item__content {
    width: min-content !important;
  }
  .router-link-active {
    text-decoration: none;
    color: var(--v-primary-base);
  }
}
header.dsps-app-behalf-bar {
  background-color: var(--v-exceptionColor-base) !important;
}
</style>
