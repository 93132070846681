










































































































































































































































































import {
  Component, Emit, Vue, Watch,
} from "vue-property-decorator";
import moment from "moment-timezone";
import { namespace } from "vuex-class";
import _, { isEqual } from "lodash";
import VehicleTargetSelection from "@/components/vehicle-inspections/VehicleTargetSelection.vue";
import { VehicleActionType } from "@/model/reminder/vehicle-action-type.model";
import { Reminder, VehicleReminderMetadata } from "@/model/reminder/reminder.model";
import { Unit } from "@/model/reminder/united-duration.model";
import DatePerVehicleDialog from "@/components/reminders/DatePerVehicleDialog.vue";
import { randomMoment } from "@/utils/utils";
import MileagePerVehicleDialog from "@/components/reminders/MileagePerVehicleDialog.vue";
import EditVehicleActionTypeDialog from "@/components/reminders/vehicle-action-types/EditVehicleActionTypeDialog.vue";
import { Vehicle } from "@/model/vehicle.model";

const vehicleActionTypesModule = namespace("vehicleActionTypesModule");
const vehicleRemindersModule = namespace("vehicleRemindersModule");
const vehicleGroupsModule = namespace("vehicleGroupsModule");
const companyModule = namespace("companyModule");

@Component({
  components: {
    EditVehicleActionTypeDialog, MileagePerVehicleDialog, DatePerVehicleDialog, VehicleTargetSelection,
  },
})
export default class CreateReminderDialog extends Vue {
  open = false;

  isDueDateMenuOpen = false;

  loading = false;

  reminder = Reminder.createEmpty();

  originalReminder: Reminder | undefined = Reminder.createEmpty();

  dateUnits = Unit;

  targets: any[] = [];

  vehiclesFromGroups: Vehicle[] = [];

  vehicleActionTypes: VehicleActionType[] = [];

  firstFireAt = "";

  firstFireAtMeter: number | null = null;

  firstFires: any[] = [];

  firstFiresAtMeter: any[] = [];

  isShowVehicleTargetSelection = true;

  @companyModule.State
  company!: any

  get originalVehicle() {
    return this.originalReminder
      && this.originalReminder.trackedVehicle;
  }

  get plan() {
    const reminder = this.reminder as Reminder;
    const result = [];
    if (this.firstFires.length > 1 || this.firstFireAt) {
      const firstPlanItem = {
        date: moment(this.firstFires.length > 1 ? this.firstFires[0] : this.firstFireAt)
          .toISOString(true)
          .substr(0, 10),
        type: "by date",
      };
      result.push(firstPlanItem);
      if (reminder.repeatEvery && reminder.repeatEvery.value !== 0) {
        for (let i = 1; i < 5; i++) {
          result.push(
            {
              date: moment(firstPlanItem.date)
                .add(reminder.repeatEvery.value * i, reminder.repeatEvery.unit)
                .toISOString(true)
                .substr(0, 10),
              type: "by date",
            }
            ,
          );
        }
      }
      if ((this.firstFireAtMeter && this.firstFireAtMeter !== 0) || (this.firstFiresAtMeter.length > 0 && this.firstFiresAtMeter[0] !== 0)) {
        result.push(
          {
            date: randomMoment(moment(firstPlanItem.date), moment(result[result.length - 1].date)).toISOString(true).substr(0, 10),
            type: "mileage reached",

          },
        );
      }
      return result.sort((a, b) => moment(a.date).diff(moment(b.date)));
    }
    return [];
  }

  get targetVehicles() {
    return this.targets.filter((it) => it.isVehicle).map((it) => it.model);
  }

  get allVehicles() {
    return [...this.targetVehicles, ...this.vehiclesFromGroups];
  }

  get targetGroups() {
    return this.targets.filter((it) => it.isGroup);
  }

  get saveIsDisabled() {
    return this.loading
        || !this.reminder.name
        || this.allVehicles.length === 0
        || isEqual(this.reminder, this.originalReminder);
  }

  @Watch("firstFireAt")
  async onChangeDoneAt() {
    this.reminder.firstFireAt = moment.tz(this.firstFireAt, this.company.timeZone).utc().format();
  }

  @Watch("targetGroups")
  async onChangeTargetGroups(): Promise<void> {
    this.loading = true;
    this.vehiclesFromGroups = await this.loadVehiclesFromGroups(this.targetGroups.map(((it: any) => it.id)));
    this.loading = false;
  }

  @Emit("onAddOrSave")
  onAddOrSave(reminders: Reminder[]) {
    return reminders;
  }

  async openDialog(reminder: Reminder | undefined) {
    this.isShowVehicleTargetSelection = true;
    this.open = true;
    this.originalReminder = reminder;
    if (this.originalReminder) {
      this.reminder = Reminder.copy(this.originalReminder);
      this.firstFireAt = this.reminder.firstFireAt ? this.reminder.firstFireAt.substr(0, 10) : "";
      this.firstFireAtMeter = this.reminder.firstFireAtMeter ? this.reminder.firstFireAtMeter : null;
    }
  }

  openDatePerVehicleDialog() {
    (this.$refs.datePerVehicleDialog as any).openDialog();
  }

  openMileagePerVehicleDialog() {
    (this.$refs.mileagePerVehicleDialog as any).openDialog();
  }

  onEditTargetsByDate(event: any) {
    this.firstFires = event;
  }

  onEditTargetsByMileage(event: any) {
    this.firstFiresAtMeter = event;
  }

  closeDialog() {
    this.open = false;
    this.reminder = Reminder.createEmpty();
    this.originalReminder = undefined;
    this.targets = [];
    this.isShowVehicleTargetSelection = false;
    this.firstFireAt = "";
    this.firstFireAtMeter = null;
  }

  onTargetSelect(targets: any[]) {
    this.targets = targets;
  }

  openCreateVehicleActionTypeDialog() {
    (this.$refs.editVehicleActionTypeDialog as any).openDialog();
  }

  applyCreatedActionType(actionType: VehicleActionType) {
    this.vehicleActionTypes = [...this.vehicleActionTypes, actionType];
    this.reminder.trackedActionType = actionType;
  }

  async createReminder() {
    let reminderMetaData: VehicleReminderMetadata[] = [];
    if (this.allVehicles.length === 1) {
      reminderMetaData.push(new VehicleReminderMetadata(
        this.allVehicles[0],
        this.reminder.firstFireAt,
        this.firstFireAtMeter,
      ));
    } else {
      reminderMetaData = _.merge(this.firstFires, this.firstFiresAtMeter)
        .map((it) => new VehicleReminderMetadata(
          it.vehicle,
          moment.tz(it.firstFireAt, this.company.timeZone).utc().format(),
          it.firstFireAtMeter,
        ));
    }

    this.loading = true;
    const response = await this.addOrSaveVehicleReminder({ vehicleReminder: this.reminder, reminderMetaData });
    this.loading = false;
    this.onAddOrSave(response);
    this.closeDialog();
  }

  @vehicleActionTypesModule.Action
  loadAllVehicleActionTypes!: () => Promise<VehicleActionType[]>

  @vehicleGroupsModule.Action
  loadVehiclesFromGroups!: (ids: string[]) => Promise<Vehicle[]>

  @vehicleRemindersModule.Action
  addOrSaveVehicleReminder!: (data: any) => Promise<Reminder[] >

  async mounted() {
    this.loading = true;
    this.vehicleActionTypes = await this.loadAllVehicleActionTypes();
    this.loading = false;
  }
}
