var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.userInputDisabled}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.criteria.id ? _vm.$localizationService.localize( "coaching_page.create_criteria_dialog.title_edit" ) : _vm.$localizationService.localize( "coaching_page.create_criteria_dialog.title_create" ))+" ")])]),_c('v-card-text',{staticClass:"pb-0"},[(_vm.open)?_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.name'
                ) + '*',"disabled":_vm.userInputDisabled,"rules":_vm.nameRules},model:{value:(_vm.criteria.name),callback:function ($$v) {_vm.$set(_vm.criteria, "name", $$v)},expression:"criteria.name"}}),_c('v-textarea',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.description'
                ),"disabled":_vm.userInputDisabled,"rows":"4","no-resize":""},model:{value:(_vm.criteria.description),callback:function ($$v) {_vm.$set(_vm.criteria, "description", $$v)},expression:"criteria.description"}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-space-between"},[_c('v-autocomplete',{staticClass:"flex-grow-0",attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.report_type'
                ) + '*',"items":_vm.availableReportTypes,"item-text":"displayName","return-object":"","disabled":_vm.userInputDisabled || _vm.editMode,"rules":_vm.reportTypeRules},model:{value:(_vm.criteria.reportType),callback:function ($$v) {_vm.$set(_vm.criteria, "reportType", $$v)},expression:"criteria.reportType"}}),_c('v-autocomplete',{staticClass:"flex-grow-0",attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.group_rows_by'
                ),"items":_vm.availableGroupingProperties,"item-text":"displayName","item-value":"name","multiple":"","disabled":_vm.userInputDisabled || _vm.editMode},model:{value:(_vm.criteria.groupBy),callback:function ($$v) {_vm.$set(_vm.criteria, "groupBy", $$v)},expression:"criteria.groupBy"}}),_c('v-autocomplete',{staticClass:"flex-grow-0",attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.property'
                ) + '*',"items":_vm.availableProperties,"item-text":"displayName","return-object":"","disabled":_vm.userInputDisabled || _vm.editMode,"rules":_vm.propertyRules},model:{value:(_vm.criteria.property),callback:function ($$v) {_vm.$set(_vm.criteria, "property", $$v)},expression:"criteria.property"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.function'
                ),"items":_vm.availableFunctions,"item-text":"displayName","return-object":"","disabled":_vm.userInputDisabled || _vm.editMode},model:{value:(_vm.criteria.func),callback:function ($$v) {_vm.$set(_vm.criteria, "func", $$v)},expression:"criteria.func"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('p',{staticClass:"mr-2 mb-2 condition-brackets"},[_vm._v("(")]),_vm._l((_vm.functionArguments),function(arg){return [(!_vm.propertyValues.length)?_c('v-text-field',{key:arg.name,attrs:{"label":arg.displayName,"disabled":_vm.userInputDisabled || _vm.editMode},model:{value:(_vm.criteria.args[arg.name]),callback:function ($$v) {_vm.$set(_vm.criteria.args, arg.name, $$v)},expression:"criteria.args[arg.name]"}}):_vm._e(),(_vm.propertyValues.length)?_c('v-autocomplete',{key:arg.name,attrs:{"label":arg.displayName,"item-text":"displayName","item-value":"name","items":_vm.propertyValues,"disabled":_vm.userInputDisabled || _vm.editMode},model:{value:(_vm.criteria.args[arg.name]),callback:function ($$v) {_vm.$set(_vm.criteria.args, arg.name, $$v)},expression:"criteria.args[arg.name]"}}):_vm._e()]}),(!_vm.functionArguments.length)?_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.no_arguments'
                ),"disabled":""}}):_vm._e(),_c('p',{staticClass:"ml-2 mb-2 condition-brackets"},[_vm._v(")")])],2),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.operator'
                ) + '*',"items":_vm.availableOperators,"item-text":"displayName","return-object":"","disabled":_vm.userInputDisabled || _vm.editMode,"rules":_vm.operatorRules},model:{value:(_vm.criteria.operator),callback:function ($$v) {_vm.$set(_vm.criteria, "operator", $$v)},expression:"criteria.operator"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.expressionLeftSideTypeIsEnum)?_c('v-autocomplete',{attrs:{"items":_vm.propertyValues,"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.threshold_value'
                ),"item-text":"displayName","item-value":"name","disabled":_vm.userInputDisabled || _vm.editMode},model:{value:(_vm.criteria.thresholdValue),callback:function ($$v) {_vm.$set(_vm.criteria, "thresholdValue", $$v)},expression:"criteria.thresholdValue"}}):_c('v-text-field',{attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.create_criteria_dialog.param.threshold_value'
                ),"rules":[_vm.validateThresholdValueRule],"disabled":_vm.userInputDisabled || _vm.editMode},model:{value:(_vm.criteria.thresholdValue),callback:function ($$v) {_vm.$set(_vm.criteria, "thresholdValue", $$v)},expression:"criteria.thresholdValue"}})],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":!_vm.loaded},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.canCreateOrSave},on:{"click":_vm.createCriteria}},[_vm._v(" "+_vm._s(_vm.criteria.id ? _vm.$localizationService.localize("btn.save") : _vm.$localizationService.localize("btn.create"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }