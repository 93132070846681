<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="loading">
      <v-card-title>
        <span class="headline">
          {{
            $localizationService.localize(editedFuelCard.id
                ? "fuel_card_page.dialog.add_and_edit.edit_title"
                : "fuel_card_page.dialog.add_and_edit.new_title"
            )
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="editedFuelCard.cardNumber"
                  v-on:keypress="isNumber($event)"
                  :label="
                $localizationService.localize(
                  'fuel_card_page.dialog.add_and_edit.param.card_number'
                )
              "
                  :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                  v-model="expirationDateMenu"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="formattedDate"
                      readonly
                      :label="
                        $localizationService.localize(
                          'fuel_card_page.dialog.add_and_edit.param.expiration_date'
                        )
                      "
                      :disabled="loading"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    type="month"
                    @input="closeMenu"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="expirationDateMenu = false">
                    {{ $localizationService.localize("btn.cancel") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="editedFuelCard.cardId"
                  :label="$localizationService.localize(
                  'fuel_card_page.dialog.add_and_edit.param.card_id'
                )"
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="closeDialog">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="addOrSaveFuelCard"
            :disabled="loading || !editedFuelCard.cardNumber || !date"
        >
          {{ $localizationService.localize(editedFuelCard.id ? "btn.save" : "btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import moment from "moment";
import { FuelCard } from "../../model/fuel-card.model";

export default {
  name: "EditFuelCardDialog",

  data: () => ({
    open: false,
    loading: false,

    date: "",

    expirationDateMenu: false,

    editedFuelCardOriginal: {},
    editedFuelCard: {},
  }),

  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    },
  },

  methods: {

    formatDate(date) {
      if (!date) return null;
      return moment(date).format("MM/YY");
    },

    closeMenu() {
      this.editedFuelCard.year = +moment(this.date, "YYYY-MM").format("YYYY");
      this.editedFuelCard.month = +moment(this.date, "YYYY-MM").format("MM");
      this.expirationDateMenu = false;
    },

    isNumber(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return false;
      return e.preventDefault();
    },

    openDialog(fuelCard) {
      this.editedFuelCardOriginal = fuelCard || {};
      this.editedFuelCard = Object.assign(new FuelCard(), this.editedFuelCardOriginal) || new FuelCard();
      this.date = fuelCard ? moment(this.editedFuelCard.validThru, "MM/YY").format("yy-MM") : "";
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    async addOrSaveFuelCard() {
      this.loading = true;
      const response = await this.$store.dispatch(
        "fuelCardsModule/addOrSaveFuelCard",
        this.editedFuelCard,
      );
      this.$emit("onAddOrSaveCard", response);
      this.loading = false;
      this.open = false;
    },
  },
};
</script>

<style scoped>

</style>
