var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.drawer ? 'drawer-open' : 'drawer-closed',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"infractions-table-display",class:{'not-default-page-size': !_vm.isDefaultSize},attrs:{"headers":_vm.headers,"items":_vm.displayInfractions,"loading":_vm.currentPageLoading || !_vm.tableInited,"server-items-length":_vm.totalCount,"footer-props":_vm.footerProps,"multi-sort":"","custom-sort":_vm.sortStub,"item-class":_vm.getItemClass,"options":{
          page: _vm.pageIndex,
          itemsPerPage: _vm.pageSize,
          sortBy: _vm.sort.sortBy,
          sortDesc: _vm.sort.sortDesc,
        }},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.infractions_table.title" ))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize(
                    'coaching_page.infractions_table.settings_tooltip.save'
                  ),"pDefaultButtonTooltip":_vm.$localizationService.localize(
                    'coaching_page.infractions_table.settings_tooltip.apply_default'
                  )},on:{"onSaveSettings":function($event){return _vm.saveSettings()},"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()}}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field mr-2",attrs:{"label":_vm.$localizationService.localize(
                  'coaching_page.infractions_table.search'
                ),"hide-details":"","width":"200"},on:{"input":function($event){return _vm.onSearchStringChange()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('CoachingMenu')],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
              'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionDateView(item))+" ")]}},{key:"item.driver",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"drivers-list-string"},[_vm._l((_vm.getInfractionDriverView(item)),function(driver){return [_c('router-link',{key:("link-" + (driver.id)),staticClass:"driver-name",attrs:{"to":("/infractions/driver/" + (driver.id))}},[_vm._v(_vm._s(driver.name))]),_c('span',{key:("spacer-" + (driver.id)),staticClass:"driver-names-delimeter"},[_vm._v(", ")])]})],2)]}},{key:"item.vehicle",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfractionVehicleView(item))+" ")]}},{key:"item.criteria",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.criteria.name)+" ")]}},{key:"item.detectedValue",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.detectedValue)+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status == 'RESOLVED')?_c('div',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.infractions_table.status.resolved" ))+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"green"}},[_vm._v("mdi-check")])],1):(item.status == 'NEW')?_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.infractions_table.status.new" ))+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"size":"1.25em","color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1):(item.status == 'COACHING_ASSIGNED')?_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize( "coaching_page.infractions_table.status.coaching_assigned" ))+" ")]):_c('div',[_vm._v(_vm._s(item.status))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }