<template>
  <!-- width="278px" = (* * * * * * * * * * * * * * 0 0 0 0).length in pixels on desktop -->
  <v-skeleton-loader
    v-if="!infractions"
    class="driver-infractions-dots-skeleton"
    width="278px"
    type="list-item"
  >
  </v-skeleton-loader>
  <div v-else class="d-flex flex-row">
    <div class="dots-container d-flex flex-row align-self-center">
      <div
        v-for="day in days"
        :key="day.key"
        class="theme-border-color"
        :class="`infraction-circle ${day.stateClass}`"
      ></div>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="infractions-count-container d-flex flex-row ml-1"
        >
          <span class="error--text">{{
            infractions.filter((it) => it.status == "NEW").length
          }}</span>
          <span class="primary--text">{{
            infractions.filter((it) => it.status == "COACHING_ASSIGNED").length
          }}</span>
          <span class="green--text">{{
            infractions.filter((it) => it.status == "RESOLVED").length
          }}</span>
          <span>{{ infractions.length }}</span>
        </div>
      </template>
      <div
        class="tooltip-content"
        :class="{ 'tooltip-content-mobile': IS_MOBILE }"
      >
        <span class="error--text">
          {{
            $localizationService.localize(
              "coaching_page.driver_infractions_dots.red"
            )
          }} </span
        ><br />
        <span class="primary--text">
          {{
            $localizationService.localize(
              "coaching_page.driver_infractions_dots.blue"
            )
          }} </span
        ><br />
        <span class="green--text">
          {{
            $localizationService.localize(
              "coaching_page.driver_infractions_dots.green"
            )
          }} </span
        ><br />
        {{
          $localizationService.localize(
            "coaching_page.driver_infractions_dots.white"
          )
        }}
        <br />
        {{
          $localizationService.localize(
            "coaching_page.driver_infractions_dots.note"
          )
        }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import moment from "moment";
import { defaultDateFormat, IS_MOBILE } from "../../utils/utils";

export default {
  props: {
    infractions: Array,
  },

  data: () => ({
    IS_MOBILE,
  }),

  computed: {
    days() {
      const days = [];

      const now = moment();

      for (
        let dateI = now.clone().subtract({ days: 13 });
        dateI.isBefore(now.clone().add({ minute: 1 }));
        dateI.add(1, "days")
      ) {
        const date = dateI.clone();

        const infractionsAtDate = (this.infractions || []).filter(
          (infraction) => {
            const dateCircumstance = infraction.circumstances.find(
              (it) => it.type == "DateCircumstance",
            );

            return (
              dateCircumstance
              && dateCircumstance.date == date.format(defaultDateFormat)
            );
          },
        );

        let stateClass = "empty";

        if (infractionsAtDate.find((it) => it.status == "NEW")) {
          stateClass = "error";
        } else if (
          infractionsAtDate.find((it) => it.status == "COACHING_ASSIGNED")
        ) {
          stateClass = "primary";
        } else if (infractionsAtDate.find((it) => it.status == "RESOLVED")) {
          stateClass = "green";
        }

        days.push({
          date,
          key: date.format(defaultDateFormat),
          infractions: infractionsAtDate,
          stateClass,
        });
      }

      return days;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip-content {
  width: 30vw;
}
.tooltip-content-mobile {
  width: 100% !important;
}
.infractions-count-container {
  * {
    margin-left: 6px;
  }
}
.infraction-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &.empty {
    border: 2px solid rgba(255, 255, 255, 0.7);
    background-color: transparent;
  }
}
.dots-container {
  min-width: 218px;
  width: 218px;
  max-width: 218px;
}
</style>

<style lang="scss">
.driver-infractions-dots-skeleton {
  .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
